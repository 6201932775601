import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonGeneralInvoice = ({
    ReloadData,
    DataRegUserAccess,
    AddGI,
    EditGI,
    DeleteData,
    Print,
    PrintRecap,
    OpenLogData,
    HandleStatus,
    DropDownTitle,
    DropDownList
}) => {

    const renderButton = (data) => {
        switch (data.accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddGI, 'New');
            case 'REI':
                return ButtonComponent.EditButton(EditGI, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data') : null;
            case 'PRI':
                return DropDownTitle !== 'Deleted' ? (
                    <>
                        {ButtonComponent.PrintButton(Print)}
                        {ButtonComponent.PrintButton(PrintRecap, 'Rekap')}
                    </>
                ) : null;
            case 'LOG':
                return DropDownTitle !== 'Active' ? ButtonComponent.ArchiveButton(OpenLogData, 'Search Transactions Log') : null;
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data)}
                        </React.Fragment>
                    ))}

                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                </Grid>
            </div>
        </div>
    );
}

export default ButtonGeneralInvoice;