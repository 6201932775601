import { LinearProgress, Stack, Typography } from "@mui/material";
import { memo } from "react";

const ProgressComponent = memo(({ progress }) => {
    return (
        <Stack direction={'row'} spacing={'32px'} sx={{ width: '100%', alignItems: 'center' }}>
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                    width: '100%',
                    height: '5px',
                    borderRadius: '4px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: '#6FCF97',
                    },
                }}
            />

            <Typography
                sx={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    lineHeight: '0px',
                    color: '#001A4F',
                    userSelect: 'none',
                }}
            >
                {`${Math.round(progress)}%`}
            </Typography>
        </Stack>
    );
});

export default memo(ProgressComponent);
