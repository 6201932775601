import React from 'react';
import ButtonService from '../ButtonService';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input } from '../../../components/ui';

const CreateEditBank = (props) => {

    const Save = () => {
        var data = {
            rowStatus: 'ACT',
            id: props.id,
            intName: props.intName,
            name: props.name,
            address: props.address,
        };

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
            window.location.reload();
        });
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                name="intName"
                label="Int. Code"
                value={props.intName}
                onChange={(e) => props.setIntName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                disabled
                name="masterCode"
                label="Master Code"
                value={props.masterCode}
                onChange={(e) => props.setmasterCode(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="name"
                label="Name"
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="address"
                label="Address"
                value={props.address}
                onChange={(e) => props.setAddress(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />
        </form>
    )

    return (
        <CreateEditModalMaster
            isModal={props.isModal} titleModal={props.titleModal} title={props.title}
            mainContainer={mainContainer} handleClick={props.handleClick} handleSave={Save}
        />
    )
}

export default CreateEditBank