import React from "react";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import { Grid } from "@mui/material";

const ButtonBooking = ({
  DataRegUserAccess,
  isApprove,
  wfComplete,
  isEdit,
  isCopy,
  Save,
  Submit,
  Print,
  Approve,
  UnApprove,
  Add
}) => {

  const hasAccessSave = () => {
    return DataRegUserAccess.some((x) => x.accessCode === 'CRE' || x.accessCode === 'UPD');
  };

  const renderButton = (accessCode) => {
    switch (accessCode) {
      case 'SUB':
        return !isApprove && wfComplete !== 19
          ? ButtonComponent.SaveButton(Submit, false, false, true)
          : null;
      case 'PRI':
        return isEdit && !isApprove && isCopy !== 'true' && wfComplete !== 2
          ? ButtonComponent.PrintButton(Print, '', true)
          : null;
      case 'APP':
        return isEdit && !isApprove && isCopy !== 'true' && wfComplete !== 2
          ? ButtonComponent.ApproveButton(Approve, "Approval", true)
          : null;
      case 'UPP':
        return isEdit && isApprove && isCopy !== 'true' && wfComplete !== 2
          ? ButtonComponent.UnApprovalButton(UnApprove, '', true)
          : null;
      case 'CRE':
        return isEdit && isCopy !== 'true' && wfComplete !== 2
          ? ButtonComponent.AddButton(Add, "New", false, false, true)
          : null;
      default:
        return null;
    }
  };

  return (
    <div className="row" style={{ marginTop: '18px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: '12px' }}
        >
          {hasAccessSave() && !isApprove && (
            ButtonComponent.SaveButton(Save, false, false, true, wfComplete !== 19 ? 'As Draft' : '')
          )}

          {DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {renderButton(data.accessCode)}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonBooking;