import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import { Grid, Box, Autocomplete, FormControl } from "@mui/material";
import { HubConnectionState } from '@microsoft/signalr';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ModalLogData from "./Modals/ModalLogData";
import ButtonBalance from "./Buttons/ButtonBalance";
import ApiService from "../../../../services/ApiService";
import { signalRConnectionCB } from "../../../../services/ApiService";
import TableBalance from "../../../../components/Tables/TableBalance";
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from "../../../../components/Components/HeaderInfo";
import CustomToastContainer, { Input, showToast } from '../../../../components/ui';

const BalanceList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const history = useNavigate();
    const paramClosingBalance = 'closingBalance/closingBalance';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [Data, setData] = useState([]);

    const [Period, setPeriod] = useState(new Date());

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [CollapseColumnData, setCollapseColumnData] = useState([]);
    const [CollapseData, setCollapseData] = useState([]);
    const [CollapseTotalRows, setCollapseTotalRows] = useState(50);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const paramBranch = 'branch/branch';
    const [BranchData, setBranchData] = useState([]);
    const [BranchId, setBranchId] = useState(0);
    const [BranchName, setBranchName] = useState(null);
    const defaultBranchProps = {
        options: BranchData,
        getOptionLabel: (option) => option.name,
    };

    const paramCompany = 'company/company';
    const [CompanyData, setCompanyData] = useState([]);
    const [CompanyId, setCompanyId] = useState(0);
    const [CompanyName, setCompanyName] = useState(null);
    const defaultCompanyProps = {
        options: CompanyData,
        getOptionLabel: (option) => option.name,
    };

    const paramGroup = 'companyGroup/companyGroup';
    const [GroupData, setGroupData] = useState([]);
    const [GroupId, setGroupId] = useState(0);
    const [GroupName, setGroupName] = useState(null);
    const defaultGroupProps = {
        options: GroupData,
        getOptionLabel: (option) => option.name,
    };

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Balance', link: '/Majura/Balance', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Balance - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FCLB')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getDateNow();
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        setIsLoadingBackDrop(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    function getDateNow() {
        let date = new Date();
        let formatDate = date.toLocaleDateString("en-US");
        ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, formatDate)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.closingPeriod;
                    localStorage.setItem("period", JSON.stringify(data.period));
                    localStorage.setItem("yearPeriod", JSON.stringify(data.yearPeriod));
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                // console.error('error saat fetch', error);
            })

        localStorage.setItem("page", JSON.stringify(1));
        localStorage.setItem("rows", JSON.stringify(50));

        localStorage.setItem('cbBranchId', JSON.stringify(props.branchId));
        localStorage.setItem('cbCompanyId', JSON.stringify(props.companyId));
        localStorage.setItem('cbGroupId', JSON.stringify(props.groupId));

        if (props.roleName === 'company') {
            GetBranchData(1, 999);
        }
        else if (props.roleName === 'group') {
            GetCompanyData(1, 999);
        }
        else if (props.roleName === 'holding') {
            GetGroupData(1, 999);
        }

        if (signalRConnectionCB.state === HubConnectionState.Disconnected) {
            getConnection();
        }
    };

    function getDateM() {
        let date = new Date();
        let formatDate = date.toLocaleDateString("en-US");
        ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, formatDate)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.closingPeriod;
                    localStorage.setItem("periodM", JSON.stringify(data.period));
                    localStorage.setItem("yearPeriodM", JSON.stringify(data.yearPeriod));
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                // console.error('error saat fetch', error);
            })
    };

    //Function for Branch Data
    const GetBranchData = (pageNumber, pageSize, companyId) => {
        let params = [];
        if (props.roleName !== 'company') {
            params = [
                {
                    'attr': 'companyId',
                    'value': '' + companyId
                }
            ]
        }
        ApiService.DynamicPostByPage(paramBranch, 'SEARCH', pageNumber, pageSize, params)
            .then((response) => {
                if (response.status === 200) {
                    setBranchData(response.data.data.branch);

                    if (props.roleName === 'company') {
                        getData(1, 25);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectBranchData = (setBranchId, BranchName, setBranchName) => (
        <FormControl sx={{ 'width': 200 }} className="mt-2" fullWidth>
            <Autocomplete
                {...defaultBranchProps}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                value={BranchName}
                onChange={(event, newValue) => {
                    setBranchName(newValue);
                    if (newValue !== null) {
                        setBranchId(newValue.id);
                    }
                    else {
                        setBranchId(0);
                    }
                }}
                id="controllable-states-demo"
                variant="standard"
                autoComplete
                includeInputInList
                renderInput={(params) => <Input {...params} label="Branch Name" variant="standard" />}
            />
        </FormControl>
    );

    //Function for Company Data
    const GetCompanyData = (pageNumber, pageSize, groupId) => {
        let params = [];
        if (props.roleName !== 'group') {
            params = [
                {
                    'attr': 'groupId',
                    'value': '' + groupId
                }
            ]
        }
        ApiService.DynamicPostByPage(paramCompany, 'SEARCH', pageNumber, pageSize, params)
            .then((response) => {
                if (response.status === 200) {
                    setCompanyData(response.data.data.company);

                    if (props.roleName === 'group') {
                        getData(1, 25);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectCompanyData = (setCompanyId, CompanyName, setCompanyName) => (
        <FormControl sx={{ 'width': 200 }} className="mt-2" fullWidth>
            <Autocomplete
                {...defaultCompanyProps}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                value={CompanyName}
                onChange={(event, newValue) => {
                    setCompanyName(newValue);
                    setBranchData([]);
                    setBranchName(null);
                    setBranchId(0);
                    if (newValue !== null) {
                        setCompanyId(newValue.id);
                        GetBranchData(1, 999, newValue.id);
                    }
                    else {
                        setCompanyId(0);
                        setBranchData([]);
                    }
                }}
                id="controllable-states-demo"
                variant="standard"
                autoComplete
                includeInputInList
                renderInput={(params) => <Input {...params} label="Company Name" variant="standard" />}
            />
        </FormControl>
    );

    //Function for Group Data
    const GetGroupData = (pageNumber, pageSize) => {
        let params = [];
        ApiService.DynamicPostByPage(paramGroup, 'SEARCH', pageNumber, pageSize, params)
            .then((response) => {
                if (response.status === 200) {
                    setGroupData(response.data.data.companyGroup);

                    if (props.roleName === 'holding') {
                        getData(1, 25);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectGroupData = (setGroupId, GroupName, setGroupName) => (
        <FormControl sx={{ 'width': 200 }} className="mt-2" fullWidth>
            <Autocomplete
                {...defaultGroupProps}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                value={GroupName}
                onChange={(event, newValue) => {
                    setGroupName(newValue);
                    setCompanyData([]);
                    setCompanyName(null);
                    setCompanyId(0);
                    setBranchData([]);
                    setBranchName(null);
                    setBranchId(0);
                    if (newValue !== null) {
                        setGroupId(newValue.id);
                        GetCompanyData(1, 999, newValue.id);
                    }
                    else {
                        setGroupId(0);
                        setCompanyData([]);
                    }
                }}
                id="controllable-states-demo"
                variant="standard"
                autoComplete
                includeInputInList
                renderInput={(params) => <Input {...params} label="Group Name" variant="standard" />}
            />
        </FormControl>
    );

    const handleClick2 = () => {
        if (isModal2 === false) {
            getDateM();
            getDataLog(1, 50);
            setIsModal2(true);
        }
        else {
            setIsModal2(false);
        }
    };

    function handleFindData() {
        localStorage.setItem('cbBranchId', JSON.stringify(BranchId));

        if (props.roleName !== 'company') {
            localStorage.setItem('cbCompanyId', JSON.stringify(CompanyId));

            if (props.roleName !== 'group') {
                localStorage.setItem('cbGroupId', JSON.stringify(GroupId));
            }
        }
        getData(1, 25);
    }

    async function getConnection() {
        try {
            signalRConnectionCB.on("ReceiveMessage", (res) => {
                if (res === 'ClosingBalanceNotif') {
                    let yourDate = new Date();
                    var Month = '' + yourDate.toLocaleDateString("sv-se", { month: 'numeric' });
                    var Year = '' + yourDate.toLocaleDateString("sv-se", { year: 'numeric' });

                    if (Month === '' + JSON.parse(localStorage.getItem("period")) && Year === '' + JSON.parse(localStorage.getItem("yearPeriod"))) {
                        let params = [
                            {
                                "attr": "Period",
                                "value": "" + JSON.parse(localStorage.getItem("period"))
                            },
                            {
                                "attr": "YearPeriod",
                                "value": "" + JSON.parse(localStorage.getItem("yearPeriod"))
                            },
                            {
                                "attr": 'groupId',
                                "value": "" + JSON.parse(localStorage.getItem("cbGroupId"))
                            },
                            {
                                "attr": 'companyId',
                                "value": "" + JSON.parse(localStorage.getItem("cbCompanyId"))
                            },
                            {
                                "attr": 'branchId',
                                "value": "" + JSON.parse(localStorage.getItem("cbBranchId"))
                            }
                        ]
                        ApiService.FinAccPostByPage(paramClosingBalance, 'PAGE', JSON.parse(localStorage.getItem("page")), JSON.parse(localStorage.getItem("rows")), params, [])
                            .then((response) => {
                                if (response.data.code === 200 || response.data.code === 500) {
                                    let temp = response.data.data.closingBalances
                                    let indexed = temp.map((el, index) => {
                                        let indexedTemp = {
                                            ...el,
                                            index
                                        }
                                        return indexedTemp
                                    })
                                    localStorage.setItem("cbData", JSON.stringify(indexed));
                                    setData(indexed);

                                    setIsLoadingBackDrop(false);
                                }
                            })
                            .catch(function (error) {
                                setIsLoadingBackDrop(false);
                                console.error('error saat fetch', error)
                                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                            })
                    }
                }
            });

            signalRConnectionCB.onclose(e => { });

            if (signalRConnectionCB.state === HubConnectionState.Disconnected) {
                await signalRConnectionCB.start();
                await signalRConnectionCB.invoke("Show", JSON.parse(localStorage.getItem("username")));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    // function handleInsertRecursive(data, arrayData) {
    //     for(let i = 0; i < arrayData.length; i++)
    //     {
    //         if(data[i].coaId === arrayData[i].coaId)
    //         {
    //             data[i].gldcId = arrayData[i].gldcId;
    //             data[i].refId = arrayData[i].refId;
    //             data[i].coaRefId = arrayData[i].coaRefId;
    //             data[i].curtDBIDR = arrayData[i].curtDBIDR;
    //             data[i].curtCDIDR = arrayData[i].curtCDIDR;

    //             if(data[i].closingBalances.length > 0 && arrayData[i].closingBalances.length > 0)
    //             {
    //                 handleInsertRecursive(data[i].closingBalances, arrayData[i].closingBalances);
    //             }
    //         }
    //     }
    // }

    const getData = (pageNumber, pageSize) => {
        setIsLoadingBackDrop(true);
        let params = [
            {
                "attr": "Period",
                "value": "" + JSON.parse(localStorage.getItem("period"))
            },
            {
                "attr": "YearPeriod",
                "value": "" + JSON.parse(localStorage.getItem("yearPeriod"))
            },
            {
                "attr": 'groupId',
                "value": "" + JSON.parse(localStorage.getItem("cbGroupId"))
            },
            {
                "attr": 'companyId',
                "value": "" + JSON.parse(localStorage.getItem("cbCompanyId"))
            },
            {
                "attr": 'branchId',
                "value": "" + JSON.parse(localStorage.getItem("cbBranchId"))
            }
        ]
        ApiService.FinAccPostByPage(paramClosingBalance, 'PAGE', pageNumber, pageSize, params, [])
            .then((response) => {
                if (response.data.code === 200 || response.data.code === 500) {
                    let temp = response.data.data.closingBalances
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setData(indexed);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoadingBackDrop(false);
                }
                setIsLoadingBackDrop(false);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const setDataPeriod = (data) => {
        let tempPeriod = data.$d;
        let month = tempPeriod.getMonth() + 1;
        let year = tempPeriod.getFullYear();

        setPeriod(data.$d);
        setIsLoadingBackDrop(true);
        localStorage.setItem("period", JSON.stringify(month));
        localStorage.setItem("yearPeriod", JSON.stringify(year));
        getData(1, 25);
    };

    const OpenLogData = () => {
        setTitleModal2('Search Log Data');
        handleClick2();
    }

    const getDataLog = (pageNumber, pageSize) => {
        let params = [
            {
                "attr": "Period",
                "value": "" + JSON.parse(localStorage.getItem("periodM"))
            },
            {
                "attr": "YearPeriod",
                "value": "" + JSON.parse(localStorage.getItem("yearPeriodM"))
            }
        ]
        ApiService.FinAccPostByPage(paramClosingBalance, 'LOG', pageNumber, pageSize, params, [])
            .then((response) => {
                if (response.data.code === 200) {
                    let temp = response.data.data.closingBalances
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setCollapseData(indexed);
                    setCollapseColumnData(response.data.data.columns);
                    setCollapseTotalRows(response.data.totalRowCount);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPagination = 60
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <ButtonBalance
                Period={Period}
                setDataPeriod={setDataPeriod}
                OpenLogData={OpenLogData}
                DataRegUserAccess={DataRegUserAccess}
                BranchId={BranchId}
                setBranchId={setBranchId}
                BranchName={BranchName}
                setBranchName={setBranchName}
                CompanyId={CompanyId}
                setCompanyId={setCompanyId}
                CompanyName={CompanyName}
                setCompanyName={setCompanyName}
                GroupId={GroupId}
                setGroupId={setGroupId}
                GroupName={GroupName}
                setGroupName={setGroupName}
                roleName={props.roleName}
                selectBranchData={selectBranchData}
                selectCompanyData={selectCompanyData}
                selectGroupData={selectGroupData}
                handleFindData={handleFindData}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Balance'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        <TableBalance Data={Data} isLoadingBackDrop={isLoadingBackDrop} maxHeight={'525px'} />
                    </div>
                </Grid>
            </Grid>

            {
                isModal2 && (
                    <ModalLogData
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}

                        getData={getDataLog}
                        CollapseTotalRows={CollapseTotalRows}
                        setCollapseTotalRows={setCollapseTotalRows}
                        CollapseColumnData={CollapseColumnData}
                        setCollapseColumnData={setCollapseColumnData}
                        CollapseData={CollapseData}
                        setCollapseData={setCollapseData}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    );
})

export default BalanceList;