import { Backdrop, Box, Grid, MenuItem } from '@mui/material';
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import ApiService from '../../../../services/ApiService';
import TableX from '../../../../components/Tables/TableX';
import freezeContainer from '../../../../Styles/freezeContainer';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import EtaEtdDateRangeSelector from '../../../../components/Pickers/EtaEtdRangeSelectorComponent';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, Selection, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';

const NoticeOfArrivalPage = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const pageCode = 'SEANOA';
  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const paramShipmentOrder = 'shipmentOrder/shipmentOrder';
  const printUrlParam = 'Reports/Operation/Print/NoticeOfArrivalReport/';
  const printNoaVariable = 'PrintNOAP?id='
  const history = useNavigate();
  const dataETARange = [
    { id: 1, name: 'All', value: false },
    { id: 2, name: 'Custom', value: true }
  ];

  const [isETARange, setIsETARange] = useState(false);
  const [ETD, setETD] = useState('');
  const [ETA, setETA] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [dataJobType, setDataJobType] = useState([]);
  const [jobType, setJobType] = useState(2);
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);
  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');
  const [dataSelected, setDataSelected] = useState({});

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Notice of Arrival Report', link: '/Majura/NoticeOfArrivalReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Notice of Arrival Report - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadData = () => {
    showToast({ type: 'success', message: 'Reload Data' });
    emptyState();
    getData(1, 50, []);
  }

  const handleReset = () => {
    emptyState();
    getData(1, 50, []);
  }

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setStateX({});
    setSortArr([]);
    setGoTo(1);
  }

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
      setDataSelected(selectedData);
    } else {
      setIsModalConfirmation(false);
    }
  }

  const confirmationSP = () => {
    if (titleConfirmation === 'Print') {
      printPdf();
    }
    setIsModalConfirmation(false);
  }

  const getData = (numPage, pageSize, filters, orderBy) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }

    let filter = filters;
    let params = [
      {
        'attr': 'jobTypeId',
        'value': `${jobType}`
      },
      ...(isETARange === true) ?
        [
          {
            "attr": "eta, eta",
            "mathSign": ">=, <=",
            "value": `${ETD},${ETA}`
          }
        ]
        :
        []
    ];

    ApiService.OperationDynamicPostByPage(paramShipmentOrder, pageCode, numPage, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.shipmentOrder
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }

          setIsLoadingBackDrop(false);
        }

        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        setIsLoadingBackDrop(false);
      })
      .catch((error) => {
        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        setIsLoadingBackDrop(false);
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
      })
  }

  const getDataAccess = () => {
    setIsLoadingBackDrop(true);

    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      const params = [
        {
          'attr': 'permissionId',
          'value': `${pId}`
        },
        {
          'attr': 'userId',
          'value': `${JSON.parse(localStorage.getItem("userId"))}`
        },
        {
          'attr': 'roleId',
          'value': `${JSON.parse(localStorage.getItem("roleId"))}`
        }
      ]

      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataJobType(
              [
                {
                  id: 2,
                  code: '11',
                  name: 'Sea Import'
                },
                {
                  id: 4,
                  code: '21',
                  name: 'Air Import'
                }
              ]
            );

            getData(1, 50, []);
          }
        })
        .catch((error) => {
          console.error('error saat fetch', error)
          history('/Majura');
        });
    } else {
      history('/Majura');
    }
  }

  const printPdf = () => {
    setIsLoadingBackDrop(true)

    ApiService.ReportPrint(printUrlParam, `${printNoaVariable}${dataSelected.id}`)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";

          const file = new Blob(
            [response.data],
            { type: rptFormat }
          );
          const fileURL = URL.createObjectURL(file);

          setIsLoadingBackDrop(false);
          showToast({ type: 'success', message: 'Print Notice of arrival Report Success' });
          window.open(fileURL);
        } else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Print Notice of arrival Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Notice of arrival Report, ' + error });
      });
  }

  const printData = () => {
    if (selectedData.id !== undefined) {
      setTitleConfirmation('Print')
      setTitleModalConfirmation('Notice of arrival');
      handleClickConfirmation();
    } else {
      showToast({ type: 'error', message: 'Please Select Data Shipment First!' });
    }
  }

  const handleIsETARange = (value) => {
    setIsETARange(value);

    if (value === true) {
      setDateFormat(new Date(), setETA);
      setDateFormat(new Date(), setETD);
    }
    else {
      setETA('');
      setETD('');
    }
  }

  const handleETD = (value) => {
    if (ETA < value) {
      showToast({ type: 'error', message: 'ETD Can\'t be Greater Than ETA' });
    } else {
      setETD(value);
    }
  }

  const handleETA = (value) => {
    if (ETD > value) {
      showToast({ type: 'error', message: 'ETA Can\'t be Smaller Than ETD' });
    } else {
      setETA(value);
    }
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const SelectJobType = (props) => (
    <Selection
      id='0'
      label={'Job Type'}
      fullWidth={true}
      placeholder={'Select Job Type'}
      value={props.jobType}
      onChange={(e) => { props.setJobType(e.target.value) }}
    >
      {
        dataJobType.map((data) => (
          <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.id}>
            {data.name}
          </MenuItem>
        ))
      }
    </Selection>
  );

  const ButtonComponents = () => {
    return (
      <>
        <div className="row">
          <div className='col-12'>
            <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
              {ButtonComponent.ReloadButton(reloadData)}
              {ButtonComponent.PrintButton(printData)}
            </Grid>
          </div>
        </div>

        <div style={{ marginTop: '16px' }}>
          <Grid
            container
            columnSpacing={{ xs: 0, md: 3 }}
            rowSpacing={{ xs: 2, md: 0 }}
          >
            <Grid
              item
              xl={2}
              lg={2}
              sm={2}
              xs={12}
            >
              <div>
                <SelectJobType jobType={jobType} setJobType={setJobType} />
              </div>
            </Grid>

            <EtaEtdDateRangeSelector
              isRangeSelected={isETARange}
              handleRangeTypeChanged={(rangeValue) => handleIsETARange(rangeValue)}
              dataRanges={dataETARange}
              etd={ETD}
              onEtdChanged={(value) => handleETD(value)}
              eta={ETA}
              onEtaChanged={(value) => handleETA(value)}
            />
          </Grid>
        </div>
      </>
    )
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter);
  }

  return (
    <Box className='w-100' sx={{ widht: '100%', p: 3, ...freezeContainer }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          btnPageRef={btnPageRef}
          title={'Notice of Arrival Report'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
          <div ref={btnTableRef}>
            {
              ButtonComponent.RefreshButton(
                sortArr,
                rowsCount,
                columnData,
                setGoTo,
                handleReset,
                setFilterBy,
                filterBy,
                setFilter,
                setNumPage,
                setRowsCount,
                getData,
                stateX,
                setStateX
              )
            }
          </div>

          <div
            className="rounded-10 table-responsive"
            style={{
              maxHeight: `calc(100vh - ${getContainerHeight()})`,
              marginTop: '16px',
              padding: '2px 16px',
              borderRadius: '12px',
              background: '#F2F2F2'
            }}
          >
            {
              isLoading ?
                <LoadingSpinner />
                :
                <div className='mt-1'>
                  <TableX
                    uniqueKey={'id'}
                    goTo={goTo}
                    rowsCount={rowsCount}
                    setNumPage={setNumPage}
                    setRowsCount={setRowsCount}
                    getData={getData}
                    columnData={columnData}
                    dataMap={dataMap}
                    SelectedData={selectedData}
                    setSelectedData={setSelectedData}
                    setFilter={setFilter}
                    setFilterBy={setFilterBy}
                    CellDoubleClick={() => { }}
                    stateX={stateX}
                    setStateX={setStateX}
                    sortArr={sortArr}
                    setSortArr={setSortArr}
                    setGoTo={setGoTo}
                  />
                </div>
            }
          </div>

          <div style={{ marginTop: '16px' }}>
            <Paginate
              page={numPage}
              limit={rowsCount}
              totalData={totalRows}
              onChange={onChangePaging}
              onChangeLimit={onChangeLimit}
            />
          </div>
        </Grid>
      </Grid>

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={confirmationSP}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </Box>
  )
})

export default NoticeOfArrivalPage;
