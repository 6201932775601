import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';

const ButtonAdjustmentAPList = ({
    ReloadData,
    DataRegUserAccess,
    AddMemo,
    Print
}) => {

    const renderButton = (data) => {
        switch (data.accessCode) {
            case 'CRE':
                return ButtonComponent.MemorialButton(AddMemo, 'Memorial');
            case 'PRI':
                return ButtonComponent.PrintButton(Print, 'Report');
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data)}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonAdjustmentAPList;