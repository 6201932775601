import React from "react";
import ButtonComponent from "../../../../../components/Buttons/ButtonComponent";
import { Grid } from "@mui/material";

const ButtonCreateEditGeneralInvoice = ({
  check,
  Printing,
  Save,
  DataRegUserAccess,
  isEdit,
  Print,
  AddNew
}) => {

  const renderButton = (data) => {
    switch (data.accessCode) {
      case "PRI":
        return isEdit ? ButtonComponent.PrintButton(Print, '', true) : null;
      case "CRE":
        return isEdit ? ButtonComponent.AddButton(AddNew, "New", false, false, true) : null;
      default:
        return null;
    }
  };

  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: "12px" }}
        >
          {check !== 0 && (
            ButtonComponent.SaveButton(Save, false, Printing > 0, true)
          )}

          {DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {renderButton(data)}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonCreateEditGeneralInvoice;