import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Grid, Box } from '@mui/material';

import TableZ from '../../../components/Tables/TableZ';
import ModalDelete from '../../../components/Modals/ModalDelete';
import ModalContra from '../../../components/Modals/ModalContra';
import ButtonPaymentRequest from './Buttons/ButtonPaymentRequest';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../services/ApiService';

import freezeContainer from '../../../Styles/freezeContainer';
import ModalUsageData from '../../../components/Modals/ModalUsageData';
import TableX from '../../../components/Tables/TableX';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import { updateDateFormat } from '../../../utils/date';
import { newWindowConfig } from '../../../utils/masks.jsx';
import ModalRePostGL from '../../../components/Modals/ModalRePostGL.jsx';
import { handleFailedGL, handleRePost } from '../../../utils/constantVariable.jsx';
import { PrintPDF } from '../../../utils/reusableFunction.jsx';

const ListPaymentRequest = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const history = useNavigate();
  const paramPaymentRequest = 'paymentRequest/paymentRequest';
  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const paramGL = 'generalLedger/generalLedger';
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);

  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);

  const [jobType, setJobType] = useState('' + JSON.parse(localStorage.getItem("jobTypeId")) === '0' ? '1' : '' + JSON.parse(localStorage.getItem("jobTypeId")));
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);

  const [dataJobType, setDataJobType] = useState([]);
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [titleModalDelete, setTitleModalDelete] = useState('');
  const [DeleteRemarks, setDeleteRemarks] = useState('');

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');
  const [dataSelected, setDataSelected] = useState({});

  const [isModalContra, setIsModalContra] = useState(false);
  const [titleModalContra, setTitleModalContra] = useState('');

  const [isDraft, setIsDraft] = useState(true);
  const [isAllJob, setIsAllJob] = useState(false);
  const [stateX, setStateX] = useState({});
  const [DropDownTitle, setDropDownTitle] = useState('All');
  const [sortArr, setSortArr] = useState([])
  const [goTo, setGoTo] = useState(1);

  const [isModalUsage, setIsModalUsage] = useState(false);
  const [titleModalUsage, setTitleModalUsage] = useState('');
  const [usageDataList, setUsageDataList] = useState([]);

  const [accessLog, setAccessLog] = useState(false);
  const [accessFin, setAccessFin] = useState(false);
  const [isAcc, setIsAcc] = useState(false);

  const [isModalRePost, setIsModalRePost] = useState(false);
  const [failedData, setFailedData] = useState({});

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Payment Request', link: '/Majura/PaymentRequest', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  const dropDownList = [
    { label: 'All', icon: 'search-doc' },
    { label: 'Active', icon: 'documents' },
    { label: 'Posted', icon: 'posted' },
    { label: 'Settled', icon: 'journal' },
    { label: 'Deleted', icon: 'status-deleted' },
  ]

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    localStorage.setItem('accessFin', JSON.stringify(false));
    document.title = 'Payment Request - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterData = (value) => {
    setDropDownTitle(value);
    emptyState();
    getData(1, 50, [], [], value);
  }

  const getData = (pageNumber, pageSize, filters, orderBy, data) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let filter = [];
    let params = [];

    params = [...params, { 'attr': 'jobTypeId', 'value': '' + jobType }];

    if (data) {
      if (data === 'All') {
        filter = [...filters]
      }
      else if (data === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
      }
      else if (data === 'Active') {
        filter = [
          ...filters,
          { 'field': 'rowStatus', 'data': 'ACT' },
          { 'field': 'isPosted', 'data': '0' },
        ];
        params = [...params, { 'attr': 'prContraNo', 'value': '0' }];
      }
      else if (data === 'Posted') {
        filter = [
          ...filters,
          { 'field': 'rowStatus', 'data': 'ACT' },
          { 'field': 'isPosted', 'data': '1' },
          { 'field': 'isAllPV', 'data': 'false' }
        ];
        params = [...params, { 'attr': 'prContraNo', 'value': '0' }];
      }
      else if (data === 'Settled') {
        filter = [
          ...filters,
          { 'field': 'rowStatus', 'data': 'ACT' },
          { 'field': 'isPosted', 'data': 'true' },
          { 'field': 'isAllPV', 'data': 'true' }
        ];
      }
    }
    else {
      if (DropDownTitle === 'All') {
        filter = [...filters]
      }
      else if (DropDownTitle === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
      }
      else if (DropDownTitle === 'Active') {
        filter = [
          ...filters,
          { 'field': 'rowStatus', 'data': 'ACT' },
          { 'field': 'isPosted', 'data': '0' },
        ];
        params = [...params, { 'attr': 'prContraNo', 'value': '0' }];
      }
      else if (DropDownTitle === 'Posted') {
        filter = [
          ...filters,
          { 'field': 'rowStatus', 'data': 'ACT' },
          { 'field': 'isPosted', 'data': '1' },
          { 'field': 'isAllPV', 'data': 'false' }
        ];
        params = [...params, { 'attr': 'prContraNo', 'value': '0' }];
      }
      else if (data === 'Settled') {
        filter = [
          ...filters,
          { 'field': 'rowStatus', 'data': 'ACT' },
          { 'field': 'isPosted', 'data': 'true' },
          { 'field': 'isAllPV', 'data': 'true' }
        ];
      }
    }

    ApiService.ListOperationDynamicPostByPage(paramPaymentRequest, JSON.parse(localStorage.getItem('accessFin')) ? 'ACCPOV' : 'PAGE', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.paymentRequests
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          localStorage.setItem('finAccTemp', JSON.stringify(indexed));
          setDataMap(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }

        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const getDataAccess = () => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'OPARE')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            let data = response.data.data.userAccess;
            setDataRegUserAccess(response.data.data.userAccess);

            data.forEach(element => {
              if (element.accessCode === 'LOG') {
                setAccessLog(true);
              }
              if (element.accessCode === 'ACD') {
                setIsAcc(true);
              }
            });

            if (hasAccess) {
              GetDataJobType();
            }
          }
          else if (response.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
            if (isLoadingBackDrop === false) {
              setIsLoading(false);
            }
            else {
              setIsLoadingBackDrop(false);
            }
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error)
          history('/Majura');
        })
    }
    else {
      history('/Majura');
    }
  }

  const getData2 = (pageNumber, pageSize, filters, jobType) => {
    setIsLoading(true);

    let filter;
    let params = [];

    params = [...params, { 'attr': 'jobTypeId', 'value': '' + jobType }];
    if (DropDownTitle === 'All') {
      filter = [...filters]
    }
    else if (DropDownTitle === 'Deleted') {
      filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
    }
    else if (DropDownTitle === 'Active') {
      filter = [
        ...filters,
        { 'field': 'rowStatus', 'data': 'ACT' },
        { 'field': 'isPosted', 'data': '0' },
      ];
      params = [...params, { 'attr': 'prContraNo', 'value': '0' }];
    }
    else if (DropDownTitle === 'Posted') {
      filter = [
        ...filters,
        { 'field': 'rowStatus', 'data': 'ACT' },
        { 'field': 'isPosted', 'data': '1' }
      ];
      params = [...params, { 'attr': 'prContraNo', 'value': '0' }];
    }

    ApiService.ListOperationDynamicPostByPage(paramPaymentRequest, 'PAGE', pageNumber, pageSize, params, filter)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.paymentRequests
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          localStorage.setItem('finAccTemp', JSON.stringify(indexed));
          setDataMap(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          setIsLoading(false)
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
          setIsLoading(false);
        }

        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false);
        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const GetDataJobType = () => {
    ApiService.PostByPage('regJobType/regJobType', 1, 50)
      .then((response) => {
        if (response.data.code === 200) {
          setDataJobType(response.data.data.jobType);

          getData(1, 50, []);
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const handleChange = (e) => {
    localStorage.setItem("jobTypeId", JSON.stringify(e.target.value));
    setJobType(e.target.value);
    getData2(1, 50, filter, e.target.value);
  };

  const handleReset = () => {
    emptyState();
    getData(1, 50, []);
  };

  const handleClick = () => {
    if (isModalDelete === false) {
      setIsModalDelete(true);
      setDataSelected(SelectedData);
    }
    else {
      setIsModalDelete(false);
      setDataSelected(SelectedData);
    }
  };

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
      setDataSelected(SelectedData);
    }
    else {
      setIsModalConfirmation(false);
      setIsDraft(true);
    }
  }

  const handleClickContra = () => {
    if (isModalContra === false) {
      setIsModalContra(true);
      setDataSelected(SelectedData);
    }
    else {
      setIsModalContra(false);
    }
  }

  const handleClickUsage = () => {
    if (isModalUsage === false) {
      setIsModalUsage(true);
    }
    else {
      setIsModalUsage(false);
    }
  }

  const AddPR = () => {
    if (jobType !== '10') {
      history('/Majura/PaymentRequest/Create/' + jobType);
    }
    else {
      showToast({ type: 'error', message: 'Coming Soon!' });
    }
  }

  const EditPR = () => {
    let check = 0;
    DataRegUserAccess.forEach(element => {
      if (element.accessCode === 'REI') {
        check = 1;
      }
    });

    if (check !== 0) {
      if (!SelectedData?.id) {
        showToast({ type: 'error', message: 'Please Select Data!' });
      }
      else {
        if (SelectedData.prContraStatus === '') {
          if (SelectedData.printing > 0) {
            setTitleModalContra('Payment Request');
            handleClickContra();
          }
          else {
            if (jobType !== '10') {
              window.open('/Majura/PaymentRequest/' + jobType + '/' + SelectedData.id, newWindowConfig);
            }
            else {
              showToast({ type: 'error', message: 'Coming Soon!' });
            }
          }
        }
        else {
          showToast({ type: 'error', message: 'Payment Request Contra Can\'t Be Opened!' });
        }
      }
    }
    else {
      showToast({ type: 'error', message: 'You Don\'t Have Access!' });
    }
  }

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data!' });
    emptyState();
    getData(1, 50, []);
  }

  const DeleteData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      if (SelectedData.rowStatus === 'ACT') {
        if (SelectedData.pvId !== 0) {
          setIsLoadingBackDrop(true);
          getDataPV(SelectedData.pvId);
        }
        else {
          if (!SelectedData.approveAcc) {
            setTitleModalDelete('Payment Request');
            setDeleteRemarks('');
            handleClick();
          }
          else {
            showToast({ type: 'error', message: 'Data Already Approved!' });
          }
        }
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted!' });
      }
    }
  }

  const getDataPV = (pvId) => {
    let sequence = 1;
    let tempArray = [];

    ApiService.FinAccPostById('paymentVoucher/paymentVoucher', pvId)
      .then((response) => {
        if (response.data.code === 200) {
          let temp = response.data.data.paymentVoucher;

          tempArray = [
            {
              'sequence': sequence,
              'usedOn': 'Payment Voucher',
              'transactionNumber': temp.reference
            }
          ];

          setUsageDataList(tempArray);
          setTitleModalUsage('Payment Request');
          handleClickUsage();

          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Payment Request Already Used in Payment Voucher!' });
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsLoadingBackDrop(false);

        console.error('error saat fetch', error);
        showToast({ type: 'error', message: error.toString() });
      })
  }

  const DeletePR = () => {
    handleClick();
    setIsLoadingBackDrop(true);

    var oldData = {};
    let isContra = '';
    let detailData = [];
    let etd = '';
    let eta = '';
    let paidUSDDate = '';
    let paidIDRDate = '';
    let deletedDate = '';
    let approveOPRDate = '';
    let approveAccDate = '';
    let printedDate = '';
    let approveDate = '';
    let dateRateEx = '';
    let approveMarketingDate = '';

    ApiService.OperationPostById(paramPaymentRequest, dataSelected.id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data.paymentRequest.printing > 0) {
            isContra = 'Cancel';
          }

          etd = updateDateFormat(response.data.data.paymentRequest.etd, true);
          eta = updateDateFormat(response.data.data.paymentRequest.eta, true);
          paidUSDDate = updateDateFormat(response.data.data.paymentRequest.datePaidUSD, true);
          paidIDRDate = updateDateFormat(response.data.data.paymentRequest.datePaidIDR, true);
          deletedDate = updateDateFormat(new Date(), true);
          approveOPRDate = updateDateFormat(response.data.data.paymentRequest.approveOprOn, true);
          approveAccDate = updateDateFormat(response.data.data.paymentRequest.approveAccOn, true);
          printedDate = updateDateFormat(response.data.data.paymentRequest.printedOn, true);
          approveDate = updateDateFormat(response.data.data.paymentRequest.approvedOn, true);
          approveMarketingDate = updateDateFormat(response.data.data.paymentRequest.approvedMarketingOn, true);
          dateRateEx = updateDateFormat(response.data.data.paymentRequest.exRateDate, true);

          detailData = response.data.data.paymentRequest.paymentRequestDetails;

          detailData.forEach(element => {
            element.fakturDate = updateDateFormat(element.fakturDate, true);
            element.deliveryDate = updateDateFormat(element.deliveryDate, true);
            element.paidOn = updateDateFormat(element.paidOn, true);
          });

          oldData = {
            "paymentRequest": {
              "countryId": countryId,
              "companyId": companyId,
              "branchId": branchId,
              "userId": userId,
              "userCode": userCode,

              "rowStatus": "DEL",
              "id": dataSelected.id,
              "ticketId": response.data.data.paymentRequest.ticketId,
              "prNo": response.data.data.paymentRequest.prNo,
              "debetCredit": response.data.data.paymentRequest.debetCredit,
              "shipmentId": response.data.data.paymentRequest.shipmentId,
              "shipmentNo": response.data.data.paymentRequest.shipmentNo,
              "bookingId": response.data.data.paymentRequest.bookingId,
              "bookingNo": response.data.data.paymentRequest.bookingNo,
              "eplId": response.data.data.paymentRequest.eplId,
              "jobTypeId": response.data.data.paymentRequest.jobTypeId,
              "etd": etd,
              "eta": eta,
              "reference": response.data.data.paymentRequest.reference,
              "prStatus": response.data.data.paymentRequest.prStatus,
              "isGeneral": response.data.data.paymentRequest.isGeneral,
              "customerId": response.data.data.paymentRequest.customerId,
              "customerName": response.data.data.paymentRequest.customerName,
              "customerTypeId": response.data.data.paymentRequest.customerTypeId,
              "personalId": response.data.data.paymentRequest.personalId,
              "personalName": response.data.data.paymentRequest.personalName,
              "paymentUSD": response.data.data.paymentRequest.paymentUSD,
              "paymentIDR": response.data.data.paymentRequest.paymentIDR,
              "prContraStatus": isContra,
              "prContraNo": response.data.data.paymentRequest.prContraNo,
              "paidUSD": response.data.data.paymentRequest.paidUSD,
              "datePaidUSD": paidUSDDate,
              "paidIDR": response.data.data.paymentRequest.paidIDR,
              "datePaidIDR": paidIDRDate,
              "deleted": true,
              "deletedOn": deletedDate,
              "approveOpr": response.data.data.paymentRequest.approveOpr,
              "approveOprOn": approveOPRDate,
              "approveAcc": response.data.data.paymentRequest.approveAcc,
              "approveAccOn": approveAccDate,
              "rate": response.data.data.paymentRequest.rate,
              "exRateDate": dateRateEx,
              "printing": response.data.data.paymentRequest.printing,
              "printedOn": printedDate,
              "prNo2": response.data.data.paymentRequest.prNo2,
              "exRateId": response.data.data.paymentRequest.exRateId,
              "deletedRemarks": DeleteRemarks,
              "isCostToCost": response.data.data.paymentRequest.isCostToCost,
              "totalPpnUSD": response.data.data.paymentRequest.totalPpnUSD,
              "totalPpnIDR": response.data.data.paymentRequest.totalPpnIDR,
              "uniqueKeyPR": response.data.data.paymentRequest.uniqueKeyPR,
              "packingListNo": response.data.data.paymentRequest.packingListNo,
              "siCustomerNo": response.data.data.paymentRequest.siCustomerNo,
              "jobOwnerId": response.data.data.paymentRequest.jobOwnerId,
              "vendorDN": response.data.data.paymentRequest.vendorDN,
              "approved": response.data.data.paymentRequest.approved,
              "approvedOn": approveDate,
              "approvedBy": response.data.data.paymentRequest.approvedBy,
              "approvedRemarks": response.data.data.paymentRequest.approvedRemarks,
              "approvedMarketing": response.data.data.paymentRequest.approvedMarketing,
              "approvedMarketingOn": approveMarketingDate,
              "approvedMarketingBy": response.data.data.paymentRequest.approvedMarketingBy,
              "requester": response.data.data.paymentRequest.requester,

              "idLama": 0,
            },
            "paymentRequestDetails": detailData,
          };

          ApiService.EditOperation(paramPaymentRequest, oldData).then((res) => {
            if (res.data.code === 200) {
              setIsLoadingBackDrop(false);
              if (oldData?.paymentRequest?.prContraStatus === 'Cancel') {
                CreateGL(res.data.data.Id);
              }
              getData(numPage, rowsCount, filter);
              showToast({ type: 'success', message: 'Delete Data Success!' });
              setIsModalDelete(false);
            }
            else {
              setIsLoadingBackDrop(false);
              showToast({ type: 'error', message: `Delete Data Failed! ${res.data.message}` });
              setIsModalDelete(false);
            }
          })
            .catch(function (error) {
              setIsLoadingBackDrop(false);
              // console.error('error saat fetch', error);
            });
        }
      })
      .catch(function (error) {
        setIsLoadingBackDrop(false);
        // console.error('error saat fetch', error);
      })
  }

  function ConfirmationPR() {
    if (titleConfirmation === 'Print') {
      setIsLoadingBackDrop(true);
      if (isDraft === false) {
        let check = 0;
        if (SelectedData.isGeneral === true && SelectedData.approved === false) {
          setIsLoadingBackDrop(false);
          check = 1;
          showToast({ type: 'error', message: 'General PR Need To Be Approved First!' });
        }

        if (check === 0) {
          Print();
        }
      }
      else {
        PrintDataPDF();
      }
    }
    setIsDraft(true);
    setIsModalConfirmation(false);
  }

  const PrintData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      if (SelectedData.rowStatus === 'ACT') {
        setTitleConfirmation('Print')
        setTitleModalConfirmation('Payment Request');
        handleClickConfirmation();
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted!' });
      }
    }
  }

  const Print = () => {
    ApiService.OperationUpdateStatusPrint(paramPaymentRequest, dataSelected.id).then((res) => {
      let message = '';
      message = res.data.message;
      if (res.data.code === 200) {
        if (dataSelected.printing === 0 && isDraft !== true) {
          CreateGL(dataSelected.id);
        }

        PrintDataPDF();
        getData(numPage, rowsCount, filter);
      }
      else {
        showToast({ type: 'error', message: message });
      }
    });
  };

  const PrintDataPDF = () => {
    let reportLink = 'Transactions/Operation/PaymentRequestReport/PaymentRequestReport/PrintPaymentRequest?Id=' + dataSelected.id
      + '&isDraft=' + isDraft + '&isAllJob=' + isAllJob;
    PrintPDF(setIsLoadingBackDrop, 'Payment Request', reportLink);
  }

  const handleApprove = () => {
    window.open('/Majura/PaymentRequest/ApproveAcc', newWindowConfig);
    handleReset();
  }

  const handleUnApprove = () => {
    window.open('/Majura/PaymentRequest/OpenApproveAcc', newWindowConfig);
    handleReset();
  }

  const handleApproveGen = () => {
    window.open('/Majura/PaymentRequest/ApproveGen', newWindowConfig);
    handleReset();
  }

  const CreateGL = (id) => {
    var data = {
      "generalLedger": {
        "source": "PR",
        "refId": id,
        "methodPost": "AUTO",
        "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
      }
    }

    ApiService.CreateFinAcc(paramGL, data).then((res) => {
      let message = '';
      message = res.data.message;
      if (res.data.code !== 200) {
        showToast({ type: 'error', message: message });
      }
    });
  }

  const handleChangePOV = () => {
    if (accessFin) {
      localStorage.setItem('accessFin', JSON.stringify(false));
      setAccessFin(false);
    }
    else {
      localStorage.setItem('accessFin', JSON.stringify(true));
      setAccessFin(true);
    }
    emptyState();
    getData(1, 50, [], []);
  }

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setTotalRows(50);
    setFilter([]);
    setGoTo(1);
    setFilterBy([]);
    setStateX({})
    setSortArr([])
  };

  function CellDoubleClick(row) {
    EditPR();
  };

  function EditContraData() {
    if (dataSelected.pvId === 0) {
      history('/Majura/PaymentRequest/' + true + '/' + jobType + '/' + dataSelected.id);
    }
    else {
      showToast({ type: 'error', message: 'Payment Request Already Used in Payment Voucher!' });
    }
  }

  function ViewOnly() {
    history('/Majura/PaymentRequest/' + false + '/' + jobType + '/' + dataSelected.id);
  }

  const handleClickRePost = () => {
    if (isModalRePost === false) {
      setIsModalRePost(true);
    }
    else {
      setIsModalRePost(false);
    }
  }

  const handleGL = (data) => {
    handleFailedGL({
      id: data.id,
      glStatusCode: data.glStatusCode,
      setIsLoadingBackDrop: setIsLoadingBackDrop,
      paramGL: paramGL,
      setFailedData: setFailedData,
      handleClickRePost: handleClickRePost,
      source: 'PR',
    });
  }

  const handlePost = () => {
    handleRePost({
      handleClickRePost: handleClickRePost,
      setIsLoadingBackDrop: setIsLoadingBackDrop,
      failedData: failedData,
      paramGL: paramGL,
      getData: getData,
      numPage: numPage,
      rowsCount: rowsCount,
      filter: filter,
    })
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const ButtonComponents = () => {
    return (
      <ButtonPaymentRequest
        ReloadData={ReloadData}
        DataRegUserAccess={DataRegUserAccess}
        AddPR={AddPR}
        EditPR={EditPR}
        DeleteData={DeleteData}
        Print={PrintData}
        handleApprove={handleApprove}
        HandleStatus={setFilterData}
        handleApproveGen={handleApproveGen}
        DropDownTitle={DropDownTitle}
        DropDownList={dropDownList}
        handleChangePOV={handleChangePOV}
        accessFin={accessFin}
        handleUnApprove={handleUnApprove}
      />
    )
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter);
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo btnPageRef={btnPageRef}
          title={
            !accessFin ?
              'Payment Request'
              :
              'Payment Request - Accounting'
          }
          isJobType={true}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
          jobTypeId={jobType}
          handleSelect={handleChange}
          dataJobType={dataJobType}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div ref={btnTableRef}>
            {
              ButtonComponent.RefreshButton(
                sortArr,
                rowsCount,
                columnData,
                setGoTo,
                handleReset,
                setFilterBy,
                filterBy,
                setFilter,
                setNumPage,
                setRowsCount,
                getData,
                stateX,
                setStateX
              )
            }
          </div>

          <div
            className="rounded-10 table-responsive"
            style={{
              maxHeight: `calc(100vh - ${getContainerHeight()})`,
              marginTop: '16px',
              padding: '2px 16px',
              borderRadius: '12px',
              background: '#F2F2F2'
            }}
          >
            {
              isLoading ?
                <LoadingSpinner />
                :
                <>
                  <div className='mt-1'>
                    <>
                      {
                        accessLog ?
                          <TableZ
                            rowsCount={rowsCount}
                            source={'PR'}
                            isLoadingBackDrop={isLoadingBackDrop}
                            setIsLoadingBackDrop={setIsLoadingBackDrop}
                            setNumPage={setNumPage}
                            setRowsCount={setRowsCount}
                            getData={getData}
                            columnData={columnData}
                            dataMap={dataMap}
                            setDataMap={setDataMap}
                            SelectedData={SelectedData}
                            setSelectedData={setSelectedData}
                            setFilter={setFilter}
                            setFilterBy={setFilterBy}
                            CellDoubleClick={CellDoubleClick}
                            localVar={'finAccTemp'}
                            stateX={stateX}
                            setStateX={setStateX}
                            sortArr={sortArr}
                            setSortArr={setSortArr}
                            goTo={goTo}
                            setGoTo={setGoTo}
                            handleFailedGL={handleGL}
                          />
                          :
                          <TableX
                            uniqueKey={'id'}
                            rowsCount={rowsCount}
                            setRowsCount={setRowsCount}
                            setNumPage={setNumPage}
                            getData={getData}
                            columnData={columnData}
                            dataMap={dataMap}
                            SelectedData={SelectedData}
                            setSelectedData={setSelectedData}
                            setFilter={setFilter}
                            setFilterBy={setFilterBy}
                            CellDoubleClick={CellDoubleClick}
                            stateX={stateX}
                            setStateX={setStateX}
                            sortArr={sortArr}
                            setSortArr={setSortArr}
                            goTo={goTo}
                            setGoTo={setGoTo}
                            handleFailedGL={handleGL}
                          />
                      }
                    </>
                  </div>
                </>
            }
          </div>

          <div style={{ marginTop: '16px' }}>
            <Paginate
              page={numPage}
              limit={rowsCount}
              totalData={totalRows}
              onChange={onChangePaging}
              onChangeLimit={onChangeLimit}
            />
          </div>
        </Grid>
      </Grid>

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={ConfirmationPR}

            isDraft={isDraft}
            setIsDraft={setIsDraft}
            isAllJob={isAllJob}
            setIsAllJob={setIsAllJob}
          />
        )
      }

      {
        isModalDelete && (
          <ModalDelete
            isModal={isModalDelete}
            titleModal={titleModalDelete}
            handleClick={handleClick}
            DeleteData={DeletePR}
            isRemarks={true}
            DeleteRemarks={DeleteRemarks}
            setDeleteRemarks={setDeleteRemarks}
          />
        )
      }

      {
        isModalContra && (
          <ModalContra
            isModal={isModalContra}
            setIsModal={setIsModalContra}
            handleClick={handleClickContra}
            titleModal={titleModalContra}
            EditContraData={EditContraData}
            ViewOnly={ViewOnly}
            isAcc={isAcc}
          />
        )
      }

      {
        isModalUsage && (
          <ModalUsageData
            isModal={isModalUsage}
            titleModal={titleModalUsage}
            handleClick={handleClickUsage}
            usageDataList={usageDataList}
          />
        )
      }

      {
        isModalRePost && (
          <ModalRePostGL
            isModal={isModalRePost}
            handleRePost={handlePost}
            transactionData={failedData}
            handleClick={handleClickRePost}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </Box>
  )
})

export default ListPaymentRequest;