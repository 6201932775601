import React, { useImperativeHandle, forwardRef } from 'react';
import { branchId, companyId, countryId, formatCurrency, userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, showToast } from '../../../components/ui';
import DollarInput, { convertMask } from '../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../components/Masks/RupiahInput';
import { defaultDate } from '../../../utils/date';

const CreateEditKursKMK = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "kursKMK": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DataKursKMK", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const convertDate = (dt) => {
        if (!dt) return

        const newDate = new Date(dt)
        const formattedDate = newDate.toLocaleDateString("sv-se").split('T')[0]

        return formattedDate
    }

    const validationSchema = Yup.object().shape({
        exRateDate: Yup.string().trim().required('This field is required.'),
        exRate: Yup.string().trim().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? {
            ...selectedData,
            exRateDate: selectedData?.exRateDate ? convertDate(selectedData.exRateDate) : '',
        } : {
            exRateDate: '',
            exRate: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.exRateDate === '') {
            return showToast({ type: 'error', message: 'exRateDate is Required!' });
        }

        if (payload?.exRate === '') {
            return showToast({ type: 'error', message: 'exRate is Required!' });
        }

        const data =
        {
            "kursKMK": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": payload?.id,
                "exRateDate": payload?.exRateDate,
                "exRate": payload?.exRate,
                "deleted": false,
                "deletedOn": defaultDate,
                "deletedBy": 0,
                "userCode": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                type={'date'}
                name="exRateDate"
                label="exRateDate"
                value={formik.values.exRateDate}
                onBlur={formik.handleBlur}
                error={formik.touched.exRateDate && Boolean(formik.errors.exRateDate)}
                helperText={formik.touched.exRateDate && formik.errors.exRateDate}
                onChange={(e) => formik.setFieldValue('exRateDate', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                textAlign='right'
                name="exRate"
                label="exRate"
                onBlur={formik.handleBlur}
                value={formik.values.exRate}
                onChange={(e) => formik.setFieldValue('exRate', formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                helperText={formik.touched.exRate && formik.errors.exRate}
                error={formik.touched.exRate && Boolean(formik.errors.exRate)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                }}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditKursKMK