import { Backdrop, InputAdornment, IconButton } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Input, PopUp, showToast } from '../../../../components/ui';

const ModalChangePassword = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [isPasswordO, setIsPasswordO] = useState(false);
    const [isPasswordN, setIsPasswordN] = useState(false);
    const [isPasswordC, setIsPasswordC] = useState(false);
    const history = useNavigate();

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    const Save = () => {
        setIsLoadingBackDrop(true);
        if (props.NPass === props.RPass) {
            var data =
            {
                "authentication": {
                    "password": props.NPass,
                    "oldPassword": props.CPass
                }
            }
            ApiService.ChangePassword('authentication/authentication', data)
                .then((res) => {
                    if (res.data.code === 200) {
                        showToast({ type: 'success', message: 'Change Password Success, Please Login Again!' });

                        setTimeout(
                            function () {
                                ApiService.logOut();
                                history("/");
                                setIsLoadingBackDrop(false);
                            },
                            1250);
                    }
                    else if (res.data.code === 204) {
                        showToast({ type: 'error', message: res.data.message });
                        setIsLoadingBackDrop(false);
                    }
                    else {
                        showToast({ type: 'error', message: 'Failed To Change Password, Please Try Again Later!, ' + res.data.message });
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch(function (error) {
                    console.error('error', error)
                    showToast({ type: 'error', message: 'Failed To Change Password, Please Try Again Later!, ' + error });
                })
        }
        else {
            showToast({ type: 'error', message: 'Please Match Your New Password with Re-Type Password' });

            setTimeout(
                function () {
                    setIsLoadingBackDrop(false);
                },
                1250);
        }
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                fullWidth
                value={props.CPass}
                label="Current Password"
                type={isPasswordO ? 'text' : 'password'}
                onChange={(e) => props.setCPass(e.target.value)}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setIsPasswordO(!isPasswordO)}>
                                <Icon
                                    iconName={isPasswordO ? 'eye-on' : 'eye-off'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '18px',
                                            width: '18px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                fullWidth
                value={props.NPass}
                label="New Password"
                type={isPasswordN ? 'text' : 'password'}
                onChange={(e) => props.setNPass(e.target.value)}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setIsPasswordN(!isPasswordN)}>
                                <Icon
                                    iconName={isPasswordN ? 'eye-on' : 'eye-off'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '18px',
                                            width: '18px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                fullWidth
                value={props.RPass}
                label="Re-Type New Password"
                type={isPasswordC ? 'text' : 'password'}
                onChange={(e) => props.setRPass(e.target.value)}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setIsPasswordC(!isPasswordC)}>
                                <Icon
                                    iconName={isPasswordC ? 'eye-on' : 'eye-off'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '18px',
                                            width: '18px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </form>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'account'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Update Password'}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelMasterModal(Save, false)}
                    </div>
                </div>
            </PopUp>
            
            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ModalChangePassword