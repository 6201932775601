import {
  Backdrop, Box, Grid,
} from '@mui/material';
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';

import ApiService from '../../../../services/ApiService';
import ButtonEBupotView from './Buttons/ButtonEBupotView';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { showToast } from '../../../../components/ui';

const EBupotView = forwardRef((props, ref) => {
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'E-Bupot Report', link: '/Majura/EBupotReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() {
      initializeView();
    }
  }));

  useEffect(() => {
    document.title = 'E-Bupot Report - ' + JSON.parse(localStorage.getItem('branchName'));
    initializeView();
  }, [])

  function initializeView() {
    setIsLoadingPrint(true);
    setTimeout(function () { setIsLoadingPrint(false); }, 2000);
  }

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
    }
    else {
      setIsModalConfirmation(false);
    }
  }

  function ConfirmationEB() {
    if (titleConfirmation === 'Print PDF') {
      PrintReport()
    }
    setIsModalConfirmation(false);
  }

  const checkInput = () => {
    let check = 0;
    if (check === 1) {
      showToast({ type: 'error', message: 'Please Select Data Customer!' });
    }

    return check;
  }

  const PrintData = () => {
    if (checkInput() === 0) {
      setTitleConfirmation('Print PDF')
      setTitleModalConfirmation('E-Bupot Report');
      handleClickConfirmation();
    }
  };

  const PrintReport = () => {
    setIsLoadingPrint(true)

    let variable = "";
    ApiService.ReportPrint("Reports/Finance/Print/Pph23/EBupotReport/PrintEBupot", variable)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";

          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoadingPrint(false);
          showToast({ type: 'success', message: 'Print E-Bupot Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoadingPrint(false);
          showToast({ type: 'error', message: 'Can\'t Print E-Bupot Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoadingPrint(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print E-Bupot Report, ' + error });
      });
  };

  const ButtonComponents = () => {
    return (
      <ButtonEBupotView PrintData={PrintData} />
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: 3 }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          title={'E-Bupot Report'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />
      </Grid>

      {
        isLoadingPrint && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={ConfirmationEB}
          />
        )
      }
    </Box>
  )
})

export default EBupotView