import React from 'react'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import TableEPL from '../TableEPL/TableEPL';
import { Grid, Stack } from '@mui/material';
import { showToast } from '../../../../components/ui';

const TabIncAgent = (props) => {
  const title = 'Inc Agent'
  const contactTypeName = 'Agent'
  const contactTypeId = 1
  const income = true

  const handleAdd = () => {
    if (props.shipmentNo !== '') {
      props.handleAdd(title, contactTypeName, contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Please Select Shipment Number First!' });
    }
  }

  const handleEdit = () => {
    if (props.selectedDetailIncAgent.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleEdit(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleConvert = () => {
    if (props.selectedDetailIncAgent.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleConvert(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleDoubleClick = () => {
    handleEdit()
  }

  const handleDelete = () => {
    if (props.selectedDetailIncAgent.bookingDetailId === 0) {
      props.handleDelete(contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Can\'t Delete This Data!' });
    }
  }

  return (
    <div className='rounded-10'>
      <TableEPL
        key={3}
        isReadOnly={props.isReadOnly}
        dataList={props.IncAgentList}
        ignoreAccess={props.ignoreAccess}
        setDataList={props.setIncAgentList}
        setSelectedDetail={props.setSelectedDetailIncAgent}
        selectedDetail={props.selectedDetailIncAgent}
        handleDoubleClick={handleDoubleClick}
        contactTypeName={contactTypeName}
        ttlUsd={props.estIncUSDAgent}
        ttlIdr={props.estIncIDRAgent}
        totalSData={props.totalIncSAgent}
        totalNData={props.totalIncNAgent}
        key1={3}
        key2={3}
        type={1}
        openClose={props.openCloseIA}
        setOpenClose={props.setOpenCloseIA}
        openClose2={props.openCloseIA2}
        setOpenClose2={props.setOpenCloseIA2}
        dataDetail={props.dataDetail}
        setDataDetail={props.setDataDetail}
        countSum={props.countSum}
        countTotalData={props.countTotalData}
      />

      <Grid container item direction="row" className='mt-3'>
        <Grid item xs={6}>
          <Stack direction={'row'} spacing={'10px'}>
            {ButtonComponent.AddButton(handleAdd, '', props.isReadOnly)}
            {ButtonComponent.EditButton(handleEdit, '', props.isReadOnly)}
            {ButtonComponent.DeleteButton(handleDelete, '', props.isReadOnly)}
            {ButtonComponent.ConvertButton(handleConvert, props.isReadOnly, true)}
          </Stack>
        </Grid>

        <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'}>
          {ButtonComponent.AddButton(() => props.handleAddHF(title, contactTypeName, contactTypeId, income), 'HF', props.isReadOnly, true)}
          {ButtonComponent.AddButton(() => props.handleAddPS(title, contactTypeName, contactTypeId, income), 'PS', props.isReadOnly, true)}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(TabIncAgent)