import React from "react";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { Icon } from "../ui";

const DocumentInfo = ({ isShow = false, showDocuments = {} }) => {
    return (
        <div
            style={{
                marginTop: '16px',
                borderRadius: '12px',
                border: '2px solid var(--Orange-Outline-1, #FEA51E)',
                background: '#FFF5D7',
                padding: '10px 18px'
            }}
            hidden={!isShow}
        >
            <Grid container item direction="row">
                <Grid item xs={9} display={'flex'}>
                    <Stack direction={'row'} spacing={'16px'} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Icon
                            iconName={'warning'} 
                            svgIconProps={{
                                sx: {
                                    width: '28px',
                                    height: '28px',
                                }
                            }} 
                        />

                        <div>
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fonFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    cursor: 'default',
                                    userSelect: 'none',
                                }}
                            >
                                You have Unapproved Documents. Please check again your documents by click 'See More'.
                            </Typography>
                            
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fonFamily: 'Nunito',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    cursor: 'default',
                                    userSelect: 'none',
                                }}
                            >
                                To Add New Document, please finish the pending documents first.
                            </Typography>
                        </div>
                    </Stack>
                </Grid>
                
                <Grid item xs={3} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        disableFocusRipple = {true}
                        disableRipple = {true}
                        onClick={ () => {showDocuments()} } 
                        sx={{ 
                            alignItems: 'center',
                            padding: '0px !important',
                            '&:hover': {
                                boxShadow: 'none',
                            },
                        }} 
                    >
                        <Stack direction={'row'} spacing={'3px'} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '20px',
                                    letterSpacing: '-0.36px',
                                    'text-decoration-line': 'underline',
                                }}
                            >
                                See More
                            </Typography>

                            <Icon
                                iconName={'arrow-right'} 
                                svgIconProps={{
                                    htmlColor: '#083A50',
                                    sx: {
                                        width: '12px',
                                        height: '12px',
                                    }
                                }} 
                            />
                        </Stack>
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
};

export default DocumentInfo;