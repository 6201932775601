import { Card, CardContent, Tab, Tabs, Typography } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import TabAgent from './TabAgent';
import TabDelivery from './TabDelivery';
import TabTranshipment from './TabTranshipment';
import TabShipper from './TabShipper';
import TabCreditInfo from './TabCreditInfo';
import TabConsignee from './TabConsignee';
import TabNParty from './TabNParty';
import TabBookingParty from './TabBookingParty';

const TabsContact = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <>
            {
                props.contactType === "MainShipmentOrder" ?
                    <Card
                        component="div"
                        className='p-0'
                        style={{
                            display: 'flex',
                            width: '100%',
                            padding: '16px 0px',
                            flexDirection: 'column',
                            gap: '16px',
                            borderRadius: '12px',
                            background: '#FAFAFA',
                        }}
                    >
                        <CardContent>
                            <TabContext value={props.tabValue} className='p-0'>
                                <Tabs
                                    sx={{ width: '100%' }}
                                    value={props.tabValue}
                                    onChange={handleChange}
                                    className='p-0'
                                    textColor="primary"
                                    aria-label="full width tabs example"
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        sx: {
                                            height: 'fit-content !important',
                                            maxHeight: '32px !important',
                                            backgroundColor: "#0087C2 !important",
                                        },
                                    }}
                                    TabScrollButtonProps={{
                                        sx: {
                                            backgroundColor: "#0087C2 !important",
                                            height: 'fit-content !important',
                                            maxHeight: '32px !important',
                                        },
                                    }}
                                >
                                    <Tab
                                        sx={{
                                            padding: '4px 12px !important',
                                            minHeight: '35px',
                                            textTransform: "none",
                                            backgroundColor: '#D7F0FF',
                                            '&.Mui-selected': {
                                                color: '#f2f2f2',
                                                backgroundColor: '#0087C2',
                                            },
                                        }}
                                        value="Agent"
                                        label={
                                            <Typography
                                                sx={{
                                                    color: props.tabValue === "Agent" ? '#F2FAFF' : '#083A50',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Agent
                                            </Typography>
                                        }
                                    />
                                    <Tab
                                        sx={{
                                            padding: '4px 12px !important',
                                            minHeight: '35px',
                                            textTransform: "none",
                                            backgroundColor: '#D7F0FF',
                                            '&.Mui-selected': {
                                                color: '#f2f2f2',
                                                backgroundColor: '#0087C2',
                                            },
                                        }}
                                        value="Delivery"
                                        label={
                                            <Typography
                                                sx={{
                                                    color: props.tabValue === "Delivery" ? '#F2FAFF' : '#083A50',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Delivery
                                            </Typography>
                                        }
                                        hidden={
                                            props.jobType.serviceType === 'Export' && (props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR') ?
                                                false
                                                :
                                                true
                                        }
                                    />
                                    <Tab
                                        sx={{
                                            padding: '4px 12px !important',
                                            minHeight: '35px',
                                            textTransform: "none",
                                            backgroundColor: '#D7F0FF',
                                            '&.Mui-selected': {
                                                color: '#f2f2f2',
                                                backgroundColor: '#0087C2',
                                            },
                                        }}
                                        value="Transhipment"
                                        label={
                                            <Typography
                                                sx={{
                                                    color: props.tabValue === "Transhipment" ? '#F2FAFF' : '#083A50',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Transhipment
                                            </Typography>
                                        }
                                        hidden={props.jobType.portType === 'SEA' ? false : true}
                                    />
                                    <Tab
                                        sx={{
                                            padding: '4px 12px !important',
                                            minHeight: '35px',
                                            textTransform: "none",
                                            backgroundColor: '#D7F0FF',
                                            '&.Mui-selected': {
                                                color: '#f2f2f2',
                                                backgroundColor: '#0087C2',
                                            },
                                        }}
                                        value="Shipper"
                                        label={
                                            <Typography
                                                sx={{
                                                    color: props.tabValue === "Shipper" ? '#F2FAFF' : '#083A50',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Shipper
                                            </Typography>
                                        }
                                    />
                                    <Tab
                                        sx={{
                                            padding: '4px 12px !important',
                                            minHeight: '35px',
                                            textTransform: "none",
                                            backgroundColor: '#D7F0FF',
                                            '&.Mui-selected': {
                                                color: '#f2f2f2',
                                                backgroundColor: '#0087C2',
                                            },
                                        }}
                                        value="Consignee"
                                        label={
                                            <Typography
                                                sx={{
                                                    color: props.tabValue === "Consignee" ? '#F2FAFF' : '#083A50',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Consignee
                                            </Typography>
                                        }
                                    />
                                    <Tab
                                        sx={{
                                            padding: '4px 12px !important',
                                            minHeight: '35px',
                                            textTransform: "none",
                                            backgroundColor: '#D7F0FF',
                                            '&.Mui-selected': {
                                                color: '#f2f2f2',
                                                backgroundColor: '#0087C2',
                                            },
                                        }}
                                        value="NParty"
                                        label={
                                            <Typography
                                                sx={{
                                                    color: props.tabValue === "NParty" ? '#F2FAFF' : '#083A50',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                N'Party
                                            </Typography>
                                        }
                                    />
                                    <Tab
                                        sx={{
                                            padding: '4px 12px !important',
                                            minHeight: '35px',
                                            textTransform: "none",
                                            backgroundColor: '#D7F0FF',
                                            '&.Mui-selected': {
                                                color: '#f2f2f2',
                                                backgroundColor: '#0087C2',
                                            },
                                        }}
                                        value="BookingParty"
                                        label={
                                            <Typography
                                                sx={{
                                                    color: props.tabValue === "BookingParty" ? '#F2FAFF' : '#083A50',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px !important',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                Booking Party
                                            </Typography>
                                        }
                                    />
                                </Tabs>

                                {
                                    props.tabValue === 'Agent' && (
                                        <TabPanel value="Agent" className='p-0'>
                                            <TabAgent
                                                handleClick={props.handleClickC}
                                                setTitleModal={props.setTitleModalC}
                                                setTipe={props.setTipe}
                                                principleBy={props.principleBy}

                                                AgentId={props.AgentId}
                                                AgentName={props.AgentName}
                                                AgentAddress={props.AgentAddress}
                                                setAgentId={props.setAgentId}
                                                setAgentName={props.setAgentName}
                                                setAgentAddress={props.setAgentAddress}

                                                contactTypeId={props.contactTypeId}
                                                setContactTypeId={props.setContactTypeId}
                                                contactType={props.contactType}
                                            />
                                        </TabPanel>
                                    )
                                }

                                {
                                    props.tabValue === 'Delivery' && (
                                        <TabPanel value="Delivery" className='p-0'>
                                            <TabDelivery
                                                handleClick={props.handleClickC}
                                                setTitleModal={props.setTitleModalC}
                                                setTipe={props.setTipe}
                                                principleBy={props.principleBy}

                                                DeliveryId={props.DeliveryId}
                                                setDeliveryId={props.setDeliveryId}
                                                DeliveryName={props.DeliveryName}
                                                setDeliveryName={props.setDeliveryName}
                                                DeliveryAddress={props.DeliveryAddress}
                                                setDeliveryAddress={props.setDeliveryAddress}

                                                contactTypeId={props.contactTypeId}
                                                setContactTypeId={props.setContactTypeId}
                                                contactType={props.contactType}
                                            />
                                        </TabPanel>
                                    )
                                }

                                {
                                    props.tabValue === 'Transhipment' && (
                                        <TabPanel value="Transhipment" className='p-0'>
                                            <TabTranshipment
                                                handleClick={props.handleClickC}
                                                setTitleModal={props.setTitleModalC}
                                                setTipe={props.setTipe}
                                                principleBy={props.principleBy}

                                                TranshipmentId={props.TranshipmentId}
                                                setTranshipmentId={props.setTranshipmentId}
                                                TranshipmentName={props.TranshipmentName}
                                                setTranshipmentName={props.setTranshipmentName}
                                                TranshipmentAddress={props.TranshipmentAddress}
                                                setTranshipmentAddress={props.setTranshipmentAddress}

                                                contactTypeId={props.contactTypeId}
                                                setContactTypeId={props.setContactTypeId}
                                                contactType={props.contactType}
                                            />
                                        </TabPanel>
                                    )
                                }

                                {
                                    props.tabValue === 'Shipper' && (
                                        <TabPanel value="Shipper" className='p-0'>
                                            <TabShipper
                                                jobType={props.jobType}
                                                handleClick={props.handleClickC}
                                                setTitleModal={props.setTitleModalC}
                                                setTipe={props.setTipe}
                                                setTipe2={props.setTipe2}
                                                principleBy={props.principleBy}

                                                ShipperId={props.ShipperId}
                                                setShipperId={props.setShipperId}
                                                ShipperName={props.ShipperName}
                                                setShipperName={props.setShipperName}
                                                ShipperAddress={props.ShipperAddress}
                                                setShipperAddress={props.setShipperAddress}

                                                contactTypeId={props.contactTypeId}
                                                setContactTypeId={props.setContactTypeId}
                                                contactType={props.contactType}
                                                billToShipperId={props.billToShipperId}

                                                creditTermUSD={props.creditTermUSD}
                                                setCreditTermUSD={props.setCreditTermUSD}
                                                creditTermDays={props.creditTermDays}
                                                setCreditTermDays={props.setCreditTermDays}
                                                creditTermIDR={props.creditTermIDR}
                                                setCreditTermIDR={props.setCreditTermIDR}
                                                ApprovedCredit={props.ApprovedCredit}
                                                setApprovedCredit={props.setApprovedCredit}
                                                ApprovedCreditValue={props.ApprovedCreditValue}
                                                setApprovedCreditValue={props.setApprovedCreditValue}
                                                outstandingInIDR={props.outstandingInIDR}
                                                setOutstandingInIDR={props.setOutstandingInIDR}
                                                outstandingInUSD={props.outstandingInUSD}
                                                setOutstandingInUSD={props.setOutstandingInUSD}
                                                freightAmountIDR={props.freightAmountIDR}
                                                setFreightAmountIDR={props.setFreightAmountIDR}
                                                freightAmountUSD={props.freightAmountUSD}
                                                setFreightAmountUSD={props.setFreightAmountUSD}
                                            />

                                            {
                                                props.jobType.serviceType !== 'Import' ?
                                                    <>
                                                        <br></br>
                                                        <TabCreditInfo
                                                            contactTypeId={props.contactTypeId}
                                                            setContactTypeId={props.setContactTypeId}
                                                            jobType={props.jobType}
                                                            handleClick={props.handleClickC}
                                                            setTitleModal={props.setTitleModalC}
                                                            setTipe={props.setTipe}
                                                            setTipe2={props.setTipe2}
                                                            principleBy={props.principleBy}

                                                            ShipperId={props.ShipperId}
                                                            billToShipperId={props.billToShipperId}
                                                            setBillToShipperId={props.setBillToShipperId}
                                                            billShipperName={props.billShipperName}
                                                            setBillShipperName={props.setBillShipperName}

                                                            creditTermUSD={props.creditTermUSD}
                                                            setCreditTermUSD={props.setCreditTermUSD}
                                                            creditTermDays={props.creditTermDays}
                                                            setCreditTermDays={props.setCreditTermDays}
                                                            creditTermIDR={props.creditTermIDR}
                                                            setCreditTermIDR={props.setCreditTermIDR}
                                                            ApprovedCredit={props.ApprovedCredit}
                                                            setApprovedCredit={props.setApprovedCredit}
                                                            ApprovedCreditValue={props.ApprovedCreditValue}
                                                            setApprovedCreditValue={props.setApprovedCreditValue}
                                                            outstandingInIDR={props.outstandingInIDR}
                                                            setOutstandingInIDR={props.setOutstandingInIDR}
                                                            outstandingInUSD={props.outstandingInUSD}
                                                            setOutstandingInUSD={props.setOutstandingInUSD}
                                                            freightAmountIDR={props.freightAmountIDR}
                                                            setFreightAmountIDR={props.setFreightAmountIDR}
                                                            freightAmountUSD={props.freightAmountUSD}
                                                            setFreightAmountUSD={props.setFreightAmountUSD}
                                                        />
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </TabPanel>
                                    )
                                }

                                {
                                    props.tabValue === 'Consignee' && (
                                        <TabPanel value="Consignee" className='p-0'>
                                            <TabConsignee
                                                jobType={props.jobType}
                                                serviceType={props.jobType.serviceType}
                                                handleClick={props.handleClickC}
                                                setTitleModal={props.setTitleModalC}
                                                setTipe={props.setTipe}
                                                setTipe2={props.setTipe2}
                                                principleBy={props.principleBy}

                                                ConsigneeId={props.ConsigneeId}
                                                setConsigneeId={props.setConsigneeId}
                                                ConsigneeName={props.ConsigneeName}
                                                setConsigneeName={props.setConsigneeName}
                                                ConsigneeAddress={props.ConsigneeAddress}
                                                setConsigneeAddress={props.setConsigneeAddress}

                                                contactTypeId={props.contactTypeId}
                                                setContactTypeId={props.setContactTypeId}
                                                contactType={props.contactType}
                                                billToConsigneeId={props.billToConsigneeId}

                                                creditTermUSD={props.creditTermUSD}
                                                setCreditTermUSD={props.setCreditTermUSD}
                                                creditTermDays={props.creditTermDays}
                                                setCreditTermDays={props.setCreditTermDays}
                                                creditTermIDR={props.creditTermIDR}
                                                setCreditTermIDR={props.setCreditTermIDR}
                                                ApprovedCredit={props.ApprovedCredit}
                                                setApprovedCredit={props.setApprovedCredit}
                                                ApprovedCreditValue={props.ApprovedCreditValue}
                                                setApprovedCreditValue={props.setApprovedCreditValue}
                                                outstandingInIDR={props.outstandingInIDR}
                                                setOutstandingInIDR={props.setOutstandingInIDR}
                                                outstandingInUSD={props.outstandingInUSD}
                                                setOutstandingInUSD={props.setOutstandingInUSD}
                                                freightAmountIDR={props.freightAmountIDR}
                                                setFreightAmountIDR={props.setFreightAmountIDR}
                                                freightAmountUSD={props.freightAmountUSD}
                                                setFreightAmountUSD={props.setFreightAmountUSD}
                                            />

                                            {
                                                props.jobType.serviceType === 'Import' ?
                                                    <>
                                                        <br></br>
                                                        <TabCreditInfo
                                                            contactTypeId={props.contactTypeId}
                                                            setContactTypeId={props.setContactTypeId}
                                                            jobType={props.jobType}
                                                            handleClick={props.handleClickC}
                                                            setTitleModal={props.setTitleModalC}
                                                            setTipe={props.setTipe}
                                                            setTipe2={props.setTipe2}
                                                            principleBy={props.principleBy}

                                                            billToConsigneeId={props.billToConsigneeId}
                                                            setBillToConsigneeId={props.setBillToConsigneeId}
                                                            ConsigneeId={props.ConsigneeId}
                                                            billConsigneeName={props.billConsigneeName}
                                                            setBillConsigneeName={props.setBillConsigneeName}

                                                            creditTermUSD={props.creditTermUSD}
                                                            setCreditTermUSD={props.setCreditTermUSD}
                                                            creditTermDays={props.creditTermDays}
                                                            setCreditTermDays={props.setCreditTermDays}
                                                            creditTermIDR={props.creditTermIDR}
                                                            setCreditTermIDR={props.setCreditTermIDR}
                                                            ApprovedCredit={props.ApprovedCredit}
                                                            setApprovedCredit={props.setApprovedCredit}
                                                            outstandingInIDR={props.outstandingInIDR}
                                                            setOutstandingInIDR={props.setOutstandingInIDR}
                                                            outstandingInUSD={props.outstandingInUSD}
                                                            setOutstandingInUSD={props.setOutstandingInUSD}
                                                            freightAmountIDR={props.freightAmountIDR}
                                                            setFreightAmountIDR={props.setFreightAmountIDR}
                                                            freightAmountUSD={props.freightAmountUSD}
                                                            setFreightAmountUSD={props.setFreightAmountUSD}
                                                        />
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </TabPanel>
                                    )
                                }

                                {
                                    props.tabValue === 'NParty' && (
                                        <TabPanel value="NParty" className='p-0'>
                                            <TabNParty
                                                handleClick={props.handleClickC}
                                                setTitleModal={props.setTitleModalC}
                                                setTipe={props.setTipe}
                                                setTipe2={props.setTipe2}
                                                principleBy={props.principleBy}
                                                isSecond={false}

                                                NPartyId={props.NPartyId}
                                                setNPartyId={props.setNPartyId}
                                                NPartyName={props.NPartyName}
                                                setNPartyName={props.setNPartyName}
                                                NPartyAddress={props.NPartyAddress}
                                                setNPartyAddress={props.setNPartyAddress}

                                                contactTypeId={props.contactTypeId}
                                                setContactTypeId={props.setContactTypeId}
                                                contactType={props.contactType}
                                            />
                                        </TabPanel>
                                    )
                                }

                                {
                                    props.tabValue === 'BookingParty' && (
                                        <TabPanel value="BookingParty" className='p-0'>
                                            <TabBookingParty
                                                handleClick={props.handleClickC}
                                                setTitleModal={props.setTitleModalC}
                                                setTipe={props.setTipe}
                                                setTipe2={props.setTipe2}
                                                principleBy={props.principleBy}

                                                BookingPartyId={props.BookingPartyId}
                                                setBookingPartyId={props.setBookingPartyId}
                                                BookingPartyName={props.BookingPartyName}
                                                setBookingPartyName={props.setBookingPartyName}
                                                BookingPartyAddress={props.BookingPartyAddress}
                                                setBookingPartyAddress={props.setBookingPartyAddress}

                                                contactTypeId={props.contactTypeId}
                                                setContactTypeId={props.setContactTypeId}
                                                contactType={props.contactType}
                                            />
                                        </TabPanel>
                                    )
                                }
                            </TabContext>
                        </CardContent>
                    </Card>
                    :
                    props.contactType === "BillOfLading" ?
                        <Card
                            component="div"
                            className='p-0'
                            style={{
                                display: 'flex',
                                width: '100%',
                                padding: '16px 0px',
                                flexDirection: 'column',
                                gap: '16px',
                                borderRadius: '12px',
                                background: '#FAFAFA',
                            }}
                        >
                            <CardContent>
                                <TabContext value={props.tabValue} className='p-0'>
                                    <Tabs
                                        sx={{ width: '100%' }}
                                        value={props.tabValue}
                                        onChange={handleChange}
                                        className='p-0'
                                        textColor="primary"
                                        aria-label="full width tabs example"
                                        variant="fullWidth"
                                        TabIndicatorProps={{
                                            sx: {
                                                height: 'fit-content !important',
                                                maxHeight: '32px !important',
                                                backgroundColor: "#0087C2 !important",
                                            },
                                        }}
                                        TabScrollButtonProps={{
                                            sx: {
                                                backgroundColor: "#0087C2 !important",
                                                height: 'fit-content !important',
                                                maxHeight: '32px !important',
                                            },
                                        }}
                                    >
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="Agent"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "Agent" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Agent
                                                </Typography>
                                            }
                                        />
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="Shipper"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "Shipper" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Shipper
                                                </Typography>
                                            }
                                        />
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="Consignee"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "Consignee" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Consignee
                                                </Typography>
                                            }
                                        />
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value="NParty"
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "NParty" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    N'Party
                                                </Typography>
                                            }
                                        />
                                        <Tab
                                            sx={{
                                                padding: '4px 12px !important',
                                                minHeight: '35px',
                                                textTransform: "none",
                                                backgroundColor: '#D7F0FF',
                                                '&.Mui-selected': {
                                                    color: '#f2f2f2',
                                                    backgroundColor: '#0087C2',
                                                },
                                            }}
                                            value='2NParty'
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: props.tabValue === "2NParty" ? '#F2FAFF' : '#083A50',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px !important',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    Second N'Party
                                                </Typography>
                                            }
                                        />
                                    </Tabs>

                                    {
                                        props.tabValue === 'Agent' && (
                                            <TabPanel value="Agent" className='p-0'>
                                                <TabAgent
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}
                                                    setTipe2={props.setTipe2}
                                                    principleBy={props.principleBy}

                                                    AgentId={props.AgentId}
                                                    setAgentId={props.setAgentId}
                                                    AgentName={props.AgentName}
                                                    setAgentName={props.setAgentName}
                                                    AgentAddress={props.AgentAddress}
                                                    setAgentAddress={props.setAgentAddress}
                                                    RefAgentId={props.RefAgentId}
                                                    RefAgentName={props.RefAgentName}
                                                    RefAgentAddress={props.RefAgentAddress}

                                                    contactTypeId={props.contactTypeId}
                                                    setContactTypeId={props.setContactTypeId}
                                                    contactType={props.contactType}
                                                />
                                            </TabPanel>
                                        )
                                    }

                                    {
                                        props.tabValue === 'Shipper' && (
                                            <TabPanel value="Shipper" className='p-0'>
                                                <TabShipper
                                                    jobType={props.jobType}
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}
                                                    setTipe2={props.setTipe2}
                                                    principleBy={props.principleBy}

                                                    ShipperId={props.ShipperId}
                                                    setShipperId={props.setShipperId}
                                                    ShipperName={props.ShipperName}
                                                    setShipperName={props.setShipperName}
                                                    ShipperAddress={props.ShipperAddress}
                                                    setShipperAddress={props.setShipperAddress}
                                                    RefShipperId={props.RefShipperId}
                                                    RefShipperName={props.RefShipperName}
                                                    RefShipperAddress={props.RefShipperAddress}

                                                    contactTypeId={props.contactTypeId}
                                                    setContactTypeId={props.setContactTypeId}
                                                    contactType={props.contactType}
                                                />
                                            </TabPanel>
                                        )
                                    }

                                    {
                                        props.tabValue === 'Consignee' && (
                                            <TabPanel value="Consignee" className='p-0'>
                                                <TabConsignee
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}
                                                    setTipe2={props.setTipe2}
                                                    principleBy={props.principleBy}

                                                    ConsigneeId={props.ConsigneeId}
                                                    setConsigneeId={props.setConsigneeId}
                                                    ConsigneeName={props.ConsigneeName}
                                                    setConsigneeName={props.setConsigneeName}
                                                    ConsigneeAddress={props.ConsigneeAddress}
                                                    setConsigneeAddress={props.setConsigneeAddress}
                                                    RefConsigneeId={props.RefConsigneeId}
                                                    RefConsigneeName={props.RefConsigneeName}
                                                    RefConsigneeAddress={props.RefConsigneeAddress}

                                                    contactTypeId={props.contactTypeId}
                                                    setContactTypeId={props.setContactTypeId}
                                                    contactType={props.contactType}
                                                />
                                            </TabPanel>
                                        )
                                    }

                                    {
                                        props.tabValue === 'NParty' && (
                                            <TabPanel value="NParty" className='p-0'>
                                                <TabNParty
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}
                                                    setTipe2={props.setTipe2}
                                                    principleBy={props.principleBy}
                                                    isSecond={false}

                                                    NPartyId={props.NPartyId}
                                                    setNPartyId={props.setNPartyId}
                                                    NPartyName={props.NPartyName}
                                                    setNPartyName={props.setNPartyName}
                                                    NPartyAddress={props.NPartyAddress}
                                                    setNPartyAddress={props.setNPartyAddress}
                                                    RefNPartyId={props.RefNPartyId}
                                                    RefNPartyName={props.RefNPartyName}
                                                    RefNPartyAddress={props.RefNPartyAddress}

                                                    contactTypeId={props.contactTypeId}
                                                    setContactTypeId={props.setContactTypeId}
                                                    contactType={props.contactType}
                                                />
                                            </TabPanel>
                                        )
                                    }

                                    {
                                        props.tabValue === '2NParty' && (
                                            <TabPanel value="2NParty" className='p-0'>
                                                <TabNParty
                                                    handleClick={props.handleClick}
                                                    setTitleModal={props.setTitleModal}
                                                    setTipe={props.setTipe}
                                                    setTipe2={props.setTipe2}
                                                    principleBy={props.principleBy}
                                                    isSecond={true}

                                                    NPartyId={props.SNPartyId}
                                                    setNPartyId={props.setSNPartyId}
                                                    NPartyName={props.SNPartyName}
                                                    setNPartyName={props.setSNPartyName}
                                                    NPartyAddress={props.SNPartyAddress}
                                                    setNPartyAddress={props.setSNPartyAddress}

                                                    contactTypeId={props.contactTypeId}
                                                    setContactTypeId={props.setContactTypeId}
                                                    contactType={props.contactType}
                                                />
                                            </TabPanel>
                                        )
                                    }
                                </TabContext>
                            </CardContent>
                        </Card>
                        :
                        props.contactType === "ShippingInstruction" ?
                            <Card
                                component="div"
                                className='p-0'
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    padding: '16px 0px',
                                    flexDirection: 'column',
                                    gap: '16px',
                                    borderRadius: '12px',
                                    background: '#FAFAFA',
                                }}
                            >
                                <CardContent>
                                    <TabContext value={props.tabValue} className='p-0'>
                                        <Tabs
                                            sx={{ width: '100%' }}
                                            value={props.tabValue}
                                            onChange={handleChange}
                                            className='p-0'
                                            textColor="primary"
                                            aria-label="full width tabs example"
                                            variant="fullWidth"
                                            TabIndicatorProps={{
                                                sx: {
                                                    height: 'fit-content !important',
                                                    maxHeight: '32px !important',
                                                    backgroundColor: "#0087C2 !important",
                                                },
                                            }}
                                            TabScrollButtonProps={{
                                                sx: {
                                                    backgroundColor: "#0087C2 !important",
                                                    height: 'fit-content !important',
                                                    maxHeight: '32px !important',
                                                },
                                            }}
                                        >
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Shipper"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Shipper" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Shipper
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="Consignee"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "Consignee" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        Consignee
                                                    </Typography>
                                                }
                                            />
                                            <Tab
                                                sx={{
                                                    padding: '4px 12px !important',
                                                    minHeight: '35px',
                                                    textTransform: "none",
                                                    backgroundColor: '#D7F0FF',
                                                    '&.Mui-selected': {
                                                        color: '#f2f2f2',
                                                        backgroundColor: '#0087C2',
                                                    },
                                                }}
                                                value="NParty"
                                                label={
                                                    <Typography
                                                        sx={{
                                                            color: props.tabValue === "NParty" ? '#F2FAFF' : '#083A50',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px !important',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        N'Party
                                                    </Typography>
                                                }
                                            />
                                        </Tabs>

                                        {
                                            props.tabValue === 'Shipper' && (
                                                <TabPanel value="Shipper" className='p-0'>
                                                    <TabShipper
                                                        jobType={props.jobType}
                                                        handleClick2={props.handleClick}
                                                        setTitleModal2={props.setTitleModal}
                                                        setTipe2={props.setTipe2}
                                                        principleBy={props.principleBy}

                                                        SICompanyId={props.SICompanyId}
                                                        setSICompanyId={props.setSICompanyId}
                                                        SICompanyName={props.SICompanyName}
                                                        setSICompanyName={props.setSICompanyName}
                                                        SICompanyAddress={props.SICompanyAddress}
                                                        setSICompanyAddress={props.setSICompanyAddress}

                                                        contactTypeId={props.contactTypeId}
                                                        setContactTypeId={props.setContactTypeId}
                                                        contactType={props.contactType}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'Consignee' && (
                                                <TabPanel value="Consignee" className='p-0'>
                                                    <TabConsignee
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}
                                                        setTipe2={props.setTipe2}
                                                        principleBy={props.principleBy}

                                                        ConsigneeId={props.ConsigneeId}
                                                        setConsigneeId={props.setConsigneeId}
                                                        ConsigneeName={props.ConsigneeName}
                                                        setConsigneeName={props.setConsigneeName}
                                                        ConsigneeAddress={props.ConsigneeAddress}
                                                        setConsigneeAddress={props.setConsigneeAddress}
                                                        RefConsigneeId={props.RefConsigneeId}
                                                        RefConsigneeName={props.RefConsigneeName}
                                                        RefConsigneeAddress={props.RefConsigneeAddress}

                                                        contactTypeId={props.contactTypeId}
                                                        setContactTypeId={props.setContactTypeId}
                                                        contactType={props.contactType}
                                                    />
                                                </TabPanel>
                                            )
                                        }

                                        {
                                            props.tabValue === 'NParty' && (
                                                <TabPanel value="NParty" className='p-0'>
                                                    <TabNParty
                                                        handleClick={props.handleClick}
                                                        setTitleModal={props.setTitleModal}
                                                        setTipe={props.setTipe}
                                                        setTipe2={props.setTipe2}
                                                        principleBy={props.principleBy}
                                                        isSecond={false}

                                                        NPartyId={props.NPartyId}
                                                        setNPartyId={props.setNPartyId}
                                                        NPartyName={props.NPartyName}
                                                        setNPartyName={props.setNPartyName}
                                                        NPartyAddress={props.NPartyAddress}
                                                        setNPartyAddress={props.setNPartyAddress}
                                                        RefNPartyId={props.RefNPartyId}
                                                        RefNPartyName={props.RefNPartyName}
                                                        RefNPartyAddress={props.RefNPartyAddress}

                                                        contactTypeId={props.contactTypeId}
                                                        setContactTypeId={props.setContactTypeId}
                                                        contactType={props.contactType}
                                                    />
                                                </TabPanel>
                                            )
                                        }
                                    </TabContext>
                                </CardContent>
                            </Card>
                            :
                            <></>
            }
        </>
    )
}

export default TabsContact;