import React from 'react';
import { Grid } from '@mui/material';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonListPaymentPPh23 = ({
  ReloadData,
  DataRegUserAccess,
  Add,
  Edit,
  DeleteData,
  PrintData,
  RePrint,
  HandleStatus,
  DropDownTitle
}) => {

  const renderButton = (data) => {
    switch (data.accessCode) {
      case 'CRE':
        return ButtonComponent.AddButton(Add, 'New');
      case 'REI':
        return ButtonComponent.EditButton(Edit, 'Data');
      case 'DEL':
        return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data') : null;
      case 'PRI':
        return DropDownTitle !== 'Deleted' ? ButtonComponent.PrintButton(PrintData, 'Rekap') : null;
      case 'APC':
        return DropDownTitle !== 'Deleted' ? ButtonComponent.ReprintButton(RePrint, 'Approval') : null;
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
          {ButtonComponent.ReloadButton(ReloadData)}
          {DataRegUserAccess.map(data => (
            <React.Fragment key={data.accessId}>
              {renderButton(data)}
            </React.Fragment>
          ))}
          {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonListPaymentPPh23;