import { IconButton, Stack, Grid, InputAdornment } from '@mui/material';
import React from 'react';
import { Icon, Input } from '../../../../components/ui';

const TabMasterAirWayBill = (props) => {
    const searchIATA = (data) => {
        if (data === 'search') {
            props.setTipeC(50);
            props.setTitleModalC('IATA');
            props.handleClickC(50);
        }
        else if (data === 'delete') {
            props.setIATAId(0);
            props.setIATAName('');
        }
    };

    const searchEMKL = (data) => {
        if (data === 'search') {
            props.setTipeC(6);
            props.setTitleModalC('EMKL');
            props.handleClickC(6);
        }
        else if (data === 'delete') {
            props.setBrokerId(0);
            props.setBrokerName('');
        }
    };

    return (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Master Airway Bill No."
                        value={props.MAWBNo}
                        onChange={(e) => props.setMAWBNo(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '12px !important'
                            }
                        }}
                    />

                    <Input
                        label="Chargeable Weight"
                        textAlign='right'
                        value={props.ChargeableWeight}
                        onChange={(e) => props.setChargeableWeight(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="House Airway Bill No."
                        value={props.HAWBNo}
                        onChange={(e) => props.setHAWBNo(e.target.value)}
                        fullWidth
                        hidden={props.jobType.serviceType === 'Import' ? false : true}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '12px !important'
                            }
                        }}
                    />

                    <Input
                        label="Weight HAWB"
                        textAlign='right'
                        value={props.WeightHAWB}
                        onChange={(e) => props.setWeightHAWB(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: props.jobType.serviceType === 'Import' ? '16px !important' : '12px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Air Line"
                        value={props.airLineName}
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                        <Input
                            label="IATA Agent"
                            value={props.IATAId}
                            disabled
                            formControlProps={{
                                sx: {
                                    marginTop: '24px'
                                }
                            }}
                        />

                        <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.IATAName}
                            type='text'
                            label={''}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchIATA('delete')}
                                            hidden={props.IATAName ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => searchIATA('search')}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            disabled
                        />
                    </Stack>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Value for Carriage"
                        value={props.CarriageValue}
                        onChange={(e) => props.setCarriageValue(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '12px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Value for Custom"
                        value={props.CustomValue}
                        onChange={(e) => props.setCustomValue(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '12px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                <Input
                    label="Customer Broker"
                    value={props.BrokerId}
                    disabled
                    formControlProps={{
                        sx: {
                            marginTop: '24px'
                        }
                    }}
                />

                <Input
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={props.BrokerName}
                    type='text'
                    label={''}
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                    inputBaseProps={{
                        sx: {
                            marginTop: '23px !important',
                        },
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => searchEMKL('delete')}
                                    hidden={props.BrokerName ? false : true}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton onClick={() => searchEMKL('search')}>
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled
                />
            </Stack>
        </>
    )
}

export default TabMasterAirWayBill;