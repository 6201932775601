import { Grid } from '@mui/material';
import React, { useState } from 'react';

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableCheck from '../../../../../components/Tables/TableCheck';
import { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';
import { defaultDate } from '../../../../../utils/date';

const SelectDataInvoice = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [dataCheck, setDataCheck] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setDataCheck([]);
    };

    function Select() {
        if (dataCheck.length === 0) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let check = 0;
            let dataArr = dataCheck;
            let dataArrId = props.invoiceIdList;

            dataArr.forEach(element => {
                dataArrId.forEach(el => {
                    if (element.id === el) {
                        check = 1;
                    }
                });
            });

            if (check === 0) {
                let Sequence = 1;
                let orId = 0;

                if (props.InvoiceList.length > 0) {
                    let index = parseInt(props.InvoiceList.length) - parseInt(1);
                    Sequence = parseInt(props.InvoiceList[index].sequence) + parseInt(1);
                }

                if (!props.officialReceiptId) {
                    orId = props.officialReceiptId;

                    if (orId === undefined) {
                        orId = 0;
                    }
                }
                else {
                    orId = 0;
                }

                if (dataCheck.length > 0) {
                    let arrayData = props.InvoiceList;
                    let arrayIdData = props.invoiceIdList;
                    dataCheck.forEach(element => {
                        let data = {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "userId": userId,
                            "userCode": userCode,

                            "rowStatus": "ACT",
                            "sequence": Sequence,
                            "orId": orId,
                            "id": 0,
                            "invoiceId": element.id,
                            "invoiceNo": element.invoiceNo2.toString(),
                            "shipmentId": element.shipmentId,
                            "shipmentNo": element.shipmentNo,
                            "paymentUSD": element.totalPaymentUSD,
                            "paymentIDR": element.totalPaymentIDR,
                            "paymentNetUSD": element.paymentUSD,
                            "paymentNetIDR": element.paymentIDR,
                            "totalCashUSD": 0,
                            "totalCashIDR": 0,
                            "totalBankUSD": 0,
                            "totalBankIDR": 0,
                            "totalVATIDR": element.totalVatIDR,
                            "totalVATUSD": element.totalVatUSD,
                            "stampDuty": element.stampDutyAmount,
                            "rate": element.rate,
                            "exRateDate": element.exRateDate,
                            "exRateId": element.exRateId,
                            "isPPh23": 0,
                            "pPh23Amount": 0,
                            "pPh23Remarks": "",
                            "isSettlePPh23": false,
                            "isSettlePPh23On": defaultDate,
                            "idLama": 0,
                            "officialReceiptInvoiceBanks": [],
                            "officialReceiptInvoiceTRs": [],
                            "officialReceiptInvoiceRVs": [],

                        };

                        arrayData = [...arrayData, data];

                        arrayIdData = [...arrayIdData, data.invoiceId];

                        Sequence = Sequence + 1;
                    });

                    props.setInvoiceList(arrayData);
                    props.setInvoiceIdList(arrayIdData);
                    props.setCheckedData(dataCheck);

                    props.calculateTotalInvoice(arrayData);
                    props.calculateTotalTR(arrayData);
                    props.calculateTotalRV(arrayData);
                    props.calculateTotalCash(arrayData);
                    props.calculateTotalBank(arrayData);
                    props.calculateTotalDifferent(arrayData);
                }
                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
            else {
                showToast({ type: 'error', message: 'Data Invoice Already Selected!' });
            }
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableCheck 
                                            uniqueKey={'id'}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            dataCheck={dataCheck}
                                            setDataCheck={setDataCheck}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                            goTo={goTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={Cancel}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'lg'}
                fullWidth={true}
            >
                <div style={{ padding: '0px 6px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        {CustomizeButtonComponent.SelectModalButton(Select)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default SelectDataInvoice;