import React, { useState } from 'react'
import { Box, Stack, Typography, Button, FormGroup, FormControlLabel, FormControl, RadioGroup, FormLabel, Radio, Checkbox } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

const BoxContainer = ({ containerTitle, children }) => {
  return (
    <Box>
      <Typography fontWeight={700}>{containerTitle}</Typography>
      <Grid2
        container
        rowSpacing={2}
        columnSpacing={2}
        mt={1}
      >
        {children}
      </Grid2>
    </Box>
  )
}

const MasterButton = () => {
  const colorList = ['inherit', 'primary', 'secondary', 'success', 'error', 'warning']
  const sizeList = ['small', 'medium', 'large']
  const variantList = ['contained', 'outlined', 'text']

  const [activeVariant, setActiveVariant] = useState('contained')
  const [isDisabled, setisDisabled] = useState(false)

  return (
    <Stack direction={'column'} gap={3}>
      <FormGroup>
        <FormControl>
          <FormLabel id="variant-option">Variant</FormLabel>
          <RadioGroup
            row
            aria-labelledby="variant-option"
            name="variant"
            value={activeVariant}
            onChange={(e) => setActiveVariant(e.target.value)}
          >
            {variantList.map((variant, index) => (
              <FormControlLabel
                key={index}
                value={variant}
                control={<Radio />}
                label={variant}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox checked={isDisabled} onChange={() => setisDisabled(!isDisabled)} />
          }
          label="Disabled"
        />
      </FormGroup>
      <BoxContainer containerTitle={'COLOR'}>
        {colorList.map((color, index) => {
          return (
            <Grid2 key={index} xs={3}>
              <Button
                variant={activeVariant}
                disabled={isDisabled}
                color={color}
                fullWidth
              >
                {color}
              </Button>
            </Grid2>
          )
        })}
      </BoxContainer>

      <BoxContainer containerTitle={'SIZE'}>
        {sizeList.map((size, index) => {
          return (
            <Grid2 key={index} xs={3}>
              <Button
                variant={activeVariant}
                disabled={isDisabled}
                size={size}
                fullWidth
              >
                {size}
              </Button>
            </Grid2>
          )
        })}
      </BoxContainer>
    </Stack>
  )
}

export default MasterButton