import React, { useState } from 'react'
import { Box, InputAdornment, Stack, Typography } from '@mui/material'

import { useDebounce } from '../../../utils/useDebounce'

import { Icon, Input } from '../../../components/ui'
import { icons } from '../../../Assets/icons'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

const colorList = [
  'inherit',
  'action',
  'disabled',
  'primary',
  'secondary',
  'error',
  'info',
  'success',
  'warning'
]

const MasterIcon = () => {
  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useDebounce(keyword, 500);

  return (
    <Stack direction={'column'} gap={3}>
      <Box>
        <Stack direction={'row'} gap={2} justifyContent={'space-between'} alignItems={'center'}>
          <Typography fontWeight={700}>VARIANT</Typography>
          <Input
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder={'Search'}
            inputBaseProps={{
              endAdornment: <InputAdornment position="end">
                <Icon iconName={'search'} svgIconProps={{ htmlColor: '#26549F' }} />
              </InputAdornment>
            }}
          />
        </Stack>
        <Box
          mt={3}
          height={300}
          border={'1px solid #CACED8'}
          borderRadius={2}
          py={2}
          sx={{
            overflowX: 'hidden'
          }}
        >
          <Grid2
            container
            rowSpacing={3}
            columnSpacing={2}
          >
            {Object.keys(icons).map((key) => {
              return (
                <Grid2
                  key={key}
                  xs={2.4}
                  display={
                    debouncedKeyword
                      ? key.toLowerCase().includes(debouncedKeyword.toLowerCase())
                        ? 'block'
                        : 'none'
                      : 'block'
                  }
                >
                  <Stack
                    direction={'column'}
                    alignItems={'center'}
                    gap={1}
                  >
                    <Icon
                      iconName={key}
                      svgIconProps={{
                        fontSize: 'large',
                        htmlColor: '#26549F',
                        titleAccess: key
                      }}
                    />
                    <Typography fontSize={12}>{key}</Typography>
                  </Stack>
                </Grid2>
              )
            })}
          </Grid2>
        </Box>
      </Box>
      <Box>
        <Typography fontWeight={700}>COLOR</Typography>
        <Grid2
          container
          rowSpacing={3}
          columnSpacing={2}
          mt={2}
        >
          {colorList.map((color) => {
            return (
              <Grid2 xs={2.4} key={color}>
                <Stack
                  direction={'column'}
                  alignItems={'center'}
                  textAlign={'center'}
                  gap={1}
                >
                  <Icon
                    iconName={'add'}
                    svgIconProps={{
                      fontSize: 'large',
                      color: color,
                    }}
                  />
                  <Typography fontSize={12}>{color}</Typography>
                </Stack>
              </Grid2>
            )
          })}
          <Grid2 xs={2.4}>
            <Stack
              direction={'column'}
              alignItems={'center'}
              textAlign={'center'}
              gap={1}
            >
              <Icon
                iconName={'add'}
                svgIconProps={{
                  fontSize: 'large',
                  htmlColor: '#FCCE32',
                }}
              />
              <Typography fontSize={12}>{'#FCCE32 using htmlColor'}</Typography>
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </Stack>
  )
}

export default MasterIcon