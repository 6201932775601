import { Grid } from '@mui/material';
import React, { useState } from 'react'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const SelectDataNonContact = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        props.setTitleModal('');
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    const getCountryName = (countryId) => {
        let param = 'country/country';
        ApiService.PostById(param, countryId).then((response) => {
            if (response.data.code === 200) {
                var result = response.data.data.country;
                props.setCountryName(result.name);
            }
        })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (props.titleModal === 'Place Of Receipt') {
                props.setReceiptPlaceId(props.SelectedData.id);
                props.setReceiptPlaceCode(props.SelectedData.code);
                props.setReceiptPlaceName(props.SelectedData.name);
                props.setTempReceiptPlaceName(props.SelectedData.name);
                props.setBLReceiptPlaceName(props.SelectedData.name);
            }
            else if (props.titleModal === 'Port Of Discharge') {
                props.setDischargePortId(props.SelectedData.id);
                props.setDischargePortCode(props.SelectedData.code);
                props.setDischargePortName(props.SelectedData.name);
                props.setTempDischargePortName(props.SelectedData.name);
                props.setBLDischargePortName(props.SelectedData.name);
            }
            else if (props.titleModal === 'Port Of Loading') {
                props.setLoadingPortId(props.SelectedData.id);
                props.setLoadingPortCode(props.SelectedData.code);
                props.setLoadingPortName(props.SelectedData.name);
                props.setTempLoadingPortName(props.SelectedData.name);
                props.setBLLoadingPortName(props.SelectedData.name);
            }
            else if (props.titleModal === 'Place Of Delivery') {
                props.setDeliveryPlaceId(props.SelectedData.id);
                props.setDeliveryPlaceCode(props.SelectedData.code);
                props.setDeliveryPlaceName(props.SelectedData.name);
                props.setTempDeliveryPlaceName(props.SelectedData.name);
                props.setBLDeliveryPlaceName(props.SelectedData.name);

                if (props.jobType.portType !== 'AIR') {
                    if (props.jobType.portType === 'SEA') {
                        props.setFreightCollectIdOBL(props.SelectedData.id);
                        props.setFreightCollectCodeOBL(props.SelectedData.code);
                        props.setFreightCollectNameOBL(props.SelectedData.name);
                    }

                    props.setFreightCollectIdHBL(props.SelectedData.id);
                    props.setFreightCollectCodeHBL(props.SelectedData.code);
                    props.setFreightCollectNameHBL(props.SelectedData.name);
                }
                else {
                    props.setMAWBCollectId(props.SelectedData.id);
                    props.setMAWBCollectCode(props.SelectedData.code);
                    props.setMAWBCollectName(props.SelectedData.name);

                    props.setHAWBCollectId(props.SelectedData.id);
                    props.setHAWBCollectCode(props.SelectedData.code);
                    props.setHAWBCollectName(props.SelectedData.name);
                }
            }
            else if (props.titleModal === 'Freight Collect') {
                if (props.tipe === 1) {
                    if (props.jobType.portType === 'SEA') {
                        props.setFreightCollectIdOBL(props.SelectedData.id);
                        props.setFreightCollectCodeOBL(props.SelectedData.code);
                        props.setFreightCollectNameOBL(props.SelectedData.name);
                    }
                    else {
                        props.setMAWBCollectId(props.SelectedData.id);
                        props.setMAWBCollectCode(props.SelectedData.code);
                        props.setMAWBCollectName(props.SelectedData.name);
                    }
                }
                else if (props.tipe === 2) {
                    if (props.jobType.portType === 'AIR') {
                        props.setHAWBCollectId(props.SelectedData.id);
                        props.setHAWBCollectCode(props.SelectedData.code);
                        props.setHAWBCollectName(props.SelectedData.name);
                    }
                    else {
                        props.setFreightCollectIdHBL(props.SelectedData.id);
                        props.setFreightCollectCodeHBL(props.SelectedData.code);
                        props.setFreightCollectNameHBL(props.SelectedData.name);
                    }
                }
            }
            else if (props.titleModal === 'BL') {
                props.setBLFormId(props.SelectedData.id);
                // props.setBLFormCode(props.SelectedData.abbreviation);
                props.setBLFormName(props.SelectedData.name);
            }
            else if (props.titleModal === 'Marketing') {
                props.setMarketId(props.SelectedData.id);
                props.setMarketName(props.SelectedData.name);
            }
            else if (props.titleModal === 'Vessel') {
                props.setVesselId(props.SelectedData.id);
                props.setVesselName(props.SelectedData.name);
            }
            else if (props.titleModal === 'Flight') {
                props.setFlightId(props.SelectedData.id);
                props.setFlightCode(props.SelectedData.abbreviation);
                props.setFlightName(props.SelectedData.name);
            }
            else if (props.titleModal === 'City') {
                if (props.tipe === 3) {
                    props.setCityId(props.SelectedData.id);
                    props.setCityCode(props.SelectedData.code);
                    props.setCityName(props.SelectedData.name);
                }
                else if (props.tipe === 4) {
                    props.setCityId(props.SelectedData.id);
                    props.setCityCode(props.SelectedData.code);
                    props.setCityName(props.SelectedData.name);

                    getCountryName(props.SelectedData.countryId);
                }
            }
            else if (props.titleModal === 'Company') {
                props.setSICompanyId(props.SelectedData.id);
                props.setSICompanyName(props.SelectedData.name);
                props.setSICompanyAddress(props.SelectedData.address);
            }
            else if (props.titleModal === 'AirPort of Destination') {
                props.setDestinationAirPortId(props.SelectedData.id);
                props.setDestinationAirPortCode(props.SelectedData.abbreviation);
                props.setDestinationAirportName(props.SelectedData.airPortName);
                props.setTempDischargePortName(props.SelectedData.name);
                props.setBLDischargePortName(props.SelectedData.name);
            }
            else if (props.titleModal === 'AirPort of Departure') {
                props.setDepartureAirPortId(props.SelectedData.id);
                props.setDepartureAirPortCode(props.SelectedData.abbreviation);
                props.setDepartureAirPortName(props.SelectedData.airPortName);
                props.setTempLoadingPortName(props.SelectedData.name);
                props.setBLLoadingPortName(props.SelectedData.name);
            }
            else if (props.titleModal === 'AirPort') {
                if (props.tipe === 5) {
                    props.setAirportFromId(props.SelectedData.id);
                    props.setAirportFromCode(props.SelectedData.abbreviation);
                    if (props.SelectedData.airPortName !== undefined) {
                        props.setAirportFromName(props.SelectedData.airPortName);
                    }

                    props.setCityFromId(props.SelectedData.cityId);
                    props.setCityFromCode(props.SelectedData.cityCode);
                    props.setCityFromName(props.SelectedData.cityName);
                }
                else if (props.tipe === 6) {
                    props.setAirportToId(props.SelectedData.id);
                    props.setAirportToCode(props.SelectedData.abbreviation);
                    if (props.SelectedData.airPortName !== undefined) {
                        props.setAirportToName(props.SelectedData.airPortName);
                    }

                    props.setCityToId(props.SelectedData.cityId);
                    props.setCityToCode(props.SelectedData.cityCode);
                    props.setCityToName(props.SelectedData.cityName);
                }
                else if (props.tipe === 7) {
                    props.setPortId(props.SelectedData.id);
                    props.setPortCode(props.SelectedData.abbreviation);
                    if (props.SelectedData.airPortName !== undefined) {
                        props.setPortName(props.SelectedData.airPortName);
                    }
                    else {
                        props.setPortName('');
                    }
                }
            }
            else if (props.titleModal === 'Port') {
                props.setPortId(props.SelectedData.id);
                props.setPortCode(props.SelectedData.code);
                props.setPortName(props.SelectedData.name);
            }
        }

        emptyState();

        props.handleClick();
        props.setIsModal(false);
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + JSON.parse(localStorage.getItem("title"))}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataNonContact;