import React, { useImperativeHandle } from 'react'
import { Box, CardContent, Grid, Card } from '@mui/material';
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService, { branchId, companyId, countryId, userCode, userId, username } from '../../../../services/ApiService'
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ButtonCreateEditReceiptPPh23 from './Component/ButtonCreateEditReceiptPPh23'
import ReceiptPPh23Detail from './Content/ReceiptPPh23Detail'
import ReceiptPPh23Header from './Content/ReceiptPPh23Header'
import ReceiptPPh23Total from './Content/ReceiptPPh23Total'
import { forwardRef } from 'react';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { defaultDate, setDateFormat } from '../../../../utils/date';
import { convertToNominal } from '../../../../utils/masks.jsx';
import CustomToastContainer, { showToast } from '../../../../components/ui';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation.jsx';

const CreateEditReceiptPPh23 = forwardRef((props, ref) => {
  const dataLocal = JSON.parse(localStorage.getItem(username + "-DataRPPH23"));

  const { orPphId } = useParams()

  const history = useNavigate()

  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [check, setCheck] = useState(0);

  const param = 'officialReceiptPph23/officialReceiptPph23'
  const paramGL = 'generalLedger/generalLedger';
  const [dataDetail, setDataDetail] = useState([])
  const [selectedDetail, setSelectedDetail] = useState({})
  const [dataJobOwner, setDataJobOwner] = useState([])

  const [reference, setReference] = useState('')
  const [buktiPotong, setBuktiPotong] = useState('')
  const [jobOwnerId, setJobOwnerId] = useState(0)
  const [jobOwnerCode, setJobOwnerCode] = useState('')
  const [jobOwnerName, setJobOwnerName] = useState('')
  const [customerId, setCustomerId] = useState(0)
  const [customerCode, setCustomerCode] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [customerType, setCustomerType] = useState('SH')
  const [remarks, setRemarks] = useState('')
  const [receivedOn, setReceivedOn] = useState('')
  const [differentAmount, setDifferentAmount] = useState(0)
  const [masaPajak, setMasaPajak] = useState(0)
  const [tahunPajak, setTahunPajak] = useState(0)
  const [totalIDR, setTotalIDR] = useState(0)
  const [totalUSD, setTotalUSD] = useState(0)
  const [totalPPh23, setTotalPPh23] = useState(0)
  const [printing, setPrinting] = useState(0)
  const [approvedAcc, setApproveAcc] = useState('');

  const [totalIDRReal, setTotalIDRReal] = useState(0)
  const [totalUSDReal, setTotalUSDReal] = useState(0)
  const [totalPPh23Real, setTotalPPh23Real] = useState(0)
  const [differentAmountReal, setDifferentAmountReal] = useState(0)

  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  let checked = 0;
  const [documentStatus, setDocumentStatus] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isApprovedAcc, setIsApprovedAcc] = useState(false);

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');

  const [infossNo, setInfossNo] = useState('');

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Receipt PPh23', link: '/Majura/OfficialReceiptPph23', style: '' },
    { label: 'Create Receipt PPh23', link: '/Majura/OfficialReceiptPph23/Create', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  const BreadcrumbsEditItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Receipt PPh23', link: '/Majura/OfficialReceiptPph23', style: '' },
    { label: 'Detail Receipt PPh23', link: '/Majura/OfficialReceiptPph23/' + orPphId, style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Receipt Pph23 - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orPphId]);

  useImperativeHandle(ref, () => ({
    localSave() {
      saveLocalData();
    }
  }));

  function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
    if (rowStatus !== 'ACT') {
      setDocumentStatus('DELETED');
    }
    else if (isApproved) {
      setDocumentStatus('APPROVED');
    }
    else {
      setDocumentStatus('ONGOING');
    }
  }

  const saveLocalData = () => {
    let id = 0
    if (orPphId) {
      id = orPphId
    }
    const payload = {
      "officialReceiptPph23": {
        "rowStatus": "ACT",
        "countryId": countryId,
        "companyId": companyId,
        "branchId": branchId,
        'noInfoss': infossNo,
        "id": id,
        "no": 0,
        "no2": "string",
        "reference": reference,
        "buktiPotong": buktiPotong,
        "jobOwnerId": jobOwnerId,
        "jobOwnerCode": '' + jobOwnerCode,
        "jobOwnerName": jobOwnerName,
        "customerId": customerId,
        "customerCode": customerCode,
        "customerName": customerName,
        "customerType": customerType,
        "isSettle": false,
        "settledOn": defaultDate,
        "isApprovedAcc": isApprovedAcc,
        "approvedAccOn": approvedAcc,
        "remarks": remarks,
        "printing": printing,
        "printedOn": defaultDate,
        "idLama": 0,
        "receivedOn": receivedOn,
        "differentAmount": differentAmountReal,
        "masaPajak": masaPajak,
        "tahunPajak": tahunPajak,
        "deleted": false,
        "deletedby": "string",
        "deletedOn": defaultDate,
        "userId": userId,
        "userCode": userCode
      },
      "officialReceiptPph23Details": dataDetail
    }

    localStorage.setItem(username + "-DataRPPH23", JSON.stringify(payload));
  }

  const getLocalData = () => {
    let officialReceiptPph23Data = dataLocal.officialReceiptPph23;
    let officialReceiptPph23DetailData = dataLocal.officialReceiptPph23Details;
    setInfossNo(officialReceiptPph23Data.noInfoss)
    setReference(officialReceiptPph23Data.reference)
    setBuktiPotong(officialReceiptPph23Data.buktiPotong)
    setJobOwnerId(officialReceiptPph23Data.jobOwnerId)
    setCustomerId(officialReceiptPph23Data.customerId)
    setCustomerCode(officialReceiptPph23Data.customerCode)
    setCustomerName(officialReceiptPph23Data.customerName)
    setIsApprovedAcc(officialReceiptPph23Data.isApprovedAcc)
    setCustomerType(officialReceiptPph23Data.customerType)
    setRemarks(officialReceiptPph23Data.remarks)
    setDateFormat(officialReceiptPph23Data.approvedAccOn, setApproveAcc)
    setPrinting(officialReceiptPph23Data.printing)
    handleDocumentStatus(officialReceiptPph23Data.rowStatus, officialReceiptPph23Data.isApprovedAcc)

    setDifferentAmountReal(officialReceiptPph23Data.differentAmount)
    setDifferentAmount(convertToNominal(officialReceiptPph23Data.differentAmount))
    setMasaPajak(officialReceiptPph23Data.masaPajak)
    setTahunPajak(officialReceiptPph23Data.tahunPajak)

    setDateFormat(officialReceiptPph23Data.receivedOn, setReceivedOn)

    setDataDetail(officialReceiptPph23DetailData)
    SumTotal(officialReceiptPph23DetailData)

    localStorage.removeItem(username + '-DataRPPH23');
    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
  }

  const getData = () => {
    ApiService.FinAccPostById(param, orPphId)
      .then((response) => {
        if (response.data.code === 200) {
          var result = response.data.data.officialReceiptPph23
          setIsEdit(true);
          setReference(result.reference)
          setInfossNo(result.noInfoss)
          setBuktiPotong(result.buktiPotong)
          setJobOwnerId(result.jobOwnerId)
          setCustomerId(result.customerId)
          setCustomerCode(result.customerCode)
          setCustomerName(result.customerName)
          setCustomerType(result.customerType)
          setRemarks(result.remarks)
          setPrinting(result.printing)
          handleDocumentStatus(result.rowStatus, result.isApprovedAcc)
          setDifferentAmountReal(result.differentAmount)
          setDifferentAmount(result.differentAmount)
          setIsApprovedAcc(result.isApprovedAcc)
          setDateFormat(result.approvedAccOn, setApproveAcc)
          setMasaPajak(result.masaPajak)
          setTahunPajak(result.tahunPajak)

          setDateFormat(result.receivedOn, setReceivedOn)

          setDataDetail(result.officialReceiptPph23Details)
          SumTotal(result.officialReceiptPph23Details)

          if (!dataLocal) {
            setIsLoadingBackDrop(false);
          }
        }
        else {
          handleBack();
        }
      })
      .catch(function (error) {
        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
      })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false
    let tempArray = [];

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'FORP')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);
            tempArray = response.data.data.userAccess;
            tempArray.forEach(element => {
              if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                checked = 1;
              }
            });

            setCheck(checked);

            getJobOwner(1, 50);
            handleDocumentStatus();
          }
          else {
            handleBack();
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error)
          history('/Majura');
        })
    }
    else {
      history('/Majura');
    }
  }

  const getJobOwner = (pageNumber, pageSize) => {
    if (!dataLocal) {
      setIsLoadingBackDrop(true);
    }

    ApiService.PostByPage('jobOwner/jobOwner', pageNumber, pageSize)
      .then((response) => {
        if (response.data.code === 200) {
          let data = response.data.data.jobOwner;
          setDataJobOwner(data);

          if (data.length === 1) {
            setJobOwnerId(data[0].id);
            setJobOwnerCode(data[0].code);
            setJobOwnerName(data[0].name);
          }

          if (dataLocal) {
            setIsLoadingBackDrop(true);
            setTimeout(function () { getLocalData(); }, 2000);
          }
          else {
            if (!orPphId) {
              setDateFormat(new Date(), setReceivedOn)
              GetFormatCurrency()
            }
            else {
              getData()
            }
          }
        }
        else {
          handleBack();
        }
      })
      .catch(function (error) {
        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
      })
  }

  const handleSave = () => {
    setIsLoadingBackDrop(true);
    let id = 0
    if (orPphId) {
      id = orPphId
    }
    const payload = {
      "officialReceiptPph23": {
        "rowStatus": "ACT",
        "countryId": countryId,
        "companyId": companyId,
        "branchId": branchId,
        "id": id,
        "no": 0,
        "no2": "",
        'noInfoss': infossNo,
        "reference": reference,
        "buktiPotong": buktiPotong,
        "jobOwnerId": jobOwnerId,
        "jobOwnerCode": '' + jobOwnerCode,
        "jobOwnerName": jobOwnerName,
        "customerId": customerId,
        "customerCode": customerCode,
        "customerName": customerName,
        "customerType": customerType,
        "isSettle": false,
        "settledOn": defaultDate,
        "remarks": remarks,
        "isApprovedAcc": isApprovedAcc,
        "approvedAccOn": approvedAcc ? approvedAcc : defaultDate,
        "printing": printing,
        "printedOn": defaultDate,
        "idLama": 0,
        "receivedOn": receivedOn,
        "differentAmount": parseFloat(differentAmountReal),
        "masaPajak": masaPajak,
        "tahunPajak": tahunPajak,
        "deleted": false,
        "deletedBy": "",
        "deletedOn": defaultDate,
        "userId": userId,
        "userCode": userCode
      },
      "officialReceiptPph23Details": dataDetail
    }

    let errMsg = undefined;

    if (payload.officialReceiptPph23.jobOwnerId === 0) {
      errMsg = 'Job Owner is Required!';
    }
    else if (payload.officialReceiptPph23.buktiPotong === '') {
      errMsg = 'Bukti Potong is Required!';
    }
    else if (payload.officialReceiptPph23.noInfoss === '') {
      errMsg = 'Please Fill Infoss Number!';
    }

    if (errMsg === undefined) {
      ApiService.HelpCreateData(payload, param, id)
        .then((res) => {
          if (res.data.code === 200) {
            let message = ''
            if (!orPphId) {
              ApiService.CreateFinAcc(param, payload)
                .then((res) => {
                  if (res.data.code === 200) {
                    message = 'Success create data';
                    showToast({ type: 'success', message: message });
                    setIsLoadingBackDrop(false);
                    setTimeout(
                      function () {
                        localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                        history('/Majura/officialReceiptPph23/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                        window.location.reload();
                        localStorage.removeItem('tempId');
                      },
                      1250);
                  }
                  else {
                    message = 'Failed create data';
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                  }
                })
                .catch(function (error) {
                  setIsLoadingBackDrop(false);
                  showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
                })
            }
            else {
              ApiService.EditFinAcc(param, payload)
                .then((res) => {
                  if (res.data.code === 200) {
                    message = 'Success update data';
                    showToast({ type: 'success', message: message });
                    setIsLoadingBackDrop(false);
                    setTimeout(
                      function () {
                        history('/Majura/officialReceiptPph23/' + orPphId, { replace: true });
                        window.location.reload();
                      },
                      1250);
                  }
                  else {
                    message = 'Failed update data';
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                  }
                })
                .catch(function (error) {
                  setIsLoadingBackDrop(false);
                  showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
                })
            }
          }
          else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Failed to Create Help Data' });
          }
        })
        .catch(function (error) {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
        })
    }
    else {
      setIsLoadingBackDrop(false);
      showToast({ type: 'error', message: errMsg });
    }
  }

  const AddNew = () => {
    history('/Majura/officialReceiptPph23/Create', { replace: true });
    window.location.reload();
  }

  const handleBack = () => {
    history('/Majura/officialReceiptPph23')
  }

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
    }
    else {
      setIsModalConfirmation(false);
    }
  }

  function ConfirmationRP() {
    if (titleConfirmation === 'Approve') {
      Approve();
    }
    else if (titleConfirmation === 'Un Approve') {
      UnApprove();
    }
    setIsModalConfirmation(false);
  }


  const ApproveData = () => {
    if (orPphId) {
      if (isApprovedAcc === false) {
        setTitleConfirmation('Approve')
        setTitleModalConfirmation('Receipt PPh 23');
        handleClickConfirmation();
      }
      else {
        showToast({ type: 'error', message: 'Data Already Been Approved!' });
      }
    }
    else {
      showToast({ type: 'error', message: 'Create The Data First!' });
    }
  }

  const Approve = () => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccUpdateStatusApprovedAcc(param, orPphId).then((res) => {
      let message = '';
      message = res.data.message;
      if (res.data.code === 200) {
        setIsLoadingBackDrop(false);
        CreateGL(orPphId);
        showToast({ type: 'success', message: message });

        setTimeout(
          function () {
            history('/Majura/officialReceiptPph23/' + orPphId, { replace: true });
            window.location.reload();
          },
          100);

      }
      else {
        setIsLoadingBackDrop(false);
        showToast({ type: 'error', message: message });
      }
    })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Approve Receipt PPh 23!' });
      });
  }

  const UnApproveData = () => {
    if (orPphId) {
      if (isApprovedAcc) {
        setTitleConfirmation('Un Approve')
        setTitleModalConfirmation('Receipt PPh 23');
        handleClickConfirmation();
      }
      else {
        showToast({ type: 'error', message: 'Data Hasn\'t Been Approved Yet!' });
      }
    }
    else {
      showToast({ type: 'error', message: 'Create The Data First!' });
    }
  }

  const UnApprove = () => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccUpdateStatusUnApprovedAcc(param, orPphId).then((res) => {
      let message = '';
      message = res.data.message;
      if (res.data.code === 200) {
        setIsLoadingBackDrop(false);
        CreateGL(res.data.data.ContraId);
        showToast({ type: 'success', message: message });

        setTimeout(
          function () {
            history('/Majura/officialReceiptPph23/' + orPphId, { replace: true });
            window.location.reload();
          },
          1250);
      }
      else {
        setIsLoadingBackDrop(false);
        showToast({ type: 'error', message: message });
      }
    })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Un Approve Receipt PPh 23!' });
      });
  }

  const CreateGL = (id) => {
    var data = {
      "generalLedger": {
        "source": "PPH",
        "refId": id,
        "methodPost": "AUTO",
        "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
      }
    }

    ApiService.CreateFinAcc(paramGL, data).then((res) => {
      let message = '';
      message = res.data.message;
      if (res.data.code !== 200) {
        showToast({ type: 'error', message: message });
      }
    });
  }

  const GetFormatCurrency = () => {
    setDifferentAmount(convertToNominal(0));
    setTotalUSD(convertToNominal(0))
    setTotalIDR(convertToNominal(0))
    setTotalPPh23(convertToNominal(0))

    if (!dataLocal) {
      setIsLoadingBackDrop(false);
    }
  }

  const SumTotal = (data) => {
    let sumIDR = 0
    let sumUSD = 0
    let sumPPh23 = 0
    data.forEach(el => {
      sumUSD += parseInt(el.amountUSD)
      sumIDR += parseInt(el.amountIDR)
      sumPPh23 += parseInt(el.pph23Amount)
    });
    setTotalUSDReal(sumUSD)
    setTotalIDRReal(sumIDR)
    setTotalPPh23Real(sumPPh23)
    setTotalUSD(convertToNominal(`${sumUSD}`))
    setTotalIDR(convertToNominal(`${sumIDR}`))
    setTotalPPh23(convertToNominal(`${sumPPh23}`))
  }

  const ButtonComponents = () => {
    return (
      <ButtonCreateEditReceiptPPh23
        handleSave={handleSave}
        handleBack={handleBack}
        DataRegUserAccess={DataRegUserAccess}
        check={check}
        AddNew={AddNew}
        isEdit={isEdit}
        Approve={ApproveData}
        UnApprove={UnApproveData}
        isApprovedAcc={isApprovedAcc}
      />
    )
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={
              orPphId ?
                'Receipt PPh 23 (' + reference + ')'
                :
                'Receipt PPh 23 ( New Document )'
            }
            BreadcrumbsItems={orPphId ? BreadcrumbsEditItems : BreadcrumbsItems}
            type={'Form'}
            ticketId={0}
            BackFunction={handleBack}
            documentStatus={documentStatus}
          />

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <ReceiptPPh23Header
                isApprovedAcc={isApprovedAcc}
                infossNo={infossNo}
                setInfossNo={setInfossNo}
                reference={reference}
                setReference={setReference}
                buktiPotong={buktiPotong}
                setBuktiPotong={setBuktiPotong}
                jobOwnerId={jobOwnerId}
                setJobOwnerId={setJobOwnerId}
                customerId={customerId}
                setCustomerId={setCustomerId}
                customerCode={customerCode}
                setCustomerCode={setCustomerCode}
                customerName={customerName}
                setCustomerName={setCustomerName}
                customerType={customerType}
                setCustomerType={setCustomerType}
                remarks={remarks}
                setRemarks={setRemarks}
                masaPajak={masaPajak}
                setMasaPajak={setMasaPajak}
                tahunPajak={tahunPajak}
                setTahunPajak={setTahunPajak}
                jobOwnerCode={jobOwnerCode}
                setJobOwnerCode={setJobOwnerCode}
                jobOwnerName={jobOwnerName}
                setJobOwnerName={setJobOwnerName}
                dataJobOwner={dataJobOwner}
                isEdit={isEdit}
                receivedOn={receivedOn}
                setReceivedOn={setReceivedOn}
              />
            </CardContent>
          </Card>

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <ReceiptPPh23Detail
                isApprovedAcc={isApprovedAcc}
                dataDetail={dataDetail}
                setDataDetail={setDataDetail}
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                customerId={customerId}
                totalIDR={totalIDR}
                totalUSD={totalUSD}
                totalPPh23={totalPPh23}
                SumTotal={SumTotal}

                totalIDRReal={totalIDRReal}
                setTotalIDRReal={setTotalIDRReal}
                totalUSDReal={totalUSDReal}
                setTotalUSDReal={setTotalUSDReal}
                totalPPh23Real={totalPPh23Real}
                setTotalPPh23Real={setTotalPPh23Real}
              />

              <ReceiptPPh23Total
                isApprovedAcc={isApprovedAcc}
                differentAmount={differentAmount}
                setDifferentAmount={setDifferentAmount}
                totalIDR={totalIDR}
                totalUSD={totalUSD}
                setDifferentAmountReal={setDifferentAmountReal}
                totalPPh23={totalPPh23}
              />
            </CardContent>
          </Card>
        </Grid>
      </Box>

      <ButtonInfo
        ButtonComponents={ButtonComponents}
      />

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={ConfirmationRP}
          />
        )
      }
    </>
  )
})

export default CreateEditReceiptPPh23