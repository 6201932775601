import { Grid } from '@mui/material';
import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const BankAdvButton = ({
  isApprove,
  ReloadData,
  DataRegUserAccess,
  Add,
  Edit,
  DeleteData,
  PrintData,
  ApprovalCRData,
  handleApprove,
  Approve,
  DropDownTitle,
  HandleStatus,
  DropDownList
}) => {
  const renderButtons = () => {
    return DataRegUserAccess.map((data) => {
      switch (data.accessCode) {
        case 'CRE':
          return <React.Fragment key={data.accessId}>{ButtonComponent.AddButton(Add, 'New')}</React.Fragment>;
        case 'REI':
          return <React.Fragment key={data.accessId}>{ButtonComponent.EditButton(Edit, 'Data')}</React.Fragment>;
        case 'DEL':
          return DropDownTitle !== 'Deleted' ? (
            <React.Fragment key={data.accessId}>{ButtonComponent.DeleteButton(DeleteData, 'Data')}</React.Fragment>
          ) : null;
        case 'PRI':
          return DropDownTitle !== 'Deleted' ? (
            <React.Fragment key={data.accessId}>{ButtonComponent.PrintButton(PrintData)}</React.Fragment>
          ) : null;
        case 'APC':
          return DropDownTitle !== 'Deleted' ? (
            <React.Fragment key={data.accessId}>{ButtonComponent.ApproveButton(ApprovalCRData, 'Approval Credit')}</React.Fragment>
          ) : null;
        case 'APP':
          return (DropDownTitle === 'All' || DropDownTitle === 'Active') ? (
            <React.Fragment key={data.accessId}>{ButtonComponent.ApproveButton(handleApprove, 'Approve')}</React.Fragment>
          ) : null;
        default:
          return null;
      }
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
          {!isApprove ? (
            <>
              {ButtonComponent.ReloadButton(ReloadData)}
              {renderButtons()}
              {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
            </>
          ) : (
            <>
              {ButtonComponent.BackButton(handleApprove)}
              {DataRegUserAccess.some(data => data.accessCode === 'APP') &&
                <React.Fragment key="approve-data">
                  {ButtonComponent.ApproveButton(Approve, 'Approve Data')}
                </React.Fragment>
              }
            </>
          )}
        </Grid>
      </div>
    </div>
  );
}

export default BankAdvButton;