import React from 'react'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import TableEPL from '../TableEPL/TableEPL';
import { Grid, Stack } from '@mui/material';
import { showToast } from '../../../../components/ui';

const TabCostTrucking = (props) => {
  const title = 'Cost Trucking'
  const contactTypeName = 'Trucking'
  const contactTypeId = 10
  const income = false

  const handleAdd = () => {
    if (props.shipmentNo !== '') {
      props.handleAdd(title, contactTypeName, contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Please Select Shipment Number First!' });
    }
  }

  const handleEdit = () => {
    if (props.selectedDetailCostTrucking.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleEdit(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleConvert = () => {
    if (props.selectedDetailCostTrucking.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleConvert(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleDoubleClick = () => {
    handleEdit()
  }

  // const handleDelete = () => {
  //   if(props.selectedDetailCostTrucking.bookingDetailId === 0)
  //   {
  //     props.handleDelete( contactTypeId, income)
  //   }
  //   else
  //   {
  //     showToast({ type: 'error', message: 'Can\'t Delete This Data!' });
  //   }
  // }

  return (
    <div className='rounded-10'>
      <TableEPL
        key={4}
        isReadOnly={props.isReadOnly}
        dataList={props.CostTruckingList}
        ignoreAccess={props.ignoreAccess}
        setDataList={props.setCostTruckingList}
        setSelectedDetail={props.setSelectedDetailCostTrucking}
        selectedDetail={props.selectedDetailCostTrucking}
        handleDoubleClick={handleDoubleClick}
        contactTypeName={contactTypeName}
        ttlUsd={props.estCostUSDTrucking}
        ttlIdr={props.estCostIDRTrucking}
        totalSData={props.totalCostSTrucking}
        totalNData={props.totalCostNTrucking}
        key1={4}
        key2={4}
        type={2}
        openClose={props.openCloseCT}
        setOpenClose={props.setOpenCloseCT}
        openClose2={props.openCloseCT2}
        setOpenClose2={props.setOpenCloseCT2}
        dataDetail={props.dataDetail}
        setDataDetail={props.setDataDetail}
        countSum={props.countSum}
        countTotalData={props.countTotalData}
      />

      <Grid container item direction="row" className='mt-3'>
        <Grid item xs={10}>
          <Stack direction={'row'} spacing={'10px'}>
            {ButtonComponent.AddButton(handleAdd, '', props.isReadOnly)}
            {ButtonComponent.EditButton(props.handleEdit, '', props.isReadOnly)}
            {ButtonComponent.DeleteButton(props.handleDelete, '', props.isReadOnly)}
            {ButtonComponent.ConvertButton(handleConvert, props.isReadOnly, true)}
          </Stack>
        </Grid>

        <Grid item xs={2} className='text-end' display={'flex'} justifyContent={'flex-end'}>
          {ButtonComponent.AddButton(null, 'Trucking', props.isReadOnly, true)}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(TabCostTrucking)