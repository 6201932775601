import { showToast } from "../components/ui";
import ApiService from "../services/ApiService";

export function setReportType(type) {
    if (type === "excel" || type === "xls") {
        return "application/vnd.ms-excel";
    }
    else if (type === "pdf") {
        return "application/pdf";
    }
}

export const handleFailedGL = ({ id, glStatusCode, setIsLoadingBackDrop, paramGL, setFailedData, handleClickRePost, source, isDP = false }) => {
    setIsLoadingBackDrop(true);
    let dataSource = setDataSource(source);
    if (glStatusCode === 'ERR') {
        let params = [
            { 'attr': 'refId', 'value': '' + id },
            { 'attr': 'source', 'value': '' + source }
        ]
        ApiService.FinAccCustomPostByPage('PostByGLFail', paramGL, 'PAGE', 1, 1, params)
            .then((res) => {
                if (res.data.code === 200) {
                    if (res.data.data.generalLedgerFail.length !== 0) {
                        let resData = res.data.data.generalLedgerFail[0];
                        let date = new Date(resData.transactionDate);
                        var dateTemp = date.toLocaleDateString("en-US", { day: "2-digit" });
                        var monthTemp = date.toLocaleDateString("en-US", { month: "short" });
                        var yearTemp = date.toLocaleDateString("en-US", { year: "numeric" });
                        let transactionDate = dateTemp + ' ' + monthTemp + ' ' + yearTemp;

                        setFailedData({
                            'initial': source,
                            'id': resData.refId,
                            'refNumber': resData.refNo,
                            'errorMessage': resData.message,
                            'errorCode': resData.messageCode,
                            'transactionDate': transactionDate,
                            'source': isDP ? 'Down Payment' : dataSource,
                            'customer': resData.customerName === '' ? '-' : resData.customerName,
                        })
                        handleClickRePost();
                    }
                    else {
                        showToast({ type: 'error', message: 'Data Un-Allowed To Be Re-Posted!' });
                    }
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: error });
            })
    }
    else {
        setIsLoadingBackDrop(false);
        showToast({ type: 'error', message: 'Data Hasn\'t Been Posted or Already Been Posted' });
    }
}

export const handleRePost = ({ handleClickRePost, setIsLoadingBackDrop, failedData, paramGL, getData, numPage, rowsCount, filter }) => {
    handleClickRePost();
    setIsLoadingBackDrop(true);
    var data = {
        "generalLedger": {
            "messageCode": failedData.errorCode,
            "refId": failedData.id,
            "source": failedData.initial,
        }
    }

    ApiService.FinAccCustomPost(paramGL + '/ReCreate', data).then((res) => {
        let message = '';
        message = res.data.message;
        if (res.data.code !== 200) {
            showToast({ type: 'error', message: message });
        }
        else {
            setIsLoadingBackDrop(false);
            getData(numPage, rowsCount, filter);
            showToast({ type: 'success', message: 'Re-Posting Data Success' });
        }
    });
}

function setDataSource(source) {
    switch (source) {
        case 'INV':
            return 'Invoice';
        case 'ME':
            return 'Memorial';
        case 'PPH':
            return 'Receipt PPh23';
        case 'PR':
            return 'Payment Request';
        case 'PV':
            return 'Payment Voucher';
        case 'RV':
            return 'Receipt Voucher';
        case 'OR':
            return 'Official Receipt';
        case 'TP':
            return 'Temporary Payment';
        case 'TR':
            return 'Temporary Receipt';
        default:
            return '';
    }
}