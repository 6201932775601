import React, { useImperativeHandle, forwardRef } from 'react'
import {
  Box,
  Grid,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

import { colorList } from '../../theme/config';

import { getColorByBackground } from './utils';

import MasterCard from './components/MasterCard';
import MasterModal from './components/MasterModal';
import MasterInput from './components/MasterInput';
import MasterInputMask from './components/MasterInputMask';
import MasterIcon from './components/MasterIcon';
import MasterButton from './components/MasterButton';
import MasterSelect from './components/MasterSelect';
import MasterTabs from './components/MasterTabs';
import MasterPaginate from './components/MasterPaginate';
import MasterSwitcher from './components/MasterSwitcher';
import MasterTickbox from './components/MasterTickbox';

const MasterDesign = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    localSave() { }
  }));

  const theme = useTheme()

  return (
    <Box sx={{ width: '100%', p: 3, minHeight: 'calc(100% - 64px)', bgcolor: '#f3f4f6' }}>
      <Grid container spacing={2}>

        <MasterCard title={'BASE COLOR'} xs={6}>
          <Stack direction={'column'} gap={2}>
            {Object.entries(colorList).map(([listKey, listValue]) => {
              return (
                <Grid container spacing={2} key={listKey}>
                  {Object.entries(listValue).map(([key, value]) => {
                    return (
                      <Grid item xs={4} key={value}>
                        <Box
                          height={100}
                          color={getColorByBackground(value)}
                          bgcolor={value}
                          borderRadius={2}
                          padding={2}
                          boxShadow={theme.shadows[3]}
                        >
                          <Typography
                            textTransform={'uppercase'}
                            fontSize={14}
                          >
                            {key}
                          </Typography>
                          <Typography
                            textTransform={'uppercase'}
                            fontSize={20}
                          >
                            {value}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  })
                  }
                </Grid>
              )
            })}
          </Stack>
        </MasterCard>

        <MasterCard title={'MODAL'} xs={6}>
          <MasterModal />
        </MasterCard>

        <MasterCard title={'INPUT'} xs={12}>
          <MasterInput />
        </MasterCard>

        <MasterCard title={'INPUT MASK'} xs={5}>
          <MasterInputMask />
        </MasterCard>

        <MasterCard title={'SELECT'} xs={7}>
          <MasterSelect />
        </MasterCard>

        <MasterCard title={'ICON'} xs={6}>
          <MasterIcon />
        </MasterCard>

        <MasterCard title={'BUTTON'} xs={6}>
          <MasterButton />
        </MasterCard>

        <MasterCard title={'PAGINATION'} xs={12}>
          <MasterPaginate />
        </MasterCard>

        <MasterCard title={'TABS'} xs={5}>
          <MasterTabs />
        </MasterCard>

        <MasterCard title={'SWITCHER'} xs={4}>
          <MasterSwitcher />
        </MasterCard>

        <MasterCard title={'TICKBOX'} xs={3}>
          <MasterTickbox />
        </MasterCard>
      </Grid>
    </Box>
  )
})

export default MasterDesign