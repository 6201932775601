import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input } from '../../../components/ui';

const CreateEditCompany = (props) => {
    const Save = () => {
        var data =
        {
            "company": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "abbreviation": props.abbreviation,
                "address": props.address,
                "phone": props.phone,
                "fax": props.fax,
                "email": props.email,
                "npwp": props.npwp,
                "nppkp": props.nppkp,
                "approvalWP": props.approvalWP,
                "namaWP": props.namaWP,
                "alamatWP": props.alamatWP,
                "masterCode": props.masterCode,
                "timeZone": props.timeZone,
                "migrasiEmkl": props.migrasiEmkl,
                "migrasiForwarding": props.migrasiForwarding,
                "nameHeader": props.nameHeader,
                "isVat": props.isVat,
                "token": props.token,
                "migrasiRE": props.migrasiRE,
                "fullName": props.fullName,
                "groupId": props.groupId,
                "emailCS": props.emailCS,
                "kantorPajakPVPPh23": props.kantorPajakPVPPh23,
                "useBookingConfirmation": props.useBookingConfirmation,
                "useBookingConfirmationDate": props.useBookingConfirmationDate,
                "refCompanyIdPusat": props.refCompanyIdPusat,
                "pejkpNamaWP": props.pejkpNamaWP,
                "pejkpJabatanWP": props.pejkpJabatanWP,
                "migrasiGreatherThan": props.migrasiGreatherThan,
                "user": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const mainContainer = (
        <form style={{ marginTop: '12px' }}>
            <Card
                component="div"
                style={{
                    borderRadius: '12px',
                    backgroundColor: '#FAFAFA',
                    padding: '8px 24px',
                    border: '1px solid var(--Grey-Outline-2, #989898)',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                        }}
                    >
                        Company Info
                    </Typography>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            sm={12}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    label="Code"
                                    value={props.code}
                                    onChange={(e) => props.setCode(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Name"
                                    value={props.name}
                                    onChange={(e) => props.setName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Abbreviation"
                                    value={props.abbreviation}
                                    onChange={(e) => props.setAbbreviation(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                />
                            </Stack>

                            <Input
                                label={'Address'}
                                value={props.address}
                                onChange={(e) => props.setAddress(e.target.value)}
                                multiline
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px'
                                    }
                                }}
                                inputBaseProps={{
                                    rows: 4,
                                    sx: {
                                        padding: '8px',
                                    },
                                }}
                            />

                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    label="Phone"
                                    value={props.phone}
                                    onChange={(e) => props.setPhone(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Fax"
                                    value={props.fax}
                                    onChange={(e) => props.setFax(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Email"
                                    type={'email'}
                                    value={props.email}
                                    onChange={(e) => props.setEmail(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        width={'xl'}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }
        </>
    )
}
export default CreateEditCompany