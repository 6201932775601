import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonPaymentVoucher = ({ isApprove, isUnApprove,
    ReloadData, AddPV, EditPV, DeleteData, Print,
    handleApprove, handleUnApprove, Approve,
    UnApprove, HandleStatus, DropDownTitle,
    DropDownList, DataRegUserAccess, isDisabled
}) => {
    const renderButtonByAccessCode = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddPV, 'New', false, isDisabled);
            case 'REI':
                return ButtonComponent.EditButton(EditPV, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' && ButtonComponent.DeleteButton(DeleteData, 'Data');
            case 'PRI':
                return DropDownTitle !== 'Deleted' && ButtonComponent.PrintButton(Print, 'Data');
            case 'APP':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All') && ButtonComponent.ApproveButton(handleApprove, 'Approve');
            case 'UPP':
                return (DropDownTitle === 'All' || DropDownTitle === 'Posted') && ButtonComponent.UnApproveButton(handleUnApprove, 'Un-Approve');
            default:
                return null;
        }
    };

    const renderButtonsForAccess = () => {
        return DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
                {renderButtonByAccessCode(data.accessCode)}
            </React.Fragment>
        ));
    };

    const renderConditionalButtons = () => {
        if (!isApprove && !isUnApprove) {
            return (
                <>
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {renderButtonsForAccess()}
                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                </>
            );
        }

        const commonButton = isApprove ? ButtonComponent.ApproveButton : ButtonComponent.UnApproveButton;
        const commonHandle = isApprove ? handleApprove : handleUnApprove;
        const commonText = isApprove ? 'Approve Data' : 'Un-Approve Data';

        return (
            <>
                {ButtonComponent.BackButton(commonHandle)}
                {DataRegUserAccess.map((data) => (
                    <React.Fragment key={data.accessId}>
                        {data.accessCode === 'APP' && commonButton(isApprove ? Approve : UnApprove, commonText)}
                    </React.Fragment>
                ))}
            </>
        );
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {renderConditionalButtons()}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonPaymentVoucher;