import { Box, MenuItem, Grid, Card, CardContent } from '@mui/material';
import React, { useImperativeHandle } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../../services/ApiService';

import ButtonCreateEditOfficialReceipt from './Buttons/ButtonCreateEditOfficialReceipt';
import OfficialReceiptDetail from './Components/OfficialReceiptDetail';
import OfficialReceiptPayment from './Components/OfficialReceiptPayment';
import { forwardRef } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { defaultDate, getBackDatePeriod, getDefaultDateFormat, nullDate, setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal } from '../../../../utils/masks.jsx';

const CreateEditOfficialReceipt = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataOR"));

    const { officialReceiptId } = useParams();

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramOfficialReceipt = 'officialReceipt/officialReceipt';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramGL = 'generalLedger/generalLedger';

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    const history = useNavigate();

    const [ORId, setORId] = useState(0);
    const [RowStatus, setRowStatus] = useState('ACT');
    const [TicketId, setTicketId] = useState(0);
    const [ORNumber, setORNumber] = useState(0);
    const [ORNo2, setORNo2] = useState('');
    const [Printing, setPrinting] = useState(0);
    const [PrintDate, setPrintDate] = useState('');
    const [Verify, setVerify] = useState('');
    const [PaymentByCode, setPaymentByCode] = useState(2);
    const [PrincipleBy, setPrincipleBy] = useState(0);
    const [CustomerId, setCustomerId] = useState(0);
    const [CustomerName, setCustomerName] = useState('');
    const [RateCount, setRateCount] = useState(0);
    const [RateCountReal, setRateCountReal] = useState(0);
    const [RateDate, setRateDate] = useState('');
    const [RateId, setRateId] = useState(0);
    const [InvoiceList, setInvoiceList] = useState([]);
    const [PPH23List, setPPH23List] = useState([]);
    const [TRList, setTRList] = useState([]);
    const [RVList, setRVList] = useState([]);
    const [InvoiceUSD, setInvoiceUSD] = useState(0);
    const [InvoiceIDR, setInvoiceIDR] = useState(0);
    const [TotalInvoiceIDR, setTotalInvoiceIDR] = useState(0);
    const [TRInUSD, setTRInUSD] = useState(0);
    const [TRInIDR, setTRInIDR] = useState(0);
    const [TotalTRInIDR, setTotalTRInIDR] = useState(0);
    const [RVInUSD, setRVInUSD] = useState(0);
    const [RVInIDR, setRVInIDR] = useState(0);
    const [TotalRVInIDR, setTotalRVInIDR] = useState(0);
    const [TotalPPh, setTotalPPh] = useState(0);
    const [VerifyAcc, setVerifyAcc] = useState('');
    const [VerifyAccDate, setVerifyAccDate] = useState('');
    const [Period, setPeriod] = useState(0);
    const [YearPeriod, setYearPeriod] = useState(0);
    const [SaveOR, setSaveOR] = useState(false);
    const [RePrintApproved, setRePrintApproved] = useState(false);
    const [RePrintApprovedOn, setRePrintApprovedOn] = useState('');
    const [RePrintApprovedBy, setRePrintApprovedBy] = useState('');
    const [DeletedRemarks, setDeletedRemarks] = useState('');
    const [Deleted, setDeleted] = useState(false);
    const [DeletedOn, setDeletedOn] = useState('');
    const [WfComplete, setWfComplete] = useState(0);
    const [isApprovedAcc, setIsApprovedAcc] = useState(false);
    const [reference, setReference] = useState('');

    const [CashPaymentUSD, setCashPaymentUSD] = useState(0);
    const [CashPaymentIDR, setCashPaymentIDR] = useState(0);
    const [TotalCashPaymentIDR, setTotalCashPaymentIDR] = useState(0);
    const [BankData, setBankData] = useState([]);
    const [TotalCashUSD, setTotalCashUSD] = useState(0);
    const [TotalCashIDR, setTotalCashIDR] = useState(0);
    const [TotalCashInIDR, setTotalCashInIDR] = useState(0);
    const [TotalBankUSD, setTotalBankUSD] = useState(0);
    const [TotalBankIDR, setTotalBankIDR] = useState(0);
    const [TotalBankInIDR, setTotalBankInIDR] = useState(0);
    const [DifferenceUSD, setDifferenceUSD] = useState(0);
    const [DifferenceIDR, setDifferenceIDR] = useState(0);
    const [TotalDifferenceIDR, setTotalDifferenceIDR] = useState(0);
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');

    const [TRInUSDReal, setTRInUSDReal] = useState(0);
    const [TRInIDRReal, setTRInIDRReal] = useState(0);
    const [TotalTRInIDRReal, setTotalTRInIDRReal] = useState(0);
    const [RVInUSDReal, setRVInUSDReal] = useState(0);
    const [RVInIDRReal, setRVInIDRReal] = useState(0);
    const [TotalRVInIDRReal, setTotalRVInIDRReal] = useState(0);
    const [TotalCashUSDReal, setTotalCashUSDReal] = useState(0);
    const [TotalCashIDRReal, setTotalCashIDRReal] = useState(0);
    const [TotalCashInIDRReal, setTotalCashInIDRReal] = useState(0);
    const [TotalBankUSDReal, setTotalBankUSDReal] = useState(0);
    const [TotalBankIDRReal, setTotalBankIDRReal] = useState(0);
    const [TotalBankInIDRReal, setTotalBankInIDRReal] = useState(0);
    const [DifferenceUSDReal, setDifferenceUSDReal] = useState(0);
    const [DifferenceIDRReal, setDifferenceIDRReal] = useState(0);
    const [TotalDifferenceIDRReal, setTotalDifferenceIDRReal] = useState(0);

    const [checkSelectedInvoice, setCheckSelectedInvoice] = useState({});
    const [invoiceIdList, setInvoiceIdList] = useState([]);
    const [TRIdList, setTRIdList] = useState([]);
    const [RVIdList, setRVIdList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [isJobOwner, setIsJobOwner] = useState(true);
    const [isNPWP, setIsNPWP] = useState(false);
    const [dueDateLast, setDueDateLast] = useState('');
    const [AccountCodeExI, setAccountCodeExI] = useState('');
    const [AccountNameExI, setAccountNameExI] = useState('');
    const [AccountCodeExE, setAccountCodeExE] = useState('');
    const [AccountNameExE, setAccountNameExE] = useState('');
    const [TempDiffUSD, setTempDiffUSD] = useState(0);
    const [TempDiffIDR, setTempDiffIDR] = useState(0);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [backDate, setBackDate] = useState('');
    const [documentStatus, setDocumentStatus] = useState('');

    const [isUnapproved, setIsUnapproved] = useState(false);

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Official Receipt', link: '/Majura/OfficialReceipt', style: '' },
        { label: 'Create Official Receipt', link: '/Majura/OfficialReceipt/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Official Receipt', link: '/Majura/OfficialReceipt', style: '' },
        { label: 'Detail Official Receipt', link: '/Majura/OfficialReceipt/' + officialReceiptId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Official Receipt - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    const saveLocalData = () => {
        let id = officialReceiptId ? officialReceiptId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(VerifyAccDate, true);
        let dateDelete = updateDateFormat(DeletedOn, true);
        let datePrintApproved = updateDateFormat(RePrintApprovedOn, true);

        var data = {
            "officialReceipt": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": TicketId,
                "orNo": ORNumber,
                "orNo2": ORNo2,
                "paymentById": PaymentByCode,
                "customerId": CustomerId,
                "customerName": CustomerName,
                "rate": RateCountReal,
                "exRateDate": RateDate,
                "totalCashUSD": TotalCashUSDReal,
                "totalCashIDR": TotalCashIDRReal,
                "totalBankUSD": TotalBankUSDReal,
                "totalBankIDR": TotalBankIDRReal,
                "printing": Printing,
                "printedOn": datePrint,
                "approvedAccBy": VerifyAcc,
                "approvedAccOn": dateVerify,
                "period": Period,
                "yearPeriod": YearPeriod,
                "difference": TotalDifferenceIDRReal,
                "saveOR": SaveOR,
                "jobOwnerId": PrincipleBy,
                "exRateId": RateId,
                "coaCode": AccountCode,
                "coaName": AccountName,
                "isApprovedAcc": isApprovedAcc,
                'dueDate': dueDateLast,
                'reference': reference,

                "rePrintApproved": RePrintApproved,
                "rePrintApprovedOn": datePrintApproved,
                "rePrintApprovedBy": RePrintApprovedBy,
                "deletedRemarks": DeletedRemarks,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "wfComplete": WfComplete,
                "idLama": 0,
            },
            "officialReceiptInvoices": InvoiceList,
        };

        localStorage.setItem(username + "-DataOR", JSON.stringify(data));
    }

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getLocalData = () => {
        let arrayInvoice = [];
        let exRateCount = 0;
        let arrayIdInvoice = [];
        let officialReceiptData = dataLocal.officialReceipt;
        let officialReceiptInvoiceData = dataLocal.officialReceiptInvoices;

        exRateCount = officialReceiptData.rate;

        if (officialReceiptId) {
            setIsEdit(true);
        }
        setInfossNo(officialReceiptData.noInfoss);
        setRowStatus(officialReceiptData.rowStatus);
        setReference(officialReceiptData.reference);
        setTicketId(officialReceiptData.ticketId);
        setORNumber(officialReceiptData.orNo);
        setORNo2(officialReceiptData.orNo2);
        setPrinting(officialReceiptData.printing);
        setPaymentByCode(officialReceiptData.paymentById);
        setPrincipleBy(officialReceiptData.jobOwnerId);
        setCustomerId(officialReceiptData.customerId);
        setCustomerName(officialReceiptData.customerName);
        setRateCountReal(officialReceiptData.rate);
        handleDocumentStatus(officialReceiptData.rowStatus, officialReceiptData.isApprovedAcc);
        setRateCount(convertToNominal(officialReceiptData.rate));
        setRateId(officialReceiptData.exRateId);
        setVerifyAcc(officialReceiptData.approvedAccBy);
        setIsApprovedAcc(officialReceiptData.isApprovedAcc);
        setVerify(officialReceiptData.isApprovedAcc === true ? 'Yes' : 'No');
        setPeriod(officialReceiptData.period);
        setYearPeriod(officialReceiptData.yearPeriod);
        setSaveOR(officialReceiptData.saveOR);
        setRePrintApproved(officialReceiptData.rePrintApproved);
        setRePrintApprovedBy(officialReceiptData.rePrintApprovedBy);
        setDeletedRemarks(officialReceiptData.deletedRemarks);
        setDeleted(officialReceiptData.deleted);
        setWfComplete(officialReceiptData.wfComplete);

        setAccountCode(officialReceiptData.coaCode);
        setAccountName(officialReceiptData.coaName);

        setDateFormat(officialReceiptData.printedOn, setPrintDate);
        setDateFormat(officialReceiptData.exRateDate, setRateDate);
        setDateFormat(officialReceiptData.approvedAccOn, setVerifyAccDate);
        setDateFormat(officialReceiptData.rePrintApprovedOn, setRePrintApprovedOn);
        setDateFormat(officialReceiptData.deletedOn, setDeletedOn);

        let tempCreateDate = '';
        let tempDate = defaultDate;
        let checkBank = 0;
        let dueDate = updateDateFormat(officialReceiptData.dueDate);
        if (dueDate !== nullDate) {
            tempDate = dueDate;
        }

        let dateCreate = updateDateFormat(officialReceiptData.createdOn);
        if (dateCreate !== nullDate) {
            tempCreateDate = dateCreate;
        }

        ApiService.PostById("regContact/regContact", officialReceiptData.customerId)
            .then((res) => {
                if (res.data.code === 200) {
                    if (res.data.data.contact.npwp === '00.000.000.0-000.000' || res.data.data.contact.npwp === '000.000.000.0-000.000') {
                        setIsNPWP(false);
                    }
                    else {
                        setIsNPWP(true);
                    }
                }
                else {
                    console.log('Error Read Reg Contact');
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);

                showToast({ type: 'error', message: error.toString() });
            })

        setInvoiceList(officialReceiptInvoiceData);
        arrayInvoice = officialReceiptInvoiceData;
        calculateTotalInvoice(arrayInvoice);
        calculateTotalPPh23(arrayInvoice);
        calculateTotalCash(arrayInvoice, exRateCount);
        calculateTotalBank(arrayInvoice, exRateCount);
        calculateTotalTR(arrayInvoice);
        calculateTotalRV(arrayInvoice);
        calculateTotalDifferent(arrayInvoice, exRateCount, true);

        let arrayIdTR = [];
        let arrayIdRV = [];

        for (let i = 0; i < arrayInvoice.length; i++) {
            arrayIdInvoice = [...arrayIdInvoice, arrayInvoice[i].invoiceId];

            if (arrayInvoice[i].officialReceiptInvoiceTRs.length > 0) {
                for (let j = 0; j < arrayInvoice[i].officialReceiptInvoiceTRs.length; j++) {
                    arrayIdTR = [...arrayIdTR, arrayInvoice[i].officialReceiptInvoiceTRs[j].trDetailId];
                }
            }

            if (arrayInvoice[i].officialReceiptInvoiceRVs.length > 0) {
                for (let k = 0; k < arrayInvoice[i].officialReceiptInvoiceRVs.length; k++) {
                    arrayIdRV = [...arrayIdRV, arrayInvoice[i].officialReceiptInvoiceRVs[k].rvDetailId];
                }
            }

            if (tempDate === defaultDate) {
                if (arrayInvoice[i].officialReceiptInvoiceBanks.length > 0) {
                    checkBank = 1;

                    for (let l = 0; l < arrayInvoice[i].officialReceiptInvoiceBanks.length; l++) {
                        if (new Date(tempDate) < new Date(arrayInvoice[i].officialReceiptInvoiceBanks[l].dueDate)) {
                            tempDate = arrayInvoice[i].officialReceiptInvoiceBanks[l].dueDate;
                        }
                    }
                }
            }
        }

        if (checkBank === 0 && tempDate === defaultDate) {
            tempDate = tempCreateDate;
        }

        setDueDateLast(tempDate);

        setInvoiceIdList(arrayIdInvoice);
        setTRIdList(arrayIdTR);
        setRVIdList(arrayIdRV);

        localStorage.removeItem(username + '-DataOR');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id) => {
        let arrayInvoice = [];
        let exRateCount = 0;
        let arrayIdInvoice = [];
        let tempCreateDate = '';
        let tempDate = defaultDate;
        let checkBank = 0;

        let arrayIdTR = [];
        let arrayIdRV = [];
        ApiService.FinAccPostById(paramOfficialReceipt, id)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);
                    let officialReceiptData = response.data.data.officialReceipt;
                    exRateCount = officialReceiptData.rate;
                    setInfossNo(officialReceiptData.noInfoss);
                    setReference(officialReceiptData.reference);
                    setRowStatus(officialReceiptData.rowStatus);
                    setTicketId(officialReceiptData.ticketId);
                    setORNumber(officialReceiptData.orNo);
                    setORNo2(officialReceiptData.orNo2);
                    setPrinting(officialReceiptData.printing);
                    setPaymentByCode(officialReceiptData.paymentById);
                    setPrincipleBy(officialReceiptData.jobOwnerId);
                    setCustomerId(officialReceiptData.customerId);
                    setCustomerName(officialReceiptData.customerName);
                    setRateCountReal(officialReceiptData.rate);
                    handleDocumentStatus(officialReceiptData.rowStatus, officialReceiptData.isApprovedAcc);
                    setRateCount(convertToNominal(officialReceiptData.rate));
                    setRateId(officialReceiptData.exRateId);
                    setVerifyAcc(officialReceiptData.verifyAcc);
                    setIsApprovedAcc(officialReceiptData.isApprovedAcc);
                    setVerify(officialReceiptData.verifyAcc === true ? 'Yes' : 'No');
                    setPeriod(officialReceiptData.period);
                    setYearPeriod(officialReceiptData.yearPeriod);
                    setSaveOR(officialReceiptData.saveOR);
                    setRePrintApproved(officialReceiptData.rePrintApproved);
                    setRePrintApprovedBy(officialReceiptData.rePrintApprovedBy);
                    setDeletedRemarks(officialReceiptData.deletedRemarks);
                    setDeleted(officialReceiptData.deleted);
                    setWfComplete(officialReceiptData.wfComplete);

                    setAccountCode(officialReceiptData.coaCode);
                    setAccountName(officialReceiptData.coaName);

                    setDateFormat(officialReceiptData.printedOn, setPrintDate);
                    setDateFormat(officialReceiptData.exRateDate, setRateDate);
                    setDateFormat(officialReceiptData.approvedAccOn, setVerifyAccDate);
                    setDateFormat(officialReceiptData.rePrintApprovedOn, setRePrintApprovedOn);
                    setDateFormat(officialReceiptData.deletedOn, setDeletedOn);
                    tempDate = updateDateFormat(officialReceiptData.rePrintApprovedOn, true);
                    tempCreateDate = updateDateFormat(officialReceiptData.createdOn, true);

                    ApiService.PostById("regContact/regContact", officialReceiptData.customerId)
                        .then((res) => {
                            if (res.data.code === 200) {
                                if (res.data.data.contact.npwp === '00.000.000.0-000.000' || res.data.data.contact.npwp === '000.000.000.0-000.000') {
                                    setIsNPWP(false);
                                }
                                else {
                                    setIsNPWP(true);
                                }
                            }
                            else {
                                console.log('Error Read Reg Contact');
                            }
                        })
                        .catch(function (error) {
                            setIsLoadingBackDrop(false);
                            console.error('error saat fetch', error);
                            showToast({ type: 'error', message: error.toString() });
                        })

                    setInvoiceList(officialReceiptData.officialReceiptInvoices);
                    arrayInvoice = officialReceiptData.officialReceiptInvoices;
                }
                else {
                    Back();
                }
            })
            .then(() => {
                calculateTotalInvoice(arrayInvoice);
                calculateTotalPPh23(arrayInvoice);
                calculateTotalCash(arrayInvoice, exRateCount);
                calculateTotalBank(arrayInvoice, exRateCount);
                calculateTotalTR(arrayInvoice);
                calculateTotalRV(arrayInvoice);
                calculateTotalDifferent(arrayInvoice, exRateCount, true);

                for (let i = 0; i < arrayInvoice.length; i++) {
                    arrayIdInvoice = [...arrayIdInvoice, arrayInvoice[i].invoiceId];

                    if (arrayInvoice[i].officialReceiptInvoiceTRs.length > 0) {
                        for (let j = 0; j < arrayInvoice[i].officialReceiptInvoiceTRs.length; j++) {
                            arrayIdTR = [...arrayIdTR, arrayInvoice[i].officialReceiptInvoiceTRs[j].trDetailId];
                        }
                    }

                    if (arrayInvoice[i].officialReceiptInvoiceRVs.length > 0) {
                        for (let k = 0; k < arrayInvoice[i].officialReceiptInvoiceRVs.length; k++) {
                            arrayIdRV = [...arrayIdRV, arrayInvoice[i].officialReceiptInvoiceRVs[k].rvDetailId];
                        }
                    }

                    if (tempDate === getDefaultDateFormat(defaultDate)) {
                        if (arrayInvoice[i].officialReceiptInvoiceBanks.length > 0) {
                            checkBank = 1;

                            for (let l = 0; l < arrayInvoice[i].officialReceiptInvoiceBanks.length; l++) {
                                if (new Date(tempDate) < new Date(arrayInvoice[i].officialReceiptInvoiceBanks[l].dueDate)) {
                                    tempDate = arrayInvoice[i].officialReceiptInvoiceBanks[l].dueDate;
                                }
                            }
                        }
                    }
                }

                if (checkBank === 0 && tempDate === getDefaultDateFormat(defaultDate)) {
                    tempDate = tempCreateDate;
                }

                setDueDateLast(tempDate);

                setInvoiceIdList(arrayIdInvoice);
                setTRIdList(arrayIdTR);
                setRVIdList(arrayIdRV);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getCOAExchangeRateIn = () => {
        ApiService.CustomPostByPage('regJournalAccount/JournalAccount', 1, 50, 0, 'OR', [], '', 'EXREI').then((response) => {
            if (response.data.code === 200) {
                if (response.data.data.journalAccount[0] !== undefined) {
                    setAccountCodeExI(response.data.data.journalAccount[0].accountCode);
                    setAccountNameExI(response.data.data.journalAccount[0].accountName);

                    setAccountCodeExE(response.data.data.journalAccount[1].accountCode);
                    setAccountNameExE(response.data.data.journalAccount[1].accountName);
                }
                else {
                    Back();
                }
            }
        })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataApproved = () => {
        let data = {};
        ApiService.FinAccCustomPost(paramOfficialReceipt + '/Create?check=true', data)
            .then((res) => {
                if (res.data.code === 200 && res.data.data !== null) {
                    let data = res.data.data;

                    if (data != null) {
                        setIsUnapproved(true);

                        if (!officialReceiptId) {
                            Back();
                        }
                    }
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            });
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FORE')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        GetJobOwnerList(1, 125);
                        getCOAExchangeRateIn();
                        handleDocumentStatus();
                        getDataApproved();
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetJobOwnerList = (pageNumber, pageSize) => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setPrincipleBy(response.data.data.jobOwner[0].id);
                    }
                    setIsJobOwner(false);
                    setJobOwnerList(response.data.data.jobOwner);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getLocalData(); }, 2000);
                    }
                    else {
                        if (officialReceiptId) {
                            getData(officialReceiptId);
                        }
                        else {
                            setDateFormat(new Date(), setDueDateLast);
                            GetExRate();
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={PrincipleBy}
                onChange={(e) => handleCheckJobOwner(e.target.value)}
                disabled={Printing > 0 ? true : false}
                formControlProps={{
                    sx: {
                        marginTop: '16px !important'
                    }
                }}
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetExRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setRateId(response.data.data.exchangeRate.id);
                    setRateCountReal(response.data.data.exchangeRate.exRate1);
                    setRateCount(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setRateDate);

                    GetFormatCurrency();
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const GetFormatCurrency = () => {
        let totalUSD = 0;
        let totalIDR = 0;
        let formatUSD = convertToNominal(totalUSD);
        let formatIDR = convertToNominal(totalIDR);

        setTRInUSD(formatUSD);
        setTRInIDR(formatIDR);
        setTotalTRInIDR(formatIDR);

        setRVInUSD(formatUSD);
        setRVInIDR(formatIDR);
        setTotalRVInIDR(formatIDR);

        setTotalCashUSD(formatUSD);
        setTotalCashIDR(formatIDR);
        setTotalCashInIDR(formatIDR);

        setTotalBankUSD(formatUSD);
        setTotalBankIDR(formatIDR);
        setTotalBankInIDR(formatIDR);

        setDifferenceUSD(formatUSD);
        setDifferenceIDR(formatIDR);
        setTotalDifferenceIDR(formatIDR);

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    }

    function Save(isDeleted = false, isPrinting = false) {
        if (isLoadingBackDrop) return
        if (isDeleted === false) {
            setIsLoadingBackDrop(true);
        }

        let id = officialReceiptId ? officialReceiptId : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(VerifyAccDate, true);
        let dateDelete = updateDateFormat(DeletedOn, true);
        let datePrintApproved = updateDateFormat(RePrintApprovedOn, true);

        let ORInvoice = InvoiceList;
        ORInvoice.forEach(element => {
            let PPhSettle = updateDateFormat(element.isSettlePPh23On);

            if (PPhSettle === nullDate) {
                element.isSettlePPh23On = defaultDate;
            }
        });

        var data = {
            "officialReceipt": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": RowStatus,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": TicketId,
                "orNo": ORNumber,
                "orNo2": ORNo2,
                "paymentById": PaymentByCode,
                "customerId": CustomerId,
                "customerName": CustomerName,
                "rate": RateCountReal,
                "exRateDate": RateDate,
                "totalCashUSD": TotalCashUSDReal,
                "totalCashIDR": TotalCashIDRReal,
                "totalBankUSD": TotalBankUSDReal,
                "totalBankIDR": TotalBankIDRReal,
                "totalDPUSD": TRInUSDReal,
                "totalDPIDR": TRInIDRReal,
                "printing": Printing,
                "printedOn": datePrint,
                "approvedAccBy": VerifyAcc,
                "approvedAccOn": dateVerify,
                "isApprovedAcc": isApprovedAcc,
                "period": Period,
                "yearPeriod": YearPeriod,
                "difference": TotalDifferenceIDRReal,
                "saveOR": SaveOR,
                "jobOwnerId": PrincipleBy,
                "exRateId": RateId,
                "coaCode": AccountCode,
                "coaName": AccountName,
                'dueDate': dueDateLast,

                "rePrintApproved": RePrintApproved,
                "rePrintApprovedOn": datePrintApproved,
                "rePrintApprovedBy": RePrintApprovedBy,
                "deletedRemarks": DeletedRemarks,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "wfComplete": WfComplete,
                "idLama": 0,
            },
            "officialReceiptInvoices": ORInvoice,
        };

        let errorMessage = undefined;

        if (data.officialReceiptInvoices.length === 0) {
            errorMessage = 'Please Add Data Invoice!';
        }
        else if (data.officialReceipt.noInfoss === '') {
            errorMessage = 'Please Fill Infoss Number!';
        }
        else {
            let sum = 0;
            ORInvoice.forEach(element => {
                sum = element.totalCashUSD + sum;
                sum = element.totalCashIDR + sum;
                sum = element.totalBankUSD + sum;
                sum = element.totalBankIDR + sum;
            });

            if (sum === 0) {
                errorMessage = 'Please Add At Least 1 Payment Method!';
            }
        }

        if (errorMessage === undefined) {
            ApiService.HelpCreateData(data, paramOfficialReceipt, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (id === 0) {
                            ApiService.CreateFinAcc(paramOfficialReceipt, data).then((res) => {
                                if (res.data.code === 200) {
                                    setIsLoadingBackDrop(false);
                                    message = 'Create Data Success';

                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                            history('/Majura/OfficialReceipt/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                        else {
                            if (Printing === 0) {
                                ApiService.EditFinAcc(paramOfficialReceipt, data).then((res) => {
                                    if (res.data.code === 200) {
                                        if (isPrinting === false) {
                                            setIsLoadingBackDrop(false);
                                            message = 'Update Data Success';

                                            showToast({ type: 'success', message: message });

                                            setTimeout(
                                                function () {
                                                    history('/Majura/OfficialReceipt/' + officialReceiptId, { replace: true });
                                                    window.location.reload();
                                                },
                                                1250);
                                        }
                                        else {
                                            Print();
                                        }
                                    }
                                    else {
                                        setIsLoadingBackDrop(false);
                                        message = 'Updated Data Failed';
                                        showToast({ type: 'error', message: res.data.message });
                                    }
                                });
                            }
                            else {
                                if (isPrinting === true) {
                                    Print();
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                }
                            }
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Create Help Data!' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!' });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    function Back() {
        history('/Majura/OfficialReceipt');
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
            setIsDueDate(false);
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    function ConfirmationOR() {
        if (titleConfirmation === 'Print') {
            if (isApprovedAcc === false) {
                Save(false, true);
            }
            else {
                Print();
            }
        }
        else if (titleConfirmation === 'Approve') {
            Approve();
        }
        else if (titleConfirmation === 'Un Approve') {
            UnApprove();
        }
        else if (titleConfirmation === 'Reprint') {
            RePrint();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setIsLoadingBackDrop(true);
        if (officialReceiptId) {
            ApiService.FinAccPostById(paramOfficialReceipt, officialReceiptId)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.officialReceipt;
                        if (data.rePrintApproved) {
                            handlePrint();
                        }
                        else {
                            setIsDueDate(false);
                            setIsLoadingBackDrop(false);
                            setIsModalConfirmation(false);
                            showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        setIsModalConfirmation(false);
                        showToast({ type: 'error', message: 'Error When Read Data!' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    setIsModalConfirmation(false);
                    showToast({ type: 'error', message: 'Can\'t Print Data, ' + error });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const handlePrint = () => {
        setIsLoadingBackDrop(true);
        setTitleConfirmation('Print');
        setTitleModalConfirmation('Official Receipt');

        if (Printing === 0) {
            getDateNow();
        }
        else {
            setDateFormat(PrintDate, setDueDate);
        }

        if (TotalCashIDRReal === 0 && TotalCashUSDReal === 0) {
            setIsDueDate(true);
        }

        getBackDatePeriod()
            .then((backDate) => {
                setBackDate(backDate);
                handleClickConfirmation();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoadingBackDrop(false);
            });
    }

    const Print = () => {
        let dateDue = dueDate === '' ? updateDateFormat(new Date()) : dueDate;
        ApiService.FinAccBackDatePrint(paramOfficialReceipt, officialReceiptId, dateDue).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                let count = parseFloat(Printing) + parseFloat(1);
                setPrinting(count);

                PrintPDF();
                setIsDueDate(false);
            }
            else {
                setIsDueDate(false);
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: message });
            }
        });
    }

    const PrintPDF = () => {
        let variable = 'PrintOR?Id=' + officialReceiptId;
        ApiService.ReportPrint('Transactions/Finance/OfficialReceiptReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Official Receipt Success!' });
                    window.open(fileURL);

                    setTimeout(
                        function () {
                            history('/Majura/OfficialReceipt/' + officialReceiptId, { replace: true });
                            window.location.reload();
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Official Receipt!' });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Official Receipt!' });
            });
    }

    const RePrintData = () => {
        if (!officialReceiptId) {
            showToast({ type: 'error', message: 'Create The Data First!' });
            setIsModalConfirmation(false);
        }
        else {
            setTitleConfirmation('Reprint')
            setTitleModalConfirmation('Official Receipt');
            handleClickConfirmation();
        }
    }

    const RePrint = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusRePrint(paramOfficialReceipt, officialReceiptId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'success', message: message });

                setTimeout(
                    function () {
                        history('/Majura/OfficialReceipt/' + officialReceiptId, { replace: true });
                        window.location.reload();
                    },
                    1250);
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    };

    const CreateGL = (id) => {
        var data = {
            "generalLedger": {
                "source": "OR",
                "refId": id,
                "methodPost": "AUTO",
                "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
            }
        }

        ApiService.CreateFinAcc(paramGL, data).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code !== 200) {
                showToast({ type: 'error', message: message });
            }
        });
    }

    function AddNew() {
        history('/Majura/OfficialReceipt/Create', { replace: true });
        window.location.reload();
    }

    const ApproveData = () => {
        if (officialReceiptId) {
            setIsLoadingBackDrop(true);
            ApiService.FinAccPostById(paramOfficialReceipt, officialReceiptId)
                .then((response) => {
                    if (response.data.code === 200) {
                        setIsLoadingBackDrop(false);
                        let data = response.data.data.officialReceipt;

                        if (data.printing > 0) {
                            if (data.isApprovedAcc === false) {
                                setTitleConfirmation('Approve')
                                setTitleModalConfirmation('Memorial');
                                handleClickConfirmation();
                            }
                            else {
                                showToast({ type: 'error', message: 'Data Already Been Approved!' });
                            }
                        }
                        else {
                            showToast({ type: 'error', message: 'Please Print The Data First!' });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Error When Read Data!, ' + response.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Read Data!, ' + error });
                })
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const Approve = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusApprovedAcc(paramOfficialReceipt, officialReceiptId)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    CreateGL(officialReceiptId);

                    showToast({ type: 'success', message: message });

                    setTimeout(
                        function () {
                            history('/Majura/OfficialReceipt/' + officialReceiptId, { replace: true });
                            window.location.reload();
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Approve Official Receipt!' });
            });
    }

    const UnApproveData = () => {
        if (officialReceiptId) {
            setIsLoadingBackDrop(true);
            ApiService.FinAccPostById(paramOfficialReceipt, officialReceiptId)
                .then((response) => {
                    if (response.data.code === 200) {
                        setIsLoadingBackDrop(false);
                        let data = response.data.data.officialReceipt;

                        if (data.printing > 0) {
                            if (data.isApprovedAcc === true) {
                                setTitleConfirmation('Un Approve')
                                setTitleModalConfirmation('Official Receipt');
                                handleClickConfirmation();
                            }
                            else {
                                showToast({ type: 'error', message: 'Data Hasn\'t Been Approved Yet!' });
                            }
                        }
                        else {
                            showToast({ type: 'error', message: 'Please Print The Data First!' });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Error When Read Data!, ' + response.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Read Data!, ' + error });
                })
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const UnApprove = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusUnApprovedAcc(paramOfficialReceipt, officialReceiptId)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    CreateGL(res.data.data.ContraId);

                    showToast({ type: 'success', message: message });

                    setTimeout(
                        function () {
                            history('/Majura/OfficialReceipt/' + officialReceiptId, { replace: true });
                            window.location.reload();
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Un Approve Official Receipt!' });
            });
    }

    function resetDataList() {
        setInvoiceList([]);
        setTRList([]);
        setRVList([]);
        setBankData([]);
        setInvoiceUSD(0);
        setInvoiceIDR(0);
        setTotalInvoiceIDR(0);
        setTRInUSDReal(0);
        setTRInIDRReal(0);
        setTotalTRInIDRReal(0);
        setRVInUSDReal(0);
        setRVInIDRReal(0);
        setTotalRVInIDRReal(0);
        setTotalPPh(0);
        setCashPaymentUSD(0);
        setCashPaymentIDR(0);
        setTotalCashPaymentIDR(0);
        setTotalCashUSDReal(0);
        setTotalCashIDRReal(0);
        setTotalCashInIDRReal(0);
        setTotalBankUSDReal(0);
        setTotalBankIDRReal(0);
        setTotalBankInIDRReal(0);
        setDifferenceUSDReal(0);
        setDifferenceIDRReal(0);
        setTotalDifferenceIDRReal(0);
        GetFormatCurrency();
    }

    function calculateTotalInvoice(result) {
        let totalUSD = 0;
        let totalIDR = 0;
        let totalPayment = 0;

        if (result === undefined || result === null) {
            for (let i = 0; i < InvoiceList.length; i++) {
                totalUSD = parseFloat(InvoiceList[i].paymentUSD) + parseFloat(totalUSD);
                totalIDR = parseFloat(InvoiceList[i].paymentIDR) + parseFloat(totalIDR);
                totalPayment = parseFloat((parseFloat(InvoiceList[i].paymentUSD)) * parseFloat(InvoiceList[i].rate))
                    + parseFloat(InvoiceList[i].paymentIDR) + parseFloat(totalPayment) - parseFloat(InvoiceList[i].pPh23Amount);
            }
        }
        else {
            for (let i = 0; i < result.length; i++) {
                totalUSD = parseFloat(result[i].paymentUSD) + parseFloat(totalUSD);
                totalIDR = parseFloat(result[i].paymentIDR) + parseFloat(totalIDR);
                totalPayment = parseFloat((parseFloat(result[i].paymentUSD)) * parseFloat(result[i].rate))
                    + parseFloat(result[i].paymentIDR) + parseFloat(totalPayment) - parseFloat(result[i].pPh23Amount);;
            }
        }

        setInvoiceUSD(totalUSD);
        setInvoiceIDR(totalIDR);
        setTotalInvoiceIDR(totalPayment);
    }

    function calculateTotalTR(result) {
        let totalUSD = 0;
        let totalIDR = 0;
        let totalInIDR = 0;

        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].officialReceiptInvoiceTRs.length; j++) {
                totalUSD = parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentUSD) + parseFloat(totalUSD);
                totalIDR = parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentIDR) + parseFloat(totalIDR);
                totalInIDR = (parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentUSD) * parseFloat(result[i].officialReceiptInvoiceTRs[j].rate)) +
                    parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentIDR) + parseFloat(totalInIDR);
            }
        }

        setTRInUSDReal(totalUSD);
        setTRInIDRReal(totalIDR);
        setTotalTRInIDRReal(totalInIDR);

        let formatUSD = convertToNominal(totalUSD);
        let formatIDR = convertToNominal(totalIDR);
        let formatInIDR = convertToNominal(totalInIDR);

        setTRInUSD(formatUSD);
        setTRInIDR(formatIDR);
        setTotalTRInIDR(formatInIDR);
    }

    function calculateTotalRV(result) {
        let totalUSD = 0;
        let totalIDR = 0;
        let totalInIDR = 0;

        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].officialReceiptInvoiceRVs.length; j++) {
                totalUSD = parseFloat(result[i].officialReceiptInvoiceRVs[j].paymentUSD) + parseFloat(totalUSD);
                totalIDR = parseFloat(result[i].officialReceiptInvoiceRVs[j].paymentIDR) + parseFloat(totalIDR);
                totalInIDR = (parseFloat(result[i].officialReceiptInvoiceRVs[j].paymentUSD) * parseFloat(result[i].officialReceiptInvoiceRVs[j].rate)) +
                    parseFloat(result[i].officialReceiptInvoiceRVs[j].paymentIDR) + parseFloat(totalInIDR);
            }
        }

        setRVInUSDReal(totalUSD);
        setRVInIDRReal(totalIDR);
        setTotalRVInIDRReal(totalInIDR);

        let formatUSD = convertToNominal(totalUSD);
        let formatIDR = convertToNominal(totalIDR);
        let formatInIDR = convertToNominal(totalInIDR);

        setRVInUSD(formatUSD);
        setRVInIDR(formatIDR);
        setTotalRVInIDR(formatInIDR);
    }

    function calculateTotalPPh23(result) {
        let count = 0;

        if (result === undefined || result === null) {
            for (let i = 0; i < InvoiceList.length; i++) {
                count = parseFloat(count) + parseFloat(InvoiceList[i].pPh23Amount);
            }
        }
        else {
            for (let i = 0; i < result.length; i++) {
                count = parseFloat(count) + parseFloat(result[i].pPh23Amount);
            }
        }

        setTotalPPh(count);
    }

    function calculateTotalCash(result, exRate) {
        let count1 = 0;
        let count2 = 0;
        let count3 = 0;

        for (let i = 0; i < result.length; i++) {
            count1 = parseFloat(count1) + parseFloat(result[i].totalCashUSD);
            count2 = parseFloat(count2) + parseFloat(result[i].totalCashIDR);
        }

        if (exRate !== undefined) {
            count3 = parseFloat(parseFloat(count1) * parseFloat(exRate)) + parseFloat(count2);
        }
        else {
            count3 = parseFloat(parseFloat(count1) * parseFloat(RateCount)) + parseFloat(count2);
        }

        setTotalCashUSDReal(count1);
        setTotalCashIDRReal(count2);
        setTotalCashInIDRReal(count3);

        let formatUSD = convertToNominal(count1);
        let formatIDR = convertToNominal(count2);
        let formatInIDR = convertToNominal(count3);

        setTotalCashUSD(formatUSD);
        setTotalCashIDR(formatIDR);
        setTotalCashInIDR(formatInIDR);
    }

    function calculateTotalBank(result, exRate) {
        let count1 = 0;
        let count2 = 0;
        let count3 = 0;

        let count4 = 0;
        let count5 = 0;

        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].officialReceiptInvoiceBanks.length; j++) {
                if (result[i].officialReceiptInvoiceBanks[j].amountCrr === 0) {
                    count1 = parseFloat(count1) + parseFloat(result[i].officialReceiptInvoiceBanks[j].amount);
                }
                else if (result[i].officialReceiptInvoiceBanks[j].amountCrr === 1) {
                    count2 = parseFloat(count2) + parseFloat(result[i].officialReceiptInvoiceBanks[j].amount);
                }

                if (checkSelectedInvoice.id !== undefined) {
                    if (result[i].invoiceId === checkSelectedInvoice.invoiceId) {
                        if (result[i].officialReceiptInvoiceBanks[j].amountCrr === 0) {
                            count4 = parseFloat(count4) + parseFloat(result[i].officialReceiptInvoiceBanks[j].amount);
                        }
                        else if (result[i].officialReceiptInvoiceBanks[j].amountCrr === 1) {
                            count5 = parseFloat(count5) + parseFloat(result[i].officialReceiptInvoiceBanks[j].amount);
                        }
                    }
                }
            }
        }

        if (checkSelectedInvoice.id !== undefined) {
            if (result.length > 0) {
                const newArray = result.slice();
                newArray.forEach(el => {
                    if (el.invoiceId === checkSelectedInvoice.invoiceId) {
                        el.totalBankUSD = count4;
                        el.totalBankIDR = count5;
                    }
                });
                setInvoiceList(newArray);
            }
        }

        if (exRate !== undefined) {
            count3 = parseFloat(parseFloat(count1) * parseFloat(exRate)) + parseFloat(count2);
        }
        else {
            count3 = parseFloat(parseFloat(count1) * parseFloat(RateCount)) + parseFloat(count2);
        }

        setTotalBankUSDReal(count1);
        setTotalBankIDRReal(count2);
        setTotalBankInIDRReal(count3);

        let formatUSD = convertToNominal(count1);
        let formatIDR = convertToNominal(count2);
        let formatInIDR = convertToNominal(count3);

        setTotalBankUSD(formatUSD);
        setTotalBankIDR(formatIDR);
        setTotalBankInIDR(formatInIDR);
    }

    function calculateTotalDifferent(result, exRate, isEdit = false) {
        let check = 0;
        // let checkPayment = false;

        let countInvUSD = 0;
        let countInvIDR = 0;
        let countInvInIDR = 0;

        let countTRUSD = 0;
        let countTRIDR = 0;
        let countTRInIDR = 0;

        let countRVUSD = 0;
        let countRVIDR = 0;
        let countRVInIDR = 0;

        let countPPH23 = 0;

        let countCashUSD = 0;
        let countCashIDR = 0;
        let countCashInIDR = 0;

        let countBankUSD = 0;
        let countBankIDR = 0;
        let countBankInIDR = 0;

        let differentUSD = 0;
        let differentIDR = 0;
        let differentInIDR = 0;

        for (let i = 0; i < result.length; i++) {
            countInvUSD = parseFloat(result[i].paymentUSD) + parseFloat(countInvUSD);
            countInvIDR = parseFloat(result[i].paymentIDR) + parseFloat(countInvIDR);
            countInvInIDR = parseFloat((parseFloat(result[i].paymentUSD)) * parseFloat(result[i].rate)) + parseFloat(result[i].paymentIDR) + parseFloat(countInvInIDR);

            countPPH23 = parseFloat(countPPH23) + parseFloat(result[i].pPh23Amount);

            countCashUSD = parseFloat(countCashUSD) + parseFloat(result[i].totalCashUSD);
            countCashIDR = parseFloat(countCashIDR) + parseFloat(result[i].totalCashIDR);

            for (let j = 0; j < result[i].officialReceiptInvoiceTRs.length; j++) {
                countTRUSD = parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentUSD) + parseFloat(countTRUSD);
                countTRIDR = parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentIDR) + parseFloat(countTRIDR);
                countTRInIDR = (parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentUSD) * parseFloat(result[i].officialReceiptInvoiceTRs[j].rate)) +
                    parseFloat(result[i].officialReceiptInvoiceTRs[j].paymentIDR) + parseFloat(countTRInIDR);
            }

            for (let k = 0; k < result[i].officialReceiptInvoiceRVs.length; k++) {
                countRVUSD = parseFloat(result[i].officialReceiptInvoiceRVs[k].paymentUSD) + parseFloat(countRVUSD);
                countRVIDR = parseFloat(result[i].officialReceiptInvoiceRVs[k].paymentIDR) + parseFloat(countRVIDR);
                countRVInIDR = (parseFloat(result[i].officialReceiptInvoiceRVs[k].paymentUSD) * parseFloat(result[i].officialReceiptInvoiceRVs[k].rate)) +
                    parseFloat(result[i].officialReceiptInvoiceRVs[k].paymentIDR) + parseFloat(countRVInIDR);
            }

            for (let l = 0; l < result[i].officialReceiptInvoiceBanks.length; l++) {
                check = 1;
                if (result[i].officialReceiptInvoiceBanks[l].amountCrr === 0) {
                    countBankUSD = parseFloat(countBankUSD) + parseFloat(result[i].officialReceiptInvoiceBanks[l].amount);
                }
                else if (result[i].officialReceiptInvoiceBanks[l].amountCrr === 1) {
                    countBankIDR = parseFloat(countBankIDR) + parseFloat(result[i].officialReceiptInvoiceBanks[l].amount);
                }
            }
        }

        if (exRate !== undefined) {
            countCashInIDR = parseFloat(parseFloat(countCashUSD) * parseFloat(exRate)) + parseFloat(countCashIDR);
            countBankInIDR = parseFloat(parseFloat(countBankUSD) * parseFloat(exRate)) + parseFloat(countBankIDR);
        }
        else {
            countCashInIDR = parseFloat(parseFloat(countCashUSD) * parseFloat(RateCount)) + parseFloat(countCashIDR);
            countBankInIDR = parseFloat(parseFloat(countBankUSD) * parseFloat(RateCount)) + parseFloat(countBankIDR);
        }

        // if (countCashInIDR === 0 && countBankInIDR === 0) {
        //     checkPayment = true;
        // }

        differentUSD = parseFloat(countInvUSD) - (parseFloat(countCashUSD) + parseFloat(countBankUSD) + parseFloat(countRVUSD) + parseFloat(countTRUSD) + parseFloat(0));
        differentIDR = parseFloat(countInvIDR) - (parseFloat(countCashIDR) + parseFloat(countBankIDR) + parseFloat(countRVIDR) + parseFloat(countTRIDR) + parseFloat(countPPH23) + parseFloat(0));
        differentInIDR = parseFloat(countInvInIDR) - (parseFloat(countCashInIDR) + parseFloat(countBankInIDR) + parseFloat(countRVInIDR) + parseFloat(countTRInIDR) + parseFloat(countPPH23) + parseFloat(0));

        // if (checkPayment) {
        setTempDiffIDR(differentIDR);
        setTempDiffUSD(differentUSD);
        // }

        if (isEdit === false) {
            if (check === 1 || (countCashUSD !== 0 || countCashIDR !== 0)) {
                if (differentInIDR > 0) {
                    if (AccountCode === '') {
                        setAccountCode(AccountCodeExE);
                        setAccountName(AccountNameExE);
                    }
                }
                else if (differentInIDR < 0) {
                    if (AccountCode === '') {
                        setAccountCode(AccountCodeExI);
                        setAccountName(AccountNameExI);
                    }
                }
                else {
                    setAccountCode('');
                    setAccountName('');
                }
            }
            else {
                setAccountCode('');
                setAccountName('');
            }
        }

        setDifferenceUSDReal(differentUSD);
        setDifferenceIDRReal(differentIDR);
        setTotalDifferenceIDRReal(differentInIDR);

        let formatUSD = convertToNominal(differentUSD);
        let formatIDR = convertToNominal(differentIDR);
        let formatInIDR = convertToNominal(differentInIDR);

        setDifferenceUSD(formatUSD);
        setDifferenceIDR(formatIDR);
        setTotalDifferenceIDR(formatInIDR);
    }

    function handleCheckJobOwner(val) {
        setPrincipleBy(val);
        if (isEdit === false) {
            if (val !== undefined || val !== 0 || val !== null || val !== '') {
                setIsJobOwner(false);
            }
            else {
                setIsJobOwner(true);
            }
        }
        else {
            setIsJobOwner(true);
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditOfficialReceipt
                Save={Save}
                Back={Back}
                Print={PrintData}
                Reprint={RePrintData}
                Approve={ApproveData}
                UnApprove={UnApproveData}
                AddNew={AddNew}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                isApprovedAcc={isApprovedAcc}
                isUnapproved={isUnapproved}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '0px 32px 24px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            officialReceiptId ?
                                'Official Receipt (' + reference + ')'
                                :
                                'Official Receipt ( New Document )'
                        }
                        BreadcrumbsItems={officialReceiptId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={TicketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                        isSticky={true}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <OfficialReceiptDetail
                                infossNo={infossNo}
                                setInfossNo={setInfossNo}
                                setCustomerId={setCustomerId}
                                setCustomerName={setCustomerName}
                                setIsNPWP={setIsNPWP}
                                setInvoiceList={setInvoiceList}
                                setInvoiceIdList={setInvoiceIdList}
                                calculateTotalInvoice={calculateTotalInvoice}
                                calculateTotalTR={calculateTotalTR}
                                calculateTotalRV={calculateTotalRV}
                                calculateTotalCash={calculateTotalCash}
                                calculateTotalBank={calculateTotalBank}
                                calculateTotalDifferent={calculateTotalDifferent}
                                setPaymentByCode={setPaymentByCode}
                                PaymentByCode={PaymentByCode}
                                resetDataList={resetDataList}
                                ORNumber={ORNumber}
                                PrintDate={PrintDate}
                                VerifyAccDate={VerifyAccDate}
                                RateCount={RateCount}
                                RateDate={RateDate}
                                selectPrincipleBy={selectPrincipleBy}
                                isEdit={isEdit}
                                isJobOwner={isJobOwner}
                                Printing={Printing}
                                CustomerId={CustomerId}
                                CustomerName={CustomerName}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <OfficialReceiptPayment
                                CustomerId={CustomerId}
                                PaymentByCode={PaymentByCode}
                                officialReceiptId={officialReceiptId}
                                calculateTotalCash={calculateTotalCash}
                                calculateTotalBank={calculateTotalBank}
                                setInvoiceIdList={setInvoiceIdList}
                                calculateTotalInvoice={calculateTotalInvoice}
                                calculateTotalTR={calculateTotalTR}
                                calculateTotalRV={calculateTotalRV}
                                calculateTotalDifferent={calculateTotalDifferent}
                                checkSelectedInvoice={checkSelectedInvoice}
                                RateCount={RateCountReal}
                                InvoiceList={InvoiceList}
                                setInvoiceList={setInvoiceList}
                                CashPaymentUSD={CashPaymentUSD}
                                setCashPaymentUSD={setCashPaymentUSD}
                                CashPaymentIDR={CashPaymentIDR}
                                setCashPaymentIDR={setCashPaymentIDR}
                                TotalCashPaymentIDR={TotalCashPaymentIDR}
                                setTotalCashPaymentIDR={setTotalCashPaymentIDR}
                                BankData={BankData}
                                setBankData={setBankData}
                                Printing={Printing}
                                TotalCashUSD={TotalCashUSD}
                                setTotalCashUSD={setTotalCashUSD}
                                TotalCashIDR={TotalCashIDR}
                                setTotalCashIDR={setTotalCashIDR}
                                TotalCashInIDR={TotalCashInIDR}
                                setTotalCashInIDR={setTotalCashInIDR}
                                TotalBankUSD={TotalBankUSD}
                                setTotalBankUSD={setTotalBankUSD}
                                TotalBankIDR={TotalBankIDR}
                                setTotalBankIDR={setTotalBankIDR}
                                TotalBankInIDR={TotalBankInIDR}
                                setTotalBankInIDR={setTotalBankInIDR}
                                DifferenceUSD={DifferenceUSD}
                                setDifferenceUSD={setDifferenceUSD}
                                DifferenceIDR={DifferenceIDR}
                                setDifferenceIDR={setDifferenceIDR}
                                TotalDifferenceIDR={TotalDifferenceIDR}
                                setTotalDifferenceIDR={setTotalDifferenceIDR}
                                AccountCode={AccountCode}
                                setAccountCode={setAccountCode}
                                AccountName={AccountName}
                                setAccountName={setAccountName}
                                TRInUSDReal={TRInUSDReal}
                                setTRInUSDReal={setTRInUSDReal}
                                TRInIDRReal={TRInIDRReal}
                                setTRInIDRReal={setTRInIDRReal}
                                TotalTRInIDRReal={TotalTRInIDRReal}
                                setTotalTRInIDRReal={setTotalTRInIDRReal}
                                RVInUSDReal={RVInUSDReal}
                                setRVInUSDReal={setRVInUSDReal}
                                RVInIDRReal={RVInIDRReal}
                                setRVInIDRReal={setRVInIDRReal}
                                TotalRVInIDRReal={TotalRVInIDRReal}
                                setTotalRVInIDRReal={setTotalRVInIDRReal}
                                TotalCashUSDReal={TotalCashUSDReal}
                                setTotalCashUSDReal={setTotalCashUSDReal}
                                TotalCashIDRReal={TotalCashIDRReal}
                                setTotalCashIDRReal={setTotalCashIDRReal}
                                TotalCashInIDRReal={TotalCashInIDRReal}
                                setTotalCashInIDRReal={setTotalCashInIDRReal}
                                TotalBankUSDReal={TotalBankUSDReal}
                                setTotalBankUSDReal={setTotalBankUSDReal}
                                TotalBankIDRReal={TotalBankIDRReal}
                                setTotalBankIDRReal={setTotalBankIDRReal}
                                TotalBankInIDRReal={TotalBankInIDRReal}
                                setTotalBankInIDRReal={setTotalBankInIDRReal}
                                DifferenceUSDReal={DifferenceUSDReal}
                                setDifferenceUSDReal={setDifferenceUSDReal}
                                DifferenceIDRReal={DifferenceIDRReal}
                                setDifferenceIDRReal={setDifferenceIDRReal}
                                TotalDifferenceIDRReal={TotalDifferenceIDRReal}
                                setTotalDifferenceIDRReal={setTotalDifferenceIDRReal}
                                dueDateLast={dueDateLast}
                                setDueDateLast={setDueDateLast}
                                TempDiffUSD={TempDiffUSD}
                                TempDiffIDR={TempDiffIDR}

                                calculateTotalPPh23={calculateTotalPPh23}
                                ORId={ORId}
                                setORId={setORId}
                                setORNumber={setORNumber}
                                setPrintDate={setPrintDate}
                                Verify={Verify}
                                setVerify={setVerify}
                                PrincipleBy={PrincipleBy}
                                setPrincipleBy={setPrincipleBy}
                                setRateCount={setRateCount}
                                setRateDate={setRateDate}
                                PPH23List={PPH23List}
                                setPPH23List={setPPH23List}
                                TRList={TRList}
                                setTRList={setTRList}
                                RVList={RVList}
                                setRVList={setRVList}
                                InvoiceUSD={InvoiceUSD}
                                setInvoiceUSD={setInvoiceUSD}
                                InvoiceIDR={InvoiceIDR}
                                setInvoiceIDR={setInvoiceIDR}
                                TotalInvoiceIDR={TotalInvoiceIDR}
                                setTotalInvoiceIDR={setTotalInvoiceIDR}
                                TRInUSD={TRInUSD}
                                setTRInUSD={setTRInUSD}
                                TRInIDR={TRInIDR}
                                setTRInIDR={setTRInIDR}
                                TotalTRInIDR={TotalTRInIDR}
                                setTotalTRInIDR={setTotalTRInIDR}
                                RVInUSD={RVInUSD}
                                setRVInUSD={setRVInUSD}
                                RVInIDR={RVInIDR}
                                setRVInIDR={setRVInIDR}
                                TotalRVInIDR={TotalRVInIDR}
                                setTotalRVInIDR={setTotalRVInIDR}
                                TotalPPh={TotalPPh}
                                setTotalPPh={setTotalPPh}
                                setCheckSelectedInvoice={setCheckSelectedInvoice}
                                invoiceIdList={invoiceIdList}
                                TRIdList={TRIdList}
                                setTRIdList={setTRIdList}
                                RVIdList={RVIdList}
                                setRVIdList={setRVIdList}
                                isNPWP={isNPWP}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationOR}
                            isDueDate={isDueDate}
                            setIsDueDate={setIsDueDate}
                            dueDate={dueDate}
                            backDate={backDate}
                            setDueDate={setDueDate}
                            PrintCount={Printing}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditOfficialReceipt;