import { Grid } from '@mui/material';
import * as React from 'react';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonReport = ({ handleExport, isMarketing, isFinance, isOperation, isBooking }) => {
  const buttonList = [
    {
      name: 'Marketing',
      dropDownList: [
        { label: 'Marketing', icon: 'chart', function: true, functionName: null, hidden: true },
        { label: 'Print - PDF', icon: 'pdf-export', function: true, functionName: () => handleExport('marketing', 'pdf') },
        { label: 'Print - Excel', icon: 'excel', function: true, functionName: () => handleExport('marketing', 'excel') }
      ],
      hidden: isMarketing,
    },
    {
      name: 'Finance',
      dropDownList: [
        { label: 'Finance', icon: 'line-graph', function: true, functionName: null, hidden: true },
        { label: 'Print - PDF', icon: 'pdf-export', function: true, functionName: () => handleExport('finance', 'pdf') },
        { label: 'Print - Excel', icon: 'excel', function: true, functionName: () => handleExport('finance', 'excel') }
      ],
      hidden: isFinance,
    },
    {
      name: 'Operation',
      dropDownList: [
        { label: 'Operation', icon: 'briefcase', function: true, functionName: null, hidden: true },
        { label: 'Print - PDF', icon: 'pdf-export', function: true, functionName: () => handleExport('operation', 'pdf') },
        { label: 'Print - Excel', icon: 'excel', function: true, functionName: () => handleExport('operation', 'excel') }
      ],
      hidden: isOperation,
    },
    {
      name: 'Rekap BC',
      dropDownList: [
        { label: 'Rekap BC', icon: 'approve-doc', function: true, functionName: null, hidden: true },
        { label: 'Print - PDF', icon: 'pdf-export', function: true, functionName: () => handleExport('bc', 'pdf') },
        { label: 'Print - Excel', icon: 'excel', function: true, functionName: () => handleExport('bc', 'excel') }
      ],
      hidden: isBooking,
    }
  ]

  return (
    <div className="row" spacing={'12px'}>
      <div className='col-12' spacing={'12px'}>
        <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
          {
            buttonList.map((btn, index) => (
              <React.Fragment key={index}>
                {btn.hidden && CustomizeButtonComponent.CustomDropDown(btn.name, btn.dropDownList)}
              </React.Fragment>
            ))
          }
        </Grid>
      </div>
    </div>
  )
}

export default ButtonReport