import { IconButton, InputAdornment } from '@mui/material';
import React from 'react';
import ApiService, { userCode } from '../../../../services/ApiService';
import ButtonService from '../../ButtonService';
import { useState } from 'react';
import SelectDataMaster from '../SelectDataMaster';
import { Icon, Input, showToast } from '../../../../components/ui';
import CreateEditModalMaster from '../../CreateEditModalMaster';

const CreateEditSitesWarehouses = (props) => {
    const paramWarehouses = "warehouse/warehouses/warehouses";
    const paramSites = "warehouse/sites/sites";

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(50);

    const [titleModalUnit, setTitleModalUnit] = useState('');
    const [isModalUnit, setIsModalUnit] = useState(false);
    const [isLoadingModalUnit, setIsLoadingModalUnit] = useState(false);
    const [dataModalUnit, setDataModalUnit] = useState([]);
    const [dataMapModalUnit, setDataMapModalUnit] = useState([]);
    const [columnDataModalUnit, setColumnDataModalUnit] = useState([]);
    const [SelectedDataModalUnit, setSelectedDataModalUnit] = useState({});
    const [totalRowsModalUnit, setTotalRowsModalUnit] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataWarehouses(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClickUnit = () => {
        if (isModalUnit === false) {
            setIsModalUnit(true);
            getDataSites(1, 50);
        }
        else {
            setIsModalUnit(false);
        }
    };

    const getDataWarehouses = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(paramWarehouses, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({})
                    setDataModal(response.data.data.warehouses);

                    let temp = response.data.data.warehouses
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModal(indexed)
                    setColumnDataModal(response.data.data.columns)
                    setTotalRowsModal(response.data.totalRowCount)

                    setIsLoadingModal(false)
                }
                setIsLoadingModal(false)
            })
            .catch(function (error) {
                setIsLoadingModal(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    };

    const getDataSites = (pageNumber, pageSize, filters) => {
        setIsLoadingModalUnit(true);
        ApiService.PostByPage(paramSites, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalUnit({})
                    setDataModalUnit(response.data.data.sites);

                    let temp = response.data.data.sites
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalUnit(indexed)
                    setColumnDataModalUnit(response.data.data.columns)
                    setTotalRowsModalUnit(response.data.totalRowCount)

                    setIsLoadingModalUnit(false)
                }
                setIsLoadingModalUnit(false)
            })
            .catch(function (error) {
                setIsLoadingModalUnit(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    };

    const searchWarehouse = () => {
        setTitleModal("Warehouse");
        handleClick();
    }

    const searchSite = () => {
        setTitleModalUnit("Site");
        handleClickUnit();
    }

    const Save = () => {
        var data =
        {
            "sitesWarehouses": {
                "rowStatus": "ACT",
                "id": props.id === 0 ? "" : props.id.toString(),
                "warehousesId": props.warehouseId,
                "sitesId": props.siteId,
                "userCode": userCode,
            }
        }
        // ButtonService.Save(props.titleModal, props.title, props.param, data);
        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                label="Site"
                value={props.siteName}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => searchSite()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                label="Warehouse"
                value={props.warehouseName}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => searchWarehouse()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataWarehouses}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}

                        setId={props.setWarehouseId}
                        setCode={() => { }}
                        setName={props.setWarehouseName}
                    />
                )
            }

            {
                isModalUnit && (
                    <SelectDataMaster
                        isModal={isModalUnit}
                        handleClick={handleClickUnit}
                        titleModal={titleModalUnit}
                        setIsModal={setIsModalUnit}

                        getData={getDataSites}
                        isLoading={isLoadingModalUnit}
                        totalRows={totalRowsModalUnit}
                        setTotalRows={setTotalRowsModalUnit}
                        SelectedData={SelectedDataModalUnit}
                        setSelectedData={setSelectedDataModalUnit}
                        data={dataModalUnit}
                        columnData={columnDataModalUnit}
                        dataMap={dataMapModalUnit}

                        setId={props.setSiteId}
                        setName={props.setSiteName}
                    />
                )
            }
        </>
    )
}

export default CreateEditSitesWarehouses