import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const BankAdvCreateEditButton = ({
  check,
  handleSave,
  DataRegUserAccess,
  isEdit,
  handlePrint,
  handleAdd,
  handleApprovalCr,
  handleApproval,
  printing
}) => {
  const renderButton = (data) => {
    switch (data.accessCode) {
      case 'PRI':
        return isEdit ? ButtonComponent.PrintButton(handlePrint, '', true) : null;
      case 'CRE':
        return isEdit ? ButtonComponent.AddButton(handleAdd, 'New', false, false, true) : null;
      case 'APC':
        return isEdit ? ButtonComponent.ApproveButton(handleApprovalCr, 'Approval Credit', true) : null;
      case 'APP':
        return isEdit && printing === 0 ? ButtonComponent.ApproveButton(handleApproval, 'Approve', true) : null;
      default:
        return null;
    }
  };

  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid container item justifyContent="right" direction="row" style={{ maxWidth: "100vw", gap: '12px' }}>
          {check !== 0 && printing === 0 && ButtonComponent.SaveButton(handleSave, false, false, true)}

          {DataRegUserAccess.map(data => (
            <React.Fragment key={data.accessId}>
              {renderButton(data)}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default BankAdvCreateEditButton;