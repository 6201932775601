const getColorByBackground = (bgColor, darkColor = '#000', lightColor = '#FFF') => {
  if (!bgColor) return '#000'

  const colorNumber = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;

  const r = parseInt(colorNumber.substring(0, 2), 16);
  const g = parseInt(colorNumber.substring(2, 4), 16);
  const b = parseInt(colorNumber.substring(4, 6), 16);

  const totalColorNumber = ((r * 0.299) + (g * 0.587) + (b * 0.114));

  return (
    totalColorNumber > 186 ? darkColor : lightColor
  )
}

export {
  getColorByBackground,
}