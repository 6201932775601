import { Box, Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import React, { useImperativeHandle, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bar, Line, } from "react-chartjs-2";

import gbr1 from './../../../Assets/news.jpg'

ChartJS.register(ArcElement, Tooltip, Legend);

const MainMenu = forwardRef((props, ref) => {
    const history = useNavigate()
    document.title = 'Main Menu - ' + JSON.parse(localStorage.getItem('branchName'))

    useImperativeHandle(ref, () => ({
        localSave() {
        }
    }));

    const dataChart = {
        labels: ['Progress', 'Need Approval', 'Approved'],
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: [
            {
                label: 'Total',
                data: [55, 23, 96],
                // you can set indiviual colors for each bar
                backgroundColor: [
                    'rgba(255, 0, 0, 0.5)',
                    'rgba(0, 255, 0, 0.5)',
                    'rgba(0, 0, 255, 0.5)'
                ],
                borderWidth: 1,
            }
        ]
    }

    const dataChart2 = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
            {
                label: "First dataset",
                data: [33, 53, 85, 41, 44, 65],
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            },
            {
                label: "Second dataset",
                data: [33, 25, 35, 51, 54, 76],
                fill: false,
                borderColor: "#742774"
            }
        ]
    };

    const dataChart3 = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Box sx={{ backgroundColor: '#F7F7F7' }}>
            <Grid container spacing={2} padding={3}>
                <Grid item xs={12}>
                    <Grid container item direction="row">
                        <Grid item xs={12}>
                            <div className="mt-1">
                                <h5 style={{ color: 'blue' }}>Operation</h5>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{
                        maxWidth: 400
                    }}
                        onClick={() =>{ history('/Majura/bookingconfirmation');}}
                    >
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="125"
                                image={gbr1}
                                alt="Booking"
                            />
                            <div className="chart-container" hidden>
                                <Bar
                                    data={dataChart}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Booking Confirmation"
                                            },
                                            legend: {
                                                display: false
                                            },
                                        },
                                        responsive: true,
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Booking Confirmation
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/shipmentorder');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Line
                                    data={dataChart2}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Shipment Order"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Shipment Order
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/estimateprofitloss');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Bar
                                    data={dataChart2}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Estimate Profit Loss"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Estimate Profit Loss
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/invoice');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Line
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Invoice"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Invoice
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/paymentrequest');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Bar
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Payment Request"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Payment Request
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>

                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>

            <Grid container spacing={2} padding={3}>
                <Grid item xs={12}>
                    <Grid container item direction="row">
                        <Grid item xs={12}>
                            <div className="mt-1">
                                <h5 style={{ color: 'blue' }}>Finance & Accounting</h5>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{
                        maxWidth: 400
                    }}
                        onClick={() => {history('/Majura/OfficialReceipt');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Bar
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Official Receipt"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Official Receipt
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/PaymentVoucher');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Line
                                    data={dataChart}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Payment Voucher"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Payment Voucher
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/ReceiptVoucher');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Line
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Receipt Voucher"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Receipt Voucher
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>

                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/TemporaryPayment');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Line
                                    data={dataChart2}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Temporary Payment"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true
                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Temporary Payment
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/TemporaryReceipt');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Bar
                                    data={dataChart2}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Temporary Receipt"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Temporary Receipt
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/TemporaryReceipt');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Line
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Temporary Receipt"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Temporary Receipt
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/BankAdvance');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Bar
                                    data={dataChart}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Bank Advance"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Bank Advance
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/CashAdvance');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Bar
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Cash Advance"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Cash Advance
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/OfficialReceiptPph23');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Line
                                    data={dataChart}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Official Receipt Pph23"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Official Receipt Pph23
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/PaymentVoucherPph23');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Line
                                    data={dataChart2}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Payment Voucher Pph23"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Payment Voucher Pph23
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/ClosingBalance');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Bar
                                    data={dataChart2}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Closing Balance"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Closing Balance
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/GeneralLedger');}}
                    >
                        <CardActionArea>
                            {/* <CardContent> */}
                            <div className="chart-container">
                                <Line
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "General Ledger"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    General Ledger
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/GeneralInvoice');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Bar
                                    data={dataChart}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "General Invoice"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    General Invoice
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12 / 5}>
                    <Card sx={{ maxWidth: 400 }}
                        onClick={() => {history('/Majura/Memorial');}}
                    >
                        <CardActionArea>
                            <div className="chart-container">
                                <Bar
                                    data={dataChart3}
                                    options={{
                                        plugins: {
                                            title: {
                                                display: false,
                                                text: "Memorial"
                                            },
                                            legend: {
                                                display: false
                                            }
                                        },
                                        responsive: true

                                    }}
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h7" component="div">
                                    Memorial
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    On Progress 57<br></br>
                                    Need Approval 7<br></br>
                                    Already Approved 50
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
})

export default MainMenu