import React, { useState, useEffect, forwardRef } from 'react';
import "jspdf-autotable";
import CreateEditAccess from './CreateEditAccess';
import ApiService from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';

const AccessList = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Access'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const param = "access/access";
    const [id, setId] = useState(0);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    const toggleAdd = () => {
        setId(0);
        setCode('');
        setName('');
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            ApiService.IdentityPostById(param, SelectedData.id).then((res) => {
                if (res !== null) {
                    var result = res.data.data.access;
                    setCode(result.code);
                    setName(result.name);
                }
                setId(SelectedData.id);
                setIsModal(true);
                setTitleModal('Edit');
            }).catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
            })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            document.title = 'Access - ' + JSON.parse(localStorage.getItem('branchName'))
            ApiService.refreshToken();
            countRefresh++;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        ApiService.IdentityPostByPage(param, 'PAGE', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.access);

                    let temp = response.data.data.access
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                sortArr={sortArr}
                setSortArr={setSortArr}
                goTo={goTo}
                setGoTo={setGoTo}
                menuCode={'IMACC'}
            />

            {
                isModal && (
                    <CreateEditAccess
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        param={param}
                        getData={getData}
                        id={id}
                        code={code}
                        setCode={setCode}
                        name={name}
                        setName={setName}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default AccessList
