import React, { forwardRef, useImperativeHandle } from 'react';

import ButtonService from '../ButtonService';
import { Input } from '../../../components/ui';
import { userCode } from '../../../services/ApiService';
import CreateEditModalIdentity from '../CreateEditModalIdentity';

const CreateEditAccess = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    const Save = () => {
        var data;
        data =
        {
            "access": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "userCode": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                label={'Code'}
                value={props.code}
                onChange={(e) => props.setCode(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                label={'Name'}
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalIdentity
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }
        </>
    )
})

export default CreateEditAccess