import { showToast } from "../../components/ui";
import ApiService from "../../services/ApiService";

export const displayToast = (type = "success", msg) => {
    showToast({ type: type, message: msg })
};

class ButtonService {
    DeleteData = (param, id) => {
        ApiService.IdentityDeleteData(param, id).then(() => {
            displayToast('success', "Data Deleted");
            window.location.reload();
        });
    }


    Save = (titleModal, title, param, data, callback) => {
        var msg = ''
        if (titleModal === 'Add') {
            ApiService.IdentityCreateData(param, data).then(() => {
                msg = "Add Data " + title + " Success";
                displayToast('success', msg);
                if (callback) {
                    callback();
                }
            })
                .catch(function (error) {
                    alert(error)
                    console.error('error saat create', error)
                    if (callback) {
                        callback();
                    }
                })
        } else {
            ApiService.IdentityUpdateData(param, data).then(() => {
                msg = 'Update Data ' + title + " Success";
                displayToast('success', msg);
                if (callback) {
                    callback();
                }
            })
                .catch(function (error) {
                    console.error('error saat update', error)
                    if (callback) {
                        callback();
                    }
                })
        }
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ButtonService()