import React, { forwardRef } from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'

import Input from './Input'
import { InputProps } from './Input.Props'

const MaskedInputCustom = forwardRef(({ inputRef, maskOptions, ...other }, ref) => (
  <MaskedInput
    {...other}
    {...maskOptions}
    ref={inputRef}
  />
));

const InputMask = ({
  mask = false,
  guide = false,
  placeholderChar,
  keepCharPositions = false,
  pipe,
  showMask = false,
  inputProps,
}) => {
  const maskOptions = {
    mask,
    guide,
    placeholderChar,
    keepCharPositions,
    pipe,
    showMask
  }

  return (
    <Input
      {...inputProps}
      inputBaseProps={{
        inputComponent: MaskedInputCustom,
        inputProps: { maskOptions }
      }}
    />
  )
}

InputMask.propTypes = {
  inputProps: PropTypes.shape(InputProps),
  mask: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(RegExp)
    ])),
    PropTypes.func,
    PropTypes.oneOf(['taxNumber', 'containerNumber', 'dollar', 'rupiah', false]),
  ]),
  guide: PropTypes.bool,
  placeholderChar: PropTypes.string,
  keepCharPositions: PropTypes.bool,
  pipe: PropTypes.func,
  showMask: PropTypes.bool,
}

export default InputMask