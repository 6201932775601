import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { branchId, companyId, countryId, userId, userCode } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Input, PopUp, Selection } from '../../../../components/ui';
import { updateDateFormat } from '../../../../utils/date';

const ModalDeliveryInvoice = (props) => {
    const [isDisabled, setIsDisabled] = useState(true);

    const deliveryStatusData = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ]

    const emptyState = () => {
        props.setIdInvoice(0);
        props.setDeliveryStatus(false);
        props.setDeliveryRemarks('');
        props.setDeliveryDate('');
        setIsDisabled(true);
    };

    const handleSave = () => {
        let deliveryDate = updateDateFormat(props.DeliveryDate, false, true);

        let data = {
            "countryId": countryId,
            "companyId": companyId,
            "branchId": branchId,
            "userId": userId,
            "userCode": userCode,

            "id": props.idInvoice,
            "deliveredOn": deliveryDate,
            "deliveredRemarks": props.DeliveryRemarks,
        };

        props.deliveryInvoice(data);
        emptyState();
    }

    function handleDeliveryStatus(val) {
        props.setDeliveryStatus(val);
        if (val === true) {
            setIsDisabled(false);
            props.getDateNow();
        }
        else {
            setIsDisabled(true);
            props.setDeliveryDate('');
            props.setDeliveryRemarks('');
        }
    }

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'delivery'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px',
                                transform: 'rotateY(180deg)'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    <Selection
                        id='0'
                        label={'Delivered'}
                        fullWidth={true}
                        placeholder={'Select Delivered'}
                        value={props.DeliveryStatus}
                        onChange={(e) => handleDeliveryStatus(e.target.value)}
                    >
                        {
                            deliveryStatusData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>

                    <Input
                        label={'Date Delivered'}
                        type={'date'}
                        value={props.DeliveryDate}
                        onChange={(e) => props.setDeliveryDate(e.target.value)}
                        disabled={isDisabled}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />

                    <Input
                        label={'Remarks'}
                        value={props.DeliveryRemarks}
                        onChange={(e) => props.setDeliveryRemarks(e.target.value)}
                        multiline
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            rows: 4,
                            sx: {
                                padding: '8px',
                            },
                        }}
                        disabled={isDisabled}
                    />

                    <div style={{ marginTop: '32px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalDeliveryInvoice;