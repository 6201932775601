import React from 'react';
import Grid from '@mui/material/Grid';

import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonInBoundList = ({ ReloadData, DataRegUserAccess, AddData, EditData, DeleteData, PrintData, PrintExcel, HandleStatus, DropDownTitle }) => {
    return (
        <div className="row">
            <div className="col-12">
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.length > 0 && DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {data.accessCode === 'CRE' && ButtonComponent.AddButton(AddData, 'New')}
                            {data.accessCode === 'REI' && ButtonComponent.EditButton(EditData, 'Data')}
                            {data.accessCode === 'DEL' && ButtonComponent.DeleteButton(DeleteData, 'Data')}
                            {data.accessCode === 'PRI' && (
                                <>
                                    {ButtonComponent.PrintButton(PrintData, 'PDF')}
                                    {ButtonComponent.ExcelButton(PrintExcel, 'Print Excel')}
                                </>
                            )}
                        </React.Fragment>
                    ))}

                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
                </Grid>
            </div>
        </div>
    );
}

export default ButtonInBoundList;