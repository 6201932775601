import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  styled,
  useTheme
} from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import sidebarImg from '../../../Assets/Background/sidebar-open.jpg';
import MyRoute from '../../Components/MyRoute';
import Login from '../../../pages/Account/Login';
import AppHeader from '../Header/AppHeader';
import ShrinkMenu from './ShrinkMenu';
import { userCode } from '../../../services/ApiService';
import { getActiveNodes } from '../../../utils/useSidebar';

import { drawerWidth, openedMixin, closedMixin } from './config';

// import logoImg from '../../../Assets/Majura-logo.png'

import ExpandMore from '@mui/icons-material/ExpandMore';
import { Icon } from '../../ui';
const missingIcon = '';

const dataMenu = JSON.parse(localStorage.getItem("dataMenu")) || [];
const dataMenuCode = JSON.parse(localStorage.getItem("dataMenuCode")) || [];

export const LightTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: '#fffcbd',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 0px 35px 0px rgba(154, 161, 171, 0.15)',
    fontSize: 11,
  },
}));

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:not(.MuiTreeItem-child-root)': {
    margin: '0 -16px',
  },
  '.MuiTreeItem-group': {
    '.MuiTreeItem-child-root': {
      '.MuiTreeItem-content': {
        paddingLeft: '16px',
        alignItems: 'center'
      },
    },
  },
  '.MuiTreeItem-iconContainer': {
    svg: {
      transition: 'all .1s ease-in-out',
    },
  },
  '&.MuiTreeItem-parent-root': {
    '&.MuiParent-shrink-selected .MuiTreeItem-content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    }
  },
  [`& .${treeItemClasses.content}`]: {
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    transition: 'all .3s ease-out',
    borderRadius: '4px',
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:not(&.Mui-expanded)': {
      '.MuiTreeItem-iconContainer': {
        svg: {
          transform: 'rotate(-90deg)'
        }
      },
    },
    '&.Mui-collapsed': {
      marginLeft: '17px',
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

function StyledTreeItem(props) {
  const { bgColor, color, labelIcon, labelInfo, labelText, isParent, parentId, ...other } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: '8px 4px' }}>
          <Icon
            iconName={labelIcon}
            svgIconProps={{
              htmlColor: props.open ? '#0087C2' : 'rgba(0, 0, 0, 0.38)',
              sx: {
                height: isParent ? '18px' : '16px',
                width: isParent ? '18px' : '16px',
                marginTop: '-2px'
              }
            }}
          />

          <Typography
            variant="body2"
            sx={{
              color: props.open ? '#0087C2' : 'rgba(0, 0, 0, 0.38)',
              fontFamily: 'Nunito',
              fontSize: isParent ? '13px' : '12px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '20px',
              letterSpacing: '0.25px',
              flexGrow: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginInlineStart: '10px'
            }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      title={labelText}
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.string.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  isParent: PropTypes.bool,
  parentId: PropTypes.string,
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open', })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    boxShadow: 1,
    border: 'none',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SideNavbar() {
  const { pathname } = useLocation()
  const [nodeExpanded, setNodeExpanded] = useState([]);
  const [nodeSelected, setNodeSelected] = useState();
  const [parentNodeSelected, setParentNodeSelected] = useState();
  const [childNodeSelected, setChildNodeSelected] = useState();

  const theme = useTheme();

  const childRef = useRef();
  const [pin, setPin] = useState(JSON.parse(localStorage.getItem("pinStatus")) === 'true' ? true : false);
  const [open, setOpen] = React.useState(JSON.parse(localStorage.getItem("sidebarStatus")) === 'true' ? true : false);
  const history = useNavigate();

  useEffect(() => {
    checkLogin();
    const activeNodes = getActiveNodes(dataMenu, pathname)
    setNodeExpanded(activeNodes?.expandedNode)
    setNodeSelected(activeNodes?.selectedNode)
    setParentNodeSelected(activeNodes?.selectedParentNode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function checkLogin() {
    let userNames = JSON.parse(localStorage.getItem("username"));
    if (!userNames) {
      history('/', { replace: true });
      window.location.reload();
    }
  }

  const handleToggle = (e, nodeIds) => {
    setNodeExpanded(nodeIds)
  };

  const handleSelect = (e, nodeId) => {
    if (dataMenuCode.findIndex((menu) => menu.id === nodeId) !== -1) {
      setNodeSelected(nodeId)
    }
  }

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("sidebarStatus", JSON.stringify('true'));
  };

  const handleDrawerClose = () => {
    if (!pin) {
      setOpen(false);
      localStorage.setItem("sidebarStatus", JSON.stringify('false'));
    }
  };

  function nodeClick(url, parentId) {
    history(url)
    setParentNodeSelected(parentId)
    setChildNodeSelected(url)
  };

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id}
      parentId={nodes.id}
      labelText={nodes.name}
      labelIcon={nodes?.icon || missingIcon}
      isParent={true}
      open={nodes.id === parentNodeSelected ? true : false}
      hidden={nodes.hidden === 'true' ? true : false}
      color={!open && nodes.id === parentNodeSelected ? theme.palette.primary?.[500] : null}
      bgColor={!open && nodes.id === parentNodeSelected ? "#e8f0fe" : null}
      className={`MuiTreeItem-parent-root ${!open && nodes.id === parentNodeSelected ? 'MuiParent-shrink-selected' : ''}`}
    >
      {
        Array.isArray(nodes.children) ?
          nodes.children.map((node) => renderTreeChild(node, nodes.id))
          :
          null
      }
    </StyledTreeItem>
  );

  const renderTreeChild = (nodes, parentId) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id}
      parentId={parentId}
      labelText={nodes.name}
      open={childNodeSelected === nodes.url ? true : false}
      labelIcon={nodes.url !== '' ? nodes?.icon || missingIcon : ''}
      color={nodes.url !== '' ? theme.palette.primary?.[500] : null}
      bgColor={nodes.url !== '' ? "#e8f0fe" : null}
      onClick={() => (nodes.url !== '' ? nodeClick(nodes.url, parentId) : null)}
      onContextMenu={(e) => {
        if (nodes.url !== '') {
          e.preventDefault();
          handleWindowsMenuOpen(e)
          setUrl(nodes.url)
        }
      }}
      hidden={nodes.hidden === 'true' ? true : false}
      className='MuiTreeItem-child-root'
    >
      {
        Array.isArray(nodes.children) ?
          nodes.children.map((node) => {
            return (
              <div style={{ marginInlineStart: '16px' }}>
                {renderTreeChild(node, parentId)}
              </div>
            )
          })
          :
          null
      }
    </StyledTreeItem>
  );

  const handlePin = () => {
    if (pin) {
      setPin(false)
      localStorage.setItem("pinStatus", JSON.stringify('false'));
      handleDrawerClose();
    }
    else {
      setPin(true)
      localStorage.setItem("pinStatus", JSON.stringify('true'));
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [url, setUrl] = useState('');
  const menuId = 'primary-search-account-menu';

  const [shrinkNodeEl, setShrinkNodeEl] = useState(null);
  const [shrinkActiveMenu, setShrinkActiveMenu] = useState([]);
  const isShrinkMenuOpen = Boolean(shrinkNodeEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleShrinkMenu = (e, nodeIds) => {
    e?.preventDefault();
    if (!e || nodeIds === 'backdropClick') {
      setShrinkNodeEl(undefined)
      setShrinkActiveMenu([])
      return
    }
    setShrinkNodeEl(e.currentTarget)
    let indexMenu = dataMenu?.findIndex((menu) => menu.id === nodeIds?.[0])
    if (indexMenu !== -1) {
      setShrinkActiveMenu(dataMenu[indexMenu])
    }
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={newTab}>New Tab</MenuItem>
      <MenuItem onClick={newWindow}>New Window</MenuItem>
    </Menu>
  );

  function newTab() {
    window.open(url, '_blank')
    handleMenuClose()
  };

  function newWindow() {
    window.open(url, '_blank', 'location=yes,height=screen.availHeight,width=screen.availWidth,scrollbars=yes,status=yes')
    handleMenuClose()
  };

  const handleWindowsMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {
        userCode ?
          <Box container="true" sx={{ display: 'flex' }}>
            <Grid container style={{ 'width': `100%`, height: '100%' }} >
              <CssBaseline />
              <AppBar sx={{ backgroundImage: 'linear-gradient(220.13deg, #00F0FF -3.72%, #00278A 109.9%)' }}>
                <Toolbar sx={{ px: { sm: 0 } }}>
                  <Box width={'100%'}>
                    <AppHeader childRef={childRef} />
                  </Box>
                </Toolbar>
              </AppBar>

              <Drawer
                variant="permanent"
                open={open}
                onMouseEnter={handleDrawerOpen}
                onMouseLeave={handleDrawerClose}
                PaperProps={{
                  sx: {
                    border: 0,
                    boxShadow: theme.shadows[8],
                    backgroundImage: `url(${sidebarImg})`,
                    backgroundSize: 'cover',
                  },
                }}
              >
                <DrawerHeader sx={{ p: '0 24px', }} />

                <TreeView
                  aria-label="gmail"
                  expanded={open ? nodeExpanded || [] : []}
                  selected={nodeSelected || ''}
                  onNodeToggle={open ? handleToggle : toggleShrinkMenu}
                  onNodeSelect={open ? handleSelect : undefined}
                  defaultCollapseIcon={open && <ExpandMore />}
                  defaultExpandIcon={open && <ExpandMore />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                  sx={{
                    p: 3,
                    // height: 264,
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    animation: 'all .15s ease-in-out',
                  }}
                >
                  <IconButton size='small' sx={{ padding: '0px', marginTop: '8px', marginBottom: open ? '20px' : '44px' }} hidden={open} onClick={handlePin}>
                    {/* <Icon 
                      iconName={pin? 'lock' : 'unlock'} 
                      svgIconProps={{ 
                        sx: { 
                          height: '24px', 
                          width: '24px', 
                        } 
                      }}
                    /> */}
                  </IconButton>

                  <Button
                    sx={{
                      display: 'flex',
                      height: '32px',
                      width: '192px',
                      padding: '8px 16px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexShrink: '0',
                      borderRadius: '32px',
                      border: '1px solid var(--Blue-Primary-1, #083A50)',
                      background: pin ? '#F2FAFF' : '#FFFFFF',
                      marginBottom: '12px !important'
                    }}
                    hidden={!open}
                    onClick={handlePin}
                  >
                    <Grid container alignItems={'center'}>
                      <Grid
                        item
                        xl={8}
                        lg={8}
                        sm={8}
                        xs={12}
                      >
                        <Typography
                          sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            textAlign: 'center',
                            fontFamily: 'Nunito',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0.25px',
                            marginTop: '2px'
                          }}
                          hidden={pin}
                        >
                          Lock Menu Bar
                        </Typography>

                        <Typography
                          sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            textAlign: 'center',
                            fontFamily: 'Nunito',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0.25px',
                            marginTop: '2px'
                          }}
                          hidden={!pin}
                        >
                          Unlock Menu Bar
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                        className='text-end'
                      >
                        <Icon
                          iconName={pin ? 'lock' : 'unlock'}
                          svgIconProps={{
                            sx: {
                              height: '18px',
                              width: '18px',
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Button>

                  <StyledTreeItem
                    key={'0'}
                    nodeId={'0'}
                    labelText={'DASHBOARD'}
                    labelIcon={'dashboard'}
                    isParent={true}
                    open={parentNodeSelected === '0' ? true : false}
                    parentId={parentNodeSelected}
                    color={theme.palette.primary?.[300]}
                    bgColor="#e8f0fe"
                    onClick={() => { setParentNodeSelected('0'); nodeClick('/Majura/Dashboard', '0'); }}
                  />

                  {
                    dataMenu?.map((el, index) => {
                      return (
                        <Box key={index}>
                          {renderTree(el)}
                        </Box>
                      )
                    })
                  }
                </TreeView>

                <ShrinkMenu
                  shrinkNodeEl={shrinkNodeEl}
                  isShrinkMenuOpen={isShrinkMenuOpen}
                  toggleShrinkMenu={toggleShrinkMenu}
                  shrinkActiveMenu={shrinkActiveMenu}
                  handleClick={nodeClick}
                  setNodeSelected={handleSelect}
                />
                {renderMenu}
              </Drawer>

              <Box
                component="main"
                width={`calc(100% - ${drawerWidth})`}
                height={'100vh'}
                sx={{ flexGrow: 1, background: '#E4E8EC' }}
              >
                <Grid component='div'>
                  <DrawerHeader />
                  <MyRoute childRef={childRef} />
                </Grid>
              </Box>
            </Grid>
          </Box>
          :
          <Login />
      }
    </>
  );
}