import { MenuItem, FormHelperText } from '@mui/material';
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import ApiService, { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditRegJobType = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props
    const [jobTypeList, setJobTypeList] = useState([])
    const [contactTypeList, setContactTypeList] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (isModal) {
            getAdditionalData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    const errorToast = (location, closeModal = false) => {
        showToast('error', `Can't get ${location} data! please contact Administrator`)
        if (closeModal) handleClick()
    }

    const getAdditionalData = async () => {
        try {
            const [resJT, resCT] = await Promise.all([
                ApiService.DynamicPostByPage('jobType/jobType', 'PAGE', 1, 9999, []),
                ApiService.DynamicPostByPage('contactType/contactType', 'PAGE', 1, 9999, [])
            ])

            if (resJT.data.code === 200) {
                setJobTypeList(resJT?.data?.data?.jobType || [])
            } else {
                errorToast('job type', true)
            }

            if (resCT.data.code === 200) {
                setContactTypeList(resCT?.data?.data?.contactType || [])
            } else {
                errorToast('contact type', true)
            }
        } catch (error) {
            console.log(error)
            errorToast('additional', true)
        }

        setIsLoading(false)
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "regJobType": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DatasetRegJobType", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        jobTypeId: Yup.string().required('This field is required.')
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            jobTypeId: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const handleJobTypeChange = (jobTypeId) => {
        const jobType = jobTypeList.find((job) => job.id === jobTypeId)
        if (jobType) {
            formik.setFieldValue('code', jobType?.code)
            formik.setFieldValue('name', jobType?.name)
            formik.setFieldValue('contactTypeId', jobType?.contactTypeId)
            formik.setFieldValue('portType', jobType?.portType)
            formik.setFieldValue('serviceType', jobType?.serviceType)
            formik.setFieldValue('group', jobType?.group)
        }
    }

    const Save = (payload) => {
        if (payload?.jobTypeId === '') {
            return showToast({ type: 'error', message: 'Job Type is Required' });
        }

        const data = {
            "regJobType": {
                id: payload?.id,
                jobTypeId: payload?.jobTypeId,
                rowStatus: payload?.rowStatus || 'ACT',
                user: userCode,
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Selection
                placeholder={'Select Job Type'}
                labelId="label-jobTypeId"
                name='jobTypeId'
                id="jobTypeId"
                value={formik.values.jobTypeId || ''}
                label="Job Type"
                onChange={(e) => {
                    formik.setFieldValue('jobTypeId', e.target.value)
                    handleJobTypeChange(e.target.value)
                }}
                isLoading
                error={formik.touched.jobTypeId && Boolean(formik.errors.jobTypeId)}
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
            >
                {
                    jobTypeList.map((jt) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={jt.id} value={jt.id}>{jt.name}</MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.jobTypeId && formik.errors.jobTypeId}</FormHelperText>
            </Selection>

            <Input
                name="code"
                label="Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                value={formik.values.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
            />

            <Input
                name="name"
                label="Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                value={formik.values.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
            />

            <Selection
                placeholder={'Select Contact Type'}
                labelId="label-contactTypeId"
                name='contactTypeId'
                id="contactTypeId"
                value={formik.values.contactTypeId || ''}
                label="Contact Type"
                onChange={(e) => formik.setFieldValue('contactTypeId', e.target.value)}
                isLoading
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    contactTypeList.map((contact) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={contact.id} value={contact.id}>{contact.name}</MenuItem>
                    ))
                }
            </Selection>

            <Selection
                placeholder={'Select Port Type'}
                labelId="label-portType"
                name='portType'
                id="portType"
                value={formik.values.portType || ''}
                label="Port Type"
                onChange={(e) => formik.setFieldValue('portType', e.target.value)}
                isLoading
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                <MenuItem sx={{ fontSize: '12px' }} value={'SEA'}>SEA</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} value={'AIR'}>AIR</MenuItem>
            </Selection>

            <Selection
                placeholder={'Select Service Type'}
                labelId="label-serviceType"
                name='serviceType'
                id="serviceType"
                value={formik.values.serviceType || ''}
                label="Service Type"
                onChange={(e) => formik.setFieldValue('serviceType', e.target.value)}
                isLoading
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                <MenuItem sx={{ fontSize: '12px' }} value={'Export'}>Export</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} value={'Import'}>Import</MenuItem>
            </Selection>

            <Input
                name="group"
                label="Group"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                value={formik.values.group}
                onChange={(e) => formik.setFieldValue('group', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        isLoading={isLoading}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditRegJobType