import { Box, Divider, InputAdornment, Stack, Typography } from '@mui/material'
import React from 'react'

import { Input } from '../../../components/ui'

const MasterInput = () => {
  return (
    <Stack direction={'column'} gap={2}>
      <Box>
        <Typography fontWeight={700}>VARIANT</Typography>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2} mt={2}>
          {/* *** */}
          <Input
            label={'Input Text'}
            value={'Lorem ipsum'}
          />
          {/* *** */}
          <Input
            label={'Input Number'}
            value={1234567890}
            type='number'
          />
          {/* *** */}
          <Input
            label={'Left Align Input'}
            value={'Lorem ipsum'}
            textAlign='right'
          />
          {/* *** */}
          <Input
            label={'Input With Start Text'}
            value={'1000000'}
            inputBaseProps={{
              startAdornment: <InputAdornment position="start">Rp</InputAdornment>
            }}
          />
          {/* *** */}
          <Input
            label={'Input With End Text'}
            value={'99'}
            inputBaseProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
          />
          {/* *** */}
          <Input
            label={'Input With Message'}
            value={'Lorem ipsum'}
            message={'Message text'}
          />
        </Stack>
      </Box>
      <Divider />
      <Box>
        <Typography fontWeight={700}>STATE</Typography>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2} mt={2}>
          {/* *** */}
          <Input
            label={'Default'}
            value={'default value'}
          />
          {/* *** */}
          <Input
            label={'Disabled'}
            value={'disabled value'}
            disabled
          />
          {/* *** */}
          <Input
            label={'Read Only'}
            value={'readonly value'}
            readOnly
          />
          {/* *** */}
          <Input
            label={'Error'}
            value={'error value'}
            error
          />
          {/* *** */}
          <Input
            label={'Error with message'}
            value={'error value'}
            message={'This field is required.'}
            error
          />
        </Stack>
      </Box>
      <Divider />
      <Box>
        <Typography fontWeight={700}>MULTILINE / TEXTAREA</Typography>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2} mt={2}>
          {/* *** */}
          <Input
            label={'Input Multiline'}
            multiline
          />
          {/* *** */}
          <Input
            label={'Input Multiline with defined rows'}
            multiline
            inputBaseProps={{
              rows: 2,
            }}
          />
        </Stack>
      </Box>
    </Stack>
  )
}

export default MasterInput