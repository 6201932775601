import { Box, Typography, Grid, Button } from '@mui/material';
import React from 'react';
import { Icon, PopUp } from '../../../../components/ui';

const ModalReOpenAccess = React.memo(({ titleModal, isModal, handleClick, handleOpenAccess }) => {
    const mainContainer = (
        <>
            <Typography
                sx={{
                    fontFamily: 'Nunito',
                    marginTop: '3px !important',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '20px',
                    letterSpacing: '0em',
                    textAlign: 'left',
                }}
            >
                You are about to re-enable login access for all users. Are you sure you want to proceed?
            </Typography>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#083A50',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={titleModal}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '24px 6px 16px' }}>
                    {mainContainer}
                </div>

                <div style={{ padding: '24px 6px 0px' }}>
                    <Box display="flex" alignItems="center">
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #0087C2)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #0087C2)',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={handleClick}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'close'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '1px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#0087C2',
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                </Button>

                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #168444)',
                                        background: 'var(--Blue-Button-Primary-1, #168444)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #168444)',
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #168444) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={handleOpenAccess}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#E4E8EC',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'unlock'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '1px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#E4E8EC',
                                        }}
                                    >
                                        Open Access
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default React.memo(ModalReOpenAccess);