import { Grid, MenuItem, FormHelperText, Backdrop } from '@mui/material';
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import ApiService from '../../../services/ApiService';
import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Icon, Input, PopUp, Selection, showToast } from '../../../components/ui';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';

const CreateEditRegUserRole = forwardRef(({ isModal, handleClick, selectedData, fetchData, numPage, rowsCount }, ref) => {
  const userId = JSON.parse(localStorage.getItem('userId'))
  const userLevel = JSON.parse(localStorage.getItem('roleLevel'))

  const date = new Date()
  const tenYearsLater = new Date(new Date().setFullYear(date.getFullYear() + 10, date.getMonth(), date.getDate()))
  const defaultForm = {
    roleId: 0,
    validOn: date.toLocaleDateString("sv-se").split('T')[0],
    validTo: tenYearsLater.toLocaleDateString("sv-se").split('T')[0],
    rowStatus: 'ACT',
  }

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [listRole, setListRole] = useState([])
  const [listUserRole, setListUserRole] = useState([])

  useEffect(() => {
    if (isModal) {
      if (selectedData?.userId === userId) {
        showToast({ type: 'error', message: "Can't update yourself!" })
        handleClick()
      } else {
        getAdditionalData()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModal])

  useImperativeHandle(ref, () => ({
    localSave() { }
  }));

  const errorToast = (location, closeModal = false) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
    if (closeModal) handleClick()
  }

  const getAdditionalData = async () => {
    setIsLoading(true)

    try {
      const paramUR = [
        { attr: 'UserEntityId', value: String(selectedData?.id || 0) },
        // { attr: 'IsUserRolePage', value: "1" },
      ]
      const [resUR, resRole] = await Promise.all([
        ApiService.IdentityPostByPage('regUserRole/regUserRole', 'PAGE', 1, 9999, paramUR),
        ApiService.IdentityPostByPage('role/role', 'PAGE', 1, 9999, [])
      ])

      if (resUR.data.code === 200) {
        const urData = resUR.data.data.userRole
        const mapData = urData.map((dt) => {
          return {
            disabledRole: true,
            roleId: dt.roleId,
            validOn: dt.validOn.split('T')[0],
            validTo: dt.validTo.split('T')[0],
            rowStatus: dt.rowStatus,
          }
        })

        if (!mapData.length) {
          mapData.push(defaultForm)
        }

        formik.setFieldValue('role', mapData)
        setListUserRole(mapData)
      } else {
        errorToast('user role')
      }

      if (resRole.data.code === 200) {
        setListRole(resRole.data.data.role)
      } else {
        errorToast('role')
      }
    } catch (error) {
      errorToast('additional')
    }

    setIsLoading(false)
  }

  const handleAddRoleForm = () => {
    const updatedForm = [...formik.values.role, defaultForm]

    formik.setFieldValue('role', updatedForm)
    setListUserRole(updatedForm)
  }

  const isHiddenRole = (id) => {
    let isHidden = true
    const listUR = [...formik.values.role]
    if (listUR.findIndex((x) => x.roleId === id) === -1) {
      isHidden = false
    }

    return isHidden
  }

  const validationSchema = Yup.object().shape({
    role: Yup.array().of(
      Yup.object().shape({
        roleId: Yup.number().min(1, 'This field is required.'),
        validOn: Yup.date().required('This field is required.'),
        validTo: Yup.date().required('This field is required.'),
        rowStatus: Yup.string(),
      })
    ).min(1, 'This field is required.'),
  })

  const formik = useFormik({
    initialValues: {
      role: '',
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values)
  })

  const handleSubmit = async (payload) => {
    setIsSubmitting(true)

    try {
      const data = []
      payload.role.forEach((elm) => {
        data.push({
          rowStatus: elm?.rowStatus || 'ACT',
          userEntityId: selectedData?.id || 0,
          roleId: elm?.roleId,
          validOn: elm?.validOn,
          validTo: elm?.validTo,
        })
      })

      const res = await ApiService.IdentityCreateData('regUserRole/regUserRole', { userRoles: data })
      if (res?.data?.code === 200) {
        showToast({ type: 'success', message: 'All employee have been successfully saved.' })
        fetchData(numPage, rowsCount)
      } else {
        showToast({ type: 'error', message: 'Can`t save employee, please try again.' })
      }
    } catch (error) {
      console.log(error)
    }

    setIsSubmitting(false)
  }

  const mainContainer = (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {listUserRole.map((ur, index) => (
          <React.Fragment key={index}>
            <Grid item xs={4}>
              <Selection
                fullWidth={true}
                id={`role.${index}.roleId`}
                label={`Role-${index + 1}`}
                labelId={`lblRole-${index}`}
                name={`role.${index}.roleId`}
                placeholder={`Select Role-${index + 1}`}
                disabled={isSubmitting || ur.disabledRole}
                value={formik.values.role?.[index]?.roleId}
                error={formik.touched.role && Boolean(formik.errors.role?.[index]?.roleId)}
                onChange={(e) => formik.setFieldValue(`role.${index}.roleId`, e.target.value)}
              >
                {
                  listRole.map((lr) => (
                    <MenuItem sx={{ fontSize: '12px' }}
                      key={lr.id}
                      value={lr.id}
                      hidden={
                        isHiddenRole(lr.id) ||
                        (lr.level !== "" && (Number(userLevel >= Number(lr.level))))
                      }
                    >
                      {lr.name}
                    </MenuItem>
                  ))
                }
                <FormHelperText>{formik.touched.role && formik.errors.role?.[index]?.roleId}</FormHelperText>
              </Selection>
            </Grid>

            <Grid item xs={3}>
              <Input
                type='date'
                label="Valid On"
                name={`role.${index}.validOn`}
                value={formik.values.role?.[index]?.validOn}
                formControlProps={{
                  sx: {
                    width: '100%',
                    // marginTop: '16px'
                  }
                }}
                onBlur={formik.handleBlur}
                onChange={(e) => { formik.setFieldValue(`role.${index}.validOn`, e.target.value) }}
                error={formik.touched.role && Boolean(formik.errors.role?.[index]?.validOn)}
                helperText={formik.touched.role && formik.errors.role?.[index]?.validOn}
                disabled={isSubmitting || (Number(userLevel) >= Number(ur.roleLevel))}
              />
            </Grid>

            <Grid item xs={3}>
              <Input
                type='date'
                label="Valid To"
                name={`role.${index}.validTo`}
                value={formik.values.role?.[index]?.validTo}
                formControlProps={{
                  sx: {
                    width: '100%',
                    // marginTop: '16px'
                  }
                }}
                onBlur={formik.handleBlur}
                onChange={(e) => { formik.setFieldValue(`role.${index}.validTo`, e.target.value) }}
                error={formik.touched.role && Boolean(formik.errors.role?.[index]?.validTo)}
                helperText={formik.touched.role && formik.errors.role?.[index]?.validTo}
                disabled={isSubmitting || (Number(userLevel) >= Number(ur.roleLevel))}
              />
            </Grid>

            <Grid item xs={2}>
              <Selection
                fullWidth={true}
                id={`role.${index}.rowStatus`}
                label={'Status'}
                labelId={`lblStatus-${index}`}
                placeholder={'Select Status'}
                name={`role.${index}.rowStatus`}
                value={formik.values.role?.[index]?.rowStatus}
                disabled={isSubmitting || (Number(userLevel) >= Number(ur.roleLevel))}
                error={formik.touched.role && Boolean(formik.errors.role?.[index]?.rowStatus)}
                onChange={(e) => formik.setFieldValue(`role.${index}.rowStatus`, e.target.value)}
              >
                <MenuItem sx={{ fontSize: '12px' }} value={'ACT'}>ACT</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} value={'DEL'}>DEL</MenuItem>
                <FormHelperText>{formik.touched.role && formik.errors.role?.[index]?.rowStatus}</FormHelperText>
              </Selection>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={12} sx={{ marginLeft: "-12px" }}>
          {ButtonComponent.AddButton(handleAddRoleForm, 'Role', isSubmitting, false, true)}
        </Grid>
      </Grid>
    </form>
  )

  return (
    <PopUp
      id='modal-id'
      icon={
        <Icon
          iconName={'account'}
          svgIconProps={{
            htmlColor: '#0087C2',
            sx: {
              height: '24px',
              width: '24px'
            }
          }}
        />
      }
      title={'Edit User Role - ' + selectedData?.userName}
      isOpen={isModal}
      onClose={handleClick}
      draggable={false}
      shrinkable={false}
      disableScrollLock={false}
      maxWidth={'md'}
      fullWidth={true}
    >
      <div style={{ padding: '24px 6px 16px' }}>
        {mainContainer}

        <div style={{ marginTop: '32px' }}>
          {CustomizeButtonComponent.SaveCancelMasterModal(handleClick, isSubmitting)}
        </div>
      </div>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner color="inherit" />
          </Backdrop>
        )
      }
    </PopUp>
  )
})

export default CreateEditRegUserRole