import React, { useImperativeHandle, forwardRef } from 'react';
import { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, showToast } from '../../../components/ui';

const CreateEditVendor = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "vendor": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-DataVendor", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        code: Yup.string().trim().required('This field is required.'),
        firstName: Yup.string().trim().required('This field is required.'),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().nullable(),
        phone: Yup.number().min(1, 'Invalid phone number.').required('This field is required.'),
        email: Yup.string().email('Invalid email address.').required('This field is required.'),
        address: Yup.string().trim().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            code: '',
            name: '',
            firstName: '',
            middleName: '',
            lastName: '',
            phone: '',
            email: '',
            address: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.code === '') {
            return showToast({ type: 'error', message: 'Code is Required!' });
        }

        if (payload?.firstName === '') {
            return showToast({ type: 'error', message: 'First Name is Required!' });
        }

        if (payload?.phone === '') {
            return showToast({ type: 'error', message: 'Phone is Required!' });
        }

        if (payload?.email === '') {
            return showToast({ type: 'error', message: 'Email is Required!' });
        }

        if (payload?.address === '') {
            return showToast({ type: 'error', message: 'Address is Required!' });
        }

        const data =
        {
            "vendor": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload?.code,
                "firstName": payload?.firstName,
                "middleName": payload?.middleName,
                "lastName": payload?.lastName,
                "phone": String(payload?.phone),
                "email": payload?.email,
                "address": payload?.address,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="code"
                label="Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
            />

            <Input
                name="firstName"
                label="First Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.firstName}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
            />

            <Input
                name="middleName"
                label="Middle Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.middleName}
                onBlur={formik.handleBlur}
                error={formik.touched.middleName && Boolean(formik.errors.middleName)}
                helperText={formik.touched.middleName && formik.errors.middleName}
                onChange={(e) => formik.setFieldValue('middleName', e.target.value)}
            />

            <Input
                name="lastName"
                label="Last Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.lastName}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
                onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
            />

            <Input
                name="phone"
                label="Phone"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    inputProps: {
                        inputMode: 'tel',
                        pattern: '[0-9]*'
                    }
                }}
                type='number'
                value={formik.values.phone}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                onChange={(e) => formik.setFieldValue('phone', e.target.value)}
            />

            <Input
                name="email"
                label="Email"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
            />

            <Input
                id='address'
                name="address"
                label="Address"
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                value={formik.values.address}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                onChange={(e) => formik.setFieldValue('address', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditVendor