import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService, { branchId, companyId, countryId, userCode, username } from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditJobOwner from './CreateEditJobOwner';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { defaultDate } from '../../../utils/date';
import CustomToastContainer, { showToast } from '../../../components/ui';

const ListJobOwner = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Job Owner'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "jobOwner/jobOwner";
    const [id, setId] = useState(0);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [masterCode, setMasterCode] = useState('');
    const [fullName, setFullName] = useState('');
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DatajobOwner"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "jobOwner": {
                    "rowStatus": "ACT",
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "id": id,
                    "code": code,
                    "name": name,
                    "masterCode": masterCode,
                    "fullName": fullName,
                    "idLama": 0,
                    "deleted": false,
                    "deletedBy": "string",
                    "deletedOn": defaultDate,
                    "userCode": userCode,
                    "titleModal": titleModal
                }
            }
            localStorage.setItem(username + "-DatajobOwner", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.jobOwner;
        setIsModal(true);
        setMasterCode(result.masterCode);
        setName(result.name);
        setCode(result.code);
        setFullName(result.fullName);
        setId(result.id);
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-DatajobOwner')
    }


    const toggleAdd = () => {
        setId(0);
        setMasterCode('');
        setName('');
        setCode('');
        setFullName('')
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            ApiService.PostById(param, SelectedData.id).then((res) => {
                if (res !== null) {
                    var result = res.data.data.jobOwner;
                    setMasterCode(result.masterCode);
                    setName(result.name);
                    setCode(result.code);
                    setFullName(result.fullName);
                }
                setId(SelectedData.id);
                setIsModal(true);
                setTitleModal('Edit');
            }).catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
            })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.jobOwner);

                    let temp = response.data.data.jobOwner
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MJOW'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditJobOwner
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        param={param}
                        getData={getData}

                        id={id}
                        masterCode={masterCode}
                        setMasterCode={setMasterCode}
                        name={name}
                        setName={setName}
                        code={code}
                        setCode={setCode}
                        fullName={fullName}
                        setFullName={setFullName}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListJobOwner