import { Typography, Grid, Card, CardContent } from '@mui/material';
import React from 'react';

const PercentComponent = (props) => {
    const PercentCardView = (props) => {
        return (
            <Grid
                item
                xl={props.size}
                lg={props.size}
                sm={props.size}
                xs={12}
            >
                <Card variant="outlined" style = {{ borderRadius: '12px', backgroundColor: props.color, borderColor: props.color }}>
                    <CardContent sx={{ padding: "16px !important" }}>
                        <Typography 
                            sx={{ 
                                color: 'var(--Primary-One, #F2F2F2)',
                                fontFamily: 'Nunito',
                                fontSize: '20px',
                                fontstyle: 'normal',
                                fontWeight: '800',
                                lineHeight: 'normal',
                                textAlign: 'center'
                            }}
                        >
                            {props.name}
                        </Typography>
                        <Typography 
                            sx={{ 
                                color: 'var(--Primary-One, #F2F2F2)',
                                fontFamily: 'Nunito',
                                fontSize: '18px',
                                fontstyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                textAlign: 'center',
                                marginTop: '8px'
                            }}
                        >
                            {Number(props.value).toFixed(2)}%
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    return (
        <Grid
            container
            spacing={'8px'}
        >
            {
                props.data.length > 0?
                    props.data.map((row, index) => (
                        <PercentCardView key = {index} name = {row.data.title.toUpperCase()} value = {row.data.value} color = {row.options[0].value} size = {props.size} />
                    ))
                :
                    <></>
            }
        </Grid>
    )
}

export default PercentComponent;