import { IconButton, MenuItem, InputAdornment } from '@mui/material';
import React from 'react';
import ApiService, { branchId, companyId, countryId, userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import ModalContact from './ModalContact';
import { useState } from 'react';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, Selection } from '../../../components/ui';

const CreateEditJobOwnerContact = (props) => {
    const [isModalContact, setIsModalContact] = useState(false);
    const [titleModalContact, setTitleModalContact] = useState('');

    const [isLoadingContact, setIsLoadingContact] = useState(false);
    const [dataContact, setDataContact] = useState([]);
    const [dataMapContact, setDataMapContact] = useState([])
    const [columnDataContact, setColumnDataContact] = useState([])
    const [SelectedDataContact, setSelectedDataContact] = useState({});
    const [totalRowsContact, setTotalRowsContact] = useState(50)

    const paramContact = "contact/contact";

    const Save = () => {
        var data =
        {
            "jobOwnerContact": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": props.id,
                "contactId": props.contactId,
                "jobOwnerId": props.jobOwnerId,
                "idLama": 0,
                "user": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const SearchContact = () => {
        if (isModalContact === false) {
            setIsModalContact(true);
            setTitleModalContact('Contact')
            getDataContact(1, 50)
        } else {
            setIsModalContact(false);
        }
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingContact(true);
        ApiService.PostByPage(paramContact, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataContact({})
                    setDataContact(response.data.data.contact);

                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapContact(indexed)
                    setColumnDataContact(response.data.data.columns)
                    setTotalRowsContact(response.data.totalRowCount)

                    setIsLoadingContact(false)
                }
                setIsLoadingContact(false)
            })
            .catch(function (error) {
                setIsLoadingContact(false)
                console.error('error saat fetch', error)
            })
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                name="contact"
                label="Contact"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                disabled
                value={props.contactName}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { SearchContact() }}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Selection
                label="Job Owner"
                placeholder={'Select Job Owner'}
                value={props.jobOwnerId}
                onChange={(e) => props.setJobOwnerId(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    props.dataJobOwner.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.id}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModalContact && (
                    <ModalContact
                        isModal={isModalContact}
                        handleClick={SearchContact}
                        titleModal={titleModalContact}
                        setIsModal={setIsModalContact}

                        isLoading={isLoadingContact}
                        data={dataContact}
                        dataMap={dataMapContact}
                        columnData={columnDataContact}
                        SelectedData={SelectedDataContact}
                        totalRows={totalRowsContact}
                        setSelectedData={setSelectedDataContact}
                        getData={getDataContact}

                        setContactId={props.setContactId}
                        setContactName={props.setContactName}
                    />
                )
            }
        </>

    )
}

export default CreateEditJobOwnerContact