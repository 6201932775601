import React, { useState, useEffect, forwardRef } from 'react';
import "jspdf-autotable";
import ApiService, { username } from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditJobStatus from './CreateEditJobStatus';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';

const ListJobStatus = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const title = 'Job Status'
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [selectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    const param = "jobStatus/jobStatus";

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DatajobStatus"));

    const fetchDataLocal = () => {
        const result = dataLocal.jobStatus;
        setTitleModal(result.titleModal);
        setSelectedData(result)

        setIsModal(true);
        localStorage.removeItem(username + '-DatajobStatus')
    }

    const toggleAdd = () => {
        if (selectedData?.id) {
            setSelectedData({})
        }
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (!selectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            setIsModal(true);
            setTitleModal('Edit');
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.jobStatus);

                    let temp = response.data.data.jobStatus
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
            setSelectedData({})
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                SelectedData={selectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MJOS'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {isModal && (
                <CreateEditJobStatus
                    ref={ref}
                    isModal={isModal}
                    handleClick={handleClick}
                    titleModal={titleModal}
                    title={title}
                    param={param}

                    getData={getData}

                    selectedData={selectedData}
                />
            )}

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListJobStatus