import React, { forwardRef, useEffect, useState } from 'react'
import { Backdrop, Box, Card, CardContent, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';

import ModalSearchCustomer from './components/ModalSearchCustomer';
import ModalSearchMarketing from './components/ModalSearchMarketing';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';

const OutstandingInvoiceReport = forwardRef(() => {
  const date = new Date()

  const [isLoading, setIsLoading] = useState(true)

  const [principleList, setPrincipleList] = useState([])
  const [contactTypeList, setContactTypeList] = useState([])

  const [activeModal, setActiveModal] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedMarketing, setSelectedMarketing] = useState({})

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Outstanding Invoice Report', link: '/Majura/OutstandingInvoiceReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Outstanding Invoice Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getAdditionalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getAdditionalData = async () => {
    setIsLoading(true)
    try {
      const [resJO, resCT] = await Promise.all([
        ApiService.PostByPage('jobOwner/jobOwner', 1, 999, []),
        ApiService.PostByPage('contactType/contactType', 1, 999, []),
      ])

      if (resJO?.data?.code === 200) {
        const tempData = resJO.data.data.jobOwner
        const mapData = tempData.map((jo) => {
          return { value: jo.id, label: jo.name }
        })

        if (mapData.length === 1) {
          formik.setFieldValue('principle', mapData[0].value)
        }
        setPrincipleList(mapData)
      } else {
        errorToast('principal')
      }

      if (resCT?.data?.code === 200) {
        const tempData = resCT.data.data.contactType
        const filterData = tempData.filter((data) => ['Agent', 'Shipper', 'Consignee'].includes(data.name))
        const mapData = filterData.map((ct) => {
          return { value: ct.id, label: ct.name }
        })

        formik.setFieldValue('customerType', mapData[0].value)

        setContactTypeList(mapData)
      } else {
        errorToast('contact type')
      }

    } catch (error) {
      errorToast('additional')
    }

    setIsLoading(false)
  }

  const checkInput = () => {
    let check = 0;

    if (formik.values.customerChange === 'custom' && selectedCustomer.code === undefined) {
      check = 1;
    }
    else if (formik.values.marketingChange === 'custom' && selectedMarketing.name === undefined) {
      check = 2;
    }

    if (check === 1) {
      showToast({ type: 'error', message: 'Please Select Data Customer!' })
    }
    else if (check === 2) {
      showToast({ type: 'error', message: 'Please Select Data Marketing!' })
    }

    return check;
  }

  const handlePrint = (fileType) => {
    if (checkInput() === 0) {
      setIsLoading(true);

      let params = [];
      let customerId = 0;
      let marketingId = 0;
      let payload = formik.values;
      let variable = 'PrintOINV?typeReport=' + fileType;

      if (payload.customerChange !== 'all') {
        customerId = selectedCustomer.contactId;
      }

      if (payload.marketingChange !== 'all') {
        marketingId = selectedMarketing.id;
      }

      params = [
        ...params,
        {
          "attr": "ContactTypeId",
          "value": '' + payload.customerType
        },
        {
          "attr": "CustomerId",
          "value": '' + customerId
        },
        {
          "attr": "MarketingId",
          "value": '' + marketingId
        },
        {
          "attr": "JobOwnerId",
          "value": '' + payload.principle
        },
        {
          "attr": "Date",
          "value": '' + payload.asPerDate
        }
      ]
      ApiService.ReportPrint("Reports/Finance/Print/ControlReport/OutstandingInvoiceReport/", variable, params)
        .then((response) => {
          if (response.status === 200) {
            let rptFormat = "application/pdf";
            if (fileType === 'xls') {
              rptFormat = "application/vnd.ms-excel";
            }
            const file = new Blob([response.data], { type: rptFormat });
            const fileURL = URL.createObjectURL(file);

            setIsLoading(false);
            showToast({ type: 'success', message: 'Print Outstanding Invoice Report Success' });
            window.open(fileURL);
          }
          else {
            setIsLoading(false);
            showToast({ type: 'error', message: 'Can\'t Print Outstanding Invoice Report, ' + response.data.message });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          showToast({ type: 'error', message: 'Can\'t Print Outstanding Invoice Report, ' + error });
        });
    }
  }

  const searchModal = (target) => {
    setActiveModal(target)
  }

  const formik = useFormik({
    initialValues: {
      principle: '0',
      customerType: '',
      customerChange: 'all',
      marketingChange: 'all',
      asPerDate: date.toLocaleDateString("sv-se").split('T')[0]
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {ButtonComponent.PrintButton(() => handlePrint('pdf'), 'PDF')}
            {ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Print Excel')}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: 3 }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          title={'Outstanding Invoice Report'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div style={{ marginTop: '24px' }}>
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                  <CardContent>
                    <Grid container spacing={'16px'}>
                      <Grid item xs={12}>
                        <Selection
                          id='0'
                          fullWidth={true}
                          label={'Principle By'}
                          value={formik.values.principle}
                          placeholder={'Select Principle By'}
                          onChange={(e) => {
                            formik.setFieldValue('principle', e.target.value)
                          }}
                        >
                          {
                            principleList.length !== 1 &&
                            <MenuItem sx={{ fontSize: '12px' }} value={'0'}>All</MenuItem>
                          }
                          {
                            principleList.map((principle) => (
                              <MenuItem sx={{ fontSize: '12px' }} key={principle.value} value={principle.value}>{principle.label}</MenuItem>
                            ))
                          }
                        </Selection>
                      </Grid>

                      <Grid item xs={12}>
                        <div>
                          <Typography
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: '20px',
                            }}
                          >
                            Customer
                          </Typography>

                          <RadioGroup
                            row
                            value={formik.values.customerType}
                            onChange={(e) => {
                              formik.setFieldValue('customerType', e.target.value)
                              setSelectedCustomer({})
                            }}
                          >
                            {
                              contactTypeList.map((ct) => (
                                <FormControlLabel
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                  }}
                                  control={<Radio size='small' />}
                                  key={ct.value}
                                  value={ct.value}
                                  label={
                                    <Typography
                                      sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                      }}
                                    >
                                      {ct.label}
                                    </Typography>
                                  }
                                />
                              ))
                            }
                          </RadioGroup>
                        </div>

                        <Box mt={'16px'} p={'12px'} borderRadius={'5px'} border={'1px solid #C4C4C4'}>
                          <RadioGroup
                            row
                            value={formik.values.customerChange}
                            onChange={(e) => {
                              formik.setFieldValue('customerChange', e.target.value)
                              setSelectedCustomer({})
                            }}
                          >
                            <FormControlLabel
                              sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '12px !important',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                              }}
                              control={<Radio size='small' />}
                              key={0}
                              value={'all'}
                              label={
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  All Customer
                                </Typography>
                              }
                            />

                            <FormControlLabel
                              sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '12px !important',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                              }}
                              control={<Radio size='small' />}
                              key={1}
                              value={'custom'}
                              label={
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  Custom Customer
                                </Typography>
                              }
                            />
                          </RadioGroup>

                          <Stack gap={'16px'} direction={'row'}>
                            <Input
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              placeholder='Code'
                              value={selectedCustomer?.code || ''}
                              type='text'
                              formControlProps={{
                                sx: {
                                  marginTop: '16px !important'
                                }
                              }}
                              disabled
                            />

                            <Input
                              variant="standard"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              placeholder='Search Customer'
                              value={selectedCustomer?.contactName || ''}
                              type='text'
                              formControlProps={{
                                sx: {
                                  width: '100%',
                                  marginTop: '16px !important'
                                }
                              }}
                              inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => searchModal('Customer')}
                                      hidden={formik.values.customerChange === 'all' || !formik.values.customerType}
                                    >
                                      <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                          fontSize: 'small',
                                          htmlColor: '#0087C2',
                                          sx: {
                                            height: '12px',
                                            width: '12px'
                                          }
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              disabled
                            />
                          </Stack>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box p={'12px'} borderRadius={'5px'} border={'1px solid #C4C4C4'}>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="radioGroupMarketingChange"
                              name="marketingChange"
                              value={formik.values.marketingChange}
                              onChange={(e) => {
                                formik.setFieldValue('marketingChange', e.target.value)
                                setSelectedMarketing({})
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value="all"
                                control={<Radio size='small' />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    All Marketing
                                  </Typography>
                                }
                              />
                              <FormControlLabel
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '400',
                                  lineHeight: '20px',
                                }}
                                value="custom"
                                control={<Radio size='small' />}
                                label={
                                  <Typography
                                    sx={{
                                      color: 'var(--Blue-Primary-1, #083A50)',
                                      fontFamily: 'Nunito',
                                      fontSize: '12px !important',
                                      fontStyle: 'normal',
                                      fontWeight: '500',
                                      lineHeight: '20px',
                                    }}
                                  >
                                    Custom Marketing
                                  </Typography>
                                }
                              />
                            </RadioGroup>
                          </FormControl>

                          <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            placeholder='Search Marketing'
                            value={selectedMarketing?.name || ''}
                            type='text'
                            formControlProps={{
                              sx: {
                                width: '100%',
                                marginTop: '16px !important'
                              }
                            }}
                            inputBaseProps={{
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => searchModal('Marketing')}
                                    hidden={formik.values.marketingChange === 'all'}
                                  >
                                    <Icon
                                      iconName={'search'}
                                      svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                          height: '12px',
                                          width: '12px'
                                        }
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            disabled
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Input
                          fullWidth
                          type='date'
                          label='As Per Date'
                          variant="standard"
                          value={formik.values.asPerDate}
                          InputLabelProps={{ shrink: true }}
                          formControlProps={{ sx: { width: '100%' } }}
                          onChange={(e) => formik.setFieldValue('asPerDate', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {activeModal === 'Customer' && (
        <ModalSearchCustomer
          isModal={activeModal === 'Customer'}
          activeModal={activeModal}
          toggleModal={searchModal}
          dataSelected={selectedCustomer}
          onSelect={setSelectedCustomer}
          errorToast={errorToast}
          formValue={formik.values}
          contactTypeList={contactTypeList}
        />
      )}

      {activeModal === 'Marketing' && (
        <ModalSearchMarketing
          isModal={activeModal === 'Marketing'}
          activeModal={activeModal}
          toggleModal={searchModal}
          dataSelected={selectedMarketing}
          onSelect={setSelectedMarketing}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </Box>
  )
})

export default OutstandingInvoiceReport