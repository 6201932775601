import { Grid, Stack, IconButton, MenuItem, InputAdornment, } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService, { formatCurrency } from '../../../../services/ApiService';
import SelectDataEPL from '../Selects/SelectDataEPL';
import SelectDataContact from '../Selects/SelectDataContact';
import SelectDataPersonal from '../Selects/SelectDataPersonal';
import { Icon, Input, Selection, showToast } from '../../../../components/ui';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';

const PaymentRequestHeader = (props) => {
    const [tempList, setTempList] = useState([]);
    const [tempPaymentTo, setTempPaymentTo] = useState(4);
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramE = 'estimateProfitLoss/estimateProfitLoss';
    const [isLoadingE, setIsLoadingE] = useState(false);
    const [dataE, setDataE] = useState([]);
    const [dataMapE, setDataMapE] = useState([]);
    const [SelectedDataE, setSelectedDataE] = useState({});
    const [totalRowsE, setTotalRowsE] = useState(50);
    const [columnDataE, setColumnDataE] = useState([]);
    let countErrE = 1;

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataHeaderEPL(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchShipment = () => {
        setTitleModal('Shipment Order');
        handleClick();
    }

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const paramC = "estimateProfitLoss/estimateProfitLoss";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([])
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([])
    let countErrC = 1;

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataHeaderContactEPL(1, 50);
        }
        else {
            setIsModal2(false);
        }
    };

    const searchCustomer = () => {
        setTitleModal2(props.Customer);
        handleClick2();
    }

    const [titleModal3, setTitleModal3] = useState('');
    const [isModal3, setIsModal3] = useState(false);

    const paramP = 'user/user';
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [dataMapP, setDataMapP] = useState([])
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);
    const [columnDataP, setColumnDataP] = useState([])
    let countErrP = 1;

    const handleClick3 = () => {
        if (isModal3 === false) {
            setIsModal3(true);
            getDataPersonal(1, 50);
        }
        else {
            setIsModal3(false);
        }
    };

    const searchPersonal = (data) => {
        if (data === 'search') {
            setTitleModal3('Personal');
            handleClick3();
        }
        else if (data === 'delete') {
            props.setPersonalId(0);
            props.setPersonalName('');
        }
    }

    const paymentFromData = [
        { id: 1, code: false, name: 'Payment Request' },
        { id: 2, code: true, name: 'General Pay. Request' }
    ];

    const typeData = [
        { id: 1, code: false, name: 'Non - Cost To Cost' },
        { id: 2, code: true, name: 'Cost To Cost' }
    ];

    const customerToData = [
        { id: 2, code: '4', name: 'SSLine', hidden: props.isSEA },
        { id: 3, code: '5', name: 'IATA', hidden: props.isAIR },
        { id: 4, code: '6', name: 'EMKL', hidden: false },
        { id: 5, code: '8', name: 'Discount Shipper', hidden: props.isExport },
        { id: 6, code: '9', name: 'Discount Consignee', hidden: props.isImport },
        { id: 7, code: '7', name: 'Depo', hidden: false },
    ];

    function handlePaymentFrom(val) {
        props.setIsGeneralPR(val);

        if (val === true) {
            setTempList(props.PaymentRequestDetail);
            props.setPaymentRequestDetail([]);
            props.calculateTotal([]);
            setTempPaymentTo(props.customerId);
        }
        else {
            if (tempPaymentTo === props.customerId) {
                props.setPaymentRequestDetail(tempList);
                props.calculateTotal(tempList);
            }
        }

        props.setCustomerId(0);
        props.setCustomerName('');

        props.setPaymentRequestDetail([]);
        props.calculateTotal([]);
    }

    function handleType(val) {
        props.setIsCostToCost(val);

        if (props.EPLId !== 0) {
            if (props.customerId !== 0) {
                props.setIsLoadingBackDrop(true);
                props.getDataDetail(props.EPLId, props.customerId, val);
            }
        }
    }

    function handleCustomerTo(val) {
        props.setCustomerTypeId(val);

        for (let i = 0; i < customerToData.length; i++) {
            if (val === customerToData[i].code) {
                props.setCustomer(customerToData[i].name);
            }
        }

        props.setCustomerId(0);
        props.setCustomerName('');

        props.setPaymentRequestDetail([]);
        props.calculateTotal([]);
    }

    const getDataHeaderEPL = (pageNumber, pageSize, filters) => {
        setIsLoadingE(true);
        let params = [];
        if (!props.isGeneralPR) {
            params = [...params,
            { 'attr': 'jobTypeId', 'value': '' + props.typeJobId },
            ];
            ApiService.OperationDynamicPostByPage(paramE, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataE({});
                        setDataE(response.data.data.estimateProfitLoss);

                        let temp = response.data.data.estimateProfitLoss;
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp;
                        })

                        setDataMapE(indexed);
                        setColumnDataE(response.data.data.columns.headerColumns);
                        setTotalRowsE(response.data.totalRowCount);

                        setIsLoadingE(false);
                    }
                    setIsLoadingE(false);
                })
                .catch(function (error) {
                    setIsLoadingE(false);
                    countErrE++
                    if (countErrE < 3) {
                        getDataHeaderEPL(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                        setIsModal(false);
                    }
                })
        }
        else {
            params = [...params,
            {
                'attr': "jobTypeId",
                'value': '' + props.typeJobId
            },
            {
                'attr': "jobClosed",
                'value': "false"
            },
            ]
            ApiService.OperationDynamicPostByPage('shipmentOrder/shipmentOrder', 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataE({})
                        setDataE(response.data.data.shipmentOrder);

                        let temp = response.data.data.shipmentOrder
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapE(indexed)
                        setColumnDataE(response.data.data.columns.headerColumns)
                        setTotalRowsE(response.data.totalRowCount)

                        setIsLoadingE(false)
                    }
                    setIsLoadingE(false)
                })
                .catch(function (error) {
                    setIsLoadingE(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                })
        }
    }

    const getDataHeaderContactEPL = (pageNumber, pageSize, filters) => {
        if (props.isGeneralPR === false) {
            if (props.EPLId !== 0) {
                let params = [];
                params = [
                    ...params,
                    { 'attr': 'eplId', 'value': '' + props.EPLId, 'isDistinct': true },
                    { 'attr': 'customerTypeId', 'value': '' + props.customerTypeId, 'isDistinct': true },
                    { 'attr': 'isIncome', 'value': '' + false, 'isDistinct': true },
                    { 'attr': 'customerId', 'value': '', 'isDistinct': true }
                ];

                setIsLoadingC(true);
                ApiService.OperationPostByDetail(paramC, 'SEARCH', pageNumber, pageSize, params)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.estimateProfitLossDetail);

                            let temp = response.data.data.estimateProfitLossDetail
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            setColumnDataC(response.data.data.columns.detailColumns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        countErrC++
                        if (countErrC < 3) {
                            getDataHeaderContactEPL(1, 50);
                        }
                        else {
                            showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                            setIsModal2(false);
                        }
                    })
            }
        }
        else {
            setIsLoadingC(true);
            ApiService.ContactPostByPage('regContact/regContact', props.customerTypeId, pageNumber, pageSize, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.contact);

                        let temp = response.data.data.contact
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        setColumnDataC(response.data.data.columns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false);
                    countErrC++
                    if (countErrC < 3) {
                        getDataHeaderContactEPL(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                        setIsModal2(false);
                    }
                })
        }
    };

    const getDataPersonal = (pageNumber, pageSize, filters) => {
        setIsLoadingP(true);
        ApiService.DynamicPostByPage(paramP, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})
                    setDataP(response.data.data.user);

                    let temp = response.data.data.user
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    setColumnDataP(response.data.data.columns)
                    setTotalRowsP(response.data.totalRowCount)

                    setIsLoadingP(false)
                }
                setIsLoadingP(false)
            })
            .catch(function (error) {
                setIsLoadingP(false);
                countErrP++
                if (countErrP < 3) {
                    getDataPersonal(1, 50);
                }
                else {
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                    setIsModal3(false);
                }
            })
    };

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={12}
                            >
                                <Input
                                    label={'Infoss Number'}
                                    value={props.infossNo}
                                    onChange={(e) => props.setInfossNo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginBottom: '16px !important'
                                        }
                                    }}
                                    disabled={props.isContra === 'false' || props.documentStatus === 'PREVIEW' ? true : false}
                                    message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'Payment From'}
                                    fullWidth={true}
                                    placeholder={'Select Payment From'}
                                    value={props.isGeneralPR}
                                    onChange={(e) => handlePaymentFrom(e.target.value)}
                                    disabled={props.isEdit}
                                >
                                    {
                                        paymentFromData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Selection
                                    id='0'
                                    label={'Type'}
                                    fullWidth={true}
                                    placeholder={'Select Type'}
                                    value={props.isCostToCost}
                                    onChange={(e) => handleType(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.isEdit}
                                >
                                    {
                                        typeData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Input
                                    label={'Pay. Request No'}
                                    value={props.PRNo}
                                    hidden={!props.isEdit}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Input
                                    label={'Reference'}
                                    value={props.reference}
                                    hidden={!props.isEdit}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Input
                                    label={'DN Vendor'}
                                    value={props.vendorDN}
                                    onChange={(e) => props.setVendorDN(e.target.value)}
                                    disabled={props.isContra === 'false' || props.documentStatus === 'PREVIEW' ? true : false}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={5}
                                lg={5}
                                sm={5}
                                xs={12}
                            >
                                <Input
                                    label="Shipment Order No."
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.shipmentNo}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    !props.eplId && props.isEdit !== true ?
                                                        <IconButton onClick={searchShipment} hidden={props.isEdit}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                        :
                                                        <IconButton hidden>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled
                                />

                                <Input
                                    label={'ETD / ETA'}
                                    value={props.ET}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Selection
                                    id='0'
                                    label={'Payment To'}
                                    fullWidth={true}
                                    placeholder={'Select Payment To'}
                                    value={props.customerTypeId}
                                    onChange={(e) => handleCustomerTo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                                >
                                    {
                                        customerToData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} hidden={data.hidden} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label={'Customer Id'}
                                        value={props.customerId}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Customer Name"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.customerName}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '24px'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={searchCustomer}
                                                        hidden={props.isEdit ? true : props.shipmentNo !== '' ? false : true}
                                                        disabled={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>

                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label={'Personal Id'}
                                        value={props.personalId}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Personal Name"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.personalName}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '24px'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchPersonal('delete')}
                                                        disabled={props.isEdit}
                                                        hidden={props.personalName && !props.isEdit ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        onClick={() => searchPersonal('search')}
                                                        disabled={props.isEdit}
                                                        hidden={props.isEdit ? true : props.shipmentNo !== '' ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                {props.selectPrincipleBy()}

                                <Stack spacing={'16px'} direction="row">
                                    <Input
                                        textAlign='right'
                                        label={'Exchange Rate'}
                                        value={props.Rate}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                        }}
                                    />

                                    <Input
                                        label={'Exchange Rate Date'}
                                        value={props.ExRateDate}
                                        type={'date'}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Stack>

                                <Stack spacing={'16px'} direction="row">
                                    <Input
                                        textAlign='right'
                                        label={'Printing'}
                                        value={props.printing}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />

                                    <Input
                                        label={'Printing Date'}
                                        value={props.printedOn}
                                        type={'date'}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataEPL
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        typeJobId={props.typeJobId}
                        jobType={props.jobType}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}

                        getDataHeader={getDataHeaderEPL}
                        isLoading={isLoadingE}
                        setIsLoading={setIsLoadingE}
                        data={dataE}
                        setData={setDataE}
                        dataMap={dataMapE}
                        setDataMap={setDataMapE}
                        columnData={columnDataE}
                        setColumnData={setColumnDataE}
                        SelectedData={SelectedDataE}
                        setSelectedData={setSelectedDataE}
                        totalRows={totalRowsE}
                        setTotalRows={setTotalRowsE}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}

                        bookingConfirmationId={props.bookingConfirmationId}
                        setBookingConfirmationId={props.setBookingConfirmationId}
                        bookingConfirmationNumber={props.bookingConfirmationNumber}
                        setBookingConfirmationNumber={props.setBookingConfirmationNumber}

                        setEPLId={props.setEPLId}
                        setShipmentNo={props.setShipmentNo}
                        setShipmentId={props.setShipmentId}
                        isGeneralPR={props.isGeneralPR}
                        setCustomerId={props.setCustomerId}
                        setCustomerName={props.setCustomerName}
                        setRequester={props.setRequester}
                        setPersonalId={props.setPersonalId}
                        setPersonalName={props.setPersonalName}
                        setTicketId={props.setTicketId}
                        setET={props.setET}
                        setPaymentRequestDetail={props.setPaymentRequestDetail}
                        setPrincipleBy={props.setPrincipleBy}
                        setIsGeneralPR={props.setIsGeneralPR}
                        GetContainerSizeData={props.GetContainerSizeData}
                        setPeriod={props.setPeriod}
                        setYearPeriod={props.setYearPeriod}
                        setIsClosed={props.setIsClosed}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataContact
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        setIsGeneralPR={props.setIsGeneralPR}
                        EPLId={props.EPLId}
                        type={props.customerTypeId}
                        prId={props.prId}
                        calculateTotal={props.calculateTotal}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}

                        getData={getDataHeaderContactEPL}
                        isLoading={isLoadingC}
                        setIsLoading={setIsLoadingC}
                        data={dataC}
                        setData={setDataC}
                        dataMap={dataMapC}
                        setDataMap={setDataMapC}
                        columnData={columnDataC}
                        setColumnData={setColumnDataC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}

                        setCustomerId={props.setCustomerId}
                        setCustomerName={props.setCustomerName}
                        isCostToCost={props.isCostToCost}
                        PaymentRequestDetail={props.PaymentRequestDetail}
                        setPaymentRequestDetail={props.setPaymentRequestDetail}
                        isGeneralPR={props.isGeneralPR}
                        isClosed={props.isClosed}
                        setIsClosed={props.setIsClosed}
                        period={props.period}
                        yearPeriod={props.yearPeriod}
                    />
                )
            }

            {
                isModal3 && (
                    <SelectDataPersonal
                        isModal={isModal3}
                        handleClick={handleClick3}
                        titleModal={titleModal3}
                        setIsModal={setIsModal3}

                        getData={getDataPersonal}
                        isLoading={isLoadingP}
                        setIsLoading={setIsLoadingP}
                        data={dataP}
                        setData={setDataP}
                        dataMap={dataMapP}
                        setDataMap={setDataMapP}
                        columnData={columnDataP}
                        setColumnData={setColumnDataP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}

                        setPersonalId={props.setPersonalId}
                        setPersonalName={props.setPersonalName}
                    />
                )
            }
        </div>
    )
}

export default PaymentRequestHeader;