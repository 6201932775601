import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreateEditBankAdvSettlement = ({
  check,
  Save,
  DataRegUserAccess,
  isEdit,
  Print,
  AddNew
}) => {

  const renderButtons = () => {
    return DataRegUserAccess.map(data => {
      switch (data.accessCode) {
        case 'PRI':
          return isEdit && (
            <React.Fragment key={data.accessId}>
              {ButtonComponent.PrintButton(Print, '', true)}
            </React.Fragment>
          );
        case 'CRE':
          return isEdit && (
            <React.Fragment key={data.accessId}>
              {ButtonComponent.AddButton(AddNew, 'New', false, false, true)}
            </React.Fragment>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: "12px" }}
        >
          {check !== 0 && (
            <>{ButtonComponent.SaveButton(Save, false, false, true)}</>
          )}

          {renderButtons()}
        </Grid>
      </div>
    </div>
  );
}

export default ButtonCreateEditBankAdvSettlement;