import { Backdrop, Box, Grid, MenuItem, Card, CardContent } from '@mui/material';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import ModalPrintQuarter from './Modals/ModalPrintQuarter';
import CustomToastContainer, { Input, Selection, showToast } from '../../../../components/ui';
import ApiService from '../../../../services/ApiService';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ButtonIncomeStatementByBranch from './Buttons/ButtonIncomeStatementByBranch';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';

const IncomeStatementByBranchReport = forwardRef((props, ref) => {
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [period, setPeriod] = useState(new Date());
    const [period2, setPeriod2] = useState(new Date());
    const [disablePeriod, setDisablePeriod] = useState(true)
    const [single, setSingle] = useState(0)
    const labelPeriod = "First Period";
    const labelPeriod2 = "Second Period";
    const [dataJobOwner, setDataJobOwner] = useState([])
    const [JobOwner, setJobOwner] = useState(0)
    const [isModal, setIsModal] = useState(false)
    const periodList = [
        { id: 1, code: 0, name: 'Single Period' },
        { id: 2, code: 1, name: 'Range Period' }
    ];

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Income Statement (Branch) Report', link: '/Majura/IncomeStatementByBranchReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Income Statement by Branch Report - ' + JSON.parse(localStorage.getItem('branchName'));
        GetDataJobOwner()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDefaultMonth = () => {
        const date = new Date();
        const year = date.getFullYear()
        let month = date.getMonth() + 1

        if (month < 10) {
            month = `0${month}`
        }

        setPeriod(`${year}-${month}`);
        setPeriod2(`${year}-${month}`);
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRC() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls");
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Income Statement by Branch Report');
        handleClickConfirmation();
    };

    const PrintExcel = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Income Statement by Branch Report');
        handleClickConfirmation();
    }

    const PrintReport = (code) => {
        const bln = new Date(period).getMonth();
        let bulan = bln + 1;
        const tahun = new Date(period).getFullYear();
        bulan = bulan.toString();
        if (bulan.length === 1) {
            bulan = ['0', bulan].join('');
        }
        const begin = [tahun, bulan, '01'].join('-')

        const bln2 = new Date(period2).getMonth();
        let bulan2 = bln2 + 1;
        const tahun2 = new Date(period2).getFullYear();
        var lastDay = new Date(tahun2, bulan2, 0);
        bulan2 = bulan2.toString();
        if (bulan2.length === 1) {
            bulan2 = ['0', bulan2].join('');
        }
        const end = [tahun2, bulan2, lastDay.getDate()].join('-')

        setIsLoadingPrint(true);
        let variable = "PrintISBranch?typeReport=" + code;
        let urlAddress = "Reports/Finance/Print/FinanceReport/IncomeStatementBranchReport/";
        let params = [
            {
                "attr": "beginningPeriod",
                "value": '' + begin
            },
            {
                "attr": "endDatePeriod",
                "value": '' + end
            },
            {
                "attr": "jobOwnerId",
                "value": "" + JobOwner
            },
            {
                "attr": "isQuarterly",
                "value": "false"
            }
        ];

        ApiService.ReportPrint(urlAddress, variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";

                    if (code === "xls") {
                        rptFormat = "application/vnd.ms-excel"
                    }
                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingPrint(false);
                    showToast({ type: 'success', message: 'Print Income Statement by Branch Success' });
                    window.open(fileURL);
                } else {
                    setIsLoadingPrint(false);
                    showToast({ type: 'error', message: 'Can\'t Print Income Statement by Branch, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingPrint(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Income Statement by Branch, ' + error });
            });
    };

    const handlePeriod = (value) => {
        setSingle(value)
        if (value === 0) {
            setDisablePeriod(true)
            setPeriod2(period)
        }
        else {
            setDisablePeriod(false)
        }
    }

    const GetDataJobOwner = () => {
        setIsLoadingPrint(true);
        let tempArray = [
            { id: 0, masterCode: 'All Principle' }
        ]
        setDataJobOwner([])
        ApiService.PostByPage("jobOwner/jobOwner", 1, 1250, [],)
            .then((response) => {
                if (response.data.code === 200) {
                    let array = response.data.data.jobOwner;

                    array.forEach(element => {
                        tempArray = [...tempArray, element];
                    });

                    setDataJobOwner(tempArray);
                    getDefaultMonth();
                }
                else {
                    ApiService.logOut();
                }
                setIsLoadingPrint(false);
            })
            .catch(function (error) {
                setIsLoadingPrint(false);
                console.error('error saat fetch', error)
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonIncomeStatementByBranch
                PrintData={PrintData}
                PrintExcel={PrintExcel}
                PrintQuarter={handleClick}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Income Statement (Branch) Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Input
                                            label={'Branch'}
                                            value={JSON.parse(localStorage.getItem('branchName'))}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%'
                                                }
                                            }}
                                        />

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={JobOwner}
                                                    label="Principle By"
                                                    fullWidth={true}
                                                    placeholder={'Select Principle By'}
                                                    onChange={(e) => setJobOwner(e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                >
                                                    {
                                                        dataJobOwner.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} key={data.id} value={data.id}>{data.masterCode}</MenuItem>
                                                        ))
                                                    }
                                                </Selection>

                                                <div style={{ marginTop: "16px" }}>
                                                    <Input
                                                        label={labelPeriod}
                                                        name="period"
                                                        value={period}
                                                        onChange={(e) => { setPeriod(e.target.value); setPeriod2(e.target.value) }}
                                                        type={'month'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={single}
                                                    label="Period"
                                                    fullWidth={true}
                                                    placeholder={'Select Period'}
                                                    onChange={(e) => handlePeriod(e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                >
                                                    {
                                                        periodList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>

                                                <div style={{ marginTop: "16px" }} hidden={disablePeriod}>
                                                    <Input
                                                        label={labelPeriod2}
                                                        name="period"
                                                        value={period2}
                                                        onChange={(e) => { setPeriod2(e.target.value) }}
                                                        type={'month'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                        disabled={disablePeriod}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModal && (
                    <ModalPrintQuarter
                        isModal={isModal}
                        handleClick={handleClick}
                        JobOwner={JobOwner}
                        setIsLoadingPrint={setIsLoadingPrint}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRC}
                    />
                )
            }
        </Box>
    )
})

export default IncomeStatementByBranchReport