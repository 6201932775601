import { IconButton, InputAdornment, MenuItem, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import ApiService, { userCode } from '../../../../services/ApiService';
import ButtonService from '../../ButtonService';
import SelectDataMaster from '../SelectDataMaster';
import { useState } from 'react';
import { Icon, Input, Selection, showToast } from '../../../../components/ui';
import CreateEditModalMaster from '../../CreateEditModalMaster';

const CreateEditWarehouseLocation = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramWarehouse = "warehouse/warehouses/warehouses";
    const paramWarehouseLocation = "warehouse/warehouselocation/warehouselocation";
    const [isLoadingWarehouse, setIsLoadingWarehouse] = useState(false);
    const [dataWarehouse, setDataWarehouse] = useState([]);
    const [dataMapWarehouse, setDataMapWarehouse] = useState([]);
    const [columnDataWarehouse, setColumnDataWarehouse] = useState([]);
    const [SelectedDataWarehouse, setSelectedDataWarehouse] = useState({});
    const [totalRowsWarehouse, setTotalRowsWarehouse] = useState(50);
    const [warehouseLocationTypeList, setWarehouseLocationTypeList] = useState([]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataWarehouse(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const getDataWarehouse = (pageNumber, pageSize, filters) => {
        setIsLoadingWarehouse(true);
        ApiService.PostByPage(paramWarehouse, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataWarehouse({})
                    setDataWarehouse(response.data.data.warehouses);

                    let temp = response.data.data.warehouses
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapWarehouse(indexed)
                    setColumnDataWarehouse(response.data.data.columns)
                    setTotalRowsWarehouse(response.data.totalRowCount)

                    setIsLoadingWarehouse(false)
                }
                setIsLoadingWarehouse(false)
            })
            .catch(function (error) {
                setIsLoadingWarehouse(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    };
    const searchWarehouse = () => {
        setTitleModal("Warehouse");
        handleClick();
    }
    const Save = () => {
        var data =
        {
            "warehouselocation": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "locationX": props.locationX ? props.locationX : "0",
                "locationY": props.locationY ? props.locationY : "0",
                "locationZ": props.locationZ ? props.locationZ : "0",
                "warehouseId": props.warehouseId,
                "warehouseName": props.warehouseName,
                "warehouseLocationTypeId": props.warehouseLocationTypeId,
                "warehouseLocationTypeName": props.warehouseLocationTypeName,
                "isRack": props.isRack,
                "isAvailable": JSON.parse(props.isAvailable),
                "userCode": userCode
            }
        }
        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const searchWarehouseLocationType = () => {
        ApiService.PostByPageWarehouseLocationType(paramWarehouseLocation, 1, 100, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setWarehouseLocationTypeList(response.data.data.warehouseLocationType)

                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const warehouseLocationTypeChangeHandler = (value) => {
        props.setWarehouseLocationTypeId(value)
        ApiService.PostByIdWarehouseLocationType(paramWarehouseLocation, value)
            .then((res) => {
                const obj = res.data.data.warehouseLocationType;
                props.setWarehouseLocationTypeName(obj.name);
                props.setIsRack(obj.isRack);
                if (!obj.isRack) {
                    props.setLocationX("");
                    props.setLocationY("");
                    props.setLocationZ("");
                }
            });
    }

    useEffect(() => {
        searchWarehouseLocationType();
    }, [])

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                label="Code"
                value={props.code}
                onChange={(e) => props.setCode(e.target.value)}
                disabled={props.id === 0 ? false : true}
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
            />

            <Input
                label="Name"
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
            />

            <Input
                label="Warehouse"
                value={props.warehouseName}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => searchWarehouse()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Selection
                id='0'
                label={'Warehouse Location Type'}
                fullWidth={true}
                placeholder={'Select Warehouse Location Type'}
                value={props.warehouseLocationTypeId}
                onOpen={searchWarehouseLocationType}
                onChange={(e) => warehouseLocationTypeChangeHandler(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px !important'
                    }
                }}
            >
                {
                    warehouseLocationTypeList.map((data, index) => {
                        return <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                    })
                }
            </Selection>

            <Stack spacing={'32px'} direction="row">
                <Input
                    label="Location X"
                    value={props.locationX}
                    onChange={(e) => props.setLocationX(e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Input
                    label="Location Y"
                    value={props.locationY}
                    onChange={(e) => props.setLocationY(e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Input
                    label="Location Z"
                    value={props.locationZ}
                    onChange={(e) => props.setLocationZ(e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />
            </Stack>

            <Selection
                id='0'
                label={'Available'}
                fullWidth={true}
                placeholder={'Select Available'}
                value={props.isAvailable}
                onChange={(e) => props.setIsAvailable(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px !important'
                    }
                }}
            >
                <MenuItem sx={{ fontSize: '12px' }} key='availabley' value='true'>Yes</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key='availablen' value='false'>No</MenuItem>
            </Selection>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataWarehouse}
                        isLoading={isLoadingWarehouse}
                        totalRows={totalRowsWarehouse}
                        setTotalRows={setTotalRowsWarehouse}
                        SelectedData={SelectedDataWarehouse}
                        setSelectedData={setSelectedDataWarehouse}
                        data={dataWarehouse}
                        columnData={columnDataWarehouse}
                        dataMap={dataMapWarehouse}
                        setId={props.setWarehouseId}
                        setName={props.setWarehouseName}
                    />
                )
            }
        </>
    )
}

export default CreateEditWarehouseLocation