import { Grid, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Input, Selection } from '../../../../../components/ui';

const PaymentPPh23Detail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '12px 16px !important',
            height: '35px !important'
        },
    }));

    const [isJasaLain, setIsJasaLain] = useState(true);
    const jenisPenghasilanList = [
        { id: 1, code: 'D', name: 'Dividen', tarif: 15 },
        { id: 2, code: 'B', name: 'Bunga', tarif: 15 },
        { id: 3, code: 'R', name: 'Royalti', tarif: 15 },
        { id: 4, code: 'HP', name: 'Hadiah dan Penghargaan', tarif: 15 },
        { id: 5, code: 'SP', name: 'Sewa dan Penghasilan lain sehubungan dengan penggunaan harta **)', tarif: 2 },
        { id: 6, code: 'JT', name: 'Jasa Teknik', tarif: 2 },
        { id: 7, code: 'JM', name: 'Jasa Manajemen', tarif: 2 },
        { id: 8, code: 'JK', name: 'Jasa Konsultasi', tarif: 2 },
        { id: 9, code: 'JL', name: 'Jasa Lain : ***)', tarif: 2 },
    ];

    function handleIsJasaLain(value) {
        props.setJenisPenghasilan(value);

        jenisPenghasilanList.forEach(element => {
            if (element.code === value) {
                let tempCount = 0;
                props.setTarif(element.tarif);
                tempCount = parseFloat(props.totalPph23Amount) * parseFloat(element.tarif);
                props.setJumlahPenghasilan(new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(tempCount));
            }
        });

        if (value === 'JL') {
            setIsJasaLain(false);
        }
        else {
            setIsJasaLain(true);
            props.setJasaLainId(0);
            props.setJasaLain('');
        }
    }

    function handleJasaLain(value) {
        props.setJasaLainId(value);

        props.dataJasa.forEach(element => {
            if (element.id === value) {
                props.setJasaLain(element.name);
            }
        });
    }

    return (
        <div>
            <form>
                <div>
                    <div>
                        <TableContainer sx={{ maxHeight: 235, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell sx={{ textAlign: "left", width: 300 }}>Jenis Penghasilan</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: "center" }}>Jumlah Penghasilan Bruto (Rp)</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: "center" }}>Tarif Tambahan WP Tidak Memiliki NPWP</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: "center" }}>Tarif (%)</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: "center" }}>PPh yang Dipotong (Rp)</StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <StyledTableBodyCell sx={{ textAlign: "left" }}>
                                            <Stack direction={'column'} spacing={!isJasaLain ? '16px' : '0px'}>
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    placeholder={'Select Jenis Penghasilan'}
                                                    value={props.jenisPenghasilan}
                                                    onChange={(e) => handleIsJasaLain(e.target.value)}
                                                >
                                                    {
                                                        jenisPenghasilanList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>{data.name}</MenuItem>
                                                        ))
                                                    }
                                                </Selection>

                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    placeholder={'Select Jasa Lain'}
                                                    value={props.jasaLainId}
                                                    onChange={(e) => handleJasaLain(e.target.value)}
                                                    hidden={isJasaLain}
                                                >
                                                    {
                                                        props.dataJasa.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.id}>{data.name}</MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Stack>
                                        </StyledTableBodyCell>

                                        <StyledTableBodyCell sx={{ textAlign: "right" }}>
                                            <Input
                                                value={props.jumlahPenghasilan}
                                                textAlign='center'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />
                                        </StyledTableBodyCell>

                                        <StyledTableBodyCell sx={{ textAlign: "right" }}>
                                            <Input
                                                value={props.tarifTambahan}
                                                textAlign='center'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />
                                        </StyledTableBodyCell>

                                        <StyledTableBodyCell sx={{ textAlign: "right" }}>
                                            <Input
                                                value={props.tarif}
                                                textAlign='center'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />
                                        </StyledTableBodyCell>

                                        <StyledTableBodyCell sx={{ textAlign: "right" }}>
                                            <Input
                                                value={props.pphPotongan}
                                                textAlign='center'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />
                                        </StyledTableBodyCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Print Date"
                                    value={props.printedOn}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Approved By"
                                    value={props.approvedBy}
                                    onChange={(e) => props.setApprovedBy(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Approved By Position"
                                    value={props.approvedByPosition}
                                    onChange={(e) => props.setApprovedByPosition(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label={'Remarks'}
                                    value={props.remarks}
                                    onChange={(e) => props.setRemarks(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 9,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PaymentPPh23Detail