import React from "react";
import { Grid } from "@mui/material";
import ButtonComponent from "../../../../../components/Buttons/ButtonComponent";

const ButtonCreateEditOutBound = ({ Save, Id, Picking }) => {
  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: "12px" }}
        >
          {ButtonComponent.SaveButton(Save, false, false, true)}
          {Id && ButtonComponent.PickingButton(Picking, true)}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonCreateEditOutBound;