import React from 'react';

import '../../Styles/spinner.css';
import { Avatar } from '@mui/material';
import loadingSpinners from '../../Assets/Icon/loadingSpinner.png';

const LoadingSpinner = ({ style, spinnerStyle = { height: '65px', width: '65px' } }) => {
  return (
    <div className="spinner-container" style={{transform: 'rotateX(180deg)', ...style}}>
      <Avatar 
        src={loadingSpinners}
        variant='square'
        alt='.'
        className="loading-spinner"
        style={spinnerStyle}
      />
      {/* <div className="loading-spinner" ></div> */}
    </div>
  )
}

export default LoadingSpinner