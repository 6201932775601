import { Avatar, Button, CssBaseline, Backdrop, Grid, Stack, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import axios from 'axios';
import ModalLogin from './Modals/ModalLogin';
import CustomToastContainer, { Icon, showToast } from '../../../components/ui';
import majuraIconWhite from '../../../Assets/Icon/majuraIconWhite.svg';
import ApiService, { username } from '../../../services/ApiService';
import majuraIconBlue from '../../../Assets/Icon/majuraIconBlue.svg';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import backgroundLanding from '../../../Assets/Background/backgroundLanding.jpg';
import backgroundAbstractLine from '../../../Assets/Background/abstractLine.png';

export const urlAuth = process.env.REACT_APP_API_URL_AUTHENTIFICATION;

const newData = (data) => {
    let newArray = [...data];
    return newArray.map((ele, i) => {
        return { ...ele, id: i, index: i };
    })
}

// function Copyright(props) 
// {
//     return (
//         <Stack spacing={0.5} direction="row" justifyContent={'center'} className='mt-5'>
//             <Typography variant="body2" color="text.secondary" align="center" {...props}>
//                 Copyright © 
//             </Typography>

//             <Typography variant="body2" color="text.secondary" align="center" {...props}>
//                 <Link color="inherit" href="/">
//                     Majura 
//                 </Link>
//             </Typography>

//             <Typography variant="body2" color="text.secondary" align="center" {...props}>
//                 {new Date().getFullYear()}.
//             </Typography>
//         </Stack>
//     );
// }

const theme = createTheme();

// export const getDataMenu = (dataMenu) => {
//     var menu = []
//     let menuCode = []
//     var mainMenu = dataMenu.filter(el => el.parentId === 0)
//     menu = insertMenu(dataMenu, mainMenu, true);
//     menuCode = insertMenuCode(dataMenu);
//     localStorage.setItem("dataMenu", JSON.stringify(menu));
//     localStorage.setItem("dataMenuCode", JSON.stringify(menuCode));
// }

// const insertMenu = (data, dataParent, isParent = false) => {
//     var menu = []
//     dataParent.forEach((el) => {
//         let childData = data.filter(elm => elm.parentId === el.permissionId);
//         var children = [];
//         if (childData.length > 0) {
//             children = insertMenu(data, childData);
//         }

//         let permissionName = el.permissionName;

//         if (isParent) {
//             permissionName = permissionName.toUpperCase();
//         }

//         menu = [
//             ...menu,
//             {
//                 id: '' + el.permissionId,
//                 name: '' + permissionName,
//                 icon: el?.iconName || '',
//                 children: children,
//                 url: '' + el.url,
//                 code: '' + el.permissionCode,
//                 hidden: '' + el.hidden
//             }
//         ];
//     })

//     return menu;
// }

// const insertMenuCode = (data) => {
//     var menuCode = []
//     data.forEach((el) => {
//         if (el?.url) {
//             menuCode.push({
//                 id: String(el?.permissionId || 0),
//                 code: (el?.permissionCode || ''),
//             })
//         }
//     })

//     return menuCode;
// }

export default function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        document.title = 'Majura - Login'
        checkLogin();
        window.ResizeObserver = null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function checkLogin() {
        setIsLoading(true);
        let expired = JSON.parse(localStorage.getItem("expiredToken"));
        let currentDate = new Date();
        let userNames = JSON.parse(localStorage.getItem("username"));
        let userUrl = userNames + '-lastUrl';
        if (expired * 1000 > currentDate) {
            if (userNames) {
                setIsLoading(false);
                let lastUrl = JSON.parse(localStorage.getItem(userUrl));

                if (lastUrl === '/' || !lastUrl) {
                    history('/Majura');
                }
                else {
                    history(lastUrl)
                }

                window.location.reload();
                ApiService.refreshToken();
            }
            else {
                setIsLogin(false);
                setIsLoading(false);
            }
        }
        else {
            setIsLogin(false);
            setIsLoading(false);
        }
    }

    const handleModal = () => {
        if (isModal) {
            setIsModal(false);
            setUserName('');
            setPassword('');
            setIsPassword(false);
        }
        else {
            setIsModal(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        let pointer = 0;
        let message = '';

        if (username !== '' && password !== '') {
            const loginPayload = {
                authentication:
                {
                    username: userName,
                    password: password
                }
            }
            const loginHeader = {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }

            let userUrl = ''
            await axios.post(urlAuth + 'Login', loginPayload, loginHeader)
                .then((res) => {
                    if (res.data.code === 200) {
                        let dataLogin = res.data.data.authentication;
                        let jobTypeId;
                        let currency = 'USD';
                        let parentCompanyId = 0;

                        if (dataLogin.companyIdPusat !== undefined) {
                            parentCompanyId = dataLogin.companyIdPusat;
                        }

                        const token = dataLogin.token;
                        const refreshToken = dataLogin.refreshToken;
                        const companyId = dataLogin.companyId;
                        const countryId = dataLogin.countryId;
                        const branchId = dataLogin.branchId;
                        const companyName = dataLogin.companyName;
                        const countryName = dataLogin.countryName;
                        const branchName = dataLogin.branchName;
                        const userId = dataLogin.userId;
                        const userNames = dataLogin.code;
                        const userCode = dataLogin.code;
                        const roleId = dataLogin.roleId;
                        const roleLevel = dataLogin.roleLevel;
                        const roleName = dataLogin.roleName;
                        const groupId = dataLogin.groupId;
                        const idleTimer = dataLogin.idleTimer;
                        const data = res.data.data;

                        if (dataLogin.jobTypeId === 0) {
                            jobTypeId = 1;
                        }
                        else {
                            jobTypeId = dataLogin.jobTypeId;
                        }

                        let decode = jwtDecode(token);
                        localStorage.setItem("expiredToken", JSON.stringify(decode.exp));
                        localStorage.setItem("token", JSON.stringify(token));
                        localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
                        localStorage.setItem("companyId", JSON.stringify(companyId));
                        localStorage.setItem("countryId", JSON.stringify(countryId));
                        localStorage.setItem("branchId", JSON.stringify(branchId));
                        localStorage.setItem("companyName", JSON.stringify(companyName));
                        localStorage.setItem("countryName", JSON.stringify(countryName));
                        localStorage.setItem("branchName", JSON.stringify(branchName));
                        localStorage.setItem("userId", JSON.stringify(userId));
                        localStorage.setItem("roleId", JSON.stringify(roleId));
                        localStorage.setItem("roleLevel", JSON.stringify(roleLevel));
                        localStorage.setItem("groupId", JSON.stringify(groupId));
                        localStorage.setItem("roleName", JSON.stringify(roleName));
                        localStorage.setItem("dataUser", JSON.stringify(data));
                        localStorage.setItem("idleTimer", JSON.stringify(idleTimer));
                        localStorage.setItem("username", JSON.stringify(userNames));
                        localStorage.setItem("userCode", JSON.stringify(userCode));
                        localStorage.setItem("sidebarStatus", JSON.stringify('false'));
                        localStorage.setItem("pinStatus", JSON.stringify('false'));
                        localStorage.setItem("jobTypeId", JSON.stringify(jobTypeId));
                        localStorage.setItem("rows", JSON.stringify(50));
                        localStorage.setItem("page", JSON.stringify(1));
                        localStorage.setItem("bsPeriod", JSON.stringify(new Date()));
                        localStorage.setItem('dashboardMenu', JSON.stringify(0));
                        localStorage.setItem('parentCompanyId', JSON.stringify(parentCompanyId));

                        if (currency === 'USD') {
                            localStorage.setItem('formatCurrency', JSON.stringify('USD'));
                            localStorage.setItem('currency', JSON.stringify('US-en'));
                        }
                        else if (currency === 'IDR') {
                            localStorage.setItem('formatCurrency', JSON.stringify('IDR'));
                            localStorage.setItem('currency', JSON.stringify('ID-id'));
                        }

                        var data1 = data.user.userProfiles;
                        var dataNew = newData(data1);
                        localStorage.setItem("dataProfile", JSON.stringify(dataNew));

                        var dataMenu = dataNew.filter(
                            el => el.companyId === companyId
                                && el.countryId === countryId
                                && el.branchId === branchId
                                && el.roleId === roleId
                        );

                        if (dataMenu) {
                            dataMenu = dataMenu[0].permissions;
                        }

                        // getDataMenu(dataMenu)

                        pointer = 1;
                        userUrl = userNames + '-lastUrl'
                    }
                    else {
                        message = res.message;
                        setIsLoading(false);
                        showToast({ type: 'error', message: message });
                    }
                }).then(() => {
                    if (pointer === 1) {
                        let lastUrl = JSON.parse(localStorage.getItem(userUrl));
                        if (lastUrl === '/' || !lastUrl) {
                            setIsLoading(false);
                            history('/Majura');
                        }
                        else {
                            setTimeout(() => {
                                setIsLoading(false);
                                history(lastUrl);
                                localStorage.removeItem(userUrl)
                                window.location.reload()
                            }, 1250);
                        }
                        window.location.reload()
                    }
                    else {
                        setIsLoading(false);
                        showToast({ type: 'error', message: message });
                    }
                })
                .catch(function (error) {
                    setIsLoading(false);
                    console.log(error)
                    showToast({ type: 'error', message: 'Wrong Usename or Password!' });
                })
        }
        else {
            setIsLoading(false);
            showToast({ type: 'error', message: 'Please Input Username & Password' });
        }
    };

    return (
        <>
            <CustomToastContainer />
            {
                isLogin === true ?
                    <></>
                    :
                    <ThemeProvider theme={theme}>
                        <div style={{ backgroundColor: '#EAEAEA' }}>
                            <div
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    width: '100%',
                                    height: '90px',
                                    padding: '16px 64px',
                                    background: '#1591C8',
                                    zIndex: 1,
                                }}
                            >
                                <Grid container item direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item xs={6} display={'flex'}>
                                        <Avatar
                                            src={majuraIconWhite}
                                            sx={{ width: 'auto', height: 'auto' }}
                                            variant='square'
                                            alt='.'
                                        />
                                    </Grid>

                                    <Grid item xs={6} alignItems='right' className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                        <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <Button
                                                variant="contained"
                                                onClick={handleModal}
                                                sx={{
                                                    fontWeight: 800,
                                                    fontFamily: 'Nunito, sans-serif',
                                                    fontSize: '16px',
                                                    background: '#EAEAEA',
                                                    borderRadius: '8px',
                                                    color: '#0087C2',
                                                    width: '150px',
                                                    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.15)',
                                                    '&:hover': {
                                                        background: 'linear-gradient(220deg, #00F0FF -3.72%, #00278A 109.9%) !important',
                                                        color: '#EAEAEA !important',
                                                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.35)',
                                                    },
                                                }}
                                            >
                                                Sign In
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    marginTop: '-90px',
                                    justifyContent: 'center'
                                }}
                            >
                                <Grid
                                    container
                                    component="main"
                                    sx={{
                                        width: '100%',
                                        height: `calc(100vh)`,
                                        justifyContent: 'center'
                                    }}
                                >
                                    <CssBaseline />
                                    <Grid
                                        item
                                        xs={false}
                                        sm={12}
                                        md={12}
                                        sx={{
                                            backgroundImage: `url(${backgroundLanding})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '190px',
                                                justifyContent: 'center',
                                                background: 'linear-gradient(180deg, #1591C8 34.5%, #1591C8 55%, rgba(21, 145, 200, 0.60) 77.74%, rgba(249, 253, 255, 0.00) 100%)'
                                            }}
                                        />

                                        <div
                                            style={{
                                                width: '100%',
                                                padding: '106px 96px 0px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Grid container item direction="row" sx={{ alignItems: 'center', justifyContent: 'center', }}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                >
                                                    <div style={{ width: 'auto' }}>
                                                        <Typography
                                                            sx={{
                                                                color: '#FFF',
                                                                textShadow: '0px 8px 16px rgba(0, 0, 0, 0.32)',
                                                                fontFamily: 'Kanit, sans-serif',
                                                                fontSize: '70px',
                                                                fontStyle: 'italic',
                                                                fontWeight: 700,
                                                                lineHeight: '70px',
                                                                letterSpacing: '3.5px',
                                                            }}
                                                        >
                                                            One Stop Solution
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                color: '#FFF',
                                                                textShadow: '0px 8px 16px rgba(0, 0, 0, 0.32)',
                                                                fontFamily: 'Kanit, sans-serif',
                                                                fontSize: '70px',
                                                                fontStyle: 'italic',
                                                                fontWeight: 700,
                                                                lineHeight: '70px',
                                                                letterSpacing: '3.5px',
                                                            }}
                                                        >
                                                            Shipment Platform
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                color: '#FFF',
                                                                textShadow: '0px 8px 16px rgba(0, 0, 0, 0.32)',
                                                                fontFamily: 'Kanit',
                                                                fontSize: '28px',
                                                                fontStyle: 'normal',
                                                                fontWeight: 500,
                                                                lineHeight: '70px',
                                                                letterSpacing: '1.4px',
                                                            }}
                                                        >
                                                            Number One Shipment Platform in Indonesia
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                color: '#FFF',
                                                                textShadow: '0px 8px 16px rgba(0, 0, 0, 0.32)',
                                                                fontFamily: 'Kanit',
                                                                fontSize: '22px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '500',
                                                                lineHeight: '70px',
                                                                letterSpacing: '1.1px',
                                                                marginTop: '-25px',
                                                            }}
                                                        >
                                                            Click the Button Below to Partnership With Us
                                                        </Typography>
                                                    </div>

                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            marginTop: '24px',
                                                            fontWeight: 800,
                                                            fontFamily: 'Nunito, sans-serif',
                                                            fontSize: '16px',
                                                            margin: '0px 0px 0px 0px',
                                                            background: '#EAEAEA',
                                                            borderRadius: '8px',
                                                            color: '#0087C2',
                                                            width: '225px',
                                                            height: '50px',
                                                            boxShadow: '0px 16px 8px 0px rgba(0, 0, 0, 0.15)',
                                                            '&:hover': {
                                                                background: 'linear-gradient(220deg, #00F0FF -3.72%, #00278A 109.9%) !important',
                                                                color: '#EAEAEA !important',
                                                                boxShadow: '0px 16px 8px 0px rgba(0, 0, 0, 0.25)',
                                                            },
                                                        }}
                                                    >
                                                        <Stack direction={'row'} spacing={1}>
                                                            <Icon iconName={'cs'} />

                                                            <span>
                                                                Contact Us
                                                            </span>
                                                        </Stack>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%',
                                    }}
                                >
                                    <Grid
                                        sx={{
                                            transform: 'rotate(180deg)',
                                            height: '103px',
                                            background: 'linear-gradient(180deg, #0087C2 0%, rgba(147, 204, 229, 0.45) 54%, rgba(255, 255, 255, 0.00) 100%)',
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ marginTop: '44px' }}>
                                <div
                                    style={{
                                        backgroundImage: `url(${backgroundAbstractLine})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                        height: '100vh',
                                        backgroundAttachment: 'fixed',
                                        position: 'absolute',
                                        backgroundPosition: 'center',
                                        '-webkit-animation': 'mover 5s infinite alternate',
                                        animation: 'mover 5s infinite alternate',
                                    }}
                                />

                                <Grid container component="main" sx={{ height: '100vh', width: '100%' }}>
                                    <div style={{ padding: '129px 0px 0px 0px', width: '100%' }}>
                                        <Stack direction={'column'} sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                            <Avatar
                                                src={majuraIconBlue}
                                                sx={{ width: '484px', height: '99px' }}
                                                variant='square'
                                                alt='.'
                                            />

                                            <Typography
                                                sx={{
                                                    marginTop: '16px',
                                                    textShadow: '0px 8px 16px rgba(0, 0, 0, 0.32)',
                                                    fontFamily: 'Kanit, sans-serif',
                                                    fontSize: '65px',
                                                    fontStyle: 'italic',
                                                    fontWeight: '700',
                                                    lineHeight: '70px',
                                                    letterSpacing: '3.25px',
                                                    background: 'linear-gradient(220deg, #00F0FF -3.72%, #00278A 109.9%)',
                                                    backgroundClip: 'text',
                                                    '-webkit-background-clip': 'text',
                                                    '-webkit-text-fill-color': 'transparent',
                                                }}
                                            >
                                                One Stop Solution Shipment Platform
                                            </Typography>
                                        </Stack>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </ThemeProvider>
            }

            <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={isLoading}
            >
                <LoadingSpinner />
            </Backdrop>

            <ModalLogin
                isModal={isModal} handleClick={handleModal} setIsModal={setIsModal}

                isPassword={isPassword} setIsPassword={setIsPassword}
                userName={userName} setUserName={setUserName}
                password={password} setPassword={setPassword}
                handleSubmit={handleSubmit} isLoading={isLoading}
            />
        </>
    );
}
