import { Grid, IconButton, MenuItem, Stack, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

import SelectDataNonContact from '../Selects/SelectDataNonContact';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';

const ModalVessel = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [tipe, setTipe] = useState(0);

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);
    let countErrN = 1;

    const handleClick = (title) => {
        if (isModal === false) {
            setIsModal(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal(false);
            localStorage.removeItem("title");
        }
    };

    const searchVessel = (data) => {
        if (data === 'search') {
            setTitleModal('Vessel');
            handleClick('Vessel');
        }
        else if (data === 'delete') {
            props.setVesselId(0);
            props.setVesselName('');
        }
    };

    const searchCity = (data) => {
        if (data === 'search') {
            setTitleModal('City');
            setTipe(3);
            handleClick('City');
        }
        else if (data === 'delete') {
            props.setCityId(0);
            props.setCityCode('');
            props.setCityName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick2();
    };

    const emptyState = () => {
        props.setVesselId(0);
        props.setVesselType('FEEDER');
        props.setVesselName('');
        props.setVoyage('');
        props.setETD('');
        props.setCityId(0);
        props.setCityCode('');
        props.setCityName('');
        props.getDateNow();
    };

    const vesselTypeData = [
        { id: 1, name: 'FEEDER' },
        { id: 2, name: 'CONNECTING' },
        { id: 3, name: 'MOTHER' },
    ];

    const handleSave = () => {
        if (!props.vesselId) {
            showToast({ type: 'error', message: 'Please Select Vessel!' });
        }
        else {
            if (!props.voyage) {
                showToast({ type: 'error', message: 'Please Input Voyage!' });
            }
            else {
                if (!props.etd) {
                    showToast({ type: 'error', message: 'Please Input ETD!' });
                }
                else {
                    if (props.calculateTypeVessel(props.vesselData, props.vesselType) !== 0 && props.detailEdit === false) {
                        if (props.calculateTypeVessel(props.vesselData, props.vesselType) === 1) {
                            showToast({ type: 'error', message: 'Feeder Vessel Already Registered!' });
                        }
                        else if (props.calculateTypeVessel(props.vesselData, props.vesselType) === 2) {
                            showToast({ type: 'error', message: 'Mother Vessel Already Registered!' });
                        }
                    }
                    else {
                        let Sequence = 1;

                        if (props.detailEdit) {
                            Sequence = props.selectedDetail.sequence;
                        }
                        else {
                            if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                        }

                        let data = {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "userId": userId,
                            "userCode": userCode,

                            "rowStatus": "ACT",
                            "shipmentId": props.ShipmentId,
                            "sequence": Sequence,
                            "vesselId": props.vesselId,
                            "vesselName": props.vesselName,
                            "vesselType": props.vesselType,
                            "voyage": props.voyage,
                            "cityId": props.cityId,
                            "cityCode": props.cityCode,
                            "cityName": props.cityName,
                            "portId": 0,
                            "portCode": "",
                            "portName": "",
                            "flightId": 0,
                            "flightCode": "",
                            "flightName": "",
                            "flightNo": "",
                            "airportFromId": 0,
                            "airPortFromCode": "",
                            "airPortFromName": "",
                            "airportToId": 0,
                            "airPortToCode": "",
                            "airPortToName": "",
                            "cityFromId": 0,
                            "cityFromCode": "",
                            "cityFromName": "",
                            "cityToId": 0,
                            "cityToCode": "",
                            "cityToName": "",
                            "etd": props.etd,
                            "idLama": 0,
                        }

                        let temp = props.checkVessel(props.vesselData, data);

                        if (temp === 0) {
                            if (!props.detailEdit) {
                                props.setVesselData([...props.vesselData, data]);
                            }
                            else {
                                const newArr = props.vesselData.slice();
                                newArr.forEach(el => {
                                    if (el.sequence === data.sequence) {
                                        el.vesselId = data.vesselId;
                                        el.vesselType = data.vesselType;
                                        el.vesselName = data.vesselName;
                                        el.voyage = data.voyage;
                                        el.etd = data.etd;
                                        el.cityId = data.cityId;
                                        el.cityCode = data.cityCode;
                                        el.cityName = data.cityName;
                                    }
                                })
                                props.setVesselData(newArr);
                            }

                            emptyState();
                            handleClose();
                            props.setDetailEdit(false);
                            props.setSelectedDetail({});
                        }
                        else {
                            showToast({ type: 'error', message: 'Vessel Already Registered!' });
                        }
                    }
                }
            }
        }
    };

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        let title = JSON.parse(localStorage.getItem("title"));

        if (title === 'Vessel') {
            param = 'vessel/vessel';
        }

        else if (title === 'City') {
            param = 'city/city';
        }

        if (param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        let temp = [];
                        switch (title) {
                            case 'Vessel':
                                setDataN(response.data.data.vessel);
                                temp = response.data.data.vessel;
                                break;
                            case 'City':
                                setDataN(response.data.data.city);
                                temp = response.data.data.city;
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false);
                    countErrN++
                    if (countErrN < 3) {
                        getDataNonContact(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                        setIsModal(false);
                    }
                })
        }
    };

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Vessel Name"
                        value={props.vesselName}
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => searchVessel('delete')}
                                        hidden={props.vesselName ? false : true}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => searchVessel('search')}
                                    >
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <Selection
                        id='0'
                        label={'Type'}
                        fullWidth={true}
                        placeholder={'Select Type'}
                        value={props.vesselType}
                        onChange={(e) => props.setVesselType(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px !important'
                            }
                        }}
                        hidden={props.TabType === 'Inc Agent' || props.TabType === 'Cost Agent' ? true : false}
                        disabled={props.isViewOnly}
                    >
                        {
                            vesselTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Voyage"
                        value={props.voyage}
                        onChange={(e) => props.setVoyage(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label="ETD"
                        type={'date'}
                        value={props.etd}
                        onChange={(e) => props.setETD(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                <Input
                    label="City Code"
                    value={props.cityCode}
                    disabled
                    formControlProps={{
                        sx: {
                            marginTop: '24px',
                            width: '50%'
                        }
                    }}
                />

                <Input
                    label="City Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={props.cityName}
                    type='text'
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => searchCity('delete')}
                                    hidden={props.cityName ? false : true}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton onClick={() => searchCity('search')}>
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled
                />
            </Stack>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal2}
                isOpen={props.isModal2}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'md'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataNonContact
                        jobType={props.jobType}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        tipe={tipe}
                        setTitleModal={setTitleModal}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setVesselId={props.setVesselId}
                        setVesselName={props.setVesselName}
                        setCityId={props.setCityId}
                        setCityCode={props.setCityCode}
                        setCityName={props.setCityName}
                    />
                )
            }
        </>
    )
}

export default ModalVessel;