import { Box, Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses, Button, IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import { Icon, PopUp, showToast, TickBox } from '../ui';
import ApiService from '../../services/ApiService';

const ModalUsageData = React.memo(({
    titleModal,
    isModal,
    handleClick,
    isDesc,
    description,
    usageDataList,
    setIsLoadingBackDrop
}) => {
    const [isAll, setIsAll] = useState(false);
    const [dataCheck, setDataCheck] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const selectedStyle = { backgroundColor: '#DCDCDC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const showDocuments = (data) => {
        if (data.usedOn === 'Official Receipt') {
            window.open('/Majura/OfficialReceipt/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Payment Voucher') {
            window.open('/Majura/PaymentVoucher/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Receipt Voucher') {
            window.open('/Majura/ReceiptVoucher/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Temporary Payment') {
            window.open('/Majura/TemporaryPayment/' + data.jobTypeId + '/' + data.id, '_blank');
        }
        else if (data.usedOn === 'Temporary Receipt') {
            if (data.isDP) {
                window.open('/Majura/DownPayment/' + data.id, '_blank');
            }
            else {
                window.open('/Majura/TemporaryReceipt/' + data.jobTypeId + '/' + data.id, '_blank');
            }
        }
    }

    const handleCheck = (e, el) => {
        let dataChecked = [];

        if (e.target.checked) {
            const data = {
                'sequence': el.sequence,
                'source': el.usedOn,
                'id': el.id,
            };
            dataChecked = [...dataCheck, data];
        } else {
            dataChecked = dataCheck.filter(dt => dt.sequence !== el.sequence);
        }

        setDataCheck(dataChecked);
        const isAllChecked = dataChecked.length === usageDataList.length;
        setIsAll(isAllChecked);
    };

    const handleCheckAll = (e) => {
        let dataChecked = [];

        if (e.target.checked) {
            dataChecked = usageDataList.map(el => ({
                'sequence': el.sequence,
                'source': el.usedOn,
                'id': el.id,
            }));
        } else {
            dataChecked = [];
        }

        setDataCheck(dataChecked);
        setIsAll(e.target.checked);
    };

    function handleSource(source) {
        switch (source) {
            case 'Official Receipt':
                return 'OR';

            case 'Payment Voucher':
                return 'PV';

            case 'Receipt Voucher':
                return 'RV';

            case 'Temporary Payment':
                return 'TP';

            case 'Temporary Receipt':
                return 'TR';

            default:
                return '';
        }
    }

    function handleApproveDoc() {
        if (dataCheck.length > 0) {
            let data = '';
            handleClick();
            setIsLoadingBackDrop(true);
            const paramGL = 'generalLedger/generalLedger/MultiVerify';
            dataCheck.forEach(el => {
                if (el.sequence === dataCheck[dataCheck.length - 1].sequence) {
                    data = data + handleSource(el.source) + ':' + el.id;
                }
                else {
                    data = data + handleSource(el.source) + ':' + el.id + ',';
                }
            });

            const payload = { "generalLedger": { "processedData": data } };
            ApiService.FinAccCustomPost(paramGL, payload)
                .then((res) => {
                    if (res.data.code === 200) {
                        showToast({ type: 'success', message: 'Data Approved Successfully!' });

                        setTimeout(
                            function () {
                                setIsLoadingBackDrop(false);
                                window.location.reload();
                            },
                            1250);
                    }
                    else {
                        showToast({ type: 'error', message: 'Failed to Approve Data!, ' + res.data.message });
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    showToast({ type: 'error', message: 'Failed to Approve Data!, ' + err });
                    setIsLoadingBackDrop(false);
                })
        }
        else {
            showToast({ type: 'error', message: 'Please Select Data to Approve!' });
        }
    }

    const mainContainer = (
        <>
            <Box display="flex">
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        marginTop: '3px !important',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '20px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {isDesc ? description : 'This Data Is Used In This Following Transaction:'}
                </Typography>
            </Box>

            <Box sx={{ marginTop: '16px' }}>
                <TableContainer sx={{ maxHeight: 230, borderRadius: '12px', background: '#FFF' }}>
                    <Table sx={{ minWidth: 450 }} stickyHeader>
                        <TableHead
                            sx={{
                                '& th:first-child': {
                                    borderRadius: '12px 0 0'
                                },
                                '& th:last-child': {
                                    borderRadius: '0 12px 0 0'
                                }
                            }}
                        >
                            <TableRow>
                                {isDesc && (
                                    <StyledTableCell sx={{ textAlign: 'center', userSelect: 'none', }}>
                                        <TickBox
                                            size={'small'}
                                            checked={isAll}
                                            onChange={(e) => { handleCheckAll(e) }}
                                        />
                                    </StyledTableCell>
                                )}
                                <StyledTableCell sx={{ userSelect: 'none', }}>
                                    {isDesc ? 'Source' : 'Used On'}
                                </StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center', userSelect: 'none', }}>Transaction Number</StyledTableCell>
                                {isDesc && (<StyledTableCell sx={{ textAlign: 'center', userSelect: 'none', }}>Action</StyledTableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                usageDataList.length > 0 ?
                                    usageDataList.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                hover={true}
                                                onClick={() => setSelectedData(el)}
                                                onDoubleClick={() => showDocuments(el)}
                                                sx={
                                                    selectedData.sequence === el.sequence ?
                                                        selectedStyle
                                                        :
                                                        { backgroundColor: 'transparent' }
                                                }
                                            >
                                                {isDesc && (
                                                    <StyledTableBodyCell sx={{ textAlign: 'center', }}>
                                                        <TickBox
                                                            size={'small'}
                                                            checked={
                                                                dataCheck.filter(dt => dt.sequence === el.sequence).length > 0 ?
                                                                    true
                                                                    :
                                                                    false
                                                            }
                                                            onChange={(e) => { handleCheck(e, el) }}
                                                        />
                                                    </StyledTableBodyCell>
                                                )}
                                                <StyledTableBodyCell sx={{ userSelect: 'none', }}>{el.usedOn}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center', userSelect: 'none', }}>{el.transactionNumber}</StyledTableBodyCell>
                                                {
                                                    isDesc && (
                                                        <StyledTableBodyCell sx={{ textAlign: 'center' }}>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                disableFocusRipple={true}
                                                                disableRipple={true}
                                                                onClick={() => { showDocuments(el) }}
                                                                sx={{
                                                                    alignItems: 'center',
                                                                    padding: '0px !important',
                                                                    '&:hover': {
                                                                        boxShadow: 'none',
                                                                    },
                                                                }}
                                                            >
                                                                <Stack direction={'row'} spacing={'3px'} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                                            fontFamily: 'Nunito',
                                                                            fontSize: '14px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '700',
                                                                            lineHeight: '20px',
                                                                            letterSpacing: '-0.36px',
                                                                            'text-decoration-line': 'underline',
                                                                        }}
                                                                    >
                                                                        Detail
                                                                    </Typography>

                                                                    <Icon
                                                                        iconName={'arrow-right'}
                                                                        svgIconProps={{
                                                                            htmlColor: '#083A50',
                                                                            sx: {
                                                                                width: '12px',
                                                                                height: '12px',
                                                                            }
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            </IconButton>
                                                        </StyledTableBodyCell>
                                                    )
                                                }
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={isDesc ? 'documents' : 'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#083A50',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={isDesc ? titleModal : 'Transaction Related To This ' + titleModal}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '24px 6px 16px' }}>
                    {mainContainer}
                </div>

                <div style={{ padding: '24px 6px 0px' }}>
                    <Box display="flex" alignItems="center">
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                {
                                    isDesc && (
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            className='d-flex align-items-center'
                                            sx={{
                                                padding: '4px 12px !important',
                                                gap: '4px',
                                                marginInlineStart: '12px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '32px !important',
                                                borderRadius: '8px',
                                                background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                                '&:hover': {
                                                    backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                                }
                                            }}
                                            onClick={handleApproveDoc}
                                        >
                                            <Icon
                                                svgIconProps={{
                                                    htmlColor: '#E4E8EC',
                                                    sx: {
                                                        height: '14px',
                                                        width: '14px'
                                                    }
                                                }}
                                                iconName={'checklist'}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Nunito',
                                                    marginTop: '3px !important',
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    lineHeight: '20px',
                                                    textAlign: 'center',
                                                    color: '#E4E8EC',
                                                }}
                                            >
                                                Approved
                                            </Typography>
                                        </Button>
                                    )
                                }

                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #0087C2)',
                                        background: 'var(--Blue-Button-Primary-1, #B3E3FF)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #0087C2)',
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #B3E3FF) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={handleClick}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'close'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#0087C2',
                                        }}
                                    >
                                        Close
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default React.memo(ModalUsageData);