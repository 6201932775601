import { FormHelperText, MenuItem } from '@mui/material';
import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import ApiService, { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditCity = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const [isLoading, setIsLoading] = useState(true)
    const [countryList, setCountryList] = useState([])
    const [provinceList, setProvinceList] = useState([])

    useEffect(() => {
        if (isModal) {
            getCountryData()
            if (selectedData?.id) {
                getProvinceData('SEARCH', [{ attr: 'countryId', value: String(selectedData.countryId) }])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    const errorToast = (location, closeModal = false) => {
        showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
        if (closeModal) handleClick()
    }

    const getCountryData = () => {
        ApiService.PostByPage('country/country', 1, 999999, []).then((res) => {
            if (res.data.code === 200) {
                setCountryList(res.data.data.country);
            } else {
                errorToast('country', true)
            }

            if (!selectedData?.id) {
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error)
            errorToast('country', true)
        })
    }

    const getProvinceData = (pageCode = 'PAGE', params = []) => {
        ApiService.DynamicPostByPage('province/province', pageCode, 1, 999999, params).then((res) => {
            if (res.data.code === 200) {
                setProvinceList(res.data.data.province);
            } else {
                errorToast('province', true)
            }

            setIsLoading(false)
        }).catch(function (error) {
            console.log(error)
            errorToast('province', true)
        })
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "city": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-DataCity", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        masterCode: Yup.string().nullable(),
        code: Yup.string().trim().required('This field is required.'),
        name: Yup.string().trim().required('This field is required.'),
        abbreviation: Yup.string().nullable(),
        countryId: Yup.number().required('This field is required.'),
        provinceId: Yup.number().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? {
            ...selectedData,
            provinceId: selectedData?.provinceId !== 0 ? selectedData.provinceId : '',
            countryId: selectedData?.countryId !== 0 ? selectedData.countryId : '',
        } : {
            masterCode: '',
            code: '',
            name: '',
            abbreviation: '',
            countryId: '',
            provinceId: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.code === '') {
            return showToast({ type: 'error', message: 'Code is Required!' });
        }

        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'City is Required!' });
        }

        if (payload?.countryId === '') {
            return showToast({ type: 'error', message: 'Country is Required!' });
        }

        if (payload?.abbreviation === '') {
            return showToast({ type: 'error', message: 'Abbreviation is Required!' });
        }

        const data = {
            "city": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload.code,
                "name": payload.name,
                "abbreviation": payload.abbreviation,
                "provinceId": payload.provinceId,
                "countryId": payload.countryId,
                "masterCode": payload.masterCode,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="masterCode"
                label="Master Code"
                value={formik.values.masterCode}
                onBlur={formik.handleBlur}
                error={formik.touched.masterCode && Boolean(formik.errors.masterCode)}
                helperText={formik.touched.masterCode && formik.errors.masterCode}
                onChange={(e) => formik.setFieldValue('masterCode', e.target.value)}
                disabled
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="code"
                label="Code"
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Selection
                id="country"
                name='countryId'
                fullWidth={true}
                labelId="lblCountry"
                placeholder={'Select Country'}
                value={formik.values.countryId}
                label="Country"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                onChange={(e) => {
                    formik.setFieldValue('countryId', e.target.value)
                    getProvinceData('SEARCH', [{ attr: 'countryId', value: String(e.target.value) }])
                }}
                error={formik.touched.countryId && Boolean(formik.errors.countryId)}
            >
                {
                    countryList.map((country) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={country.id} value={country.id}>{country.name}</MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.countryId && formik.errors.countryId}</FormHelperText>
            </Selection>

            <Selection
                labelId="lblProvince"
                id="province"
                name='province'
                label="Province"
                fullWidth={true}
                placeholder={'Select Province'}
                value={formik.values.provinceId || ''}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                onChange={(e) => formik.setFieldValue('provinceId', e.target.value)}
                error={formik.touched.provinceId && Boolean(formik.errors.provinceId)}
            >
                {
                    provinceList.map((province) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={province.id} key={province.id} value={province.id}>
                            {province.name}
                        </MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.provinceId && formik.errors.provinceId}</FormHelperText>
            </Selection>

            <Input
                name="city"
                label="City"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="abbreviation"
                label="Abbreviation"
                value={formik.values.abbreviation}
                onBlur={formik.handleBlur}
                error={formik.touched.abbreviation && Boolean(formik.errors.abbreviation)}
                helperText={formik.touched.abbreviation && formik.errors.abbreviation}
                onChange={(e) => formik.setFieldValue('abbreviation', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        isLoading={isLoading}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditCity