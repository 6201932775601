import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import TabCustomer from './TabCustomer';
import TabBillTo from './TabBillTo';

const TabsCustomerBillTo = (props) => {
    const handleChange = (event, newValue) => {
        props.setTabValue(newValue);
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <TabContext value={props.tabValue} className='p-0'>
                    <Tabs
                        sx={{ width: '100%', marginTop: '24px', height: 'fit-content', maxHeight: '32px !important' }}
                        variant="fullWidth"
                        value={props.tabValue}
                        onChange={handleChange}
                        className='p-0'
                        textColor="primary"
                        aria-label="full width tabs example"
                        TabIndicatorProps={{
                            sx: {
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                                backgroundColor: "#0087C2 !important",
                            },
                        }}
                        TabScrollButtonProps={{
                            sx: {
                                backgroundColor: "#0087C2 !important",
                                height: 'fit-content !important',
                                maxHeight: '32px !important',
                            },
                        }}
                    >
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="Customer"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "Customer" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Customer
                                </Typography>
                            }
                        />
                        <Tab
                            sx={{
                                padding: '4px 12px !important',
                                minHeight: '35px',
                                textTransform: "none",
                                backgroundColor: '#D7F0FF',
                                '&.Mui-selected': {
                                    color: '#f2f2f2',
                                    backgroundColor: '#0087C2',
                                },
                            }}
                            value="BillTo"
                            label={
                                <Typography
                                    sx={{
                                        color: props.tabValue === "BillTo" ? '#F2FAFF' : '#083A50',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                    }}
                                >
                                    Bill To
                                </Typography>
                            }
                        />
                    </Tabs>

                    {
                        props.tabValue === 'Customer' && (
                            <TabPanel value="Customer" className='p-0'>
                                <TabCustomer
                                    isEdit={props.isEdit}
                                    EPLId={props.EPLId}
                                    getDataDetail={props.getDataDetail}
                                    PaymentFrom={props.PaymentFrom}
                                    PaymentType={props.PaymentType}
                                    setIsLoadingBackDrop={props.setIsLoadingBackDrop}

                                    Customer={props.Customer}
                                    setTitleModal={props.setTitleModal}
                                    handleClick={props.handleClick}
                                    CustomerType={props.CustomerType}
                                    setCustomerType={props.setCustomerType}
                                    CustomerId={props.CustomerId}
                                    setCustomerId={props.setCustomerId}
                                    CustomerName={props.CustomerName}
                                    setCustomerName={props.setCustomerName}
                                    CustomerAddress={props.CustomerAddress}
                                    setCustomerAddress={props.setCustomerAddress}

                                    CustomerSCTempId={props.CustomerSCTempId}
                                    CustomerSCTempName={props.CustomerSCTempName}
                                    CustomerSCTempAddress={props.CustomerSCTempAddress}
                                    CustomerATempId={props.CustomerATempId}
                                    CustomerATempName={props.CustomerATempName}
                                    CustomerATempAddress={props.CustomerATempAddress}

                                    setBillToId={props.setBillToId}
                                    setBillToName={props.setBillToName}
                                    setBillToAddress={props.setBillToAddress}
                                />
                            </TabPanel>
                        )
                    }


                    {
                        props.tabValue === 'BillTo' && (
                            <TabPanel value="BillTo" className='p-0'>
                                <TabBillTo
                                    isEdit={props.isEdit}
                                    EPLId={props.EPLId}
                                    CustomerId={props.CustomerId}
                                    Customer={props.Customer}
                                    setTitleModal={props.setTitleModal}
                                    handleClick={props.handleClick}
                                    CustomerType={props.CustomerType}
                                    setCustomerType={props.setCustomerType}
                                    BillToId={props.BillToId}
                                    setBillToId={props.setBillToId}
                                    BillToName={props.BillToName}
                                    setBillToName={props.setBillToName}
                                    BillToAddress={props.BillToAddress}
                                    setBillToAddress={props.setBillToAddress}
                                    getDataDetail={props.getDataDetail}
                                    PaymentType={props.PaymentType}
                                />
                            </TabPanel>
                        )
                    }
                </TabContext>
            </Box>
        </>
    )
}

export default TabsCustomerBillTo;