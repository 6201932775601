import React from 'react';
import Grid from '@mui/material/Grid';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';

const ButtonListInvoiceDelete = ({
    ReloadData,
    DataRegUserAccess,
    View,
    Approve,
    Decline
}) => {
    const renderButton = (data) => {
        switch (data.accessCode) {
            case 'REI':
                return ButtonComponent.ViewButton(View, 'View Data');
            case 'APP':
                return (
                    <>
                        {ButtonComponent.ApproveButton(Approve, 'Approve Delete')}
                        {ButtonComponent.DeclineButton(Decline, 'Decline Delete')}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data)}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonListInvoiceDelete;