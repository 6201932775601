import { Box, Stack } from '@mui/material'
import React from 'react'

import { InputMask } from '../../../components/ui'
import { ContainerNumber, DollarMask, RupiahMask, TaxIdentificationNumber } from '../../../utils/masks.jsx'

const MasterInputMask = () => {
  return (
    <Stack direction={'column'} gap={2}>
      <Box>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
          {/* *** */}
          <InputMask
            mask={DollarMask()}
            inputProps={{
              label: 'Dollar Mask'
            }}
          />
          {/* *** */}
          <InputMask
            mask={RupiahMask()}
            inputProps={{
              label: 'Rupiah Mask'
            }}
          />
          {/* *** */}
          <InputMask
            mask={TaxIdentificationNumber}
            guide
            inputProps={{
              label: 'Tax Identification Number (NPWP) Mask'
            }}
          />
          {/* *** */}
          <InputMask
            mask={ContainerNumber}
            guide
            inputProps={{
              label: 'Container Number Mask'
            }}
          />
        </Stack>
      </Box>
    </Stack>
  )
}

export default MasterInputMask