import { TableCell, TableHead, TableRow, styled, tableCellClasses } from '@mui/material';
import React from 'react'

const THead = () => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f0f7ff',
      color: theme.palette.common.black,
    },
  }));

  return (
    <TableHead>
      <TableRow sx={{ '.MuiTableCell-root': { p: '6px 8px' } }}>
        <StyledTableCell>No</StyledTableCell>
        <StyledTableCell>Column</StyledTableCell>
        <StyledTableCell>Text</StyledTableCell>
        <StyledTableCell>Alias</StyledTableCell>
        <StyledTableCell>Order</StyledTableCell>
        <StyledTableCell>Data Type</StyledTableCell>
        <StyledTableCell>Length</StyledTableCell>
        <StyledTableCell>Format</StyledTableCell>
        <StyledTableCell>Text Align</StyledTableCell>
        <StyledTableCell align="center">Hidden</StyledTableCell>
        <StyledTableCell align="center">Create</StyledTableCell>
        <StyledTableCell align="center">Read</StyledTableCell>
        <StyledTableCell align="center">Update</StyledTableCell>
        <StyledTableCell align="center">Delete</StyledTableCell>
        <StyledTableCell>Valid On</StyledTableCell>
        <StyledTableCell>Valid To</StyledTableCell>
        <StyledTableCell align="right"></StyledTableCell>
      </TableRow>
    </TableHead>
  )
}

export default THead