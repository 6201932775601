import { Grid, Button, Typography } from '@mui/material';
import React from 'react';
import ApiService from '../../../../../services/ApiService';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput from '../../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../../components/Masks/RupiahInput';

import SelectDataBankAdvance from '../Selects/SelectDataBankAdvance';
import { Input, showToast } from '../../../../../components/ui';
import { convertToNominal, convertToNumeric } from '../../../../../utils/masks.jsx';

const BankAdvSettlementDetail = (props) => {
    const [titleModalBA, setTitleModalBA] = useState('');
    const [isModalBA, setIsModalBA] = useState(false);
    const paramBA = "bankAdvance/bankAdvance";
    const [isLoadingBA, setIsLoadingBA] = useState(false);
    const [dataBA, setDataBA] = useState([]);
    const [SelectedDataBA, setSelectedDataBA] = useState({});
    const [totalRowsBA, setTotalRowsBA] = useState(50);
    const [dataMapBA, setDataMapBA] = useState([]);
    const [columnDataBA, setColumnDataBA] = useState([]);

    const handleClickBalance = () => {
        if (isModalBA === false) {
            setIsModalBA(true);
            getDataBankAdvance(1, 50);
        }
        else {
            setIsModalBA(false);
        }
    };

    const searchBalance = (data) => {
        if (data === 'search') {
            if (props.EmployeeId !== 0) {
                setTitleModalBA('Balance');
                handleClickBalance();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data Personal First!' });
            }
        }
    };

    const getDataBankAdvance = (pageNumber, pageSize, filters) => {
        setIsLoadingBA(true);
        let param = [
            { 'attr': 'employeeId', 'value': '' + props.EmployeeId }
        ];
        ApiService.FinAccPostByPage(paramBA, 'SEARCH', pageNumber, pageSize, param, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataBA({});
                    setDataBA(response.data.data.bankAdvance);

                    let temp = response.data.data.bankAdvance
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapBA(indexed);
                    setColumnDataBA(response.data.data.columns.headerColumns);
                    setTotalRowsBA(response.data.totalRowCount);

                    setIsLoadingBA(false);
                }
                setIsLoadingBA(false);
            })
            .catch(function (error) {
                setIsLoadingBA(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    function handleTotalUSD(value)
    {
        let val = parseFloat(convertToNumeric(value));

        if(props.BankAdvanceId !== 0)
        {
            props.setTotalUSD(val);
            let countSettlementUSD = parseFloat(props.BalanceSettlementUSDReal) + parseFloat(val);
            props.setBalanceSettlementUSD(convertToNominal(countSettlementUSD));
            props.calculateBalance(props.TotalIDR, val, props.BalanceBAIDRReal, props.BalanceBAUSDReal, props.BalanceSettlementIDRReal, props.BalanceSettlementUSDReal);
        }
        else
        {
            showToast({ type: 'error', message: 'Please Input No More Than Balance Per Bank Advance USD!' });
        }
    }

    function handleTotalIDR(value)
    {
        let val = parseFloat(convertToNumeric(value));

        if(props.BankAdvanceId !== 0)
        {
            props.setTotalIDR(val); 
            let countSettlementIDR = parseFloat(props.BalanceSettlementIDRReal) + parseFloat(val);
            props.setBalanceSettlementIDR(convertToNominal(countSettlementIDR));
            props.calculateBalance(val, props.TotalUSD, props.BalanceBAIDRReal, props.BalanceBAUSDReal, props.BalanceSettlementIDRReal, props.BalanceSettlementUSDReal);
        }
        else
        {
            showToast({ type: 'error', message: 'Please Input No More Than Balance Per Bank Advance IDR!' });
        }
    }

    return (
        <div>
            <form>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <div>
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                            >
                                Settlement
                            </Typography>

                            <Grid
                                container
                                spacing={'16px'}
                            >
                                <Grid
                                    item
                                    xl={12}
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                >
                                    <Input
                                        label="Total USD"
                                        value={props.TotalUSD}
                                        onChange={(e) => handleTotalUSD(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                        }}
                                    />

                                    <Input
                                        label="Total IDR"
                                        value={props.TotalIDR}
                                        onChange={(e) => handleTotalIDR(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                        }}
                                    />

                                    <Button
                                        size='small'
                                        variant="outlined"
                                        className='d-flex align-items-center'
                                        // disabled = {props.printing > 0? true : false}
                                        sx={{
                                            padding: '6px 24px !important',
                                            gap: '8px',
                                            width: '100%',
                                            marginTop: '37px !important',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '8px',
                                            background: 'var(--Blue-Button-Primary-1, #2A8D36)',
                                            '&:hover': {
                                                backgroundColor: 'var(--Blue-Button-Primary-1, #2A8D36) !important',
                                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                            }
                                        }}
                                        onClick={() => searchBalance()}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '20px',
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                                color: '#E4E8EC',
                                            }}
                                        >
                                            BALANCE OF OVERALL BANK ADVANCE
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>

                    <Grid
                        item
                        xl={8}
                        lg={8}
                        sm={8}
                        xs={12}
                    >
                        <div>
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                            >
                                Balance
                            </Typography>

                            <Grid
                                container
                                spacing={'16px'}
                            >
                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <Input
                                        label="Balance Per Bank Advance IDR"
                                        value={props.BalanceBAIDR}
                                        onChange={(e) => props.setBalanceBAIDR(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Balance Settlement IDR"
                                        value={props.BalanceSettlementIDR}
                                        onChange={(e) => props.setBalanceSettlementIDR(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Balance IDR"
                                        value={props.BalanceIDR}
                                        onChange={(e) => props.setBalanceIDR(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xl={6}
                                    lg={6}
                                    sm={6}
                                    xs={12}
                                >
                                    <Input
                                        label="Balance Per Bank Advance USD"
                                        value={props.BalanceBAUSD}
                                        onChange={(e) => props.setBalanceBAUSD(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Balance Settlement USD"
                                        value={props.BalanceSettlementUSD}
                                        onChange={(e) => props.setBalanceSettlementUSD(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Balance USD"
                                        value={props.BalanceUSD}
                                        onChange={(e) => props.setBalanceUSD(e.target.value)}
                                        textAlign='right'
                                        fullWidth
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </form>
            
            {
                isModalBA && (
                    <SelectDataBankAdvance
                        isModal={isModalBA}
                        handleClick={handleClickBalance}
                        titleModal={titleModalBA}
                        setIsModal={setIsModalBA}
        
                        getData={getDataBankAdvance}
                        isLoading={isLoadingBA}
                        totalRows={totalRowsBA}
                        setTotalRows={setTotalRowsBA}
                        SelectedData={SelectedDataBA}
                        setSelectedData={setSelectedDataBA}
                        data={dataBA}
                        columnData={columnDataBA}
                        dataMap={dataMapBA}
        
                        setBalanceBAIDR={props.setBalanceBAIDR}
                        setBalanceBAUSD={props.setBalanceBAUSD}
                        TotalIDR={props.TotalIDR}
                        TotalUSD={props.TotalUSD}
                        calculateBalance={props.calculateBalance}
                    />
                )
            }
        </div>
    )
}

export default BankAdvSettlementDetail;