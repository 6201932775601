import { IconButton, Stack, Grid } from '@mui/material';
import { Icon, Input } from '../../../../components/ui';
import React from 'react';

const TabCustomer = (props) => {
    const searchCustomer = () => {
        props.setCustomerType(1);
        props.setTitleModal(props.Customer);
        props.handleClick();
    };

    const resetCustomer = () => {
        props.setIsLoadingBackDrop(true);
        if (props.Customer !== 'Agent') {
            props.setCustomerId(props.CustomerSCTempId);
            props.setCustomerName(props.CustomerSCTempName);
            props.setCustomerAddress(props.CustomerSCTempAddress);

            props.getDataDetail(props.EPLId, props.CustomerSCTempId);
        }
        else {
            props.setCustomerId(props.CustomerATempId);
            props.setCustomerName(props.CustomerATempName);
            props.setCustomerAddress(props.CustomerATempAddress);

            props.getDataDetail(props.EPLId, props.CustomerATempId);
        }

        props.setBillToId(0);
        props.setBillToName('');
        props.setBillToAddress('');
    }

    return (
        <>
            <Grid
                container
                spacing={'16px'}
            >
                <Grid
                    item
                    xl={2}
                    lg={2}
                    sm={2}
                    xs={12}
                    sx={{ marginTop: '-12px !important' }}
                >
                    <Input
                        label={props.Customer + ' Code'}
                        value={props.CustomerId}
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '26px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                    sx={{ marginTop: '-12px !important' }}
                >
                    <Input
                        label={props.Customer + ' Name'}
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.CustomerName}
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '26px'
                            }
                        }}
                        disabled
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    sx={{ marginTop: '-12px !important' }}
                >
                    <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                        <Input
                            label={props.Customer + ' Address'}
                            value={props.CustomerAddress}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '26px !important'
                                }
                            }}
                            inputBaseProps={{
                                rows: 5,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                            disabled
                        />

                        <Stack
                            direction={'column'}
                            spacing={'32px'}
                            sx={{ marginTop: '54px !important' }}
                            hidden={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                        >
                            <IconButton
                                onClick={searchCustomer}
                                hidden={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '16px',
                                            width: '16px'
                                        }
                                    }}
                                />
                            </IconButton>

                            {
                                props.EPLId !== 0 && props.PaymentFrom === 0 ?
                                    <IconButton
                                        className='mt-3'
                                        onClick={resetCustomer}
                                        hidden={props.isEdit}
                                    >
                                        <Icon
                                            iconName={'convert'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                    :
                                    <IconButton
                                        className='mt-3'
                                        onClick={resetCustomer}
                                        hidden
                                    >
                                        <Icon
                                            iconName={'convert'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                            }
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default TabCustomer;