import { FormHelperText, MenuItem } from '@mui/material';
import React, { useImperativeHandle, forwardRef } from 'react';
import { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditReportConfig = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "reportConfig": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DataReportConfig", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        code: Yup.string().trim().required('This field is required.'),
        folderCode: Yup.string().trim().required('This field is required.'),
        fileCode: Yup.string().trim().required('This field is required.'),
        generateType: Yup.string().trim().required('This field is required.'),
        url: Yup.string().trim().required('This field is required.'),
        fileExtension: Yup.string().trim().required('This field is required.'),
        description: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            code: '',
            folderCode: '',
            fileCode: '',
            generateType: '',
            url: '',
            fileExtension: '',
            description: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.code === '') {
            return showToast({ type: 'error', message: 'Code is Required' });
        }

        if (payload?.folderCode === '') {
            return showToast({ type: 'error', message: 'Folder Code is Required' });
        }

        if (payload?.fileCode === '') {
            return showToast({ type: 'error', message: 'File Code is Required' });
        }

        if (payload?.generateType === '') {
            return showToast({ type: 'error', message: 'Generate Type is Required' });
        }

        if (payload?.url === '') {
            return showToast({ type: 'error', message: 'Url is Required' });
        }

        if (payload?.fileExtension === '') {
            return showToast({ type: 'error', message: 'File Extension is Required' });
        }

        const data =
        {
            "reportConfig": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload?.code,
                "folderCode": payload?.folderCode,
                "fileCode": payload?.fileCode,
                "generateType": payload?.generateType,
                "url": payload?.url,
                "fileExtension": payload?.fileExtension,
                "description": payload?.description,
                "userCode": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="code"
                label="Code"
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="folderCode"
                label="Folder Code"
                value={formik.values.folderCode}
                onBlur={formik.handleBlur}
                error={formik.touched.folderCode && Boolean(formik.errors.folderCode)}
                helperText={formik.touched.folderCode && formik.errors.folderCode}
                onChange={(e) => formik.setFieldValue('folderCode', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="fileCode"
                label="File Code"
                value={formik.values.fileCode}
                onBlur={formik.handleBlur}
                error={formik.touched.fileCode && Boolean(formik.errors.fileCode)}
                helperText={formik.touched.fileCode && formik.errors.fileCode}
                onChange={(e) => formik.setFieldValue('fileCode', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Selection
                id="generateType"
                name='generateType'
                label="Generate Type"
                labelId="label-generateType"
                value={formik.values.generateType}
                placeholder={'Select Generate Type'}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                onChange={(e) => formik.setFieldValue('generateType', e.target.value)}
                error={formik.touched.generateType && Boolean(formik.errors.generateType)}
            >
                <MenuItem sx={{ fontSize: '12px' }} key={0} id={0} value='Fixed'>Fixed</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={1} id={1} value='Dynamic'>Dynamic</MenuItem>
                <FormHelperText>{formik.touched.generateType && formik.errors.generateType}</FormHelperText>
            </Selection>

            <Input
                name="url"
                label="Url"
                value={formik.values.url}
                onBlur={formik.handleBlur}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
                onChange={(e) => formik.setFieldValue('url', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="fileExtension"
                label="File Extension"
                value={formik.values.fileExtension}
                onBlur={formik.handleBlur}
                error={formik.touched.fileExtension && Boolean(formik.errors.fileExtension)}
                helperText={formik.touched.fileExtension && formik.errors.fileExtension}
                onChange={(e) => formik.setFieldValue('fileExtension', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="description"
                label="Description"
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                onChange={(e) => formik.setFieldValue('description', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditReportConfig