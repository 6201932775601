import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/material";

import ButtonService from "./ButtonService";
import GeneralButton from "./GeneralButton";
import ApiService from "../../services/ApiService";
import TableX from "../../components/Tables/TableX";
import freezeContainer from "../../Styles/freezeContainer";
import ModalDelete from "../../components/Modals/ModalDelete";
import ButtonComponent from "../../components/Buttons/ButtonComponent";
import LoadingSpinner from "../../components/Components/LoadingSpinner";
import HeaderInfo from "../../components/Components/HeaderInfo";
import { Paginate, showToast } from "../../components/ui";

const TempListMaster2 = (props) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const paramRegUserAccess = "regUserAccess/regUserAccess";
  const history = useNavigate();
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [titleModalDelete, setTitleModalDelete] = useState("");

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: props.title, link: '/Majura/' + props.title.replace(/ /g, ''), style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = props.title + ' - ' + JSON.parse(localStorage.getItem('branchName'))
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === props.menuCode)
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        {
          attr: "userId",
          value: "" + JSON.parse(localStorage.getItem("userId"))
        },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];
      ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            props.getData(1, 50);
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Majura');
        });
    }
    else {
      history('/Majura');
    }
  };

  const ReloadData = () => {
    setGoTo(1);
    props.setStateX({});
    props.setFilter([]);
    props.setFilterBy([]);
    props.getData(1, 50);
  };

  const handleClickDelete = () => {
    if (isModalDelete === false) {
      setIsModalDelete(true);
    } else {
      setIsModalDelete(false);
    }
  };

  const DeleteData = () => {
    if (props.SelectedData.id === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      if (props.SelectedData.approved) {
        showToast({ type: 'error', message: 'Can not delete data, already approved!' })
      } else {
        setTitleModalDelete(props.title);
        handleClickDelete();
      }
    }
  };

  const Delete = () => {
    if (props.SelectedData.contactId === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      ApiService.deleteData(props.param, props.SelectedData.contactId)
        .then(() => {
          let msg = "Delete Data " + props.title + ' Success';
          showToast({ type: 'success', message: msg })
          window.location.reload();
        })
        .catch(function (error) {
          console.error("error saat create", error);
        });
    }
  };

  const PrintData = () => {
    var content = document.getElementById("tabel");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };
  const ExportPDF = () => {
    ButtonService.ExportPDF(props.data, props.title);
  };

  const ExportExcel = () => {
    ButtonService.ExportExcel(props.data, props.title);
  };

  const handleReset = () => {
    setGoTo(1);
    props.setStateX({});
    props.setFilter([]);
    props.setFilterBy([]);
    props.getData(1, 50);
  };

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const ButtonComponents = () => {
    return (
      <GeneralButton
        ReloadData={ReloadData}
        DataRegUserAccess={DataRegUserAccess}
        toggleAdd={props.toggleAdd}
        toggleEdit={props.toggleEdit}
        DeleteData={DeleteData}
        PrintData={PrintData}
        ExportPDF={ExportPDF}
        ExportExcel={ExportExcel}
      />
    )
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    props.getData(value, rowsCount, props.filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    props.getData(1, value, props.filter);
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          btnPageRef={btnPageRef}
          title={props.title}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
          <Grid container spacing={'24px'} direction="row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <div ref={btnTableRef}>
                {ButtonComponent.RefreshButton(
                  props.sortArr,
                  rowsCount,
                  props.columnData,
                  setGoTo,
                  handleReset,
                  props.setFilterBy,
                  props.filterBy,
                  props.setFilter,
                  setNumPage,
                  setRowsCount,
                  props.getData,
                  props.stateX,
                  props.setStateX
                )}
              </div>

              <div
                className="rounded-10 table-responsive"
                style={{
                  maxHeight: `calc(100vh - ${getContainerHeight()})`,
                  marginTop: '16px',
                  padding: '2px 16px',
                  borderRadius: '12px',
                  background: '#F2F2F2'
                }}
              >
                {props.isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <TableX
                      uniqueKey={props.menuCode === 'MRCON' ? 'contactId' : 'id'}
                      goTo={goTo}
                      rowsCount={rowsCount}
                      setNumPage={setNumPage}
                      setRowsCount={setRowsCount}
                      getData={props.getData}
                      columnData={props.columnData}
                      dataMap={props.dataMap}
                      SelectedData={props.SelectedData}
                      setSelectedData={props.setSelectedData}
                      setFilter={props.setFilter}
                      setFilterBy={props.setFilterBy}
                      CellDoubleClick={props.CellDoubleClick}
                      stateX={props.stateX}
                      setStateX={props.setStateX}
                      setSortArr={props.setSortArr}
                      setGoTo={setGoTo}
                    />
                  </>
                )}
              </div>

              <div style={{ marginTop: '16px' }}>
                <Paginate
                  page={numPage}
                  limit={rowsCount}
                  totalData={props.totalRows}
                  onChange={onChangePaging}
                  onChangeLimit={onChangeLimit}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {
        isModalDelete && (
          <ModalDelete
            isModal={isModalDelete}
            titleModal={titleModalDelete}
            handleClick={handleClickDelete}
            DeleteData={Delete}
          />
        )
      }
    </Box>
  );
};

export default TempListMaster2;
