import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import BalanceList from './BalanceList';
import Backdrop from '@mui/material/Backdrop';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';

const BalancePage = forwardRef((props, ref) => {
    const history = useNavigate();
    // const countryId = JSON.parse(localStorage.getItem("countryId"));
    // const companyId = JSON.parse(localStorage.getItem("companyId"));
    // const branchId = JSON.parse(localStorage.getItem("branchId"));
    // const groupId = JSON.parse(localStorage.getItem('groupId'));
    // const groupId = 1;
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    
    let countRefresh = 0;

    useImperativeHandle(ref, () => ({
        localSave() {
            
        }
    }));

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            getDataAccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FCLB')
        if (indexMenu !== -1) {
            hasAccess = true
        }

        if(hasAccess) {
            setIsLoadingBackDrop(false);
        } else {
            history('/Majura');
        }
    }

    return (
        <>
            <div>
                <Grid item xs={12} style={{ width: "100%" }}>
                    {/* {
                        countryId !== 0 && groupId !== 0 && companyId !== 0 && branchId !== 0?
                            <BalanceList roleName = {'branch'} groupId = {groupId} companyId = {companyId} branchId = {branchId} />
                        :
                            countryId !== 0 && groupId !== 0 && companyId !== 0 && branchId === 0?
                                <BalanceList roleName = {'company'} groupId = {groupId} companyId = {companyId} branchId = {0} />
                            :
                                countryId !== 0 && groupId !== 0 && companyId === 0 && branchId === 0?
                                    <BalanceList roleName = {'group'} groupId = {groupId} companyId = {0} branchId = {0} />
                                : */}
                                    <BalanceList roleName = {'holding'} groupId = {0} companyId = {0} branchId = {0} />
                    {/* } */}
                </Grid>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingBackDrop}
            >
                <LoadingSpinner />
            </Backdrop>
        </>
    )
})

export default BalancePage;