import { Backdrop, Button, Card, CardHeader, Checkbox, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import ApiService from '../../../services/ApiService';
import { useDebounce } from '../../../utils/useDebounce';
import { Icon, Input, PopUp, Selection, showToast } from '../../../components/ui';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const CreateEditRegUserEntity = forwardRef(({ isModal, closeModal }, ref) => {
  useImperativeHandle(ref, () => ({
    localSave() { }
  }));

  const roleId = JSON.parse(localStorage.getItem("roleId"))
  const groupId = JSON.parse(localStorage.getItem("groupId"))
  const companyId = JSON.parse(localStorage.getItem("companyId"))
  const branchId = JSON.parse(localStorage.getItem("branchId"))

  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500);

  const [selectedCompany, setSelectedCompany] = useState()
  const [selectedBranch, setSelectedBranch] = useState()

  const [listCompany, setListCompany] = useState([])
  const [listBranch, setListBranch] = useState([])

  const [checked, setChecked] = useState([]);

  const [leftSide, setLeftSide] = useState([])
  const [rightSide, setRightSide] = useState([])
  const [leftListEmployee, setLeftListEmployee] = useState([])
  const [rightListEmployee, setRightListEmployee] = useState([])

  const leftChecked = intersection(checked, leftSide);
  const rightChecked = intersection(checked, rightSide);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const arrRight = rightSide.concat(leftChecked)
    setRightSide(arrRight);
    setLeftSide(not(leftSide, leftChecked));
    setChecked(not(checked, leftChecked));

    // *** Ambil data checked employee
    const rightEmployee = leftListEmployee.filter((x) => arrRight.includes(x.id))
    setRightListEmployee(rightEmployee)
  };

  const handleCheckedLeft = () => {
    const arrRight = not(rightSide, rightChecked)
    setRightSide(arrRight);
    setLeftSide(leftSide.concat(rightChecked));
    setChecked(not(checked, rightChecked));

    // *** Ambil data checked employee
    const rightEmployee = leftListEmployee.filter((x) => arrRight.includes(x.id))
    setRightListEmployee(rightEmployee)
  };

  useEffect(() => {
    // *** Get company data if user login has access to select company
    if (hasAccess('company')) {
      getCompanyData()
    } else if (roleId !== 1 && hasAccess('branch')) {
      // *** Get branch data if user login has access to select branch
      // *** NB: Super Administrator must select company first
      getBranchData(companyId)
    } else if (roleId === 6) {
      // *** Administrator Branch automatically get employee based on user companyId and branchId
      getEmployeeData(companyId, branchId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCompanyData = async () => {
    setIsLoading(true)

    try {
      const res = await ApiService.DynamicPostByPage('company/company', 'PAGE', 1, 999, [])
      if (res.data.code === 200) {
        const tempData = res.data.data.company
        const mapData = tempData.map((dt) => {
          return { id: dt.id, name: `${dt.name}` }
        })

        setListCompany(mapData)
      } else {
        showToast({ type: 'error', message: 'Can`t get company data.' })
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Can`t get company data.' })
    }

    setIsLoading(false)
  }

  const getBranchData = async (id) => {
    setIsLoading(true)

    try {
      const params = [
        { attr: 'companyId', value: String(id) }
      ]
      const res = await ApiService.DynamicPostByPage('branch/branch', 'PAGE', 1, 999, params)
      if (res.data.code === 200) {
        const tempData = res.data.data.branch
        const mapData = tempData.map((dt) => {
          return { id: dt.id, name: `${dt.name}` }
        })

        setListBranch(mapData)
      } else {
        showToast({ type: 'error', message: 'Can`t get company data.' })
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Can`t get company data.' })
    }

    setIsLoading(false)
  }

  const getEmployeeData = async (company, branch) => {
    setIsLoading(true)

    try {
      const params = [
        { attr: 'rowStatus', value: 'ACT' },
        { attr: 'companyId', value: String(company) },
        { attr: 'branchId', value: String(branch) },
        { attr: 'userId', value: '0' },
        { attr: 'isRegUserEntity', value: '1' },
      ]
      const filters = []
      if (debouncedSearch) {
        filters.push({ field: 'name', value: debouncedSearch })
      }

      const res = await ApiService.DynamicPostByPage('employee/employee', 'PAGE', 1, 999, params, filters)
      const tempData = res.data.data.employees
      const mapData = tempData.map((dt) => {
        return dt.id
      })

      setLeftSide(mapData)
      setLeftListEmployee(tempData)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  const getEmployeeName = (id, side) => {
    let employeeName = '-'

    if (side === 'left') {
      const employee = leftListEmployee.find((e) => e.id === id)
      employeeName = employee ? `${employee?.nik || 0} - ${employee.fullName}` : '-'
    } else {
      const employee = rightListEmployee.find((e) => e.id === id)
      employeeName = employee ? `${employee?.nik || 0} - ${employee.fullName}` : '-'
    }

    return employeeName
  }

  const isHiddenItem = (id) => {
    if (!debouncedSearch?.trim()) return false
    let isHidden = false

    const employee = leftListEmployee.find((elm) => elm.id === id)
    if (employee.name.toUpperCase().indexOf(debouncedSearch.toUpperCase()) === -1) {
      isHidden = true
    }

    return isHidden
  }

  const handleSave = async () => {
    if (!rightSide.length) {
      return showToast({ type: 'error', message: 'Select at least one employee.' })
    }

    setIsSubmitting(true)

    try {
      const payload = []
      rightListEmployee.forEach((elm) => {
        payload.push({
          rowStatus: 'ACT',
          groupId,
          countryId: elm?.countryId,
          companyId: elm?.companyId,
          branchId: elm?.branchId,
          employeeId: elm?.id,
          employeeName: elm?.name,
          employeeEmail: elm?.email
        })
      })

      const res = await ApiService.IdentityCreateData('regUserEntity/regUserEntity', { userEntities: payload })
      if (res?.data?.code === 200) {
        showToast({ type: 'success', message: 'All employee have been successfully saved.' })
        getEmployeeData((selectedCompany || companyId), (selectedBranch || branchId))

        setRightListEmployee([])
        setRightSide([])
      } else {
        showToast({ type: 'error', message: 'Can`t save employee, please try again.' })
      }

    } catch (error) {
      showToast({ type: 'error', message: 'Can`t save employee, please try again.' })
      console.log(error)
    }

    setIsSubmitting(false)
  }

  const hasAccess = (accessType) => {
    let isPermitted = false

    if (roleId === 1) {
      isPermitted = true
    } else {
      if (accessType === 'branch' && roleId === 2) {
        isPermitted = true
      }
    }

    return isPermitted
  }

  const resetList = () => {
    setLeftSide([])
    setRightSide([])
    setLeftListEmployee([])
    setRightListEmployee([])
  }

  const renderList = (title, items, side) => (
    <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFFFFF', padding: '0px 24px 24px' }}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            size='small'
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          height: 350,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
              hidden={side === 'left' ? isHiddenItem(value) : false}
            >
              <ListItemIcon>
                <Checkbox
                  size='small'
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={getEmployeeName(value, side)} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  const mainContainer = (
    <>
      <Grid container spacing={'16px'}>
        <Grid item xs={5.5}>
          <Stack direction={'row'} spacing={'16px'}>
            {hasAccess('company') && (
              <Selection
                id='0'
                label={'Company'}
                fullWidth={true}
                placeholder={'Select Company'}
                value={selectedCompany}
                disabled={isLoading}
                onChange={(e) => {
                  setSelectedCompany(e.target.value)
                  getBranchData(e.target.value)
                  setSelectedBranch(undefined)
                  resetList()
                }}
              >
                {
                  listCompany.map((company) => (
                    <MenuItem sx={{ fontSize: '12px' }} key={company.id} value={company.id}>{company.name}</MenuItem>
                  ))
                }
              </Selection>
            )}

            {hasAccess('branch') && (
              <Selection
                id='0'
                label={'Branch'}
                fullWidth={true}
                placeholder={'Select Branch'}
                value={selectedBranch}
                disabled={listBranch.length === 0 ? true : isLoading}
                onChange={(e) => {
                  resetList()
                  setSelectedBranch(e.target.value)
                  getEmployeeData(selectedCompany, e.target.value)
                }}
              >
                {
                  listBranch.map((branch) => (
                    <MenuItem sx={{ fontSize: '12px' }} key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                  ))
                }
              </Selection>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={5.5}>
          <Input
            label={'Search Employee'}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            disabled={!leftListEmployee.length}
            formControlProps={{
              sx: {
                width: '100%',
                marginTop: '16px',
                marginBottom: '12px',
              }
            }}
          />
        </Grid>

        <Grid item xs={6.5} />

        <Grid item xs={5.5}>{renderList('Employee', leftSide, 'left')}</Grid>

        <Grid item xs={1}>
          <Stack direction={'column'} spacing={'16px'}>
            <Button
              size='small'
              variant="outlined"
              className='d-flex align-items-center'
              sx={{
                padding: '3px 6px',
                gap: '4px',
                marginInlineStart: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '18px !important',
                minHeight: '28px !important',
                borderRadius: '8px',
                '&:hover': {
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                }
              }}
              disabled={leftChecked.length === 0}
              onClick={handleCheckedRight}
            >
              <Icon
                svgIconProps={{
                  sx: {
                    height: '12px',
                    width: '12px',
                    transform: 'rotateY(180deg)'
                  }
                }}
                iconName={'arrow-left'}
              />
            </Button>

            <Button
              size='small'
              variant="outlined"
              className='d-flex align-items-center'
              sx={{
                padding: '3px 6px',
                gap: '4px',
                marginInlineStart: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '18px !important',
                minHeight: '28px !important',
                borderRadius: '8px',
                '&:hover': {
                  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                }
              }}
              disabled={rightChecked.length === 0}
              onClick={handleCheckedLeft}
            >
              <Icon
                svgIconProps={{
                  sx: {
                    height: '12px',
                    width: '12px',
                  }
                }}
                iconName={'arrow-left'}
              />
            </Button>
          </Stack>
        </Grid>

        <Grid item xs={5.5}>{renderList('Selected Employee', rightSide, 'right')}</Grid>
      </Grid>
    </>
  )

  return (
    <>
      <PopUp
        id='modal-id'
        icon={
          <Icon
            iconName={'approve-doc'}
            svgIconProps={{
              htmlColor: '#0087C2',
              sx: {
                height: '24px',
                width: '24px'
              }
            }}
          />
        }
        title={'Add User Entity'}
        isOpen={isModal}
        onClose={closeModal}
        draggable={true}
        shrinkable={true}
        disableScrollLock={true}
        maxWidth={'lg'}
        fullWidth={true}
        topButton={false}
      >
        <div style={{ padding: '12px 6px 0px' }}>
          {mainContainer}

          <div style={{ marginTop: '48px' }}>
            {CustomizeButtonComponent.SaveCancelMasterModal(handleSave, false)}
          </div>
        </div>
      </PopUp>
      
      {
        isSubmitting && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner color="inherit" />
          </Backdrop>
        )
      }
    </>
  )
})

export default CreateEditRegUserEntity