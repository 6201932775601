import React, { useImperativeHandle, forwardRef } from 'react';
import { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, showToast, TickBox } from '../../../components/ui';
import { Typography } from '@mui/material';

const CreateEditMemorialType = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "memorialType": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DataMemorialType", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        code: Yup.string().trim().required('This field is required.'),
        name: Yup.string().trim().required('This field is required.'),
        isCost: Yup.boolean(),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            code: '',
            name: '',
            isCost: true,
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Name is Required!' });
        }

        if (payload?.code === '') {
            return showToast({ type: 'error', message: 'Code is Required!' });
        }

        const data =
        {
            "memorialType": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload?.code,
                "name": payload?.name,
                "isCost": payload?.isCost,
                "userCode": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    function handleChange(name, value) {
        formik.setFieldValue(name, !value);
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="code"
                label="Code"
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="name"
                label="Name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <div style={{ marginTop: '16px' }}>
                <TickBox
                    size={'small'}
                    label={
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '12px !important',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '20px',
                            }}
                        >
                            Cost
                        </Typography>
                    }
                    checked={formik.values.isCost}
                    onChange={() => handleChange('isCost', formik.values.isCost)}
                />
            </div>
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditMemorialType