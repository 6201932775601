import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Grid, Box, MenuItem, Backdrop, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ApiService from '../../../services/ApiService';

import TableX from '../../../components/Tables/TableX';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import ButtonRegUserAccessList from './Buttons/ButtonRegUserAccessList';
import CreateEditRegUserAccess from './CreateEditRegUserAccess';

import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Paginate, Selection, showToast } from '../../../components/ui';

const RegUserAccessList = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const roleId = JSON.parse(localStorage.getItem("roleId"))
  const companyId = JSON.parse(localStorage.getItem("companyId"))
  const branchId = JSON.parse(localStorage.getItem("branchId"))

  const [selectedCompany, setSelectedCompany] = useState()
  const [selectedBranch, setSelectedBranch] = useState()

  const [listCompany, setListCompany] = useState([])
  const [listBranch, setListBranch] = useState([])

  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);

  const [isModal, setIsModal] = useState(false);

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  let countRefresh = 0;

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Reg User Access', link: '/Majura/RegUserAccess', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() { }
  }));

  useEffect(() => {
    if (countRefresh === 0) {
      document.title = 'Reg User Access - ' + JSON.parse(localStorage.getItem('branchName'))
      ApiService.refreshToken();
      countRefresh++;

      getDataAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'IRUACC')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            if (hasAccessFilter('company')) {
              setIsLoading(false)
              setIsLoadingBackDrop(false)
              getCompanyData()
            } else if (roleId !== 1 && hasAccessFilter('branch')) {
              setIsLoading(false)
              setIsLoadingBackDrop(false)
              getBranchData(companyId)
            } if (roleId === 6) {
              getData()
            }
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Majura');
        });
    } else {
      history('/Majura');
    }
  };

  const getData = async (pageNumber = 1, pageSize = 5, filters = []) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }

    setGoTo(pageNumber)
    setNumPage(pageNumber)

    try {
      const params = []
      if (roleId === 1) {
        if (!selectedBranch || !selectedCompany) {
          showToast({ type: 'error', message: 'Please select company & branch first' })
          setIsLoading(false)

          return
        }
        params.push({ attr: 'companyId', value: String(selectedCompany) })
        params.push({ attr: 'branchId', value: String(selectedBranch) })
      } else if (roleId === 2) {
        if (!selectedBranch) {
          showToast({ type: 'error', message: 'Please select branch first' })
          setIsLoading(false)

          return
        }
        params.push({ attr: 'companyId', value: String(selectedCompany) })
      } else {
        params.push({ attr: 'companyId', value: String(companyId) })
        params.push({ attr: 'branchId', value: String(branchId) })
      }

      const res = await ApiService.IdentityPostByPage('regUserEntity/regUserEntity', 'PAGE', pageNumber, pageSize, params, filters)
      if (res.data.code === 200) {
        setSelectedData({})

        let tempData = res.data.data.userEntity
        let indexedData = tempData.map((el, index) => {
          return { ...el, index }
        })

        setDataMap(indexedData)
        setColumnData(res.data.data.columns)
        setTotalRows(res.data.totalRowCount)
      } else {
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + res.data.message })
        emptyTable()
      }

      setIsLoading(false)
      setIsLoadingBackDrop(false)

    } catch (error) {
      console.log(error)
      setIsLoadingBackDrop(false)
      showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
    }
  }

  const getCompanyData = async () => {
    setIsFetching(true)

    try {
      const res = await ApiService.DynamicPostByPage('company/company', 'PAGE', 1, 999, [])
      if (res.data.code === 200) {
        const tempData = res.data.data.company
        const mapData = tempData.map((dt) => {
          return { id: dt.id, name: `${dt.name}` }
        })

        setListCompany(mapData)
      } else {
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + res.data.message })
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
    }

    setIsFetching(false)
  }

  const getBranchData = async (id) => {
    setIsFetching(true)

    try {
      const params = [
        { attr: 'companyId', value: String(id) }
      ]
      const res = await ApiService.DynamicPostByPage('branch/branch', 'PAGE', 1, 999, params)
      if (res.data.code === 200) {
        const tempData = res.data.data.branch
        const mapData = tempData.map((dt) => {
          return { id: dt.id, name: `${dt.name}` }
        })

        setListBranch(mapData)
      } else {
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + res.data.message })
      }
    } catch (error) {
      showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
    }

    setIsFetching(false)
  }

  const hasAccessFilter = (accessType) => {
    let isPermitted = false

    if (roleId === 1) {
      isPermitted = true
    } else {
      if (accessType === 'branch' && roleId === 2) {
        isPermitted = true
      }
    }

    return isPermitted
  }

  const emptyTable = () => {
    setDataMap([])
    setColumnData([])
    setTotalRows(50)
  }

  const handleReset = () => {
    emptyState();
    getData();
  };

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setGoTo(1);
    setSortArr([]);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setStateX({});
  };

  const reloadData = () => {
    showToast({ type: 'info', message: 'Reloading Data' })
    getData(numPage, rowsCount)
  };

  const toggleModal = () => {
    if (!isModal) {
      if (!selectedData?.id) {
        return showToast({ type: 'error', message: 'Please select user first.' })
      }
    }
    setIsModal(!isModal)
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter);
  }

  const ButtonComponents = () => {
    return (
      <>
        <ButtonRegUserAccessList
          handleEdit={toggleModal}
          reloadData={reloadData}
          dataRegUserAccess={dataRegUserAccess}
        />

        <Grid
          container
          columnSpacing={{ xs: 0, md: 3 }}
          rowSpacing={{ xs: 2, md: 0 }}
          className='mt-2'
        >
          {
            hasAccessFilter('company') && (
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
              >
                <Selection
                  id='0'
                  label={'Company'}
                  fullWidth={true}
                  placeholder={'Select Company'}
                  value={selectedCompany}
                  onChange={(e) => {
                    setSelectedCompany(e.target.value)
                    getBranchData(e.target.value)
                    setSelectedBranch(undefined)
                  }}
                  disabled={isLoading || isFetching}
                >
                  {listCompany.map((company) => (
                    <MenuItem sx={{ fontSize: '12px' }} key={company.id} value={company.id}>{company.name}</MenuItem>
                  ))}
                </Selection>
              </Grid>
            )
          }

          {
            hasAccessFilter('branch') && (
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
              >
                <Selection
                  id='0'
                  label={'Branch'}
                  fullWidth={true}
                  placeholder={'Select Branch'}
                  value={selectedBranch}
                  onChange={(e) => {
                    setSelectedBranch(e.target.value)
                  }}
                  disabled={listBranch.length === 0 ? true : isLoading || isFetching}
                >
                  {listBranch.map((branch) => (
                    <MenuItem sx={{ fontSize: '12px' }} key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                  ))}
                </Selection>
              </Grid>
            )
          }

          {
            roleId !== 6 && (
              <Grid
                item
                lg={2}
                md={2}
                sm={12}
                xs={12}
              >
                <Button
                  size='small'
                  variant="outlined"
                  className='d-flex align-items-center'
                  sx={{
                    marginTop: '23px !important',
                    padding: '4px 12px !important',
                    gap: '8px',
                    marginInlineStart: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '32px !important',
                    minHeight: '28px !important',
                    borderRadius: '8px',
                    background: 'var(--Blue-Button-Primary-1, #B3E3FF)',
                    '&:hover': {
                      backgroundColor: 'var(--Blue-Button-Primary-1, #B3E3FF) !important',
                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                    }
                  }}
                  onClick={() => getData(1, rowsCount)}
                >
                  <Icon
                    svgIconProps={{
                      htmlColor: '#0087C2',
                      sx: {
                        height: '14px',
                        width: '14px',
                      }
                    }}
                    iconName={'search'}
                  />

                  <Typography
                    sx={{
                      fontFamily: 'Nunito',
                      fontSize: '12px',
                      fontWeight: '600',
                      lineHeight: '20px',
                      letterSpacing: '0em',
                      textAlign: 'center',
                      color: '#0087C2',
                    }}
                  >
                    Search
                  </Typography>
                </Button>
              </Grid>
            )
          }
        </Grid>
      </>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3, ...freezeContainer }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo btnPageRef={btnPageRef}
            title={'Reg User Access'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
            <div ref={btnTableRef}>
              {ButtonComponent.RefreshButton(sortArr, rowsCount, columnData, setGoTo, handleReset, setFilterBy,
                filterBy, setFilter, setNumPage, setRowsCount, getData, stateX, setStateX)}
            </div>

            <div
              className="rounded-10 table-responsive"
              style={{
                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                marginTop: '16px',
                padding: '2px 16px',
                borderRadius: '12px',
                background: '#F2F2F2'
              }}
            >
              {
                isLoading ?
                  <LoadingSpinner />
                  :
                  <>
                    <div className='mt-1'>
                      <TableX
                        uniqueKey={'id'}
                        goTo={goTo}
                        rowsCount={rowsCount}
                        setNumPage={setNumPage}
                        setRowsCount={setRowsCount}
                        getData={getData}
                        columnData={columnData}
                        dataMap={dataMap}
                        SelectedData={selectedData}
                        setSelectedData={setSelectedData}
                        setFilter={setFilter}
                        setFilterBy={setFilterBy}
                        CellDoubleClick={toggleModal}
                        stateX={stateX}
                        setStateX={setStateX}
                        sortArr={sortArr}
                        setSortArr={setSortArr}
                        setGoTo={setGoTo}
                        preventKeyCapture={isModal}
                      />
                    </div>
                  </>
              }
            </div>

            <div style={{ marginTop: '16px' }}>
              <Paginate
                page={numPage}
                limit={rowsCount}
                totalData={totalRows}
                onChange={onChangePaging}
                onChangeLimit={onChangeLimit}
              />
            </div>
          </Grid>
        </Grid>

        {isModal && (
          <CreateEditRegUserAccess
            ref={ref}
            isModal={isModal}
            handleClick={toggleModal}
            selectedData={selectedData}
            fetchData={getData} numPage={numPage} rowsCount={rowsCount}
          />
        )}

        {
          isLoadingBackDrop && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoadingBackDrop}
            >
              <LoadingSpinner />
            </Backdrop>
          )
        }
      </Box>
    </>
  )
})

export default RegUserAccessList
