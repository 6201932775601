import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Grid, Box, MenuItem, Card, CardContent, Stack, InputAdornment, IconButton } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonOutstandingTemporaryReceiptView from './Buttons/ButtonOutstandingTemporaryReceiptView';
import SelectDataContact from './Selects/SelectDataContact';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';

const OutstandingTemporaryReceiptView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [dateNow, setDateNow] = useState('');
    const [isCompany, setIsCompany] = useState('C');
    const CustomCompanyData = [
        { id: 1, code: 'C', name: 'Per Company' },
        { id: 2, code: 'R', name: 'Per Regional' },
    ];
    const [isDP, setIsDP] = useState(false);
    const DPData = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ];
    const [isAll, setIsAll] = useState(true);
    const CustomSalesData = [
        { id: 1, code: true, name: 'All Customer' },
        { id: 2, code: false, name: 'Custom Customer' },
    ];
    const [contactTypeId, setContactTypeId] = useState(2);
    const ContactTypeData = [
        { id: 1, code: 2, name: 'Shipper' },
        { id: 2, code: 3, name: 'Consignee' },
    ];
    const [contactId, setContactId] = useState(0);
    const [contactCode, setContactCode] = useState('');
    const [contactName, setContactName] = useState('');

    const paramC = 'regContact/regContact';
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [isModalC, setIsModalC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [titleModalC, setTitleModalC] = useState('');
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [dataMapC, setDataMapC] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Outstanding Temporary Receipt', link: '/Majura/OutstandingTemporaryReceiptReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Outstanding Temporary Receipt - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getDateNow();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCOSC() {
        if (titleConfirmation === 'Print') {
            PrintPDF();
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintExcel();
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (isAll === false && contactCode === '') {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Customer!' })
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Outstanding Temporary Receipt');
            handleClickConfirmation();
        }
    };

    const PrintDataExcel = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Outstanding Temporary Receipt');
            handleClickConfirmation();
        }
    }

    function PrintPDF() {
        setIsLoadingBackDrop(true);
        let dp = 0;
        if (isDP === true) {
            dp = 1;
        }
        let params = [
            {
                'attr': 'ContactId',
                'value': '' + contactId
            },
            {
                'attr': 'date',
                'value': '' + dateNow
            },
            {
                'attr': 'IsDP',
                'value': '' + dp
            }
        ];
        let variable = "PrintOTR?typeReport=pdf";
        ApiService.ReportPrint("Reports/Finance/Print/ControlReport/OutstandingTRReport/", variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Outstanding Temporary Receipt Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Outstanding Temporary Receipt, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Outstanding Temporary Receipt, ' + error });
            });
    }

    function PrintExcel() {
        setIsLoadingBackDrop(true);
        let dp = 0;
        if (isDP === true) {
            dp = 1;
        }
        let params = [
            {
                'attr': 'ContactId',
                'value': '' + contactId
            },
            {
                'attr': 'date',
                'value': '' + dateNow
            },
            {
                'attr': 'IsDP',
                'value': '' + dp
            }
        ];
        let variable = "PrintOTR?typeReport=xls";
        ApiService.ReportPrint("Reports/Finance/Print/ControlReport/OutstandingTRReport/", variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/vnd.ms-excel";

                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Outstanding Temporary Receipt Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Outstanding Temporary Receipt, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Outstanding Temporary Receipt, ' + error });
            });
    }

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDateNow);
    }

    function handleIsAll(value) {
        setIsAll(value);
        EmptyContact();
    }

    function handleContactType(value) {
        setContactTypeId(value);
        EmptyContact();
    }

    function EmptyContact() {
        setContactId(0);
        setContactCode('');
        setContactName('');
    }

    const handleClickContact = () => {
        setTitleModalC('Contact');
        if (isModalC === false) {
            getDataContact(1, 50);
            setIsModalC(true);
        }
        else {
            setIsModalC(false);
        }
    }

    const getDataContact = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingC(true);
        ApiService.ContactPostByPage(paramC, contactTypeId, pageNumber, pageSize, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.contact);
                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)
                    setIsLoadingC(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingC(false)
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonOutstandingTemporaryReceiptView
                PrintData={PrintData}
                PrintExcel={PrintDataExcel}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Outstanding Temporary Receipt'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={isCompany}
                                                    label={'Custom Company'}
                                                    placeholder={'Select Custom Company'}
                                                    onChange={(e) => setIsCompany(e.target.value)}
                                                >
                                                    {
                                                        CustomCompanyData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={isDP}
                                                    fullWidth={true}
                                                    label={'Down Payment'}
                                                    placeholder={'Select Down Payment'}
                                                    onChange={(e) => setIsDP(e.target.value)}
                                                >
                                                    {
                                                        DPData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>

                                        <Input
                                            type='date'
                                            value={dateNow}
                                            label="As Per Date"
                                            onChange={(e) => setDateNow(e.target.value)}
                                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={isAll}
                                                    fullWidth={true}
                                                    label={'Custom Customer'}
                                                    placeholder={'Select Custom Customer'}
                                                    onChange={(e) => handleIsAll(e.target.value)}
                                                >
                                                    {
                                                        CustomSalesData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    disabled={isAll}
                                                    value={contactTypeId}
                                                    label={'Customer Type'}
                                                    placeholder={'Select Customer Type'}
                                                    onChange={(e) => handleContactType(e.target.value)}
                                                >
                                                    {
                                                        ContactTypeData.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                            <Input
                                                readOnly
                                                disabled
                                                value={contactCode}
                                                label="Customer Code"
                                            />

                                            <Input
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                disabled
                                                value={contactName}
                                                label="Customer Name"
                                                type='text'
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyContact()}
                                                                hidden={contactName ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => handleClickContact()}
                                                                hidden={isAll}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalC && (
                    <SelectDataContact
                        isModal={isModalC}
                        titleModal={titleModalC}
                        handleClick={handleClickContact}
                        isLoading={isLoadingC}
                        data={dataC}
                        getData={getDataContact}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        totalRows={totalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}

                        setContactId={setContactId}
                        setContactCode={setContactCode}
                        setContactName={setContactName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCOSC}
                    />
                )
            }
        </Box>
    )
})

export default OutstandingTemporaryReceiptView