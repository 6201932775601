import React, { forwardRef, useImperativeHandle } from 'react';
import { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, showToast } from '../../../components/ui';

const CreateEditVat = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "vat": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-Datavat", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required('This field is required.'),
        value: Yup.number().min(1, 'Must be greater than 0.').required('This field is required.'),
        seriFakturPajak: Yup.string().trim().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            name: '',
            value: '',
            seriFakturPajak: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Name is Required!' });
        }

        if (payload?.value === '') {
            return showToast({ type: 'error', message: 'Value is Required!' });
        }

        if (payload?.seriFakturPajak === '') {
            return showToast({ type: 'error', message: 'Seri Faktur Pajak is Required!' });
        }

        const data =
        {
            "vat": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "value": payload?.value,
                "name": payload?.name,
                "seriFakturPajak": payload?.seriFakturPajak,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="name"
                label="Vat Name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="value"
                label="Value"
                value={formik.values.value}
                onBlur={formik.handleBlur}
                error={formik.touched.value && Boolean(formik.errors.value)}
                helperText={formik.touched.value && formik.errors.value}
                onChange={(e) => formik.setFieldValue('value', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }
                }}
            />

            <Input
                name="seriFakturPajak"
                label="Seri Faktur Pajak"
                value={formik.values.seriFakturPajak}
                onBlur={formik.handleBlur}
                error={formik.touched.seriFakturPajak && Boolean(formik.errors.seriFakturPajak)}
                helperText={formik.touched.seriFakturPajak && formik.errors.seriFakturPajak}
                onChange={(e) => formik.setFieldValue('seriFakturPajak', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})


export default CreateEditVat