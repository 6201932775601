import { IconButton, Stack, InputAdornment, Typography } from '@mui/material'
import React from 'react'
import { Icon, Input } from '../../../../components/ui'

const TabBookingParty = (props) => {
    const cariBookingParty = (data) => {
        if (data === 'search') {
            props.setTipe(11);
            props.setTitleModal('Booking Party');
            localStorage.setItem("ContactType", 11);
            props.handleClick();
        }
        else if (data === 'delete') {
            props.setIdBookingParty(0);
            props.setBookingPartyName('');
            props.setBookingPartyAddress('');
        }
    }
    return (
        <>
            <Typography
                sx={{
                    color: 'var(--Blue-Primary-1, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                }}
            >
                Booking Party
            </Typography>

            <Stack spacing={'16px'} direction="row" className='mt-2'>
                <Input
                    label={'Code'}
                    value={props.idBookingParty}
                    formControlProps={{
                        sx: {
                            marginTop: '24px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                    }}
                    disabled
                />

                <Input
                    label="Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={props.BookingPartyName}
                    type='text'
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '24px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => cariBookingParty('delete')}
                                    disabled={props.isApproved}
                                    hidden={props.BookingPartyName && !props.isApproved ? false : true}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => cariBookingParty('search')}
                                    disabled={props.isApproved}
                                    hidden={props.isApproved}
                                >
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled
                />
            </Stack>

            <Input
                label={'Address'}
                value={props.BookingPartyAddress}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '24px'
                    }
                }}
                inputBaseProps={{
                    readOnly: true,
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                disabled
            />
        </>
    )
}

export default TabBookingParty