import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
// import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

import TempListMaster from '../../TempListMaster';
import ApiService, { userCode, username } from '../../../../services/ApiService';
import CreateEditSitesWarehouses from './CreateEditSitesWarehouses';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../../components/ui';

const RegSitesWarehousesList = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Reg Sites Warehouses'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    // const history = useNavigate()
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "warehouse/RegSitesWarehouses/RegSitesWarehouses";
    const paramWarehouses = "warehouse/warehouses/warehouses";
    const paramSites = "warehouse/sites/sites";
    const [id, setId] = useState(0);
    const [warehouseId, setWarehouseId] = useState('');
    const [warehouseName, setWarehouseName] = useState('');
    const [siteId, setSiteId] = useState('');
    const [siteName, setSiteName] = useState('');
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    let countErr = 0

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataSitesWarehouses"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "sitesWarehouses": {
                    "rowStatus": "ACT",
                    "id": id,
                    "warehouseId": warehouseId,
                    "warehouseName": warehouseName,
                    "siteId": siteId,
                    "siteName": siteName,
                    "userCode": userCode,
                    "titleModal": titleModal
                }
            }
            localStorage.setItem(username + "-DataSitesWarehouses", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.sitesWarehouses;
        setIsModal(true);
        setWarehouseId(result.warehouseId);
        setWarehouseName(result.warehouseName);
        setSiteId(result.siteId);
        setSiteName(result.siteName);
        setId(result.id);
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-DataSitesWarehouses')
    }

    const toggleAdd = () => {
        setId(0);
        setWarehouseId('');
        setWarehouseName('');
        setSiteId('');
        setSiteName('');
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            setId(SelectedData.id);
            setWarehouseId(SelectedData.warehousesId);
            setSiteId(SelectedData.sitesId);
            setIsModal(true);
            setTitleModal('Edit');
            ApiService.PostById(paramWarehouses, SelectedData.warehousesId)
                .then((res) => {
                    setWarehouseName(res.data.data.warehouses.name);
                })
            ApiService.PostById(paramSites, SelectedData.sitesId)
                .then((res) => {
                    setSiteName(res.data.data.sites.name);
                })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        ApiService.ListMasterDynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.sitesWarehouses);

                    let temp = response.data.data.sitesWarehouses
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    // ApiService.logOut();
                    // history('/')
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                countErr++
                if (countErr < 3) {
                    getData(1, 50)
                } else {
                    // ApiService.logOut()
                    // history('/')
                }
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
            setId(0);
            setWarehouseId('');
            setSiteId('');
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MWHRSW'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditSitesWarehouses
                        isModal={isModal}
                        titleModal={titleModal}
                        handleClick={handleClick}
                        title={title}
                        param={param}

                        id={id}
                        warehouseId={warehouseId}
                        setWarehouseId={setWarehouseId}
                        siteId={siteId}
                        setSiteId={setSiteId}
                        warehouseName={warehouseName}
                        setWarehouseName={setWarehouseName}
                        siteName={siteName}
                        setSiteName={setSiteName}

                        getData={getData}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default RegSitesWarehousesList;