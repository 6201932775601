import { BrowserRouter, Route, Routes } from "react-router-dom";
// import MiniDrawer from "../components/SideBar";
import SideNavbar from "../components/Layouts/Sidebar/SideNavbar";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Rollbar
// import { Provider, ErrorBoundary } from "@rollbar/react";

import '../Styles/index.css';
import Login from "../pages/Account/Login";
import MaintenancePage from "../pages/Home/Maintenance/MaintenancePage";
import TrackingTicket from "../pages/Home/TrackingTicket/TrackingTicket";
import NotFoundPage from "../pages/Home/NotFound/NotFoundPage";

import ThemeComponent from "../theme";

// import { NODE_ENV, ROLLBAR_TOKEN } from "../helpers/constant";

// const rollbarConfig = {
//   accessToken: NODE_ENV === 'PROD' ? ROLLBAR_TOKEN : '',
//   environment: NODE_ENV,
// }

function App() {
  const routeServer = '/';
  const isMaintenance = false;

  return (
    <div>
      {/* <Provider config={rollbarConfig}>
        <ErrorBoundary> */}
        <ThemeComponent>
          <BrowserRouter>
            <Routes>
              {
                isMaintenance ?
                  <>
                    <Route path="*" element={<><MaintenancePage ref={undefined} isOutside={true} /></>} />
                  </>
                  :
                  <>
                    <Route exact path={routeServer} element={<Login />} />
                    <Route path={routeServer + "/Majura/*"} element={<><SideNavbar /></>} />
                    <Route path="/Majura/Tracking/:id" element={<><TrackingTicket /></>} />
                    <Route path="*" element={<><NotFoundPage ref={undefined} isOutside={true} /></>} />
                  </>
              }
            </Routes>
          </BrowserRouter>
        </ThemeComponent>
        {/* </ErrorBoundary>
      </Provider> */}
    </div>
  );
}

export default App;
