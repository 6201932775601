import React, { useState } from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material'

import { Switcher } from '../../../components/ui'

const MasterSwitcher = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const handleChange = (e) => {
    setIsChecked(e.target.checked)
  }

  return (
    <Stack direction={'column'} gap={2}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={isDisabled} onChange={() => setIsDisabled(!isDisabled)} />
          }
          label="Disabled"
        />
      </FormGroup>

      <Box>
        <Typography fontWeight={700}>Standalone Switch (use MUI Switch)</Typography>
        <Switch
          disabled={isDisabled}
          checked={isChecked}
          onChange={handleChange}
        />
      </Box>

      <Box>
        <Typography fontWeight={700}>Labeled Switcher (use custom Switcher)</Typography>
        <Switcher
          disabled={isDisabled}
          onChange={handleChange}
          checked={isChecked}
          label={'Switcher label'}
        />
      </Box>
    </Stack>
  )
}

export default MasterSwitcher