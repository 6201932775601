import {
    TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, TableFooter,
    Grid, Typography,
    Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

import ModalPaymentRequest from '../Modals/ModalPaymentRequest';
import { Input, showToast } from '../../../../components/ui';
import { convertToNominal } from '../../../../utils/masks.jsx';
import { getDefaultDateFormat, setDateFormat } from '../../../../utils/date.jsx';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent.jsx';

const InvoiceBeingForPayment = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.footer}`]: {
            background: '#E4E8EC',
            color: '#083A50 !important',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
            left: 0,
            bottom: 0,
            zIndex: 2,
            position: 'sticky',
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [AccountId, setAccountId] = useState(0);
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [COACode, setCOACode] = useState('');
    const [COAName, setCOAName] = useState('');
    const [isContainer, setIsContainer] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [PaidDate, setPaidDate] = useState('');
    const [ContainerType, setContainerType] = useState('All');
    const [Description, setDescription] = useState('');
    const [AmountCrr, setAmountCrr] = useState(1);
    const [CostTrucking, setCostTrucking] = useState(false);
    const [CostToCost, setCostToCost] = useState(false);
    const [Quantity, setQuantity] = useState('');
    const [UnitCost, setUnitCost] = useState('');
    const [Amount, setAmount] = useState('');
    const [isPPN, setIsPPN] = useState(false);
    const [PercentVAT, setPercentVAT] = useState(0);
    const [OriginalRate, setOriginalRate] = useState('');
    const [OriginalUSD, setOriginalUSD] = useState('');
    const [DifferentCost, setDifferentCost] = useState(0);
    const [FakturNo, setFakturNo] = useState('');
    const [FakturDate, setFakturDate] = useState('');
    const [ContainerFormat, setContainerFormat] = useState('All');
    const [containerTypeId, setContainerTypeId] = useState(0);
    const [containerTypeCode, setContainerTypeCode] = useState('');
    const [containerTypeName, setContainerTypeName] = useState('');
    const [isOthers, setIsOthers] = useState(false);
    const [eplDetailId, setEPLDetailId] = useState(0);

    function getDateNow() {
        var tanggal = getDefaultDateFormat(new Date());
        setPaidDate(tanggal);
        setFakturDate(tanggal);
    };

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddPaymentFor = () => {
        if (props.isContra !== 'false') {
            if (props.CustomerId !== 0) {
                setDetailEdit(false);
                setTitleModal('Payment Request On Shipment ' + props.jobType.name);
                if (props.PaymentRequestDetail.length > 0) {
                    let data = props.PaymentRequestDetail.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                    setDetailSequence(data.sequence);
                }
                else {
                    setDetailSequence(0);
                }
                setCostToCost(props.isCostToCost);
                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Customer First!' });
            }
        }
        else {
            showToast({ type: 'error', message: 'Data Has Been Printed!' });
        }
    };

    const ShowEditPaymentFor = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setDetailEdit(true);
                setTitleModal('Payment Request On Shipment ' + props.jobType.name);

                setAccountCode(selectedDetail.accountCode);
                setAccountName(selectedDetail.accountName);
                setCOACode(selectedDetail.coaCode);
                setCOAName(selectedDetail.coaName);
                setIsPaid(selectedDetail.paid);
                setContainerType(selectedDetail.containerSize);
                setContainerFormat(selectedDetail.formatContainer);
                setContainerTypeId(selectedDetail.containerTypeId);
                setContainerTypeCode(selectedDetail.containerTypeCode);
                setContainerTypeName(selectedDetail.containerTypeName);
                setIsContainer(selectedDetail.isContainer);
                setDescription(selectedDetail.description);
                setAmountCrr(selectedDetail.amountCrr);
                setCostTrucking(selectedDetail.isCostTrucking);
                setCostToCost(selectedDetail.isCostToCost);
                setQuantity(selectedDetail.quantity);
                setUnitCost(selectedDetail.perQty);
                setAmount(selectedDetail.amount);
                setIsPPN(selectedDetail.isPpn);
                setPercentVAT(selectedDetail.persenPpn);
                setOriginalRate(selectedDetail.originalRate);
                setOriginalUSD(selectedDetail.originalUsd);
                setFakturNo(selectedDetail.fakturNo);
                setEPLDetailId(selectedDetail.eplDetailId);

                setDateFormat(selectedDetail.fakturDate, setFakturDate);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    };

    const DeletePaymentFor = () => {
        if (props.isContra !== 'false') {
            if (!selectedDetail.sequence) {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
            else {
                let tempSequence = selectedDetail.sequence;

                const deleteFunction = (data) => {
                    return data.sequence !== tempSequence;
                }

                const result = props.PaymentRequestDetail.filter(deleteFunction);

                if (result.length > 0) {
                    tempSequence = 0;

                    result.forEach((el) => {
                        tempSequence += 1;
                        el.sequence = tempSequence;
                    })

                    setDetailSequence(tempSequence);
                }
                else {
                    setDetailSequence(0);
                }

                props.setPaymentRequestDetail(result);
                props.calculateTotal(result);

                setSelectedDetail({});
            }
        }
        else {
            showToast({ type: 'error', message: 'Data Has Been Printed!' });
        }
    }

    const AddTrucking = () => {

    }

    function handleSelectedDetail(val) {
        setSelectedDetail(val);
    }

    return (
        <div>
            <form>
                <Box>
                    <Box component='div'>
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                            }}
                        >
                            Being for Payment
                        </Typography>

                        <TableContainer
                            sx={{
                                maxHeight: 350, marginTop: '16px', background: '#FFF', borderRadius: '12px'
                            }}
                        >
                            <Table
                                sx={{
                                    minWidth: '650',
                                }}
                                stickyHeader
                            >
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Code</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Paid</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Description</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>CTC</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>PPN</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Faktur No.</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount IDR</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.PaymentRequestDetail.length > 0 ?
                                            props.PaymentRequestDetail.map((el) => {
                                                return (
                                                    <TableRow
                                                        key={el.sequence}
                                                        onClick={() => handleSelectedDetail(el)}
                                                        onDoubleClick={() => ShowEditPaymentFor()}
                                                        sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                        hover={true}
                                                    >
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>
                                                            <Tooltip key={el.sequence} title={el.accountCode + ' - ' + el.accountName}>
                                                                <span>{el.accountCode}</span>
                                                            </Tooltip>
                                                        </StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.paid === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.isCostToCost === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{el.persenPpn}%</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.fakturNo}</StyledTableBodyCell>
                                                        {
                                                            el.amountCrr === 0 ?
                                                                <>
                                                                    <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amount)}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(0)}</StyledTableBodyCell>
                                                                </>
                                                                :
                                                                <>
                                                                    <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(0)}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amount)}</StyledTableBodyCell>
                                                                </>
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal'
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>

                                <TableFooter
                                    sx={{
                                        left: 0,
                                        bottom: 0,
                                        zIndex: 2,
                                        position: 'sticky',
                                        background: '#E4E8EC',
                                        '& td:first-of-type': {
                                            borderRadius: '0 0 0 12px'
                                        },
                                        '& td:last-child': {
                                            borderRadius: '0 0 12px 0'
                                        }
                                    }}
                                    hidden={props.PaymentRequestDetail.length > 0 ? false : true}
                                >
                                    <TableRow>
                                        <StyledTableFooterCell
                                            colSpan={6}
                                            sx={{
                                                fontWeight: 'bold',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'center',
                                                background: '#f5f5f5',
                                            }}
                                        >
                                            Total Amount
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalPaymentUSD)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell
                                            sx={{
                                                background: '#f5f5f5',
                                                position: 'sticky',
                                                fontSize: 14,
                                                color: '#000',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {convertToNominal(props.TotalPaymentIDR)}
                                        </StyledTableFooterCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>

                        <div style={{ marginTop: '16px' }}>
                            <Grid container item direction="row">
                                <Grid item xs={10} display={'flex'} sx={{ gap: '10px' }}>
                                    {
                                        !props.isEdit ?
                                            <div>
                                                {ButtonComponent.AddButton(ShowAddPaymentFor, '')}
                                            </div>
                                            :
                                            <div>
                                                {ButtonComponent.AddButton(ShowAddPaymentFor, '', (props.isViewOnly || props.documentStatus === 'PREVIEW'))}
                                            </div>
                                    }
                                    {ButtonComponent.EditButton(ShowEditPaymentFor, '', (!props.isAcc && props.isViewOnly && props.documentStatus === 'PREVIEW'))}
                                    {ButtonComponent.DeleteButton(DeletePaymentFor, '', (props.isViewOnly || props.documentStatus === 'PREVIEW'))}
                                </Grid>

                                <Grid item xs={2} alignItems='right' className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ gap: '10px' }}>
                                    {ButtonComponent.AddButton(AddTrucking, 'Trucking', (props.isViewOnly || props.documentStatus === 'PREVIEW'), true)}
                                </Grid>
                            </Grid>
                        </div>
                    </Box>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={3}
                            lg={3}
                            sm={3}
                            xs={12}
                        >
                            <Input
                                label={'Total USD'}
                                value={props.paidUSD ? 'Paid' : 'Not Paid'}
                                onChange={(e) => props.setPaidUSD(e.target.value)}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '24px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={3}
                            lg={3}
                            sm={3}
                            xs={12}
                        >
                            <Input
                                label={'Total IDR'}
                                value={props.paidIDR ? 'Paid' : 'Not Paid'}
                                onChange={(e) => props.setPaidIDR(e.target.value)}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '24px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={3}
                            lg={3}
                            sm={3}
                            xs={12}
                        >
                            <Input
                                textAlign='right'
                                label={'PPN USD'}
                                value={props.TotalPPNUSD}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '24px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={3}
                            lg={3}
                            sm={3}
                            xs={12}
                        >

                            <Input
                                textAlign='right'
                                label={'PPN IDR'}
                                value={props.TotalPPNIDR}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '24px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {
                isModal && (
                    <ModalPaymentRequest
                        isModal={isModal}
                        setIsModal={setIsModal}
                        handleClick={handleClick}
                        prId={props.prId}
                        setSelectedDetail={setSelectedDetail}
                        selectVATData={props.selectVATData}
                        VATData={props.VATData}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        getDateNow={getDateNow}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        calculateTotal={props.calculateTotal}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        isContra={props.isContra === 'false' ? true : props.isContra === 'true' ? false : false}
                        PaymentRequestDetail={props.PaymentRequestDetail}
                        setPaymentRequestDetail={props.setPaymentRequestDetail}
                        containerSizeData={props.containerSizeData}

                        AccountId={AccountId}
                        setAccountId={setAccountId}
                        AccountCode={AccountCode}
                        setAccountCode={setAccountCode}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                        COACode={COACode}
                        setCOACode={setCOACode}
                        COAName={COAName}
                        setCOAName={setCOAName}
                        isContainer={isContainer}
                        setIsContainer={setIsContainer}
                        isPaid={isPaid}
                        setIsPaid={setIsPaid}
                        PaidDate={PaidDate}
                        ContainerType={ContainerType}
                        setContainerType={setContainerType}
                        Description={Description}
                        setDescription={setDescription}
                        AmountCrr={AmountCrr}
                        setAmountCrr={setAmountCrr}
                        CostTrucking={CostTrucking}
                        setCostTrucking={setCostTrucking}
                        CostToCost={CostToCost}
                        setCostToCost={setCostToCost}
                        Quantity={Quantity}
                        setQuantity={setQuantity}
                        UnitCost={UnitCost}
                        setUnitCost={setUnitCost}
                        Amount={Amount}
                        setAmount={setAmount}
                        isPPN={isPPN}
                        setIsPPN={setIsPPN}
                        PercentVAT={PercentVAT}
                        setPercentVAT={setPercentVAT}
                        OriginalRate={OriginalRate}
                        setOriginalRate={setOriginalRate}
                        OriginalUSD={OriginalUSD}
                        setOriginalUSD={setOriginalUSD}
                        DifferentCost={DifferentCost}
                        setDifferentCost={setDifferentCost}
                        FakturNo={FakturNo}
                        setFakturNo={setFakturNo}
                        isViewOnly={(props.isViewOnly || props.documentStatus === 'PREVIEW')}
                        FakturDate={FakturDate}
                        setFakturDate={setFakturDate}
                        containerTypeId={containerTypeId}
                        setContainerTypeId={setContainerTypeId}
                        containerTypeCode={containerTypeCode}
                        setContainerTypeCode={setContainerTypeCode}
                        containerTypeName={containerTypeName}
                        setContainerTypeName={setContainerTypeName}
                        ContainerFormat={ContainerFormat}
                        setContainerFormat={setContainerFormat}
                        isOthers={isOthers}
                        setIsOthers={setIsOthers}
                        isCostToCost={props.isCostToCost}
                        serviceType={props.serviceType}
                        portType={props.portType}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}
                        isAcc={props.isAcc}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                        isGeneralPR={props.isGeneralPR}
                        EPLId={props.EPLId}
                        CustomerId={props.CustomerId}
                        setEPLDetailId={setEPLDetailId}
                        eplDetailId={eplDetailId}
                    />
                )
            }
        </div>
    )
}

export default InvoiceBeingForPayment;