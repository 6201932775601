import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';

import Grid from '@mui/material/Grid';

const ButtonRestoreClosingMonthly = ({ Process }) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    <div style={{ marginLeft: '-12px' }}>
                        {ButtonComponent.ProcessButton(Process)}
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default ButtonRestoreClosingMonthly