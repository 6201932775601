import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Typography
} from '@mui/material'

import { PopUp, Input } from '../../../components/ui'

import { ApproveDocIcon } from '../../../Assets/icons'

const MasterModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDraggable, setIsDraggable] = useState(false)
  const [isShrinkable, setIsShrinkable] = useState(false)
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [isScrollable, setIsScrollable] = useState(false)
  const [maxWidth, setMaxWidth] = useState(false)

  const [value, setValue] = useState('')

  const toggleSize = (size = false) => {
    if (!isFullWidth) {
      setIsFullWidth(true)
    }
    setMaxWidth(size)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setMaxWidth(false)
    setIsModalOpen(false)
  }

  return (
    <Stack direction={'column'} gap={2}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={isFullWidth} onChange={() => setIsFullWidth(!isFullWidth)} />
          }
          label="Full Width"
        />
        <FormControlLabel
          control={
            <Checkbox checked={isDraggable} onChange={() => setIsDraggable(!isDraggable)} />
          }
          label="Draggable"
        />
        <FormControlLabel
          control={
            <Checkbox checked={isShrinkable} onChange={() => setIsShrinkable(!isShrinkable)} />
          }
          label="Shrinkable"
        />
        <FormControlLabel
          control={
            <Checkbox checked={isScrollable} onChange={() => setIsScrollable(!isScrollable)} />
          }
          label="Enable Scroll"
        />
      </FormGroup>
      <Box>
        <Typography fontWeight={700}>DEFAULT</Typography>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2} mt={2}>
          <Button variant='outlined' size='small' color='primary' onClick={() => setIsModalOpen(true)}>Open Modal</Button>
        </Stack>
      </Box>
      <Box>
        <Typography fontWeight={700}>SIZE</Typography>
        <FormHelperText sx={{ m: 0, lineHeight: 1 }}>Use with fullWidth props to see the difference.</FormHelperText>
        <FormHelperText sx={{ m: 0, lineHeight: 1 }}>PS: you can set the maxWidth according to your needs.</FormHelperText>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2} mt={2}>
          <Button variant='outlined' size='small' color='primary' onClick={() => toggleSize('xs')}>XS</Button>
          <Button variant='outlined' size='small' color='primary' onClick={() => toggleSize('sm')}>SM</Button>
          <Button variant='outlined' size='small' color='primary' onClick={() => toggleSize('md')}>MD</Button>
          <Button variant='outlined' size='small' color='primary' onClick={() => toggleSize('lg')}>LG</Button>
          <Button variant='outlined' size='small' color='primary' onClick={() => toggleSize('xl')}>XL</Button>
          <Button variant='outlined' size='small' color='primary' onClick={() => toggleSize(false)}>False</Button>
        </Stack>
      </Box>
      <Box>
        {isModalOpen && (
          <PopUp
            id='modal-id'
            icon={<ApproveDocIcon />}
            title={'Reusable Component Modal'}
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            draggable={isDraggable}
            shrinkable={isShrinkable}
            disableScrollLock={isScrollable}
            maxWidth={maxWidth}
            fullWidth={isFullWidth}
          >
            <Typography fontWeight={700} mb={2}>This is popup content</Typography>
            <Input
              id={'input-modal'}
              formControlProps={{
                fullWidth: true
              }}
              label={'Form Input'}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </PopUp>
        )}
      </Box>
    </Stack>
  )
}

export default MasterModal