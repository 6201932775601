import { Grid, Box } from '@mui/material';
import React, { useState } from 'react';

import TableX from '../../../../../components/Tables/TableX';
import TableCheck from '../../../../../components/Tables/TableCheck';
import TableXPreview from '../../../../../components/Tables/TableXPreview';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';

const SelectDataPaymentVoucher = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [dataCheck, setDataCheck] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [isLoading2, setIsLoading2] = useState(false);
    const [rowsCount2, setRowsCount2] = useState(50)
    const [numPage2, setNumPage2] = useState(1)
    const [dataMap2, setDataMap2] = useState([])
    const [columnData2, setColumnData2] = useState([])
    const [totalRows2, setTotalRows2] = useState(50);
    const [filter2, setFilter2] = useState([]);
    const [filterBy2, setFilterBy2] = useState([]);
    const [stateX2, setStateX2] = useState({});
    const [sortArr2, setSortArr2] = useState([]);
    const [goTo2, setGoTo2] = useState(1);

    const emptyState = () => {
        props.setSelectedData({});
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setDataCheck([]);

        setNumPage2(1);
        setRowsCount2(50);
        setColumnData2([]);
        setTotalRows2(50);
        setFilter2([]);
        setFilterBy2([]);
        setDataMap2([]);
        setStateX({});
        setStateX2({});
    };

    const getData2 = (pageNumber, pageSize, filters) => {
        setIsLoading2(true);
        let params = [];
        let arrayData = [];

        if (props.SettlementTo === 'I') {
            params = [
                ...params,
                {
                    'attr': 'invoiceId',
                    'value': '' + localStorage.getItem('dataId'),
                },
            ];
            ApiService.OperationPostByDetail(props.param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        arrayData = response.data.data.invoiceDetail;

                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMap2(indexed)

                        setColumnData2(response.data.data.columns.detailColumns)
                        setTotalRows2(response.data.totalRowCount);

                        setIsLoading2(false)
                    }
                    setIsLoading2(false)
                })
                .catch(function (error) {
                    setIsLoading2(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.SettlementTo === 'P') {
            params = [
                ...params,
                {
                    'attr': 'prId',
                    'value': '' + localStorage.getItem('dataId'),
                },
                {
                    'attr': 'pvId',
                    'value': '0',
                },
                {
                    'attr': 'amountCrr',
                    'value': props.PaymentBy === 'BI' || props.PaymentBy === 'CI' ? '1' : '0',
                }
            ];
            ApiService.OperationPostByDetail(props.param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        const filterValues = props.DataPaymentDetail.map(item => item['refDetailId']);
                        arrayData = response.data.data.paymentRequestDetail;
                        arrayData = arrayData.filter(item => !filterValues.includes(item['id']));

                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMap2(indexed)

                        setColumnData2(response.data.data.columns.detailColumns)
                        setTotalRows2(response.data.totalRowCount);

                        setIsLoading2(false)
                    }
                    setIsLoading2(false)
                })
                .catch(function (error) {
                    setIsLoading2(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.SettlementTo === 'T') {
            params = [
                ...params,
                {
                    'attr': 'trId',
                    'value': '' + localStorage.getItem('dataId'),
                },
                {
                    'attr': 'pvId',
                    'value': '0',
                },
            ];
            ApiService.FinAccPostByDetail(props.param, pageNumber, pageSize, params, filters, 'SEARCH')
                .then((response) => {
                    if (response.data.code === 200) {
                        const filterValues = props.DataPaymentDetail.map(item => item['refDetailId']);
                        arrayData = response.data.data.temporaryReceiptJob;
                        arrayData = arrayData.filter(item => !filterValues.includes(item['id']));

                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMap2(indexed)

                        setColumnData2(response.data.data.columns.jobColumns)
                        setTotalRows2(response.data.totalRowCount);

                        setIsLoading2(false)
                    }
                    setIsLoading2(false)
                })
                .catch(function (error) {
                    setIsLoading2(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.SettlementTo === 'R') {
            params = [
                ...params,
                {
                    'attr': 'rvId',
                    'value': '' + localStorage.getItem('dataId'),
                },
                {
                    'attr': 'pvId',
                    'value': '0',
                },
            ];
            ApiService.FinAccPostByDetail(props.param, pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        const filterValues = props.DataPaymentDetail.map(item => item['refDetailId']);
                        arrayData = response.data.data.receiptVoucherDetail;
                        arrayData = arrayData.filter(item => !filterValues.includes(item['id']));

                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMap2(indexed)

                        setColumnData2(response.data.data.columns.detailColumns)
                        setTotalRows2(response.data.totalRowCount);

                        setIsLoading2(false)
                    }
                    setIsLoading2(false)
                })
                .catch(function (error) {
                    setIsLoading2(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    function Select() {
        if (props.SettlementTo === 'I') {
            props.setIsLoadingBackDrop(true);

            if (dataCheck.length > 0) {
                let check = 0;
                let Sequence = 1;
                let pvId = 0;

                let accountCode = '';
                let accountName = '';

                let accountId = 0;

                if (props.paymentVoucherId) {
                    pvId = props.paymentVoucherId;
                }

                let tempCount = 0;
                let arrayData = props.DataPaymentDetail;
                let arrayDataId = props.DataPaymentDetailId;

                for (let i = 0; i < props.DataPaymentDetailId.length; i++) {
                    for (let j = 0; j < dataCheck.length; j++) {
                        if (dataCheck[j].id === props.DataPaymentDetailId[i].id) {
                            check = 1;
                            break;
                        }
                    }
                }

                if (check === 0) {
                    let iteration = 0;
                    ApiService.CustomPostByPage('regJournalAccount/JournalAccount', 1, 50, props.CustomerTo, 'PV', [])
                        .then((response) => {
                            if (response.data.code === 200) {
                                if (response.data.data.journalAccount[0] !== undefined) {
                                    accountCode = response.data.data.journalAccount[0].accountCode;
                                    accountName = response.data.data.journalAccount[0].accountName;

                                    dataCheck.forEach(el => {
                                        if (props.detailSequence > 0) Sequence = props.detailSequence + 1;

                                        let data = {
                                            "countryId": countryId,
                                            "companyId": companyId,
                                            "branchId": branchId,
                                            "userId": userId,
                                            "userCode": userCode,

                                            "rowStatus": "ACT",
                                            "pvId": pvId,
                                            "id": 0,
                                            "sequence": Sequence,
                                            "paymentBy": props.PaymentBy,
                                            "bankId": 0,
                                            "description": "SETTLEMENT INVOICE C/N NO." + el.invoiceNo2,
                                            "dcNote": 'D',
                                            "amountUSD": el.totalPaymentUSD,
                                            "amountIDR": el.totalPaymentIDR,
                                            "amountTaxUSD": el.totalVatUSD,
                                            "amountTaxIDR": el.totalVatIDR,
                                            "paymentCash": 0,
                                            "refId": el.id,
                                            "refNo": el.invoiceNo2,
                                            "shipmentOrderId": el.shipmentId,
                                            "shipmentNo": el.shipmentNo,
                                            "refRate": el.rate,
                                            "refDetailId": 0,
                                            "refAccountId": accountId,
                                            "coaCode": accountCode,
                                            "coaName": accountName,
                                            "exRateId": el.exRateId,
                                            "isPPh23": el.percentVAT === 0 ? false : true,
                                            "isStored": false,
                                            "paymentVoucherDetailBanks": [],

                                            "idLama": 0,
                                        };

                                        arrayData = [...arrayData, data];

                                        props.setDataPaymentDetail(arrayData);
                                        props.setDataPaymentDetailId(arrayDataId);

                                        props.calculateTotalDebitCredit(arrayData, props.Rate, props.PaymentBy, props.PPh23Amount);

                                        if (tempCount === 0) {
                                            arrayDataId = [...arrayDataId, data.refId];
                                            tempCount++;
                                        }

                                        Sequence = parseInt(Sequence) + parseInt(1);

                                        iteration = parseInt(iteration) + parseInt(1);

                                        if (dataCheck.length === iteration) {
                                            props.setIsLoadingBackDrop(false);
                                        }
                                    });
                                }
                            }
                        })
                        .catch(function (error) {
                            props.setIsLoadingBackDrop(false);
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() });
                        })

                    props.emptyState();
                    emptyState();
                    props.handleClick();

                    props.setIsModal(false);
                    props.handleClose();
                }
                else {
                    props.setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Data Has Been Selected!' });

                    props.setRefId(0);
                    props.setExRateId(0);
                    props.setRefRate(0);
                    emptyState();

                    if (props.SettlementTo === 'I') {
                        props.setRefNo('');
                        props.setShipmentId(0);
                        props.setShipmentNo('');
                    }
                    else if (props.SettlementTo === 'P') {
                        props.setRefNo('');
                        props.setShipmentId(0);
                        props.setShipmentNo('');
                    }
                    else if (props.SettlementTo === 'T') {
                        props.setRefNo('');
                        props.setAccountCode('');
                        props.setAccountName('');
                    }
                    else if (props.SettlementTo === 'R') {
                        props.setRefNo('');
                    }
                }
                emptyState();
            }
            else {
                props.setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
        }
        else {
            props.setIsLoadingBackDrop(true);

            if (dataCheck.length > 0) {
                let Sequence = 1;
                let pvId = 0;

                let amountUSD = 0;
                let amountIDR = 0;
                let amountTaxUSD = 0;
                let amountTaxIDR = 0;

                let shipmentId = 0;
                let shipmentNo = '';

                let description = '';

                let accountCode = '';
                let accountName = '';

                let accountId = 0;

                if (props.paymentVoucherId) {
                    pvId = props.paymentVoucherId;
                }

                let tempCount = 0;
                let arrayData = props.DataPaymentDetail;
                let arrayDataId = props.DataPaymentDetailId;

                if (props.detailSequence > 0) Sequence = props.detailSequence + 1;

                if (props.SettlementTo === 'P') {
                    props.setIsLoadingBackDrop(true);
                    props.handleClick();

                    props.setIsModal(false);
                    props.handleClose();

                    let contactTypeId = 0;
                    let jobCode = '';

                    if (props.CustomerTo === 4) {
                        contactTypeId = props.CustomerTo;
                        jobCode = JSON.parse(localStorage.getItem('jobTypeCode'));
                    }
                    else if (props.CustomerTo === 5) {
                        jobCode = JSON.parse(localStorage.getItem('jobTypeCode'));
                    }
                    else {
                        contactTypeId = props.CustomerTo;
                    }

                    ApiService.CustomPostByPage('regJournalAccount/JournalAccount', 1, 50, contactTypeId, 'PR', [], 'D', '', jobCode, '', 'D')
                        .then((response) => {
                            if (response.data.code === 200) {
                                if (response.data.data.journalAccount[0] !== undefined) {
                                    accountCode = response.data.data.journalAccount[0].accountCode;
                                    accountName = response.data.data.journalAccount[0].accountName;

                                    dataCheck.forEach(element => {
                                        amountUSD = 0;
                                        amountIDR = 0;
                                        amountTaxUSD = 0;
                                        amountTaxIDR = 0;

                                        if (element.amountCrr === 0) {
                                            amountUSD = element.amount;
                                            amountTaxUSD = parseFloat(element.amount) * parseFloat(element.persenPpn) / parseFloat(100);
                                        }
                                        else if (element.amountCrr === 1) {
                                            amountIDR = element.amount;
                                            amountTaxIDR = parseFloat(element.amount) * parseFloat(element.persenPpn) / parseFloat(100)
                                        }

                                        if (props.ShipmentId !== 0 && props.ShipmentNo !== '') {
                                            shipmentId = props.ShipmentId;
                                            shipmentNo = props.ShipmentNo;
                                        }
                                        else {
                                            shipmentId = element.shipmentOrderId;
                                            shipmentNo = element.shipmentNo;
                                        }

                                        description = element.description;

                                        if (element.accountId !== undefined) {
                                            accountId = element.accountId;
                                        }

                                        let data = {
                                            "countryId": countryId,
                                            "companyId": companyId,
                                            "branchId": branchId,
                                            "userId": userId,
                                            "userCode": userCode,

                                            "rowStatus": "ACT",
                                            "pvId": pvId,
                                            "id": 0,
                                            "sequence": Sequence,
                                            "paymentBy": props.PaymentBy,
                                            "bankId": 0,
                                            "description": description,
                                            "dcNote": 'D',
                                            "amountUSD": amountUSD,
                                            "amountIDR": amountIDR,
                                            "amountTaxUSD": amountTaxUSD,
                                            "amountTaxIDR": amountTaxIDR,
                                            "paymentCash": 0,
                                            "refId": props.RefId,
                                            "refNo": props.RefNo,
                                            "shipmentOrderId": shipmentId,
                                            "shipmentNo": shipmentNo,
                                            "refRate": props.RefRate,
                                            "refDetailId": element.id,
                                            "refAccountId": accountId,
                                            "coaCode": accountCode,
                                            "coaName": accountName,
                                            "exRateId": props.ExRateId,
                                            "isPPh23": false,
                                            "isStored": false,
                                            "paymentVoucherDetailBanks": [],

                                            "idLama": 0,
                                        };

                                        arrayData = [...arrayData, data];

                                        if (tempCount === 0) {
                                            arrayDataId = [...arrayDataId, data.refId];
                                            tempCount++;
                                        }

                                        Sequence = parseInt(Sequence) + parseInt(1);
                                    });

                                    props.setDataPaymentDetail(arrayData);
                                    props.setDataPaymentDetailId(arrayDataId);

                                    props.calculateTotalDebitCredit(arrayData, props.Rate, props.PaymentBy, props.PPh23Amount);

                                    props.emptyState();
                                    emptyState();

                                    props.setIsLoadingBackDrop(false);
                                }
                            }
                        })
                        .catch(function (error) {
                            props.setIsLoadingBackDrop(false);
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() });
                        })
                }
                else if (props.SettlementTo === 'T') {
                    dataCheck.forEach(element => {
                        amountUSD = 0;
                        amountIDR = 0;
                        amountTaxUSD = 0;
                        amountTaxIDR = 0;

                        amountUSD = element.totalPaymentUSD;
                        amountIDR = element.totalPaymentIDR;

                        if (props.ShipmentId !== 0 && props.ShipmentNo !== '') {
                            shipmentId = props.ShipmentId;
                            shipmentNo = props.ShipmentNo;
                        }
                        else {
                            shipmentId = element.shipmentOrderId;
                            shipmentNo = element.shipmentNo;
                        }

                        description = 'Refund Jaminan TR No. ' + props.RefNo;

                        if (props.AccountCode === '') {
                            if (element.coaCode !== undefined) {
                                accountCode = element.coaCode;
                                accountName = element.coaName;
                            }
                            else {
                                accountCode = '';
                                accountName = '';
                            }
                        }
                        else {
                            accountCode = props.AccountCode;
                            accountName = props.AccountName;
                        }

                        if (element.accountId !== undefined) {
                            accountId = element.accountId;
                        }

                        let data = {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "userId": userId,
                            "userCode": userCode,

                            "rowStatus": "ACT",
                            "pvId": pvId,
                            "id": 0,
                            "sequence": Sequence,
                            "paymentBy": props.PaymentBy,
                            "bankId": 0,
                            "description": description,
                            "dcNote": 'D',
                            "amountUSD": amountUSD,
                            "amountIDR": amountIDR,
                            "amountTaxUSD": amountTaxUSD,
                            "amountTaxIDR": amountTaxIDR,
                            "paymentCash": 0,
                            "refId": props.RefId,
                            "refNo": props.RefNo,
                            "shipmentOrderId": shipmentId,
                            "shipmentNo": shipmentNo,
                            "refRate": props.RefRate,
                            "refDetailId": element.id,
                            "refAccountId": accountId,
                            "coaCode": accountCode,
                            "coaName": accountName,
                            "exRateId": props.ExRateId,
                            "isPPh23": false,
                            "isStored": false,
                            "paymentVoucherDetailBanks": [],

                            "idLama": 0,
                        };

                        arrayData = [...arrayData, data];

                        if (tempCount === 0) {
                            arrayDataId = [...arrayDataId, data.refId];
                            tempCount++;
                        }

                        Sequence = parseInt(Sequence) + parseInt(1);
                    });

                    props.setDataPaymentDetail(arrayData);
                    props.setDataPaymentDetailId(arrayDataId);

                    props.calculateTotalDebitCredit(arrayData, props.Rate, props.PaymentBy, props.PPh23Amount);

                    props.setIsLoadingBackDrop(false);
                    props.emptyState();
                    props.handleClick();
                    emptyState();

                    props.setIsModal(false);
                    props.handleClose();
                }
                else if (props.SettlementTo === 'R') {
                    dataCheck.forEach(element => {
                        amountUSD = 0;
                        amountIDR = 0;
                        amountTaxUSD = 0;
                        amountTaxIDR = 0;

                        amountUSD = element.amountUSD;
                        amountIDR = element.amountIDR;

                        if (props.ShipmentId !== 0 && props.ShipmentNo !== '') {
                            shipmentId = props.ShipmentId;
                            shipmentNo = props.ShipmentNo;
                        }
                        else {
                            shipmentId = element.shipmentOrderId;
                            shipmentNo = element.shipmentNo;
                        }

                        description = element.description;

                        if (props.AccountCode === '') {
                            if (element.coaCode !== undefined) {
                                accountCode = element.coaCode;
                                accountName = element.coaName;
                            }
                            else {
                                accountCode = '';
                                accountName = '';
                            }
                        }
                        else {
                            accountCode = props.AccountCode;
                            accountName = props.AccountName;
                        }

                        if (element.accountId !== undefined) {
                            accountId = element.accountId;
                        }

                        let data = {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "userId": userId,
                            "userCode": userCode,

                            "rowStatus": "ACT",
                            "pvId": pvId,
                            "id": 0,
                            "sequence": Sequence,
                            "paymentBy": props.PaymentBy,
                            "bankId": 0,
                            "description": description,
                            "dcNote": 'D',
                            "amountUSD": amountUSD,
                            "amountIDR": amountIDR,
                            "amountTaxUSD": amountTaxUSD,
                            "amountTaxIDR": amountTaxIDR,
                            "paymentCash": 0,
                            "refId": props.RefId,
                            "refNo": props.RefNo,
                            "shipmentOrderId": shipmentId,
                            "shipmentNo": shipmentNo,
                            "refRate": props.RefRate,
                            "refDetailId": element.id,
                            "refAccountId": accountId,
                            "coaCode": accountCode,
                            "coaName": accountName,
                            "exRateId": props.ExRateId,
                            "isPPh23": false,
                            "isStored": false,
                            "paymentVoucherDetailBanks": [],

                            "idLama": 0,
                        };

                        arrayData = [...arrayData, data];

                        if (tempCount === 0) {
                            arrayDataId = [...arrayDataId, data.refId];
                            tempCount++;
                        }

                        Sequence = parseInt(Sequence) + parseInt(1);
                    });

                    props.setDataPaymentDetail(arrayData);
                    props.setDataPaymentDetailId(arrayDataId);

                    props.calculateTotalDebitCredit(arrayData, props.Rate, props.PaymentBy, props.PPh23Amount);

                    props.setIsLoadingBackDrop(false);
                    props.emptyState();
                    props.handleClick();
                    emptyState();

                    props.setIsModal(false);
                    props.handleClose();
                }
            }
            else {
                props.setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Please Select Data!' });
            }

        }
    }

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    function handleGetData(data) {
        setDataCheck([]);
        props.setSelectedData(data);

        props.setRefId(data.id);
        props.setExRateId(data.exRateId);
        props.setRefRate(data.rate);

        if (props.SettlementTo === 'I') {
            props.setRefNo(data.invoiceNo2);
            props.setShipmentId(data.shipmentId);
            props.setShipmentNo(data.shipmentNo);
        }
        else if (props.SettlementTo === 'P') {
            props.setRefNo(data.prNo2);
            props.setShipmentId(data.shipmentId);
            props.setShipmentNo(data.shipmentNo);
        }
        else if (props.SettlementTo === 'T') {
            props.setRefNo(data.trNo2);
            props.setAccountCode(data.accountCode);
            props.setAccountName(data.accountName);
        }
        else if (props.SettlementTo === 'R') {
            props.setRefNo(data.rvNo2);
        }

        localStorage.setItem('jobTypeCode', JSON.stringify(data.jobTypeCode));
        localStorage.setItem('dataId', JSON.stringify(data.id));
        getData2(1, 100, filter2);
    }

    function CellDoubleClick(row) {
        return null;
    }

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    const handleReset2 = () => {
        setFilter2([]);
        setFilterBy2([]);
        getData2(1, 100, []);
    };

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter);
    }

    const onChangePaging2 = (value) => {
        setNumPage2(value);
        getData2(value, rowsCount, filter);
    }

    const onChangeLimit2 = (value) => {
        setNumPage2(1);
        setRowsCount2(value);
        getData2(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={6} style={{ width: "100%", borderRadius: "5px" }}>
                <Box height='55%'>
                    <div>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                props.columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                props.getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: '375px',
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            props.isLoading ?
                                (
                                    <LoadingSpinner />
                                )
                                :
                                (
                                    <>
                                        <div className="mt-1">
                                            {
                                                props.SettlementTo !== 'I' ?
                                                    <TableX
                                                        uniqueKey={'id'}
                                                        goTo={goTo}
                                                        rowsCount={rowsCount}
                                                        setNumPage={setNumPage}
                                                        setRowsCount={setRowsCount}
                                                        getData={props.getData}
                                                        columnData={props.columnData}
                                                        dataMap={props.dataMap}
                                                        SelectedData={props.SelectedData}
                                                        setSelectedData={props.setSelectedData}
                                                        setFilter={setFilter}
                                                        setFilterBy={setFilterBy}
                                                        CellDoubleClick={CellDoubleClick}
                                                        stateX={stateX}
                                                        setStateX={setStateX}
                                                        sortArr={sortArr}
                                                        setSortArr={setSortArr}
                                                        setGoTo={setGoTo}
                                                        handleGetData={handleGetData}
                                                    />
                                                    :
                                                    <TableCheck
                                                        uniqueKey={'id'}
                                                        rowsCount={rowsCount}
                                                        setNumPage={setNumPage}
                                                        setRowsCount={setRowsCount}
                                                        getData={props.getData}
                                                        columnData={props.columnData}
                                                        dataMap={props.dataMap}
                                                        SelectedData={props.SelectedData}
                                                        setSelectedData={props.setSelectedData}
                                                        setFilter={setFilter}
                                                        setFilterBy={setFilterBy}
                                                        dataCheck={dataCheck}
                                                        setDataCheck={setDataCheck}
                                                        stateX={stateX}
                                                        setStateX={setStateX}
                                                        sortArr={sortArr}
                                                        setSortArr={setSortArr}
                                                        setGoTo={setGoTo}
                                                        handleGetData={handleGetData}
                                                    />
                                            }
                                        </div>
                                    </>
                                )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={props.totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                            size='small'
                        />
                    </div>
                </Box>
            </Grid>
        </>
    );

    const mainContainer2 = (
        <>
            <Grid item xs={6} style={{ width: "100%", borderRadius: "5px" }}>
                <Box height='55%'>
                    <div>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr2,
                                rowsCount2,
                                props.columnData2,
                                setGoTo2,
                                handleReset2,
                                setFilterBy2,
                                filterBy2,
                                setFilter2,
                                setNumPage2,
                                setRowsCount2,
                                getData2,
                                stateX2,
                                setStateX2
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: '375px',
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading2 ?
                                (
                                    <LoadingSpinner />
                                )
                                :
                                (
                                    <>
                                        <div className="mt-1">
                                            {
                                                props.SettlementTo === 'I' ?
                                                    <TableXPreview
                                                        rowsCount={rowsCount2}
                                                        goTo={goTo2}
                                                        setNumPage={setNumPage2}
                                                        setRowsCount={setRowsCount2}
                                                        getData={getData2}
                                                        columnData={columnData2}
                                                        dataMap={dataMap2}
                                                        setFilter={setFilter2}
                                                        setFilterBy={setFilterBy2}
                                                        stateX={stateX2}
                                                        setStateX={setStateX2}
                                                        sortArr={sortArr2}
                                                        setSortArr={setSortArr2}
                                                        setGoTo={setGoTo2}
                                                    />
                                                    :
                                                    <TableCheck uniqueKey={'id'}
                                                        rowsCount={rowsCount2}
                                                        goTo={goTo2}
                                                        dataCheck={dataCheck}
                                                        setDataCheck={setDataCheck}
                                                        SelectedData={props.SelectedData2}
                                                        setSelectedData={props.setSelectedData2}
                                                        setNumPage={setNumPage2}
                                                        setRowsCount={setRowsCount2}
                                                        getData={getData2}
                                                        columnData={columnData2}
                                                        dataMap={dataMap2}
                                                        setFilter={setFilter2}
                                                        setFilterBy={setFilterBy2}
                                                        stateX={stateX2}
                                                        setStateX={setStateX2}
                                                        sortArr={sortArr2}
                                                        setSortArr={setSortArr2}
                                                        setGoTo={setGoTo2}
                                                    />
                                            }
                                        </div>
                                    </>
                                )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage2}
                            limit={rowsCount2}
                            totalData={totalRows2}
                            onChange={onChangePaging2}
                            onChangeLimit={onChangeLimit2}
                            size='small'
                        />
                    </div>
                </Box>
            </Grid>
        </>
    );


    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.SettlementToName}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                <Grid container spacing={'16px'}>
                    {mainContainer}
                    {mainContainer2}
                </Grid>

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataPaymentVoucher;