import PropTypes from 'prop-types'
import { elementTypeAcceptingRef, refType } from '@mui/utils';

import { FormControlProps } from '../props'

export const InputProps = {
  // *** Component Props ***
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  message: PropTypes.string,
  textAlign: PropTypes.oneOf(['left', 'right']),

  // *** Form Control Props Based on MUI ***
  formControlProps: PropTypes.shape(FormControlProps),

  // *** Input Base Props Based on MUI ***
  inputBaseProps: PropTypes.shape({
    'aria-describedby': PropTypes.string,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOfType([
      PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
      PropTypes.string
    ]),
    components: PropTypes.shape({
      Input: PropTypes.elementType,
      Root: PropTypes.elementType
    }),
    componentsProps: PropTypes.shape({
      input: PropTypes.object,
      root: PropTypes.object
    }),
    disabled: PropTypes.bool,
    disableInjectingGlobalStyles: PropTypes.bool,
    endAdornment: PropTypes.node,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    inputComponent: elementTypeAcceptingRef,
    inputProps: PropTypes.object,
    inputRef: refType,
    margin: PropTypes.oneOf(['dense', 'none']),
    maxRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minRows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onInvalid: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    renderSuffix: PropTypes.func,
    required: PropTypes.bool,
    rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOfType([
      PropTypes.oneOf(['medium', 'small']),
      PropTypes.string
    ]),
    slotProps: PropTypes.shape({
      input: PropTypes.object,
      root: PropTypes.object
    }),
    slots: PropTypes.shape({
      input: PropTypes.elementType,
      root: PropTypes.elementType
    }),
    startAdornment: PropTypes.node,
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.func,
          PropTypes.object,
          PropTypes.bool
        ])
      ),
      PropTypes.func,
      PropTypes.object
    ]),
  }),

  // *** Form Label Props Based on MUI ***
  formLabelProps: PropTypes.shape({
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOfType([
      PropTypes.oneOf(['error', 'info', 'primary', 'secondary', 'success', 'warning']),
      PropTypes.string]
    ),
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    filled: PropTypes.bool,
    focused: PropTypes.bool,
    required: PropTypes.bool,
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.func,
          PropTypes.object,
          PropTypes.bool
        ])
      ),
      PropTypes.func,
      PropTypes.object
    ]),
  }),
}