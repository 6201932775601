import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

const ButtonInvoice = ({
    ReloadData,
    DataRegUserAccess,
    DropDownTitle,
    AddInv,
    EditInv,
    DeleteData,
    Print,
    PrintPEJKP,
    RePrint,
    Delivery,
    ApproveGenInv,
    Approve,
    HandleStatus,
    DropDownList
}) => {

    const renderButtonByAccessCode = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddInv, 'New');
            case 'REI':
                return ButtonComponent.EditButton(EditInv, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data') : null;
            case 'PRI':
                return DropDownTitle !== 'Deleted' ? (
                    <>
                        {ButtonComponent.PrintButton(Print, 'Data')}
                        {ButtonComponent.PrintButton(PrintPEJKP, 'PEJKP')}
                    </>
                ) : null;
            case 'RPR':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.ReprintButton(RePrint, 'Approval') : null;
            case 'DVD':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.DeliveryButton(Delivery, 'Delivered') : null;
            case 'APG':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All') ? ButtonComponent.ApproveButton(ApproveGenInv, 'Approve General Inv') : null;
            case 'APC':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.ApproveButton(Approve, 'Approval Credit') : null;
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButtonByAccessCode(data.accessCode)}
                        </React.Fragment>
                    ))}

                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonInvoice;