import { Typography } from "@mui/material";
import React, { Component } from "react";

class LiveClock extends Component {
  constructor(props) {
    super(props);

    this.state = { date: new Date() };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      date: new Date()
    });
  }

  render() {
    return (
      <Typography
        sx={{
          color: 'var(--Primary-One, #083A50)',
          fontFamily: 'Nunito',
          fontSize: '14px',
          fontstyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
          cursor: 'default',
          userSelect: 'none',
        }}
      >
        {this.state.date.toLocaleTimeString("sv-se", { hour: "2-digit", minute: "2-digit", second: "2-digit" })}
      </Typography>
    );
  }
}

export default LiveClock;
