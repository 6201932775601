import {
    Grid, MenuItem, FormHelperText, Button, IconButton, Backdrop, Stack,
    TableContainer, Table, TableRow, TableCell, TableBody,
} from '@mui/material';
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';

import ApiService, { userCode, username } from '../../../services/ApiService';

import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import THead from './components/THead';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Icon, Input, Paginate, PopUp, Selection, showToast, TickBox } from '../../../components/ui';

const requiredText = 'This field is required.'
const removeArrow = {
    'input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '&[type=number]': {
            MozAppearance: 'input',
        }
    }
}

const CreateEditSetColumn = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, selectedData } = props
    const dateNow = new Date()
    const tenYearsLater = new Date(new Date().setFullYear(dateNow.getFullYear() + 10, dateNow.getMonth(), dateNow.getDate()))

    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)

    const [dataTypeList, setDataTypeList] = useState([])
    const [isNewCode, setIsNewCode] = useState(false)

    const [listCode, setListCode] = useState([])
    const [listColumn, setListColumn] = useState([])

    const [selectedCopy, setSelectedCopy] = useState('')

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "setColumn": {
                    ...formik.values,
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DatasetColumn", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    useEffect(() => {
        getAdditionalData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAdditionalData = async () => {
        setIsLoading(true)

        const paramsSC = [
            { attr: 'IsMasterSetColumn', value: '1' },
            { attr: 'SetTableId', value: String(selectedData?.id || 0) },
        ]

        try {
            const [resSC, /* resST, */ resDT] = await Promise.all([
                ApiService.DynamicPostByPage('setColumn/setColumn', 'PAGE', 1, 999999, paramsSC),
                // ApiService.DynamicPostByPage('setTable/setTable', 'PAGE', 1, 999, []),
                ApiService.DynamicPostByPage('dataType/dataType', 'PAGE', 1, 999, [])
            ])

            if (resSC.data.code === 200) {
                let data = resSC.data.data.setColumn
                setListColumn(data)

                const arrCode = []
                data.forEach((elm) => {
                    if (!arrCode.includes(elm.code)) {
                        arrCode.push(elm.code)
                    }
                })

                setListCode(arrCode)
            }

            if (resDT.data.code === 200) {
                const data = resDT?.data?.data?.dataType || []
                const mapData = data.map((dataType) => {
                    return { id: dataType?.id, value: dataType?.name }
                })

                setDataTypeList(mapData)
            }
        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
    }

    const toggleForm = () => {
        setIsNewCode(!isNewCode)
        formik.resetForm()
        setSelectedCopy('')
    }

    const handleChangeCode = (code) => {
        formik.setFieldValue('code', code)
        let tempColumn = [...listColumn]
        tempColumn = tempColumn.filter((x) => x.code === code)
        tempColumn = tempColumn.map((temp) => {
            return {
                ...temp,
                validOn: temp?.validOn ? formatDate(temp.validOn) : formatDate(),
                validTo: temp?.validOn ? formatDate(temp.validTo) : formatDate(tenYearsLater),
            }
        })

        formik.setFieldValue('columns', tempColumn)
        setPage(1)
    }

    const handleCopyCode = (code) => {
        setSelectedCopy(code)
        if (!code) return formik.setFieldValue('columns', [])

        let tempColumn = [...listColumn]
        tempColumn = tempColumn.filter((x) => x.code === code)
        tempColumn = tempColumn.map((temp) => {
            return {
                ...temp,
                validOn: temp?.validOn ? formatDate(temp.validOn) : formatDate(),
                validTo: temp?.validOn ? formatDate(temp.validTo) : formatDate(tenYearsLater),
            }
        })

        formik.setFieldValue('columns', tempColumn)
        setPage(1)
    }

    const formatDate = (dt) => {
        const targetDate = dt ? new Date(dt) : dateNow

        return targetDate.toLocaleDateString("sv-se").split('T')[0]
    }

    const handleDeleteColumn = (index) => {
        let activeColumn = [...formik.values.columns]

        activeColumn = activeColumn.filter((x, i) => i !== index)

        formik.setFieldValue('columns', activeColumn)
    }

    const paginateForm = () => {
        let arrForm = [...formik.values.columns]

        const start = (page - 1) * limit
        const end = page * limit
        arrForm = arrForm.slice(start, end)
        arrForm = arrForm.sort((a, b) => {
            return a.displayOrder - b.displayOrder
        })

        return arrForm
    }

    Yup.addMethod(Yup.string, "uniqueCode", function (errorMessage) {
        return this.test(`test-unique-code`, errorMessage, function (value) {
            const { path, createError } = this;

            return (
                (value && !listCode.includes(value)) ||
                createError({ path, message: errorMessage })
            );
        });
    });

    Yup.addMethod(Yup.number, "uniqueOrder", function (errorMessage) {
        return this.test(`test-unique-order`, errorMessage, function (value) {
            const { path, createError } = this;

            let isUnique = false
            let columnList = [...formik?.values?.columns]
            columnList = columnList.filter((x) => x.displayOrder === value)

            if (columnList.length === 1) {
                isUnique = true
            }

            return (
                (value && isUnique) ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const validationSchema = Yup.object().shape({
        code: Yup.string().when([], {
            is: () => isNewCode,
            then: () => Yup.string().trim().uniqueCode('Code must be unique.').required(requiredText),
            otherwise: () => Yup.string().trim().required(requiredText),
        }),
        columns: Yup.array().of(
            Yup.object().shape({
                column: Yup.string().trim().required(requiredText),
                text: Yup.string().trim().required(requiredText),
                alias: Yup.string().trim().required(requiredText),
                displayOrder: Yup.number('Must be a valid number.').uniqueOrder('Order must be unique.').min(1, 'Must greater than 0.').required(requiredText),
                dataType: Yup.string().trim().required(requiredText),
                length: Yup.number('Must be a valid number.').min(1, 'Must greater than 0.').required(requiredText),
                textAlign: Yup.string().trim().required(requiredText),
                format: Yup.string().nullable(),
                validOn: Yup.date().required(requiredText),
                validTo: Yup.date().required(requiredText),
                hidden: Yup.boolean(),
                create: Yup.boolean(),
                read: Yup.boolean(),
                update: Yup.boolean(),
                delete: Yup.boolean(),
            })
        ).min(1, requiredText),
    })

    const formik = useFormik({
        initialValues: {
            code: '',
            columns: [],
        },
        validationSchema,
        onSubmit: (values) => submitForm(values)
    })

    const submitForm = async (values) => {
        setIsSubmitting(true)

        try {
            const setColumns = []
            values.columns.forEach((elm) => {
                setColumns.push({
                    ...elm,
                    rowStatus: 'ACT',
                    userId: 0,
                    code: values.code,
                    userCode,
                })
            })

            const res = await ApiService.createData('setColumn/setColumn', { setColumns })

            if (res?.data?.code === 200) {
                showToast({ type: 'success', message: 'All column have been successfully saved.' })

                formik.resetForm()
                setSelectedCopy('')
                getAdditionalData()
            } else {
                showToast({ type: 'error', message: 'Can`t save columns, please try again.' })
            }
        } catch (error) {
            showToast({ type: 'error', message: 'Can`t save columns, please try again.' })
        }

        setIsSubmitting(false)
    }

    function handleChange(name, value) {
        formik.setFieldValue(name, !value);
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Grid container spacing={'16px'}>
                <Grid item xs={isNewCode ? 4 : 2}>
                    {!isNewCode ? (
                        <Selection
                            fullWidth={true}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    // marginTop: '16px'
                                }
                            }}
                            label="Code"
                            disabled={isSubmitting}
                            value={formik.values.code}
                            placeholder={'Select Code'}
                            onChange={(e) => handleChangeCode(e.target.value)}
                            error={formik.touched.code && Boolean(formik.errors.code)}
                        >
                            {
                                listCode.map((code) => (
                                    <MenuItem sx={{ fontSize: '12px' }} value={code}>{code}</MenuItem>
                                ))
                            }
                            <FormHelperText>{formik.touched.code && formik.errors.code}</FormHelperText>
                        </Selection>
                    ) : (
                        <Stack direction={'row'} spacing={'16px'}>
                            <Input
                                id='code'
                                name="code"
                                label="Code"
                                disabled={isSubmitting}
                                value={formik.values.code}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code}
                                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        // marginTop: '16px'
                                    }
                                }}
                            />

                            <Selection
                                fullWidth={true}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        // marginTop: '16px'
                                    }
                                }}
                                label="Copy Columns"
                                disabled={isSubmitting}
                                value={selectedCopy}
                                placeholder={'Select Copy Columns'}
                                onChange={(e) => handleCopyCode(e.target.value)}
                            >
                                <MenuItem sx={{ fontSize: '12px' }} value=''>None</MenuItem>
                                {
                                    listCode.map((code) => (
                                        <MenuItem sx={{ fontSize: '12px' }} value={code}>{code}</MenuItem>
                                    ))
                                }
                            </Selection>
                        </Stack>
                    )}
                </Grid>

                <Grid item xs={6} alignSelf={'center'}>
                    <Button
                        variant='outlined'
                        size='small'
                        color={isNewCode ? 'warning' : 'info'}
                        onClick={toggleForm}
                        disabled={isSubmitting}
                        sx={{ marginTop: '24px' }}
                    >
                        {isNewCode ? 'Cancel' : 'Create New Code'}
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer>
                        <Table sx={{ minWidth: 1500, mb: '10px' }} size="small" aria-label="a dense table">
                            <THead />

                            <TableBody>
                                {paginateForm().map((row, counter) => {

                                    const index = ((page - 1) * limit) + counter

                                    return (
                                        <>
                                            <TableRow key={row?.id} sx={{ '.MuiTableCell-root': { p: '6px 8px' } }}>
                                                <TableCell>
                                                    {index + 1}
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.column`}
                                                        name={`columns.${index}.column`}
                                                        formControlProps={{ sx: { width: '150px', } }}
                                                        value={formik.values.columns?.[index]?.column}
                                                        helperText={formik.touched.columns && formik.errors.columns?.[index]?.column}
                                                        error={formik.touched.columns && Boolean(formik.errors.columns?.[index]?.column)}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.column`, e.target.value)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.text`}
                                                        name={`columns.${index}.text`}
                                                        value={formik.values.columns?.[index]?.text}
                                                        formControlProps={{ sx: { width: '150px', } }}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.text`, e.target.value)}
                                                        helperText={formik.touched.columns?.[index]?.text && formik.errors.columns?.[index]?.text}
                                                        error={formik.touched.columns?.[index]?.text && Boolean(formik.errors.columns?.[index]?.text)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.alias`}
                                                        name={`columns.${index}.alias`}
                                                        value={formik.values.columns?.[index]?.alias}
                                                        formControlProps={{ sx: { width: '150px', } }}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.alias`, e.target.value)}
                                                        helperText={formik.touched.columns?.[index]?.alias && formik.errors.columns?.[index]?.alias}
                                                        error={formik.touched.columns?.[index]?.alias && Boolean(formik.errors.columns?.[index]?.alias)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        type='number'
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.displayOrder`}
                                                        name={`columns.${index}.displayOrder`}
                                                        value={formik.values.columns?.[index]?.displayOrder}
                                                        formControlProps={{ sx: { ...removeArrow, width: '80px', } }}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.displayOrder`, e.target.value)}
                                                        helperText={formik.touched.columns?.[index]?.displayOrder && formik.errors.columns?.[index]?.displayOrder}
                                                        error={formik.touched.columns?.[index]?.displayOrder && Boolean(formik.errors.columns?.[index]?.displayOrder)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Selection
                                                        fullWidth={true}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                                // marginTop: '16px'
                                                            }
                                                        }}
                                                        disabled={isSubmitting}
                                                        placeholder={'Select Data Type'}
                                                        id={`columns.${index}.dataType`}
                                                        name={`columns.${index}.dataType`}
                                                        value={formik.values.columns?.[index]?.dataType}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.dataType`, e.target.value)}
                                                        error={formik.touched.columns?.[index]?.dataType && Boolean(formik.errors.columns?.[index]?.dataType)}
                                                    >
                                                        {
                                                            dataTypeList.map((dataType) => (
                                                                <MenuItem sx={{ fontSize: '12px' }} key={dataType.id} value={dataType.value}>{dataType.value || '-'}</MenuItem>
                                                            ))
                                                        }
                                                        <FormHelperText>{formik.touched.columns?.[index]?.dataType && formik.errors.columns?.[index]?.dataType}</FormHelperText>
                                                    </Selection>
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        type='number'
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.length`}
                                                        name={`columns.${index}.length`}
                                                        value={formik.values.columns?.[index]?.['length']}
                                                        formControlProps={{ sx: { ...removeArrow, width: '80px', } }}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.length`, e.target.value)}
                                                        helperText={formik.touched.columns?.[index]?.['length'] && formik.errors.columns?.[index]?.['length']}
                                                        error={formik.touched.columns?.[index]?.['length'] && Boolean(formik.errors.columns?.[index]?.['length'])}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.format`}
                                                        name={`columns.${index}.format`}
                                                        value={formik.values.columns?.[index]?.format}
                                                        formControlProps={{ sx: { width: '100px', } }}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.format`, e.target.value)}
                                                        helperText={formik.touched.columns?.[index]?.format && formik.errors.columns?.[index]?.format}
                                                        error={formik.touched.columns?.[index]?.format && Boolean(formik.errors.columns?.[index]?.format)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Selection
                                                        fullWidth={true}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                                // marginTop: '16px'
                                                            }
                                                        }}
                                                        disabled={isSubmitting}
                                                        placeholder={'Select Text Align'}
                                                        id={`columns.${index}.textAlign`}
                                                        name={`columns.${index}.textAlign`}
                                                        value={formik.values.columns?.[index]?.textAlign}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.textAlign`, e.target.value)}
                                                        error={formik.touched.columns?.[index]?.textAlign && Boolean(formik.errors.columns?.[index]?.textAlign)}
                                                    >
                                                        <MenuItem sx={{ fontSize: '12px' }} value={'left'}>Left</MenuItem>
                                                        <MenuItem sx={{ fontSize: '12px' }} value={'center'}>Center</MenuItem>
                                                        <MenuItem sx={{ fontSize: '12px' }} value={'right'}>Right</MenuItem>
                                                        <FormHelperText>{formik.touched.columns?.[index]?.textAlign && formik.errors.columns?.[index]?.textAlign}</FormHelperText>
                                                    </Selection>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <TickBox
                                                        name={`columns.${index}.hidden`}
                                                        checked={formik.values.columns?.[index]?.hidden}
                                                        onChange={() => handleChange(`columns.${index}.hidden`, formik.values.columns?.[index]?.hidden)}
                                                    />
                                                </TableCell>

                                                <TableCell align="center">
                                                    <TickBox
                                                        name={`columns.${index}.create`}
                                                        checked={formik.values.columns?.[index]?.create}
                                                        onChange={() => handleChange(`columns.${index}.create`, formik.values.columns?.[index]?.create)}
                                                    />
                                                </TableCell>

                                                <TableCell align="center">
                                                    <TickBox
                                                        name={`columns.${index}.read`}
                                                        checked={formik.values.columns?.[index]?.read}
                                                        onChange={() => handleChange(`columns.${index}.read`, formik.values.columns?.[index]?.read)}
                                                    />
                                                </TableCell>

                                                <TableCell align="center">
                                                    <TickBox
                                                        name={`columns.${index}.update`}
                                                        checked={formik.values.columns?.[index]?.update}
                                                        onChange={() => handleChange(`columns.${index}.update`, formik.values.columns?.[index]?.update)}
                                                    />
                                                </TableCell>

                                                <TableCell align="center">
                                                    <TickBox
                                                        name={`columns.${index}.delete`}
                                                        checked={formik.values.columns?.[index]?.delete}
                                                        onChange={() => handleChange(`columns.${index}.delete`, formik.values.columns?.[index]?.delete)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        type='date'
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.validOn`}
                                                        name={`columns.${index}.validOn`}
                                                        formControlProps={{ sx: { width: '150px', } }}
                                                        value={formik.values.columns?.[index]?.validOn}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.validOn`, e.target.value)}
                                                        helperText={formik.touched.columns?.[index]?.validOn && formik.errors.columns?.[index]?.validOn}
                                                        error={formik.touched.columns?.[index]?.validOn && Boolean(formik.errors.columns?.[index]?.validOn)}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <Input
                                                        type='date'
                                                        disabled={isSubmitting}
                                                        onBlur={formik.handleBlur}
                                                        id={`columns.${index}.validTo`}
                                                        name={`columns.${index}.validTo`}
                                                        formControlProps={{ sx: { width: '150px', } }}
                                                        value={formik.values.columns?.[index]?.validTo}
                                                        onChange={(e) => formik.setFieldValue(`columns.${index}.validTo`, e.target.value)}
                                                        helperText={formik.touched.columns?.[index]?.validTo && formik.errors.columns?.[index]?.validTo}
                                                        error={formik.touched.columns?.[index]?.validTo && Boolean(formik.errors.columns?.[index]?.validTo)}
                                                    />
                                                </TableCell>

                                                <TableCell align="right">
                                                    <IconButton onClick={() => handleDeleteColumn(index)} disabled={isSubmitting}>
                                                        <Icon
                                                            iconName={'delete'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '24px',
                                                                    width: '24px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <div style={{ marginTop: '16px' }}>
                <Paginate
                    page={page}
                    limit={limit}
                    // totalData={totalRows}
                    onChange={setPage}
                    onChangeLimit={setLimit}
                />
            </div>
        </form>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={titleModal + ' ' + title + ' - ' + selectedData?.tableName}
                isOpen={isModal}
                onClose={handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'xl'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleClick, false, false)}
                    </div>
                </div>

                {
                    isLoading && (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <LoadingSpinner color="inherit" />
                        </Backdrop>
                    )
                }
            </PopUp>
        </>
    )
})

export default CreateEditSetColumn