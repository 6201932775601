import React from 'react';
import { Stack, FormControl, Box } from '@mui/material';

import MonthYearPicker from '../../../../../components/Pickers/MonthYearPicker';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';

const ButtonBalance = (props) => {
    return (
        <div className="row mb-3">
            <Stack spacing={1} direction="row">
                <div>
                    <Stack spacing={'32px'} direction="row">
                        {
                            props.roleName === 'company'?
                                <>
                                    <Box>
                                        <FormControl variant="standard" fullWidth>
                                            {props.selectBranchData(props.setBranchId, props.BranchName, props.setBranchName)}
                                        </FormControl>
                                    </Box>
                                </>
                            :
                                <>
                                    {
                                        props.roleName === 'group'?
                                            <>
                                                <Box>
                                                    <FormControl variant="standard" fullWidth>
                                                        {props.selectCompanyData(props.setCompanyId, props.CompanyName, props.setCompanyName)}
                                                    </FormControl>
                                                </Box>
                                                
                                                <Box>
                                                    <FormControl variant="standard" fullWidth>
                                                        {props.selectBranchData(props.setBranchId, props.BranchName, props.setBranchName)}
                                                    </FormControl>
                                                </Box>
                                            </>
                                        :
                                            <>
                                                {
                                                    props.roleName === 'holding'?
                                                        <>
                                                            <Box>
                                                                <FormControl variant="standard" fullWidth>
                                                                    {props.selectGroupData(props.setGroupId, props.GroupName, props.setGroupName)}
                                                                </FormControl>
                                                            </Box>
                                                                
                                                            <Box>
                                                                <FormControl variant="standard" fullWidth>
                                                                    {props.selectCompanyData(props.setCompanyId, props.CompanyName, props.setCompanyName)}
                                                                </FormControl>
                                                            </Box>
                                                            
                                                            <Box>
                                                                <FormControl variant="standard" fullWidth>
                                                                    {props.selectBranchData(props.setBranchId, props.BranchName, props.setBranchName)}
                                                                </FormControl>
                                                            </Box>
                                                        </>
                                                    :
                                                        <></>
                                                }
                                            </>
                                    }
                                </>
                        }

                        <MonthYearPicker
                            period={props.Period} setPeriod={props.setDataPeriod}
                            labelPeriod={'Select Period'} disablePeriod={false}
                            handleDate={function () { return null }}
                        />
                    </Stack>
                </div>

                <div className='mt-4'>
                    {
                        props.roleName !== 'branch'?
                            <>
                                {ButtonComponent.ArchiveButton(props.OpenLogData, 'Search Log Data')}
                            </>
                        :
                            <>
                                {ButtonComponent.FindButton(props.handleFindData)}
                            </>
                    }
                </div>
            </Stack>
        </div>
    )
}

export default ButtonBalance