import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

const ButtonShipment = ({
    ReloadData, AddSh, EditSh,
    DeleteData, FindData, DataRegUserAccess,
    DropDownTitle, HandleStatus
}) => {

    const renderButton = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddSh, 'New');
            case 'REI':
                return ButtonComponent.EditButton(EditSh, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted'
                    ? ButtonComponent.DeleteButton(DeleteData, 'Data')
                    : null;
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid
                    container
                    item
                    spacing={1}
                    direction="row"
                    style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
                >
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data.accessCode)}
                        </React.Fragment>
                    ))}

                    {ButtonComponent.FindButton(FindData)}

                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
                </Grid>
            </div>
        </div>
    );
}

export default ButtonShipment;