import React from 'react';
import ButtonService from '../../ButtonService';
import CreateEditModalMaster from '../../CreateEditModalMaster';
import { Input, Selection } from '../../../../components/ui';
import { Grid, MenuItem } from '@mui/material';

const ModalRestore = (props) => {
    const restoreClosing = () => {
        var data = {
            'closingPeriod': {
                "period": props.Period,
                "yearPeriod": props.YearPeriod,
                "BDValidActiveDate": props.ValidDate,
            }
        };

        ButtonService.Restore(props.titleModal, props.title, props.param + '/RestoreClosingMonthly', data, () => {
            props.getData(1, 50, []);
            props.handleClick();
            window.location.reload();
        });
    }

    const getMonthNames = (locale) => {
        const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
        const currentMonth = new Date().getMonth();

        return Array.from({ length: currentMonth }, (_, i) => ({
            id: i + 1,
            label: formatter.format(new Date(0, i)),
        }));
    };
    const periodList = getMonthNames('en-US');

    const mainContainer = (
        <form onSubmit={restoreClosing} style={{ marginTop: '12px' }}>
            <Grid container spacing={'32px'}>
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Selection
                        fullWidth={true}
                        id="period"
                        label="Period"
                        labelId="periodLabel"
                        placeholder={'Select Period'}
                        value={props.Period}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        onChange={(e) => props.setPeriod(e.target.value)}
                    >
                        {
                            periodList.map((period) => (
                                <MenuItem sx={{ fontSize: '12px' }} id={period.id} key={period.id} value={period.id}>
                                    {period.label}
                                </MenuItem>
                            ))
                        }
                    </Selection>

                    <Input
                        type='date'
                        label="Valid Active Date"
                        name="yearPeriod"
                        value={props.ValidDate}
                        onChange={(e) => props.setValidDate(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            },
                        }}
                        inputBaseProps={{ inputProps: { min: props.MinValidDate, max: props.MaxValidDate } }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        type='number'
                        label="Year Period"
                        name="yearPeriod"
                        value={props.YearPeriod}
                        onChange={(e) => props.setYearPeriod(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                        disabled
                    />
                </Grid>
            </Grid>
        </form>
    )

    return (
        <CreateEditModalMaster
            isModal={props.isModal}
            titleModal={props.titleModal}
            title={props.title}
            mainContainer={mainContainer}
            handleClick={props.handleClick}
            handleSave={restoreClosing}
        />
    )
}

export default ModalRestore;