import React from 'react'
import { createTheme, CssBaseline, GlobalStyles, ThemeProvider, useTheme } from '@mui/material'

import { lightThemeOptions } from './config'
import scrollBarTheme from './scrollbarTheme'

const ThemeComponent = ({ children }) => {
  const theme = useTheme();
  const applyTheme = true
  const customTheme = createTheme({
    palette: lightThemeOptions.palette,
    typography: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            ...scrollBarTheme,
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            padding: '13px 25px',
            background: '#F2FAFF',
            color: '#083A50',
            borderRadius: '12px 12px 0 0',
            '&.Mui-selected': {
              background: '#B3E3FF',
              borderBottom: '4px solid #083A50',
              color: '#083A50',
            },
            '&.Mui-disabled': {
              color: '#7F888C',
            },
            '&:not(:first-of-type)': {
              marginInlineStart: '4px',
            }
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '.MuiTableCell-head': {
              fontWeight: 700,
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            borderRadius: 8,
            fontSize: '16px',
            lineHeight: 1.25,
            fontWeight: 700,
          },
          sizeSmall: {
            minHeight: '32px',
            padding: '6px 8px'
          },
          sizeMedium: {
            minHeight: '40px',
            padding: '8px 12px',
          },
          sizeLarge: {
            minHeight: '52px',
            padding: '14px 20px'
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#083A50',
            fontWeight: 700,
            '&.Mui-focused': {
              color: 'inherit',
            },
            '&.Mui-error': {
              color: 'inherit',
            },
            '&.Mui-disabled': {
              color: 'inherit',
            },
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 35,
            height: 20,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: 1.5,
              transitionDuration: '150ms',
              color: '#CACED8',
              '&.Mui-checked': {
                transform: 'translateX(15px)',
                color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#083A50',
                '& + .MuiSwitch-track': {
                  backgroundColor: theme.palette.mode === 'dark' ? '#083A50' : '#FFFFFF',
                  border: '1px solid #083A50',
                  opacity: 1,
                },
                '&.Mui-disabled': {
                  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#083A50',
                }
              },
              '&.Mui-disabled': {
                color: '#CACED8',
                '.MuiSwitch-thumb': {
                  opacity: 0.5
                },
                '& + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              boxShadow: 'none',
              width: 17,
              height: 17,
            },
            '& .MuiSwitch-track': {
              borderRadius: 16,
              border: '1px solid #CACED8',
              backgroundColor: theme.palette.mode === 'dark' ? '#083A50' : '#FFFFFF',
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 150,
              }),
            },
          },
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={applyTheme ? customTheme : theme}>
      <CssBaseline enableColorScheme />
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: theme.palette.background
          }
        }}
      />
      {children}
    </ThemeProvider>
  )
}

export default ThemeComponent