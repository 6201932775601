import { FormHelperText, MenuItem } from '@mui/material';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import ApiService, { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditRegion = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const [isLoading, setIsLoading] = useState(true)
    const [continentList, setContinentList] = useState([])

    useEffect(() => {
        if (isModal) {
            getContinentData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    const errorToast = (location, closeModal = false) => {
        showToast('error', `Can't get ${location} data! please contact Administrator`)
        if (closeModal) handleClick()
    }

    const getContinentData = () => {
        ApiService.PostByPage('continent/continent', 1, 200, []).then((res) => {
            if (res.data.code === 200) {
                setContinentList(res.data.data.continent);
            } else {
                errorToast('continent', true)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error)
            errorToast('continent', true)
        })
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "region": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-Dataregion", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        masterCode: Yup.string().nullable(),
        code: Yup.string().trim().required('This field is required.'),
        name: Yup.string().trim().required('This field is required.'),
        abbreviation: Yup.string().nullable(),
        continentId: Yup.number().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? {
            ...selectedData,
            continentId: selectedData?.continentId !== 0 ? selectedData.continentId : ''
        } : {
            masterCode: '',
            code: '',
            name: '',
            abbreviation: '',
            continentId: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.continentId === '') {
            return showToast({ type: 'error', message: 'Continent is Required' });
        }

        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Name is Required' });
        }

        const data = {
            "region": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload?.code,
                "name": payload?.name,
                "abbreviation": payload?.abbreviation,
                "masterCode": payload?.masterCode,
                "continentId": payload?.continentId,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="masterCode"
                label="Master Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                disabled
                value={formik.values.masterCode}
                onBlur={formik.handleBlur}
                error={formik.touched.masterCode && Boolean(formik.errors.masterCode)}
                helperText={formik.touched.masterCode && formik.errors.masterCode}
                onChange={(e) => formik.setFieldValue('masterCode', e.target.value)}
            />

            <Input
                name="code"
                label="Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
            />

            <Selection
                placeholder={'Select Continent'}
                labelId="lblContinent"
                id="continent"
                name='continentId'
                value={formik.values.continentId || ''}
                label="Continent"
                onChange={(e) => formik.setFieldValue('continentId', e.target.value)}
                error={formik.touched.continentId && Boolean(formik.errors.continentId)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    continentList.map((continent) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={continent.id} key={continent.id} value={continent.id}>
                            {continent.name}
                        </MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.continentId && formik.errors.continentId}</FormHelperText>
            </Selection>

            <Input
                name="name"
                label="Region"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
            />

            <Input
                name="abbreviation"
                label="Abbreviation"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.abbreviation}
                onBlur={formik.handleBlur}
                error={formik.touched.abbreviation && Boolean(formik.errors.abbreviation)}
                helperText={formik.touched.abbreviation && formik.errors.abbreviation}
                onChange={(e) => formik.setFieldValue('abbreviation', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        isLoading={isLoading}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditRegion