import React from 'react';
import Grid from '@mui/material/Grid';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonInBoundList = ({
    ReloadData,
    DataRegUserAccess,
    AddData,
    EditData,
    DeleteData,
    HandleStatus,
    DropDownTitle
}) => {

    const renderButton = (data) => {
        switch (data.accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddData, 'New');
            case 'REI':
                return ButtonComponent.EditButton(EditData, 'Data');
            case 'DEL':
                return ButtonComponent.DeleteButton(DeleteData, 'Data');
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data)}
                        </React.Fragment>
                    ))}
                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
                </Grid>
            </div>
        </div>
    );
}

export default ButtonInBoundList;