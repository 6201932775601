import { Grid, Stack, IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';

import ApiService, { formatCurrency } from '../../../../services/ApiService';
import SelectDataInvoice from '../Selects/SelectDataInvoice';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';
import { Icon, Input, Selection, showToast } from '../../../../components/ui';

const FakturPajakHeader = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramI = 'invoice/invoice';
    const [isLoadingI, setIsLoadingI] = useState(false);
    const [dataI, setDataI] = useState([]);
    const [dataMapI, setDataMapI] = useState([]);
    const [SelectedDataI, setSelectedDataI] = useState({});
    const [totalRowsI, setTotalRowsI] = useState(50);
    const [columnDataI, setColumnDataI] = useState([]);

    const currencyTypeData = [
        { id: 1, code: true, name: 'IDR' },
        { id: 2, code: false, name: 'Others' }
    ];

    function handleIsIDRCurrency(val) {
        props.setIsIDRCurrency(val);

        if (val === true) {
            props.setCurrency('IDR');
            props.setKursValue(0);
        }
        else {
            props.setCurrency('USD');
        }

        props.setCurrencyType('USD');
    }

    function handleCurrencyType(val) {
        props.setCurrencyType(val);
        props.setCurrency(val);
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataHeaderEPL(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchInvoice = (type) => {
        if (type === 'search') {
            setTitleModal('Invoice');
            handleClick();
        }
        else if (type === 'delete') {
            props.setInvoiceId(0);
            props.setInvoiceNo('');
            props.setShipmentId(0);
            props.setShipmentNo('');
            props.setJobOwnerId(0);

            props.setCustomerId(0);
            props.setCustomerName('');
            props.setCustomerAddress('');

            props.setNPWPData('');
            props.setNPPPKPData('-');

            props.setInvoiceDetailList([]);
        }
    }

    const getDataHeaderEPL = (pageNumber, pageSize, filters) => {
        setIsLoadingI(true);
        let params = [];
        params = [...params,
        { 'attr': 'jobTypeId', 'value': '' + props.typeJobId },
        ];
        ApiService.OperationDynamicPostByPage(paramI, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataI({});
                    setDataI(response.data.data.invoices);

                    let temp = response.data.data.invoices;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp;
                    })

                    setDataMapI(indexed);
                    setColumnDataI(response.data.data.columns.headerColumns);
                    setTotalRowsI(response.data.totalRowCount);

                    setIsLoadingI(false);
                }
                setIsLoadingI(false);
            })
            .catch(function (error) {
                setIsLoadingI(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    return (
        <div>
            <form>
                <div>
                    <div>
                        <Grid
                            container
                            spacing={'64px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <div>
                                    {props.selectVATData(props.vatValue, props.setVatValue, false, 'Type Pajak')}
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'64px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Invoice No"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.invoiceNo}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => searchInvoice('delete')}
                                                    disabled={props.isEdit}
                                                    hidden={props.invoiceId !== 0 ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => searchInvoice('search')} disabled={props.isEdit}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled
                                />

                                <Input
                                    label='Shipment No'
                                    value={props.shipmentNo}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <div style={{ marginTop: '16px' }}>
                                    {props.selectPrincipleBy(props.jobOwnerId, props.setJobOwnerId)}
                                </div>

                                <Stack spacing={'32px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='Customer'
                                        value={props.customerId}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                marginTop: '26px'
                                            }
                                        }}
                                    />

                                    <Input
                                        value={props.customerName}
                                        onChange={(e) => props.setCustomerName(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%'
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                        }}
                                    />
                                </Stack>

                                <Input
                                    label={'Address'}
                                    value={props.customerAddress}
                                    onChange={(e) => props.setCustomerAddress(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 5,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label='Date F.Pajak'
                                    type={'date'}
                                    value={props.fakturDate}
                                    onChange={(e) => props.setFakturDate(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Stack spacing={'32px'} direction="row">
                                    <Input
                                        label='No Seri FPS'
                                        value={props.seriFPS1}
                                        onChange={(e) => props.setSeriFPS1(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />

                                    <Input
                                        value={props.seriFPS2}
                                        onChange={(e) => props.setSeriFPS2(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Stack>

                                <Input
                                    label='NPWP'
                                    value={props.NPWPData}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='NPPKP'
                                    value={props.NPPPKPData}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <div style={{ marginTop: '18px' }}>
                                    <Grid className='text-start'>
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: '19.6px',
                                            }}
                                        >
                                            Valas (Default USD)
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={'32px'}
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={6}
                                        >
                                            <Selection
                                                id='0'
                                                fullWidth={true}
                                                placeholder={'Select Currency Type'}
                                                value={props.isIDRCurrency}
                                                onChange={(e) => handleIsIDRCurrency(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        marginTop: '16px'
                                                    }
                                                }}
                                            >
                                                {
                                                    currencyTypeData.map((data, index) => (
                                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                                    ))
                                                }
                                            </Selection>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={'32px'}
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={6}
                                        >
                                            <div style={{ marginTop: '16px' }}>
                                                {props.selectCurrency(props.currencyType, handleCurrencyType, props.isIDRCurrency, 'Others Currency')}
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={6}
                                        >
                                            <Input
                                                textAlign='right'
                                                label="Kurs"
                                                value={props.kursValue}
                                                disabled={props.isIDRCurrency}
                                                onChange={(e) => props.setKursValue(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <SelectDataInvoice
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        typeJobId={props.typeJobId}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}

                        getDataHeader={getDataHeaderEPL}
                        isLoading={isLoadingI}
                        setIsLoading={setIsLoadingI}
                        data={dataI}
                        setData={setDataI}
                        dataMap={dataMapI}
                        setDataMap={setDataMapI}
                        columnData={columnDataI}
                        setColumnData={setColumnDataI}
                        SelectedData={SelectedDataI}
                        setSelectedData={setSelectedDataI}
                        totalRows={totalRowsI}
                        setTotalRows={setTotalRowsI}

                        setInvoiceId={props.setInvoiceId}
                        setInvoiceNo={props.setInvoiceNo}
                        setShipmentId={props.setShipmentId}
                        setShipmentNo={props.setShipmentNo}
                        setJobOwnerId={props.setJobOwnerId}
                        setCustomerId={props.setCustomerId}
                        setCustomerName={props.setCustomerName}
                        setCustomerAddress={props.setCustomerAddress}
                        setNPWPData={props.setNPWPData}
                        setNPPPKPData={props.setNPPPKPData}
                        setInvoiceDetailList={props.setInvoiceDetailList}
                    />
                )
            }
        </div>
    )
}

export default FakturPajakHeader;