import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';

const ButtonFindDataShipment = ({
    FindData, EditData, Report,
    ReportExcel, DataRegUserAccess
}) => {

    const renderButton = (accessCode) => {
        switch (accessCode) {
            case 'REI':
                return ButtonComponent.EditButton(EditData, 'Data', false, true);
            case 'PRI':
                return (
                    <>
                        {ButtonComponent.ExcelButton(Report, 'Report', false, true)}
                        {ButtonComponent.ExcelButton(ReportExcel, 'Report Excel', false, true)}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className="row mt-4">
            <div className="col-12">
                <Grid
                    container
                    item
                    spacing={1}
                    justifyContent="right"
                    direction="row"
                    style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
                >
                    {ButtonComponent.FindButton(FindData, 'Find', true)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data.accessCode)}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonFindDataShipment;