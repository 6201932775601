import { Grid, Stack } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../../components/Masks/RupiahInput';

import CustomizeButtonComponent from '../../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Input, PopUp, showToast } from '../../../../../../components/ui';

const ModalAdjustment = (props) => {
    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setEPLUSD(0);
        props.setEPLIDR(0);
        props.setInvoicePRUSD(0);
        props.setInvoicePRIDR(0);
        props.setPreviousUSD(0);
        props.setPreviousIDR(0);
        props.setCurrentUSD(0);
        props.setCurrentIDR(0);
        props.setNextUSD(0);
        props.setNextIDR(0);
    };

    const handleSave = () => {
        let currentIDR = formatCurrency === 'USD' ? parseFloat(convertMask(props.CurrentIDR)) : parseFloat(convertMaskIDR(props.CurrentIDR));
        let currentUSD = formatCurrency === 'USD' ? parseFloat(convertMask(props.CurrentUSD)) : parseFloat(convertMaskIDR(props.CurrentUSD));
        if (currentIDR > props.EPLIDR || currentUSD > props.EPLUSD) {
            showToast({ type: 'error', message: 'Adjustment Can\'t Be Greater Than EPL' });
        }
        else {
            let array = props.dataMap.slice();
            array.forEach(element => {
                if (element.id === props.SelectedData.id) {
                    element.adjustIDR = currentIDR;
                    element.adjustUSD = currentUSD;
                }
            });

            props.setDataMap(array);
            props.calculateTotal(array);
            showToast({ type: 'success', message: 'Adding Adjustment on ' + props.SelectedData.shipmentNo + ' Success' });

            handleClose();
        }
    }

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'64px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Shipment No"
                        value={props.ShipmentNo}
                        fullWidth
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Customer Name"
                        value={props.Customer}
                        fullWidth
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>

            <Stack direction={'row'} spacing={'24px'} sx={{ marginTop: '32px !important' }}>
                <Input
                    label="EPL USD"
                    textAlign='right'
                    fullWidth
                    disabled
                    value={props.EPLUSD}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Invoice / PR USD"
                    value={props.InvoicePRUSD}
                    textAlign='right'
                    fullWidth
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Current USD"
                    value={props.CurrentUSD}
                    onChange={(e) => props.setCurrentUSD(e.target.value)}
                    textAlign='right'
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Adjust Previous USD"
                    value={props.PreviousUSD}
                    textAlign='right'
                    fullWidth
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Adjust Next USD"
                    value={props.NextUSD}
                    textAlign='right'
                    fullWidth
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />
            </Stack>

            <Stack direction={'row'} spacing={'24px'} sx={{ marginTop: '24px' }}>
                <Input
                    label="EPL IDR"
                    textAlign='right'
                    fullWidth
                    disabled
                    value={props.EPLIDR}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Invoice / PR IDR"
                    value={props.InvoicePRIDR}
                    textAlign='right'
                    fullWidth
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Current IDR"
                    value={props.CurrentIDR}
                    onChange={(e) => props.setCurrentIDR(e.target.value)}
                    textAlign='right'
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Adjust Previous IDR"
                    value={props.PreviousIDR}
                    textAlign='right'
                    fullWidth
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Input
                    label="Adjust Next IDR"
                    value={props.NextIDR}
                    textAlign='right'
                    fullWidth
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />
            </Stack>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalAdjustment;