import React from "react";
import { Grid } from "@mui/material";

import ButtonComponent from "../../../../../components/Buttons/ButtonComponent";

const ButtonCreateEditAdjustment = ({ Save, isEdit, Approve }) => {
  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: "12px" }}
        >
          {ButtonComponent.SaveButton(Save, false, false, true)}
          {isEdit && ButtonComponent.ApproveButton(Approve, "Approve", true)}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonCreateEditAdjustment;
