import { Grid, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import ApiService from '../../../../../services/ApiService';
import ModalBalance from '../ModalBankAdv/ModalBalance';
import { Input } from '../../../../../components/ui';

const BankAdvTotal = (props) => {
    const [isModalBalance, setIsModalBalance] = useState(false);
    const [titleModalBalance, setTitleModalBalance] = useState('');

    const [isLoadingBalance, setIsLoadingBalance] = useState(false);
    const [dataBalance, setDataBalance] = useState([]);
    const [dataMapBalance, setDataMapBalance] = useState([])
    const [columnDataBalance, setColumnDataBalance] = useState([])
    const [SelectedDataBalance, setSelectedDataBalance] = useState({});
    const [totalRowsBalance, setTotalRowsBalance] = useState(50)

    const paramBalance = "bank/bank";

    const ShowModalBalance = () => {
        setTitleModalBalance('Balance')
        if (isModalBalance) {
            setIsModalBalance(false)
        } else {
            setIsModalBalance(true)
        }
    }

    const getBalance = (pageNumber, pageSize, filters) => {
        setIsLoadingBalance(true);
        ApiService.OperationDynamicPostByPage(paramBalance, 'PAGE', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataBalance({})
                    setDataBalance(response.data.data.bank);

                    let temp = response.data.data.bank
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapBalance(indexed)
                    setColumnDataBalance(response.data.data.columns.headerColumns)
                    setTotalRowsBalance(response.data.totalRowCount)

                    setIsLoadingBalance(false)
                }
                setIsLoadingBalance(false)
            })
            .catch(function (error) {
                setIsLoadingBalance(false)
                console.error('error saat fetch', error)
            })
    }

    return (
        <div style={{ marginTop: '24px' }}>
            <Grid container spacing={2} direction="column">
                <Stack spacing={'32px'} direction="row" alignItems={'center'}>
                    <Button
                        size='small'
                        variant="outlined"
                        className='d-flex align-items-center'
                        // disabled = {props.printing > 0? true : false}
                        sx={{
                            padding: '6px 24px !important',
                            gap: '8px',
                            width: '100%',
                            marginTop: '24px !important',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            background: 'var(--Blue-Button-Primary-1, #0087C2)',
                            '&:hover': {
                                backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            }
                        }}
                        onClick={props.printing === 0 ? ShowModalBalance : null}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                color: '#E4E8EC',
                            }}
                        >
                            BALANCE OF OVERALL {props.titleDetail.toUpperCase()} ADVANCE
                        </Typography>
                    </Button>

                    <Input
                        label="Total USD"
                        value={props.totalUSD}
                        textAlign='right'
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label="Total IDR"
                        value={props.totalIDR}
                        textAlign='right'
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label="Total In IDR"
                        value={props.totalInIDR}
                        textAlign='right'
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Stack>
            </Grid>
            
            {
                isModalBalance && (
                    <ModalBalance
                        isModal={isModalBalance}
                        handleClick={ShowModalBalance}
                        titleModal={titleModalBalance}
                        setIsModal={setIsModalBalance}

                        isLoading={isLoadingBalance}
                        data={dataBalance}
                        dataMap={dataMapBalance}
                        columnData={columnDataBalance}
                        SelectedData={SelectedDataBalance}
                        totalRows={totalRowsBalance}
                        setSelectedData={setSelectedDataBalance}
                        getData={getBalance}
                    />
                )
            }
        </div>
    )
}

export default BankAdvTotal