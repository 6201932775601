import { Backdrop, MenuItem, FormHelperText } from '@mui/material';
import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import ApiService, { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalIdentity from '../CreateEditModalIdentity';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditServer = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const [serverTypeList, setServerTypeList] = useState([])
    const [isLoading, setisLoading] = useState(true)

    useEffect(() => {
        if (isModal) {
            getAdditionalData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    const errorToast = (location, closeModal = false) => {
        showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
        if (closeModal) handleClick()
    }

    const getAdditionalData = () => {
        ApiService.PostByPage('serverType/serverType', 1, 9999, [])
            .then((res) => {
                if (res.data.code === 200) {
                    setServerTypeList(res.data.data.serverType);
                } else {
                    errorToast('server type')
                }

                setisLoading(false)
            }).catch(function (error) {
                errorToast('server type')
                setisLoading(false)
            })
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "server": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DatasetServer", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        code: Yup.string().trim().required('This field is required.'),
        name: Yup.string().trim().required('This field is required.'),
        serverTypeId: Yup.number().required('This field is required.'),
        ipAddress: Yup.string().trim().required('This field is required.'),
        password: Yup.string().trim().required('This field is required.'),
        description: Yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            code: '',
            name: '',
            serverTypeId: '',
            ipAddress: '',
            password: '',
            description: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        const data = {
            "server": {
                id: payload?.id,
                name: payload?.name,
                serverTypeId: payload?.serverTypeId,
                ipAddress: payload?.ipAddress,
                password: payload?.password,
                description: payload?.description,
                rowStatus: payload?.rowStatus || 'ACT',
                userCode,
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                label={'Code'}
                value={formik.values.code}
                onBlur={formik.handleBlur}
                helperText={formik.touched.code && formik.errors.code}
                error={formik.touched.code && Boolean(formik.errors.code)}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                label={'Name'}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                helperText={formik.touched.name && formik.errors.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Selection
                fullWidth={true}
                id="serverTypeId"
                name='serverTypeId'
                label="Server Type"
                labelId="lblServerType"
                placeholder={'Select Server Type'}
                value={formik.values.serverTypeId}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                onChange={(e) => { formik.setFieldValue('serverTypeId', e.target.value) }}
                error={formik.touched.serverTypeId && Boolean(formik.errors.serverTypeId)}
            >
                {
                    serverTypeList.map((svr) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={svr.id} value={svr.id}>{svr.name}</MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.serverTypeId && formik.errors.serverTypeId}</FormHelperText>
            </Selection>

            <Input
                label={'IP Address'}
                value={formik.values.ipAddress}
                onBlur={formik.handleBlur}
                helperText={formik.touched.ipAddress && formik.errors.ipAddress}
                error={formik.touched.ipAddress && Boolean(formik.errors.ipAddress)}
                onChange={(e) => formik.setFieldValue('ipAddress', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                type='password'
                label={'Password'}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                helperText={formik.touched.password && formik.errors.password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                id='description'
                name="description"
                label="Description"
                value={formik.values.description}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                onChange={(e) => formik.setFieldValue('description', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalIdentity
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={formik.handleSubmit}
                    />
                )
            }

            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <LoadingSpinner color="inherit" />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditServer