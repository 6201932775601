import React from 'react';
import { userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input } from '../../../components/ui';

const CreateEditJobType = (props) => {

    const Save = () => {
        var data = {
            "jobType": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "user": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                name="masterCode"
                label="Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                value={props.code}
                onChange={(e) => props.setCode(e.target.value)}
            />

            <Input
                name="name"
                label="Job Type"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }
        </>
    )
}

export default CreateEditJobType
