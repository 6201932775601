import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableX from '../../../../../components/Tables/TableX';
import ApiService from '../../../../../services/ApiService';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';

const ModalSearchCustomer = ({
    isModal,
    activeModal,
    toggleModal,
    dataSelected,
    onSelect,
    errorToast,
    formValue,
    listData,
}) => {
    const [goTo, setGoTo] = useState(1)
    const [stateX, setStateX] = useState({})
    const [filter, setFilter] = useState([])
    const [numPage, setNumPage] = useState(1)
    const [sortArr, setSortArr] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [rowsCount, setRowsCount] = useState(50)

    const [dataTable, setDataTable] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [columnData, setColumnData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const [selectedData, setSelectedData] = useState(dataSelected?.id ? { ...dataSelected } : {})

    useEffect(() => {
        if (isModal) {
            getData(1, 50)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    const getData = (pageNumber = 1, pageSize = 25, filters) => {
        setIsLoading(true);

        let contactTypeId = formValue?.contactType
        if (contactTypeId === 99) {
            contactTypeId = 3;
        }

        if (contactTypeId < 11) {
            ApiService.ContactPostByPage('regContact/regContact', contactTypeId, pageNumber, pageSize, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        let temp = response?.data?.data?.contact || []
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataTable(indexed)
                        setColumnData(response.data.data.columns)
                        setTotalRows(response.data.totalRowCount)

                        setIsLoading(false)
                    } else {
                        errorToast('customer')
                    }
                    setIsLoading(false)
                })
                .catch(function (error) {
                    setIsLoading(false)
                    errorToast('customer')
                    console.error('error saat fetch', error)
                })
        }
        else {
            ApiService.DynamicPostByPage('branch/branch', 'SEARCH', 1, 50, [], filter)
                .then((response) => {
                    if (response.data.code === 200) {
                        let temp = response?.data?.data?.branch || []
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataTable(indexed)
                        setColumnData(response.data.data.columns)
                        setTotalRows(response.data.totalRowCount)

                        setIsLoading(false)
                    } else {
                        errorToast('customer')
                    }
                    setIsLoading(false)
                })
                .catch(function (error) {
                    setIsLoading(false)
                    errorToast('customer')
                    console.error('error saat fetch', error)
                })
        }
    }

    const handleClose = () => {
        toggleModal('');
    }

    const handleChoose = () => {
        if (!selectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            onSelect(selectedData)
            toggleModal('')
        }
    }

    const handleReset = () => {
        setGoTo(1);
        setSortArr([])
        setFilter([])
        setFilterBy([])
        getData(1, 50)
        setStateX({})
    }

    function CellDoubleClick(row) {
        handleChoose()
    }

    const getModalTitle = () => {
        const contactType = listData.find((ct) => ct.id === formValue.contactType)
        let modalTitle = activeModal
        if (contactType) {
            modalTitle = contactType.name
        }

        return modalTitle
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataTable}
                                            SelectedData={selectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + getModalTitle()}
            isOpen={isModal}
            onClose={handleClose}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(handleChoose)}
                </div>
            </div>
        </PopUp>
    )
}

export default ModalSearchCustomer