import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService, { userCode, username } from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditContact from './CreateEditContact';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';

const ListContact = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Contact'
    const [isModal, setIsModal] = useState(false);
    const param = "contact/contact";
    const [isLoading, setIsLoading] = useState(false);
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    const [id, setId] = useState(0);
    const [code, setCode] = useState('');
    const [contactStatus, setContactStatus] = useState('PT')
    const [contactName, setContactName] = useState('')

    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataContact"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data = {
                "contact": {
                    "rowStatus": "ACT",
                    "id": id,
                    "code": code,
                    "contactStatus": contactStatus,
                    "contactName": contactName,
                    "userCode": userCode,
                    "titleModal": titleModal
                }
            }
            localStorage.setItem(username + "-DataContact", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.contact;
        setIsModal(true);
        setId(result.id);
        setCode(result.code);
        setContactStatus(result.contactStatus)
        setContactName(result.contactName)
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-DataContact')
    }

    const emptyState = () => {
        setId(0);
        setCode('');
        setContactStatus('PT');
        setContactName('')
    }

    const toggleAdd = () => {
        setTitleModal('Add');
        emptyState()
        handleClick();
    }

    const toggleEdit = () => {
        setIsLoadingBackDrop(true)
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
            setIsLoadingBackDrop(false)
        }
        else {
            ApiService.PostById(param, SelectedData.id).then((res) => {
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false)
                    var result = res.data.data.contact;
                    setIsModal(true);
                    setTitleModal('Edit');
                    setId(SelectedData.id);
                    setCode(result.code);
                    setContactStatus(result.contactStatus)
                    setContactName(result.contactName)
                }
                else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Failed To Retrieved Data, Please Try Again Later!, ' + res.data.message })
                }
            }).catch(function (error) {
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
        }
    }

    useEffect(() => {
        ApiService.refreshToken();
        if (dataLocal) {
            setTimeout(function () { fetchDataLocal(); }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === true) {
            setIsLoadingBackDrop(true);
        }
        else {
            setIsLoading(true);
        }

        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.contact);

                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === true) {
                        setIsLoadingBackDrop(false);
                    }
                    else {
                        setIsLoading(false);
                    }
                }

                if (isLoadingBackDrop === true) {
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === true) {
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoading(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
            emptyState()
            setNumPage(1)
            setRowsCount(50)
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MCONT'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModal && (
                    <CreateEditContact
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        param={param}
                        getData={getData}

                        id={id}
                        code={code}
                        setCode={setCode}
                        contactStatus={contactStatus}
                        setContactStatus={setContactStatus}
                        contactName={contactName}
                        setContactName={setContactName}
                    />
                )
            }
        </>
    )
})

export default ListContact