export const colorList = {
  baseColor: {
    blue: '#0087C2',
    green: '#2A8D36',
    red: '#E62B2B',
    yellow: '#FFC527',
  },
  grayscaleColor: {
    black: '#000000',
    white: '#FBFBFB',
    gray: '#484848',
    night: '#070F1D',
  }
}

export const lightThemeOptions = {
  palette: {
    primary: {
      main: '#0087C2',
      50: '#0096D81A',
      100: '#4DB6E4',
      200: '#33ABE0',
      300: '#1AA1DC',
      400: '#0096D8',
      500: '#0087C2',
      600: '#0078AD',
      700: '#006997',
      800: '#005A82',
      900: '#004B6C',
      light: '#1AA1DC',
      dark: '#006997',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFC527',
      50: '#FFCE381A',
      100: '#FFEDB6',
      200: '#FFE288',
      300: '#FFD859',
      400: '#FFCE38',
      500: '#FFC527',
      600: '#FFB821',
      700: '#FEA51E',
      800: '#FE951B',
      900: '#FC7717',
      light: '#FFD859',
      dark: '#FEA51E',
      contrastText: '#000',
    },
    success: {
      main: '#2A8D36',
      50: '#2F9D3C1A',
      100: '#6DBA77',
      200: '#59B163',
      300: '#44A750',
      400: '#2F9D3C',
      500: '#2A8D36',
      600: '#267E30',
      700: '#216E2A',
      800: '#1C5E24',
      900: '#184F1E',
      light: '#44A750',
      dark: '#216E2A',
      contrastText: '#FFF',
    },
    error: {
      main: '#E62B2B',
      50: '#FF30301A',
      100: '#FF6E6E',
      200: '#FF5959',
      300: '#FF4545',
      400: '#FF3030',
      500: '#E62B2B',
      600: '#CC2626',
      700: '#B32222',
      800: '#991D1D',
      900: '#801818',
      light: '#FF4545',
      dark: '#B32222',
      contrastText: '#FFF',
    },
    warning: {
      main: '#A38709',
      50: '#B5960A1A',
      100: '#CBB654',
      200: '#C4AB3B',
      300: '#BCA123',
      400: '#B5960A',
      500: '#A38709',
      600: '#917808',
      700: '#7F6907',
      800: '#6D5A06',
      900: '#5B4B05',
      light: '#BCA123',
      dark: '#7F6907',
      contrastText: '#FFF',
    },
    paper: {
      main: '#FFFFFF',
      200: '#C8C8C8',
      300: '#929292',
      400: '#6D6D6D',
      500: '#494949',
    },
  },
}

export const darkThemeOptions = {}