import { Box, Collapse, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography, tableCellClasses } from "@mui/material"
import { styled } from '@mui/material/styles';
import { Icon } from "../../../../components/ui";
import { convertToNominal } from "../../../../utils/masks.jsx";

const IgnoreTable = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            padding: '0px 16px !important',
            height: '32px !important'
        },
    }));

    return (
        <>
            <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => props.setOpenClose(!props.openClose)}
                    disableFocusRipple={true}
                    disableRipple={true}
                >
                    <Stack direction='row' spacing={'12px'} justifyContent="center" alignItems="center">
                        <Icon
                            iconName={props.openClose ? 'arrow-up' : 'arrow-down'}
                            svgIconProps={{
                                fontSize: 'small',
                                htmlColor: '#0087C2',
                                sx: {
                                    height: '12px',
                                    width: '12px'
                                }
                            }}
                        />
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                            }}
                        >
                            New EPL Detail
                        </Typography>
                    </Stack>
                </IconButton>

                <Collapse in={props.openClose} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: '8px 0px' }}>
                        <TableContainer sx={{ maxHeight: 305, 'borderRadius': '12px', background: '#FFF' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{props.contactTypeName}</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', }}>Amount USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', }}>Amount IDR</StyledTableCell>
                                        <StyledTableCell>Sign</StyledTableCell>
                                        <StyledTableCell>Cost To Cost</StyledTableCell>
                                        {
                                            props.type === 2 ?
                                                <StyledTableCell>Cost Trucking</StyledTableCell>
                                                :
                                                <></>
                                        }
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        props.dataList.length > 0 && props.totalNData > 0 ?
                                            props.dataList.map((el) => {
                                                return (
                                                    el.bookingDetailId === 0 ?
                                                        <TableRow
                                                            key={parseInt(el.sequence) + (parseInt(props.key2) * parseInt(el.sequence))}
                                                            onClick={() => props.handleSelectData(el)}
                                                            onDoubleClick={() => props.handleDoubleClick(el)}
                                                            sx={
                                                                props.selectedDetail.sequence === el.sequence ?
                                                                    props.selectedStyle
                                                                    :
                                                                    {}
                                                            }
                                                            hover={true}
                                                        >
                                                            <StyledTableBodyCell>
                                                                <Tooltip title={el.accountCode + ' - ' + el.accountName}>
                                                                    <span>{el.customerName}</span>
                                                                </Tooltip>
                                                            </StyledTableBodyCell>
                                                            <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                                                                {el.amountCrr === 0 ? convertToNominal(el.amountUSD) : convertToNominal(0)}
                                                            </StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                                                                {el.amountCrr === 1 ? convertToNominal(el.amountIDR) : convertToNominal(0)}
                                                            </StyledTableBodyCell>
                                                            <StyledTableBodyCell>{el.sign ? '+' : '-'}</StyledTableBodyCell>
                                                            <StyledTableBodyCell>{el.isCostToCost === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                            {
                                                                props.type === 2 ?
                                                                    <StyledTableBodyCell>{el.isCostTrucking === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                                    :
                                                                    <></>
                                                            }
                                                        </TableRow>
                                                        :
                                                        <></>
                                                )
                                            })
                                            :
                                            <TableRow>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal'
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>

                                <TableFooter sx={{ left: 0, bottom: 0, zIndex: 2, position: 'sticky' }} />
                            </Table>
                        </TableContainer>
                    </Box>
                </Collapse>
            </Grid>
        </>
    )
}


export default IgnoreTable