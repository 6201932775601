import React, { forwardRef, useImperativeHandle } from 'react';
import { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, showToast } from '../../../components/ui';

const CreateEditAirline = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "airLine": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-DataAirline", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        masterCode: Yup.string().nullable(),
        abbreviation: Yup.string().nullable(),
        name: Yup.string().trim().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            masterCode: '',
            abbreviation: '',
            name: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Airline Name is Required' });
        }

        if (payload?.abbreviation === '') {
            return showToast({ type: 'error', message: 'Abbreviation is Required' });
        }

        const data =
        {
            "airLine": {
                "rowStatus": payload?.rowStatus || "ACT",
                "id": payload?.id,
                "name": payload?.name,
                "abbreviation": payload?.abbreviation,
                "masterCode": payload?.masterCode,
                "user": userCode
            }
        }
        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                disabled
                name="masterCode"
                label={'Master Code'}
                onBlur={formik.handleBlur}
                value={formik.values.masterCode}
                helperText={formik.touched.masterCode && formik.errors.masterCode}
                onChange={(e) => formik.setFieldValue('masterCode', e.target.value)}
                error={formik.touched.masterCode && Boolean(formik.errors.masterCode)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="airline"
                label={'Airline'}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="abbreviation"
                label={'Abbreviation'}
                value={formik.values.abbreviation}
                onChange={(e) => formik.setFieldValue('abbreviation', e.target.value)}
                onBlur={formik.handleBlur}
                error={formik.touched.abbreviation && Boolean(formik.errors.abbreviation)}
                helperText={formik.touched.abbreviation && formik.errors.abbreviation}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditAirline