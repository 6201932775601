import React from 'react'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import TableEPL from '../TableEPL/TableEPL'
import { Grid, Stack } from '@mui/material'
import { showToast } from '../../../../components/ui'

const TabCostDepo = (props) => {
  const title = 'Cost Depo'
  const contactTypeName = 'Depo'
  const contactTypeId = 7
  const income = false

  const handleAdd = () => {
    if (props.shipmentNo !== '') {
      props.handleAdd(title, contactTypeName, contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Please Select Shipment Number First!' });
    }
  }

  const handleEdit = () => {
    if (props.selectedDetailCostDepo.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleEdit(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleConvert = () => {
    if (props.selectedDetailCostDepo.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleConvert(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleDoubleClick = () => {
    handleEdit()
  }

  const handleDelete = () => {
    if (props.selectedDetailCostDepo.bookingDetailId === 0) {
      props.handleDelete(contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Can\'t Delete This Data!' });
    }
  }

  return (
    <div className='rounded-10'>
      <TableEPL
        key={10}
        isReadOnly={props.isReadOnly}
        dataList={props.CostDepoList}
        ignoreAccess={props.ignoreAccess}
        setDataList={props.setCostDepoList}
        setSelectedDetail={props.setSelectedDetailCostDepo}
        selectedDetail={props.selectedDetailCostDepo}
        handleDoubleClick={handleDoubleClick}
        contactTypeName={contactTypeName}
        ttlUsd={props.estCostUSDDepo}
        ttlIdr={props.estCostIDRDepo}
        totalSData={props.totalCostSDepo}
        totalNData={props.totalCostNDepo}
        key1={10}
        key2={10}
        type={2}
        openClose={props.openCloseCD}
        setOpenClose={props.setOpenCloseCD}
        openClose2={props.openCloseCD2}
        setOpenClose2={props.setOpenCloseCD2}
        dataDetail={props.dataDetail}
        setDataDetail={props.setDataDetail}
        countSum={props.countSum}
        countTotalData={props.countTotalData}
      />

      <Grid container item direction="row" className='mt-3'>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={'10px'}>
            {ButtonComponent.AddButton(handleAdd, '', props.isReadOnly)}
            {ButtonComponent.EditButton(handleEdit, '', props.isReadOnly)}
            {ButtonComponent.DeleteButton(handleDelete, '', props.isReadOnly)}
            {ButtonComponent.ConvertButton(handleConvert, props.isReadOnly, true)}
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(TabCostDepo)