import {
    IconButton, Stack, InputAdornment, Grid, Box, MenuItem, Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import React from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

import ModalVessel from '../Modals/ModalVessel';
import { Icon, Input, Selection, showToast } from '../../../../components/ui';
import { getDefaultDateFormat, setDateFormat } from '../../../../utils/date';

const TabVessel = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [vesselId, setVesselId] = useState(0);
    const [vesselName, setVesselName] = useState('');
    const [voyage, setVoyage] = useState('');
    const [vesselType, setVesselType] = useState('FEEDER');
    const [etd, setETD] = useState('');
    const [cityId, setCityId] = useState(0);
    const [cityCode, setCityCode] = useState('');
    const [cityName, setCityName] = useState('');

    const dataVesselFlight = [
        { id: 1, code: 'N', name: 'None' },
        { id: 2, code: 'V', name: 'Vessel' },
        { id: 3, code: 'F', name: 'Flight' },
    ];

    function getDateNow() {
        setETD(getDefaultDateFormat(new Date()));
    };

    const AddPlaceOfReceipt = (data) => {
        if (data === 'search') {
            props.setTitleModal('Place Of Receipt');
            props.handleClick('Place Of Receipt');
        }
        else if (data === 'delete') {
            props.setReceiptPlaceId(0);
            props.setReceiptPlaceCode('');
            props.setReceiptPlaceName('');
            props.setTempReceiptPlaceName('');
        }
    };

    const AddPortOfDischarge = (data) => {
        if (data === 'search') {
            props.setTitleModal('Port Of Discharge');
            props.handleClick('Port Of Discharge');
        }
        else if (data === 'delete') {
            props.setDischargePortId(0);
            props.setDischargePortCode('');
            props.setDischargePortName('');
            props.setTempDischargePortName('');
        }
    };

    const AddPortOfLoading = (data) => {
        if (data === 'search') {
            props.setTitleModal('Port Of Loading');
            props.handleClick('Port Of Loading');
        }
        else if (data === 'delete') {
            props.setLoadingPortId(0);
            props.setLoadingPortCode('');
            props.setLoadingPortName('');
            props.setTempLoadingPortName('');
        }
    };

    const AddPlaceOfDelivery = (data) => {
        if (data === 'search') {
            props.setTitleModal('Place Of Delivery');
            props.handleClick('Place Of Delivery');
        }
        else if (data === 'delete') {
            props.setDeliveryPlaceId(0);
            props.setDeliveryPlaceCode('');
            props.setDeliveryPlaceName('');
            props.setTempDeliveryPlaceName('');
        }
    };

    const searchPort = (data) => {
        if (data === 'search') {
            props.setTitleModal('Port');
            props.handleClick('Port');
        }
        else if (data === 'delete') {
            props.setPortId(0);
            props.setPortCode('');
            props.setPortName('');
        }
    };

    const searchAirport = (data) => {
        if (data === 'search') {
            props.setTipe(7);
            props.setTitleModal('AirPort');
            props.handleClick('AirPort');
        }
        else if (data === 'delete') {
            props.setPortId(0);
            props.setPortCode('');
            props.setPortName('');
        }
    };

    const searchVessel = (data) => {
        if (data === 'search') {
            props.setTitleModal('Vessel');
            props.handleClick('Vessel');
        }
        else if (data === 'delete') {
            props.setVesselId(0);
            props.setVesselName('');
        }
    };

    const searchFlight = (data) => {
        if (data === 'search') {
            props.setTitleModal('Flight');
            props.handleClick('Flight');
        }
        else if (data === 'delete') {
            props.setFlightId(0);
            props.setFlightCode('');
            props.setFlightName('');
        }
    };

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
        }
        else {
            setIsModal2(false);
        }
    };

    const ShowAddVessel = () => {
        getDateNow();
        setTitleModal2('Add Vessel');
        setDetailEdit(false);
        if (props.vesselData.length > 0) {
            let data = props.vesselData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        handleClick2();
    };

    function checkVessel(arrayData, data) {
        for (let i = 0; i < arrayData.length; i++) {
            if (arrayData[i].vesselId === data.vesselId && arrayData[i].sequence !== data.sequence) {
                return 1;
            }
        }

        return 0;
    }

    const ShowEditVessel = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setTitleModal2('Edit Vessel');
                setDetailEdit(true);

                setVesselId(selectedDetail.vesselId);
                setVesselName(selectedDetail.vesselName);
                setVoyage(selectedDetail.voyage);
                setVesselType(selectedDetail.vesselType);
                let etdDate = new Date(selectedDetail.etd);
                setDateFormat(etdDate, setETD);
                setCityId(selectedDetail.cityId);
                setCityCode(selectedDetail.cityCode);
                setCityName(selectedDetail.cityName);

                handleClick2();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    }

    const DeleteVessel = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            for (let i = 0; i < props.dataTracking.length; i++) {
                if (props.dataTracking[i].sequenceRouting === tempSequence) {
                    DeleteDataTracking(props.dataTracking[i]);
                }
            }

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.vesselData.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setVesselData(result);

            setSelectedDetail({});
            showToast({ type: 'success', message: 'Vessel Deleted Successfully' });
        }
    }

    const DeleteDataTracking = (selectData) => {
        let tempSequence = selectData.sequence;

        const deleteFunction = (data) => {
            return data.sequence !== tempSequence;
        }

        const result = props.dataTracking.filter(deleteFunction);

        if (result.length > 0) {
            tempSequence = 0;

            result.forEach((el) => {
                tempSequence += 1;
                el.sequence = tempSequence;
            })
        }

        props.setDataTracking(result);
    };

    function handleChangeLocation(type, value) {
        if (type === 'loading') {
            props.setLoadingPortName(value);
        }
        else if (type === 'receipt') {
            props.setReceiptPlaceName(value);
        }
        else if (type === 'discharge') {
            props.setDischargePortName(value);
        }
        else if (type === 'delivery') {
            props.setDeliveryPlaceName(value);
        }
    }

    return (
        <>
            {
                props.jobType.portType === 'SEA' ?
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '12px' }}>
                                <Input
                                    label={'Place of Receipt'}
                                    value={props.ReceiptPlaceName && props.TempReceiptPlaceName && props.ReceiptPlaceName.toLowerCase().includes(props.TempReceiptPlaceName.toLowerCase()) ? props.ReceiptPlaceCode : ''}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '24px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.ReceiptPlaceName}
                                    type='text'
                                    label={''}
                                    disabled
                                    onChange={(e) => handleChangeLocation('receipt', e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        sx: {
                                            marginTop: '23px !important',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => AddPlaceOfReceipt('delete')}
                                                    hidden={props.ReceiptPlaceName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => AddPlaceOfReceipt('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    label="Port of Discharge"
                                    value={props.DischargePortName && props.TempDischargePortName && props.DischargePortName.toLowerCase().includes(props.TempDischargePortName.toLowerCase()) ? props.DischargePortCode : ''}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '24px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.DischargePortName}
                                    type='text'
                                    label={''}
                                    disabled
                                    onChange={(e) => handleChangeLocation('discharge', e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        sx: {
                                            marginTop: '23px !important',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => AddPortOfDischarge('delete')}
                                                    hidden={props.DischargePortName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => AddPortOfDischarge('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Input
                                label={"ETD (Current Period " + props.period + ")"}
                                value={props.ETD}
                                onChange={(e) => props.setETD(e.target.value)}
                                type={'date'}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    inputProps: {
                                        // min: `${!props.isClosed && props.period === 1? (props.yearPeriod - 1) : props.yearPeriod}-${props.isClosed? String(props.period).padStart(2, '0') : props.period !== 1? String((props.period - 1)).padStart(2, '0') : String('12').padStart(2, '0')}-01`,
                                        // max: props.ETA,
                                    }
                                }}
                            />

                            <Input
                                label="ATD (Actual)"
                                value={props.ATD}
                                onChange={(e) => props.setATD(e.target.value)}
                                disabled
                                type={'date'}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '12px' }}>
                                <Input
                                    label="Port of Loading"
                                    value={props.LoadingPortName && props.TempLoadingPortName && props.LoadingPortName.toLowerCase().includes(props.TempLoadingPortName.toLowerCase()) ? props.LoadingPortCode : ''}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '24px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.LoadingPortName}
                                    type='text'
                                    label={''}
                                    disabled
                                    onChange={(e) => handleChangeLocation('loading', e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        sx: {
                                            marginTop: '23px !important',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => AddPortOfLoading('delete')}
                                                    hidden={props.LoadingPortName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => AddPortOfLoading('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    label="Place of Delivery"
                                    value={props.DeliveryPlaceName && props.TempDeliveryPlaceName && props.DeliveryPlaceName.toLowerCase().includes(props.TempDeliveryPlaceName.toLowerCase()) ? props.DeliveryPlaceCode : ''}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '24px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.DeliveryPlaceName}
                                    type='text'
                                    label={''}
                                    disabled
                                    onChange={(e) => handleChangeLocation('delivery', e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        sx: {
                                            marginTop: '23px !important',
                                        },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => AddPlaceOfDelivery('delete')}
                                                    hidden={props.DeliveryPlaceName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => AddPlaceOfDelivery('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Input
                                label={"ETA (Current Period " + props.period + ")"}
                                value={props.ETA}
                                onChange={(e) => props.setETA(e.target.value)}
                                type={'date'}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label="ATA (Actual)"
                                value={props.ATA}
                                onChange={(e) => props.setATA(e.target.value)}
                                disabled
                                type={'date'}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid
                            container
                            spacing={'16px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={8}
                            >
                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '12px' }}>
                                    <Input
                                        label={'Place of Receipt'}
                                        value={props.ReceiptPlaceName && props.TempReceiptPlaceName && props.ReceiptPlaceName.toLowerCase().includes(props.TempReceiptPlaceName.toLowerCase()) ? props.ReceiptPlaceCode : ''}
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                        }}
                                        disabled
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.ReceiptPlaceName}
                                        type='text'
                                        label={''}
                                        disabled
                                        onChange={(e) => handleChangeLocation('receipt', e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => AddPlaceOfReceipt('delete')}
                                                        hidden={props.ReceiptPlaceName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => AddPlaceOfReceipt('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={8}
                            >
                                <Input
                                    label={"ETD (Current Period " + props.period + ")"}
                                    value={props.ETD}
                                    onChange={(e) => props.setETD(e.target.value)}
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '12px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={8}
                            >
                                <Input
                                    label="ATD (Actual)"
                                    value={props.ATD}
                                    onChange={(e) => props.setATD(e.target.value)}
                                    disabled
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '12px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'16px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={8}
                            >
                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label="Place of Delivery"
                                        value={props.DeliveryPlaceName && props.TempDeliveryPlaceName && props.DeliveryPlaceName.toLowerCase().includes(props.TempDeliveryPlaceName.toLowerCase()) ? props.DeliveryPlaceCode : ''}
                                        formControlProps={{
                                            sx: {
                                                marginTop: '24px'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                        }}
                                        disabled
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.DeliveryPlaceName}
                                        type='text'
                                        label={''}
                                        disabled
                                        onChange={(e) => handleChangeLocation('delivery', e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => AddPlaceOfDelivery('delete')}
                                                        hidden={props.DeliveryPlaceName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => AddPlaceOfDelivery('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={8}
                            >
                                <Input
                                    label={"ETA (Current Period " + props.period + ")"}
                                    value={props.ETA}
                                    onChange={(e) => props.setETA(e.target.value)}
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={8}
                            >
                                <Input
                                    label="ATA (Actual)"
                                    value={props.ATA}
                                    onChange={(e) => props.setATA(e.target.value)}
                                    disabled
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
            }

            <div style={{ marginTop: '16px' }}>
                {
                    props.jobType.portType === 'SEA' ?
                        <Box component="div">
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    marginTop: '8px'
                                }}
                            >
                                Add Vessel
                            </Typography>

                            <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                                <Table sx={{ minWidth: 650 }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ textAlign: 'right' }}>No</StyledTableCell>
                                            <StyledTableCell>Vessel</StyledTableCell>
                                            <StyledTableCell sx={{ textAlign: 'center' }}>Type</StyledTableCell>
                                            <StyledTableCell sx={{ textAlign: 'center' }}>Voyage</StyledTableCell>
                                            <StyledTableCell sx={{ textAlign: 'center' }}>ETD</StyledTableCell>
                                            <StyledTableCell>City</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            props.vesselData.length > 0 ?
                                                props.vesselData.map((el) => {
                                                    return (
                                                        <TableRow
                                                            key={el.sequence}
                                                            onClick={() => setSelectedDetail(el)}
                                                            onDoubleClick={() => ShowEditVessel()}
                                                            sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                            hover={true}
                                                        >
                                                            <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.sequence}</StyledTableBodyCell>
                                                            <StyledTableBodyCell>{el.vesselName}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.vesselType}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.voyage}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{new Date(el.etd).toLocaleDateString("id", { day: '2-digit', month: '2-digit', year: 'numeric' }).split('T')[0]}</StyledTableBodyCell>
                                                            <StyledTableBodyCell>{el.cityName}</StyledTableBodyCell>
                                                        </TableRow>
                                                    )
                                                })
                                                :
                                                <TableRow>
                                                    <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--Primary-One, #083A50)',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '12px',
                                                                fontstyle: 'normal',
                                                                fontWeight: '500',
                                                                lineHeight: 'normal'
                                                            }}
                                                        >
                                                            Data Empty
                                                        </Typography>
                                                    </StyledTableBodyCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div style={{ marginTop: '16px' }}>
                                {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddVessel, ShowEditVessel, DeleteVessel)}
                            </div>
                        </Box>
                        :
                        <Box component="div">
                            <Selection
                                id='0'
                                label={'Vessel / Flight'}
                                fullWidth={true}
                                placeholder={'Select Vessel / Flight'}
                                value={props.VesselFlight}
                                onChange={(e) => props.setVesselFlight(e.target.value)}
                            >
                                {
                                    dataVesselFlight.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                    ))
                                }
                            </Selection>

                            <div style={{ marginTop: '16px' }} hidden={props.VesselFlight === 'V' ? false : true}>
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        marginTop: '8px'
                                    }}
                                >
                                    Vessel Data
                                </Typography>

                                <Stack spacing={'16px'} direction="row">
                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.VesselName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchVessel('delete')}
                                                        hidden={props.vesselName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => searchVessel('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />

                                    <Input
                                        label="Voy."
                                        value={props.Voyage}
                                        onChange={(e) => props.setVoyage(e.target.value)}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                    />

                                    <Input
                                        label="Port"
                                        value={props.PortCode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%'
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.PortName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchPort('delete')}
                                                        hidden={props.PortName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => searchPort('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </div>

                            <div style={{ marginTop: '16px' }} hidden={props.VesselFlight === 'F' ? false : true}>
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        marginTop: '8px'
                                    }}
                                >
                                    Flight Data
                                </Typography>

                                <Stack spacing={'16px'} direction="row">
                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.FlightCode}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '50%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchFlight('delete')}
                                                        hidden={props.FlightCode ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => searchFlight('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />

                                    <Input
                                        value={props.FlightNo}
                                        onChange={(e) => props.setFlightNo(e.target.value)}
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                        }}
                                    />

                                    <Input
                                        label="Airport"
                                        value={props.PortCode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%'
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.PortName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchAirport('delete')}
                                                        hidden={props.PortName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => searchAirport('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </div>
                        </Box>
                }
            </div>
            
            {
                isModal2 && (
                    <ModalVessel
                        jobType={props.jobType}
                        getDateNow={getDateNow}
                        calculateTypeVessel={props.calculateTypeVessel}
                        isModal2={isModal2}
                        handleClick2={handleClick2}
                        titleModal2={titleModal2}
                        detailTypeId={props.detailTypeId}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        checkVessel={checkVessel}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
        
                        ShipmentId={props.ShipmentId}
                        setShipmentId={props.setShipmentId}
        
                        vesselData={props.vesselData}
                        setVesselData={props.setVesselData}
        
                        vesselId={vesselId}
                        setVesselId={setVesselId}
                        vesselName={vesselName}
                        setVesselName={setVesselName}
                        voyage={voyage}
                        setVoyage={setVoyage}
                        vesselType={vesselType}
                        setVesselType={setVesselType}
                        etd={etd}
                        setETD={setETD}
                        cityId={cityId}
                        setCityId={setCityId}
                        cityCode={cityCode}
                        setCityCode={setCityCode}
                        cityName={cityName}
                        setCityName={setCityName}
                    />
                )
            }
        </>
    )
}

export default TabVessel;