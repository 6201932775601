import {
  Box, InputAdornment, Grid, IconButton, MenuItem, Table, CardContent,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, styled, tableCellClasses, Card, Typography,
} from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { useFormik } from 'formik';

import ApiService from '../../../../services/ApiService';
import ModalSearchShipping from './components/ModalSearchShipping';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast, TickBox } from '../../../../components/ui';

const LiftingReport = forwardRef(() => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F2FAFF',
      color: 'var(--Primary-One, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '16px',
      fontstyle: 'normal',
      fontWeight: '700',
      lineHeight: 'normal',
      padding: '8px 16px !important',
    },
  }));

  const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      color: 'var(--Blue-Primary-1, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      padding: '0px 16px !important',
      height: '35px !important'
    },
  }));

  const date = new Date()

  const [jobTypeList, setJobTypeList] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedRow, setSelectedRow] = useState()
  const [selectedShipping, setSelectedShipping] = useState({})
  const [shippingTableData, setShippingTableData] = useState([])

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Lifting Report', link: '/Majura/LiftingReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Lifting Report - ' + JSON.parse(localStorage.getItem('branchName'));

    getAdditionalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getAdditionalData = async () => {
    try {
      const [resRJT] = await Promise.all([
        ApiService.PostByPage('regJobType/regJobType', 1, 999, []),
      ])

      if (resRJT?.data?.code === 200) {
        const tempData = resRJT.data.data.jobType
        const mapData = tempData.map((rjt) => {
          return { value: rjt.id, label: rjt.name }
        })

        formik.setFieldValue('jobType', mapData[0].value)

        setJobTypeList(mapData)
      } else {
        errorToast('job type')
      }

    } catch (error) {
      errorToast('additional')
    }
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handlePrint = (fileType) => {
    let payload = formik.values;
    console.log(fileType, payload)
    // TODO Print data based on form
  }

  const handleAddShipping = () => {
    if (!selectedShipping?.id) return showToast({ type: 'error', message: 'Please select shipping first.' })

    const arrShipping = [...shippingTableData]
    const isAlreadyThere = arrShipping.findIndex((dt) => dt.id === selectedShipping.id) !== -1
    if (!isAlreadyThere) {
      arrShipping.push(selectedShipping)
      setShippingTableData(arrShipping)
      setSelectedShipping({});
    } else {
      showToast({ type: 'info', message: 'Shipping already added to list.' })
    }
  }

  const handleDeleteShipping = () => {
    if (!selectedRow) return showToast({ type: 'error', message: 'Please select shipping row first.' })

    const arrShipping = [...shippingTableData].filter((x) => x.id !== selectedRow.id)
    setShippingTableData(arrShipping)
    setSelectedRow(undefined)
  }

  const toggleRow = (row) => {
    if (row?.id === selectedRow?.id) {
      setSelectedRow(undefined)
    } else {
      setSelectedRow(row)
    }
  }

  const disabledButton = () => {
    return !formik.values.isShipping || !selectedShipping
  }

  const formik = useFormik({
    initialValues: {
      jobType: '',
      startYear: date.getFullYear(),
      endYear: date.getFullYear(),
      isShipping: false,
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {ButtonComponent.PrintButton(() => handlePrint('pdf'), 'PDF')}
            {ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Print Excel')}
          </Grid>
        </div>
      </div>
    )
  }

  function handleChange(name, value) {
    formik.setFieldValue(name, !value);
    setSelectedShipping({})
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Lifting Report'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div className="mt-3">
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={6}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={'32px'}
                      >
                        <Grid
                          item
                          xl={6}
                          lg={6}
                          sm={6}
                          xs={6}
                        >
                          <Selection
                            label="Shipment Order"
                            placeholder={'Select Shipment Order'}
                            value={formik.values.jobType}
                            onChange={(e) => formik.setFieldValue('jobType', e.target.value)}
                            formControlProps={{
                              sx: {
                                width: '100%',
                              }
                            }}
                          >
                            {
                              jobTypeList.map((jt) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={jt.value} value={jt.value}>{jt.label}</MenuItem>
                              ))
                            }
                          </Selection>
                        </Grid>
                      </Grid>

                      <div style={{ marginTop: '24px' }}>
                        <Grid
                          container
                          spacing={'32px'}
                        >
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                          >
                            <Selection
                              label={'Start Period'}
                              placeholder={'Select Start Period'}
                              value={formik.values?.startYear}
                              onChange={(e) => formik.setFieldValue('startYear', e.target.value)}
                              formControlProps={{
                                sx: {
                                  width: '100%',
                                  MarginTop: '16px !important'
                                }
                              }}
                            >
                              {
                                Array.from({ length: (new Date().getFullYear() - 2002 + 1) }, (_, index) => new Date().getFullYear() - index).map((year) => (
                                  <MenuItem sx={{ fontSize: '12px' }} key={year} value={year}>{year}</MenuItem>
                                ))
                              }
                            </Selection>
                          </Grid>

                          <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                          >
                            <Selection
                              label={'End Period'}
                              placeholder={'Select End Period'}
                              value={formik.values?.endYear}
                              onChange={(e) => formik.setFieldValue('endYear', e.target.value)}
                              formControlProps={{
                                sx: {
                                  width: '100%',
                                  MarginTop: '16px !important'
                                }
                              }}
                            >
                              {
                                Array.from({ length: (new Date().getFullYear() - 2002 + 1) }, (_, index) => new Date().getFullYear() - index).map((year) => (
                                  <MenuItem sx={{ fontSize: '12px' }} key={year} value={year}>{year}</MenuItem>
                                ))
                              }
                            </Selection>
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <Grid
                        container
                        spacing={'32px'}
                      >
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          sm={12}
                          xs={12}
                        >
                          <TickBox
                            label='Is Shipping'
                            checked={formik.values.isShipping}
                            baseLabelProps={{ sx: { marginTop: '26px' } }}
                            onChange={() => handleChange('isShipping', formik.values.isShipping)}
                          />

                          <Input
                            placeholder='Search Shipping'
                            disabled={!formik.values.isShipping}
                            value={selectedShipping?.contactName || ''}
                            formControlProps={{ sx: { width: '100%', marginTop: '24px !important' } }}
                            inputBaseProps={{
                              sx: { marginTop: '23px !important' },
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setSelectedShipping({})}
                                    hidden={selectedShipping?.contactName ? false : true}
                                  >
                                    <Icon
                                      iconName={'close'}
                                      svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                          height: '12px',
                                          width: '12px'
                                        }
                                      }}
                                    />
                                  </IconButton>

                                  <IconButton
                                    onClick={toggleModal}
                                    hidden={!formik.values.isShipping}
                                  >
                                    <Icon
                                      iconName={'search'}
                                      svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                          height: '12px',
                                          width: '12px'
                                        }
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />

                          <TableContainer sx={{ maxHeight: 419, marginTop: '32px', background: '#FFF', borderRadius: '12px' }}>
                            <Table size='small' aria-label="a dense table" stickyHeader>
                              <TableHead
                                sx={{
                                  '& th:first-of-type': {
                                    borderRadius: '12px 0 0'
                                  },
                                  '& th:last-child': {
                                    borderRadius: '0 12px 0 0'
                                  }
                                }}
                              >
                                <TableRow>
                                  <StyledTableCell align='center'>Name</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  !shippingTableData.length ?
                                    (
                                      <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                          <Typography
                                            sx={{
                                              color: 'var(--Primary-One, #083A50)',
                                              fontFamily: 'Nunito',
                                              fontSize: '16px',
                                              fontstyle: 'normal',
                                              fontWeight: '500',
                                              lineHeight: 'normal'
                                            }}
                                          >
                                            Data Empty
                                          </Typography>
                                        </StyledTableBodyCell>
                                      </TableRow>
                                    )
                                    :
                                    (
                                      shippingTableData.map((row) => (
                                        <TableRow
                                          key={row.id}
                                          sx={{
                                            cursor: 'pointer',
                                            '&.Mui-selected': {
                                              bgcolor: '#CCCCCC',
                                              '&:hover': {
                                                bgcolor: '#CCCCCC'
                                              }
                                            }
                                          }}
                                          hover={true}
                                          onClick={() => toggleRow(row)}
                                          selected={selectedRow?.id === row.id}
                                        >
                                          <StyledTableBodyCell>{row.contactName}</StyledTableBodyCell>
                                        </TableRow>
                                      ))
                                    )
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <Grid container item direction="row" sx={{ marginTop: '24px' }}>
                            <Grid item xs={12} alignItems='right' className='text-end' display={'flex'} justifyContent={'flex-end'}>
                              {ButtonComponent.AddButton(handleAddShipping, '', disabledButton(), false, true)}

                              {ButtonComponent.DeleteButton(handleDeleteShipping, '', disabledButton() || !shippingTableData.length, false, true)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {isModalOpen && (
        <ModalSearchShipping
          isModal={isModalOpen}
          toggleModal={toggleModal}
          dataSelected={selectedShipping}
          onSelect={setSelectedShipping}
          errorToast={errorToast}
        />
      )}
    </>
  )
})

export default LiftingReport