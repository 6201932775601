import { Typography, Grid, FormControl, MenuItem, FormHelperText, Backdrop, FormGroup, ListSubheader, Stack } from '@mui/material';
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import ApiService from '../../../services/ApiService';
import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Icon, Input, PopUp, Selection, showToast, TickBox } from '../../../components/ui';

const CreateEditRegUserAccess = forwardRef(({ isModal, handleClick, selectedData, fetchData, numPage, rowsCount }, ref) => {
  const date = new Date()
  const tenYearsLater = new Date(new Date().setFullYear(date.getFullYear() + 10, date.getMonth(), date.getDate()))

  const [isLoading, setIsLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [listAccess, setListAccess] = useState([])
  const [listUserRole, setListUserRole] = useState([])
  const [listPermission, setListPermission] = useState([])
  const [listUserAccess, setListUserAccess] = useState([])

  useEffect(() => {
    if (isModal) {
      getAdditionalData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModal])

  useImperativeHandle(ref, () => ({
    localSave() { }
  }));

  const errorToast = (location, closeModal = false) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
    if (closeModal) handleClick()
  }

  const getAdditionalData = async () => {
    setIsLoading(true)

    try {
      const paramUR = [
        { attr: 'UserEntityId', value: String(selectedData?.id || 0) }
      ]
      const [resUR, resP] = await Promise.all([
        ApiService.IdentityPostByPage('regUserRole/regUserRole', 'PAGE', 1, 9999, paramUR),
        ApiService.IdentityPostByPage('permission/permission', 'PAGE', 1, 9999, []),
      ])

      if (resUR.data.code === 200) {
        const urData = resUR.data.data.userRole
        setListUserRole(urData)
      } else {
        errorToast('user role')
      }

      if (resP.data.code === 200) {
        const permissionData = resP.data.data.permission
        const filterData = permissionData.filter((x) => x.type === 'ITEM')
        const mapData = []
        filterData.forEach((elm) => {
          const index = mapData.findIndex((x) => x?.category === elm.categoryType)
          if (index === -1) {
            mapData.push({
              category: elm.categoryType,
              permissions: [{ id: elm.id, name: elm.name }]
            })
          } else {
            mapData[index].permissions.push({ id: elm.id, name: elm.name })
          }
        })

        setListPermission(mapData)
      } else {
        errorToast('permission')
      }

    } catch (error) {
      errorToast('additional')
    }

    setIsLoading(false)
  }

  const getAccessData = async (permissionId) => {
    setIsFetching(true)
    try {
      const paramsUA = [
        { attr: 'UserRoleId', value: String(formik.values.userRole) },
        { attr: 'PermissionId', value: String(permissionId) },
        { attr: 'IsUserAccess', value: "1" }
      ]

      const paramsA = [
        { attr: 'PermissionId', value: String(permissionId) },
      ]

      const [resA, resUA] = await Promise.all([
        ApiService.IdentityPostByPage('regAccess/regAccess', 'PAGE', 1, 9999, paramsA),
        ApiService.IdentityPostByPage('regUserAccess/regUserAccess', 'PAGE', 1, 9999, paramsUA),
      ])

      if (resA.data.code === 200) {
        setListAccess(resA.data.data.access)
      } else {
        errorToast('access permission')
      }

      if (resUA.data.code === 200) {
        const userAccess = resUA.data.data.userAccess
        const validOn = userAccess?.[0]?.validOn || date.toLocaleDateString("sv-se")
        const validTo = userAccess?.[0]?.validTo || tenYearsLater.toLocaleDateString("sv-se")
        setListUserAccess(userAccess)

        formik.setFieldValue('validOn', validOn.split('T')[0])
        formik.setFieldValue('validTo', validTo.split('T')[0])
      } else {
        errorToast('user access')
      }

    } catch (error) {
      console.log(error)
      errorToast('access')
    }

    setIsFetching(false)
  }

  const resetField = () => {
    formik.setFieldValue('permission', 0)
    setListAccess([])
    setListUserAccess([])
  }

  const renderSelectionGroup = (data) => {
    const items = data.permissions.map((item) => {
      return (
        <MenuItem sx={{ fontSize: '12px' }}
          key={item.id}
          value={item.id}
        >
          {item.name}
        </MenuItem>
      )
    })

    return [<ListSubheader sx={{ bgcolor: '#4196d6', color: '#FFF' }}>{data.category}</ListSubheader>, items]
  }

  const handleChangeAccess = (access, hasAccess) => {
    let updatedUserAccess = [...listUserAccess]
    if (hasAccess) {
      updatedUserAccess.push({ ...access, hidden: false })
    } else {
      updatedUserAccess = updatedUserAccess.filter((x) => x.accessId !== access.accessId)
    }

    setListUserAccess(updatedUserAccess)
  }

  const handleHiddenAccess = (access, isHidden) => {
    const indexAccess = listUserAccess.findIndex((x) => x.accessId === access.accessId)
    const updatedUserAccess = [...listUserAccess]
    if (indexAccess !== -1) {
      updatedUserAccess[indexAccess].hidden = isHidden
    }

    setListUserAccess(updatedUserAccess)
  }

  const validationSchema = Yup.object().shape({
    userRole: Yup.number().min(1, 'This field is required.').required('This field is required.'),
    permission: Yup.number().min(1, 'This field is required.').required('This field is required.'),
    validOn: Yup.date().required('This field is required.'),
    validTo: Yup.date().required('This field is required.'),
  })

  const formik = useFormik({
    initialValues: {
      userRole: '',
      permission: '',
      validOn: date.toLocaleDateString("sv-se").split('T')[0],
      validTo: tenYearsLater.toLocaleDateString("sv-se").split('T')[0],
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values)
  })

  const handleSubmit = async (payload) => {
    if (!listAccess.length) return showToast({ type: 'error', message: "Selected permission doesn't have access." })
    // setIsSubmitting(true)

    try {
      const data = []
      if (!listUserAccess?.length) {
        // * Remove user access according to roleId & permissionId
        // ** When no access is selected in permission
        data.push({
          rowStatus: 'DEL',
          permissionId: payload?.permission || 0,
          userRoleId: payload?.userRole || 0,
        })
      } else {
        listUserAccess.forEach((elm) => {
          data.push({
            rowStatus: 'ACT',
            permissionId: payload?.permission || 0,
            userRoleId: payload?.userRole || 0,
            accessId: elm.accessId,
            accessCode: elm.accessCode,
            validOn: payload?.validOn || date.toLocaleDateString("sv-se").split('T')[0],
            validTo: payload?.validTo || tenYearsLater.toLocaleDateString("sv-se").split('T')[0],
            hidden: elm.hidden,
          })
        })
      }
      const res = await ApiService.IdentityCreateData('regUserAccess/regUserAccess', { userAccess: data })
      if (res?.data?.code === 200) {
        showToast({ type: 'success', message: 'User access have been successfully saved.' })
        fetchData(numPage, rowsCount)
      } else {
        showToast({ type: 'error', message: 'Can`t save user access, please try again.' })
      }
    } catch (error) {
      console.log(error)
    }

    setIsSubmitting(false)
  }

  const mainContainer = (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={[3, 2]}>
        <Grid item xs={6}>
          <Selection
            fullWidth={true}
            label="User Role"
            placeholder={'Select User Role'}
            disabled={isSubmitting}
            value={formik.values.userRole}
            error={formik.touched.userRole && Boolean(formik.errors.userRole)}
            onChange={(e) => {
              formik.setFieldValue("userRole", e.target.value)
              resetField()
            }}
          >
            {
              listUserRole.map((ur) => (
                <MenuItem sx={{ fontSize: '12px' }} key={ur.id} value={ur.id}>{ur.roleName}</MenuItem>
              ))
            }
            <FormHelperText>{formik.touched.userRole && formik.errors.userRole}</FormHelperText>
          </Selection>
        </Grid>

        <Grid item xs={6}>
          <Selection
            fullWidth={true}
            label="Permission"
            placeholder={'Select Permission'}
            disabled={isSubmitting || !formik.values.userRole}
            value={formik.values.permission}
            error={formik.touched.permission && Boolean(formik.errors.permission)}
            onChange={(e) => {
              formik.setFieldValue('permission', e.target.value)
              getAccessData(e.target.value)
            }}
          >
            {listPermission.map((elm) => renderSelectionGroup(elm))}
            <FormHelperText>{formik.touched.permission && formik.errors.permission}</FormHelperText>
          </Selection>
        </Grid>

        <Grid item xs={12}>
          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '20px',
              marginTop: '6px',
              marginBottom: '12px'
            }}
          >
            User Access Permission
          </Typography>

          {isFetching && (
            <LoadingSpinner style={{ height: '200px' }} />
          )}

          {(!isFetching && Boolean(listAccess?.length)) && (
            <>
              <Stack spacing={3} direction={'row'}>
                <FormControl fullWidth>
                  <Typography
                    sx={{
                      color: 'var(--Blue-Primary-1, #083A50)',
                      fontFamily: 'Nunito',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '20px',
                      marginTop: '6px',
                      marginBottom: '6px'
                    }}
                  >
                    Access
                  </Typography>
                  <FormGroup>
                    {listAccess.map((access, index) => (
                      <TickBox
                        key={index}
                        size={'small'}
                        label={
                          <Typography
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '500',
                              lineHeight: '20px',
                            }}
                          >
                            {access.accessName}
                          </Typography>
                        }
                        disabled={isSubmitting}
                        checked={listUserAccess.findIndex((el) => access.accessId === el.accessId) !== -1}
                        onChange={(e) => handleChangeAccess(access, e.target.checked)}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
                <FormControl fullWidth sx={{ textAlign: 'center' }}>
                  <Typography
                    sx={{
                      color: 'var(--Blue-Primary-1, #083A50)',
                      fontFamily: 'Nunito',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '20px',
                      marginTop: '6px',
                      marginBottom: '6px'
                    }}
                  >
                    Hidden
                  </Typography>
                  <FormGroup sx={{ alignItems: 'center' }}>
                    {listAccess.map((access) => (
                      <TickBox
                        size={'small'}
                        key={access.accessId}
                        checked={listUserAccess.find((el) => access.accessId === el.accessId)?.hidden}
                        baseCheckboxProps={{
                          sx: {
                            marginLeft: '20px'
                          }
                        }}
                        onChange={(e) => handleHiddenAccess(access, e.target.checked)}
                        disabled={(listUserAccess.findIndex((el) => access.accessId === el.accessId) === -1) || isSubmitting}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Stack>
            </>
          )}

          {(!isFetching && !listAccess.length) && (
            <Typography
              variant='caption'
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'200px'}
            >
              {!formik.values.permission ? 'Please select user role and permission.' : "This permission doesn't have access."}
            </Typography>
          )}
        </Grid>

        <Grid item xs={6}>
          <Input
            type='date'
            label="Valid On"
            value={formik.values.validOn}
            onChange={(e) => { formik.setFieldValue(`validOn`, e.target.value) }}
            onBlur={formik.handleBlur}
            error={formik.touched.validOn && Boolean(formik.errors.validOn)}
            helperText={formik.touched.validOn && formik.errors.validOn}
            disabled={isSubmitting}
            formControlProps={{
              sx: {
                width: '100%',
                // marginTop: '16px'
              }
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <Input
            type='date'
            label="Valid To"
            value={formik.values.validTo}
            onChange={(e) => { formik.setFieldValue(`validTo`, e.target.value) }}
            onBlur={formik.handleBlur}
            error={formik.touched.validTo && Boolean(formik.errors.validTo)}
            helperText={formik.touched.validTo && formik.errors.validTo}
            disabled={isSubmitting}
            formControlProps={{
              sx: {
                width: '100%',
                // marginTop: '16px'
              }
            }}
          />
        </Grid>
      </Grid>
    </form>
  )

  return (
    <PopUp
      id='modal-id'
      icon={
        <Icon
          iconName={'account'}
          svgIconProps={{
            htmlColor: '#0087C2',
            sx: {
              height: '24px',
              width: '24px'
            }
          }}
        />
      }
      title={'Edit User Access - ' + selectedData?.userName}
      isOpen={isModal}
      onClose={handleClick}
      draggable={false}
      shrinkable={false}
      disableScrollLock={false}
      maxWidth={'md'}
      fullWidth={true}
    >
      <div style={{ padding: '24px 6px 16px' }}>
        {mainContainer}

        <div style={{ marginTop: '32px' }}>
          {CustomizeButtonComponent.SaveCancelMasterModal(() => handleSubmit(formik.values), isSubmitting)}
        </div>
      </div>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner color="inherit" />
          </Backdrop>
        )
      }
    </PopUp>
  )
})

export default CreateEditRegUserAccess