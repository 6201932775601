import {
    Box,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useState } from 'react';
import { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';

import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import ModalReceiptVoucherDetail from '../Modals/ModalReceiptVoucherDetail';
import ModalDelete from '../../../../../components/Modals/ModalDelete';
import { defaultDate } from '../../../../../utils/date';
import { showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const ReceiptVoucherDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };
    const [size, setSize] = useState('82%');
    const [isDisable, setIsDisable] = useState(false);
    const [isGeneral, setIsGeneral] = useState(true);

    const [description, setDescription] = useState('');
    const [dcNote, setDCNote] = useState('C');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [amountUSD, setAmountUSD] = useState(0);
    const [amountIDR, setAmountIDR] = useState(0);
    const [refId, setRefId] = useState(0);
    const [refDetailId, setRefDetailId] = useState(0);
    const [refNo, setRefNo] = useState('');
    const [shipmentId, setShipmentId] = useState(0);
    const [shipmentNo, setShipmentNo] = useState('');
    const [exRateId, setExRateId] = useState(0);
    const [refRate, setRefRate] = useState(0);
    const [refAccountCode, setRefAccountCode] = useState('');
    const [refAccountName, setRefAccountName] = useState('');
    const [refCustomerId, setRefCustomerId] = useState(0);
    const [refCustomerName, setRefCustomerName] = useState('');

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const emptyState = () => {
        setDescription('');
        setDCNote('C');
        setAmountUSD(0);
        setAmountIDR(0);
        setRefId(0);
        setRefNo('');
        setShipmentId(0);
        setShipmentNo('');
        setRefAccountCode('');
        setRefAccountName('');
        setRefCustomerId(0);
        setRefCustomerName('');
        setExRateId(0);
        setRefRate(0);
        setPaymentMethod('');
    };

    const ShowAddReceiptDetail = () => {
        let check = props.checkInput();
        emptyState();

        if (check === 0) {
            setTitleModal('Add Receipt Voucher');
            handleBoxSize();
            handleCheckGeneral();
            handleDisable();
            setDetailEdit(false);
            setRefCustomerId(props.ContactId);
            setExRateId(props.ExRateId);
            setRefCustomerName(props.ContactName);
            if (props.DataReceiptVoucher.length > 0) {
                let data = props.DataReceiptVoucher.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                setDetailSequence(data.sequence);
            }
            else {
                setDetailSequence(0);
            }
            handleClick();
        }
    };

    const ShowEditReceiptDetail = () => {
        emptyState();
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setTitleModal('Edit Receipt Voucher');
                handleBoxSize();
                handleDisable();
                handleCheckGeneral();
                setDetailEdit(true);

                setDescription(selectedDetail.description);
                setDCNote(selectedDetail.dcNote);
                setAmountUSD(selectedDetail.amountUSD);
                setAmountIDR(selectedDetail.amountIDR);
                setRefId(selectedDetail.refId);
                setRefNo(selectedDetail.refNo);
                setPaymentMethod(selectedDetail.paymentMethod);
                setRefAccountCode(selectedDetail.coaCode);
                setRefAccountName(selectedDetail.coaName);
                setShipmentId(selectedDetail.shipmentId);
                setShipmentNo(selectedDetail.shipmentNo);
                setRefCustomerId(selectedDetail.customerId);
                setRefCustomerName(selectedDetail.customerName);
                setExRateId(selectedDetail.exRateId);
                setRefRate(selectedDetail.refRate);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    }

    const DeleteDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;
            let tempRefId = selectedDetail.refId;
            let tempIsBack = selectedDetail.isStored;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const deleteFunctionRef = (data) => {
                return data.refId !== tempRefId;
            }

            const deleteFunctionId = (data) => {
                return data !== tempRefId;
            }

            const result = props.DataReceiptVoucher.filter(deleteFunction);

            let lastResult = result.filter(deleteFunctionRef);

            const resultId = props.DataReceiptVoucherId.filter(deleteFunctionId);

            tempSequence = 0;

            if (lastResult.length > 0) {
                lastResult.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                    if (props.ReceiptBy !== 'G' && tempIsBack === true) {
                        el.isStored = true;
                    }
                })
            }

            setDetailSequence(tempSequence);

            if (tempIsBack === true) {
                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "userCode": userCode,

                    "rowStatus": "ACT",
                    "sequence": 0,
                    "id": 0,
                    "rvId": 0,
                    "rvNo": '',
                    "description": '',
                    "dcNote": '',
                    "amountUSD": 0,
                    "amountIDR": 0,
                    "receiptCashUSD": 0,
                    "receiptCashIDR": 0,
                    "refId": tempRefId,
                    "refDetailId": 0,
                    "refNo": '',
                    "coaCode": '',
                    "coaName": '',
                    "shipmentOrderId": 0,
                    "shipmentNo": '',
                    "customerId": 0,
                    "customerName": '',
                    "exRateId": 0,
                    "refRate": 0,
                    "linkDetail": 0,
                    "paidOn": defaultDate,
                    "paymentMethod": '',
                    "saveOR": false,
                    "seqDetail": 0,
                    "tandaMigrasi": 0,
                    "isStored": false,

                    "idLama": 0,
                    "receiptVoucherDetailBanks": [],
                }

                lastResult = [...lastResult, data];

                const deleteFunctionSeq = (data) => {
                    return data.sequence !== 0;
                }

                lastResult = lastResult.filter(deleteFunctionSeq);
            }

            props.setDataReceiptVoucher(lastResult);
            props.setDataReceiptVoucherId(resultId);
            props.calculateTotalDebitCredit(lastResult, props.Rate);

            props.setBankData([]);
            props.setCashPaymentUSD(0);
            props.setCashPaymentIDR(0);

            setSelectedDetail({});
            handleClickDelete();
        }
    }

    function handleSelectedDetail(val) {
        setSelectedDetail(val);
        props.setSelectedReceiptVoucher(val);
        props.setBankData(val.receiptVoucherDetailBanks);
        props.setCashPaymentUSD(val.receiptCashUSD);
        props.setCashPaymentIDR(val.receiptCashIDR);
    }

    function handleBoxSize() {
        if (props.CustomerTo === 0) {
            setSize('52%');
        }
        else {
            setSize('73%');
        }
    }

    function handleDisable() {
        if (props.CustomerTo === 0) {
            setIsDisable(true);
        }
        else {
            setIsDisable(true);
        }
    }

    function handleCheckGeneral() {
        if (props.ReceiptBy === 'G') {
            setIsGeneral(true);
            setRefRate(props.RateReal);
        }
        else {
            setIsGeneral(false);
        }
    }

    const handleClickDelete = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const DeleteReceiptDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleModalDelete('Receipt Voucher Detail');
            handleClickDelete();
        }
    }

    const handleDoubleClick = () => {
        ShowEditReceiptDetail();
    }

    return (
        <>
            <div>
                <form>
                    <Box>
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                            }}
                        >
                            Receipt Voucher Detail
                        </Typography>

                        <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px', marginTop: '16px' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>Account Code</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>DC Note</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right' }}>Amount USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right' }}>Amount IDR</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>Ref No</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>Shipment Number</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right' }}>Rate</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.DataReceiptVoucher.length > 0 ?
                                            props.DataReceiptVoucher.map((el) => {
                                                return (
                                                    el.sequence !== 0 ?
                                                        <TableRow
                                                            key={el.sequence}
                                                            onClick={() => handleSelectedDetail(el)}
                                                            onDoubleClick={() => handleDoubleClick()}
                                                            sx={
                                                                selectedDetail.sequence === el.sequence ?
                                                                    selectedStyle
                                                                    :
                                                                    el.rowStatus === 'DEL' ?
                                                                        deletedDetailStyle
                                                                        :
                                                                        {}
                                                            }
                                                            hover={true}
                                                        >
                                                            <StyledTableBodyCell style={{ textAlign: 'center' }}>
                                                                <Tooltip title={el.coaName}>
                                                                    <span>{el.coaCode}</span>
                                                                </Tooltip>
                                                            </StyledTableBodyCell>
                                                            <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.dcNote === 'D' ? 'Debit' : 'Credit'}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.amountUSD)}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.amountIDR)}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.refId !== 0 ? el.refNo : '-'}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.shipmentOrderId !== 0 ? el.shipmentNo : '-'}</StyledTableBodyCell>
                                                            <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.refRate)}</StyledTableBodyCell>
                                                        </TableRow>
                                                        :
                                                        <></>
                                                )
                                            })
                                            :
                                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal'
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div style={{ marginTop: '16px' }}>
                            {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddReceiptDetail, ShowEditReceiptDetail, DeleteReceiptDetail, props.isApprovedAcc)}
                        </div>
                    </Box>
                </form>
            </div>

            {
                isModal && (
                    <ModalReceiptVoucherDetail
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        size={size}
                        isDisable={isDisable}
                        isGeneral={isGeneral}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        Rate={props.Rate}
                        RVNo={props.RVNo}
                        ContactId={props.ContactId}
                        calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                        description={description}
                        setDescription={setDescription}
                        dcNote={dcNote}
                        setDCNote={setDCNote}
                        amountUSD={amountUSD}
                        setAmountUSD={setAmountUSD}
                        amountIDR={amountIDR}
                        setAmountIDR={setAmountIDR}
                        refId={refId}
                        setRefId={setRefId}
                        refDetailId={refDetailId}
                        setRefDetailId={setRefDetailId}
                        refNo={refNo}
                        setRefNo={setRefNo}
                        shipmentId={shipmentId}
                        setShipmentId={setShipmentId}
                        shipmentNo={shipmentNo}
                        setShipmentNo={setShipmentNo}
                        exRateId={exRateId}
                        setExRateId={setExRateId}
                        refRate={refRate}
                        setRefRate={setRefRate}
                        refAccountCode={refAccountCode}
                        setRefAccountCode={setRefAccountCode}
                        refAccountName={refAccountName}
                        setRefAccountName={setRefAccountName}
                        refCustomerId={refCustomerId}
                        setRefCustomerId={setRefCustomerId}
                        refCustomerName={refCustomerName}
                        setRefCustomerName={setRefCustomerName}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        DataReceiptVoucher={props.DataReceiptVoucher}
                        setDataReceiptVoucher={props.setDataReceiptVoucher}
                        CustomerTo={props.CustomerTo}
                        ReceiptBy={props.ReceiptBy}
                        isApprovedAcc={props.isApprovedAcc}
                        DataReceiptVoucherId={props.DataReceiptVoucherId}
                        setDataReceiptVoucherId={props.setDataReceiptVoucherId}
                    />
                )
            }

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClickDelete}
                        DeleteData={DeleteDetail}
                    />
                )
            }
        </>
    )
}

export default ReceiptVoucherDetail;