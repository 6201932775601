import { Grid } from '@mui/material';
import React from 'react';
import { branchId, companyId, countryId, userId, userCode } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Input, PopUp, showToast } from '../../../../components/ui';

const ModalConNote = (props) => {
    const handleClose = () => {
        emptyState();
        props.handleClick2();
    };

    const emptyState = () => {
        props.setConNote('');
        props.setCommodity('');
        props.setCollie(0);
        props.setWeight(0);
        props.setDimensionLong(0);
        props.setDimensionWide(0);
        props.setDimensionHigh(0);
        props.setCBM(0);
    };

    const handleSave = () => {
        if (props.setConNote === '') {
            showToast({ type: 'error', message: 'Please Input Con Note!' });
        }
        else {
            if (!props.weight) {
                showToast({ type: 'error', message: 'Please Input Weight!' });
            }
            else {
                if (!props.collie) {
                    showToast({ type: 'error', message: 'Please Input Collie!' });
                }
                else {
                    if (!props.cbm) {
                        showToast({ type: 'error', message: 'Please Input CBM!' });
                    }
                    else {
                        if (!props.dimensionLong) {
                            showToast({ type: 'error', message: 'Please Input Dimension Long!' });
                        }
                        else {
                            if (!props.dimensionWide) {
                                showToast({ type: 'error', message: 'Please Input Dimension Wide!' });
                            }
                            else {
                                if (!props.dimensionHigh) {
                                    showToast({ type: 'error', message: 'Please Input Dimension High!' });
                                }
                                else {
                                    if (!props.commodity) {
                                        showToast({ type: 'error', message: 'Please Input Commodity!' });
                                    }
                                    else {
                                        let Sequence = 1;

                                        if (props.detailEdit) {
                                            Sequence = props.selectedDetail.sequence;
                                        }
                                        else {
                                            if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                                        }

                                        let data = {
                                            "countryId": countryId,
                                            "companyId": companyId,
                                            "branchId": branchId,
                                            "userId": userId,
                                            "userCode": userCode,

                                            "rowStatus": "ACT",
                                            "shipmentId": props.ShipmentId,
                                            "sequence": Sequence,
                                            "conNote": props.conNote,
                                            "commodity": props.commodity,
                                            "collie": props.collie,
                                            "weight": props.weight,
                                            "dimensionLong": props.dimensionLong,
                                            "dimensionWide": props.dimensionWide,
                                            "dimensionHigh": props.dimensionHigh,
                                            "cbm": props.cbm,
                                            "idLama": 0,
                                        }

                                        if (!props.detailEdit) {
                                            props.setConNoteData([...props.ConNoteData, data]);
                                        }
                                        else {
                                            const newArr = props.ConNoteData.slice();
                                            newArr.forEach(el => {
                                                if (el.sequence === data.sequence) {
                                                    el.conNote = data.conNote;
                                                    el.commodity = data.commodity;
                                                    el.collie = data.collie;
                                                    el.weight = data.weight;
                                                    el.dimensionLong = data.dimensionLong;
                                                    el.dimensionWide = data.dimensionWide;
                                                    el.dimensionHigh = data.dimensionHigh;
                                                    el.cbm = data.cbm;
                                                }
                                            })
                                            props.setConNoteData(newArr);
                                        }

                                        emptyState();
                                        handleClose();
                                        props.setDetailEdit(false);
                                        props.setSelectedDetail({});
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Con. Note"
                        value={props.conNote}
                        onChange={(e) => props.setConNote(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label="Collie"
                        textAlign='right'
                        value={props.collie}
                        onChange={(e) => props.setCollie(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Weight"
                        textAlign='right'
                        value={props.weight}
                        onChange={(e) => props.setWeight(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label="CBM"
                        textAlign='right'
                        value={props.cbm}
                        onChange={(e) => props.setCBM(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label="Dimensi Long (Panjang)"
                        textAlign='right'
                        value={props.dimensionLong}
                        onChange={(e) => props.setDimensionLong(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label="Dimensi Wide (Lebar)"
                        textAlign='right'
                        value={props.dimensionWide}
                        onChange={(e) => props.setDimensionWide(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label="Dimensi High (Tinggi)"
                        textAlign='right'
                        value={props.dimensionHigh}
                        onChange={(e) => props.setDimensionHigh(e.target.value)}
                        type={'number'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                label="Commodity"
                value={props.commodity}
                onChange={(e) => props.setCommodity(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal2}
                isOpen={props.isModal2}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalConNote;