import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

const ButtonPaymentRequest = ({
    ReloadData,
    DataRegUserAccess,
    AddPR,
    EditPR,
    DeleteData,
    Print,
    handleApprove,
    HandleStatus,
    handleApproveGen,
    DropDownTitle,
    DropDownList,
    handleChangePOV,
    accessFin,
    handleUnApprove,
}) => {

    const renderButtonByAccessCode = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddPR, 'New');
            case 'REI':
                return ButtonComponent.EditButton(EditPR, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data') : null;
            case 'PRI':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.PrintButton(Print, 'PR') : null;
            case 'APP':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All') ?
                    <>
                        {ButtonComponent.ApproveButton(handleApprove, 'Approve By Acc Mgr')}
                        {ButtonComponent.UnApprovalButton(handleUnApprove, 'Open Approval By Acc Mgr')}
                    </>
                    :
                    null;
            case 'APG':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All') ?
                    ButtonComponent.ApproveButton(handleApproveGen, 'Approve General PR') : null;
            case 'APV':
                return ButtonComponent.ViewButton(handleChangePOV, accessFin ? 'PR Operation' : 'PR Accounting');
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButtonByAccessCode(data.accessCode)}
                        </React.Fragment>
                    ))}
                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonPaymentRequest;