import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Backdrop, Grid } from '@mui/material';

import TempListMaster from '../TempListMaster';
import ApiService, { username } from '../../../services/ApiService';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CreateEditChartOfAccountBranch from './CreateEditChartOfAccountBranch';
import CustomToastContainer, { showToast } from '../../../components/ui';

const ListChartOfAccountBranch = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [totalRows, setTotalRows] = useState(50)
    const [columnData, setColumnData] = useState([])
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [legacyCode, setLegacyCode] = useState('')
    const [accountCode, setAccountCode] = useState('')
    const [accountTitle, setAccountTitle] = useState('')
    const [accountGroup, setAccountGroup] = useState(0)
    const [accountLevel, setAccountLevel] = useState(0)
    const [accountParent, setAccountParent] = useState('')
    const [accountParentTitle, setAccountParentTitle] = useState('')
    const [bank, setBank] = useState(true)
    const [coaStandardId, setCOAStandardId] = useState(0)
    const [coaStandardTitle, setCOAStandardTitle] = useState('')
    const [bankId, setBankId] = useState(0)
    const [bankCode, setBankCode] = useState('')
    const [bankName, setBankName] = useState('')
    const [parentId, setParentId] = useState(0)

    const [SelectedData, setSelectedData] = useState({});
    const param = 'RegChartOfAccountBranch/RegChartOfAccountBranch'
    const [data, setData] = useState([]);
    const title = 'Chart of Account Branch'
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    let countRefresh = 0;

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataChartOfAccountCabang"));

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchDataLocal = () => {
        const result = dataLocal.RegChartOfAccountBranchGroup;
        setTitleModal(result.titleModal);
        setSelectedData(result)

        setIsModal(true);
        localStorage.removeItem(username + '-DataChartOfAccountCabang')
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.ListMasterDynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({});
                    setData(response.data.data.chartOfAccountBranch);

                    let temp = response.data.data.chartOfAccountBranch;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    });

                    setDataMap(indexed);
                    setColumnData(response.data.data.columns);
                    setTotalRows(response.data.totalRowCount);
                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            emptyState();
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
            setSelectedData({})
        }
    };

    const emptyState = () => {
        setLegacyCode('');
        setAccountCode('');
        setAccountTitle('');
        setAccountGroup(0);
        setAccountLevel(0);
        setAccountParent('');
        setAccountParentTitle('');
        setBank(false);
        setStateX({});
        setFilter([]);
        setFilterBy([]);
        setCOAStandardId(0);
        setCOAStandardTitle('');
        setBankId(0);
        setBankCode('');
        setBankName('');
    }

    const toggleAdd = () => {
        if (SelectedData?.id) {
            setSelectedData({})
        }
        emptyState();
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            fetchEditData(SelectedData.id);
            setIsModal(true);
            setTitleModal('Edit');
        }
    }

    const fetchEditData = (coaId) => {
        ApiService.PostById('RegChartOfAccountBranch/RegChartOfAccountBranch', coaId)
            .then(response => {
                const data = response.data.data.chartOfAccountBranch ? response.data.data.chartOfAccountBranch : '';
                const dataStandard = response.data.data.chartOfAccountStandard !== null ? response.data.data.chartOfAccountStandard : '';
                const dataBank = response.data.data.bank !== null ? response.data.data.bank : '';
                console.log(dataBank)
                setLegacyCode(data.legacyCode)
                setAccountCode(data.accountCode)
                setAccountTitle(data.accountTitle)
                setAccountGroup(data.accountGroup)
                setAccountLevel(data.accountLevel)
                setAccountParent(data.accountParent)

                if (dataBank?.id !== 0 && dataBank?.id !== undefined) {
                    setBank(true)
                    fetchBankData(dataBank.id)
                }
                else {
                    setBank(false)
                }

                if (dataStandard?.id !== 0 && dataStandard?.id !== undefined) {
                    fetchStandardAccountTitle(dataStandard.id);
                    setCOAStandardId(dataStandard.id);
                }

                if (data.parentId !== 0 && data.accountLevel > 0) {
                    fetchParentAccountTitle(data.parentId);
                    setParentId(data.parentId);
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const fetchBankData = (bankId) => {
        ApiService.PostById('Bank/Bank', bankId)
            .then(response => {
                const data = response.data.data.bank;
                if (data) {
                    setBankCode(data.masterCode + ' - ' + data.intName)
                    setBankName(data.name)
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const fetchParentAccountTitle = (COAParentId) => {
        ApiService.PostById('RegChartOfAccountBranch/RegChartOfAccountBranch', COAParentId)
            .then(response => {
                const data = response.data.data.chartOfAccountBranch;
                if (data) {
                    setAccountParentTitle(data.accountCode + ' - ' + data.accountTitle)
                    setAccountParent(data.accountCode)
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const fetchStandardAccountTitle = (coaStandardId) => {
        ApiService.PostById('chartOfAccount/chartOfAccount', coaStandardId)
            .then(response => {
                const data = response.data.data.chartOfAccount;
                if (data) {
                    setCOAStandardTitle(data.accountCode + ' - ' + data.accountTitle)
                    setAccountParent(data.accountCode)
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    function CellDoubleClick(row) {
        toggleEdit()
    }

    const handleReset = () => {
        emptyState();
        getData(1, 50, [], []);
    }

    return (
        <>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <TempListMaster
                    SelectedData={SelectedData}
                    param={param}
                    data={data}
                    title={title}
                    isLoading={isLoading}
                    getData={getData}
                    columnData={columnData}
                    dataMap={dataMap}
                    setSelectedData={setSelectedData}
                    CellDoubleClick={CellDoubleClick}
                    totalRows={totalRows}
                    toggleAdd={toggleAdd}
                    toggleEdit={toggleEdit}
                    rowsCount={rowsCount}
                    setRowsCount={setRowsCount}
                    numPage={numPage}
                    setNumPage={setNumPage}
                    filter={filter}
                    setFilter={setFilter}
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    stateX={stateX}
                    setStateX={setStateX}
                    menuCode={'MRCOA'}
                    menuName={'MASTER'}
                    sortArr={sortArr}
                    setSortArr={setSortArr}
                />
            </Grid>

            {isModal && (
                <CreateEditChartOfAccountBranch
                    ref={ref}
                    isModal={isModal}
                    handleClick={handleClick}
                    titleModal={titleModal}
                    title={title}
                    param={param}

                    getData={getData}

                    legacyCode={legacyCode}
                    setLegacyCode={setLegacyCode}
                    accountCode={accountCode}
                    setAccountCode={setAccountCode}
                    accountTitle={accountTitle}
                    setAccountTitle={setAccountTitle}
                    accountGroup={accountGroup}
                    setAccountGroup={setAccountGroup}
                    accountLevel={accountLevel}
                    setAccountLevel={setAccountLevel}
                    accountParent={accountParent}
                    setAccountParent={setAccountParent}
                    accountParentTitle={accountParentTitle}
                    setAccountParentTitle={setAccountParentTitle}
                    bank={bank}
                    coaId={SelectedData?.id ? SelectedData.id : 0}
                    setBank={setBank}

                    coaStandardId={coaStandardId}
                    setCOAStandardId={setCOAStandardId}
                    coaStandardTitle={coaStandardTitle}
                    setCOAStandardTitle={setCOAStandardTitle}
                    bankId={bankId}
                    setBankId={setBankId}
                    bankCode={bankCode}
                    setBankCode={setBankCode}
                    bankName={bankName}
                    setBankName={setBankName}
                    parentId={parentId}
                    setParentId={setParentId}
                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                    handleReset={handleReset}
                />
            )}

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 100000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListChartOfAccountBranch