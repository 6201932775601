import React from 'react';

import { userCode } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Input, PopUp, showToast } from '../../../../components/ui';

const ModalBankAccount = (props) => {
    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setAccountNo('');
        props.setAccountBankName('');
        props.setAccountName('');
    };

    const handleSave = () => {
        if (!props.AccountNo) {
            showToast({ type: 'error', message: 'Please Input Account No' })
        }
        else {
            if (!props.AccountBankName) {
                showToast({ type: 'error', message: 'Please Input Account Bank Name' })
            }
            else {
                if (!props.AccountName) {
                    showToast({ type: 'error', message: 'Please Input Account Name' })
                }
                else {
                    let check = 0;

                    let data = {
                        "userCode": userCode,

                        "rowStatus": "ACT",
                        "contactId": props.contactId,
                        "accNo": props.AccountNo,
                        "bankName": props.AccountBankName,
                        "accName": props.AccountName,

                        "idLama": 0,
                    }

                    props.listBankAccount.forEach(element => {
                        if (element.accNo === data.accNo) {
                            check = 1;
                        }
                    });

                    if (check === 0) {
                        props.setListBankAccount([...props.listBankAccount, data]);

                        emptyState();
                        handleClose();
                        props.setSelectedDetail({});
                    }
                    else {
                        showToast({ type: 'error', message: 'Account No Must Be Unique!' })
                    }
                }
            }
        }
    }

    const mainContainer = (
        <>
            <Input
                label="Account No."
                value={props.AccountNo}
                onChange={(e) => props.setAccountNo(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '12px'
                    }
                }}
            />

            <Input
                label="Account Bank Name"
                value={props.AccountBankName}
                onChange={(e) => props.setAccountBankName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                label="Account Name"
                value={props.AccountName}
                onChange={(e) => props.setAccountName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalBankAccount;