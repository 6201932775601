import { MenuItem, IconButton, Stack, Grid, Box, InputAdornment } from '@mui/material';
import React from 'react';
import { Icon, Input, Selection } from '../../../../components/ui';
import { formatCurrency } from '../../../../services/ApiService';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';

const TabFreightAir = (props) => {
    const dataFreightStatus = [
        { id: 1, code: 'P', name: 'Prepaid' },
        { id: 2, code: 'C', name: 'Collect' },
    ];

    const AddFreightCollectMAWB = (data) => {
        if (data === 'search') {
            props.setTitleModal('Freight Collect');
            props.setTipe(1);
            props.handleClick('Freight Collect');
        }
        else if (data === 'delete') {
            props.setMAWBCollectId(0);
            props.setMAWBCollectCode('');
            props.setMAWBCollectName('');
        }
    };

    const AddFreightCollectHAWB = (data) => {
        if (data === 'search') {
            props.setTitleModal('Freight Collect');
            props.setTipe(2);
            props.handleClick('Freight Collect');
        }
        else if (data === 'delete') {
            props.setHAWBCollectId(0);
            props.setHAWBCollectCode('');
            props.setHAWBCollectName('');
        }
    };

    return (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Box component="div" sx={{ marginTop: '12px' }}>
                        <Selection
                            id='0'
                            label={'Freight MAWB'}
                            fullWidth={true}
                            placeholder={'Select Freight MAWB'}
                            value={props.MAWBStatus}
                            onChange={(e) => props.setMAWBStatus(e.target.value)}
                        >
                            {
                                dataFreightStatus.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                            <Input
                                label="Freight Collect at"
                                value={props.MAWBCollectName && props.MAWBStatus === 'C' ? props.MAWBCollectCode : ''}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.MAWBCollectName && props.MAWBStatus === 'C' ? props.MAWBCollectName : ''}
                                type='text'
                                label={''}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => AddFreightCollectMAWB('delete')}
                                                hidden={props.MAWBCollectName && props.MAWBStatus === 'C' ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => AddFreightCollectMAWB('search')}
                                                hidden={props.MAWBStatus === 'C' ? false : true}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                            <Input
                                label="Payable By"
                                value={props.MAWBStatus === 'C' ? props.payableAgentIdMAWB : '0'}
                                onChange={(e) => props.setPayableAgentIdOBL(e.target.value)}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                            />

                            <Input
                                label=""
                                value={props.MAWBStatus === 'C' ? props.payableAgentNameMAWB : ''}
                                onChange={(e) => props.setPayableAgentNameOBL(e.target.value)}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px',
                                        width: '100%'
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                }}
                            />
                        </Stack>

                        <Stack direction='row' spacing={'16px'} sx={{ marginTop: '16px' }}>
                            {
                                props.jobType.serviceType === 'Export' ?
                                    <>
                                        {props.selectCurrency(props.currency, props.setCurrency, 'Currency')}
                                    </>
                                    :
                                    <>
                                        {props.selectCurrency(props.MAWBCurrency, props.setMAWBCurrency, 'Rate')}

                                        <Input
                                            textAlign='right'
                                            value={props.MAWBRate}
                                            onChange={(e) => props.setMAWBRate(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%'
                                                }
                                            }}
                                            inputBaseProps={{
                                                sx: {
                                                    marginTop: '23px !important',
                                                },
                                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                            }}
                                        />
                                    </>
                            }
                        </Stack>
                    </Box>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Box component="div" sx={{ marginTop: '12px' }}>
                        <Selection
                            id='0'
                            label={'Freight HAWB'}
                            fullWidth={true}
                            placeholder={'Select Freight HAWB'}
                            value={props.HAWBStatus}
                            onChange={(e) => props.setHAWBStatus(e.target.value)}
                        >
                            {
                                dataFreightStatus.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                            <Input
                                label="Freight Collect at"
                                value={props.HAWBCollectName && props.HAWBStatus === 'C' ? props.HAWBCollectCode : ''}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.HAWBCollectName && props.HAWBStatus === 'C' ? props.HAWBCollectName : ''}
                                type='text'
                                label={''}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => AddFreightCollectHAWB('delete')}
                                                hidden={props.HAWBCollectName && props.HAWBStatus === 'C' ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => AddFreightCollectHAWB('search')}
                                                hidden={props.HAWBStatus === 'C' ? false : true}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                disabled
                            />
                        </Stack>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                            <Input
                                label="Payable By"
                                value={props.HAWBStatus === 'C' ? props.payableConsigneeIdHAWB : '0'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                            />

                            <Input
                                label=""
                                value={props.HAWBStatus === 'C' ? props.payableConsigneeNameHAWB : ''}
                                disabled
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px',
                                        width: '100%'
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                }}
                            />
                        </Stack>

                        <Stack direction='row' spacing={'16px'} sx={{ marginTop: '16px' }}>
                            {
                                props.jobType.serviceType === 'Export' ?
                                    <Input
                                        label="Handling Information"
                                        value={props.handlingInf}
                                        onChange={(e) => props.setHandlingInf(e.target.value)}
                                        multiline
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            rows: 4,
                                            sx: {
                                                padding: '8px',
                                            },
                                        }}
                                    />
                                    :
                                    <>
                                        {props.selectCurrency(props.HAWBCurrency, props.setHAWBCurrency, 'Rate')}

                                        <Input
                                            textAlign='right'
                                            value={props.HAWBRate}
                                            onChange={(e) => props.setHAWBRate(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%'
                                                }
                                            }}
                                            inputBaseProps={{
                                                sx: {
                                                    marginTop: '23px !important',
                                                },
                                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                            }}
                                        />
                                    </>
                            }
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default TabFreightAir;