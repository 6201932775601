import {
  Backdrop, Box, Grid,
  IconButton, InputAdornment,
  Stack, Card, CardContent, MenuItem
} from '@mui/material';
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';

import ModalContact from './Selects/ModalContact';
import ApiService from '../../../../services/ApiService';
import ButtonARAgingReport from './Buttons/ButtonARAgingReport';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';

const ArAgingReport = forwardRef((props, ref) => {
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [tanggal, setTanggal] = useState((new Date()).toLocaleDateString("sv-se").split('T')[0])
  const [custType, setCustType] = useState('S')
  const [reportType, setReportType] = useState('R')
  const [customCompany, setCustomCompany] = useState('C')
  const [all, setAll] = useState(0)
  const [isModalContact, setIsModalContact] = useState(false)
  const [dataContact, setDataContact] = useState([])
  const [titleModalContact, setTitleModalContact] = useState('Customer List')
  const paramDataContact = 'regContact/regContact'
  const [columnDataContact, setColumnDataContact] = useState([])
  const [SelectedDataContact, setSelectedDataContact] = useState({});
  const [totalRowsContact, setTotalRowsContact] = useState(50)
  const [dataMapContact, setDataMapContact] = useState([])
  const [contactId, setContactId] = useState(0)
  const [contactCode, setContactCode] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactId2, setContactId2] = useState(0)
  const [contactCode2, setContactCode2] = useState('')
  const [contactName2, setContactName2] = useState('')
  const [isLoadingContact, setIsLoadingContact] = useState(false)
  const [contact, setContact] = useState(0)

  const customCompanyList = [
    { id: 1, code: 'C', name: 'Per Company' },
    { id: 2, code: 'R', name: 'Per Regional' }
  ];

  const reportTypeList = [
    { id: 1, code: 'R', name: 'Recapitulation' },
    { id: 2, code: 'D', name: 'Detail' }
  ];

  const customerTypeList = [
    { id: 1, code: 'S', name: 'Shipper' },
    { id: 2, code: 'C', name: 'Consignee' },
    { id: 3, code: 'A', name: 'Agent' },
  ]

  const customContactList = [
    { id: 1, code: 0, name: 'All' },
    { id: 2, code: 1, name: 'Custom' }
  ];

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Receivable Aging Report', link: '/Majura/ReceivableAgingReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() {
      initializeView();
    }
  }));

  useEffect(() => {
    document.title = 'Receivable Aging Report - ' + JSON.parse(localStorage.getItem('branchName'));
    initializeView();
  }, [])

  function initializeView() {
    setIsLoadingPrint(true);
    setTimeout(function () { setIsLoadingPrint(false); }, 2000);
  }

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
    }
    else {
      setIsModalConfirmation(false);
    }
  }

  function ConfirmationARAR() {
    if (titleConfirmation === 'Print PDF < 6 Months') {
      PrintReport("ARAP", 0)
    }
    else if (titleConfirmation === 'Print Excel < 6 Months') {
      PrintReport("ARAX", 0)
    }
    else if (titleConfirmation === 'Print PDF > 6 Months') {
      PrintReport("ARAP", 1)
    }
    else if (titleConfirmation === 'Print Excel > 6 Months') {
      PrintReport("ARAX", 1)
    }
    setIsModalConfirmation(false);
  }

  const checkInput = () => {
    let check = 0;

    if (all === 1 && (contactCode === '' || contactCode2 === '')) {
      check = 1;
    }

    if (check === 1) {
      showToast({ type: 'error', message: 'Please Select Data Customer!' })
    }

    return check;
  }

  const PrintData = () => {
    if (checkInput() === 0) {
      setTitleConfirmation('Print PDF < 6 Months')
      setTitleModalConfirmation('Receivable Aging Report');
      handleClickConfirmation();
    }
  };

  const PrintExcel = () => {
    if (checkInput() === 0) {
      setTitleConfirmation('Print Excel < 6 Months')
      setTitleModalConfirmation('Receivable Aging Report');
      handleClickConfirmation();
    }
  };

  const PrintData2 = () => {
    if (checkInput() === 0) {
      setTitleConfirmation('Print PDF > 6 Months')
      setTitleModalConfirmation('Receivable Aging Report');
      handleClickConfirmation();
    }
  };

  const PrintExcel2 = () => {
    if (checkInput() === 0) {
      setTitleConfirmation('Print Excel > 6 Months')
      setTitleModalConfirmation('Receivable Aging Report');
      handleClickConfirmation();
    }
  };

  const PrintReport = (code, overSix) => {
    setIsLoadingPrint(true)
    let isRecap = true;
    if (reportType === 'D') {
      isRecap = false
    }

    let isAll = true
    if (all === 1) {
      isAll = false
    }

    let cst = 'Shipper'
    switch (custType) {
      case 'S':
        cst = 'SHIPPER'
        break
      case 'C':
        cst = "CONSIGNEE"
        break
      case 'A':
        cst = 'AGENT'
        break
      default:
        break
    }

    let variable = "PrintARA?date=" + tanggal + "&apArType=" + cst + "&isRecap=" + isRecap + "&isAllContact=" + isAll +
      "&contactId1=" + contactId + "&contactId2=" + contactId2 + "&isOver180days=" + overSix + "&code=" + code;
    ApiService.ReportPrint("Reports/Finance/Print/AccountReceivable/ArAgingReport/", variable)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";
          if (code === "ARAX") {
            rptFormat = "application/vnd.ms-excel"
          }
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoadingPrint(false);
          showToast({ type: 'success', message: 'Print Receivable Aging Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoadingPrint(false);
          showToast({ type: 'error', message: 'Can\'t Print Receivable Aging Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoadingPrint(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Receivable Aging Report, ' + error });
      });
  };

  const handleAll = (e) => {
    setAll(e);
    if (e === 0) {
      EmptyContact()
      EmptyContact2()
    }
  }

  const EmptyContact = () => {
    setContactId(0)
    setContactCode('')
    setContactName('')
  }

  const EmptyContact2 = () => {
    setContactId2(0)
    setContactCode2('')
    setContactName2('')
  }

  const getContact = (pageNumber, pageSize, filters, orderBy) => {
    setIsLoadingContact(true)
    let typeId = '';
    switch (custType) {
      case "S":
        typeId = '2';
        setTitleModalContact('Shipper')
        break;
      case 'C':
        typeId = '3';
        setTitleModalContact('Consignee')
        break;
      default:
        typeId = '1';
        setTitleModalContact('Agent')
        break;
    }

    const params = [{ attr: "contactTypeId", value: typeId }]
    ApiService.DynamicPostByPage(paramDataContact, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataContact({})
          setDataContact(response.data.data.contact);
          let temp = response.data.data.contact
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMapContact(indexed)
          setColumnDataContact(response.data.data.columns)
          setTotalRowsContact(response.data.totalRowCount)
          setIsLoadingContact(false)
        }
      })
      .catch(function (error) {
        console.error('error saat fetch', error)
        setIsLoadingContact(false)
      })
  }

  const handleModalContact = () => {
    if (isModalContact === false) {
      getContact(1, 50);
      setContact(1)
      setIsModalContact(true);
    }
    else {
      setIsModalContact(false);
    }
  }

  const handleModalContact2 = () => {
    if (isModalContact === false) {
      getContact(1, 50);
      setContact(2)
      setIsModalContact(true);
    }
    else {
      setIsModalContact(false);
    }
  }

  const ButtonComponents = () => {
    return (
      <ButtonARAgingReport
        PrintData={PrintData}
        PrintExcel={PrintExcel}
        PrintData2={PrintData2}
        PrintExcel2={PrintExcel2}
      />
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: 3 }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          title={'Receivable Aging Report'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div style={{ marginTop: '24px' }}>
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                  <CardContent>
                    <Grid
                      container
                      spacing={'16px'}
                    >
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                      >
                        <Input
                          type={'date'}
                          value={tanggal}
                          variant="standard"
                          label="As Per Date"
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => setTanggal(e.target.value)}
                          formControlProps={{ sx: { width: '100%', } }}
                        />

                        <Selection
                          id='0'
                          fullWidth={true}
                          value={reportType}
                          label="Report Type"
                          placeholder={'Select Report Type'}
                          formControlProps={{ sx: { marginTop: '16px' } }}
                          onChange={(e) => setReportType(e.target.value)}
                        >
                          {
                            reportTypeList.map((data) => (
                              <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                {data.name}
                              </MenuItem>
                            ))
                          }
                        </Selection>
                      </Grid>

                      <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                      >
                        <Selection
                          id='0'
                          disabled
                          fullWidth={true}
                          value={customCompany}
                          label="Custom Company"
                          placeholder={'Select Custom Company'}
                          onChange={(e) => setCustomCompany(e.target.value)}
                        >
                          {
                            customCompanyList.map((data) => (
                              <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                {data.name}
                              </MenuItem>
                            ))
                          }
                        </Selection>

                        <Selection
                          id='0'
                          value={custType}
                          fullWidth={true}
                          label="Customer Type"
                          placeholder={'Select Customer Type'}
                          formControlProps={{ sx: { marginTop: '16px' } }}
                          onChange={(e) => { setCustType(e.target.value); EmptyContact(); EmptyContact2(); }}
                        >
                          {
                            customerTypeList.map((data) => (
                              <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                {data.name}
                              </MenuItem>
                            ))
                          }
                        </Selection>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                  <CardContent>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                      >
                        <Selection
                          id='0'
                          value={all}
                          fullWidth={true}
                          label="Custom Contact"
                          placeholder={'Select Custom Contact'}
                          onChange={(e) => handleAll(e.target.value)}
                        >
                          {
                            customContactList.map((data) => (
                              <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                {data.name}
                              </MenuItem>
                            ))
                          }
                        </Selection>
                      </Grid>
                    </Grid>

                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                      <Input
                        readOnly
                        fullWidth
                        variant="standard"
                        value={contactCode}
                        label="Customer Code"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />

                      <Input
                        fullWidth
                        variant="standard"
                        value={contactName}
                        label="Customer Name"
                        InputLabelProps={{ shrink: true }}
                        disabled
                        formControlProps={{ sx: { width: '100%', } }}
                        inputBaseProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => EmptyContact()}
                                hidden={contactName ? false : true}
                              >
                                <Icon
                                  iconName={'close'}
                                  svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                      height: '12px',
                                      width: '12px'
                                    }
                                  }}
                                />
                              </IconButton>

                              <IconButton
                                onClick={() => handleModalContact()}
                                hidden={all === 0 ? true : false}
                              >
                                <Icon
                                  iconName={'search'}
                                  svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                      height: '12px',
                                      width: '12px'
                                    }
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Stack>

                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                      <Input
                        readOnly
                        fullWidth
                        variant="standard"
                        value={contactCode2}
                        label="To Customer Code"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />

                      <Input
                        fullWidth
                        variant="standard"
                        value={contactName2}
                        label="To Customer Name"
                        InputLabelProps={{ shrink: true }}
                        disabled
                        formControlProps={{ sx: { width: '100%', } }}
                        inputBaseProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => EmptyContact2()}
                                hidden={contactName2 ? false : true}
                              >
                                <Icon
                                  iconName={'close'}
                                  svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                      height: '12px',
                                      width: '12px'
                                    }
                                  }}
                                />
                              </IconButton>

                              <IconButton
                                onClick={() => handleModalContact2()}
                                hidden={all === 0 ? true : false}
                              >
                                <Icon
                                  iconName={'search'}
                                  svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                      height: '12px',
                                      width: '12px'
                                    }
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {
        isLoadingPrint && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingPrint}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {
        isModalContact && (
          <ModalContact
            isModal={isModalContact}
            handleClick={handleModalContact}
            titleModal={titleModalContact}
            getData={getContact}
            columnData={columnDataContact}
            dataMap={dataMapContact}
            SelectedData={SelectedDataContact}
            setSelectedData={setSelectedDataContact}
            totalRows={totalRowsContact}
            data={dataContact}
            setContactId={contact === 1 ? setContactId : setContactId2}
            setContactCode={contact === 1 ? setContactCode : setContactCode2}
            setContactName={contact === 1 ? setContactName : setContactName2}
            isLoading={isLoadingContact}
          />
        )
      }

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={ConfirmationARAR}
          />
        )
      }
    </Box>
  )
})

export default ArAgingReport