import {
    Box,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useState } from 'react';

import ApiService from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataTRAndRV from '../Selects/SelectDataTRAndRV';
import { showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const TabTemporaryReceipt = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const paramT = "temporaryReceipt/temporaryReceipt";
    const [isLoadingT, setIsLoadingT] = useState(false);
    const [dataT, setDataT] = useState([]);
    const [dataMapT, setDataMapT] = useState([])
    const [columnDataT, setColumnDataT] = useState([])
    const [SelectedDataT, setSelectedDataT] = useState({});
    const [totalRowsT, setTotalRowsT] = useState(50);

    useEffect(() => {
        setSelectedDetail({});
    }, [props.TRList]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataTemporaryReceipt(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddTR = () => {
        if (props.checkSelectedInvoice.id !== undefined) {
            setTitleModal('Temporary Receipt');
            setDetailEdit(false);
            if (props.TRList.length > 0) {
                let data = props.TRList.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                setDetailSequence(data.sequence);
            }
            else {
                setDetailSequence(0);
            }
            handleClick();
        }
        else {
            showToast({ type: 'error', message: 'Please Select Invoice First!' });
        }
    };

    const DeleteTR = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;
            let tempIdDetail = selectedDetail.trDetailId;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const deleteFunctionId = (data) => {
                return data !== tempIdDetail;
            }

            const result = props.TRList.filter(deleteFunction);

            const resultId = props.TRIdList.filter(deleteFunctionId);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setTRList(result);
            props.setTRIdList(resultId);

            const newArray = props.InvoiceList.slice();
            newArray.forEach(el => {
                if (el.invoiceId === props.checkSelectedInvoice.invoiceId) {
                    el.officialReceiptInvoiceTRs = result;
                }
            });
            props.setInvoiceList(newArray);

            props.calculateTotalTR(newArray);
            props.calculateTotalDifferent(newArray);

            setSelectedDetail({});

            showToast({ type: 'success', message: 'Temporary Receipt Deleted Successfully!' });
        }
    }

    const getDataTemporaryReceipt = (pageNumber, pageSize, filters) => {
        setIsLoadingT(true);
        let params = [];
        params = [
            ...params,
            { 'attr': 'shipmentOrderId', 'value': '' + props.checkSelectedInvoice.shipmentId },
            { 'attr': 'isDP', 'value': 'true' },
            { 'attr': 'isDPJob', 'value': 'true' },
            { 'attr': 'ORId', 'value': '0' },
            { 'attr': 'isApprovedAcc', 'value': 'true' }
        ];
        if (props.checkSelectedInvoice.invoiceId !== undefined) {
            ApiService.FinAccPostByDetail(paramT, pageNumber, pageSize, params, filters, 'SEAOR')
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataT({})
                        setDataT(response.data.data.temporaryReceiptJob);

                        let temp = response.data.data.temporaryReceiptJob
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMapT(indexed)

                        setColumnDataT(response.data.data.columns.jobColumns)

                        setTotalRowsT(response.data.totalRowCount)

                        setIsLoadingT(false)
                    }
                    setIsLoadingT(false)
                })
                .catch(function (error) {
                    setIsLoadingT(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    return (
        <>
            <Box component="div" sx={{ marginTop: '-12px !important' }}>
                <TableContainer sx={{ maxHeight: 235, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead
                            sx={{
                                '& th:first-of-type': {
                                    borderRadius: '12px 0 0'
                                },
                                '& th:last-child': {
                                    borderRadius: '0 12px 0 0'
                                }
                            }}
                        >
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'center' }}>TR No</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Invoice No</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Payment USD</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Payment IDR</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.TRList.length > 0 ?
                                    props.TRList.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                onClick={() => setSelectedDetail(el)}
                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                hover={true}
                                            >
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.trNo}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.invoiceNo}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.paymentUSD)}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.paymentIDR)}</StyledTableBodyCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div style={{ marginTop: '16px' }}>
                    {CustomizeButtonComponent.AddDeleteTabsButton(ShowAddTR, DeleteTR, (props.Printing > 0 ? true : false), (props.Printing > 0 ? true : false))}
                </div>
            </Box>
            
            {
                isModal && (
                    <SelectDataTRAndRV
                        calculateTotalTR={props.calculateTotalTR}
                        calculateTotalDifferent={props.calculateTotalDifferent}
                        officialReceiptId={props.officialReceiptId}
                        setIsModal={setIsModal}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        getData={getDataTemporaryReceipt}
                        isLoading={isLoadingT}
                        totalRows={totalRowsT}
                        setTotalRows={setTotalRowsT}
                        SelectedData={SelectedDataT}
                        setSelectedData={setSelectedDataT}
                        data={dataT}
                        columnData={columnDataT}
                        dataMap={dataMapT}
                        checkSelectedInvoice={props.checkSelectedInvoice}
                        RateCount={props.RateCount}
                        InvoiceList={props.InvoiceList}
                        setInvoiceList={props.setInvoiceList}
                        TRList={props.TRList}
                        setTRList={props.setTRList}
                        TRIdList={props.TRIdList}
                        setTRIdList={props.setTRIdList}
                    />
                )
            }
        </>
    )
}

export default TabTemporaryReceipt;