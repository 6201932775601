import React from 'react';
import { Grid } from '@mui/material';
import { Chart } from 'react-chartjs-2';

import {
    Chart as ChartJS, CategoryScale, LinearScale,
    PointElement, BarElement, LineElement,
    Title, Tooltip, Legend,
    LineController, BarController,
} from 'chart.js';

ChartJS.register(
    CategoryScale, LinearScale, PointElement,
    BarElement, LineElement, 
    LineController, BarController,
    Title, Tooltip, Legend
  );

const ChartComponent = (props) => {
    const ChartView = (props) => {
        const dataR = props.data;
        const optionsR = props.options;
        let scales = {
            x: {
                stacked: false,
            },
            y: {
                stacked: false,
            },
        }

        optionsR.forEach(element => {
            if(element.attribute === 'xStacked')
            {
                if(element.value === 'true')
                {
                    scales.x.stacked = true;
                }
            }
            else if(element.attribute === 'yStacked')
            {
                if(element.value === 'true')
                {
                    scales.y.stacked = true;
                }
            }
        });

        const options = {
          indexAxis: 'x',
          animation: false,
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 2,
          layout: {
            autoPadding: true,
          },
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: props.title,
            },
          },
          scales: scales
        };

        optionsR.forEach(element => {
            if(element.attribute === 'indexAxis')
            {
                options.indexAxis = element.value;
            }
            else if(element.attribute === 'animation')
            {
                if(element.value === 'true')
                {
                    options.animation = true;
                }
            }
            else if(element.attribute === 'legendPosition')
            {
                options.plugins.legend.position = element.value;
            }
        });
      
        const labels = dataR.labels;
      
        const data = {
          labels,
          datasets: dataR.datasets
        };
    
        return (
          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            <div 
                className='table-responsive' 
                style={{
                    display: 'flex',
                    padding: '16px 24px',
                    flexDirection: 'column',
                    borderRadius: '12px',
                    background: '#FAFAFA',
                    height:"31.5vh", 
                }}
            >
              <Chart options={options} data={data} className='w-100' />
            </div>
          </Grid>
        )
    }

    return (
        <Grid
            container
            spacing={2}
        >
            {
                props.data.length > 0?
                    props.data.map((row, index) => (
                        <ChartView key = {index} title = {row.name} data = {row.data} options = {row.options} />
                    ))
                :
                    <></>
            }
        </Grid>
    )
}

export default ChartComponent;