const drawerWidth = '240px';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 5px)`,
  [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 5px)`,
  },
});

export {
  drawerWidth,
  openedMixin,
  closedMixin,
}