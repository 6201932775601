import React from 'react'
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';

import Grid from '@mui/material/Grid';

const ButtonEstimateIncomeStatementView = (props) => {
    return (
        <div className="row" spacing={'12px'}>
            <div className='col-12' spacing={'12px'}>
                <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.PrintButton(props.PrintData, 'PDF')}
                    {ButtonComponent.ExcelButton(props.PrintExcel, 'Print Excel')}
                </Grid>
            </div>
        </div>
    )
}

export default ButtonEstimateIncomeStatementView;