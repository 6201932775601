import React from 'react';
import { Grid } from '@mui/material';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';

const ButtonCreateEditPaymentPPh23 = ({
    check,
    Save,
    DataRegUserAccess,
    isEdit,
    Print,
    Add,
    RePrint
}) => {

    const renderButton = (data) => {
        if (isEdit) {
            switch (data.accessCode) {
                case 'PRI':
                    return ButtonComponent.PrintButton(Print, '', true);
                case 'CRE':
                    return ButtonComponent.AddButton(Add, 'New', false, false, true);
                case 'RPR':
                    return ButtonComponent.ReprintButton(RePrint, 'Approval', true);
                default:
                    return null;
            }
        }
        return null;
    };

    return (
        <div className="row" style={{ marginTop: '16px' }}>
            <div className="col-12">
                <Grid
                    container
                    item
                    justifyContent="right"
                    direction="row"
                    style={{ maxWidth: '100vw', gap: '12px' }}
                >
                    {check !== 0 && ButtonComponent.SaveButton(Save, false, false, true)}
                    {DataRegUserAccess.map(data => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data)}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonCreateEditPaymentPPh23;