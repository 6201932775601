import PropTypes from 'prop-types'

const FormControlProps = {
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
    PropTypes.string
  ]),
  focused: PropTypes.bool,
  fullWidth: PropTypes.bool,
  hiddenLabel: PropTypes.bool,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']),
  required: PropTypes.bool,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['medium', 'small']),
    PropTypes.string
  ]),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.bool
      ])
    ),
    PropTypes.func,
    PropTypes.object
  ]),
}

export {
  FormControlProps
}