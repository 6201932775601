import { MenuItem } from '@mui/material';
import React from 'react';
import { userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection } from '../../../components/ui';

const CreateEditContact = (props) => {
    const contactStatusData = [
        { id: 1, code: 'PT', name: 'PT' },
        { id: 2, code: 'CV', name: 'CV' },
        { id: 3, code: 'OT', name: 'Others' },
    ];

    const Save = () => {
        var data = {
            "contact": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "contactStatus": props.contactStatus,
                "contactName": props.contactName,
                "userCode": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
            // window.location.reload();
        });
    }

    const mainContainer = (
        <form style={{ marginTop: '12px' }}>
            <Input
                label="Contact Code"
                value={props.code}
                disabled
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                label="Contact Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={props.contactName}
                onChange={(e) => props.setContactName(e.target.value)}
            />

            <Selection
                label="Contact Status"
                placeholder={'Select Contact Status'}
                value={props.contactStatus}
                onChange={(e) => props.setContactStatus(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    contactStatusData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                    ))
                }
            </Selection>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }
        </>
    )
}

export default CreateEditContact