import { IconButton, Stack, Grid, InputAdornment, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ApiService, { formatCurrency } from '../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../components/Masks/RupiahInput';
import { Icon, Input } from '../../../../components/ui';

const TabCreditInfo = (props) => {
    const param = "regContact/regContact";

    const getDataCreditTerm = (contTypeId, contId, billTo) => {
        ApiService.OutstandingInvoicePostBySearch('shipmentOrder/shipmentOrder', contId)
            .then((response) => {
                if (response.data.code === 200) {
                    props.setOutstandingInIDR(response.data.data.shipmentOrderOSInv.outstandingInvInIDR);
                    props.setOutstandingInUSD(response.data.data.shipmentOrderOSInv.outstandingInvInUSD);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
            })
    };

    const getCreditTermIn = (contactId, contactTypeId) => {
        let params = [{ 'attr': 'contactTypeId', 'value': '' + contactTypeId }, { 'attr': 'contactId', 'value': '' + contactId }];
        ApiService.DynamicPostByPage(param, 'SEARCH', 1, 1, params, [])
            .then((response) => {
                if (response.data.code === 200) {
                    props.setCreditTermUSD(response.data.data.contact[0].creditTermInUSD);
                    props.setCreditTermDays(response.data.data.contact[0].creditTermInDays);
                    props.setCreditTermIDR(response.data.data.contact[0].creditTermInIDR);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
            })
    }

    const searchBillToShipper = (data) => {
        if (data === 'search') {
            props.setTipe(2);
            props.setContactTypeId(15);
            props.setTitleModal('Bill To');
            props.handleClick(2);
        }
        else if (data === 'delete') {
            props.setBillToShipperId(0);
            props.setBillShipperName('');

            getCreditTermIn(props.ShipperId, 2);
            getDataCreditTerm(2, props.ShipperId, false);
        }
    };

    const searchBillToConsignee = (data) => {
        if (data === 'search') {
            props.setTipe(3);
            props.setContactTypeId(19);
            props.setTitleModal('Bill To');
            props.handleClick(3);
        }
        else if (data === 'delete') {
            props.setBillToConsigneeId(0);
            props.setBillConsigneeName('');

            getDataCreditTerm(3, props.ConsigneeId, false);
            getCreditTermIn(props.ConsigneeId, 3);
        }
    };

    return (
        <>
            {
                props.jobType.serviceType !== 'Import' ?
                    <Grid
                        container
                        spacing={'16px'}
                    >
                        <Grid
                            item
                            xl={1.5}
                            lg={1.5}
                            sm={1.5}
                            xs={12}
                        >
                            <Input
                                label="Bill To Code"
                                value={props.billToShipperId}
                                disabled
                            />
                        </Grid>

                        <Grid
                            item
                            xl={10.5}
                            lg={10.5}
                            sm={10.5}
                            xs={12}
                        >
                            <Input
                                label="Bill To Name"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                disabled
                                value={props.billShipperName}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {
                                                props.ShipperId !== 0 ?
                                                    <Stack direction={'row'} spacing={1}>
                                                        <IconButton onClick={() => searchBillToShipper('delete')}
                                                            hidden={props.billShipperName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={() => searchBillToShipper('search')}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                    :
                                                    <Stack direction={'row'} spacing={1}>
                                                        <IconButton
                                                            disabled
                                                            hidden={props.billShipperName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            disabled
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    :
                    <Grid
                        container
                        spacing={'16px'}
                    >
                        <Grid
                            item
                            xl={2}
                            lg={2}
                            sm={2}
                            xs={12}
                        >
                            <Input
                                label="Bill To Id"
                                value={props.billToConsigneeId}
                                disabled
                            />
                        </Grid>

                        <Grid
                            item
                            xl={10}
                            lg={10}
                            sm={10}
                            xs={12}
                        >

                            <Input
                                label="Bill To Name"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.billConsigneeName}
                                disabled
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {
                                                props.ShipperId !== 0 ?
                                                    <Stack direction={'row'} spacing={1}>
                                                        <IconButton onClick={() => searchBillToConsignee('delete')}
                                                            hidden={props.billShipperName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={() => searchBillToConsignee('search')}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                                    :
                                                    <Stack direction={'row'} spacing={1}>
                                                        <IconButton
                                                            disabled
                                                            hidden={props.billShipperName ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            disabled
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '16px',
                                                                        width: '16px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Stack>
                                            }
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
            }

            <Box component="div" sx={{ marginTop: '24px !important' }}>
                {
                    props.jobType.serviceType !== 'Import' ?
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                marginTop: '8px'
                            }}
                        >
                            Credit Info - Shipper
                        </Typography>
                        :
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                marginTop: '8px'
                            }}
                        >
                            Credit Info - Consignee
                        </Typography>
                }

                <Grid
                    container
                    spacing={'16px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="Credit Term (In Days)"
                            textAlign='right'
                            fullWidth
                            value={props.creditTermDays}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="Credit Term (In IDR)"
                            textAlign='right'
                            fullWidth
                            value={props.creditTermIDR}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="Credit Term (In USD)"
                            textAlign='right'
                            fullWidth
                            value={props.creditTermUSD}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="Approval Credit"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.ApprovedCredit === false ? 'No' : 'Yes'}
                            disabled
                            type='text'
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="Outstanding Invoice In IDR"
                            textAlign='right'
                            fullWidth
                            value={props.outstandingInIDR}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="Outstanding Invoice In USD"
                            textAlign='right'
                            fullWidth
                            value={props.outstandingInUSD}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="Freight Amount IDR"
                            textAlign='right'
                            fullWidth
                            value={props.freightAmountIDR}
                            onChange={(e) => props.setFreightAmountIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="Freight Amount USD"
                            textAlign='right'
                            fullWidth
                            value={props.freightAmountUSD}
                            onChange={(e) => props.setFreightAmountUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default TabCreditInfo;