import { FormHelperText, Grid, MenuItem, Stack, Typography } from '@mui/material';
import React, { useImperativeHandle, forwardRef } from 'react';
import { branchId, companyId, countryId, userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast, TickBox } from '../../../components/ui';
import { updateDateFormat } from '../../../utils/date';

const CreateEditRegClosingPeriod = forwardRef((props, ref) => {
    const getMonthNames = (locale) => {
        const formatter = new Intl.DateTimeFormat(locale, { month: 'long' });
        return Array.from({ length: 12 }, (_, i) => ({
            id: i + 1,
            label: formatter.format(new Date(0, i)),
        }));
    };
    const periodList = getMonthNames('en-US');
    const { isModal, handleClick, titleModal, title, param, selectedData, getData, setIsLoadingBackDrop } = props

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "regClosingPeriod": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DataRegClosingPeriod", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        period: Yup.number().required('This field is required.'),
        yearPeriod: Yup.number().min(1, 'Must be greater than 0').required('This field is required.'),
    })

    const formik = useFormik({
        initialValues:
            selectedData?.id ?
                {
                    id: selectedData?.id,
                    period: selectedData?.period,
                    yearPeriod: selectedData?.yearPeriod,
                    closing: selectedData?.closing,
                    closingDate: updateDateFormat(selectedData?.closingDate),
                    eplClosing: selectedData?.eplClosing,
                    eplClosingDate: updateDateFormat(selectedData?.eplClosingDate),
                    arClosing: selectedData?.arClosing,
                    arClosingDate: updateDateFormat(selectedData?.arClosingDate),
                    bdFromDate: updateDateFormat(selectedData?.bdValidFromDate),
                    bdActiveDate: updateDateFormat(selectedData?.bdValidActiveDate),
                    beginningPeriod: updateDateFormat(selectedData?.beginningPeriod),
                    endDatePeriod: updateDateFormat(selectedData?.endDatePeriod),
                }
                :
                {
                    id: 0,
                    period: '',
                    yearPeriod: '',
                    closing: false,
                    closingDate: '',
                    eplClosing: false,
                    eplClosingDate: '',
                    arClosing: false,
                    arClosingDate: '',
                    bdFromDate: '',
                    bdActiveDate: '',
                    beginningPeriod: '',
                    endDatePeriod: '',
                },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        setIsLoadingBackDrop(true);

        if (payload?.period === '') {
            setIsLoadingBackDrop(false);
            return showToast({ type: 'error', message: 'Period is Required!' });
        }

        if (payload?.yearPeriod < 1) {
            setIsLoadingBackDrop(false);
            return showToast({ type: 'error', message: 'Year Period Must be Greater Than 0!' });
        }

        const data =
        {
            "closingPeriod": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "period": payload?.period,
                "yearPeriod": parseInt(payload?.yearPeriod),
                "userCode": userCode,

                "closing": payload?.closing,
                "closingDate": updateDateFormat(payload?.closingDate, true),
                "eplClosing": payload?.eplClosing,
                "eplClosingDate": updateDateFormat(payload?.eplClosingDate, true),
                "arClosing": payload?.arClosing,
                "arClosingDate": updateDateFormat(payload?.arClosingDate, true),
                "bdValidFromDate": updateDateFormat(payload?.bdFromDate, true),
                "BDValidActiveDate": updateDateFormat(payload?.bdActiveDate, true),
                "beginningPeriod": updateDateFormat(payload?.beginningPeriod, true),
                "endDatePeriod": updateDateFormat(payload?.endDatePeriod, true),
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            setIsLoadingBackDrop(false);
            getData(1, 50, []);
            handleClick();
        });
    }

    const handleSetDate = (period, yearPeriod) => {
        if (period !== '' && yearPeriod !== '') {
            const year = parseInt(yearPeriod, 10);
            const month = String(period).padStart(2, '0');
            const endingDay = new Date(year, parseInt(month, 10), 0).getDate();

            formik.setFieldValue('beginningPeriod', `${yearPeriod}-${month}-01`);
            formik.setFieldValue('endDatePeriod', `${yearPeriod}-${month}-${endingDay}`);
            formik.setFieldValue('bdActiveDate', `${yearPeriod}-${month}-${endingDay}`);
            formik.setFieldValue('bdFromDate', `${yearPeriod}-${month}-${endingDay}`);


            let monthCB = parseInt(period, 10);
            let yearCB = parseInt(yearPeriod, 10);
            monthCB = monthCB === 12 ? 2 : monthCB + 1;
            const monthFormatted = String(monthCB).padStart(2, '0');

            formik.setFieldValue('closingDate', `${yearCB}-${monthFormatted}-10`);
            formik.setFieldValue('eplClosingDate', `${yearCB}-${monthFormatted}-05`);
        }
    };

    const handleSetTickBox = (name, value) => {

        if (name === 'closing') {
            formik.setFieldValue('closing', !value);
        }
        else if (name === 'arClosing') {
            formik.setFieldValue('arClosing', !value);
        }
        else if (name === 'eplClosing') {
            formik.setFieldValue('eplClosing', !value);
        }
    };

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Grid container spacing={'32px'}>
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Selection
                        fullWidth={true}
                        id="period"
                        label="Period"
                        labelId="periodLabel"
                        placeholder={'Select Period'}
                        value={formik.values.period}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                        disabled={selectedData?.id}
                        onChange={(e) => { formik.setFieldValue('period', e.target.value); handleSetDate(e.target.value, formik.values.yearPeriod) }}
                        error={formik.touched.period && Boolean(formik.errors.period)}
                    >
                        {
                            periodList.map((period) => (
                                <MenuItem sx={{ fontSize: '12px' }} id={period.id} key={period.id} value={period.id}>
                                    {period.label}
                                </MenuItem>
                            ))
                        }
                        <FormHelperText>{formik.touched.period && formik.errors.period}</FormHelperText>
                    </Selection>

                    <Input
                        type='date'
                        label="Beginning Period"
                        name="beginningPeriod"
                        value={formik.values.beginningPeriod}
                        onBlur={formik.handleBlur}
                        disabled={selectedData?.id}
                        error={formik.touched.beginningPeriod && Boolean(formik.errors.beginningPeriod)}
                        helperText={formik.touched.beginningPeriod && formik.errors.beginningPeriod}
                        onChange={(e) => formik.setFieldValue('beginningPeriod', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        type='date'
                        label="BackDate From Date"
                        name="bdFromDate"
                        value={formik.values.bdFromDate}
                        onBlur={formik.handleBlur}
                        hidden={formik.values.period !== '' && formik.values.yearPeriod !== '' ? false : true}
                        error={formik.touched.bdFromDate && Boolean(formik.errors.bdFromDate)}
                        helperText={formik.touched.bdFromDate && formik.errors.bdFromDate}
                        onChange={(e) => formik.setFieldValue('bdFromDate', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        type='number'
                        label="Year Period"
                        name="yearPeriod"
                        value={formik.values.yearPeriod}
                        onBlur={formik.handleBlur}
                        disabled={selectedData?.id}
                        error={formik.touched.yearPeriod && Boolean(formik.errors.yearPeriod)}
                        helperText={formik.touched.yearPeriod && formik.errors.yearPeriod}
                        onChange={(e) => { formik.setFieldValue('yearPeriod', e.target.value); handleSetDate(formik.values.period, e.target.value) }}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        type='date'
                        label="Ending Period"
                        name="endDatePeriod"
                        value={formik.values.endDatePeriod}
                        onBlur={formik.handleBlur}
                        disabled={selectedData?.id}
                        error={formik.touched.endDatePeriod && Boolean(formik.errors.endDatePeriod)}
                        helperText={formik.touched.endDatePeriod && formik.errors.endDatePeriod}
                        onChange={(e) => formik.setFieldValue('endDatePeriod', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        type='date'
                        label="BackDate To Date"
                        name="bdActiveDate"
                        value={formik.values.bdActiveDate}
                        onBlur={formik.handleBlur}
                        hidden={formik.values.period !== '' && formik.values.yearPeriod !== '' ? false : true}
                        error={formik.touched.bdActiveDate && Boolean(formik.errors.bdActiveDate)}
                        helperText={formik.touched.bdActiveDate && formik.errors.bdActiveDate}
                        onChange={(e) => formik.setFieldValue('bdActiveDate', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Stack
                direction={'row'}
                spacing={'32px'}
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '16px'
                }}
                hidden={formik.values.period !== '' && formik.values.yearPeriod !== '' ? false : true}
            >
                <div style={{ marginTop: '16px', width: '100%' }}>
                    <TickBox
                        size={'medium'}
                        label={
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    marginTop: '2px'
                                }}
                            >
                                Closing
                            </Typography>
                        }
                        sx={{ width: '100%' }}
                        checked={formik.values.closing}
                        onChange={() => handleSetTickBox('closing', formik.values.closing)}
                    />
                </div>

                <Input
                    type='date'
                    label="Closing Date"
                    name="closingDate"
                    value={formik.values.closingDate}
                    onBlur={formik.handleBlur}
                    error={formik.touched.closingDate && Boolean(formik.errors.closingDate)}
                    helperText={formik.touched.closingDate && formik.errors.closingDate}
                    onChange={(e) => formik.setFieldValue('closingDate', e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                />
            </Stack>

            <Stack
                direction={'row'}
                spacing={'32px'}
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '16px'
                }}
                hidden={formik.values.period !== '' && formik.values.yearPeriod !== '' ? false : true}
            >
                <div style={{ marginTop: '16px', width: '100%' }}>
                    <TickBox
                        size={'medium'}
                        label={
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    marginTop: '2px'
                                }}
                            >
                                AR Closing
                            </Typography>
                        }
                        sx={{ width: '100%' }}
                        checked={formik.values.arClosing}
                        onChange={() => handleSetTickBox('arClosing', formik.values.arClosing)}
                    />
                </div>

                <Input
                    type='date'
                    label="AR Closing Date"
                    name="arClosingDate"
                    value={formik.values.arClosingDate}
                    onBlur={formik.handleBlur}
                    error={formik.touched.arClosingDate && Boolean(formik.errors.arClosingDate)}
                    helperText={formik.touched.arClosingDate && formik.errors.arClosingDate}
                    onChange={(e) => formik.setFieldValue('arClosingDate', e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                />
            </Stack>

            <Stack
                direction={'row'}
                spacing={'32px'}
                sx={{
                    alignItems: 'center',
                    width: '100%',
                    marginTop: '16px'
                }}
                hidden={formik.values.period !== '' && formik.values.yearPeriod !== '' ? false : true}
            >
                <div style={{ marginTop: '16px', width: '100%' }}>
                    <TickBox
                        size={'medium'}
                        label={
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '24px',
                                    marginTop: '2px'
                                }}
                            >
                                EPL Closing
                            </Typography>
                        }
                        sx={{ width: '100%' }}
                        checked={formik.values.eplClosing}
                        onChange={() => handleSetTickBox('eplClosing', formik.values.eplClosing)}
                    />
                </div>

                <Input
                    type='date'
                    label="EPL Closing Date"
                    name="eplClosingDate"
                    value={formik.values.eplClosingDate}
                    onBlur={formik.handleBlur}
                    error={formik.touched.eplClosingDate && Boolean(formik.errors.eplClosingDate)}
                    helperText={formik.touched.eplClosingDate && formik.errors.eplClosingDate}
                    onChange={(e) => formik.setFieldValue('eplClosingDate', e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                />
            </Stack>
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditRegClosingPeriod