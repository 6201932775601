import { Grid, InputAdornment, IconButton, MenuItem } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService from '../../../../../services/ApiService';
import SelectDataMaster from '../../../../Master/MasterWarehouse/SelectDataMaster'
import SelectDataShipmentOrder from '../Selects/SelectDataShipmentOrder';
import { useEffect } from 'react';
import { Icon, Input, Selection, showToast } from '../../../../../components/ui';

const InBoundHeader = (props) => {
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramSite = "warehouse/Sites/Sites";
    const paramWarehouse = "warehouse/RegSitesWarehouses/RegSitesWarehouses";
    const paramWarehouseLocation = "warehouse/warehouselocation/warehouselocation";
    const paramShipmentOrder = "shipmentOrder/shipmentOrder";
    const paramRegJobType = "regJobType/regJobType"
    const paramInboundCategory = "InboundCategory/InboundCategory"

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(50);

    const [titleModalSite, setTitleModalSite] = useState('');
    const [isModalSite, setIsModalSite] = useState(false);
    const [isLoadingModalSite, setIsLoadingModalSite] = useState(false);
    const [dataModalSite, setDataModalSite] = useState([]);
    const [dataMapModalSite, setDataMapModalSite] = useState([]);
    const [columnDataModalSite, setColumnDataModalSite] = useState([]);
    const [SelectedDataModalSite, setSelectedDataModalSite] = useState({});
    const [totalRowsModalSite, setTotalRowsModalSite] = useState(50);

    const [titleModalWarehouse, setTitleModalWarehouse] = useState('');
    const [isModalWarehouse, setIsModalWarehouse] = useState(false);
    const [isLoadingModalWarehouse, setIsLoadingModalWarehouse] = useState(false);
    const [dataModalWarehouse, setDataModalWarehouse] = useState([]);
    const [dataMapModalWarehouse, setDataMapModalWarehouse] = useState([]);
    const [columnDataModalWarehouse, setColumnDataModalWarehouse] = useState([]);
    const [SelectedDataModalWarehouse, setSelectedDataModalWarehouse] = useState({});
    const [totalRowsModalWarehouse, setTotalRowsModalWarehouse] = useState(50);

    const [titleModalWarehouseLocation, setTitleModalWarehouseLocation] = useState('');
    const [isModalWarehouseLocation, setIsModalWarehouseLocation] = useState(false);
    const [isLoadingModalWarehouseLocation, setIsLoadingModalWarehouseLocation] = useState(false);
    const [dataModalWarehouseLocation, setDataModalWarehouseLocation] = useState([]);
    const [dataMapModalWarehouseLocation, setDataMapModalWarehouseLocation] = useState([]);
    const [columnDataModalWarehouseLocation, setColumnDataModalWarehouseLocation] = useState([]);
    const [SelectedDataModalWarehouseLocation, setSelectedDataModalWarehouseLocation] = useState({});
    const [totalRowsModalWarehouseLocation, setTotalRowsModalWarehouseLocation] = useState(50);

    const [titleModalShipmentOrder, setTitleModalShipmentOrder] = useState('');
    const [isModalShipmentOrder, setIsModalShipmentOrder] = useState(false);
    const [isLoadingModalShipmentOrder, setIsLoadingModalShipmentOrder] = useState(false);
    const [dataModalShipmentOrder, setDataModalShipmentOrder] = useState([]);
    const [dataMapModalShipmentOrder, setDataMapModalShipmentOrder] = useState([]);
    const [columnDataModalShipmentOrder, setColumnDataModalShipmentOrder] = useState([]);
    const [SelectedDataModalShipmentOrder, setSelectedDataModalShipmentOrder] = useState({});
    const [totalRowsModalShipmentOrder, setTotalRowsModalShipmentOrder] = useState(50);

    const [jobTypeList, setJobTypeList] = useState([])
    const [jobTypeCode, setJobTypeCode] = useState('')

    const SelectShipmentOrder = (
        <Selection
            id='0'
            label={'Job Type'}
            fullWidth={true}
            placeholder={'Select Job Type'}
            value={jobTypeCode}
            onChange={(e) => setJobTypeCode(e.target.value)}
        >
            {
                jobTypeList.map((data, index) => {
                    return <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                })
            }
        </Selection>
    )

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataProductCategory(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClickSite = () => {
        if (isModalSite === false) {
            setIsModalSite(true);
            getDataSite(1, 50);
        }
        else {
            setIsModalSite(false);
        }
    };

    const handleClickWarehouse = () => {
        if (isModalWarehouse === false) {
            setIsModalWarehouse(true);
            getDataWarehouse(1, 50);
        }
        else {
            setIsModalWarehouse(false);
        }
    };

    const handleClickWarehouseLocation = () => {
        if (isModalWarehouseLocation === false) {
            setIsModalWarehouseLocation(true);
            getDataWarehouseLocation(1, 50);
        }
        else {
            setIsModalWarehouseLocation(false);
        }
    };

    const handleClickShipmentOrder = () => {
        if (isModalShipmentOrder === false) {
            setIsModalShipmentOrder(true);
            getDataShipmentOrder(1, 50);
        }
        else {
            setIsModalShipmentOrder(false);
        }
    };

    const getDataProductCategory = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(paramProductCategory, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({})
                    setDataModal(response.data.data.productCategory);

                    let temp = response.data.data.productCategory
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModal(indexed)
                    setColumnDataModal(response.data.data.columns)
                    setTotalRowsModal(response.data.totalRowCount)

                    setIsLoadingModal(false)
                }
                setIsLoadingModal(false)
            })
            .catch(function (error) {
                setIsLoadingModal(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataSite = (pageNumber, pageSize, filters) => {
        setIsLoadingModalSite(true);
        ApiService.PostByPage(paramSite, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalSite({})
                    setDataModalSite(response.data.data.sites);

                    let temp = response.data.data.sites
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalSite(indexed)
                    setColumnDataModalSite(response.data.data.columns)
                    setTotalRowsModalSite(response.data.totalRowCount)

                    setIsLoadingModalSite(false)
                }
                setIsLoadingModalSite(false)
            })
            .catch(function (error) {
                setIsLoadingModalSite(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataWarehouse = (pageNumber, pageSize, filters) => {
        setIsLoadingModalWarehouse(true);
        filters = [{
            "field": "sitesId",
            "data": props.siteId.toString()
        }]
        ApiService.PostByPage(paramWarehouse, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalWarehouse({})
                    setDataModalWarehouse(response.data.data.sitesWarehouses);
                    let temp = response.data.data.sitesWarehouses
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalWarehouse(indexed)
                    setColumnDataModalWarehouse(response.data.data.columns)
                    setTotalRowsModalWarehouse(response.data.totalRowCount)

                    setIsLoadingModalWarehouse(false)
                }
                setIsLoadingModalWarehouse(false)
            })
            .catch(function (error) {
                setIsLoadingModalWarehouse(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataWarehouseLocation = (pageNumber, pageSize, filters) => {
        setIsLoadingModalWarehouseLocation(true);
        filters = [{
            "field": "warehouseId",
            "data": props.warehouseId.toString()
        }]
        ApiService.PostByPage(paramWarehouseLocation, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalWarehouseLocation({})
                    setDataModalWarehouseLocation(response.data.data.warehouseLocation);

                    let temp = response.data.data.warehouseLocation
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalWarehouseLocation(indexed)
                    setColumnDataModalWarehouseLocation(response.data.data.columns)
                    setTotalRowsModalWarehouseLocation(response.data.totalRowCount)

                    setIsLoadingModalWarehouseLocation(false)
                }
                setIsLoadingModalWarehouseLocation(false)
            })
            .catch(function (error) {
                setIsLoadingModalWarehouseLocation(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataShipmentOrder = (pageNumber, pageSize, filters) => {
        setIsLoadingModalShipmentOrder(true);
        if (filters) {
            filters = [...filters, { "field": "jobTypeCode", "data": jobTypeCode }]
        } else {
            filters = [{ "field": "jobTypeCode", "data": jobTypeCode }]
        }
        ApiService.OperationPostByPage(paramShipmentOrder, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalShipmentOrder({})
                    setDataModalShipmentOrder(response.data.data.shipmentOrder);

                    let temp = response.data.data.shipmentOrder
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })
                    const filteredColumn = response.data.data.columns.headerColumns.filter(item => {
                        return item.column === 'shipmentNo' || item.column === 'bookingNo' || item.column === 'shipperName' || item.column === 'agentName' || item.column === 'consigneeName'
                    })
                    setDataMapModalShipmentOrder(indexed)
                    setColumnDataModalShipmentOrder(filteredColumn)
                    setTotalRowsModalShipmentOrder(response.data.totalRowCount)

                    setIsLoadingModalShipmentOrder(false)
                }
                setIsLoadingModalShipmentOrder(false)
            })
            .catch(function (error) {
                setIsLoadingModalShipmentOrder(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const searchProductCategory = () => {
        setTitleModal("Product Category");
        handleClick();
    }

    const searchSite = () => {
        setTitleModalSite("Site");
        handleClickSite();
    }

    const searchWarehouse = () => {
        if (!props.siteId) {
            showToast({ type: 'error', message: 'Select Site!' })
            return
        }
        setTitleModalWarehouse("Warehouse")
        handleClickWarehouse();
    }

    const searchWarehouseLocation = () => {
        if (!props.warehouseId) {
            showToast({ type: 'error', message: 'Select Warehouse!' })
            return
        }
        setTitleModalWarehouseLocation("Warehouse Location")
        handleClickWarehouseLocation();
    }

    const searchShipmentOrder = () => {
        ApiService.PostByPage(paramRegJobType, 1, 100, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setJobTypeList(response.data.data.jobType)
                    if (jobTypeCode === '') {
                        setJobTypeCode(response.data.data.jobType[0].code)
                    }
                    setTitleModalShipmentOrder("Job/Shipment Order")
                    handleClickShipmentOrder();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    }

    const selectSite = (selectedData) => {
        props.setSiteId(selectedData.id);
        props.setSiteName(selectedData.name);
        props.setWarehouseId('');
        props.setWarehouseName('');
        props.setWarehouseLocationId('');
        props.setWarehouseLocationName('');
    }

    const selectWarehouse = (selectedData) => {
        props.setIsLoadingBackDrop(true);
        props.setWarehouseId(selectedData.warehousesId);
        props.setWarehouseName(selectedData.warehousesName);
        props.setWarehouseLocationId('');
        props.setWarehouseLocationName('');
        ApiService.DynamicPostByPage(paramWarehouseLocation, 'PAGE', 1, 100, [
            {
                "attr": "warehouseId",
                "value": selectedData.warehousesId.toString(),
                "mathSign": "="
            },
            {
                "attr": "locationX",
                "value": selectedData.warehouseLocationX
            },
            {
                "attr": "locationY",
                "value": selectedData.warehouseLocationY
            },
            {
                "attr": "locationZ",
                "value": selectedData.warehouseLocationZ
            },
        ], [])
            .then((res) => {
                if (res.data.code === 200) {
                    const retData = res.data.data.warehouseLocation;
                    props.setWarehouseLocationId(retData[0]?.id);
                    props.setWarehouseLocationName(retData[0]?.name);
                    props.setIsLoadingBackDrop(false);
                }
                props.setIsLoadingBackDrop(false);
            })
    }

    const selectWarehouseLocation = (selectedData) => {
        props.setWarehouseLocationId(selectedData.id);
        props.setWarehouseLocationName(selectedData.name);
        props.InboundDetailList.map((data) => {
            data.warehouseLocationId = selectedData.id;
            data.warehouseLocationName = selectedData.name;
            return null
        })
    }

    const selectProductCategory = (selectedData) => {
        props.setIsLoadingBackDrop(true);
        if (selectedData.id !== props.productCategoryId) {
            props.setInBoundDetailList([]);
        }
        props.setProductCategoryId(selectedData.id);
        props.setProductCategoryName(selectedData.name);
        props.setSiteId(selectedData.sitesId);
        props.setSiteName(selectedData.sitesName);
        props.setWarehouseId(selectedData.warehousesId);
        props.setWarehouseName(selectedData.warehousesName);
        ApiService.DynamicPostByPage(paramWarehouseLocation, 'PAGE', 1, 100, [
            {
                "attr": "warehouseId",
                "value": selectedData.warehousesId.toString(),
                "mathSign": "="
            },
            {
                "attr": "locationX",
                "value": selectedData.warehouseLocationX
            },
            {
                "attr": "locationY",
                "value": selectedData.warehouseLocationY
            },
            {
                "attr": "locationZ",
                "value": selectedData.warehouseLocationZ
            },
        ], [])
            .then((res) => {
                const retData = res.data.data.warehouseLocation;
                props.setWarehouseLocationId(retData[0].id);
                props.setWarehouseLocationName(retData[0].name);
                props.setIsLoadingBackDrop(false);
            })
    }

    const selectInboundCategory = (selectedData) => {
        props.setInboundCategoryId(selectedData);
        props.setIsLoadingBackDrop(true);
        ApiService.PostByIdWarehouse(paramInboundCategory, selectedData)
            .then((res) => {
                const resData = res.data.data.inboundCategory;
                props.setInboundCategoryName(resData.name);
                if (resData.code === "RJCT" && props.warehouseId !== null && props.warehouseId !== '') {
                    // Pada tanggal 31 Mei 2024 jam 09:47 sudah berdiskusi dengan Pak Arif terkait dengan InBound Category 
                    // Proses auto set field Warehouse Location dimatikan berdasarkan hasil diskusi tersebut
                    // ApiService.PostByPage(paramWarehouseLocation, 1, 100, [], [
                    //     {
                    //         "attr": "warehouseId",
                    //         "value": props.warehouseId.toString(),
                    //         "mathSign": "="
                    //     }
                    // ])
                    // .then((res) => {
                    //     const retData = res.data.data.warehouseLocation;
                    //     if (retData.length > 0){
                    //         for (let i = 0; i < retData.length; i++) {
                    //             if (retData[i].warehouseLocationTypeId === 3){
                    //                 props.setWarehouseLocationId(retData[i].id);
                    //                 props.setWarehouseLocationName(retData[i].name);
                    //                 break;
                    //             }
                    //         }
                    //     }
                    //     props.setIsLoadingBackDrop(false);
                    // });
                }
                else {
                    props.setIsLoadingBackDrop(false);
                }
            });
    }

    useEffect(() => {
        getDataShipmentOrder(1, 5)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobTypeCode])

    function handleDecimalsOnValue(value) {
        const regex = /([0-9]*[,]{0,1}[0-9]{0,2})/s;
        props.setSquareMeter(value.match(regex)[0]);
    }

    return (
        <div>
            <form>
                <div>
                    <div>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="InBound Date"
                                    type={'date'}
                                    disabled={props.approved}
                                    value={props.InBoundDate}
                                    onChange={(e) => { props.setInBoundDate(e.target.value) }}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="InBound No"
                                    value={props.InBoundNo}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Selection
                                    id='0'
                                    label={'InBound Category'}
                                    fullWidth={true}
                                    placeholder={'Select InBound Category'}
                                    value={props.inboundCategoryId}
                                    onChange={(e) => selectInboundCategory(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.approved}
                                >
                                    {
                                        props.listInBoundCategory.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Input
                                    label="Job/Shipment Order"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.shipmentOrderNo}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.shipmentOrderNo && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setShipmentOrderNo('');
                                                                    props.setShipmentOrderId('');
                                                                    props.setShipperConsigneeId('');
                                                                    props.setShipperConsigneeName('')
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchShipmentOrder()} hidden={props.approved}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.approved}
                                />

                                <Input
                                    label="Shipper/Consignee"
                                    value={props.shipperConsigneeName}
                                    disabled={props.approved}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Product Category"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.productCategoryName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.productCategoryName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setSiteId('');
                                                                    props.setSiteName('');
                                                                    props.setWarehouseId('');
                                                                    props.setWarehouseName('');
                                                                    props.setInBoundDetailList([]);
                                                                    props.setProductCategoryId('');
                                                                    props.setWarehouseLocationId('');
                                                                    props.setProductCategoryName('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchProductCategory()} hidden={props.approved}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.approved}
                                />

                                <Input
                                    label="Site"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.siteName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.siteName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setSiteName('');
                                                                    props.setSiteId('')
                                                                    props.setWarehouseId('');
                                                                    props.setWarehouseName('');
                                                                    props.setWarehouseLocationId('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchSite()} hidden={props.approved}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.approved}
                                />

                                <Input
                                    label="Warehouse"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.warehouseName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.warehouseName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setWarehouseId('');
                                                                    props.setWarehouseName('');
                                                                    props.setWarehouseLocationId('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchWarehouse()} hidden={props.approved}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.approved}
                                />

                                <Input
                                    label="Warehouse Location"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    disabled={props.approved}
                                    value={props.warehouseLocationName}
                                    onChange={(e) => props.setWarehouseLocationName(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.warehouseLocationName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchWarehouseLocation()} hidden={props.approved}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Input
                                    label="Square Meter"
                                    value={props.squareMeter}
                                    onChange={(e) => handleDecimalsOnValue(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled={props.approved}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataProductCategory}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}

                        selectHandler={selectProductCategory}
                    />
                )
            }

            {
                isModalSite && (
                    <SelectDataMaster
                        isModal={isModalSite}
                        handleClick={handleClickSite}
                        titleModal={titleModalSite}
                        setIsModal={setIsModalSite}

                        getData={getDataSite}
                        isLoading={isLoadingModalSite}
                        totalRows={totalRowsModalSite}
                        setTotalRows={setTotalRowsModalSite}
                        SelectedData={SelectedDataModalSite}
                        setSelectedData={setSelectedDataModalSite}
                        data={dataModalSite}
                        columnData={columnDataModalSite}
                        dataMap={dataMapModalSite}

                        selectHandler={selectSite}
                    />
                )
            }

            {
                isModalWarehouse && (
                    <SelectDataMaster
                        isModal={isModalWarehouse}
                        handleClick={handleClickWarehouse}
                        titleModal={titleModalWarehouse}
                        setIsModal={setIsModalWarehouse}

                        getData={getDataWarehouse}
                        isLoading={isLoadingModalWarehouse}
                        totalRows={totalRowsModalWarehouse}
                        setTotalRows={setTotalRowsModalWarehouse}
                        SelectedData={SelectedDataModalWarehouse}
                        setSelectedData={setSelectedDataModalWarehouse}
                        data={dataModalWarehouse}
                        columnData={columnDataModalWarehouse}
                        dataMap={dataMapModalWarehouse}

                        selectHandler={selectWarehouse}
                    />
                )
            }

            {
                isModalWarehouseLocation && (
                    <SelectDataMaster
                        isModal={isModalWarehouseLocation}
                        handleClick={handleClickWarehouseLocation}
                        titleModal={titleModalWarehouseLocation}
                        setIsModal={setIsModalWarehouseLocation}

                        getData={getDataWarehouseLocation}
                        isLoading={isLoadingModalWarehouseLocation}
                        totalRows={totalRowsModalWarehouseLocation}
                        setTotalRows={setTotalRowsModalWarehouseLocation}
                        SelectedData={SelectedDataModalWarehouseLocation}
                        setSelectedData={setSelectedDataModalWarehouseLocation}
                        data={dataModalWarehouseLocation}
                        columnData={columnDataModalWarehouseLocation}
                        dataMap={dataMapModalWarehouseLocation}

                        selectHandler={selectWarehouseLocation}
                    />
                )
            }

            {
                isModalShipmentOrder && (
                    <SelectDataShipmentOrder
                        isModal={isModalShipmentOrder}
                        handleClick={handleClickShipmentOrder}
                        titleModal={titleModalShipmentOrder}
                        setIsModal={setIsModalShipmentOrder}

                        getData={getDataShipmentOrder}
                        isLoading={isLoadingModalShipmentOrder}
                        totalRows={totalRowsModalShipmentOrder}
                        setTotalRows={setTotalRowsModalShipmentOrder}
                        SelectedData={SelectedDataModalShipmentOrder}
                        setSelectedData={setSelectedDataModalShipmentOrder}
                        data={dataModalShipmentOrder}
                        columnData={columnDataModalShipmentOrder}
                        dataMap={dataMapModalShipmentOrder}

                        setId={props.setShipmentOrderId}
                        setName={props.setShipmentOrderNo}
                        SelectShipmentOrder={SelectShipmentOrder}
                        jobTypeList={jobTypeList}
                        setShipperConsigneeId={props.setShipperConsigneeId}
                        setShipperConsigneeName={props.setShipperConsigneeName}
                        setWarehouseId={props.setWarehouseId}
                        setWarehouseName={props.setWarehouseName}
                        setSiteId={props.setSiteId}
                        setSiteName={props.setSiteName}
                    />
                )
            }
        </div>
    )
}

export default InBoundHeader;