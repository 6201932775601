import { IconButton, Stack, InputAdornment, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Icon, Input, showToast } from '../../../../components/ui'
import { convertToNominal } from '../../../../utils/masks.jsx'

const TabCustomer = (props) => {
    useEffect(() => {
        setIsValidPhone(phoneRegex.test(props.phone));
        setIsValidEmail(emailRegex.test(props.email));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.phone, props.email]);

    const [isValidPhone, setIsValidPhone] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);

    // Regex untuk nomor telepon Indonesia
    const phoneRegex = /^[\d+\-\s]{9,14}$/;

    // Regex untuk validasi email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleChangePhone = (value) => {
        setIsValidPhone(phoneRegex.test(value));
        props.setPhone(value);
    };

    const handleChangeEmail = (value) => {
        props.setEmail(value);
        setIsValidEmail(emailRegex.test(value));
    };

    const cariCustomer = (data) => {
        if (data === 'search') {
            if (props.jobServiceType !== '') {
                props.setTipe(23);
                props.setTitleModal('Customer');
                localStorage.setItem("ContactType", 23);
                props.handleClick();
            } else {
                showToast({ type: 'error', message: "Please Select Job Type First!" });
            }
        }
        else {
            props.setIdCustomer(0);
            props.setCustomerName('');
            props.setCustomerAddress('');
            props.setPic('');
            props.setEmail('');
            props.setPhone('');
            let formatUSD = convertToNominal(0);
            let formatIDR = convertToNominal(0);
            props.setCreditTermUsd(formatUSD);
            props.setCreditTermIdr(formatIDR);
            props.setCreditTermUsdReal(0);
            props.setCreditTermDays(0);
            props.setCreditTermIdrReal(0);
            if (props.contactTypeId === 2) {
                props.setIdShipper(0);
                props.setShipperName('');
                props.setShipperAddress('');
            } else {
                props.setIdConsignee(0);
                props.setConsigneeName('');
                props.setConsigneeAddress('');
            }
        }
    }

    const mainContainer = () => {
        return (
            <>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                    }}
                >

                    {
                        props.jobServiceType === 'Export' || props.jobServiceType === 'Domestic' ?
                            <>Customer / Shipper</>
                            :
                            props.jobServiceType === 'Import' ?
                                <>Customer / Consignee</>
                                :
                                <></>
                    }
                </Typography>

                <Stack spacing={'16px'} direction="row" className='mt-2'>
                    <Input
                        label={'Code'}
                        value={props.idCustomer}
                        message={props.idCustomer !== 0 ? '' : 'This Field is Required'}
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '24px'
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true
                        }}
                    />

                    <Input
                        label="Name"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.CustomerName}
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '24px'
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => cariCustomer('delete')}
                                        disabled={props.isApproved}
                                        hidden={props.CustomerName && !props.isApproved ? false : true}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => cariCustomer('search')}
                                        disabled={props.isApproved}
                                        hidden={props.isApproved}
                                    >
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        disabled
                    />
                </Stack>

                <Input
                    label={'Address'}
                    value={props.CustomerAddress}
                    multiline
                    fullWidth
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: props.idCustomer !== 0 ? '24px' : '2px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        rows: 4,
                        sx: {
                            padding: '8px',
                        },
                    }}
                />

                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '32px' }}>
                    <Input
                        label={'PIC'}
                        value={props.pic}
                        disabled={props.isApproved ? true : false}
                        onChange={(e) => props.setPic(e.target.value)}
                        message={props.pic ? '' : 'This Field is Required'}
                        formControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    />

                    <Input
                        label={'Email'}
                        value={props.email}
                        onChange={(e) => handleChangeEmail(e.target.value)}
                        type={'email'}
                        disabled={props.isApproved ? true : false}
                        message={props.email === '' || props.email === '-' || isValidEmail ? '' : 'Email Format is Wrong'}
                        formControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    />

                    <Input
                        label={'Phone'}
                        value={props.phone}
                        onChange={(e) => handleChangePhone(e.target.value)}
                        type={'text'}
                        disabled={props.isApproved ? true : false}
                        message={props.phone === '' || isValidPhone ? '' : 'Phone Number Format is Wrong'}
                        formControlProps={{
                            sx: {
                                width: '100%'
                            }
                        }}
                    />
                </Stack>

                <div style={{ marginTop: props.pic ? '50.5px' : '28px' }}>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                        }}
                    >
                        Credit Term
                    </Typography>

                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '6px' }}>
                        <Input
                            label={'Days'}
                            value={props.creditTermDays}
                            onChange={(e) => props.creditTermDays(e.target.value)}
                            disabled
                            type={'text'}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                        />

                        <Input
                            label={'USD'}
                            textAlign='right'
                            value={props.creditTermUsd}
                            onChange={(e) => props.creditTermUsd(e.target.value)}
                            disabled
                            type={'text'}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                        />

                        <Input
                            label={'IDR'}
                            textAlign='right'
                            value={props.creditTermIdr}
                            onChange={(e) => props.creditTermIdr(e.target.value)}
                            disabled
                            type={'text'}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                        />
                    </Stack>
                </div>
            </>
        )
    }

    return (
        <>
            {
                props.isApproved ?
                    <fieldset disabled>
                        {mainContainer()}
                    </fieldset>
                    :
                    <fieldset>
                        {mainContainer()}
                    </fieldset>
            }
        </>
    )
}

export default TabCustomer