import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import React, { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import ModalInboundDetail from '../Modals/ModalInboundDetail';
import { showToast } from '../../../../../components/ui';

const InBoundDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const selectedStyle = { bgcolor: '#CCCCCC' };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);

    const [Id, setId] = useState(0);
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productCode, setProductCode] = useState('');
    const [expiredDate, setExpiredDate] = useState('');
    const [batchNo, setBatchNo] = useState('');
    const [unitId, setUnitId] = useState('');
    const [unitName, setUnitName] = useState('');
    const [unitCode, setUnitCode] = useState('');
    const [qty, setQty] = useState('');

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddInBoundDetail = () => {
        if (!props.productCategoryId) {
            showToast({ type: 'error', message: 'Select Product Category!' });
            return
        }
        emptyState();
        setExpiredDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
        setDetailEdit(false);
        setTitleModal('Add InBound Detail');
        setDetailSequence(props.InboundDetailList.length);
        handleClick();
    };

    const ShowEditInBoundDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setDetailEdit(true);
                setTitleModal('Edit InBound Detail');

                setProductId(selectedDetail.productId);
                setProductName(selectedDetail.productName);
                setProductCode(selectedDetail.productCode);
                setExpiredDate(selectedDetail.expiredDate.split('T')[0]);
                setBatchNo(selectedDetail.batchNo);
                setUnitId(selectedDetail.unitId);
                setUnitName(selectedDetail.unitName);
                setUnitCode(selectedDetail.unitCode);
                setQty(selectedDetail.quantity);
                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted' });
            }
        }
    };

    const DeleteInBoundDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.InboundDetailList.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }
            props.setInBoundDetailList(result);

            setSelectedDetail({});
        }
    }

    const handleDoubleClick = () => {
        ShowEditInBoundDetail();
    }

    const emptyState = () => {
        setId(0);
        setProductId('');
        setProductName('');
        setProductCode('');
        setExpiredDate('');
        setBatchNo('');
        setUnitId('');
        setUnitName('');
        setUnitCode('')
        setQty('');
    }

    return (
        <div>
            <form>
                <div>
                    <Typography
                        sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        InBound Detail
                    </Typography>

                    <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px', marginTop: '16px' }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader>
                            <TableHead
                                sx={{
                                    '& th:first-of-type': {
                                        borderRadius: '12px 0 0'
                                    },
                                    '& th:last-child': {
                                        borderRadius: '0 12px 0 0'
                                    }
                                }}
                            >
                                <TableRow>
                                    <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Product</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Expiry Date</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Batch No</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>UoM</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Quantity</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Warehouse Location</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    props.InboundDetailList.length > 0 ?
                                        props.InboundDetailList.map((el) => {
                                            return (
                                                <TableRow
                                                    key={el.sequence}
                                                    onClick={() => setSelectedDetail(el)}
                                                    onDoubleClick={() => handleDoubleClick()}
                                                    sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                >
                                                    <StyledTableBodyCell sx={{ textAlign: 'left', }}>{el.productName}</StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'left', }}>{el.expiredDate.split('T')[0]}</StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'left', }}>{el.batchNo}</StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'left', }}>{el.unitName}</StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'right', }}>{el.quantity}</StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'left', }}>{el.warehouseLocationName}</StyledTableBodyCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                            <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px',
                                                        fontstyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal'
                                                    }}
                                                >
                                                    Data Empty
                                                </Typography>
                                            </StyledTableBodyCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div style={{ marginTop: '16px' }}>
                        {
                            CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddInBoundDetail, ShowEditInBoundDetail, DeleteInBoundDetail, props.approved)
                        }
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <ModalInboundDetail
                        isModal={isModal}
                        setIsModal={setIsModal}
                        titleModal={titleModal}
                        handleClick={handleClick}

                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        emptyState={emptyState}
                        approved={props.approved}
                        InboundDetailList={props.InboundDetailList}
                        setInBoundDetailList={props.setInBoundDetailList}

                        Id={Id}
                        setId={setId}
                        productId={productId}
                        setProductId={setProductId}
                        productName={productName}
                        setProductName={setProductName}
                        productCode={productCode}
                        setProductCode={setProductCode}
                        expiredDate={expiredDate}
                        setExpiredDate={setExpiredDate}
                        batchNo={batchNo}
                        setBatchNo={setBatchNo}
                        unitId={unitId}
                        setUnitId={setUnitId}
                        unitName={unitName}
                        setUnitName={setUnitName}
                        unitCode={unitCode}
                        setUnitCode={setUnitCode}
                        qty={qty}
                        setQty={setQty}
                        warehouseLocationId={props.warehouseLocationId}
                        warehouseLocationName={props.warehouseLocationName}
                        warehouseLocationX={props.warehouseLocationX}
                        warehouseLocationY={props.warehouseLocationY}
                        warehouseLocationZ={props.warehouseLocationZ}
                        productCategoryId={props.productCategoryId}
                    />
                )
            }
        </div>
    )
}

export default InBoundDetail;