import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService from '../../../../services/ApiService';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const ModalContact = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);

    function Cancel() {
        props.handleClick();
    }

    function AddNew() {
        props.setIsNewReg(false);
        props.handleClick();
        props.setIsSearch(true);
    }

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            props.setIsLoadingBackDrop(true);
            props.setSelectedData(props.SelectedData);
            props.setContactId(props.SelectedData.id);
            props.setContactCode(props.SelectedData.code);
            props.setContactName(props.SelectedData.contactName);
            props.setContactType(props.SelectedData.contactStatus);
            props.handleClick()
            props.setIsSearch(true);
            ApiService.PostById("regContact/regContact", props.SelectedData.id)
                .then((res) => {
                    if (res.data.code === 200) {
                        if (res.data.data.contact.id !== undefined) {
                            props.setIsNewReg(true);
                            props.setId(res.data.data.contact.id);
                            props.setContactPerson(res.data.data.contact.contactPerson);
                            props.setContactNIK(res.data.data.contact.nik);
                            props.setContactPhone(res.data.data.contact.phone);
                            props.setContactFax(res.data.data.contact.fax);
                            props.setContactEmail(res.data.data.contact.email);
                            props.setContactNPWP(res.data.data.contact.npwp);
                            props.setContactNPPKP(res.data.data.contact.nppkp);
                            props.setCreditTermInDays(res.data.data.contact.creditTermInDays);
                            props.setCreditTermInIDR(res.data.data.contact.creditTermInIDR);
                            props.setCreditTermInUSD(res.data.data.contact.creditTermInUSD);
                            props.setShipmentStatus(res.data.data.contact.shipmentStatus);
                            props.setMarketId(res.data.data.contact.marketId);
                            props.setMarketingCode(res.data.data.contact.marketingCode);
                            props.setMarketingName(res.data.data.contact.marketingName);
                            props.setGroupId(res.data.data.contact.groupId);
                            props.setGroupCode(res.data.data.contact.groupCode);
                            props.setGroupName(res.data.data.contact.groupName);
                            props.setListContactType(res.data.data.contact.contactTypes);
                            props.setListAddress(res.data.data.contact.contactAddresses);
                            let seq = 1;
                            let array = res.data.data.contact.contactJobOwners;
                            array.forEach(element => {
                                element.sequence = seq;
                                seq = seq + 1;
                            });
                            props.setListJobOwner(array);
                            props.setListBankAccount(res.data.data.contact.contactBanks);
                            props.setContactType(res.data.data.contact.contactMaster.contactStatus);

                            if (res.data.data.contact.contactGroups.length > 0) {
                                props.setGroupId(res.data.data.contact.contactGroups[0].contactGroupId);
                                props.setGroupCode(res.data.data.contact.contactGroups[0].contactGroupCode);
                                props.setGroupName(res.data.data.contact.contactGroups[0].contactGroupName);
                            }
                        }

                        props.setIsLoadingBackDrop(false);
                    }
                    else if (res.data.code === 204) {
                        props.setIsNewReg(false);
                        props.setIsLoadingBackDrop(false);
                    }
                    else {
                        props.setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Error When Read Reg Contact Data!, ' + res.data.message })
                    }
                })
                .catch(function (error) {
                    props.setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: error.toString() })
                })
        }
    }

    function CellDoubleClick(row) {
        Select()
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, []);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, []);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={props.setFilter}
                                            setFilterBy={props.setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal + '-' + props.contactName}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    <Box display="flex" justifyContent="flex-end">
                        <Stack spacing={'12px'} direction="row" className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            {ButtonComponent.SelectButton(Select, true)}
                            {ButtonComponent.AddButton(AddNew, 'New Contact', false, false, true)}
                        </Stack>
                    </Box>
                </div>
            </div>
        </PopUp>
    )
}

export default ModalContact