import { Grid, Box, MenuItem, Card, CardContent } from '@mui/material';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import ButtonLiftingDestinationPortView from './Buttons/ButtonLiftingDestinationPortView';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';

const LiftingDestinationPortView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [dataJobType, setDataJobType] = useState([]);

    const [jobType, setJobType] = useState(1);
    const [yearPeriod, setYearPeriod] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Lifting Destination Port Report', link: '/Majura/LiftingDestinationPortReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Lifting Destination Port Report - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OSHOR')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        GetDataJobType();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    setDataJobType(response.data.data.jobType);
                    getDateNow();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    function getDateNow() {
        let dateNow = new Date()
        setYearPeriod(dateNow.getFullYear());
    }

    const selectJobType = (jobType, setJobType) => {
        return (
            <Selection
                label="Shipment Order"
                placeholder={'Select Shipment Order'}
                value={jobType}
                onChange={(e) => setJobType(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
            >
                {
                    dataJobType.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.id}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        )
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCM() {
        if (titleConfirmation === 'Print') {
            PrintPDF();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print')
        setTitleModalConfirmation('Lifting Destination Port');
        handleClickConfirmation();
    };

    function PrintPDF() {

    }

    const ButtonComponents = () => {
        return (
            <ButtonLiftingDestinationPortView
                PrintData={PrintData}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Lifting Destination Port Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div className="mt-3">
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={6}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={4}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                {selectJobType(jobType, setJobType)}
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    label={'Year'}
                                                    placeholder={'Select Year'}
                                                    value={yearPeriod}
                                                    onChange={(e) => setYearPeriod(e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                >
                                                    {
                                                        Array.from({ length: (new Date().getFullYear() - 2002 + 1) }, (_, index) => new Date().getFullYear() - index).map((year) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} key={year} value={year}>{year}</MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCM}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default LiftingDestinationPortView