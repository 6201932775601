import { Grid, Typography, } from '@mui/material';
import { Input } from '../../../../../components/ui';

const ReceiptVoucherTotalReceipt = (props) => {
    return (
        <div>
            <form>
                <div>
                    <Typography
                        sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            marginBottom: '12px'
                        }}
                    >
                        Total Receipt
                    </Typography>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label='Total Debit USD'
                                value={props.TotalDebitUSD}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label='Total Credit USD'
                                value={props.TotalCreditUSD}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label='Total Debit IDR'
                                value={props.TotalDebitIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label='Total Credit IDR'
                                value={props.TotalCreditIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label='Total Debit In IDR'
                                value={props.TotalDebitInIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label='Total Credit In IDR'
                                value={props.TotalCreditInIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label={props.LabelDifference}
                                value={props.TotalDifference}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    )
}

export default ReceiptVoucherTotalReceipt;