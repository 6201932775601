import { Box, Typography, Grid, MenuItem, Stack, Button } from '@mui/material';
import React from 'react';

import { Icon, PopUp, Selection } from '../ui';

const ModalContra = React.memo(({ titleModal, typeEdit, setTypeEdit, DataEditType, isModal, handleClick, EditContraData, ViewOnly, isAcc, isHiddenEdit = true }) => {
    const mainContainer = (
        <>
            {
                titleModal === 'Invoice' && typeEdit !== 'R' && (
                    <div className='mb-3'>
                        <Selection
                            id='0'
                            label={'Type'}
                            fullWidth={true}
                            placeholder={'Select Type'}
                            value={typeEdit}
                            onChange={(e) => setTypeEdit(e.target.value)}
                        >
                            {
                                DataEditType.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </div>
                )
            }

            <Box display="flex">
                <Stack direction={'column'}>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito',
                            marginTop: '3px !important',
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                        }}
                    >
                        {titleModal} Has Been Printed!
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Nunito',
                            marginTop: '3px !important',
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: '20px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                        }}
                    >
                        {titleModal === 'Invoice' && typeEdit === 'R' ? `${titleModal} Can't be Edited, Need ${titleModal} Delete Request First!` : `Edit this ${titleModal} will make Contra and New ${titleModal} Automatically!`}
                    </Typography>
                </Stack>
            </Box>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#083A50',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={'Edit Data ' + titleModal}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '24px 6px 16px' }}>
                    {mainContainer}
                </div>

                <div style={{ padding: '24px 6px 0px' }}>
                    <Box display="flex" alignItems="center">
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                {
                                    typeEdit !== 'R' && (
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            className='d-flex align-items-center'
                                            sx={{
                                                padding: '4px 12px !important',
                                                gap: '4px',
                                                marginInlineStart: '12px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '32px !important',
                                                borderRadius: '8px',
                                                background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                                display: isHiddenEdit? 'none !important' : undefined,
                                                '&:hover': {
                                                    backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                                }
                                            }}
                                            onClick={EditContraData}
                                            hidden={isHiddenEdit}
                                        >
                                            <Icon
                                                svgIconProps={{
                                                    htmlColor: '#E4E8EC',
                                                    sx: {
                                                        height: '14px',
                                                        width: '14px'
                                                    }
                                                }}
                                                iconName={'checklist'}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Nunito',
                                                    marginTop: '3px !important',
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    lineHeight: '20px',
                                                    textAlign: 'center',
                                                    color: '#E4E8EC',
                                                }}
                                            >
                                                Edit Data
                                            </Typography>
                                        </Button>
                                    )
                                }

                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #D20C0C)',
                                        background: 'rgba(210, 12, 12, 0.25)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #D20C0C)',
                                            background: 'rgba(210, 12, 12, 0.25)',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={handleClick}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#D20C0C',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'close'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#D20C0C',
                                        }}
                                    >
                                        Cancel
                                    </Typography>
                                </Button>

                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #0087C2)',
                                        background: 'var(--Blue-Button-Primary-1, #B3E3FF)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #0087C2)',
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #B3E3FF) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={ViewOnly}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '14px',
                                                width: '14px'
                                            }
                                        }}
                                        iconName={'documents'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#0087C2',
                                        }}
                                    >
                                        {isAcc ? 'Edit No Faktur' : 'View Only'}
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default React.memo(ModalContra);