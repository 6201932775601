import { convertToNominal } from '../../../../utils/masks.jsx';

export const formatCurrency = (value, currency) => {
  if (!value) return 0
  let number = 0
  if (currency === 'usd') {
    number = '$ ' + convertToNominal(value)
  } else {
    number = 'Rp ' + convertToNominal(value)
  }

  return number
}

export const calculateBC = (arrAmount) => {
  let totalAmount = {
    idr: 0,
    usd: 0,
  }

  if (!arrAmount) return totalAmount

  arrAmount.forEach(elm => {
    const currency = elm.amountCrr === 1 ? 'idr' : 'usd'
    if (elm.isIncome && elm.sign) {
      totalAmount[currency] += elm.amount
    } else {
      totalAmount[currency] -= elm.amount
    }
  });

  totalAmount.idr = formatCurrency(totalAmount.idr, 'idr');
  totalAmount.usd = formatCurrency(totalAmount.usd, 'usd');

  return totalAmount
}