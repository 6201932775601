import React from 'react'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import TableEPL from '../TableEPL/TableEPL';
import { Grid, Stack } from '@mui/material';
import { showToast } from '../../../../components/ui';

const TabIncShipper = (props) => {
  const title = 'Inc Shipper'
  const contactTypeName = 'Shipper'
  const contactTypeId = 2
  const income = true

  const handleAdd = () => {
    if (props.shipmentNo !== '') {
      props.handleAdd(title, contactTypeName, contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Please Select Shipment Number First!' });
    }
  }

  const handleEdit = () => {
    if (props.selectedDetailIncShipper.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleEdit(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleConvert = () => {
    if (props.selectedDetailIncShipper.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleConvert(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleDoubleClick = () => {
    handleEdit()
  }

  const handleDelete = () => {
    if (props.selectedDetailIncShipper.bookingDetailId === 0) {
      props.handleDelete(contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Can\'t Delete This Data!' });
    }
  }

  return (
    <div className='rounded-10'>
      <TableEPL
        key={1}
        isReadOnly={props.isReadOnly}
        dataList={props.IncShipperList}
        ignoreAccess={props.ignoreAccess}
        setDataList={props.setIncShipperList}
        setSelectedDetail={props.setSelectedDetailIncShipper}
        selectedDetail={props.selectedDetailIncShipper}
        handleDoubleClick={handleDoubleClick}
        contactTypeName={contactTypeName}
        ttlUsd={props.estIncUSDShipper}
        ttlIdr={props.estIncIDRShipper}
        totalSData={props.totalIncSShipper}
        totalNData={props.totalIncNShipper}
        key1={1}
        key2={1}
        type={1}
        openClose={props.openCloseIS}
        setOpenClose={props.setOpenCloseIS}
        openClose2={props.openCloseIS2}
        setOpenClose2={props.setOpenCloseIS2}
        dataDetail={props.dataDetail}
        setDataDetail={props.setDataDetail}
        countSum={props.countSum}
        countTotalData={props.countTotalData}
      />

      <Grid container item direction="row" className='mt-3'>
        <Grid item xs={10}>
          <Stack direction={'row'} spacing={'10px'}>
            {ButtonComponent.AddButton(handleAdd, '', props.isReadOnly)}
            {ButtonComponent.EditButton(handleEdit, '', props.isReadOnly)}
            {ButtonComponent.DeleteButton(handleDelete, '', props.isReadOnly)}
            {ButtonComponent.ConvertButton(handleConvert, props.isReadOnly, true)}
            {ButtonComponent.DemurrageButton(props.handleDemurrage, true, props.isReadOnly, false)}
          </Stack>
        </Grid>

        <Grid item xs={2} className='text-end' display={'flex'} justifyContent={'flex-end'}>
          {ButtonComponent.AddButton(() => props.handleAddSt(title, contactTypeName, contactTypeId, income), 'Storage', props.isReadOnly, true)}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(TabIncShipper)