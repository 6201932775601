import React from 'react';
import { userCode } from '../../../../services/ApiService';
import ButtonService from '../../ButtonService';
import { Input } from '../../../../components/ui';
import CreateEditModalMaster from '../../CreateEditModalMaster';

const CreateEditWarehouse = (props) => {
    const Save = () => {
        var data =
        {
            "warehouses": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "userCode": userCode
            }
        }
        // ButtonService.Save(props.titleModal, props.title, props.param, data);
        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                label="Code"
                value={props.code}
                onChange={(e) => props.setCode(e.target.value)}
                disabled={props.id === 0 ? false : true}
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
            />

            <Input
                label="Name"
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }
        </>
    )
}

export default CreateEditWarehouse