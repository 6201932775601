import React from 'react'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputBase,
  alpha,
  styled
} from '@mui/material';

import { InputProps } from './Input.Props';

const CustomInputBase = styled(InputBase)(({ theme, align }) => ({
  border: '1.5px solid',
  borderColor: '#989898',
  borderRadius: '8px',
  fontSize: 12,
  backgroundColor: 'transparent',
  display: 'flex',
  minHeight: '32px',
  padding: '0px 12px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  background: 'transparent',
  marginTop: '3px !important',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),
  '&.Mui-focused': {
    boxShadow: `${alpha('#989898', 0.25)} 0 0 0 0.2rem`,
    borderColor: '#989898',
  },
  '&.Mui-error': {
    borderColor: theme.palette.error.main,
    '&.Mui-focused': {
      boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.error.main,
    }
  },
  '&.Mui-readOnly': {
    cursor: 'default',
  },
  '&.Mui-disabled': {
    backgroundColor: '#EAEAEA',
    cursor: 'not-allowed',
  },
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    padding: 0,
    textAlign: align,
    cursor: 'inherit',
  },
}));

const handleKeyDown = (e) => {
  e.preventDefault(); // Prevent any keyboard input
};

const Input = ({
  // *** Props
  id,
  label,
  error,
  disabled,
  hidden,
  multiline,
  onChange,
  onFocus,
  readOnly,
  placeholder,
  type,
  value,
  defaultValue,
  required,
  message,
  textAlign = 'left',
  onKeyDown,

  // *** Default MUI Props
  formControlProps,
  formLabelProps,
  inputBaseProps,
}) => {
  return (
    <FormControl
      variant='standard'
      error={error}
      disabled={disabled}
      required={required}
      hidden={hidden}
      {...formControlProps}
    >
      {/* CUSTOM FORM LABEL */}
      {label && (
        <FormLabel
          htmlFor={formLabelProps?.htmlFor || id || ''}
          {...formLabelProps}
          sx={{
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontWeight: '600',
            color: '#083A50 !important',
            textAlign: textAlign,
            ...formLabelProps?.sx
          }}
        >
          {label}
        </FormLabel>
      )}

      {/* CUSTOM FORM INPUT */}
      <CustomInputBase
        id={id}
        error={error}
        multiline={multiline}
        onChange={onChange}
        onFocus={onFocus}
        readOnly={readOnly}
        placeholder={placeholder}
        type={type}
        value={value}
        defaultValue={defaultValue}
        align={textAlign}
        onKeyDown={type === 'date' || type === 'month' ? handleKeyDown : onKeyDown}
        {...inputBaseProps}
      />
      {message && (
        <FormHelperText error={error}>
          {message}
        </FormHelperText>
      )}
    </FormControl>
  )
}

Input.propTypes = InputProps

export default Input