import React from 'react';
import { Grid } from '@mui/material';

import ButtonComponent from '../../../../../../components/Buttons/ButtonComponent';

const ButtonAdditionalMemorial = ({
    ReloadData,
    Memorial,
    Adjust,
    Assessment,
    PrintExcel,
    DeleteData,
    isFilter,
    isCOA,
    handleShowWidget
}) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {ButtonComponent.MemorialButton(Memorial, 'Memorial')}
                    {ButtonComponent.AdjustButton(Adjust, 'Adjust')}
                    {ButtonComponent.AssessmentButton(Assessment, 'Report')}
                    {ButtonComponent.ExcelButton(PrintExcel, 'Print Excel')}
                    {ButtonComponent.DeleteButton(DeleteData, 'Data')}
                    {ButtonComponent.OpenCollapseButton(!isFilter, () => handleShowWidget('filter', !isFilter), !isFilter ? 'Hide Filter' : 'Show Filter')}
                    {ButtonComponent.OpenCollapseButton(!isCOA, () => handleShowWidget('coa', !isCOA), !isCOA ? 'Hide COA Detail' : 'Show COA Detail')}
                </Grid>
            </div>
        </div>
    )
}

export default ButtonAdditionalMemorial