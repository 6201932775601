import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";

import ApiService from "../../services/ApiService";
import { showToast } from '../../components/ui';

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const displayToast = (type = "success", msg) => {
    showToast({ type: type, message: msg })
};

class ButtonService {
    DeleteData = (param, id, callback) => {
        ApiService.deleteData(param, id).then((res) => {
            if (res?.data?.code === 200) {
                displayToast('success', "Data Deleted");
                if (callback) {
                    callback()
                } else {
                    window.location.reload();
                }
            } else {
                displayToast('error', 'Failed to delete data, contact the administrator')
            }
        }).catch((error) => {
            console.log('Error delete: ', error)
            displayToast('error', 'Failed to delete data, contact the administrator')
        });
    }

    UnDeleteData = (param, id, callback) => {
        ApiService.unDeleteData(param, id).then((res) => {
            if (res?.data?.code === 200) {
                displayToast('success', "Data Un-Deleted");
                if (callback) {
                    callback()
                } else {
                    window.location.reload();
                }
            } else {
                displayToast('error', 'Failed to delete data, contact the administrator')
            }
        }).catch((error) => {
            console.log('Error un-delete: ', error)
            displayToast('error', 'Failed to delete data, contact the administrator')
        });
    }

    ExportPDF = (data, title) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        console.log('data', data)
        doc.setFontSize(15);
        var headers = [Object.keys(data[0])]
        var data2 = [];
        for (let i = 0; i < data.length; i++) {
            data2 = [...data2, Object.values(data[i])]
        }
        console.log('headers', headers)
        console.log('data2', data2)
        let content = {
            startY: 50,
            head: headers,
            body: data2
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(title + ".pdf")
        showToast({ type: 'success', message: 'Export Data to PDF Success' })
    }


    ExportExcel = (data, title) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data1, title + fileExtension);
        showToast({ type: 'success', message: 'Export Data to Excel Success' })
    }

    Save = (titleModal, title, param, data, callback) => {
        var msg = ''
        if (titleModal === 'Add') {
            ApiService.createData(param, data).then((res) => {
                if (res?.data?.code === 200) {
                    msg = "Create Data " + title + " Success";
                    displayToast('success', msg);
                    if (callback) {
                        callback();
                    }
                } else {
                    const errMsg = res?.data?.error
                    if (errMsg && errMsg.indexOf('duplicate') !== -1) {
                        displayToast('error', 'Failed to create data, there is duplicate code or name.')
                    } else {
                        displayToast('error', 'Failed to update data, ' + errMsg)
                    }
                }
            })
                .catch(function (error) {
                    console.error('Create error: ', error)
                    displayToast('error', 'Failed to create data, contact the administrator')
                })
        } else {
            ApiService.updateData(param, data).then((res) => {
                if (res?.data?.code === 200) {
                    msg = 'Update Data ' + title + ' Success'
                    displayToast('success', msg);
                    if (callback) {
                        callback();
                    }
                } else {
                    const errMsg = res?.data?.error
                    if (errMsg && errMsg.indexOf('duplicate') !== -1) {
                        displayToast('error', 'Failed to update data, there is duplicate code or name.')
                    } else {
                        if (errMsg !== '') {
                            displayToast('error', errMsg)
                        }
                        else {
                            displayToast('error', 'Failed to update data, ' + errMsg)
                        }
                    }
                }
            })
                .catch(function (error) {
                    console.error('Update error: ', error)
                    displayToast('error', 'Failed to update data, contact the administrator')
                })
        }
    }

    Restore = (titleModal, title, param, data, callback) => {
        ApiService.CustomMasterUpdate(param, data)
            .then((res) => {
                if (res?.data?.code === 200) {
                    const msg = titleModal + " " + title + " Success";
                    displayToast('success', msg);
                    if (callback) {
                        callback();
                    }
                } else {
                    const errMsg = res?.data?.error
                    displayToast('error', `Failed to ${titleModal} ${title} ${errMsg}`);
                }
            })
            .catch(function (error) {
                displayToast('error', `Failed to ${titleModal} ${title} ${error}`);
            })
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ButtonService()