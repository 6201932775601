import { IconButton, MenuItem, InputAdornment } from '@mui/material';
import React from 'react';
import ApiService, { userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import ModalCompany from '../Branch/Selects/ModalCompany';
import { useState } from 'react';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, Selection } from '../../../components/ui';
import { defaultDate } from '../../../utils/date';

const CreateEditStorage = (props) => {
    const [isModalCompany, setIsModalCompany] = useState(false);
    const [titleModalCompany, setTitleModalCompany] = useState('');

    const [isLoadingCompany, setIsLoadingCompany] = useState(false);
    const [dataCompany, setDataCompany] = useState([]);
    const [dataMapCompany, setDataMapCompany] = useState([])
    const [columnDataCompany, setColumnDataCompany] = useState([])
    const [SelectedDataCompany, setSelectedDataCompany] = useState({});
    const [totalRowsCompany, setTotalRowsCompany] = useState(50)

    const paramCompany = "company/company";

    const Save = () => {
        var data =
        {
            "storage": {
                "rowStatus": "ACT",
                "id": props.id,
                "companyId": props.companyId,
                "name": props.name,
                "shipmentStatus": props.shipmentStatus,
                "deleted": false,
                "deletedBy": "",
                "deletedOn": defaultDate,
                "user": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const SearchCompany = () => {
        if (isModalCompany === false) {
            setIsModalCompany(true);
            setTitleModalCompany('Company')
            getDataCompany(1, 50)
        } else {
            setIsModalCompany(false);
        }
    }

    const getDataCompany = (pageNumber, pageSize, filters) => {
        setIsLoadingCompany(true);
        ApiService.PostByPage(paramCompany, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataCompany({})
                    setDataCompany(response.data.data.company);

                    let temp = response.data.data.company
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapCompany(indexed)
                    setColumnDataCompany(response.data.data.columns)
                    setTotalRowsCompany(response.data.totalRowCount)

                    setIsLoadingCompany(false)
                }
                setIsLoadingCompany(false)
            })
            .catch(function (error) {
                setIsLoadingCompany(false)
                console.error('error saat fetch', error)
            })
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                name="company"
                label="Company"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                disabled
                value={props.companyName}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { SearchCompany() }}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                name="name"
                label="Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
            />

            <Selection
                label="Shipment Status"
                placeholder={'Select Shipment Status'}
                value={props.shipmentStatus}
                onChange={(e) => props.setShipmentStatus(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    props.dataShipmentStatus.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModalCompany && (
                    <ModalCompany
                        isModal={isModalCompany}
                        handleClick={SearchCompany}
                        titleModal={titleModalCompany}
                        setIsModal={setIsModalCompany}

                        isLoading={isLoadingCompany}
                        data={dataCompany}
                        dataMap={dataMapCompany}
                        columnData={columnDataCompany}
                        SelectedData={SelectedDataCompany}
                        totalRows={totalRowsCompany}
                        setSelectedData={setSelectedDataCompany}
                        getData={getDataCompany}

                        setCompanyId={props.setCompanyId}
                        setCompanyName={props.setCompanyName}
                    />
                )
            }
        </>

    )
}
export default CreateEditStorage