import { IconButton, InputAdornment } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import ApiService, { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import ModalCoa from './Selects/ModalCoa';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, showToast } from '../../../components/ui';

const CreateEditCash = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCoa, setSelectedCoa] = useState(undefined)
    const [isModalCoa, setIsModalCoa] = useState(false);

    useEffect(() => {
        if (selectedData?.id) {
            getCoaById(selectedData.chartOfAccountId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedCoa) {
            formik.setFieldValue('chartOfAccountId', selectedCoa.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCoa])

    const errorToast = (location, closeModal = false) => {
        showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` });
        if (closeModal) handleClick()
    }

    const getCoaById = (id) => {
        setIsLoading(true)
        ApiService.PostById('chartOfAccount/chartOfAccount', id).then((res) => {
            if (res.data.code === 200) {
                const result = res.data.data.chartOfAccount
                setSelectedCoa(result)
            } else {
                errorToast('account')
            }
            setIsLoading(false)
        })
            .catch(function (error) {
                console.error('error saat fetch', error)
                errorToast('account')
                setIsLoading(false)
            })
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "cash": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-DataCash", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        masterCode: Yup.string().nullable(),
        name: Yup.string().trim().required('This field is required.'),
        abbreviation: Yup.string().nullable(),
        chartOfAccountId: Yup.number().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            masterCode: '',
            name: '',
            abbreviation: '',
            chartOfAccountId: ''
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Cash Name is Required' });
        }

        if (payload?.chartOfAccountId === '') {
            return showToast({ type: 'error', message: 'Chart of Account is Required' });
        }

        if (payload?.abbreviation === '') {
            return showToast({ type: 'error', message: 'Abbreviation is Required' });
        }

        const data =
        {
            "cash": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "abbreviation": payload?.abbreviation,
                "name": payload?.name,
                "masterCode": payload?.masterCode,
                "chartOfAccountId": payload?.chartOfAccountId,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const searchCoa = () => {
        setIsModalCoa(!isModalCoa)
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                disabled
                name="masterCode"
                label="Master Code"
                value={formik.values.masterCode}
                onBlur={formik.handleBlur}
                error={formik.touched.masterCode && Boolean(formik.errors.masterCode)}
                helperText={formik.touched.masterCode && formik.errors.masterCode}
                onChange={(e) => formik.setFieldValue('masterCode', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="name"
                label="Cash Name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="abbreviation"
                label="Abbreviation"
                value={formik.values.abbreviation}
                onBlur={formik.handleBlur}
                error={formik.touched.abbreviation && Boolean(formik.errors.abbreviation)}
                helperText={formik.touched.abbreviation && formik.errors.abbreviation}
                onChange={(e) => formik.setFieldValue('abbreviation', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="chartOfAccountId"
                label="Chart Of Account"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                value={selectedCoa?.accountTitle}
                error={formik.touched.chartOfAccountId && Boolean(formik.errors.chartOfAccountId)}
                helperText={formik.touched.chartOfAccountId && formik.errors.chartOfAccountId}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { searchCoa() }}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        isLoading={isLoading}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }

            {isModal && (
                <ModalCoa
                    isModal={isModalCoa}
                    handleClick={searchCoa}
                    titleModal={"Chart of Account"}

                    selectedData={selectedCoa}
                    setSelectedData={setSelectedCoa}

                    errorToast={errorToast}
                />
            )}
        </>

    )
})

export default CreateEditCash