import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

import ModalConNote from '../Modals/ModalConNote';
import { showToast } from '../../../../components/ui';

const TabConNote = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [conNote, setConNote] = useState('');
    const [commodity, setCommodity] = useState('');
    const [collie, setCollie] = useState(0);
    const [weight, setWeight] = useState(0);
    const [dimensionLong, setDimensionLong] = useState(0);
    const [dimensionWide, setDimensionWide] = useState(0);
    const [dimensionHigh, setDimensionHigh] = useState(0);
    const [cbm, setCBM] = useState(0);

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
        }
        else {
            setIsModal2(false);
        }
    };

    const ShowAddConNote = () => {
        setTitleModal2('Add Con Note');
        setDetailEdit(false);
        if (props.ConNoteData.length > 0) {
            let data = props.ConNoteData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        handleClick2();
    };

    const ShowEditConNote = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setTitleModal2('Edit Con Note');
                setDetailEdit(true);

                setConNote(selectedDetail.conNote);
                setCommodity(selectedDetail.commodity);
                setCollie(selectedDetail.collie);
                setWeight(selectedDetail.weight);
                setDimensionLong(selectedDetail.dimensionLong);
                setDimensionWide(selectedDetail.dimensionWide);
                setDimensionHigh(selectedDetail.dimensionHigh);
                setCBM(selectedDetail.cbm);

                handleClick2();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    }

    const DeleteConNote = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            for (let i = 0; i < props.dataTracking.length; i++) {
                if (props.dataTracking[i].sequenceConNote === tempSequence) {
                    DeleteDataTracking(props.dataTracking[i]);
                }
            }

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.ConNoteData.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setConNoteData(result);

            setSelectedDetail({});
            showToast({ type: 'success', message: 'Con Note Deleted Successfully!' });
        }
    }

    const DeleteDataTracking = (selectData) => {
        let tempSequence = selectData.sequence;

        const deleteFunction = (data) => {
            return data.sequence !== tempSequence;
        }

        const result = props.dataTracking.filter(deleteFunction);

        if (result.length > 0) {
            tempSequence = 0;

            result.forEach((el) => {
                tempSequence += 1;
                el.sequence = tempSequence;
            })
        }

        props.setDataTracking(result);
    }

    return (
        <>
            <Box component="div" sx={{ marginTop: '12px' }}>
                <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Con. Note</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Collie</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Weight</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>CBM</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Dimensi Long</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Dimensi Wide</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Dimensi High</StyledTableCell>
                                <StyledTableCell>Commodity</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.ConNoteData.length > 0 ?
                                    props.ConNoteData.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                onClick={() => setSelectedDetail(el)}
                                                onDoubleClick={() => ShowEditConNote()}
                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                hover={true}
                                            >
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.conNote}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.collie}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.weight}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.cbm}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.dimensionLong}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.dimensionWide}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{el.dimensionHigh}</StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.commodity}</StyledTableBodyCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <br></br>
                {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddConNote, ShowEditConNote, DeleteConNote)}
            </Box>
            
            {
                isModal2 && (
                    <ModalConNote
                        jobType={props.jobType}
                        isModal2={isModal2}
                        handleClick2={handleClick2}
                        titleModal2={titleModal2}
                        detailTypeId={props.detailTypeId}
                        selectedDetail={selectedDetail}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
        
                        ShipmentId={props.ShipmentId}
                        setShipmentId={props.setShipmentId}
                        ConNoteData={props.ConNoteData}
                        setConNoteData={props.setConNoteData}
        
                        conNote={conNote}
                        setConNote={setConNote}
                        commodity={commodity}
                        setCommodity={setCommodity}
                        collie={collie}
                        setCollie={setCollie}
                        weight={weight}
                        setWeight={setWeight}
                        dimensionLong={dimensionLong}
                        setDimensionLong={setDimensionLong}
                        dimensionWide={dimensionWide}
                        setDimensionWide={setDimensionWide}
                        dimensionHigh={dimensionHigh}
                        setDimensionHigh={setDimensionHigh}
                        cbm={cbm}
                        setCBM={setCBM}
                        setSelectedDetail={setSelectedDetail}
                    />
                )
            }
        </>
    )
}

export default TabConNote;