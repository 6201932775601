import { MenuItem, FormHelperText } from '@mui/material';
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import ApiService, { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditPort = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const [cityList, setCityList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [isLoading, setisLoading] = useState(true)

    useEffect(() => {
        if (isModal) {
            getCountryData()

            if (selectedData?.id) {
                getCityById(selectedData.cityId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    const errorToast = (location, closeModal = false) => {
        showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
        if (closeModal) handleClick()
    }

    const getCountryData = () => {
        ApiService.PostByPage('country/country', 1, 9999, [])
            .then((res) => {
                if (res.data.code === 200) {
                    setCountryList(res.data.data.country);
                } else {
                    errorToast('country', true)
                }

                if (!selectedData?.id) {
                    setisLoading(false)
                }
            }).catch(function (error) {
                errorToast('country', true)
            })
    }

    const getCityById = (cityId) => {
        if (!cityId) return

        ApiService.PostById('city/city', cityId).then((res) => {
            if (res?.data?.code === 200) {
                const cityResult = res.data.data.city
                formik.setFieldValue('countryId', cityResult.countryId)
                searchCityData(cityResult.countryId)
            } else {
                errorToast('city')
            }

            setisLoading(false)
        }).catch((error) => {
            console.log(error)
            errorToast('city')
        })
    }

    const searchCityData = (countryId) => {
        ApiService.CityPostBySearch('city/city', 1, 9999, countryId, []).then((res) => {
            if (res?.data?.code === 200) {
                setCityList(res.data.data.city)
            } else {
                errorToast('city')
            }
        }).catch((error) => {
            console.log(error)
            errorToast('city')
        })
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "port": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DatasetPort", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        code: Yup.string().trim().required('This field is required.'),
        abbreviation: Yup.string().nullable(),
        name: Yup.string().trim().required('This field is required.'),
        countryId: Yup.number().required('This field is required.'),
        cityId: Yup.number().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? {
            ...selectedData,
            countryId: '',
            cityId: selectedData?.cityId !== 0 ? selectedData.cityId : ''
        } : {
            code: '',
            abbreviation: '',
            name: '',
            countryId: '',
            cityId: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Name is Required!' });
        }

        if (payload?.code === '') {
            return showToast({ type: 'error', message: 'Code is Required!' });
        }

        if (payload?.countryId === '') {
            return showToast({ type: 'error', message: 'Country is Required!' });
        }

        if (payload?.cityId === '') {
            return showToast({ type: 'error', message: 'City is Required!' });
        }

        const data = {
            "port": {
                id: payload?.id,
                code: payload?.code,
                abbreviation: payload?.abbreviation,
                name: payload?.name,
                cityId: payload?.cityId,
                rowStatus: payload?.rowStatus || 'ACT',
                deletedOn: '1785-12-06T10:51:29.426Z',
                user: userCode,
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                id='code'
                name="code"
                label="Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
            />

            <Selection
                placeholder={'Select Country'}
                labelId="label-country"
                name='country'
                id="country"
                value={formik.values.countryId}
                label="Country"
                error={formik.touched.countryId && Boolean(formik.errors.countryId)}
                onChange={(e) => {
                    formik.setFieldValue('countryId', e.target.value)
                    searchCityData(e.target.value)
                }}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    countryList.map((country) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={country.id} value={country.id}>{country.name}</MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.countryId && formik.errors.countryId}</FormHelperText>
            </Selection>

            <Selection
                placeholder={'Select City'}
                labelId="label-city"
                name='cityId'
                id="city"
                value={formik.values.cityId}
                label="City"
                disabled={cityList.length === 0 ? true : false}
                error={formik.touched.cityId && Boolean(formik.errors.cityId)}
                onChange={(e) => {
                    formik.setFieldValue('countryId', e.target.value)
                    searchCityData(e.target.value)
                }}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    cityList.map((city) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={city.id} value={city.id}>{city.name}</MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.cityId && formik.errors.cityId}</FormHelperText>
            </Selection>

            <Input
                id='name'
                name="name"
                label="Port Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
            />

            <Input
                id='abbreviation'
                name="abbreviation"
                label="Abbreviation"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={formik.values.abbreviation}
                onBlur={formik.handleBlur}
                error={formik.touched.abbreviation && Boolean(formik.errors.abbreviation)}
                helperText={formik.touched.abbreviation && formik.errors.abbreviation}
                onChange={(e) => formik.setFieldValue('abbreviation', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        isLoading={isLoading}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditPort