import React, { useState } from 'react'
import { Box, Stack, Tab, Tabs } from '@mui/material'

const MasterTabs = () => {
  const [activeTab, setActiveTab] = useState('1')

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue)
  }

  return (
    <Stack direction={'column'} gap={2}>
      <Box>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab label="Item One" value={'1'} />
          <Tab label="Item Two" value={'2'} disabled />
          <Tab label="Item Three" value={'3'} />
        </Tabs>
      </Box>
    </Stack>
  )
}

export default MasterTabs