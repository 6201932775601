export const getActiveNodes = (dataMenu = [], pathname) => {
  const arrPath = pathname.split('/')
  const PATH = arrPath?.[2]?.toUpperCase() || ''

  if (!PATH || PATH === 'MAINMENU') return { expandedNode: [], selectedNode: '', selectedParentNode: '' }
  if (PATH === 'DASHBOARD') return { expandedNode: [], selectedNode: '0', selectedParentNode: '0' }

  let expandedNode = []
  let selectedNode = ''
  let selectedParentNode = ''

  mainLoop: for (let i = 0; i < (dataMenu?.length || 0); i++) {
    const nodeI = dataMenu[i]
    const nodeChildI = nodeI?.children || []
    selectedParentNode = nodeI?.id

    if (nodeChildI.length) {
      for (let j = 0; j < nodeChildI.length; j++) {
        const nodeJ = nodeChildI[j]
        const nodeChildJ = nodeJ?.children || []

        if (nodeChildJ.length) {
          for (let k = 0; k < nodeChildJ.length; k++) {
            const nodeK = nodeChildJ[k]

            if (nodeK?.url && getLastUrl(nodeK.url) === PATH) {
              selectedNode = String(nodeK.id)
              expandedNode = [String(nodeI.id), String(nodeJ.id)]
              break mainLoop;
            }
          }
        } else {
          if (nodeJ?.url && getLastUrl(nodeJ.url) === PATH) {
            selectedNode = String(nodeJ.id)
            expandedNode = [String(nodeI.id)]
            break mainLoop;
          }
        }
      }
    } else {
      if (nodeI?.url && getLastUrl(nodeI.url) === PATH) {
        selectedNode = String(nodeI.id)
        expandedNode = []
        break;
      }
    }
  }

  return {
    expandedNode,
    selectedNode,
    selectedParentNode,
  }
}

const getLastUrl = (url) => {
  const arrUrl = url.split('/')

  return arrUrl?.[arrUrl.length - 1]?.toUpperCase() || ''
}