import React from 'react';
import { Grid } from '@mui/material';
import ButtonComponent from '../../components/Buttons/ButtonComponent';

const GeneralButton = ({
  ReloadData,
  DataRegUserAccess,
  toggleAdd,
  toggleEdit,
  DeleteData,
  PrintData
}) => {

  const renderButton = (data) => {
    switch (data.accessCode) {
      case 'CRE':
        return ButtonComponent.AddButton(toggleAdd, 'New');
      case 'REI':
        return ButtonComponent.EditButton(toggleEdit, 'Data');
      case 'DEL':
        return ButtonComponent.DeleteButton(DeleteData, 'Data');
      case 'PRI':
        return ButtonComponent.PrintButton(PrintData);
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <Grid
          container
          item
          spacing={1}
          direction="row"
          style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
        >
          {ButtonComponent.ReloadButton(ReloadData)}
          {DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {renderButton(data)}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default GeneralButton;