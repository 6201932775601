import { MenuItem, Stack } from '@mui/material'
import React from 'react'
import { Input, Selection } from '../../../../components/ui'

const TabCargo = ({
  marksNumber,
  setMarksNumber,
  cargoDescription,
  setCargoDescription,
  numberOfPackages,
  setNumberOfPackages,
  packagingType,
  setPackagingType,
  dataPackagingType,
  grossWeight,
  setGrossWeight,
  volume,
  setVolume,
  chargeableWeightVolume,
  setChargeableWeightVolume,
  specialInstructions,
  setSpecialInstructions,
  isApproved,
}) => {

  const mainContainer = () => {
    return (
      <>
        <div
          style={{
            display: 'flex',
            padding: '16px 32px',
            flexDirection: 'column',
            borderRadius: '8px',
            background: '#FAFAFA',
            marginTop: '-13px',
          }}
        >
          <Stack direction={'column'} spacing={'16px'}>
            <Input
              label={'Marks Number'}
              value={marksNumber}
              onChange={(e) => setMarksNumber(e.target.value)}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
              disabled={isApproved}
            />

            <Input
              label={'Cargo Description'}
              value={cargoDescription}
              onChange={(e) => setCargoDescription(e.target.value)}
              multiline
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                }
              }}
              inputBaseProps={{
                rows: 4,
                sx: {
                  padding: '8px',
                },
              }}
              disabled={isApproved}
            />

            <Input
              label={'Number of Packages'}
              value={numberOfPackages}
              onChange={(e) => setNumberOfPackages(e.target.value)}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
              disabled={isApproved}
            />

            <Selection
              id='0'
              label={'Packaging Type'}
              value={packagingType}
              fullWidth={true}
              placeholder={'Select Packaging Type'}
              disabled={isApproved}
              onChange={(e) => setPackagingType(e.target.value)}
            >
              {
                dataPackagingType.map((data) => (
                  <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                    {data.name}
                  </MenuItem>
                ))
              }
            </Selection>

            <Input
              label={'Gross Weight'}
              value={grossWeight}
              onChange={(e) => setGrossWeight(e.target.value)}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
              disabled={isApproved}
            />

            <Input
              label={'Volume'}
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
              disabled={isApproved}
            />

            <Input
              label={'Chargeable Weight / Volume'}
              value={chargeableWeightVolume}
              onChange={(e) => setChargeableWeightVolume(e.target.value)}
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px'
                }
              }}
              disabled={isApproved}
            />

            <Input
              label={'Special Instruction'}
              value={specialInstructions}
              onChange={(e) => setSpecialInstructions(e.target.value)}
              multiline
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                }
              }}
              inputBaseProps={{
                rows: 4,
                sx: {
                  padding: '8px',
                },
              }}
              disabled={isApproved}
            />
          </Stack>
        </div>
      </>
    )
  }
  return (
    <>
      {
        isApproved
          ? <fieldset disabled>
            {mainContainer()}
          </fieldset>
          : <fieldset>
            {mainContainer()}
          </fieldset>
      }
    </>
  )
}

export default TabCargo