import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { formatCurrency } from '../../../../services/ApiService';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';
import { Input } from '../../../../components/ui';
import { convertToNominal } from '../../../../utils/masks.jsx';

const FakturPajakDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [selectedDetail, setSelectedDetail] = useState({});
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    function handleSelectedDetail(val) {
        setSelectedDetail(val);
    }

    const handleDoubleClick = () => {

    }

    const valueInput = (title, value, setValue, isDisabled = false) => {
        return (
            <Input
                textAlign='right'
                label={title}
                value={value}
                disabled={isDisabled}
                onChange={(e) => setValue(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                inputBaseProps={{
                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                }}
            />
        )
    }

    const amountSection = (title, value1, setValue1, value2, setValue2, value3, setValue3) => {
        return (
            <Grid container spacing={'32px'} alignItems={'center'}>
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                >
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '19.6px',
                            marginTop: '24px !important'
                        }}
                    >
                        {title}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xl={8}
                    lg={8}
                    sm={8}
                    xs={12}
                >
                    <Stack direction={'row'} spacing={'32px'}>
                        {valueInput("USD", value1, setValue1)}

                        {valueInput("IDR", value2, setValue2)}

                        {valueInput("In IDR", value3, setValue3)}
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            <form>
                <div style={{ marginTop: '16px' }}>
                    <TableContainer sx={{ maxHeight: 230, borderRadius: '12px', background: '#FFF' }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader>
                            <TableHead
                                sx={{
                                    '& th:first-of-type': {
                                        borderRadius: '12px 0 0'
                                    },
                                    '& th:last-child': {
                                        borderRadius: '0 12px 0 0'
                                    }
                                }}
                            >
                                <TableRow>
                                    <StyledTableCell sx={{ textAlign: 'center' }}>No</StyledTableCell>
                                    <StyledTableCell>Description</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: 'right' }}>Amount USD</StyledTableCell>
                                    <StyledTableCell sx={{ textAlign: 'right' }}>Amount IDR</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.invoiceDetailList.length > 0 ?
                                        props.invoiceDetailList.map((el) => {
                                            return (
                                                <TableRow
                                                    key={el.sequence}
                                                    onClick={() => handleSelectedDetail(el)}
                                                    onDoubleClick={() => handleDoubleClick()}
                                                    sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                    hover={true}
                                                >
                                                    <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.sequence}</StyledTableBodyCell>
                                                    <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'right' }}>
                                                        {
                                                            el.amountCrr === 0 ?
                                                                convertToNominal(el.amount)
                                                                :
                                                                convertToNominal(0)
                                                        }
                                                    </StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'right' }}>
                                                        {
                                                            el.amountCrr === 1 ?
                                                                convertToNominal(el.amount)
                                                                :
                                                                convertToNominal(0)
                                                        }
                                                    </StyledTableBodyCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px',
                                                        fontstyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal'
                                                    }}
                                                >
                                                    Data Empty
                                                </Typography>
                                            </StyledTableBodyCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div style={{ marginTop: '32px' }}>
                        {
                            amountSection(
                                'Jumlah Harga Jual / Penggantian / Uang Muka / Termin **)',
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue
                            )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        {
                            amountSection(
                                'Dikurangi Potongan Harga',
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue
                            )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        {
                            amountSection(
                                'Dikurangi Uang Muka Yang Telah Diterima',
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue
                            )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        {
                            amountSection(
                                'Dasar Pengenaan Pajak',
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue
                            )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        {
                            amountSection(
                                'PPN = 10% Dasar Pengenaan Pajak',
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue,
                                props.kursValue, props.setKursValue
                            )
                        }
                    </div>

                    <div style={{ marginTop: '32px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Input
                                    label='KMK No.'
                                    value={props.seriFPS1}
                                    onChange={(e) => props.setSeriFPS1(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='Approved By'
                                    value={props.seriFPS1}
                                    onChange={(e) => props.setSeriFPS1(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label='Description'
                                    value={props.seriFPS1}
                                    onChange={(e) => props.setSeriFPS1(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default FakturPajakDetail;