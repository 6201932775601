import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import * as React from 'react';
import dayjs from 'dayjs';

export default function DatePickerOpenTo(props) {
    return (
        <FormControl fullWidth>
            <LocalizationProvider sx={{fullWidth: true}} dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{fullWidth: true}} components={['DatePicker']}>
                    <DatePicker 
                        label={props.labelPeriod}
                        slotProps={{ textField: { fullWidth: true, variant: 'standard' } }}
                        selected={props.period}
                        views={['month', 'year']}
                        value={dayjs(props.period)}
                        sx={{
                            minHeight: 50,
                            overflow: 'hidden',
                            '.MuiButtonBase-root': {
                                margin: 0,
                                p: '6px'
                            }
                        }}
                        onChange={(x) => { props.setPeriod(x); props.handleDate(x); }}
                        disabled={props.disablePeriod}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </FormControl>
    );
}