import { Backdrop, Box, Grid, IconButton, InputAdornment, Stack, Card, CardContent, MenuItem } from '@mui/material';
import React from 'react'
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import { useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalListPeriod from './Selects/ModalListPeriod';
import ApiService from '../../../../services/ApiService';
import ModalContact from './Selects/ModalContact';
import ButtonSOAARReport from './Buttons/ButtonSOAARReport';
import { useEffect } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';

const SoaArReport = forwardRef((props, ref) => {
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [period, setPeriod] = useState(0);
    const [tahun, setTahun] = useState()
    const [beginingPeriod, setBeginingPeriod] = useState()
    const [endingPeriod, setEndingPeriod] = useState()
    const [isModal, setIsModal] = useState(false)
    const [listPeriod, setListPeriod] = useState([])
    const titleModal = 'List Period'
    const paramListPeriod = 'regClosingPeriod/regClosingPeriod'
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [dataMap, setDataMap] = useState([])
    const [custType, setCustType] = useState(2)
    const [all, setAll] = useState(1)
    const [isModalContact, setIsModalContact] = useState(false)
    const [dataContact, setDataContact] = useState([])
    const [titleModalContact, setTitleModalContact] = useState('Customer List')
    const paramDataContact = 'regContact/regContact'
    const [columnDataContact, setColumnDataContact] = useState([])
    const [SelectedDataContact, setSelectedDataContact] = useState({});
    const [totalRowsContact, setTotalRowsContact] = useState(50)
    const [dataMapContact, setDataMapContact] = useState([])
    const [contactCode, setContactCode] = useState('')
    const [contactName, setContactName] = useState('')
    const [contactId, setContactId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingContact, setIsLoadingContact] = useState(false)
    const CustomerTypeList = [
        { id: 1, code: 2, name: 'Shipper' },
        { id: 2, code: 3, name: 'Consignee' },
        { id: 3, code: 1, name: 'Agent' },
    ]
    const CustomCustomerList = [
        { id: 1, code: 1, name: 'All' },
        { id: 2, code: 2, name: 'Custom' },
    ]

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Statement of Account AR Report', link: '/Majura/StatementOfAccountReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {
            initializeView();
        }
    }));

    useEffect(() => {
        document.title = 'Statement of Account AR Report - ' + JSON.parse(localStorage.getItem('branchName'));
        initializeView();
    }, []);

    function initializeView() {
        setIsLoadingPrint(true);
        setTimeout(function () { setIsLoadingPrint(false); }, 2000);
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRC() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf")
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls")
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (period === 0) {
            check = 1;
        }
        else if (all === 2 && contactCode === '') {
            check = 2;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Period!' })
        }
        else if (check === 2) {
            showToast({ type: 'error', message: 'Please Select Data Customer!' })
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('Statement of Account AR Report');
            handleClickConfirmation();
        }
    };

    const PrintExcel = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Statement of Account AR Report');
            handleClickConfirmation();
        }
    };

    const PrintReport = (code) => {
        setIsLoadingPrint(true)
        let variable = "PrintSOA?typeReport=" + code;
        let params = [
            {
                "attr": "month",
                "value": '' + period
            },
            {
                "attr": "year",
                "value": '' + tahun
            },
            {
                "attr": "contactId",
                "value": '' + contactId
            },
            {
                "attr": "contactTypeId",
                "value": '' + custType
            }
        ]
        ApiService.ReportPrint("Reports/Finance/Print/AccountReceivable/StatementOfAccountReport/", variable, params)
            .then((response) => {
                if (response.status === 200) {
                    let rptFormat = "application/pdf";
                    if (code === "xls") {
                        rptFormat = "application/vnd.ms-excel"
                    }
                    const file = new Blob([response.data], { type: rptFormat });
                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingPrint(false);
                    showToast({ type: 'success', message: 'Print Statement of Account AR Report Success' });
                    window.open(fileURL);
                } else {
                    setIsLoadingPrint(false);
                    showToast({ type: 'error', message: 'Can\'t Print Statement of Account AR Report, ' + response.data.message });
                }
            })
            .catch((error) => {
                setIsLoadingPrint(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Statement of Account AR Report, ' + error });
            });
    };

    const handleModal = () => {
        if (isModal === false) {
            getListPeriod(1, 50);
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    }

    const handleModalContact = () => {
        if (isModalContact === false) {
            getContact(1, 50);
            setIsModalContact(true);
        }
        else {
            setIsModalContact(false);
        }
    }

    const EmptyPeriod = () => {
        setPeriod(0);
        setTahun('')
        setBeginingPeriod('')
        setEndingPeriod('')
    }

    const EmptyContact = () => {
        setContactCode('')
        setContactId(0)
        setContactName('')
    }

    const getListPeriod = (pageNumber, pageSize) => {
        setIsLoading(true)
        ApiService.PostByPage(paramListPeriod, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setListPeriod(response.data.data.closingPeriod);
                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoading(false)
            })
    }

    const getContact = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingContact(true)
        let typeId = '';
        switch (custType) {
            case 2:
                typeId = '2';
                setTitleModalContact('Shipper List')
                break;
            case 3:
                typeId = '3';
                setTitleModalContact('Consigne List')
                break;
            default:
                typeId = '1';
                setTitleModalContact('Agent List')
        }
        const params = [{
            attr: "contactTypeId",
            value: typeId
        }]
        ApiService.DynamicPostByPage(paramDataContact, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataContact({})
                    setDataContact(response.data.data.contact);
                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapContact(indexed)
                    setColumnDataContact(response.data.data.columns)
                    setTotalRowsContact(response.data.totalRowCount)
                    setIsLoadingContact(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingContact(false)
            })
    }

    const handleAll = (e) => {
        setAll(e);
        if (e === 1) {
            EmptyContact()
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonSOAARReport
                PrintData={PrintData}
                PrintExcel={PrintExcel}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Statement of Account AR Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Stack spacing={'16px'} direction="row">
                                            <Input
                                                readOnly
                                                fullWidth
                                                label="Period"
                                                value={period}
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            />

                                            <Input
                                                fullWidth
                                                label="Year"
                                                value={tahun}
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                formControlProps={{ sx: { width: '100%', } }}
                                                disabled
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyPeriod()}
                                                                hidden={period ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton onClick={() => handleModal()}>
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    disabled
                                                    fullWidth
                                                    type={'date'}
                                                    variant="standard"
                                                    value={beginingPeriod}
                                                    label="Begining Period"
                                                    InputLabelProps={{ shrink: true }}
                                                    formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    disabled
                                                    fullWidth
                                                    type={'date'}
                                                    variant="standard"
                                                    value={endingPeriod}
                                                    label="Ending Period"
                                                    InputLabelProps={{ shrink: true }}
                                                    formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={custType}
                                                    fullWidth={true}
                                                    label="Customer Type"
                                                    placeholder={'Select Customer Type'}
                                                    formControlProps={{ sx: { width: '100%', } }}
                                                    onChange={(e) => setCustType(e.target.value)}
                                                >
                                                    {
                                                        CustomerTypeList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={all}
                                                    fullWidth={true}
                                                    label="Custom Contact"
                                                    placeholder={'Select Custom Contact'}
                                                    onChange={(e) => handleAll(e.target.value)}
                                                    formControlProps={{ sx: { width: '100%', } }}
                                                >
                                                    {
                                                        CustomCustomerList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                            <Input
                                                readOnly
                                                fullWidth
                                                variant="standard"
                                                value={contactCode}
                                                label="Customer Code"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            />

                                            <Input
                                                fullWidth
                                                variant="standard"
                                                value={contactName}
                                                label="Customer Name"
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                                formControlProps={{ sx: { width: '100%', } }}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyContact()}
                                                                hidden={contactName ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => handleModalContact()}
                                                                hidden={all === 1 ? true : false}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModal && (
                    <ModalListPeriod
                        isModal={isModal}
                        handleClick={handleModal}
                        setPeriod={setPeriod}
                        setTahun={setTahun}
                        setBeginingPeriod={setBeginingPeriod}
                        setEndingPeriod={setEndingPeriod}
                        setListPeriod={setListPeriod}
                        titleModal={titleModal}
                        getData={getListPeriod}
                        columnData={columnData}
                        dataMap={dataMap}
                        SelectedData={SelectedData}
                        setSelectedData={setSelectedData}
                        totalRows={totalRows}
                        data={listPeriod}
                        isLoading={isLoading}
                    />
                )
            }

            {
                isModalContact && (
                    <ModalContact
                        isModal={isModalContact}
                        handleClick={handleModalContact}
                        titleModal={titleModalContact}
                        getData={getContact}
                        columnData={columnDataContact}
                        dataMap={dataMapContact}
                        SelectedData={SelectedDataContact}
                        setSelectedData={setSelectedDataContact}
                        totalRows={totalRowsContact}
                        data={dataContact}
                        setContactCode={setContactCode}
                        setContactName={setContactName}
                        setContactId={setContactId}
                        isLoading={isLoadingContact}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRC}
                    />
                )
            }
        </Box>
    )
})

export default SoaArReport