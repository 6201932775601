import { Box, Card, CardContent, Grid } from '@mui/material';
import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useImperativeHandle } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../../services/ApiService';
import ButtonCreateEditOutBound from './Buttons/ButtonCreateEditOutBound';
import OutBoundHeader from './Components/OutBoundHeader';
import OutBoundDetail from './Components/OutBoundDetail';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { setDateFormat } from '../../../../utils/date';
import CustomToastContainer, { showToast } from '../../../../components/ui';

const CreateEditOutBound = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataOutBound"));

    const { Id } = useParams();
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramTransactionOutbound = "TransactionOutBound/TransactionOutBound"
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const history = useNavigate();

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    //Variable
    const [RowStatus, setRowStatus] = useState('ACT');

    const [OutBoundDate, setOutBoundDate] = useState(new Date().toISOString().split('T')[0]);
    const [OutBoundNo, setOutBoundNo] = useState('');
    const [productCategoryName, setProductCategoryName] = useState('');
    const [productCategoryId, setProductCategoryId] = useState(0);
    const [siteName, setSiteName] = useState('');
    const [siteId, setSiteId] = useState(0);
    const [warehouseId, setWarehouseId] = useState(0);
    const [warehouseName, setWarehouseName] = useState('');
    const [shipmentOrderId, setShipmentOrderId] = useState(0);
    const [shipmentOrderNo, setShipmentOrderNo] = useState('');
    const [shipperConsigneeId, setShipperConsigneeId] = useState(0);
    const [shipperConsigneeName, setShipperConsigneeName] = useState('');
    const [warehouseLocationId, setWarehouseLocationId] = useState(0);
    const [warehouseLocationName, setWarehouseLocationName] = useState('');
    const [contactId, setContactId] = useState(0);
    const [contactName, setContactName] = useState('');
    const [vehicleTypeId, setVehicleTypeId] = useState(0);
    const [vehicleTypeName, setVehicleTypeName] = useState('');
    const [vehicleNo, setVehicleNo] = useState('');
    const [driverName, setDriverName] = useState('');
    const [driverNo, setDriverNo] = useState('');
    const [printing, setPrinting] = useState(0);

    const [OutBoundDetailList, setOutBoundDetailList] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Transaction OutBound', link: '/Majura/TransactionOutBound', style: '' },
        { label: 'Create Transaction OutBound', link: '/Majura/TransactionOutBound/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Transaction OutBound', link: '/Majura/TransactionOutBound', style: '' },
        { label: 'Detail Transaction Outbound', link: '/Majura/TransactionOutBound/' + Id, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    let countErr = 0;

    useEffect(() => {
        document.title = 'Transaction OutBound - ' + JSON.parse(localStorage.getItem('branchName'));
        setIsLoadingBackDrop(true)
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        // const unloadCallback = (event) => {
        //     event.preventDefault();
        //     event.returnValue = "";
        //     return "";
        //   };

        //   window.addEventListener("beforeunload", unloadCallback);
        //   return () => window.removeEventListener("beforeunload", unloadCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const saveLocalData = () => {
        let id = 0;

        if (Id) {
            id = parseInt(Id);
        }

        var data = {
            "transactionOutBound": {
                "rowStatus": RowStatus,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "outBoundNo": OutBoundNo,
                "outBoundDate": OutBoundDate,
                "shipmentOrderId": shipmentOrderId,
                "shipmentOrderNo": shipmentOrderNo,
                "shipperId": shipperConsigneeId,
                "shipperName": shipperConsigneeName,
                "productCategoryId": productCategoryId,
                "productCategoryName": productCategoryName,
                "sitesId": siteId,
                "sitesName": siteName,
                "warehousesId": warehouseId,
                "warehousesName": warehouseName,
                "warehouseLocationId": "",
                "warehouseLocationName": "",
                "contactId": contactId,
                "contactName": contactName,
                "vehicleTypeId": vehicleTypeId,
                "vehicleTypeName": vehicleTypeName,
                "vehicleNo": vehicleNo,
                "driverName": driverName,
                "driverNo": driverNo,
                "userId": userId,
                "userCode": userCode,
                "printing": printing,
            },
            "transactionOutBoundDetails": OutBoundDetailList,
        };

        localStorage.setItem(username + "-DataOutBound", JSON.stringify(data));
    }

    const getLocalData = () => {
        if (!dataLocal) {
            return;
        }
        let outBound = dataLocal.transactionOutBound;
        let outBoundDetail = dataLocal.transactionOutBoundDetails;

        setRowStatus(outBound.rowStatus);
        setOutBoundNo(outBound.outBoundNo);
        setProductCategoryId(outBound.productCategoryId);
        setProductCategoryName(outBound.productCategoryName);
        setOutBoundDate(outBound.outBoundDate);
        setShipmentOrderId(outBound.shipmentOrderId);
        setShipmentOrderNo(outBound.shipmentOrderNo);
        setShipperConsigneeId(outBound.shipperId);
        setShipperConsigneeName(outBound.shipperName);
        setPrinting(outBound.printing);
        setSiteId(outBound.sitesId);
        setSiteName(outBound.sitesName);
        setWarehouseId(outBound.warehouseId);
        setWarehouseName(outBound.warehouseName);
        setContactId(outBound.contactId);
        setContactName(outBound.contactName);
        setVehicleTypeId(outBound.vehicleTypeId);
        setVehicleTypeName(outBound.vehicleTypeName);
        setVehicleNo(outBound.vehicleNo);
        setDriverName(outBound.driverName);
        setDriverNo(outBound.driverNo);
        handleDocumentStatus(outBound.rowStatus, outBound.isApproved)

        if (Id) {
            setIsEdit(true);
        }
        setOutBoundDetailList(outBoundDetail);
        localStorage.removeItem(username + '-DataOutBound');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1000);
    }

    const getData = () => {
        if (Id) {
            ApiService.PostByIdWarehouse(paramTransactionOutbound, Id)
                .then((res) => {
                    if (res.data.code === 200) {
                        const data = res.data.data.transactionOutBound;
                        setIsEdit(true)
                        setDateFormat(data.outBoundDate, setOutBoundDate);
                        setOutBoundNo(data.outBoundNo);
                        setProductCategoryId(data.productCategoryId);
                        setProductCategoryName(data.productCategoryName);
                        setShipmentOrderId(data.shipmentOrderId);
                        setShipmentOrderNo(data.shipmentOrderNo);
                        setShipperConsigneeId(data.shipperId);
                        setShipperConsigneeName(data.shipperName);
                        setWarehouseId(data.warehousesId);
                        setWarehouseName(data.warehousesName);
                        setSiteId(data.sitesId);
                        setSiteName(data.sitesName);
                        setContactId(data.contactId);
                        setContactName(data.contactName);
                        setVehicleTypeId(data.vehicleTypeId);
                        setVehicleTypeName(data.vehicleTypeName);
                        setVehicleNo(data.vehicleNo);
                        setDriverName(data.driverName);
                        setDriverNo(data.driverNo);
                        setPrinting(data.printing);
                        setOutBoundDetailList(data.transactionOutBoundDetails);
                        handleDocumentStatus(data.rowStatus, data.isApproved)
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch((error) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else {
            setIsLoadingBackDrop(false)
        }
    }

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'TWHTO')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        let tempArray = [];
        let checked = 0;

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ];

            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        for (let i = 0; i < tempArray.length; i++) {
                            if (Id) {
                                if (tempArray[i].accessCode === 'UPD' && tempArray[i].hidden === false) {
                                    checked = 1;
                                    break;
                                }
                            }
                            else {
                                if (tempArray[i].accessCode === 'CRE') {
                                    checked = 1;
                                    break;
                                }
                            }
                        };

                        setCheck(checked);

                        if (hasAccess) {
                            handleDocumentStatus();
                            setCheck(checked);
                            getData();
                            getLocalData();
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    countErr++
                    if (countErr < 3) {
                        getDataAccess()
                    }
                    else {
                        ApiService.logOut()
                        history('/')
                    }
                })
        }
        else {
            history('/Majura');
        }
    }


    function Save() {
        if (siteId === "") {
            showToast({ type: 'error', message: 'Please Select Site!' });
            return;
        }
        if (warehouseId === "") {
            showToast({ type: 'error', message: 'Please Select Warehouse!' });
            return;
        }
        if (contactId === "") {
            showToast({ type: 'error', message: 'Please Select Customer of Principal!' });
            return;
        }
        if (vehicleTypeId === "") {
            showToast({ type: 'error', message: 'Please Select Vehicle Type!' });
            return;
        }
        if (vehicleNo === "") {
            showToast({ type: 'error', message: 'Please Enter Vehicle No!' });
            return;
        }
        if (driverName === "") {
            showToast({ type: 'error', message: 'Please Enter Driver Name!' });
            return;
        }
        if (driverNo === "") {
            showToast({ type: 'error', message: 'Please Enter Driver Mobile No!' });
            return;
        }
        setIsLoadingBackDrop(true);

        let id = 0;

        if (Id) {
            id = parseInt(Id);
        }

        const dataDetail = OutBoundDetailList.map((obj) => {
            const { warehouseLocationId, warehouseLocationName, warehouseLocationX, warehouseLocationY, warehouseLocationZ, ...newObj } = obj;
            newObj.expiredDate = "1900-01-01";
            return newObj;
        })
        var data = {
            "transactionOutBound": {
                "rowStatus": RowStatus,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "outBoundNo": OutBoundNo,
                "outBoundDate": OutBoundDate,
                "shipmentOrderId": shipmentOrderId === "" ? 0 : shipmentOrderId,
                "shipmentOrderNo": shipmentOrderNo,
                "shipperId": shipperConsigneeId === "" ? 0 : shipperConsigneeId,
                "shipperName": shipperConsigneeName,
                "productCategoryId": productCategoryId === "" ? 0 : productCategoryId,
                "productCategoryName": productCategoryName,
                "sitesId": siteId,
                "sitesName": siteName,
                "warehousesId": warehouseId,
                "warehousesName": warehouseName,
                "warehouseLocationId": warehouseLocationId,
                "warehouseLocationName": warehouseLocationName,
                "contactId": contactId,
                "contactName": contactName,
                "vehicleTypeId": vehicleTypeId,
                "vehicleTypeName": vehicleTypeName,
                "vehicleNo": vehicleNo,
                "driverName": driverName,
                "driverNo": driverNo,
                "userId": userId,
                "userCode": userCode,
            },
            "transactionOutBoundDetails": dataDetail,
        };

        let message = '';
        if (id === 0) {
            ApiService.createDataWarehouse(paramTransactionOutbound, data).then((res) => {
                if (res.data.code === 200) {
                    message = 'Create Data Success';
                    showToast({ type: 'success', message: message });
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                            history('/Majura/TransactionOutBound');
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    message = 'Create Data Failed';
                    showToast({ type: 'error', message: message });
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                        },
                        1250);
                }
            })
                .catch((err) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: err.toString() });
                })
        }
        else {
            ApiService.updateDataWarehouse(paramTransactionOutbound, data).then((res) => {
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    message = 'Update Data Success';
                    showToast({ type: 'success', message: message });
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                            history('/Majura/TransactionOutBound');
                        },
                        1250);
                }
                else {
                    message = 'Updated Data Failed';
                    showToast({ type: 'error', message: message });
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                        },
                        1250);
                }
            })
                .catch((err) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: err.toString() });
                })
        }
    }

    function Back() {
        history('/Majura/TransactionOutBound');
    }

    const Picking = () => {
        setIsLoadingBackDrop(true)
        ApiService.PickingWarehouse(paramTransactionOutbound, Id)
            .then((res) => {
                setIsLoadingBackDrop(false)
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: res.data.message });
                    getData();
                } else {
                    showToast({ type: 'error', message: res.data.message });
                }
            })
            .catch((err) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: err.toString() });
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditOutBound
                Save={Save}
                Back={Back}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                Picking={Picking}
                Id={Id}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            Id ?
                                'Transaction OutBound (' + OutBoundNo + ')'
                                :
                                'Transaction OutBound (New Document)'
                        }
                        BreadcrumbsItems={Id ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={0}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <fieldset>
                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <OutBoundHeader
                                    Id={Id}
                                    isEdit={isEdit}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                    OutBoundDate={OutBoundDate}
                                    setOutBoundDate={setOutBoundDate}
                                    OutBoundNo={OutBoundNo}
                                    setOutBoundNo={setOutBoundNo}
                                    productCategoryName={productCategoryName}
                                    setProductCategoryName={setProductCategoryName}
                                    productCategoryId={productCategoryId}
                                    setProductCategoryId={setProductCategoryId}
                                    siteName={siteName}
                                    setSiteName={setSiteName}
                                    siteId={siteId}
                                    setSiteId={setSiteId}
                                    warehouseId={warehouseId}
                                    setWarehouseId={setWarehouseId}
                                    warehouseName={warehouseName}
                                    setWarehouseName={setWarehouseName}
                                    shipmentOrderId={shipmentOrderId}
                                    setShipmentOrderId={setShipmentOrderId}
                                    shipmentOrderNo={shipmentOrderNo}
                                    setShipmentOrderNo={setShipmentOrderNo}
                                    shipperConsigneeId={shipperConsigneeId}
                                    setShipperConsigneeId={setShipperConsigneeId}
                                    shipperConsigneeName={shipperConsigneeName}
                                    setShipperConsigneeName={setShipperConsigneeName}
                                    contactId={contactId}
                                    setContactId={setContactId}
                                    contactName={contactName}
                                    setContactName={setContactName}
                                    vehicleTypeId={vehicleTypeId}
                                    setVehicleTypeId={setVehicleTypeId}
                                    vehicleTypeName={vehicleTypeName}
                                    setVehicleTypeName={setVehicleTypeName}
                                    vehicleNo={vehicleNo}
                                    setVehicleNo={setVehicleNo}
                                    driverName={driverName}
                                    setDriverName={setDriverName}
                                    driverNo={driverNo}
                                    setDriverNo={setDriverNo}
                                    printing={printing}
                                    OutBoundDetailList={OutBoundDetailList}
                                    setOutBoundDetailList={setOutBoundDetailList}
                                    warehouseLocationId={warehouseLocationId}
                                    setWarehouseLocationId={setWarehouseLocationId}
                                    warehouseLocationName={warehouseLocationName}
                                    setWarehouseLocationName={setWarehouseLocationName}
                                />
                            </CardContent>
                        </Card>

                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <OutBoundDetail
                                    Id={Id}
                                    OutBoundDetailList={OutBoundDetailList}
                                    setOutBoundDetailList={setOutBoundDetailList}
                                    productCategoryId={productCategoryId}
                                    printing={printing}
                                    warehouseId={warehouseId}
                                    isEdit={isEdit}
                                />
                            </CardContent>
                        </Card>
                    </fieldset>
                </Grid>
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditOutBound;