import { Box, InputAdornment, IconButton, Button, Avatar, Stack } from '@mui/material';
import React, { forwardRef } from 'react';

import { Icon, Input, PopUp } from '../../../../components/ui';
import majuraIconBlue from '../../../../Assets/Icon/majuraIconBlue.svg';

const ModalChangePassword = forwardRef((props, ref) => {

    const mainContainer = (
        <>
            <Box display="flex" alignItems="center" justifyContent="center" className='mb-3'>
                <Avatar
                    src={majuraIconBlue}
                    sx={{ width: 'auto', height: 'auto' }}
                    variant='square'
                    alt='.'
                />
            </Box>

            <form onSubmit={props.handleSubmit}>
                <Box component='form' onSubmit={props.handleSubmit}>
                    <Input
                        label={'Username / Email'}
                        placeholder={'Enter your username or email'}
                        onChange={(e) => props.setUserName(e.target.value)}
                        value={props.userName}
                        type={'email'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label={'Password'}
                        placeholder={'Enter your password'}
                        onChange={(e) => props.setPassword(e.target.value)}
                        value={props.password}
                        type={props.isPassword ? 'text' : 'password'}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                        inputBaseProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => props.setIsPassword(!props.isPassword)}
                                        size='small'
                                    >
                                        {
                                            props.isPassword ?
                                                <Icon iconName={'eye-on'} />
                                                :
                                                <Icon iconName={'eye-off'} />
                                        }
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
            </form>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                title={''}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '0px 6px 6px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '32px' }}>
                        <Button
                            type='submit'
                            variant="contained"
                            fullWidth
                            onClick={props.handleSubmit}
                            sx={{
                                borderRadius: '8px',
                                background: '#0087C2',
                                fontSize: '16px',
                                fontWeight: 700,
                                fontFamily: 'Nunito, sans-serif',
                                '&:hover': {
                                    borderRadius: '8px',
                                    background: '#0087C2',
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.35)',
                                },
                            }}
                        >
                            <Stack direction={'row'} spacing={1}>
                                <Icon iconName={'login'} />

                                <span>
                                    Login
                                </span>
                            </Stack>
                        </Button>

                        <Button
                            type='submit'
                            variant="contained"
                            fullWidth
                            className='mt-3'
                            onClick={props.handleClick}
                            sx={{
                                background: '#FFFFFF',
                                color: '#0087C2',
                                borderRadius: '8px',
                                border: '2px solid var(--Blue-Button-Primary-1, #0087C2)',
                                fontSize: '16px',
                                fontWeight: 700,
                                fontFamily: 'Nunito, sans-serif',
                                '&:hover': {
                                    background: '#FFFFFF',
                                    color: '#0087C2',
                                    borderRadius: '8px',
                                    border: '2px solid var(--Blue-Button-Primary-1, #0087C2)',
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.35)',
                                },
                            }}
                        >
                            <Stack direction={'row'} spacing={1}>
                                <Icon iconName={'return'} />

                                <span>
                                    Cancel
                                </span>
                            </Stack>
                        </Button>
                    </div>
                </div>
            </PopUp>
        </>
    )
})

export default ModalChangePassword