import React from 'react';
import { Grid } from '@mui/material';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

const ButtonCreateEditFakturPajak = ({ check, Save, DataRegUserAccess, isEdit, Print }) => {
    return (
        <div className="row mt-4">
            <div className="col-12">
                <Grid
                    container
                    item
                    justifyContent="right"
                    direction="row"
                    style={{ maxWidth: "100vw", gap: "12px" }}
                >
                    {check !== 0 && ButtonComponent.SaveButton(Save, false, false, true)}

                    {DataRegUserAccess.map(({ accessId, accessCode }) => (
                        accessCode === 'PRI' && isEdit && (
                            <React.Fragment key={accessId}>
                                {ButtonComponent.PrintButton(Print, '', true)}
                            </React.Fragment>
                        )
                    ))}
                </Grid>
            </div>
        </div>
    );
}

export default ButtonCreateEditFakturPajak;