import { Box, Typography, Grid, Button } from '@mui/material';
import { PopUp, Icon } from '../../../../../components/ui';
import React from 'react';

const ModalAllPPh23 = (props) => {
    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Apply PPh23'}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'xs'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '6px 12px 0px' }}>
                    <Box display="flex" className='mt-3' sx={{ padding: '0px 8px' }}>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                marginTop: '3px !important',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            Do You Want to Apply PPh23 to All Data?
                        </Typography>
                    </Box>

                    <Box display="flex" alignItems="center" className='mt-4'>
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                        '&:hover': {
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => { props.SetPPh23('All') }}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#E4E8EC',
                                            sx: {
                                                height: '16px',
                                                width: '16px',
                                                marginTop: '2px'
                                            }
                                        }}
                                        iconName={'checklist'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#E4E8EC',
                                        }}
                                    >
                                        Yes
                                    </Typography>
                                </Button>

                                <Button
                                    size='small'
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '4px',
                                        marginInlineStart: '12px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        borderRadius: '8px',
                                        border: '1px solid var(--Red-Alert, #D20C0C)',
                                        background: 'rgba(210, 12, 12, 0.25)',
                                        '&:hover': {
                                            border: '1px solid var(--Red-Alert, #D20C0C)',
                                            background: 'rgba(210, 12, 12, 0.25)',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                    onClick={() => { props.SetPPh23('No') }}
                                >
                                    <Icon
                                        svgIconProps={{
                                            htmlColor: '#D20C0C',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                        iconName={'close'}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            marginTop: '3px !important',
                                            fontSize: '12px',
                                            fontWeight: '600',
                                            lineHeight: '20px',
                                            textAlign: 'center',
                                            color: '#D20C0C',
                                        }}
                                    >
                                        No
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
}

export default ModalAllPPh23;