import React from 'react'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent'
import TableEPL from '../TableEPL/TableEPL';
import { Grid, Stack } from '@mui/material';
import { showToast } from '../../../../components/ui';

const TabCostIATA = (props) => {
  const title = 'Cost IATA'
  const contactTypeName = 'IATA'
  const contactTypeId = 5
  const income = false

  const handleAdd = () => {
    if (props.shipmentNo !== '') {
      props.handleAdd(title, contactTypeName, contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Please Select Shipment Number First!' });
    }
  }

  const handleEdit = () => {
    if (props.selectedDetailCostIATA.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleEdit(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleConvert = () => {
    if (props.selectedDetailCostIATA.sequence === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.handleConvert(title, contactTypeName, contactTypeId, income)
    }
  }

  const handleDoubleClick = () => {
    handleEdit()
  }

  const handleDelete = () => {
    if (props.selectedDetailCostIATA.bookingDetailId === 0) {
      props.handleDelete(contactTypeId, income)
    }
    else {
      showToast({ type: 'error', message: 'Can\'t Delete This Data!' });
    }
  }

  return (
    <div className='rounded-10'>
      <TableEPL
        key={8}
        isReadOnly={props.isReadOnly}
        dataList={props.CostIATAList}
        ignoreAccess={props.ignoreAccess}
        setDataList={props.setCostIATAList}
        setSelectedDetail={props.setSelectedDetailCostIATA}
        selectedDetail={props.selectedDetailCostIATA}
        handleDoubleClick={handleDoubleClick}
        contactTypeName={contactTypeName}
        ttlUsd={props.estCostUSDIATA}
        ttlIdr={props.estCostIDRIATA}
        totalSData={props.totalCostSIATA}
        totalNData={props.totalCostNIATA}
        key1={8}
        key2={8}
        type={1}
        openClose={props.openCloseCI}
        setOpenClose={props.setOpenCloseCI}
        openClose2={props.openCloseCI2}
        setOpenClose2={props.setOpenCloseCI2}
        dataDetail={props.dataDetail}
        setDataDetail={props.setDataDetail}
        countSum={props.countSum}
        countTotalData={props.countTotalData}
      />

      <Grid container item direction="row" className='mt-3'>
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={'10px'}>
            {ButtonComponent.AddButton(handleAdd, '', props.isReadOnly)}
            {ButtonComponent.EditButton(handleEdit, '', props.isReadOnly)}
            {ButtonComponent.DeleteButton(handleDelete, '', props.isReadOnly)}
            {ButtonComponent.ConvertButton(handleConvert, props.isReadOnly, true)}
          </Stack>
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(TabCostIATA)