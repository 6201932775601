import { Grid, Stack, IconButton, InputAdornment, Typography, Card, CardContent } from '@mui/material';
import React, { useState } from 'react';
import { Box } from '@mui/system';

import ApiService from '../../../../../services/ApiService';
import SelectDataCOA from '../Selects/SelectDataCOA';
import TabsPaymentMethod from '../Tabs/TabsPaymentMethod';
import { Icon, Input, showToast } from '../../../../../components/ui';
import TableInvoice from '../Tables/TableInvoice';

const OfficialReceiptPayment = (props) => {
    const [tabValue, setTabValue] = useState('Cash');
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCOA = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    return (
        <div>
            <form>
                <Box>
                    <TableInvoice
                        calculateTotalInvoice={props.calculateTotalInvoice}
                        calculateTotalPPh23={props.calculateTotalPPh23}
                        calculateTotalCash={props.calculateTotalCash}
                        calculateTotalBank={props.calculateTotalBank}
                        calculateTotalRV={props.calculateTotalRV}
                        calculateTotalTR={props.calculateTotalTR}
                        calculateTotalDifferent={props.calculateTotalDifferent}
                        isNPWP={props.isNPWP}
                        InvoiceList={props.InvoiceList}
                        setInvoiceList={props.setInvoiceList}
                        TotalPPh={props.TotalPPh}
                        setTotalPPh={props.setTotalPPh}
                        PPH23List={props.PPH23List}
                        setPPH23List={props.setPPH23List}
                        PaymentByCode={props.PaymentByCode}
                        CustomerId={props.CustomerId}
                        setBankData={props.setBankData}
                        setCheckSelectedInvoice={props.setCheckSelectedInvoice}
                        setTRList={props.setTRList}
                        setRVList={props.setRVList}
                        Printing={props.Printing}
                        InvoiceUSD={props.InvoiceUSD}
                        setInvoiceUSD={props.setInvoiceUSD}
                        InvoiceIDR={props.InvoiceIDR}
                        setInvoiceIDR={props.setInvoiceIDR}
                        TotalInvoiceIDR={props.TotalInvoiceIDR}
                        setTotalInvoiceIDR={props.setTotalInvoiceIDR}
                        officialReceiptId={props.officialReceiptId}
                        RateCount={props.RateCount}
                        setTotalCashUSD={props.setTotalCashUSD}
                        setTotalCashIDR={props.setTotalCashIDR}
                        setTotalCashInIDR={props.setTotalCashInIDR}
                        setTotalBankUSD={props.setTotalBankUSD}
                        setTotalBankIDR={props.setTotalBankIDR}
                        setTotalBankInIDR={props.setTotalBankInIDR}
                        setCashPaymentUSD={props.setCashPaymentUSD}
                        setCashPaymentIDR={props.setCashPaymentIDR}
                        setTotalCashPaymentIDR={props.setTotalCashPaymentIDR}
                        invoiceIdList={props.invoiceIdList}
                        setInvoiceIdList={props.setInvoiceIdList}
                        TRIdList={props.TRIdList}
                        setTRIdList={props.setTRIdList}
                        RVIdList={props.RVIdList}
                        setRVIdList={props.setRVIdList}
                        dueDateLast={props.dueDateLast}
                        setDueDateLast={props.setDueDateLast}

                        TRList={props.TRList}
                        TRInUSD={props.TRInUSD}
                        TRInIDR={props.TRInIDR}
                        TotalTRInIDR={props.TotalTRInIDR}
                        RVList={props.RVList}
                        RVInUSD={props.RVInUSD}
                        RVInIDR={props.RVInIDR}
                        TotalRVInIDR={props.TotalRVInIDR}
                        checkSelectedInvoice={props.checkSelectedInvoice}
                    />

                    <Card component="div" style={{ marginTop: '24px', marginBottom: '24px', borderRadius: '8px', backgroundColor: '#FFFFFF', padding: '0px 24px' }}>
                        <CardContent>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                }}
                            >
                                Payment Method
                            </Typography>

                            <Box sx={{ marginTop: '24px' }}>
                                <div>
                                    <TabsPaymentMethod
                                        tabValue={tabValue}
                                        setTabValue={setTabValue}
                                        officialReceiptId={props.officialReceiptId}
                                        Printing={props.Printing}
                                        BankData={props.BankData}
                                        setBankData={props.setBankData}
                                        calculateTotalCash={props.calculateTotalCash}
                                        calculateTotalBank={props.calculateTotalBank}
                                        calculateTotalDifferent={props.calculateTotalDifferent}
                                        CashPaymentUSD={props.CashPaymentUSD}
                                        setCashPaymentUSD={props.setCashPaymentUSD}
                                        CashPaymentIDR={props.CashPaymentIDR}
                                        setCashPaymentIDR={props.setCashPaymentIDR}
                                        TotalCashPaymentIDR={props.TotalCashPaymentIDR}
                                        setTotalCashPaymentIDR={props.setTotalCashPaymentIDR}
                                        TotalCashUSD={props.TotalCashUSD}
                                        setTotalCashUSD={props.setTotalCashUSD}
                                        TotalCashIDR={props.TotalCashIDR}
                                        setTotalCashIDR={props.setTotalCashIDR}
                                        TotalCashInIDR={props.TotalCashInIDR}
                                        setTotalCashInIDR={props.setTotalCashInIDR}
                                        TotalBankUSD={props.TotalBankUSD}
                                        setTotalBankUSD={props.setTotalBankUSD}
                                        TotalBankIDR={props.TotalBankIDR}
                                        setTotalBankIDR={props.setTotalBankIDR}
                                        TotalBankInIDR={props.TotalBankInIDR}
                                        setTotalBankInIDR={props.setTotalBankInIDR}
                                        InvoiceList={props.InvoiceList}
                                        setInvoiceList={props.setInvoiceList}
                                        checkSelectedInvoice={props.checkSelectedInvoice}
                                        RateCount={props.RateCount}
                                        dueDateLast={props.dueDateLast}
                                        setDueDateLast={props.setDueDateLast}
                                        TempDiffUSD={props.TempDiffUSD}
                                        TempDiffIDR={props.TempDiffIDR}
                                    />
                                </div>
                            </Box>
                        </CardContent>
                    </Card>

                    <Grid container columnSpacing={'32px'} rowSpacing={'24px'}>
                        <Grid item xs={5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                    marginBottom: '-8px'
                                }}
                            >
                                USD
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    marginBottom: '-8px'
                                }}
                            >
                                IDR
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    marginBottom: '-8px'
                                }}
                            >
                                In IDR
                            </Typography>
                        </Grid>

                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                }}
                            >
                                Total Cash Payment
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.TotalCashUSD}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.TotalCashIDR}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.TotalCashInIDR}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                }}
                            >
                                Total Bank Payment
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.TotalBankUSD}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.TotalBankIDR}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.TotalBankInIDR}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                }}
                            >
                                Different
                            </Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.DifferenceUSD}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.DifferenceIDR}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={3.5}>
                            <Input
                                textAlign='right'
                                value={props.TotalDifferenceIDR}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Grid>

                        <Grid item xs={1.5} sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                            <Typography
                                sx={{
                                    color: 'var(--Primary-One, #083A50)',
                                    textAlign: 'right !important',
                                    fontFamily: 'Nunito',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '19.6px',
                                    width: '100%',
                                }}
                            >
                                Account Code
                            </Typography>
                        </Grid>

                        <Grid item xs={10.5}>
                            <Stack spacing={'16px'} direction="row">
                                <Input
                                    value={props.AccountCode}
                                    disabled
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.AccountName}
                                    type='text'
                                    label={''}
                                    formControlProps={{
                                        sx: {
                                            width: '100%'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => searchCOA('delete')}
                                                    disabled={props.isJobOwner}
                                                    hidden={props.AccountName && props.Printing === 0 ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => searchCOA('search')} hidden={props.Printing > 0}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }
        </div>
    )
}

export default OfficialReceiptPayment;