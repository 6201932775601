import React from 'react'
import { Box, Grid, Stack, Typography, useTheme } from '@mui/material'

const MasterCard = ({ title, xs, sm, md, children }) => {
  const theme = useTheme();

  return (
    <Grid item xs={xs} sm={sm} md={md}>
      <Stack
        direction={'column'}
        bgcolor={'#FFF'}
        boxShadow={theme.shadows?.[1]}
        borderRadius={1.5}
      >
        <Box className="card-header" p={2} borderBottom={'1px solid #EFEFEF'}>
          <Typography fontWeight={500} letterSpacing={2}>{title || 'Card Title'}</Typography>
        </Box>
        <Box className="card-content" p={2}>
          { children }
        </Box>
      </Stack>
    </Grid>
  )
}

export default MasterCard