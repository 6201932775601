/* eslint-disable react/no-typos */
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import React from 'react';

export const convertMaskIDR = (value, dataType = 0) => {
    let tempData = '' + value;

    if (tempData === '') {
        tempData = '0';
    }

    let temp = '' + tempData;
    // let val = temp.replace(/./g, '');
    // let vale = val.replace(/,/g, '.');
    let val = temp.split('.').join('');
    let vale = val.split(',').join('.');


    if (dataType === 0) {
        vale = parseFloat(vale);
    }
    return vale;
}

const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 5, // how many digits allowed after the decimal
    // integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const RupiahInput = React.forwardRef(({ maskOptions, ...inputProps }, ref) => {
    const RupiahMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    });

    return <MaskedInput mask={RupiahMask} {...inputProps} />
});

RupiahInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

RupiahInput.propTypes = {
    inputmode: PropTypes.string,
    maskOptions: PropTypes.shape({
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
        // integerLimit: PropTypes.number,
    }),
}

export default RupiahInput