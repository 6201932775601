import React from 'react'
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel } from '@mui/material'

import Icon from '../Icon/Icon'

const TickBox = ({
  isBold,
  id,
  name,
  size,
  required,
  disabled,
  hidden,
  label,
  checked,
  onChange,
  icon,
  checkedIcon,
  indeterminate,
  baseLabelProps,
  baseCheckboxProps,
}) => {
  const tickBoxStyles = {
    padding: '8px',
    '&.MuiCheckbox-root': {
      color: 'transparent',
    },
    '&.Mui-disabled': {
      opacity: .5,
    },
    '& .MuiSvgIcon-root': {
      width: size === 'small' ? '16px' : undefined,
      height: size === 'small' ? '16px' : undefined,
      fill: checked ?  '#0087C2' : 'transparent',
      stroke: checked ? '#EAEAEA' : '#7F888C',
      'rect': {
        stroke: checked ? '#EAEAEA' : '#7F888C',
      },
      'path': {
        fill: checked ? '#EAEAEA' : '#7F888C',
      }
    }
  }

  const defaultStyles = {
    padding: '8px',
    '&.MuiCheckbox-root': {
      color: '#083A50',
      '&.Mui-checked': {
        color: '#083A50'
      },
      '&.Mui-disabled': {
        opacity: .5
      }
    },
    '& .MuiSvgIcon-root': {
      width: size === 'small' ? '16px' : undefined,
      height: size === 'small' ? '16px' : undefined,
    }
  }

  return (
    <FormControlLabel
      {...baseLabelProps}
      id={id}
      name={name}
      required={required}
      disabled={disabled}
      hidden = {hidden}
      control={
        <Checkbox
          {...baseCheckboxProps}
          checked={checked}
          onChange={onChange}
          icon={icon || <Icon iconName='checkbox-empty' />}
          checkedIcon={checkedIcon || <Icon iconName='checkbox-checked' />}
          indeterminate={indeterminate}
          size={size}
          sx={[
            !icon ? tickBoxStyles : defaultStyles,
            baseCheckboxProps?.sx
          ]}
        />
      }
      label={label}
      sx={{
        ml: '-8px',
        '& .MuiFormControlLabel-label': {
          fontWeight: isBold ? 600 : 400,
          fontSize: size === 'small' ? 12 : 14
        },
        ...baseLabelProps?.sx
      }}
    />
  )
}

TickBox.propTypes = {
  isBold: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,  // Untuk string
    PropTypes.node,    // Untuk elemen React (JSX atau React component)
  ]),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.element,
  checkedIcon: PropTypes.element,
  indeterminate: PropTypes.bool,

  baseLabelProps: PropTypes.object,
  baseCheckboxProps: PropTypes.object,
};

export default TickBox