import { FormHelperText, IconButton, InputAdornment, MenuItem, Stack } from '@mui/material';
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import ApiService, { branchId, companyId, countryId, userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import ModalDriver from './ModalDriver';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, Selection, showToast } from '../../../components/ui';
import { defaultDate } from '../../../utils/date';

const CreateEditKendaraan = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const [isModalDriver, setIsModalDriver] = useState(false);

    const [isLoading, setIsLoading] = useState(true)
    const [selectedDriver, setSelectedDriver] = useState({})
    const [transportationList, setTransportationList] = useState([])
    const [containerList, setContainerList] = useState([])

    useEffect(() => {
        if (isModal) {
            console.log(selectedData)
            getAdditionalData()

            if (selectedData?.id) {
                getDriverById(selectedData.driverId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    useEffect(() => {
        if (selectedDriver?.id) {
            formik.setFieldValue('driverId', selectedDriver.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDriver])

    const errorToast = (location, closeModal = false) => {
        showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
        if (closeModal) handleClick()
    }

    const getAdditionalData = async () => {
        setIsLoading(true)
        try {
            const [resJK, resCT] = await Promise.all([
                ApiService.PostByPage('jeniskendaraan/jeniskendaraan', 1, 99999),
                ApiService.PostByPage('containerType/containerType', 1, 99999)
            ])

            if (resJK?.data?.code === 200) {
                setTransportationList(resJK.data.data.jenisKendaraan)
            } else {
                errorToast('transportation type')
            }

            if (resCT?.data?.code === 200) {
                setContainerList(resCT.data.data.containerType)
            } else {
                errorToast('container type')
            }
        } catch (error) {
            errorToast('additional')
        }
        setIsLoading(false)
    }

    const getDriverById = (id) => {
        ApiService.PostById('employee/employee', id).then((res) => {
            if (res.data.code === 200) {
                const driver = res.data.data.employee
                setSelectedDriver(driver)
                formik.setFieldValue('driverId', driver.id)
            } else {
                errorToast('driver')
            }
        }).catch((error) => {
            errorToast('driver')
        })
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "kendaraan": {
                    ...formik.values,
                    thnPembuatan: parseInt(formik.values?.thnPembuatan),
                    cc: parseInt(formik.values?.cc),
                    hargaBeli: parseInt(formik.values?.hargaBeli),
                    jmlSusut: parseInt(formik.values?.jmlSusut),
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-DataKendaraan", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        masterCode: Yup.string().nullable(),
        jenisKendaraanId: Yup.number().required('This field is required.'),
        tglBeli: Yup.date().required('This field is required.'),
        noPolisi: Yup.string().trim().required('This field is required.'),
        namaPemilik: Yup.string().trim().required('This field is required.'),
        alamat: Yup.string().trim().required('This field is required.'),
        merk: Yup.string().trim().required('This field is required.'),
        warna: Yup.string().trim().required('This field is required.'),
        thnPembuatan: Yup.number().min(1990, 'Must be greater than 1990.').required('This field is required.'),
        cc: Yup.number().min(0, 'Must be greater than 0.').required('This field is required.'),
        noRangka: Yup.string().trim().required('This field is required.'),
        noMesin: Yup.string().trim().required('This field is required.'),
        hargaBeli: Yup.number().min(0, 'Must be greater than 0.').required('This field is required.'),
        jmlSusut: Yup.number().required('This field is required.'),
        driverId: Yup.number().nullable(),
        containerType: Yup.number().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? {
            ...selectedData,
            tglBeli: selectedData?.tglBeli ? new Date(selectedData.tglBeli).toLocaleDateString("sv-se").split('T')[0] : '',
        } : {
            masterCode: '',
            jenisKendaraanId: '',
            tglBeli: '',
            noPolisi: '',
            namaPemilik: '',
            alamat: '',
            merk: '',
            warna: '',
            thnPembuatan: '',
            cc: '',
            noRangka: '',
            noMesin: '',
            hargaBeli: '',
            jmlSusut: '',
            driverId: '',
            containerType: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.jenisKendaraanId === '') {
            return showToast({ type: 'error', message: 'Jenis Kendaraan is Required!' });
        }

        if (payload?.noPolisi === '') {
            return showToast({ type: 'error', message: 'No Polisi is Required!' });
        }

        if (payload?.namaPemilik === '') {
            return showToast({ type: 'error', message: 'Nama Pemilik is Required!' });
        }

        if (payload?.thnPembuatan === '') {
            return showToast({ type: 'error', message: 'Tahun Pembuatan is Required!' });
        }

        const data =
        {
            "kendaraan": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": payload?.id,
                "jenisKendaraanId": payload?.jenisKendaraanId,
                "masterCode": payload?.masterCode,
                "tglBeli": payload?.tglBeli,
                "noPolisi": payload?.noPolisi,
                "namaPemilik": payload?.noPolisi,
                "alamat": payload?.alamat,
                "merk": payload?.merk,
                "warna": payload?.warna,
                "thnPembuatan": parseInt(payload?.thnPembuatan),
                "cc": parseInt(payload?.cc),
                "noRangka": payload?.noRangka,
                "noMesin": payload?.noMesin,
                "hargaBeli": parseInt(payload?.hargaBeli),
                "jmlSusut": parseInt(payload?.jmlSusut),
                "flagType": 1,
                "deleted": false,
                "deletedBy": 0,
                "deletedOn": defaultDate,
                "driverId": payload?.driverId,
                "containerType": payload?.containerType,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const handleDriverModal = () => {
        setIsModalDriver(!isModalDriver)
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                disabled
                name="code"
                label="Master Code"
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Stack direction={'row'} spacing={'16px'}>
                <Selection
                    id="jenisKendaraanId"
                    label="Jenis Kendaraan"
                    name="jenisKendaraanId"
                    labelId="label-jenisKendaraan"
                    placeholder={'Select Jenis Kendaraan'}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    value={formik.values.jenisKendaraanId}
                    onChange={(e) => { formik.setFieldValue('jenisKendaraanId', e.target.value) }}
                    error={formik.touched.jenisKendaraanId && Boolean(formik.errors.jenisKendaraanId)}
                >
                    {
                        transportationList.map((data) => (
                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.id}>
                                {data.name}
                            </MenuItem>
                        ))
                    }
                    <FormHelperText>{formik.touched.jenisKendaraanId && formik.errors.jenisKendaraanId}</FormHelperText>
                </Selection>

                <Selection
                    id="containerType"
                    name="containerType"
                    label="Container Type"
                    labelId="label-container"
                    placeholder={'Select Container Type'}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    value={formik.values.containerType}
                    onChange={(e) => { formik.setFieldValue('containerType', e.target.value) }}
                    error={formik.touched.containerType && Boolean(formik.errors.containerType)}
                >
                    {
                        containerList.map((data) => (
                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.id}>
                                {data.name}
                            </MenuItem>
                        ))
                    }
                    <FormHelperText>{formik.touched.containerType && formik.errors.containerType}</FormHelperText>
                </Selection>
            </Stack>

            <Stack direction={'row'} spacing={'16px'}>
                <Input
                    type={'date'}
                    name="tglbeli"
                    label="Tanggal Beli"
                    value={formik.values.tglbeli}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tglbeli && Boolean(formik.errors.tglbeli)}
                    helperText={formik.touched.tglbeli && formik.errors.tglbeli}
                    onChange={(e) => formik.setFieldValue('tglbeli', e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Input
                    name="noPolisi"
                    label="No Polisi"
                    value={formik.values.noPolisi}
                    onBlur={formik.handleBlur}
                    error={formik.touched.noPolisi && Boolean(formik.errors.noPolisi)}
                    helperText={formik.touched.noPolisi && formik.errors.noPolisi}
                    onChange={(e) => formik.setFieldValue('noPolisi', e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Input
                    name="namaPemilik"
                    label="Nama Pemilik"
                    value={formik.values.namaPemilik}
                    onBlur={formik.handleBlur}
                    error={formik.touched.namaPemilik && Boolean(formik.errors.namaPemilik)}
                    helperText={formik.touched.namaPemilik && formik.errors.namaPemilik}
                    onChange={(e) => formik.setFieldValue('namaPemilik', e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />
            </Stack>

            <Input
                multiline
                fullWidth
                name="alamat"
                label="Alamat"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                value={formik.values.alamat}
                onBlur={formik.handleBlur}
                helperText={formik.touched.alamat && formik.errors.alamat}
                error={formik.touched.alamat && Boolean(formik.errors.alamat)}
                onChange={(e) => formik.setFieldValue('alamat', e.target.value)}
            />

            <Stack direction={'column'} spacing={'16px'} sx={{ marginTop: '16px !important' }}>
                <Stack direction={'row'} spacing={'16px'}>
                    <Input
                        name="merk"
                        label="Merk"
                        value={formik.values.merk}
                        onBlur={formik.handleBlur}
                        error={formik.touched.merk && Boolean(formik.errors.merk)}
                        helperText={formik.touched.merk && formik.errors.merk}
                        onChange={(e) => formik.setFieldValue('merk', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                    />

                    <Input
                        name="warna"
                        label="Warna"
                        value={formik.values.warna}
                        onBlur={formik.handleBlur}
                        error={formik.touched.warna && Boolean(formik.errors.warna)}
                        helperText={formik.touched.warna && formik.errors.warna}
                        onChange={(e) => formik.setFieldValue('warna', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                    />

                    <Input
                        type='number'
                        name="thnPembuatan"
                        label="Tahun Pembuatan"
                        value={formik.values.thnPembuatan}
                        onBlur={formik.handleBlur}
                        error={formik.touched.thnPembuatan && Boolean(formik.errors.thnPembuatan)}
                        helperText={formik.touched.thnPembuatan && formik.errors.thnPembuatan}
                        onChange={(e) => formik.setFieldValue('thnPembuatan', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                        inputBaseProps={{
                            inputProps: {
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }
                        }}
                    />
                </Stack>

                <Stack direction={'row'} spacing={'16px'}>
                    <Input
                        name="cc"
                        label="CC"
                        type='number'
                        value={formik.values.cc}
                        onBlur={formik.handleBlur}
                        error={formik.touched.cc && Boolean(formik.errors.cc)}
                        helperText={formik.touched.cc && formik.errors.cc}
                        onChange={(e) => formik.setFieldValue('cc', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                        inputBaseProps={{
                            inputProps: {
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }
                        }}
                    />

                    <Input
                        name="noRangka"
                        label="No Rangka"
                        value={formik.values.noRangka}
                        onBlur={formik.handleBlur}
                        error={formik.touched.noRangka && Boolean(formik.errors.noRangka)}
                        helperText={formik.touched.noRangka && formik.errors.noRangka}
                        onChange={(e) => formik.setFieldValue('noRangka', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                    />

                    <Input
                        name="noMesin"
                        label="No Mesin"
                        value={formik.values.noMesin}
                        onBlur={formik.handleBlur}
                        error={formik.touched.noMesin && Boolean(formik.errors.noMesin)}
                        helperText={formik.touched.noMesin && formik.errors.noMesin}
                        onChange={(e) => formik.setFieldValue('noMesin', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                    />
                </Stack>

                <Stack direction={'row'} spacing={'16px'}>
                    <Input
                        type='number'
                        name="hargaBeli"
                        label="Harga Beli"
                        value={formik.values.hargaBeli}
                        onBlur={formik.handleBlur}
                        error={formik.touched.hargaBeli && Boolean(formik.errors.hargaBeli)}
                        helperText={formik.touched.hargaBeli && formik.errors.hargaBeli}
                        onChange={(e) => formik.setFieldValue('hargaBeli', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                        inputBaseProps={{
                            inputProps: {
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }
                        }}
                    />

                    <Input
                        type='number'
                        name="jmlSusut"
                        label="Jumlah Susut"
                        value={formik.values.jmlSusut}
                        onBlur={formik.handleBlur}
                        error={formik.touched.jmlSusut && Boolean(formik.errors.jmlSusut)}
                        helperText={formik.touched.jmlSusut && formik.errors.jmlSusut}
                        onChange={(e) => formik.setFieldValue('jmlSusut', e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px !important'
                            }
                        }}
                        inputBaseProps={{
                            inputProps: {
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }
                        }}
                    />

                    <Input
                        label="Driver"
                        name="driverId"
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                        disabled
                        value={selectedDriver?.name}
                        error={formik.touched.driverId && Boolean(formik.errors.driverId)}
                        helperText={formik.touched.driverId && formik.errors.driverId}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => { handleDriverModal() }}
                                    >
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
            </Stack>
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        isLoading={isLoading}
                        width={'lg'}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />

                )
            }
            {isModalDriver && (
                <ModalDriver
                    isModal={isModalDriver}
                    handleClick={handleDriverModal}
                    titleModal={'Driver'}

                    selectedData={selectedDriver}
                    setSelectedData={setSelectedDriver}

                    errorToast={errorToast}
                />
            )}
        </>

    )
})

export default CreateEditKendaraan