import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import ModalBank from '../Modals/ModalBank';
import { showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { defaultDate, getDefaultDateFormat, nullDate, setDateFormat, updateDateFormat } from '../../../../../utils/date.jsx';

const TabBank = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [isAccount, setIsAccount] = useState(0);
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [CheckBG, setCheckBG] = useState('');
    const [AmountCrr, setAmountCrr] = useState(1);
    const [Remarks, setRemarks] = useState('');
    const [Amount, setAmount] = useState(0);
    const [DueDate, setDueDate] = useState('');
    const [isDueDate, setIsDueDate] = useState(false);
    const [statusDueDate, setStatusDueDate] = useState(false);

    useEffect(() => {
        setSelectedDetail({});
    }, [props.BankData]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    const ShowAddBank = () => {
        if (props.selectedPaymentVoucher.sequence !== undefined) {
            if (props.BankId !== 0) {
                setTitleModal('Add Payment Method Bank');
                if (props.TempIsPPh23 === true) {
                    setAmount(props.TempAmount);
                }
                if (props.PaymentBy === 'BU') {
                    setAmountCrr(0);
                }
                else if (props.PaymentBy === 'BI') {
                    setAmountCrr(1);
                }

                if (isAccount === 0 || isAccount > 1) {
                    setAccountCode(props.COACode);
                    setAccountName(props.COAName);
                }

                setDetailEdit(false);
                setRemarks(props.selectedPaymentVoucher.description);
                if (props.BankData.length > 0) {
                    let data = props.BankData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                    setDetailSequence(data.sequence);
                }
                else {
                    setDetailSequence(0);
                }
                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Bank First!' });
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Select Detail First!' });
        }
    };

    const ShowEditBank = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setTitleModal('Edit Payment Method Bank');
                setDetailEdit(true);

                setAccountCode(selectedDetail.accountCode);
                setAccountName(selectedDetail.accountName);
                setCheckBG(selectedDetail.checkBG);
                setRemarks(selectedDetail.remarks);
                setAmountCrr(selectedDetail.amountCrr);
                setAmount(selectedDetail.amount);
                var tglDue = updateDateFormat(selectedDetail.dueDate);
                if (tglDue !== nullDate && tglDue !== defaultDate) {
                    setDueDate(tglDue);
                    setIsDueDate(true);
                }
                setStatusDueDate(selectedDetail.statusDueDate);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    }

    const DeleteBank = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.BankData.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setBankData(result);

            let dateDueLast = defaultDate;
            let arrayData = props.DataPaymentDetail;
            arrayData.forEach(element => {
                if (element.sequence === props.selectedPaymentVoucher.sequence) {
                    element.paymentVoucherDetailBanks = result;
                }

                if (element.paymentVoucherDetailBanks.length > 0) {
                    element.paymentVoucherDetailBanks.forEach(el => {
                        if (new Date(dateDueLast) < new Date(el.dueDate)) {
                            dateDueLast = el.dueDate;
                        }
                    });
                }
            });

            if (dateDueLast === defaultDate) {
                dateDueLast = updateDateFormat(new Date());
            }
            props.setDueDateLast(dateDueLast);

            props.setDataPaymentDetail(arrayData);

            props.calculateTotalDebitCredit(arrayData, props.Rate, props.PaymentBy, props.PPh23AmountReal);

            setSelectedDetail({});
        }
    }

    const handleDoubleClick = () => {
        ShowEditBank();
    }

    return (
        <>
            <Box component="div">
                <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px' }}>
                    <Table sx={{ minWidth: 650 }} stickyHeader>
                        <TableHead
                            sx={{
                                '& th:first-of-type': {
                                    borderRadius: '12px 0 0'
                                },
                                '& th:last-child': {
                                    borderRadius: '0 12px 0 0'
                                }
                            }}
                        >
                            <TableRow>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Account Code</StyledTableCell>
                                <StyledTableCell>BG No</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Currency</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'right' }}>Amount</StyledTableCell>
                                <StyledTableCell sx={{ textAlign: 'center' }}>Due Date</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.BankData.length > 0 ?
                                    props.BankData.map((el) => {
                                        return (
                                            <TableRow
                                                key={el.sequence}
                                                onClick={() => setSelectedDetail(el)}
                                                onDoubleClick={() => handleDoubleClick()}
                                                sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                hover={true}
                                            >
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>
                                                    <Tooltip title={el.accountName}>
                                                        <span>{el.accountCode}</span>
                                                    </Tooltip>
                                                </StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.checkBG}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.amountCrr === 0 ? 'USD' : 'IDR'}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'right' }}>{convertToNominal(el.amount)}</StyledTableBodyCell>
                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{updateDateFormat(el.dueDate, true) !== getDefaultDateFormat(defaultDate) && updateDateFormat(el.dueDate) !== nullDate ? new Date(el.dueDate).toLocaleDateString("id", { day: '2-digit', month: '2-digit', year: 'numeric' }).split('T')[0] : '-'}</StyledTableBodyCell>
                                                <StyledTableBodyCell>{el.remarks}</StyledTableBodyCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                        <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '12px',
                                                    fontstyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: 'normal'
                                                }}
                                            >
                                                Data Empty
                                            </Typography>
                                        </StyledTableBodyCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

                <div className='mb-3 mt-4'>
                    {
                        props.isBank === true ?
                            <>
                                {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddBank, ShowEditBank, DeleteBank, props.isBank)}
                            </>
                            :
                            <>
                                <Stack spacing={'10px'} direction='row'>
                                    {ButtonComponent.AddButton(ShowAddBank, '', props.printing > 0)}
                                    {ButtonComponent.EditButton(ShowEditBank, '', props.isPosted)}
                                    {ButtonComponent.DeleteButton(DeleteBank, '', props.printing > 0)}
                                </Stack>
                            </>
                    }
                </div>
            </Box>

            {
                isModal && (
                    <ModalBank
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        PPh23AmountReal={props.PPh23AmountReal}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        getDateNow={getDateNow}
                        setSelectedDetail={props.setSelectedDetail}
                        IsPPh23={props.IsPPh23}
                        DataPaymentDetail={props.DataPaymentDetail}
                        setDataPaymentDetail={props.setDataPaymentDetail}
                        paymentVoucherId={props.paymentVoucherId}
                        PaymentBy={props.PaymentBy}
                        isPosted={props.isPosted}
                        selectedPaymentVoucher={props.selectedPaymentVoucher}
                        Rate={props.Rate}
                        printing={props.printing}
                        BankData={props.BankData}
                        setBankData={props.setBankData}
                        isApprovedAcc={props.isApprovedAcc}
                        AccountCode={AccountCode}
                        setAccountCode={setAccountCode}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                        CheckBG={CheckBG}
                        setCheckBG={setCheckBG}
                        isAccount={isAccount}
                        setIsAccount={setIsAccount}
                        Remarks={Remarks}
                        setRemarks={setRemarks}
                        AmountCrr={AmountCrr}
                        setAmountCrr={setAmountCrr}
                        Amount={Amount}
                        setAmount={setAmount}
                        setIsInput={props.setIsInput}
                        DueDate={DueDate}
                        setDueDate={setDueDate}
                        isDueDate={isDueDate}
                        setIsDueDate={setIsDueDate}
                        statusDueDate={statusDueDate}
                        setStatusDueDate={setStatusDueDate}
                        TempSeq={props.TempSeq}
                        setTempSeq={props.setTempSeq}
                        TempIsPPh23={props.TempIsPPh23}
                        setTempIsPPh23={props.setTempIsPPh23}
                        dueDateLast={props.dueDateLast}
                        setDueDateLast={props.setDueDateLast}
                        calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                        SettlementTo={props.SettlementTo}
                        isAddValue={props.isAddValue}
                        setIsAddValue={props.setIsAddValue}
                    />
                )
            }
        </>
    )
}

export default TabBank;