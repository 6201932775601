import { IconButton, Stack, Grid } from '@mui/material';
import React from 'react';
import { Icon, Input, showToast } from '../../../../components/ui';

const TabNParty = (props) => {
    const searchNParty = (data) => {
        if (data === 'search') {
            if (props.principleBy !== 0) {
                props.setTipe(3);
                props.setContactTypeId(17);
                props.setTitleModal('N Party');
                props.handleClick(3);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Principle By!' });
            }
        }
        else if (data === 'delete') {
            props.setNPartyId(0);
            props.setNPartyName('');
            props.setNPartyAddress('');
        }
        else if (data === 'reset') {
            props.setNPartyId(props.RefNPartyId);
            props.setNPartyName(props.RefNPartyName);
            props.setNPartyAddress(props.RefNPartyAddress);
        }
    };

    return (
        <>
            {
                !props.isSecond ?
                    <>
                        <Grid
                            container
                            spacing={'16px'}
                        >
                            <Grid
                                item
                                xl={1.5}
                                lg={1.5}
                                sm={1.5}
                                xs={12}
                            >
                                <Input
                                    label="Code"
                                    value={props.NPartyId}
                                    disabled
                                    hidden={props.isSecond}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={5}
                                lg={5}
                                sm={5}
                                xs={12}
                            >
                                <Input
                                    label="Name"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.NPartyName}
                                    onChange={(e) => props.setNPartyName(e.target.value)}
                                    disabled={props.contactType === 'MainShipmentOrder' ? true : false}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: props.contactType === 'MainShipmentOrder' ? true : false,
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={5.5}
                                lg={5.5}
                                sm={5.5}
                                xs={12}
                            >
                                <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                                    <Input
                                        label="Address"
                                        value={props.NPartyAddress}
                                        onChange={(e) => props.setNPartyAddress(e.target.value)}
                                        disabled={props.contactType === 'MainShipmentOrder' ? true : false}
                                        multiline
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: props.contactType === 'MainShipmentOrder' ? true : false,
                                            rows: 4,
                                            sx: {
                                                padding: '8px',
                                            },
                                        }}
                                    />

                                    {
                                        props.contactType === 'MainShipmentOrder' ?
                                            <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                                                <IconButton
                                                    onClick={() => searchNParty('search')}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '16px',
                                                                width: '16px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => searchNParty('delete')}
                                                    hidden={props.NPartyAddress ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '16px',
                                                                width: '16px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </Stack>
                                            :
                                            <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                                                <IconButton
                                                    onClick={() => searchNParty('reset')}
                                                    hidden={props.isSecond}
                                                >
                                                    <Icon
                                                        iconName={'refresh'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '16px',
                                                                width: '16px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </Stack>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Stack spacing={'16px'} direction="column" sx={{ marginTop: '16px' }}>
                            <Input
                                label="Name"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.NPartyName}
                                onChange={(e) => props.setNPartyName(e.target.value)}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px'
                                    }
                                }}
                            />

                            <Input
                                label="Address"
                                value={props.NPartyAddress}
                                onChange={(e) => props.setNPartyAddress(e.target.value)}
                                multiline
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    rows: 4,
                                    sx: {
                                        padding: '8px',
                                    },
                                }}
                            />
                        </Stack>
                    </>
            }
        </>
    )
}

export default TabNParty;