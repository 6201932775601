import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, TableFooter, Typography, Tooltip } from '@mui/material'
import React from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { formatCurrency } from '../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../components/Masks/RupiahInput';

import ModalIncomeCost from '../ModalBc/ModalIncomeCost';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Input, showToast } from '../../../../components/ui';
import { convertToNominal } from '../../../../utils/masks.jsx';

const TabEstimate = (props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F2FAFF',
      color: 'var(--Primary-One, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontstyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      padding: '8px 16px !important',
    },
  }));

  const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      // backgroundColor: '#FFFFFF',
      color: 'var(--Blue-Primary-1, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      padding: '0px 16px !important',
      height: '35px !important'
    },
  }));

  const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.footer}`]: {
      background: '#E4E8EC',
      color: '#083A50 !important',
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontstyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      padding: '8px 16px !important',
      left: 0,
      bottom: 0,
      zIndex: 2,
      position: 'sticky',
    },
  }));

  const [isPlusMin, setIsPlusMin] = useState(true)
  const [isModal2, setIsModal2] = useState(false);
  const [titleModal2, setTitleModal2] = useState('');
  const [selectedDetailIncome, setSelectedDetailIncome] = useState({})
  const [selectedDetailCost, setSelectedDetailCost] = useState({})
  const [isIncome, setIsIncome] = useState(true)
  const [detailSequence, setDetailSequence] = useState(0)

  const [tipe, setTipe] = useState();
  const [tipeName, setTipeName] = useState();
  const [COACode, setCOACode] = useState('');
  const [COAName, setCOAName] = useState('');
  const [perQty, setPerQty] = useState('')
  const [amount, setAmount] = useState('')
  const [amountCrr, setAmountCrr] = useState(1)
  const [isCostToCost, setIsCostToCost] = useState(false)
  const [originalUsd, setOriginalUsd] = useState('')
  const [originalRate, setOriginalRate] = useState('')
  const [sign, setSign] = useState(true)
  const [isOthers, setIsOthers] = useState(false);

  const handleClick2 = () => {
    if (isModal2 === false) {
      setIsModal2(true);
    } else {
      setIsModal2(false);
    }
  };

  const ShowAddIncome = () => {
    if (props.jobTypeId) {
      EmptyState()
      setTitleModal2('Add Income');
      props.setDetailEdit(false)
      if (props.dataIncome?.length > 0) {
        let data = props.dataIncome.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
        setDetailSequence(data.sequence)
      }
      else {
        setDetailSequence(0);
      }
      setIsIncome(true);
      setIsPlusMin(true);
      props.setIsContainer(false);
      props.setContainerSize('All');
      handleClick2();

    } else {
      showToast({ type: 'error', message: "Invalid Job Type!" });
    }
  }

  const ShowEditIncome = () => {
    if (!selectedDetailIncome.sequence) {
      showToast({ type: 'error', message: "Please Select Data!" });
    } else {
      setTitleModal2('Edit Income');
      props.setDetailEdit(true)
      if (selectedDetailIncome.customerTypeId === 1) {
        setIsPlusMin(false);
      }
      else {
        setIsPlusMin(true);
      }
      setTipeName(selectedDetailIncome.customerTypeName)
      setCOACode(selectedDetailIncome.coaCode);
      setCOAName(selectedDetailIncome.coaName);
      props.setContactId(selectedDetailIncome.contactId)
      props.setTempCustName(selectedDetailIncome.contactName)
      props.setAccountId(selectedDetailIncome.accountId)
      props.setAccountCode(selectedDetailIncome.accountCode)
      props.setDescription(selectedDetailIncome.description)
      props.setIsContainer(selectedDetailIncome.isContainer)
      props.setContainerFormat(selectedDetailIncome.formatContainer)
      setAmountCrr(selectedDetailIncome.amountCrr)
      setIsCostToCost(selectedDetailIncome.isCostToCost)
      setOriginalUsd(selectedDetailIncome.originalUsd)
      setOriginalRate(selectedDetailIncome.originalRate)
      props.setQuantity(selectedDetailIncome.quantity)
      setPerQty(selectedDetailIncome.perQty)
      setSign(selectedDetailIncome.sign)
      setAmount(selectedDetailIncome.amount)
      setTipe(selectedDetailIncome.customerTypeId)
      localStorage.setItem("ContactType", selectedDetailIncome.customerTypeId);
      props.setTempAccountName(selectedDetailIncome.accountName)
      if (selectedDetailIncome.accountName === 'OTHER') {
        setIsOthers(true);
      }
      else {
        setIsOthers(false);
      }
      setIsIncome(true);
      handleClick2();
    }
  }

  const DeleteIncome = () => {
    if (!selectedDetailIncome.sequence) {
      showToast({ type: 'error', message: "Please Select Data!" });
    } else {

      let tempSequence = selectedDetailIncome.sequence
      let sumIncomeUsd = 0;
      let sumIncomeIdr = 0;
      let sumCostUsd = 0;
      let sumCostIdr = 0;
      const deleteFunction = (dataIncome) => {
        return dataIncome.sequence !== tempSequence
      }
      const result = props.dataIncome.filter(deleteFunction)
      if (result?.length > 0) {
        tempSequence = 0
        result.forEach((el) => {
          if (el.amountCrr === 1) {
            sumIncomeIdr += el.amount
          } else {
            sumIncomeUsd += el.amount
          }

          tempSequence += 1
          el.sequence = tempSequence
        })

        setDetailSequence(tempSequence)
      } else {
        setDetailSequence(0)
      }

      let arrDetailCost = [...props.dataCost]
      arrDetailCost.forEach(el => {
        if (el.amountCrr === 1) {
          sumCostIdr += el.amount
        } else {
          sumCostUsd += el.amount
        }
      })
      props.setDataIncome(result)
      props.setTotalIncomeUsd(Number(sumIncomeUsd.toFixed(2)))
      props.setTotalIncomeIdr(Number(sumIncomeIdr.toFixed(2)))
      props.setTotalUsd(sumIncomeUsd - sumCostUsd)
      props.setTotalIdr(sumIncomeIdr - sumCostIdr)
      setSelectedDetailIncome({})
      showToast({ type: 'success', message: "Delete Detail Income Success!" });
    }
  }

  const ShowAddCost = () => {
    if (props.jobTypeId) {
      EmptyState()
      setTitleModal2('Add Cost');
      if (props.dataCost?.length > 0) {
        let data = props.dataCost.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
        setDetailSequence(data.sequence)
      }
      else {
        setDetailSequence(0);
      }
      props.setDetailEdit(false)
      setIsPlusMin(true);
      setIsIncome(false);
      props.setIsContainer(false);
      props.setContainerSize('All');
      handleClick2();
    } else {
      showToast({ type: 'error', message: "Invalid Job Type!" });
    }
  }

  const ShowEditCost = () => {
    if (!selectedDetailCost.sequence) {
      showToast({ type: 'error', message: "Please Select Data!" });
    } else {
      setTitleModal2('Edit Cost');
      props.setDetailEdit(true)
      setTipeName(selectedDetailCost.customerTypeName)
      if (selectedDetailCost.customerTypeId === 1) {
        setIsPlusMin(false);
      }
      else {
        setIsPlusMin(true);
      }
      props.setContactId(selectedDetailCost.contactId)
      props.setTempCustName(selectedDetailCost.contactName)
      props.setAccountId(selectedDetailCost.accountId)
      props.setAccountCode(selectedDetailCost.accountCode)
      setCOACode(selectedDetailCost.coaCode);
      setCOAName(selectedDetailCost.coaName);
      props.setDescription(selectedDetailCost.description)
      props.setIsContainer(selectedDetailCost.isContainer)
      props.setContainerFormat(selectedDetailCost.formatContainer)
      setAmountCrr(selectedDetailCost.amountCrr)
      setIsCostToCost(selectedDetailCost.isCostToCost)
      setOriginalUsd(selectedDetailCost.originalUsd)
      setOriginalRate(selectedDetailCost.originalRate)
      props.setQuantity(selectedDetailCost.quantity)
      setPerQty(selectedDetailCost.perQty)
      setSign(selectedDetailCost.sign)
      setAmount(selectedDetailCost.amount)
      setTipe(selectedDetailCost.customerTypeId)
      localStorage.setItem("ContactType", selectedDetailCost.customerTypeId);
      props.setTempAccountName(selectedDetailCost.accountName)
      if (selectedDetailCost.accountName === 'OTHER') {
        setIsOthers(true);
      }
      else {
        setIsOthers(false);
      }
      setIsIncome(false);
      handleClick2();
    }
  }

  const DeleteCost = () => {
    if (!selectedDetailCost.sequence) {
      showToast({ type: 'error', message: "Please Select Data!" });
    } else {

      let tempSequence = selectedDetailCost.sequence
      let sumIncomeUsd = 0;
      let sumIncomeIdr = 0;
      let sumCostUsd = 0;
      let sumCostIdr = 0;
      const deleteFunction = (dataCost) => {
        return dataCost.sequence !== tempSequence
      }
      const result = props.dataCost.filter(deleteFunction)
      if (result?.length > 0) {
        tempSequence = 0
        result.forEach((el) => {
          if (el.amountCrr === 1) {
            sumCostIdr += el.amount
          } else {
            sumCostUsd += el.amount
          }

          tempSequence += 1
          el.sequence = tempSequence
        })

        setDetailSequence(tempSequence)
      } else {
        setDetailSequence(0)
      }

      let arrDetailCost = [...props.dataIncome]
      arrDetailCost.forEach(el => {
        if (el.amountCrr === 1) {
          sumIncomeIdr += el.amount
        } else {
          sumIncomeUsd += el.amount
        }
      })

      props.setDataCost(result)
      props.setTotalCostUsd(Number(sumCostUsd.toFixed(2)))
      props.setTotalCostIdr(Number(sumCostIdr.toFixed(2)))
      props.setTotalUsd(sumIncomeUsd - sumCostUsd)
      props.setTotalIdr(sumIncomeIdr - sumCostIdr)
      setSelectedDetailCost({})
      showToast({ type: 'success', message: "Delete Detail Cost Success!" });
    }
  }

  const EmptyState = () => {
    setTipe()
    setTipeName('')
    props.setAccountId(0)
    props.setAccountCode('')
    props.setTempAccountName('')
    props.setContainerFormat('All')
    props.setContainerSize('All')
    props.setContainerTypeId(0)
    props.setContainerTypeCode('')
    props.setContainerTypeName('')
    props.setIsContainer(false)
    props.setDescription('')
    props.setQuantity('')
    setCOACode('')
    setCOAName('')
    setPerQty('')
    setAmount('')
    setAmountCrr(1)
    setIsCostToCost(false)
    props.setContactId(0)
    props.setTempCustName('')
    setOriginalUsd('')
    setOriginalRate('')
    setSign(true)
    setIsOthers(false)
  }

  const selectedStyle = { backgroundColor: '#DCDCDC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
  const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled }

  const mainContainer = () => {
    return (
      <>
        <Grid
          container
          spacing={'32px'}
        >
          <Grid
            item
            xl={2}
            lg={2}
            sm={2}
            xs={12}
          >
            <Box component='div'>
              <Typography
                sx={{
                  color: 'var(--Blue-Primary-1, #083A50)',
                  textAlign: 'left',
                  fontFamily: 'Nunito',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '19.6px',
                }}
              >
                Rate (USD)
              </Typography>

              <Stack spacing={'16px'} direction='column' className='mt-1'>
                <Input
                  label={'Selling'}
                  textAlign='right'
                  value={props.sellingRateInUSD}
                  onChange={(e) => props.setSellingRateInUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                  formControlProps={{
                    sx: {
                      marginTop: '24px'
                    }
                  }}
                  inputBaseProps={{
                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                  }}
                  disabled={props.isApproved}
                />

                <Input
                  label={'Buying'}
                  textAlign='right'
                  value={props.buyingRateInUSD}
                  onChange={(e) => props.setBuyingRateInUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                  formControlProps={{
                    sx: {
                      marginTop: '24px'
                    }
                  }}
                  inputBaseProps={{
                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                  }}
                  disabled={props.isApproved}
                />
              </Stack>
            </Box>
          </Grid>

          <Grid
            item
            xl={2}
            lg={2}
            sm={2}
            xs={12}
          >
            <Box component="div">
              <Input
                textAlign='right'
                label={'Rate'}
                value={props.exRate}
                disabled
                fullWidth
                formControlProps={{
                  sx: {
                    marginTop: '24px',
                    width: '100%'
                  }
                }}
                inputBaseProps={{
                  inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            sm={4}
            xs={12}
          >
            <Box component="div" fullWidth>
              <Typography
                sx={{
                  color: 'var(--Blue-Primary-1, #083A50)',
                  textAlign: 'left',
                  fontFamily: 'Nunito',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '19.6px',
                }}
              >
                Margin Ratio
              </Typography>

              <Stack spacing={'16px'} direction='row' className='mt-1' fullWidth >
                <Input
                  label={'Profit'}
                  textAlign='right'
                  value={props.marginIncome}
                  disabled
                  fullWidth
                  formControlProps={{
                    sx: {
                      marginTop: '24px',
                      width: '100%'
                    }
                  }}
                />

                <Input
                  label={'Cost'}
                  textAlign='right'
                  value={props.marginCost}
                  disabled
                  fullWidth
                  formControlProps={{
                    sx: {
                      marginTop: '24px',
                      width: '100%'
                    }
                  }}
                />
              </Stack>
            </Box>
          </Grid>

          <Grid
            item
            xl={4}
            lg={4}
            sm={4}
            xs={12}
          >
            <Box component="div" fullWidth>
              <Typography
                sx={{
                  color: 'var(--Blue-Primary-1, #083A50)',
                  textAlign: 'left',
                  fontFamily: 'Nunito',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '19.6px',
                }}
              >
                Total Estimated P/L
              </Typography>

              <Stack spacing={'16px'} direction='row' className='mt-1' fullWidth>
                <Input
                  label={'USD'}
                  textAlign='right'
                  value={props.totalUsd}
                  disabled
                  fullWidth
                  formControlProps={{
                    sx: {
                      marginTop: '24px',
                      width: '100%'
                    }
                  }}
                />

                <Input
                  label={'IDR'}
                  textAlign='right'
                  value={props.totalIdr}
                  disabled
                  fullWidth
                  formControlProps={{
                    sx: {
                      marginTop: '24px',
                      width: '100%'
                    }
                  }}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <Box component="div" className='mt-4'>
          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Income
          </Typography>

          <TableContainer sx={{ maxHeight: 345, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead
                sx={{
                  '& th:first-of-type': {
                    borderRadius: '12px 0 0'
                  },
                  '& th:last-child': {
                    borderRadius: '0 12px 0 0'
                  }
                }}
              >
                <TableRow>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Customer</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>CTC</StyledTableCell>
                  <StyledTableCell>Sign</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', }}>Amount USD</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', }}>Amount IDR</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.dataIncome?.length > 0 ?
                    props.dataIncome.map((el) => {
                      return (
                        <TableRow
                          key={el.sequence}
                          onClick={() => setSelectedDetailIncome(el)}
                          onDoubleClick={() => ShowEditIncome()}
                          sx={
                            selectedDetailIncome.sequence === el.sequence ?
                              selectedStyle
                              :
                              el.rowStatus === 'DEL' ?
                                deletedDetailStyle
                                :
                                { backgroundColor: '#FFFFFF' }
                          }
                          hover={true}
                        >
                          <StyledTableBodyCell>{el.customerTypeName}</StyledTableBodyCell>
                          <StyledTableBodyCell>
                            <Tooltip title={el.accountCode + ' - ' + el.accountName}>
                              <span>{el.contactName}</span>
                            </Tooltip>
                          </StyledTableBodyCell>
                          <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                          <StyledTableBodyCell>{el.isCostToCost === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                          <StyledTableBodyCell>{el.sign === true ? '+' : '-'}</StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                            {el.amountCrr === 0 ? convertToNominal(el.amount) : convertToNominal(0)}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                            {el.amountCrr === 1 ? convertToNominal(el.amount) : convertToNominal(0)}
                          </StyledTableBodyCell>
                        </TableRow>
                      )
                    })
                    :
                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                      <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '12px',
                            fontstyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal'
                          }}
                        >
                          Data Empty
                        </Typography>
                      </StyledTableBodyCell>
                    </TableRow>
                }
              </TableBody>

              <TableFooter
                sx={{
                  left: 0,
                  bottom: 0,
                  zIndex: 2,
                  position: 'sticky',
                  background: '#E4E8EC',
                  '& td:first-of-type': {
                    borderRadius: '0 0 0 12px'
                  },
                  '& td:last-child': {
                    borderRadius: '0 0 12px 0'
                  }
                }}
              >
                <TableRow>
                  <StyledTableFooterCell sx={{ textAlign: 'center', }} colSpan={5}>
                    Total Income
                  </StyledTableFooterCell>
                  <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                    {convertToNominal(props.totalIncomeUsd)}
                  </StyledTableFooterCell>
                  <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                    {convertToNominal(props.totalIncomeIdr)}
                  </StyledTableFooterCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <div className='mt-4'>
            {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddIncome, ShowEditIncome, DeleteIncome, props.isApproved)}
          </div>
        </Box>

        <hr
          className='mt-4 mb-2'
          style={{
            borderWidth: '1.75px',
            borderStyle: 'solid',
            color: '#CACED8 !important',
            marginTop: '0px !important',
            marginLeft: '0px !important',
            marginRight: '0px !important',
            marginBottom: '0px !important',
            borderColor: '#CACED8 !important',
            backgroundColor: '#CACED8 !important',
          }}
        />

        <Box component="div" className='mt-3'>
          <Typography
            sx={{
              color: 'var(--Blue-Primary-1, #083A50)',
              fontFamily: 'Nunito',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
            }}
          >
            Cost
          </Typography>

          <TableContainer
            sx={{
              maxHeight: 345, marginTop: '8px', background: '#FFF', borderRadius: '12px'
            }}
          >
            <Table
              sx={{
                minWidth: '650',
              }}
              stickyHeader
            >
              <TableHead
                sx={{
                  '& th:first-of-type': {
                    borderRadius: '12px 0 0'
                  },
                  '& th:last-child': {
                    borderRadius: '0 12px 0 0'
                  }
                }}
              >
                <TableRow>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Customer</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>CTC</StyledTableCell>
                  <StyledTableCell>Sign</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', }}>Amount USD</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', }}>Amount IDR</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.dataCost?.length > 0 ?
                    props.dataCost.map((el) => {
                      return (
                        <TableRow
                          key={el.sequence}
                          onClick={() => setSelectedDetailCost(el)}
                          onDoubleClick={() => ShowEditCost()}
                          sx={selectedDetailCost.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : { backgroundColor: '#FFFFFF' }}
                          hover={true}
                        >
                          <StyledTableBodyCell>{el.customerTypeName}</StyledTableBodyCell>
                          <StyledTableBodyCell>
                            <Tooltip title={el.accountCode + ' - ' + el.accountName}>
                              <span>{el.contactName}</span>
                            </Tooltip>
                          </StyledTableBodyCell>
                          <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                          <StyledTableBodyCell>{el.isCostToCost === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                          <StyledTableBodyCell>{el.sign === true ? '+' : '-'}</StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                            {el.amountCrr === 0 ? convertToNominal(el.amount) : 0}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                            {el.amountCrr === 1 ? convertToNominal(el.amount) : 0}
                          </StyledTableBodyCell>
                        </TableRow>
                      )
                    })
                    :
                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                      <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '12px',
                            fontstyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal'
                          }}
                        >
                          Data Empty
                        </Typography>
                      </StyledTableBodyCell>
                    </TableRow>
                }
              </TableBody>
              <TableFooter
                sx={{
                  left: 0,
                  bottom: 0,
                  zIndex: 2,
                  position: 'sticky',
                  background: '#E4E8EC',
                  '& td:first-of-type': {
                    borderRadius: '0 0 0 12px'
                  },
                  '& td:last-child': {
                    borderRadius: '0 0 12px 0'
                  }
                }}
              >
                <TableRow>
                  <StyledTableFooterCell sx={{ textAlign: 'center', }} colSpan={5}>
                    Total Cost
                  </StyledTableFooterCell>
                  <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                    {convertToNominal(props.totalCostUsd)}
                  </StyledTableFooterCell>
                  <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                    {convertToNominal(props.totalCostIdr)}
                  </StyledTableFooterCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          <div className='mt-4'>
            {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddCost, ShowEditCost, DeleteCost, props.isApproved)}
          </div>
        </Box>
      </>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        padding: '16px 32px',
        flexDirection: 'column',
        borderRadius: '8px',
        marginTop: '-13px',
        background: '#FAFAFA',
      }}
    >
      {
        props.isApproved ?
          <fieldset disabled>
            {mainContainer()}
          </fieldset>
          :
          <fieldset>
            {mainContainer()}
          </fieldset>
      }

      {
        isModal2 && (
          <ModalIncomeCost
            isModal2={isModal2}
            handleClick2={handleClick2}
            titleModal2={titleModal2}
            bookingId={props.bookingId}
            contactTypeId={props.contactTypeId}
            calculateBookingData={props.calculateBookingData}
            isIncome={isIncome}
            exRate={props.exRateReal}
            setDataIncome={props.setDataIncome}
            setDataCost={props.setDataCost}
            dataIncome={props.dataIncome}
            dataCost={props.dataCost}
            detailSequence={detailSequence}
            isApproved={props.isApproved}
            isCopy={props.isCopy}
            selectedDetailIncome={selectedDetailIncome}
            setSelectedDetailIncome={setSelectedDetailIncome}
            selectedDetailCost={selectedDetailCost}
            setSelectedDetailCost={setSelectedDetailCost}
            detailEdit={props.detailEdit}
            setDetailEdit={props.setDetailEdit}
            setTotalIncomeUsd={props.setTotalIncomeUsd}
            setTotalIncomeIdr={props.setTotalIncomeIdr}
            setTotalCostUsd={props.setTotalCostUsd}
            setTotalCostIdr={props.setTotalCostIdr}
            setTotalUsd={props.setTotalUsd}
            setTotalIdr={props.setTotalIdr}

            isVolume20={props.isVolume20}
            setIsVolume20={props.setIsVolume20}
            isVolume40={props.isVolume40}
            setIsVolume40={props.setIsVolume40}
            isVolume45={props.isVolume45}
            setIsVolume45={props.setIsVolume45}
            isVolumeCBM={props.isVolumeCBM}
            setIsVolumeCBM={props.setIsVolumeCBM}
            shipmentVolume20={props.shipmentVolume20}
            setShipmentVolume20={props.setShipmentVolume20}
            shipmentVolume40={props.shipmentVolume40}
            setShipmentVolume40={props.setShipmentVolume40}
            shipmentVolume45={props.shipmentVolume45}
            setShipmentVolume45={props.setShipmentVolume45}
            shipmentVolumeCBM={props.shipmentVolumeCBM}
            setShipmentVolumeCBM={props.setShipmentVolumeCBM}

            accountId={props.accountId}
            setAccountId={props.setAccountId}
            accountCode={props.accountCode}
            setAccountCode={props.setAccountCode}
            tempAccountName={props.tempAccountName}
            setTempAccountName={props.setTempAccountName}
            isContainer={props.isContainer}
            setIsContainer={props.setIsContainer}
            containerSize={props.containerSize}
            setContainerSize={props.setContainerSize}
            containerFormat={props.containerFormat}
            setContainerFormat={props.setContainerFormat}
            containerTypeId={props.containerTypeId}
            setContainerTypeId={props.setContainerTypeId}
            containerTypeCode={props.containerTypeCode}
            setContainerTypeCode={props.setContainerTypeCode}
            containerTypeName={props.containerTypeName}
            setContainerTypeName={props.setContainerTypeName}
            description={props.description}
            setDescription={props.setDescription}
            quantity={props.quantity}
            setQuantity={props.setQuantity}
            perQty={perQty}
            setPerQty={setPerQty}
            amount={amount}
            setAmount={setAmount}
            amountCrr={amountCrr}
            setAmountCrr={setAmountCrr}
            isCostToCost={isCostToCost}
            setIsCostToCost={setIsCostToCost}
            contactId={props.contactId}
            setContactId={props.setContactId}
            tempCustName={props.tempCustName}
            setTempCustName={props.setTempCustName}
            originalUsd={originalUsd}
            setOriginalUsd={setOriginalUsd}
            originalRate={originalRate}
            setOriginalRate={setOriginalRate}
            sign={sign}
            setSign={setSign}
            tipe={tipe}
            setTipe={setTipe}
            tipeName={tipeName}
            setTipeName={setTipeName}
            isOthers={isOthers}
            setIsOthers={setIsOthers}

            handleClickContact={props.handleClickContact}
            setTitleModalContact={props.setTitleModalContact}

            idCustomer={props.idCustomer}
            CustomerName={props.CustomerName}
            idAgent={props.idAgent}
            agentName={props.agentName}
            idEMKL={props.idEMKL}
            EMKLName={props.EMKLName}
            idDepo={props.idDepo}
            depoName={props.depoName}
            idCarrier={props.idCarrier}
            carrierName={props.carrierName}

            portType={props.portType}
            jobServiceType={props.jobServiceType}
            setIsPlusMin={setIsPlusMin}
            isPlusMin={isPlusMin}
            containerData={props.containerData}
            EmptyState={EmptyState}
            COACode={COACode}
            setCOACode={setCOACode}
            COAName={COAName}
            setCOAName={setCOAName}

            setDetailSequence={setDetailSequence}
          />
        )
      }
    </div>
  )
}

export default TabEstimate