import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Grid, Box, Backdrop } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import TableX from '../../../../components/Tables/TableX';
import freezeContainer from '../../../../Styles/freezeContainer';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import ButtonListInvoiceDelete from './Buttons/ButtonListInvoiceDelete';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ApiService from '../../../../services/ApiService';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import { defaultDate, updateDateFormat } from '../../../../utils/date';

const ListInvoiceDelete = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramInvoice = 'invoice/invoice';
    const paramGL = 'generalLedger/generalLedger';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);

    const [jobType, setJobType] = useState('' + JSON.parse(localStorage.getItem("jobTypeId")) === '0' ? '1' : '' + JSON.parse(localStorage.getItem("jobTypeId")));
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [dataJobType, setDataJobType] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');
    const [DeleteRemarks, setDeleteRemarks] = useState('');
    const [typeDelete, setTypeDelete] = useState('H');

    const [isApprove, setIsApprove] = useState(false);

    const DataEditType = [
        { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
        { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
        { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
    ];

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Invoice Deletion Approval', link: '/Majura/InvoiceDeletionApproval', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Invoice Deletion Approval - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        params = [{ 'attr': 'jobTypeId', 'value': '' + jobType }];
        ApiService.InvoiceDeletePostByPage(paramInvoice, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.invoiceReqDels
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.reqDelColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FINVRD')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        if (hasAccess) {
                            GetDataJobType();
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getData2 = (pageNumber, pageSize, filters, jobType) => {
        setIsLoading(true);

        let params = [];
        params = [{ 'attr': 'jobTypeId', 'value': '' + jobType }];
        ApiService.InvoiceDeletePostByPage(paramInvoice, 'PAGE', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.invoiceReqDels
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.reqDelColumns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    setIsLoading(false);
                }

                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);

                    getData(1, 50, []);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleChange = (e) => {
        localStorage.setItem("jobTypeId", JSON.stringify(e.target.value));
        setJobType(e.target.value);
        getData2(1, 50, filter, e.target.value);
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    const handleClickDelete = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
            setTypeDelete('H');
            setDeleteRemarks('');
        }
        else {
            setIsModalDelete(false);
            setDeleteRemarks('');
        }
    }

    function ConfirmationInvoice() {
        if (titleConfirmation === 'Approve Delete') {
            setIsApprove(true);
            setTitleModalDelete('Invoice');
            handleClickDelete();
        }
        else if (titleConfirmation === 'Decline Delete') {
            setIsApprove(false);
            setTitleModalDelete('Invoice');
            handleClickDelete();
        }
        setIsModalConfirmation(false);
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(1, 50, []);
    }

    const ViewData = () => {
        if (jobType !== '10') {
            if (SelectedData.invoiceId !== undefined) {
                history('/Majura/Invoice/' + jobType + '/' + SelectedData.invoiceId + '/' + SelectedData.branchId + '/' + SelectedData.companyId + '/' + SelectedData.countryId);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
        }
        else {
            showToast({ type: 'error', message: 'Coming Soon' });
        }
    }

    const ApproveDeleteData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setTitleConfirmation('Approve Delete')
                setTitleModalConfirmation('Invoice');
                handleClickConfirmation();
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted' });
            }
        }
    }

    const DeclineDeleteData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setTitleConfirmation('Decline Delete')
                setTitleModalConfirmation('Invoice');
                handleClickConfirmation();
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted' });
            }
        }
    }

    const DeleteInvoice = () => {
        handleClickDelete();
        setIsLoadingBackDrop(true);

        var oldData = {};
        let deleteTypes = 0;
        let approvedStatus = 1;
        let approvedStatusCode = 'APP';
        let approvedStatusName = 'Approved';

        let etd = defaultDate;
        let eta = defaultDate;
        let datePaid = defaultDate;
        let dateBadDebt = defaultDate;
        let dateReBadDebts = defaultDate;
        let datePrint = defaultDate;
        let dateDeleted = defaultDate;
        let dateRePrintApproved = defaultDate;
        let dateDelivered = defaultDate;
        let dateApprovedCredit = defaultDate;
        let dateTransaction = defaultDate;

        if (isApprove === true) {
            approvedStatus = 1;
            approvedStatusCode = 'APP';
            approvedStatusName = 'Approved';

            if (typeDelete === 'H') {
                deleteTypes = 1;
            }
            else if (typeDelete === 'S') {
                deleteTypes = 2;
            }
            else if (typeDelete === 'P') {
                deleteTypes = 3;
            }
        }
        else {
            approvedStatus = 2;
            approvedStatusCode = 'DCL';
            approvedStatusName = 'Declined';
        }

        let body = {
            "invoiceReqDel": {
                "countryId": dataSelected.countryId,
                "companyId": dataSelected.companyId,
                "branchId": dataSelected.branchId,
                "invoiceId": dataSelected.invoiceId
            }
        };

        ApiService.InvoiceDeletePostById(paramInvoice, body)
            .then((response) => {
                if (response.status === 200) {
                    etd = updateDateFormat(response.data.data.invoice.etd, true);
                    eta = updateDateFormat(response.data.data.invoice.eta, true);
                    datePaid = updateDateFormat(response.data.data.invoice.paidOn, true);
                    dateBadDebt = updateDateFormat(response.data.data.invoice.badDebtOn, true);
                    dateReBadDebts = updateDateFormat(response.data.data.invoice.dateReBadDebt, true);
                    datePrint = updateDateFormat(response.data.data.invoice.printedOn, true);
                    dateDeleted = updateDateFormat(response.data.data.invoice.deletedOn, true);
                    dateRePrintApproved = updateDateFormat(response.data.data.invoice.rePrintApprovedOn, true);
                    dateDelivered = updateDateFormat(response.data.data.invoice.deliveredOn, true);
                    dateApprovedCredit = updateDateFormat(response.data.data.invoice.approvedCreditOn, true);
                    dateTransaction = updateDateFormat(response.data.data.invoice.transactionOn, true);

                    oldData = {
                        "invoiceRequest": {
                            "invoice": {
                                "countryId": response.data.data.invoice.countryId,
                                "companyId": response.data.data.invoice.companyId,
                                "branchId": response.data.data.invoice.branchId,
                                "userId": response.data.data.invoice.userId,
                                "userCode": response.data.data.invoice.userCode,

                                "rowStatus": "DEL",
                                "id": dataSelected.invoiceId,
                                "ticketId": response.data.data.invoice.ticketId,
                                "invoiceNo": response.data.data.invoice.invoiceNo,
                                "debetCredit": response.data.data.invoice.debetCredit,
                                "eplId": response.data.data.invoice.eplId,
                                "shipmentId": response.data.data.invoice.shipmentId,
                                "shipmentNo": response.data.data.invoice.shipmentNo,
                                "bookingId": response.data.data.invoice.bookingId,
                                "bookingNo": response.data.data.invoice.bookingNo,
                                "jobTypeCode": response.data.data.invoice.jobTypeCode,
                                "jobTypeId": response.data.data.invoice.jobTypeId,
                                "etd": etd,
                                "eta": eta,
                                "invoicesTo": response.data.data.invoice.invoicesTo,
                                "customerTypeId": response.data.data.invoice.customerTypeId,
                                "customerId": response.data.data.invoice.customerId,
                                "customerName": response.data.data.invoice.customerName,
                                "customerAddress": response.data.data.invoice.customerAddress,
                                "billId": response.data.data.invoice.billId,
                                "billName": response.data.data.invoice.billName,
                                "billAddress": response.data.data.invoice.billAddress,
                                "invoicesToCode": response.data.data.invoice.invoicesToCode,
                                "invoiceStatus": response.data.data.invoice.invoiceStatus,
                                "paymentUSD": response.data.data.invoice.paymentUSD,
                                "paymentIDR": response.data.data.invoice.paymentIDR,
                                "totalPaymentUSD": response.data.data.invoice.totalPaymentUSD,
                                "totalPaymentIDR": response.data.data.invoice.totalPaymentIDR,
                                "totalVatUSD": response.data.data.invoice.totalVatUSD,
                                "totalVatIDR": response.data.data.invoice.totalVatIDR,
                                "rate": response.data.data.invoice.rate,
                                "jobOwnerId": response.data.data.invoice.jobOwnerId,
                                "exRateDate": response.data.data.invoice.exRateDate,
                                "period": response.data.data.invoice.period,
                                "yearPeriod": response.data.data.invoice.yearPeriod,
                                "invoicesAgent": response.data.data.invoice.invoicesAgent,
                                "isEdited": response.data.data.invoice.isEdited,
                                "isGeneral": response.data.data.invoice.isGeneral,
                                "linkTo": response.data.data.invoice.linkTo,
                                "dueDate": response.data.data.invoice.dueDate,
                                "paid": response.data.data.invoice.paid,
                                "paidOn": datePaid,
                                "saveOR": response.data.data.invoice.saveOR,
                                "badDebt": response.data.data.invoice.badDebt,
                                "badDebtOn": dateBadDebt,
                                "reBadDebt": response.data.data.invoice.reBadDebt,
                                "dateReBadDebt": dateReBadDebts,
                                "printing": response.data.data.invoice.printing,
                                "printedOn": datePrint,
                                "deleted": response.data.data.invoice.deleted,
                                "deletedOn": dateDeleted,
                                "invoiceNo2": response.data.data.invoice.invoiceNo2,
                                "invHeader": response.data.data.invoice.invHeader,
                                "exRateId": response.data.data.invoice.exRateId,
                                "rePrintApproved": response.data.data.invoice.rePrintApproved,
                                "rePrintApprovedOn": dateRePrintApproved,
                                "rePrintApprovedBy": response.data.data.invoice.rePrintApprovedBy,
                                "deletedRemarks": response.data.data.invoice.deletedRemarks,
                                "isCostToCost": response.data.data.invoice.isCostToCost,
                                "sfpId": response.data.data.invoice.sfpId,
                                "sfpNoFormat": response.data.data.invoice.sfpNoFormat,
                                "sfpDetailId": response.data.data.invoice.sfpDetailId,
                                "uniqueKeySFP": response.data.data.invoice.uniqueKeySFP,
                                "uniqueKeyInvoice": response.data.data.invoice.uniqueKeyInvoice,
                                "deleteType": deleteTypes,
                                "deleteTypeRefInvId": response.data.data.invoice.deleteTypeRefInvId,
                                "kursKMK": response.data.data.invoice.kursKMK,
                                "kursKMKId": response.data.data.invoice.kursKMKId,
                                "isDelivered": response.data.data.invoice.isDelivered,
                                "deliveredOn": dateDelivered,
                                "deliveredRemarks": response.data.data.invoice.deliveredRemarks,
                                "sfpReference": response.data.data.invoice.sfpReference,
                                "approvedCredit": response.data.data.invoice.approvedCredit,
                                "approvedCreditBy": response.data.data.invoice.approvedCreditBy,
                                "approvedCreditOn": dateApprovedCredit,
                                "approvedCreditRemarks": response.data.data.invoice.approvedCreditRemarks,
                                "packingListNo": response.data.data.invoice.packingListNo,
                                "siCustomerNo": response.data.data.invoice.siCustomerNo,
                                "reference": response.data.data.invoice.reference,
                                "isStampDuty": response.data.data.invoice.isStampDuty,
                                "stampDutyAmount": response.data.data.invoice.stampDutyAmount,
                                "pejkpNumber": response.data.data.invoice.pejkpNumber,
                                "pejkpReference": response.data.data.invoice.pejkpReference,
                                "transactionOn": dateTransaction,
                                "requester": response.data.data.invoice.requester,

                                "idLama": 0,
                            },
                            "invoiceDetails": response.data.data.invoice.invoiceDetails
                        },
                        "invoiceReqDelRequest": {
                            "invoiceReqDel": {
                                "countryId": dataSelected.countryId,
                                "companyId": dataSelected.companyId,
                                "branchId": dataSelected.branchId,
                                "id": dataSelected.id,
                                "deleteType": deleteTypes,
                                "remarks": dataSelected.remarks,
                                "approvedStatus": approvedStatus,
                                "approvedStatusCode": approvedStatusCode,
                                "approvedStatusName": approvedStatusName,
                                "approvedRemarks": DeleteRemarks,
                            }
                        }
                    };

                    ApiService.ApproveInvoiceDelete(paramInvoice, oldData)
                        .then((res) => {
                            if (res.data.code === 200) {
                                setIsLoadingBackDrop(false);
                                if (isApprove) {
                                    CreateGL(res.data.data.Id, dataSelected);
                                }
                                getData(numPage, rowsCount, filter);
                                showToast({ type: 'success', message: 'Delete Data Success' });
                                setIsModalDelete(false);
                            }
                            else {
                                setIsLoadingBackDrop(false);
                                showToast({ type: 'error', message: res.data.message });
                                setIsModalDelete(false);
                            }
                        })
                        .catch(function (error) {
                            setIsLoadingBackDrop(false);
                            // console.error('error saat fetch', error);
                        });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                // console.error('error saat fetch', error);
            })
    }

    const CreateGL = (id, dataSelected) => {
        var data = {
            "generalLedger": {
                "userLogin": {
                    "countryId": dataSelected.countryId,
                    "companyId": dataSelected.companyId,
                    "branchId": dataSelected.branchId,
                    "roleId": 0,
                    "userId": 0,
                    "userCode": ""
                },
                "source": "INV",
                "refId": id,
                "methodPost": "AUTO",
                "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage',
                "postingFor": "APPDEL"
            }
        }

        ApiService.CreateFinAcc(paramGL, data)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code !== 200) {
                    showToast({ type: 'error', message: message });
                }
            });
    }

    function CellDoubleClick() {
        ViewData();
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setStateX({})
        setGoTo(1);
        setSortArr([])
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    const ButtonComponents = () => {
        return (
            <ButtonListInvoiceDelete
                ReloadData={ReloadData} DataRegUserAccess={DataRegUserAccess}
                Approve={ApproveDeleteData} View={ViewData} Decline={DeclineDeleteData}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={'Invoice Deletion Approval'}
                    isJobType={true}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                    jobTypeId={jobType}
                    handleSelect={handleChange}
                    dataJobType={dataJobType}
                />

                {/* <Grid item xs={12} alignItems='center'>
                    <Grid container item direction="row">
                        <Grid item xs={9}>
                            <div className='mt-1'>
                                <h5>Invoice Deletion Approval</h5>
                            </div>
                        </Grid>

                        <Grid item xs={3} className='text-end'>
                            <JobType jobTypeId={jobType} handleSelect={handleChange} dataJobType={dataJobType} />
                        </Grid>
                    </Grid>
                </Grid>

                <div className='mt-1' ref={btnPageRef}>
                    <ButtonListInvoiceDelete 
                        ReloadData={ReloadData} DataRegUserAccess={DataRegUserAccess}  
                        Approve = {ApproveDeleteData} View = {ViewData} Decline = {DeclineDeleteData}
                    />
                </div> */}

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(sortArr, rowsCount, columnData,
                                setGoTo, handleReset, setFilterBy,
                                filterBy, setFilter, setNumPage, setRowsCount,
                                getData, stateX, setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <div className='mt-1'>
                                    <TableX uniqueKey={'id'}
                                        rowsCount={rowsCount} setRowsCount={setRowsCount}
                                        setNumPage={setNumPage} getData={getData}
                                        columnData={columnData} dataMap={dataMap}
                                        SelectedData={SelectedData} setSelectedData={setSelectedData}
                                        setFilter={setFilter} setFilterBy={setFilterBy}
                                        CellDoubleClick={CellDoubleClick}
                                        stateX={stateX} setStateX={setStateX}
                                        sortArr={sortArr} setSortArr={setSortArr}
                                        goTo={goTo} setGoTo={setGoTo}
                                    />
                                </div>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            <ModalConfirmation
                isModal={isModalConfirmation} titleModal={titleModalConfirmation} title={titleConfirmation}
                handleClick={handleClickConfirmation} ConfirmationData={ConfirmationInvoice}

            />

            <ModalDelete
                isModal={isModalDelete} titleModal={titleModalDelete}
                handleClick={handleClickDelete} DeleteData={DeleteInvoice} typeDelete={typeDelete} setTypeDelete={setTypeDelete}
                isRemarks={true} DeleteRemarks={DeleteRemarks} setDeleteRemarks={setDeleteRemarks} isPrinting={isApprove}
                DataEditType={DataEditType}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingBackDrop}
            >
                <LoadingSpinner />
            </Backdrop>
        </Box>
    )
})

export default ListInvoiceDelete