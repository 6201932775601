import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreateEditReceiptPPh23 = ({ check, handleSave, DataRegUserAccess, isEdit, isApprovedAcc, Approve, UnApprove, AddNew }) => {
  const renderButton = (data) => {
    switch (data.accessCode) {
      case 'CRE':
        return isEdit && ButtonComponent.AddButton(AddNew, 'New', false, false, true);
      case 'APP':
        return isEdit && !isApprovedAcc ? ButtonComponent.ApproveButton(Approve, 'Approve', true) : null;
      case 'UPP':
        return isEdit && isApprovedAcc ? ButtonComponent.UnApproveButton(UnApprove, 'Un Approve', true) : null;
      default:
        return null;
    }
  };

  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: "12px" }}
        >
          {check !== 0 && !isApprovedAcc && ButtonComponent.SaveButton(handleSave, false, false, true)}

          {DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {renderButton(data)}
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonCreateEditReceiptPPh23;