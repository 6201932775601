import { Stack, IconButton, Grid, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataAccount from '../Selects/SelectDataAccount';
import { Icon, Input, PopUp, showToast } from '../../../../../components/ui';
import { convertToNumeric } from '../../../../../utils/masks.jsx';

const ModalGeneralInvoice = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramA = "account/account";
    const [isLoadingA, setIsLoadingA] = useState(false);
    const [dataA, setDataA] = useState([]);
    const [dataMapA, setDataMapA] = useState([]);
    const [columnDataA, setColumnDataA] = useState([]);
    const [SelectedDataA, setSelectedDataA] = useState({});
    const [totalRowsA, setTotalRowsA] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataAccount(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Account');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setAccountCode('');
        props.setAccountName('');
        props.setDescription('');
        props.setAmountUSD(0);
        props.setAmountIDR(0);
    };

    const handleSave = () => {
        if (!props.AccountCode) {
            showToast({ type: 'error', message: 'Please Account!' });
        }
        else {
            if (props.AmountUSD === 0 && props.AmountIDR === 0) {
                showToast({ type: 'error', message: 'Please Input Amount!' });
            }
            else {
                let giId = 0;
                let vatId = 0;
                let Sequence = 1;
                let vatPercent = 0;
                let amountVatUSD = 0;
                let amountVatIDR = 0;
                let amountUSD = 0;
                let amountIDR = 0;

                amountUSD = convertToNumeric(props.AmountUSD);
                amountIDR = convertToNumeric(props.AmountIDR);

                if (props.detailEdit) {
                    Sequence = props.selectedDetail.sequence;
                }
                else {
                    if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                }

                if (props.generalInvoiceId !== undefined || props.generalInvoiceId !== null) {
                    giId = props.generalInvoiceId;

                    if (giId === undefined) {
                        giId = 0;
                    }
                }
                else {
                    giId = 0;
                }

                if (props.VATId !== 0) {
                    vatId = props.VATId;
                    for (let i = 0; i < props.VATData.length; i++) {
                        if (props.VATId === props.VATData[i].id) {
                            vatPercent = props.VATData[i].value;
                            break;
                        }
                    }
                }

                amountVatUSD = parseFloat(amountUSD) * parseFloat(vatPercent) / parseFloat(100);
                amountVatIDR = parseFloat(amountIDR) * parseFloat(vatPercent) / parseFloat(100);

                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "userCode": userCode,

                    "giId": giId,
                    "sequence": Sequence,
                    "accountId": props.AccountId,
                    "accountCode": props.AccountCode,
                    "accountName": props.AccountName,
                    "description": props.Description,
                    "amountUSD": amountUSD,
                    "amountIDR": amountIDR,
                    "vatId": vatId,
                    "amountVatUSD": amountVatUSD,
                    "amountVatIDR": amountVatIDR,

                    "idLama": 0,
                };

                if (!props.detailEdit) {
                    let newArray = props.GeneralInvoiceList;
                    newArray = [...newArray, data];

                    props.setGeneralInvoiceList(newArray);

                    props.calculateTotal(newArray, props.RateCount);
                }
                else {
                    const newArr = props.GeneralInvoiceList.slice();

                    newArr.forEach(el => {
                        if (el.sequence === data.sequence) {
                            el.accountId = data.accountId;
                            el.accountCode = data.accountCode;
                            el.accountName = data.accountName;
                            el.description = data.description;
                            el.amountUSD = data.amountUSD;
                            el.amountIDR = data.amountIDR;
                            el.vatId = data.vatId;
                            el.amountVatUSD = data.amountVatUSD;
                            el.amountVatIDR = data.amountVatIDR;
                        }
                    })
                    props.setGeneralInvoiceList(newArr);

                    props.calculateTotal(newArr, props.RateCount);
                }

                emptyState();
                handleClose();
                props.setDetailEdit(false);
                props.setSelectedDetail({});
            }
        }
    }

    const getDataAccount = (pageNumber, pageSize, filters) => {
        setIsLoadingA(true);
        ApiService.DynamicPostByPage(paramA, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataA({})
                    setDataA(response.data.data.account);

                    let temp = response.data.data.account
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapA(indexed)
                    setColumnDataA(response.data.data.columns)
                    setTotalRowsA(response.data.totalRowCount)

                    setIsLoadingA(false)
                }
                setIsLoadingA(false)
            })
            .catch(function (error) {
                setIsLoadingA(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const mainContainer = (
        <>
            <Stack direction={'row'} spacing={'16px'}>
                <Input
                    label={'Account'}
                    value={props.AccountCode}
                    disabled
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '50%',
                        }
                    }}
                />

                <Input
                    value={props.AccountName}
                    disabled
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                    inputBaseProps={{
                        sx: {
                            marginTop: '23px !important',
                        },
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => searchAccount('delete')}
                                    hidden={props.printing > 0 ? true : props.AccountName ? false : true}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => searchAccount('search')}
                                    hidden={props.printing > 0 ? true : false}
                                >
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>

            <Input
                label={'Description'}
                value={props.Description}
                onChange={(e) => props.setDescription(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 5,
                    sx: {
                        padding: '8px',
                    },
                }}
                disabled={props.printing > 0 ? true : false}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Amount USD"
                        textAlign='right'
                        fullWidth
                        value={props.AmountUSD}
                        onChange={(e) => props.setAmountUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                        disabled={props.printing > 0 ? true : false}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Amount IDR"
                        textAlign='right'
                        fullWidth
                        value={props.AmountIDR}
                        onChange={(e) => props.setAmountIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                        disabled={props.printing > 0 ? true : false}
                    />
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'md'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.printing > 0 ? true : false)}
                    </div>
                </div>
            </PopUp>
            
            {
                isModal && (
                    <SelectDataAccount
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
        
                        getData={getDataAccount}
                        isLoading={isLoadingA}
                        totalRows={totalRowsA}
                        setTotalRows={setTotalRowsA}
                        SelectedData={SelectedDataA}
                        setSelectedData={setSelectedDataA}
                        data={dataA}
                        columnData={columnDataA}
                        dataMap={dataMapA}
        
                        setAccountId={props.setAccountId}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }
        </>
    )
}

export default ModalGeneralInvoice;