import React, { forwardRef, useEffect, useState } from 'react'
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, Backdrop, Card, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Input, showToast } from '../../../../components/ui';

const ExportEJKPView = forwardRef(() => {
  const history = useNavigate()

  const date = new Date()
  const dateString = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoading, setIsLoading] = useState(false);

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Export EJKP', link: '/Majura/ExportEJKP', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Export EJKP - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputNumberValidation = (name, e) => {
    const regex = /^[0-9\b]+$/
    const value = e.target.value

    if (value === '' || regex.test(value)) {
      formik.setFieldValue(name, value)
    }
  }

  const getDataAccess = () => {
    setIsLoading(true)
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'REXPEJKP')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            setIsLoading(false)
          }
        })
        .catch(function (error) {
          setIsLoading(false)
          console.error("error saat fetch", error);
          history('/Majura');
        });
    } else {
      setIsLoading(false)
      history('/Majura');
    }
  };

  const handlePrint = () => {
    setIsLoading(true);

    let variable = '';
    let params = [];

    if (formik.values.filter === '1') {
      params = [...params,
      {
        "attr": "FilterBy",
        "value": '' + formik.values.filter
      },
      {
        "attr": "StartDate",
        "value": '' + formik.values.startDate
      },
      {
        "attr": "EndDate",
        "value": '' + formik.values.endDate
      }
      ];
    }
    else {
      params = [...params,
      {
        "attr": "FilterBy",
        "value": '' + formik.values.filter
      },
      {
        "attr": "StartInvoice",
        "value": '' + formik.values.startInvoice
      },
      {
        "attr": "EndInvoice",
        "value": '' + formik.values.endInvoice
      }
      ];
    }
    ApiService.ReportPrint("Reports/Finance/Print/EFaktur/ExportEJKPReport/PrintExportEJKP", variable, params)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/vnd.ms-excel";
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoading(false);
          showToast({ type: 'success', message: 'Print Export EJKP Success' });
          window.open(fileURL);
        }
        else {
          setIsLoading(false);
          showToast({ type: 'error', message: 'Can\'t Print Export EJKP, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Export EJKP, ' + error });
      });
  }

  const setDefault = () => {
    formik.setFieldValue('startDate', dateString)
    formik.setFieldValue('endDate', dateString)
    formik.setFieldValue('startInvoice', 0)
    formik.setFieldValue('endInvoice', 0)
  }

  const formik = useFormik({
    initialValues: {
      filter: '1',
      startDate: dateString,
      endDate: dateString,
      startInvoice: 0,
      endInvoice: 0,
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {dataRegUserAccess.map((elm, index) => (
              <React.Fragment key={index}>
                {elm.accessCode === 'PRI' && ButtonComponent.ExcelButton(() => handlePrint(), 'Print CSV')}
              </React.Fragment>
            ))}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3 }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={'Export EJKP'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div style={{ marginTop: '24px' }}>
              <Grid
                container
                spacing={'32px'}
              >
                <Grid
                  item
                  xl={6}
                  lg={6}
                  sm={6}
                  xs={12}
                >
                  <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                    <CardContent>
                      <div fullWidth>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                          }}
                        >
                          Filter
                        </Typography>

                        <RadioGroup
                          sx={{
                            width: '100%',
                          }}
                          fullWidth
                          aria-labelledby="variant-option"
                          name="variant"
                          value={formik.values.filter}
                          onChange={(e) => {
                            formik.setFieldValue('filter', e.target.value)
                            setDefault()
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value='1'
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                By Print Date Invoice
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value='2'
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                By Invoice No. (Job)
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value='3'
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                By General Invoice No. (Non Job)
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </div>

                      {formik.values.filter === '1' && (
                        <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                          <Input
                            name="asPerDate"
                            label="From"
                            value={formik.values.startDate}
                            onChange={(e) => { formik.setFieldValue('startDate', e.target.value) }}
                            type={'date'}
                            formControlProps={{
                              sx: {
                                width: '100%',
                              }
                            }}
                          />

                          <Input
                            name="asPerDate"
                            label="To"
                            value={formik.values.endDate}
                            onChange={(e) => { formik.setFieldValue('endDate', e.target.value) }}
                            type={'date'}
                            formControlProps={{
                              sx: {
                                width: '100%',
                              }
                            }}
                          />
                        </Stack>
                      )}

                      {['2', '3'].includes(formik.values.filter) && (
                        <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                          <Input
                            name="asPerDate"
                            label="From"
                            value={formik.values.startInvoice}
                            onChange={(e) => inputNumberValidation('startInvoice', e)}
                            type={'number'}
                            formControlProps={{
                              sx: {
                                width: '100%',
                              }
                            }}
                          />

                          <Input
                            name="asPerDate"
                            label="To"
                            value={formik.values.endInvoice}
                            onChange={(e) => inputNumberValidation('endInvoice', e)}
                            type={'number'}
                            formControlProps={{
                              sx: {
                                width: '100%',
                              }
                            }}
                          />
                        </Stack>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  )
})

export default ExportEJKPView