import { Grid, IconButton, MenuItem, Stack, Typography, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataReference from '../Selects/SelectDataReference';
import SelectDataCOA from '../Selects/SelectDataCOA';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../../components/ui';

const ModalReceiptVoucherDetail = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const param1 = "temporaryPayment/temporaryPayment";
    const param2 = 'temporaryReceipt/temporaryReceipt';
    const [isLoadingT, setIsLoadingT] = useState(false);
    const [dataT, setDataT] = useState([]);
    const [dataMapT, setDataMapT] = useState([])
    const [columnDataT, setColumnDataT] = useState([])
    const [SelectedDataT, setSelectedDataT] = useState({});
    const [totalRowsT, setTotalRowsT] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataTemporary(1, 50);
        }
        else {
            setIsModal(false);
            emptyState();
        }
    };

    const searchTemporaryPayment = (data) => {
        if (data === 'search') {
            if (props.ReceiptBy === 'T') {
                setTitleModal('Temporary Payment');
            }
            else if (props.ReceiptBy === 'D') {
                setTitleModal('DP Invoice');
            }
            handleClick();
        }
        else if (data === 'delete') {
            props.setDescription('');
            props.setDCNote('C');
            props.setAmountUSD(0);
            props.setAmountIDR(0);
            props.setRefId(0);
            props.setRefNo('');
            props.setShipmentId(0);
            props.setShipmentNo('');
            props.setRefAccountCode('');
            props.setRefAccountName('');
            props.setExRateId(0);
            props.setRefRate(0);
        }
    };

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal2(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal2('Chart of Account (COA)');
            handleClick2();
        }
        else if (data === 'delete') {
            props.setRefAccountCode('');
            props.setRefAccountName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setDescription('');
        props.setDCNote('C');
        props.setAmountUSD(0);
        props.setAmountIDR(0);
        props.setRefId(0);
        props.setRefNo('');
        props.setShipmentId(0);
        props.setShipmentNo('');
        props.setRefAccountCode('');
        props.setRefAccountName('');
        props.setRefCustomerId(0);
        props.setRefCustomerName('');
        props.setExRateId(0);
        props.setRefRate(0);
        props.setPaymentMethod('');
    };

    const TransactionTypeData = [
        { id: 1, code: 'D', name: 'Debit' },
        { id: 2, code: 'C', name: 'Credit' },
    ];

    const handleSave = () => {
        if (props.amountUSD < 0 && props.amountIDR < 0) {
            showToast({ type: 'error', message: 'Please Input Amount!' });
        }
        else if (props.amountUSD > 0 && props.amountIDR > 0) {
            showToast({ type: 'error', message: 'Please Input Only 1 Type of Currency!' });
        }
        else {
            let Sequence = 1;
            let rvId = 0;

            if (props.detailEdit) {
                Sequence = props.selectedDetail.sequence;
            }
            else {
                if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
            }

            if (props.receiptVoucherId !== undefined || props.receiptVoucherId !== null) {
                rvId = props.receiptVoucherId;

                if (rvId === undefined) {
                    rvId = 0;
                }
            }
            else {
                rvId = 0;
            }

            let data = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                "sequence": Sequence,
                "id": 0,
                "rvId": rvId,
                "rvNo": props.RVNo,
                "description": props.description,
                "dcNote": 'C',
                "amountUSD": formatCurrency === 'USD' ? parseFloat(convertMask(props.amountUSD)) : parseFloat(convertMaskIDR(props.amountUSD)),
                "amountIDR": formatCurrency === 'USD' ? parseFloat(convertMask(props.amountIDR)) : parseFloat(convertMaskIDR(props.amountIDR)),
                "receiptCashUSD": 0,
                "receiptCashIDR": 0,
                "receiptBankIDR": 0,
                "receiptBankUSD": 0,
                "refId": props.refId,
                "refDetailId": props.refDetailId,
                "refNo": props.refNo,
                "coaCode": props.refAccountCode,
                "coaName": props.refAccountName,
                "shipmentOrderId": props.shipmentId,
                "shipmentNo": props.shipmentNo,
                "customerId": props.refCustomerId,
                "customerName": props.refCustomerName,
                "exRateId": props.exRateId,
                "refRate": props.refRate,
                "linkDetail": 0,
                "paidOn": "1785-12-06T03:32:32.013Z",
                "paymentMethod": props.paymentMethod,
                "saveOR": false,
                "seqDetail": 0,
                "tandaMigrasi": 0,
                "isStored": false,

                "idLama": 0,
                "receiptVoucherDetailBanks": [],
            }

            if (!props.detailEdit) {
                let arrayData = [...props.DataReceiptVoucher, data];
                props.setDataReceiptVoucher(arrayData);

                props.calculateTotalDebitCredit(arrayData, props.Rate);
            }
            else {
                const newArr = props.DataReceiptVoucher.slice();
                newArr.forEach(el => {
                    if (el.sequence === data.sequence) {
                        el.description = data.description;
                        el.dcNote = data.dcNote;
                        el.amountUSD = data.amountUSD;
                        el.amountIDR = data.amountIDR;
                        el.receiptCashUSD = data.receiptCashUSD;
                        el.receiptCashIDR = data.receiptCashIDR;
                        el.refId = data.refId;
                        el.refDetailId = data.refDetailId;
                        el.refNo = data.refNo;
                        el.coaCode = data.coaCode;
                        el.coaName = data.coaName;
                        el.shipmentId = data.shipmentId;
                        el.shipmentNo = data.shipmentNo;
                        el.paymentMethod = data.paymentMethod;
                        el.refCustomerId = data.refCustomerId;
                        el.refCustomerName = data.refCustomerName;
                        el.exRateId = data.exRateId;
                        el.refRate = data.refRate;
                    }
                })
                props.setDataReceiptVoucher(newArr);

                props.calculateTotalDebitCredit(newArr, props.Rate);
            }
            emptyState();
            handleClose();
            props.setDetailEdit(false);
            props.setSelectedDetail({});
        }
    }

    const getDataTemporary = (pageNumber, pageSize, filters) => {
        setIsLoadingT(true);
        let arrayData = [];
        if (props.ReceiptBy === 'T') {
            let params = [];
            params = [
                ...params,
                { 'attr': 'customerId', 'value': '' + props.ContactId },
                { 'attr': 'isAllRV', 'value': 'false' },
                { 'attr': 'isApprovedAcc', 'value': 'true' }
            ]
            ApiService.FinAccPostByPage(param1, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataT({})
                        arrayData = response.data.data.temporaryPayments;
                        setDataT(arrayData);

                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMapT(indexed)

                        setColumnDataT(response.data.data.columns.headerColumns)

                        setTotalRowsT(response.data.totalRowCount);

                        setIsLoadingT(false);
                        setIsModal(true);
                    }
                    setIsLoadingT(false)
                })
                .catch(function (error) {
                    setIsLoadingT(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.ReceiptBy === 'D') {
            let params = [];
            params = [
                ...params,
                { 'attr': 'contactId', 'value': '' + props.ContactId },
                { 'attr': 'isAllRV', 'value': 'false' },
                { 'attr': 'isDP', 'value': 'true' },
                { 'attr': 'isDPJob', 'value': 'true' },
                { 'attr': 'isApprovedAcc', 'value': 'true' }
            ]
            ApiService.FinAccPostByPage(param2, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataT({})
                        setDataT(response.data.data.temporaryReceipts);

                        let temp = response.data.data.temporaryReceipts
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMapT(indexed)

                        setColumnDataT(response.data.data.columns.headerColumns)
                        setTotalRowsT(response.data.totalRowCount)

                        setIsLoadingT(false)
                    }
                    setIsLoadingT(false)
                })
                .catch(function (error) {
                    setIsLoadingT(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const mainContainer = (
        <>
            <div hidden={props.ReceiptBy !== 'G' ? false : true}>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                    }}
                >
                    Settlement For
                </Typography>

                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label='Reference No'
                            value={props.refNo}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '12px !important'
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '3px !important',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchTemporaryPayment('delete')}
                                            disabled={props.isGeneral}
                                            hidden={props.detailEdit ? true : props.refNo ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchTemporaryPayment('search')}
                                            disabled={props.isGeneral} hidden={props.detailEdit}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label='Ref Shipment No'
                            value={props.shipmentNo}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '12px !important'
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <Input
                    label='Customer Name'
                    value={props.refCustomerName}
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '12px !important'
                        }
                    }}
                />
            </div>

            <div style={{ marginTop: props.SettlementTo !== 'G' ? '16px' : '12px' }}>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Stack direction={'row'} spacing={'16px'}>
                            <Input
                                label={'Account Code'}
                                value={props.refAccountCode}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '50%',
                                    }
                                }}
                            />

                            <Input
                                value={props.refAccountName}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => searchAccount('delete')}
                                                disabled={props.isNot || props.isApprovedAcc}
                                                hidden={props.isApprovedAcc ? true : props.refAccountName ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => searchAccount('search')}
                                                hidden={props.isApprovedAcc}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        <Selection
                            id='0'
                            label={'Transaction'}
                            fullWidth={true}
                            placeholder={'Select Transaction'}
                            value={props.dcNote}
                            onChange={(e) => props.setDCNote(e.target.value)}
                            disabled={props.isDisable}
                            formControlProps={{
                                sx: {
                                    marginTop: '16px !important'
                                }
                            }}
                        >
                            {
                                TransactionTypeData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label={'Description'}
                            value={props.description}
                            onChange={(e) => props.setDescription(e.target.value)}
                            disabled={props.isApprovedAcc}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                rows: 5,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div className='mt-4'>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                    }}
                >
                    Amount
                </Typography>

                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="USD"
                            textAlign='right'
                            fullWidth
                            value={props.amountUSD}
                            onChange={(e) => props.setAmountUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            disabled={!props.isGeneral || props.isApprovedAcc}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="IDR"
                            textAlign='right'
                            fullWidth
                            value={props.amountIDR}
                            onChange={(e) => props.setAmountIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            disabled={!props.isGeneral || props.isApprovedAcc}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.isApprovedAcc)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataReference
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataTemporary}
                        isLoading={isLoadingT}
                        totalRows={totalRowsT}
                        setTotalRows={setTotalRowsT}
                        SelectedData={SelectedDataT}
                        setSelectedData={setSelectedDataT}
                        data={dataT}
                        columnData={columnDataT}
                        dataMap={dataMapT}
                        refId={props.refId}
                        setRefId={props.setRefId}
                        setRefDetailId={props.setRefDetailId}
                        refNo={props.refNo}
                        setRefNo={props.setRefNo}
                        ReceiptBy={props.ReceiptBy}
                        setPaymentMethod={props.setPaymentMethod}
                        setShipmentId={props.setShipmentId}
                        setShipmentNo={props.setShipmentNo}
                        exRateId={props.exRateId}
                        setExRateId={props.setExRateId}
                        refRate={props.refRate}
                        setRefRate={props.setRefRate}
                        description={props.description}
                        setDescription={props.setDescription}
                        refAccountCode={props.refAccountCode}
                        setRefAccountCode={props.setRefAccountCode}
                        refAccountName={props.refAccountName}
                        setRefAccountName={props.setRefAccountName}
                        refCustomerId={props.refCustomerId}
                        setRefCustomerId={props.setRefCustomerId}
                        refCustomerName={props.refCustomerName}
                        setRefCustomerName={props.setRefCustomerName}
                        setAmountUSD={props.setAmountUSD}
                        setAmountIDR={props.setAmountIDR}
                        setDetailEdit={props.setDetailEdit}
                        setSelectedDetail={props.setSelectedDetail}
                        DataReceiptVoucher={props.DataReceiptVoucher}
                        setDataReceiptVoucher={props.setDataReceiptVoucher}
                        CustomerTo={props.CustomerTo}
                        Rate={props.Rate}
                        RVNo={props.RVNo}
                        ContactId={props.ContactId}
                        calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                        detailSequence={props.detailSequence}
                        emptyState={emptyState}
                        handleClose={handleClose}
                        DataReceiptVoucherId={props.DataReceiptVoucherId}
                        setDataReceiptVoucherId={props.setDataReceiptVoucherId}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataCOA
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        refAccountCode={props.refAccountCode}
                        setRefAccountCode={props.setRefAccountCode}
                        refAccountName={props.refAccountName}
                        setRefAccountName={props.setRefAccountName}
                    />
                )
            }
        </>
    )
}

export default ModalReceiptVoucherDetail;