import React from 'react';
import { Selection } from '../ui';
import { MenuItem } from '@mui/material';

const JobTypePicker = (props) => {

    return (
        <>
            <Selection
                id='0'
                label={'Job Type'}
                value={props.dataJobType.length > 0? props.jobTypeId : ''}
                minWidth={200}
                placeholder={'Select Job Type'}
                onChange={(e, c) => props.handleSelect(e, c)}
                formLabelProps={{
                    sx: {
                        fontSize: '12px !important',
                    }
                }}
                selectBaseProps={{
                    sx: {
                        marginTop: '3px !important',
                        border: '2px solid #0087C2',
                        backgroundColor: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(88.4deg, #0087C2 0%, #00D1FF 100%) !important',
                        borderRadius: '8px',
                        height: '32px',
                        fontSize: '12px !important',
                        boxShadow: '0px 4px 8px 0px #01C2FF40',
                        textAlign: 'left',
                        '&.Mui-focused': {
                            border: '2px solid #0049FF',
                            borderRadius: '8px',
                            boxShadow: '0px 6px 8px 0px #0000001A',
                        }
                    },
                }}
                selectInnerStyle={{
                    style: {
                        fontSize: '12px !important'
                    }
                }}
            >
                {
                    props.dataJobType.map((data) => (
                        <MenuItem id={data.id} key={data.code} value={data.id} sx={{'textAlign': 'center', fontSize: '12px !important'}}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        </>
    )
}

export default JobTypePicker