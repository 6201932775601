import { InputAdornment, IconButton, MenuItem, Grid } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService from '../../../../../services/ApiService';
import SelectDataMaster from '../../../../Master/MasterWarehouse/SelectDataMaster'
import { Icon, Input, Selection, showToast } from '../../../../../components/ui';

const AdjustmentHeader = (props) => {
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramSite = "warehouse/Sites/Sites";
    const paramWarehouse = "warehouse/RegSitesWarehouses/RegSitesWarehouses";
    const paramWarehouseLocation = "warehouse/warehouselocation/warehouselocation";

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(5);

    const [titleModalSite, setTitleModalSite] = useState('');
    const [isModalSite, setIsModalSite] = useState(false);
    const [isLoadingModalSite, setIsLoadingModalSite] = useState(false);
    const [dataModalSite, setDataModalSite] = useState([]);
    const [dataMapModalSite, setDataMapModalSite] = useState([]);
    const [columnDataModalSite, setColumnDataModalSite] = useState([]);
    const [SelectedDataModalSite, setSelectedDataModalSite] = useState({});
    const [totalRowsModalSite, setTotalRowsModalSite] = useState(5);

    const [titleModalWarehouse, setTitleModalWarehouse] = useState('');
    const [isModalWarehouse, setIsModalWarehouse] = useState(false);
    const [isLoadingModalWarehouse, setIsLoadingModalWarehouse] = useState(false);
    const [dataModalWarehouse, setDataModalWarehouse] = useState([]);
    const [dataMapModalWarehouse, setDataMapModalWarehouse] = useState([]);
    const [columnDataModalWarehouse, setColumnDataModalWarehouse] = useState([]);
    const [SelectedDataModalWarehouse, setSelectedDataModalWarehouse] = useState({});
    const [totalRowsModalWarehouse, setTotalRowsModalWarehouse] = useState(5);

    const [titleModalWarehouseLocation, setTitleModalWarehouseLocation] = useState('');
    const [isModalWarehouseLocation, setIsModalWarehouseLocation] = useState(false);
    const [isLoadingModalWarehouseLocation, setIsLoadingModalWarehouseLocation] = useState(false);
    const [dataModalWarehouseLocation, setDataModalWarehouseLocation] = useState([]);
    const [dataMapModalWarehouseLocation, setDataMapModalWarehouseLocation] = useState([]);
    const [columnDataModalWarehouseLocation, setColumnDataModalWarehouseLocation] = useState([]);
    const [SelectedDataModalWarehouseLocation, setSelectedDataModalWarehouseLocation] = useState({});
    const [totalRowsModalWarehouseLocation, setTotalRowsModalWarehouseLocation] = useState(5);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataProductCategory(1, 5);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClickSite = () => {
        if (isModalSite === false) {
            setIsModalSite(true);
            getDataSite(1, 5);
        }
        else {
            setIsModalSite(false);
        }
    };

    const handleClickWarehouse = () => {
        if (isModalWarehouse === false) {
            setIsModalWarehouse(true);
            getDataWarehouse(1, 5);
        }
        else {
            setIsModalWarehouse(false);
        }
    };

    const handleClickWarehouseLocation = () => {
        if (isModalWarehouseLocation === false) {
            setIsModalWarehouseLocation(true);
            getDataWarehouseLocation(1, 5);
        }
        else {
            setIsModalWarehouseLocation(false);
        }
    };

    const getDataProductCategory = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(paramProductCategory, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({})
                    setDataModal(response.data.data.productCategory);

                    let temp = response.data.data.productCategory
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModal(indexed)
                    setColumnDataModal(response.data.data.columns)
                    setTotalRowsModal(response.data.totalRowCount)

                    setIsLoadingModal(false)
                }
                setIsLoadingModal(false)
            })
            .catch(function (error) {
                setIsLoadingModal(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataSite = (pageNumber, pageSize, filters) => {
        setIsLoadingModalSite(true);
        ApiService.PostByPage(paramSite, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalSite({})
                    setDataModalSite(response.data.data.sites);

                    let temp = response.data.data.sites
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalSite(indexed)
                    setColumnDataModalSite(response.data.data.columns)
                    setTotalRowsModalSite(response.data.totalRowCount)

                    setIsLoadingModalSite(false)
                }
                setIsLoadingModalSite(false)
            })
            .catch(function (error) {
                setIsLoadingModalSite(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataWarehouse = (pageNumber, pageSize, filters) => {
        setIsLoadingModalWarehouse(true);
        filters = [{
            "field": "sitesId",
            "data": props.sitesId.toString()
        }]
        ApiService.PostByPage(paramWarehouse, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalWarehouse({})
                    setDataModalWarehouse(response.data.data.sitesWarehouses);
                    let temp = response.data.data.sitesWarehouses
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalWarehouse(indexed)
                    setColumnDataModalWarehouse(response.data.data.columns)
                    setTotalRowsModalWarehouse(response.data.totalRowCount)

                    setIsLoadingModalWarehouse(false)
                }
                setIsLoadingModalWarehouse(false)
            })
            .catch(function (error) {
                setIsLoadingModalWarehouse(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataWarehouseLocation = (pageNumber, pageSize, filters) => {
        setIsLoadingModalWarehouseLocation(true);
        filters = [{
            "field": "warehouseId",
            "data": props.warehousesId.toString()
        }]
        ApiService.PostByPage(paramWarehouseLocation, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalWarehouseLocation({})
                    setDataModalWarehouseLocation(response.data.data.warehouseLocation);

                    let temp = response.data.data.warehouseLocation
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalWarehouseLocation(indexed)
                    setColumnDataModalWarehouseLocation(response.data.data.columns)
                    setTotalRowsModalWarehouseLocation(response.data.totalRowCount)

                    setIsLoadingModalWarehouseLocation(false)
                }
                setIsLoadingModalWarehouseLocation(false)
            })
            .catch(function (error) {
                setIsLoadingModalWarehouseLocation(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const searchProductCategory = () => {
        setTitleModal("Product Category");
        handleClick();
    }

    const searchSite = () => {
        setTitleModalSite("Site");
        handleClickSite();
    }

    const searchWarehouse = () => {
        if (!props.sitesId) {
            showToast({ type: 'error', message: 'Select Site!' });
            return
        }
        setTitleModalWarehouse("Warehouse")
        handleClickWarehouse();
    }

    const searchWarehouseLocation = () => {
        if (!props.warehousesId) {
            showToast({ type: 'error', message: 'Select Warehouse!' });
            return
        }
        setTitleModalWarehouseLocation("Warehouse Location")
        handleClickWarehouseLocation();
    }

    const selectSite = (selectedData) => {
        props.setSitesId(selectedData.id);
        props.setSitesName(selectedData.name);
        props.setWarehousesId('');
        props.setWarehousesName('');
        props.setWarehouseLocationId('');
        props.setWarehouseLocationName('');
    }

    const selectWarehouse = (selectedData) => {
        props.setWarehousesId(selectedData.warehousesId);
        props.setWarehousesName(selectedData.warehousesName);
        props.setWarehouseLocationId('');
        props.setWarehouseLocationName('');
        ApiService.PostByPage(paramWarehouseLocation, 1, 100, [
            {
                "field": "isStage",
                "data": "true"
            },
            {
                "field": "warehouseId",
                "data": selectedData.warehousesId.toString()
            }
        ])
            .then((res) => {
                const retData = res.data.data.warehouseLocation;
                if (retData.length > 0) {
                    props.setWarehouseLocationId(retData[0].id);
                    props.setWarehouseLocationName(retData[0].name);
                }
            })
    }

    const selectWarehouseLocation = (selectedData) => {
        props.setWarehouseLocationId(selectedData.id);
        props.setWarehouseLocationName(selectedData.name);
        props.adjustmentDetailList.map((data) => {
            data.warehouseLocationId = selectedData.id;
            data.warehouseLocationName = selectedData.name;
            return null
        })
    }

    const selectProductCategory = (selectedData) => {
        props.setIsLoadingBackDrop(true);
        if (selectedData.id !== props.productCategoryId) {
            props.setAdjustmentDetailList([]);
        }
        props.setProductCategoryId(selectedData.id);
        props.setProductCategoryName(selectedData.name);
        props.setSitesId(selectedData.sitesId);
        props.setSitesName(selectedData.sitesName);
        props.setWarehousesId(selectedData.warehousesId);
        props.setWarehousesName(selectedData.warehousesName);
        ApiService.DynamicPostByPage(paramWarehouseLocation, 'PAGE', 1, 100, [
            {
                "attr": "warehouseId",
                "value": selectedData.warehousesId.toString(),
                "mathSign": "="
            },
            {
                "attr": "locationX",
                "value": selectedData.warehouseLocationX
            },
            {
                "attr": "locationY",
                "value": selectedData.warehouseLocationY
            },
            {
                "attr": "locationZ",
                "value": selectedData.warehouseLocationZ
            },
        ], [])
            .then((res) => {
                const retData = res.data.data.warehouseLocation;
                props.setWarehouseLocationId(retData[0].id);
                props.setWarehouseLocationName(retData[0].name);
                props.setIsLoadingBackDrop(false);
            })
    }

    return (
        <div>
            <form>
                <div>
                    <div>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Adjustment Date"
                                    type={'date'}
                                    disabled={props.approved}
                                    value={props.adjustmentDate}
                                    onChange={(e) => { props.setAdjustmentDate(e.target.value) }}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Adjustment No"
                                    value={props.adjustmentNo}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Selection
                                    id='0'
                                    label={'Adjustment Category'}
                                    fullWidth={true}
                                    placeholder={'Select Adjustment Category'}
                                    value={props.adjustmentCategoryId}
                                    onChange={
                                        (e) => {
                                            props.setAdjustmentCategoryId(e.target.value)
                                            props.setAdjustmentCategoryName(props.listAdjustmentCategory.find(item => item.id === e.target.value).name);
                                        }
                                    }
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.approved}
                                >
                                    {
                                        props.listAdjustmentCategory.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Input
                                    label="Product Category"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.productCategoryName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.productCategoryName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setSitesId('');
                                                                    props.setSitesName('');
                                                                    props.setWarehousesId('');
                                                                    props.setWarehousesName('');
                                                                    props.setAdjustmentDetailList([]);
                                                                    props.setProductCategoryId('');
                                                                    props.setWarehouseLocationId('');
                                                                    props.setProductCategoryName('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.approved} onClick={() => searchProductCategory()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.approved}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Site"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.sitesName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.sitesName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setSitesName('');
                                                                    props.setSitesId('')
                                                                    props.setWarehousesId('');
                                                                    props.setWarehousesName('');
                                                                    props.setWarehouseLocationId('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.approved} onClick={() => searchSite()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.approved}
                                />

                                <Input
                                    label="Warehouse"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.warehousesName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.warehousesName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setWarehousesId('');
                                                                    props.setWarehousesName('');
                                                                    props.setWarehouseLocationId('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.approved} onClick={() => searchWarehouse()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    disabled={props.approved}
                                />

                                <Input
                                    label="Warehouse Location"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    disabled={props.approved}
                                    value={props.warehouseLocationName}
                                    onChange={(e) => props.setWarehouseLocationName(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.warehouseLocationName && (
                                                        <IconButton hidden={props.approved}
                                                            onClick={
                                                                () => {
                                                                    props.setWarehouseLocationId('');
                                                                    props.setWarehouseLocationName('');
                                                                }
                                                            }
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={props.approved} onClick={() => searchWarehouseLocation()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataProductCategory}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}

                        selectHandler={selectProductCategory}
                    />
                )
            }

            {
                isModalSite && (
                    <SelectDataMaster
                        isModal={isModalSite}
                        handleClick={handleClickSite}
                        titleModal={titleModalSite}
                        setIsModal={setIsModalSite}

                        getData={getDataSite}
                        isLoading={isLoadingModalSite}
                        totalRows={totalRowsModalSite}
                        setTotalRows={setTotalRowsModalSite}
                        SelectedData={SelectedDataModalSite}
                        setSelectedData={setSelectedDataModalSite}
                        data={dataModalSite}
                        columnData={columnDataModalSite}
                        dataMap={dataMapModalSite}

                        selectHandler={selectSite}
                    />
                )
            }

            {
                isModalWarehouse && (
                    <SelectDataMaster
                        isModal={isModalWarehouse}
                        handleClick={handleClickWarehouse}
                        titleModal={titleModalWarehouse}
                        setIsModal={setIsModalWarehouse}

                        getData={getDataWarehouse}
                        isLoading={isLoadingModalWarehouse}
                        totalRows={totalRowsModalWarehouse}
                        setTotalRows={setTotalRowsModalWarehouse}
                        SelectedData={SelectedDataModalWarehouse}
                        setSelectedData={setSelectedDataModalWarehouse}
                        data={dataModalWarehouse}
                        columnData={columnDataModalWarehouse}
                        dataMap={dataMapModalWarehouse}

                        selectHandler={selectWarehouse}
                    />
                )
            }

            {
                isModalWarehouseLocation && (
                    <SelectDataMaster
                        isModal={isModalWarehouseLocation}
                        handleClick={handleClickWarehouseLocation}
                        titleModal={titleModalWarehouseLocation}
                        setIsModal={setIsModalWarehouseLocation}

                        getData={getDataWarehouseLocation}
                        isLoading={isLoadingModalWarehouseLocation}
                        totalRows={totalRowsModalWarehouseLocation}
                        setTotalRows={setTotalRowsModalWarehouseLocation}
                        SelectedData={SelectedDataModalWarehouseLocation}
                        setSelectedData={setSelectedDataModalWarehouseLocation}
                        data={dataModalWarehouseLocation}
                        columnData={columnDataModalWarehouseLocation}
                        dataMap={dataMapModalWarehouseLocation}

                        selectHandler={selectWarehouseLocation}
                    />
                )
            }
        </div>
    )
}

export default AdjustmentHeader;