import { Stack } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Input, showToast } from '../../../../../components/ui';
import { convertToNominal, convertToNumeric } from '../../../../../utils/masks.jsx';

const TabCash = (props) => {
    const countTotalPaymentIDR = () => {
        if (props.isDPJob === true) {
            if (props.selectedShipmentOrder.sequence !== undefined) {
                if (props.checkMethod(props.ShipmentOrderList) !== 1) {
                    let amountUSD = 0;
                    let amountIDR = 0;

                    amountUSD = convertToNumeric(props.CashPaymentUSD);
                    amountIDR = convertToNumeric(props.CashPaymentIDR);

                    if (amountUSD < 0) {
                        showToast({ type: 'error', message: 'Please Input Cash Payment USD!' });
                    }
                    else if (amountIDR < 0) {
                        showToast({ type: 'error', message: 'Please Input Cash Payment IDR!' });
                    }
                    else {
                        const newArray = props.ShipmentOrderList.slice();
                        newArray.forEach(el => {
                            if (el.shipmentOrderId === props.selectedShipmentOrder.shipmentOrderId) {
                                el.cashUSD = parseFloat(amountUSD);
                                el.cashIDR = parseFloat(amountIDR);
                            }
                        });
                        props.setShipmentOrderList(newArray);

                        props.calculateTotalCash(newArray);
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Please Input Payment Method on Bank Only!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Please Select Shipment First!' });
            }
        }
        else {
            if (props.CustomerId !== 0) {
                if (props.TotalBankUSDReal === 0 && props.TotalBankIDRReal === 0) {
                    props.setTotalCashUSDReal(parseFloat(props.CashPaymentUSD));
                    props.setTotalCashIDRReal(parseFloat(props.CashPaymentIDR));
                    props.setTotalCashUSD(convertToNominal(props.CashPaymentUSD));
                    props.setTotalCashIDR(convertToNominal(props.CashPaymentIDR));
                }
                else {
                    showToast({ type: 'error', message: 'Please Input Payment Method on Bank Only!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Please Select Customer First!' });
            }
        }
    };

    return (
        <>
            <div>
                <Stack spacing={'32px'} direction="row">
                    <Input
                        label='Cash Payment USD'
                        disabled={props.Printing > 0}
                        value={props.CashPaymentUSD}
                        onChange={(e) => props.setCashPaymentUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        fullWidth
                        textAlign='right'
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        label='Cash Payment IDR'
                        disabled={props.Printing > 0}
                        value={props.CashPaymentIDR}
                        onChange={(e) => props.setCashPaymentIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        fullWidth
                        textAlign='right'
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Stack>

                <div style={{ marginTop: '16px' }}>
                    {ButtonComponent.AddButton(countTotalPaymentIDR, '', props.Printing > 0)}
                </div>
            </div>
        </>
    )
}

export default TabCash;