import React, { forwardRef } from 'react';
import CustomizeButtonComponent from '../../components/Buttons/CustomizeButtonComponent';

import { Icon, PopUp } from '../../components/ui';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../components/Components/LoadingSpinner';

const CreateEditModalIdentity = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title = '', mainContainer, handleSave, width = 'sm', isLoading = false } = props

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={titleModal + ' ' + title}
                isOpen={isModal}
                onClose={handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={width}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelMasterModal(handleSave, false)}
                    </div>
                </div>
            </PopUp>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <LoadingSpinner />
            </Backdrop>
        </>
    )
})

export default CreateEditModalIdentity