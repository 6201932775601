import React from "react";
import { Grid } from "@mui/material";

import ButtonComponent from "../../../../../components/Buttons/ButtonComponent";

const ButtonCreateEditInBound = ({ check, Save, DataRegUserAccess, Id, Print, Approved, Approve, PutAway }) => {
  return (
    <div className="row" style={{ marginTop: '16px' }}>
      <div className="col-12">
        <Grid
          container
          item
          justifyContent="right"
          direction="row"
          style={{ maxWidth: "100vw", gap: "12px" }}
        >
          {check !== 0 && ButtonComponent.SaveButton(Save, false, false, true)}

          {DataRegUserAccess.map((data) => (
            <React.Fragment key={data.accessId}>
              {data.accessCode === 'PRI' && Id && ButtonComponent.PrintButton(Print, 'QR', true)}
              {data.accessCode === 'APP' && !Approved && Id && ButtonComponent.ApproveButton(Approve, "Approve", true)}
            </React.Fragment>
          ))}

          {Id && ButtonComponent.PutAwayButton(PutAway, true)}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonCreateEditInBound;