import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';
import { newWindowConfig } from '../../../../utils/masks';

const SelectDataBooking = (props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [isEPL, setIsEPL] = useState(false);

    const createSelection = [
        { 'id': 1, 'name': 'Yes', 'code': true },
        { 'id': 2, 'name': 'No', 'code': false }
    ]

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            window.open('/Majura/ShipmentOrder/Create/' + props.jobType + '/' + props.SelectedData.id + '/' + isEPL, newWindowConfig);
        }

        emptyState();

        props.setIsModal(false);
    }

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '19.6px',
                        }}
                    >
                        Create Auto EPL from Booking Confirmation
                    </Typography>

                    <RadioGroup
                        row
                        sx={{
                            marginTop: '2px',
                            gap: '12px'
                        }}
                        aria-labelledby="variant-option"
                        name="variant"
                        value={isEPL}
                        onChange={(e) => setIsEPL(!isEPL)}
                    >
                        {
                            createSelection.map((data) => (
                                <FormControlLabel
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '20px',
                                    }}
                                    value={data.code}
                                    key={data.id}
                                    control={<Radio size='small' />}
                                    label={
                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '12px !important',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: '20px',
                                            }}
                                        >
                                            {data.name}
                                        </Typography>
                                    }
                                />
                            ))
                        }
                    </RadioGroup>
                </div>

                <Typography
                    sx={{
                        color: 'var(--Primary-One, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '19.6px',
                        marginBottom: '-8px'
                    }}
                >
                    Booking Confirmation
                </Typography>

                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Create Shipment Order'}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div >
        </PopUp >
    )
}

export default SelectDataBooking;