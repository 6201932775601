import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'

import PropTypes from 'prop-types';

const Switcher = ({
  id,
  name,
  label,
  required = false,
  disabled = false,
  checked,
  onChange,

  baseLabelProps,
  baseSwitchProps,
}) => {
  return (
    <FormControlLabel
      id={id}
      name={name}
      required={required}
      disabled={disabled}
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          {...baseSwitchProps}
        />
      }
      label={label}
      sx={{
        ml: 0,
        '& .MuiFormControlLabel-label': {
          marginInlineStart: '8px'
        }
      }}
      {...baseLabelProps}
    />
  )
}

Switcher.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  baseLabelProps: PropTypes.shape({
    labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object
    ]),
  }),
  baseSwitchProps: PropTypes.shape({
    checkedIcon: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    icon: PropTypes.node,
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
      PropTypes.func,
      PropTypes.object
    ]),
  }),
};

export default Switcher