import { IconButton, Stack, Grid } from '@mui/material';
import React from 'react';
import { Icon, Input, showToast } from '../../../../components/ui';

const TabBillTo = (props) => {
    const searchBillTo = (data) => {
        if (data === 'search') {
            if (props.CustomerId !== 0) {
                props.setCustomerType(2);
                props.setTitleModal('Bill To');
                props.handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Customer First Then Bill To!' });
            }
        }
        else if (data === 'delete') {
            props.setBillToId(0);
            props.setBillToName('');
            props.setBillToAddress('');

            if (props.PaymentType === 'C') {
                props.getDataDetail(props.EPLId, props.CustomerId, false);
            }
            else {
                props.getDataDetail(props.EPLId, props.CustomerId, true);
            }
        }
    };

    return (
        <>
            <Grid
                container
                spacing={'16px'}
            >
                <Grid
                    item
                    xl={2}
                    lg={2}
                    sm={2}
                    xs={12}
                    sx={{ marginTop: '-12px !important' }}
                >
                    <Input
                        label={'Bill To Code'}
                        value={props.BillToId}
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '26px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={12}
                    sx={{ marginTop: '-12px !important' }}
                >
                    <Input
                        label={'Bill To Name'}
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.BillToName}
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '26px'
                            }
                        }}
                        disabled
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                    sx={{ marginTop: '-12px !important' }}
                >
                    <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                        <Input
                            label={'Bill To Address'}
                            value={props.BillToAddress}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '26px !important'
                                }
                            }}
                            inputBaseProps={{
                                rows: 5,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                            disabled
                        />

                        <Stack
                            direction={'column'}
                            spacing={'16px'}
                            sx={{ marginTop: '54px !important' }}
                            hidden={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                        >
                            <IconButton
                                onClick={() => searchBillTo('search')}
                                disabled={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '16px',
                                            width: '16px'
                                        }
                                    }}
                                />
                            </IconButton>

                            <IconButton
                                onClick={() => searchBillTo('delete')}
                                disabled={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                                hidden={props.BillToAddress ? false : true}
                            >
                                <Icon
                                    iconName={'close'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '16px',
                                            width: '16px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default TabBillTo;