import MaterialReactTable from 'material-react-table';
import React from 'react';

const TableBalance = (props) => {
    const columns = React.useMemo(
        () =>  [
            {
                accessorKey: 'accountCode',
                header: 'Account Code',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'accountTitle',
                header: 'Account Title',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'accountLevel',
                header: 'Account Level',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'period',
                header: 'Period',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'yearPeriod',
                header: 'Year',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'curtDBIDR',
                header: 'Debit',
                muiTableHeadCellProps: {
                    align: 'right',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ cell }) =>
                    cell.getValue().toLocaleString(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            },
            {
                accessorKey: 'curtCDIDR',
                header: 'Credit',
                muiTableHeadCellProps: {
                    align: 'right',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ cell }) =>
                    cell.getValue().toLocaleString(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
            },
            {
                accessorKey: 'accountGroup',
                header: 'Account Group',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'accountType',
                header: 'Account Type',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'gldcId',
                header: 'GLDC. ID',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'refId',
                header: 'Ref. ID',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'coaId',
                header: 'COAId',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'coaParentId',
                header: 'COAParentId',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={props.Data}
            enableExpanding
            enableExpandAll
            enableStickyHeader
            enableColumnFilters={false}
            // enablePagination={false}
            enableSorting={false}
            enableColumnActions={false}
            paginateExpandedRows={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            enableDensityToggle={false}
            initialState={{ density: 'compact' }}
            state={{ isLoading: props.isLoadingBackDrop }}
            muiTableContainerProps={{ sx: { maxHeight: props.maxHeight, boxShadow: '0px !important' } }}
            enableHiding={false}
        />
    )
}
export default TableBalance