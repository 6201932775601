import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import { Grid } from '@mui/material';

const ButtonCreatePaymentRequest = ({
    isViewOnly,
    documentStatus,
    check,
    DataRegUserAccess,
    isEdit,
    isContra,
    isPosted,
    isGeneral,
    Save,
    AddNew,
    PrintPR,
    ApproveAccManager,
    ApproveMKT,
    ApproveGeneralPR
}) => {

    const renderButtonByAccessCode = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return isEdit ? ButtonComponent.AddButton(AddNew, 'New', false, false, true) : null;
            case 'PRI':
                return isEdit && isContra !== 'true' ? ButtonComponent.PrintButton(PrintPR, 'PR', true) : null;
            case 'APP':
                return isEdit && isContra !== 'true' ? (
                    <>
                        {isPosted && ButtonComponent.ApproveButton(ApproveAccManager, 'Approve By Acc Mgr', true)}
                        {ButtonComponent.ApproveButton(ApproveMKT, 'Approve MKT', true)}
                    </>
                ) : null;
            case 'APG':
                return isEdit && !isPosted && isGeneral ?
                    ButtonComponent.ApproveButton(ApproveGeneralPR, 'Approve General PR', true)
                    : null;
            default:
                return null;
        }
    };

    return (
        <div className="row" style={{ marginTop: '16px' }}>
            <div className="col-12">
                <Grid
                    container
                    item
                    justifyContent="right"
                    direction="row"
                    style={{ maxWidth: "100vw", gap: "12px" }}
                >
                    <>
                        {check !== 0 && isViewOnly === false && documentStatus !== 'PREVIEW' && ButtonComponent.SaveButton(Save, false, false, true)}
                        {DataRegUserAccess.map((data) => (
                            <React.Fragment key={data.accessId}>
                                {renderButtonByAccessCode(data.accessCode)}
                            </React.Fragment>
                        ))}
                    </>
                </Grid>
            </div>
        </div>
    );
};

export default ButtonCreatePaymentRequest;