import React from 'react'
import ButtonComponent from '../../../components/Buttons/ButtonComponent';

import Grid from '@mui/material/Grid';
const ButtonInvoice = (props) => {
    return (
        <div className="row mt-3 mb-3">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{'maxWidth': '100vw'}}>
                    {ButtonComponent.BackButton(props.Back)}
                    {ButtonComponent.ReloadButton(props.ReloadData)}
                </Grid>
            </div>
        </div>
    )
}

export default ButtonInvoice