import { Grid, IconButton, MenuItem, Stack, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';

import { userCode } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import ModalCity from '../Selects/ModalCity';
import ApiService from '../../../../services/ApiService';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';

const ModalMultiAddress = (props) => {
    const [isModalCity, setIsModalCity] = useState(false);
    const [titleModalCity, setTitleModalCity] = useState('');
    const [isLoadingCity, setIsLoadingCity] = useState(false);
    const paramCity = "City/City";
    const [dataCity, setDataCity] = useState([]);
    const [dataMapCity, setDataMapCity] = useState([])
    const [columnDataCity, setColumnDataCity] = useState([])
    const [SelectedDataCity, setSelectedDataCity] = useState({});
    const [totalRowsCity, setTotalRowsCity] = useState(50);

    const AddressTypeData = [
        { id: 1, name: 'PUSAT' },
        { id: 2, name: 'CABANG' },
    ];

    const isMainAddressData = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ];

    const handleClickCity = () => {
        if (isModalCity === false) {
            setIsModalCity(true);
            getDataCity(1, 50);
        }
        else {
            setIsModalCity(false);
        }
    };

    const searchCity = (data) => {
        if (data === 'search') {
            setTitleModalCity('City');
            handleClickCity();
        }
        else if (data === 'delete') {
            props.setCityId(0);
            props.setCityCode('');
            props.setCityName('');
            props.setCountryId(0);
            props.setCountryName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setCityId(0);
        props.setCityCode('');
        props.setCityName('');
        props.setCountryId(0);
        props.setCountryName('');
        props.setContactAddress('');
        props.setIsMainAddress(false);
        props.setAddressType('PUSAT');
        props.setIsOversea(false);
        props.setPostalCode('');
    };

    const handleSave = () => {
        if (props.CityId === 0) {
            showToast({ type: 'error', message: 'Please Select City!' })
        }
        else {
            if (!props.ContactAddress) {
                showToast({ type: 'error', message: 'Please Input Contact Address!' })
            }
            else {
                if (props.CountryName === 'INDONESIA' && props.isOversea) {
                    showToast({ type: 'error', message: 'Please Change The Oversea or The City!' })
                }
                else {
                    let Sequence = 1;

                    if (props.detailEdit) {
                        Sequence = props.selectedDetail.sequence;
                    }
                    else {
                        if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                    }

                    let data = {
                        "userCode": userCode,

                        "rowStatus": "ACT",
                        "contactId": props.contactId,
                        "sequence": Sequence,
                        "cityId": props.CityId,
                        "cityCode": props.CityCode,
                        "cityName": props.CityName,
                        "address": props.ContactAddress,
                        "contactCountryId": props.CountryId,
                        "contactCountryName": props.CountryName,
                        "isDefault": props.isMainAddress,
                        "addressType": props.AddressType,
                        "isOversea": props.isOversea,
                        "postalCode": props.PostalCode,

                        "idLama": 0,
                    }

                    if (!props.detailEdit) {
                        props.setListAddress([...props.listAddress, data]);
                    }
                    else {
                        const newArr = props.listAddress.slice();
                        newArr.forEach(el => {
                            if (el.sequence === data.sequence) {
                                el.cityId = data.cityId;
                                el.cityCode = data.cityCode;
                                el.cityName = data.cityName;
                                el.address = data.address;
                                el.contactCountryId = data.contactCountryId;
                                el.contactCountryName = data.contactCountryName;
                                el.isDefault = data.isDefault;
                                el.addressType = data.addressType;
                                el.isOversea = data.isOversea;
                                el.postalCode = data.postalCode;
                            }
                        })
                        props.setListAddress(newArr);
                    }

                    emptyState();
                    handleClose();
                    props.setDetailEdit(false);
                    props.setSelectedDetail({});
                }
            }
        }
    }

    const getDataCity = (pageNumber, pageSize, filters) => {
        setIsLoadingCity(true);
        ApiService.DynamicPostByPage(paramCity, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataCity({})
                    setDataCity(response.data.data.city);

                    let temp = response.data.data.city
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapCity(indexed)
                    setColumnDataCity(response.data.data.columns)
                    setTotalRowsCity(response.data.totalRowCount)

                    setIsLoadingCity(false)
                }
                setIsLoadingCity(false)
            })
            .catch(function (error) {
                setIsLoadingCity(false)
                console.error('error saat fetch', error)
            })
    }

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Selection
                        label={'Address Type'}
                        value={props.AddressType}
                        placeholder={'Select Address Type'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '12px !important'
                            }
                        }}
                        onChange={(e) => props.setAddressType(e.target.value)}
                    >
                        {
                            AddressTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Selection
                        label={'Default Address'}
                        value={props.isMainAddress}
                        disabled={!props.isNotDefault}
                        placeholder={'Select Default Address'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '12px !important'
                            }
                        }}
                        onChange={(e) => props.setIsMainAddress(e.target.value)}
                    >
                        {
                            isMainAddressData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>
            </Grid>

            <Input
                label="Address"
                value={props.ContactAddress}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                onChange={(e) => props.setContactAddress(e.target.value)}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px !important' }}>
                        <Input
                            label='City Code'
                            value={props.CityCode}
                            formControlProps={{
                                sx: {
                                    // width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true
                            }}
                            disabled
                        />

                        <Input
                            label='City Name'
                            value={props.CityName}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            disabled
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchCity('delete')}
                                            hidden={props.CityName ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => searchCity('search')}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>

                    <Input
                        label="Postal Code"
                        value={props.PostalCode}
                        onChange={(e) => props.setPostalCode(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        disabled
                        label="Country"
                        value={props.CountryName}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Selection
                        label={'Oversea'}
                        value={props.isOversea}
                        placeholder={'Select Oversea'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px !important'
                            }
                        }}
                        onChange={(e) => props.setIsOversea(e.target.value)}
                        disabled
                    >
                        {
                            isMainAddressData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>

            {
                isModalCity && (
                    <ModalCity
                        isModal={isModalCity}
                        handleClick={handleClickCity}
                        titleModal={titleModalCity}
                        setIsModal={setIsModalCity}

                        isLoading={isLoadingCity}
                        data={dataCity}
                        dataMap={dataMapCity}
                        columnData={columnDataCity}
                        SelectedData={SelectedDataCity}
                        totalRows={totalRowsCity}
                        setSelectedData={setSelectedDataCity}
                        getData={getDataCity}

                        setCityId={props.setCityId}
                        setCityCode={props.setCityCode}
                        setCityName={props.setCityName}
                        setCountryId={props.setCountryId}
                        setCountryName={props.setCountryName}
                        setIsOversea={props.setIsOversea}
                    />
                )
            }
        </>
    )
}

export default ModalMultiAddress;