import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import { Icon, Input, PopUp } from '../../../../components/ui';
import { Grid, Backdrop } from '@mui/material';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import React from 'react';

const ContainerMask = React.forwardRef(({ maskOptions, ...inputProps }, ref) => {
    return (
        <MaskedInput
            {...inputProps}
            mask={[
                /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/,
                " ",
                /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,
                "-",
                /\d/
            ]}
            keepCharPositions
        />
    );
});

ContainerMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const ModalDeliveryOrder = (props) => {

    const handleClose = () => {
        props.emptyState();
        props.handleClick();
    };

    const handleSave = () => {
        props.SaveData();
    }

    const mainContainer = (
        <>
            {
                props.jobType.serviceType === 'Export' ?
                    <>
                        <Input
                            label="DO Form"
                            value={props.Company}
                            disabled
                            fullWidth
                            hidden={props.jobType.portType === 'SEA' && props.jobType.group === 'Forwarding' ? false : true}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Reference"
                                    value={props.Reference}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: props.jobType.portType === 'SEA' && props.jobType.group === 'Forwarding' ? '16px !important' : ''
                                        }
                                    }}
                                />

                                <Input
                                    label="To"
                                    value={props.ToDO}
                                    onChange={(e) => props.setToDO(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 3,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'16px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label="Intended Vessel"
                                            value={props.IntendVessel}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: props.jobType.portType === 'SEA' && props.jobType.group === 'Forwarding' ? '16px !important' : ''
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label="ETD"
                                            value={props.DOETD}
                                            type={'date'}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: props.jobType.portType === 'SEA' && props.jobType.group === 'Forwarding' ? '16px !important' : ''
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Input
                                    label="Transhipment"
                                    value={props.sealNo}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Port of Discharge"
                                    value={props.Discharge}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Place of Delivery"
                                    value={props.Delivery}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Attention"
                                    value={props.AttentionDO}
                                    onChange={(e) => props.setAttentionDO(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Release To"
                                    value={props.Shipper}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                {
                                    props.jobType.portType === 'SEA' && props.jobType.group === 'Forwarding' ?
                                        <>
                                            <Input
                                                label="Quantity"
                                                value={props.QuantityDO}
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />
                                        </>
                                        :
                                        <>
                                            <div style={{ marginTop: '16px' }} hidden={props.jobType.group === 'Forwarding' ? false : true}>
                                                {props.selectPrincipleBy(true)}
                                            </div>
                                        </>
                                }
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Commodity"
                                    value={props.CommodityDO}
                                    onChange={(e) => props.setCommodityDo(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Remarks"
                                    value={props.RemarksDO}
                                    onChange={(e) => props.setRemarksDO(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: props.jobType.portType === 'SEA' && props.jobType.group === 'Forwarding' ? 5 : 3,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Container No"
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputComponent: ContainerMask,
                                        value: props.ContainerDO,
                                        onChange: (e) => {
                                            let text = e.target.value
                                            if (text) { text = text.toUpperCase() }
                                            props.setContainerDO(text)
                                        }
                                    }}
                                />

                                <Input
                                    label="Seal No"
                                    value={props.SealDO}
                                    onChange={(e) => props.setSealDO(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Slot Operator"
                                    value={props.OperatorDO}
                                    onChange={(e) => props.setOperatorDO(e.target.value)}
                                    hidden={props.jobType.portType === 'SEA' && props.jobType.group === 'Forwarding' ? false : true}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Input
                        label="Gudang (GoDown)"
                        value={props.GudangName}
                        onChange={(e) => props.setGudangName(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
            }
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'printer'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={props.jobType.serviceType === 'Export' ? 'lg' : 'sm'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
            
            {
                props.isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props.isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
}

export default ModalDeliveryOrder;