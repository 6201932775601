import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon } from '@mui/material'

import { icons } from '../../../Assets/icons'

const Icon = ({ iconName, svgIconProps }) => {
  const { component, children, ...otherProps } = svgIconProps || {};

  return (
    <SvgIcon
      {...otherProps}
      component={icons?.[iconName] || component}
    >
      {children}
    </SvgIcon>
  )
}

Icon.propTypes = {
  iconName: PropTypes.string,
  svgIconProps: PropTypes.shape({
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    color: PropTypes.oneOfType([
      PropTypes.oneOf(['inherit', 'action', 'disabled', 'primary', 'secondary', 'error', 'info', 'success', 'warning']),
      PropTypes.string
    ]),
    component: PropTypes.elementType,
    fontSize: PropTypes.oneOfType([PropTypes.oneOf(['inherit', 'large', 'medium', 'small']), PropTypes.string]),
    htmlColor: PropTypes.string,
    inheritViewBox: PropTypes.bool,
    shapeRendering: PropTypes.string,
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.object,
        PropTypes.bool
      ])),
      PropTypes.func,
      PropTypes.object
    ]),
    titleAccess: PropTypes.string,
    viewBox: PropTypes.string
  }),
}

export default Icon