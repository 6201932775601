import React from 'react'
import { Grid, MenuItem } from '@mui/material'
import { Input, Selection } from '../../../../../components/ui'

const FilterSection = ({ formik }) => {
  const inputNumberValidation = (name, e) => {
    const regex = /^[0-9\b]+$/
    const value = e.target.value

    if (value === '' || regex.test(value)) {
      formik.setFieldValue(name, value)
    }
  }

  return (
    <Grid
      container
      columnSpacing={{ xs: 0, md: 3 }}
      rowSpacing={{ xs: 2, md: 0 }}
    >
      <Grid
        item
        lg={3}
        md={3}
        sm={12}
        xs={12}
      >
        <Selection
          id='0'
          label={'Filter'}
          fullWidth={true}
          placeholder={'Select Filter'}
          value={formik.values.filter}
          onChange={(e) => formik.setFieldValue('filter', e.target.value)}
        >
          <MenuItem sx={{ fontSize: '12px' }} value='period'>Period Print Date Invoice</MenuItem>
          <MenuItem sx={{ fontSize: '12px' }} value='invoice'>Invoice No. From</MenuItem>
          <MenuItem sx={{ fontSize: '12px' }} value='generalInvoice'>General Invoice No. From</MenuItem>
        </Selection>
      </Grid>

      <Grid
        item
        lg={3}
        md={3}
        sm={12}
        xs={12}
      >
        {
          formik.values.filter === 'period' ?
            (
              <>
                <Input
                  name="asPerDate"
                  label="Start Date"
                  value={formik.values.startDate}
                  onChange={(e) => { formik.setFieldValue('startDate', e.target.value) }}
                  type={'date'}
                  formControlProps={{
                    sx: {
                      width: '100%',
                    }
                  }}
                />
              </>
            )
            :
            (
              <>
                <Input
                  name="asPerDate"
                  label="From"
                  value={formik.values.startInvoice}
                  onChange={(e) => inputNumberValidation('startInvoice', e)}
                  type='text'
                  formControlProps={{
                    sx: {
                      width: '100%',
                    }
                  }}
                />
              </>
            )
        }
      </Grid>

      <Grid
        item
        lg={3}
        md={3}
        sm={12}
        xs={12}
      >
        {
          formik.values.filter === 'period' ?
            (
              <>
                <Input
                  name="asPerDate"
                  label="End Date"
                  value={formik.values.endDate}
                  onChange={(e) => { formik.setFieldValue('endDate', e.target.value) }}
                  type={'date'}
                  formControlProps={{
                    sx: {
                      width: '100%',
                    }
                  }}
                />
              </>
            )
            :
            (
              <>
                <Input
                  name="asPerDate"
                  label="To"
                  value={formik.values.endInvoice}
                  onChange={(e) => inputNumberValidation('endInvoice', e)}
                  type='text'
                  formControlProps={{
                    sx: {
                      width: '100%',
                    }
                  }}
                />
              </>
            )
        }
      </Grid>
    </Grid>
  )
}

export default FilterSection