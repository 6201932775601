import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService, { branchId, companyId, countryId, userCode, username } from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditJobOwnerContact from './CreateEditJobOwnerContact';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';

const ListJobOwnerContact = forwardRef((props, ref) => {
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const [data, setData] = useState([]);
  const [titleModal, setTitleModal] = useState('');
  const title = 'Job Owner Contact'
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataMap, setDataMap] = useState([])
  const [columnData, setColumnData] = useState([])
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50)
  const [rowsCount, setRowsCount] = useState(50)
  const [numPage, setNumPage] = useState(1)
  const [filter, setFilter] = useState([])
  const [filterBy, setFilterBy] = useState([])

  const param = "jobOwnerContact/jobOwnerContact";
  const [id, setId] = useState(0);
  const [jobOwnerId, setJobOwnerId] = useState(0);
  const [contactId, setContactId] = useState(0);

  const [contactName, setContactName] = useState('');
  const [dataJobOwner, setDataJobOwner] = useState([])
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([])

  const dataLocal = JSON.parse(localStorage.getItem(username + "-DatajobOwnerContact"));

  const simpanLocal = () => {
    if (isModal) {
      var data;
      data =
      {
        "jobOwnerContact": {
          "rowStatus": "ACT",
          "countryId": countryId,
          "companyId": companyId,
          "branchId": branchId,
          "id": id,
          "contactId": contactId,
          "jobOwnerId": jobOwnerId,
          "idLama": 0,
          "user": userCode,
          "titleModal": titleModal
        }
      }
      localStorage.setItem(username + "-DatajobOwnerContact", JSON.stringify(data));
    }
  }

  useImperativeHandle(ref, () => ({

    localSave() {
      simpanLocal()
    }

  }));

  const fetchDataLocal = () => {
    var result = dataLocal.jobOwnerContact;
    setIsModal(true);
    setJobOwnerId(result.jobOwnerId);
    setContactId(result.contactId);
    setId(result.id);
    getContactById(result.contactId)
    setTitleModal(result.titleModal);
    localStorage.removeItem(username + '-DatajobOwnerContact')
  }


  const toggleAdd = () => {
    getJobOwner()
    setId(0);
    setJobOwnerId(0);
    setContactId(0);
    handleClick();
    setTitleModal('Add');
  }

  const toggleEdit = () => {
    if (SelectedData.id === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      ApiService.PostById(param, SelectedData.id).then((res) => {
        getJobOwner()
        if (res !== null) {
          var result = res.data.data.jobOwnerContact;
          setJobOwnerId(result.jobOwnerId);
          setContactId(result.contactId);
        }
        setId(SelectedData.id);
        getContactById(SelectedData.contactId)
        setIsModal(true);
        setTitleModal('Edit');
      }).catch(function (error) {
        console.error('error saat fetch', error)
        showToast({ type: 'error', message: error.toString() })
      })
    }
  }

  const getContactById = (id) => {
    ApiService.PostById('contact/contact', id).then((res) => {
      if (res.data.code === 200) {
        var result = res.data.data.contact
        setContactName(result.contactName)
      }
    })
      .catch(function (error) {
        console.error('error saat fetch', error)
      })
  }

  let countRefresh = 0;

  useEffect(() => {
    if (countRefresh === 0) {
      ApiService.refreshToken();
      countRefresh++;
      if (dataLocal) {
        setTimeout(function () { fetchDataLocal(); }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageNumber, pageSize, filters, orderBy) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }
    ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})
          setData(response.data.data.jobOwnerContact);

          let temp = response.data.data.jobOwnerContact
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns)
          setTotalRows(response.data.totalRowCount)

          setIsLoading(false)
          setIsLoadingBackDrop(false)
        } else {
          setIsLoadingBackDrop(false)
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
        }
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        setIsLoadingBackDrop(false)
        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() })
      })
  }

  const getJobOwner = () => {
    ApiService.PostByPage('jobOwner/jobowner', 1, 100).then((res) => {
      if (res.data.code === 200) {
        var result = res.data.data.jobOwner
        setDataJobOwner(result)
      }
    })
      .catch(function (error) {
        console.error('error saat fetch', error)
      })
  }

  const handleClick = () => {
    if (isModal === false) {
      setIsModal(true);
    } else {
      setIsModal(false);
      setNumPage(1)
      setRowsCount(50)
    }
  };

  function CellDoubleClick(row) {
    toggleEdit()
  }

  return (
    <>
      <CustomToastContainer />
      <TempListMaster
        SelectedData={SelectedData}
        param={param}
        data={data}
        title={title}
        toggleAdd={toggleAdd}
        toggleEdit={toggleEdit}
        isLoading={isLoading}
        getData={getData}
        columnData={columnData}
        dataMap={dataMap}
        setSelectedData={setSelectedData}
        CellDoubleClick={CellDoubleClick}
        totalRows={totalRows}
        rowsCount={rowsCount}
        setRowsCount={setRowsCount}
        numPage={numPage}
        setNumPage={setNumPage}
        filter={filter}
        setFilter={setFilter}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        stateX={stateX}
        setStateX={setStateX}
        menuCode={'MJOWC'}
        menuName={'MASTER'}
        sortArr={sortArr}
        setSortArr={setSortArr}
      />

      {
        isModal && (
          <CreateEditJobOwnerContact
            isModal={isModal}
            handleClick={handleClick}
            titleModal={titleModal}
            title={title}
            param={param}
            getData={getData}

            id={id}
            jobOwnerId={jobOwnerId}
            setJobOwnerId={setJobOwnerId}
            contactId={contactId}
            setContactId={setContactId}
            contactName={contactName}
            setContactName={setContactName}
            dataJobOwner={dataJobOwner}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  )
})

export default ListJobOwnerContact