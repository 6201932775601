import { showToast } from "../components/ui";
import ApiService, { baseurlReport } from "../services/ApiService";
import { newWindowConfig } from "./masks";

const PrintPDF = (setIsLoading, module, reportLink) => {
    ApiService.IdentityRefreshPrintToken()
        .then((response) => {
            if (response.data.code === 200) {
                setIsLoading(false);
                showToast({ type: 'success', message: 'Print ' + module + ' Success' });
                window.open(baseurlReport + reportLink + '&tempToken=' + response.data.data.token, newWindowConfig);
            }
            else {
                setIsLoading(false);
                showToast({ type: 'error', message: 'Can\'t Print ' + module + ', ' + response.data.error });
            }
        })
        .catch(function (error) {
            console.error("error saat fetch", error);
            showToast({ type: 'error', message: 'Can\'t Print ' + module + ', ' + error.toString() });
        });
};

export {
    PrintPDF
}