import React from 'react'
import { MenuItem, RadioGroup, FormControlLabel, Radio, Stack, FormControl, Typography } from '@mui/material'
import { Input, Selection } from '../../../../components/ui'

const FilterSection = ({ filterData, changeFilter, jobTypeList, principleList }) => {
  return (
    <Stack spacing={'24px'} direction="row">
      <Selection
        id='0'
        label={'Job Type'}
        value={filterData?.jobType || ''}
        minWidth={263}
        placeholder={'Select Job Type'}
        onChange={(e) => changeFilter('jobType', e.target.value)}
      >
        {jobTypeList.map((jobType) => (
          <MenuItem sx={{ fontSize: '12px' }} key={jobType.value} value={jobType.value}>{jobType.label}</MenuItem>
        ))}
      </Selection>

      <Selection
        id='0'
        label={'Principle'}
        value={filterData?.principle || ''}
        minWidth={263}
        placeholder={'Select Principle'}
        onChange={(e) => changeFilter('principle', e.target.value)}
      >
        <MenuItem sx={{ fontSize: '12px' }} key={0} value={'all'}>All</MenuItem>
        {principleList.map((principle) => (
          <MenuItem sx={{ fontSize: '12px' }} key={principle.value} value={principle.value}>{principle.label}</MenuItem>
        ))}
      </Selection>

      <div>
        <FormControl>
          <Typography
            sx={{
              color: 'var(--Primary-One, #083A50)',
              fontFamily: 'Nunito',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '19.6px',
            }}
          >
            ETA/ETD Range
          </Typography>
          <RadioGroup
            row
            sx={{
              gap: '20px'
            }}
            aria-labelledby="variant-option"
            name="variant"
            value={filterData?.range}
            onChange={(e) => changeFilter('range', e.target.value)}
          >
            <FormControlLabel
              sx={{
                color: 'var(--Blue-Primary-1, #083A50)',
                fontFamily: 'Nunito',
                fontSize: '12px !important',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
              key={0}
              value='all'
              control={<Radio size='small' />}
              label={
                <Typography
                  sx={{
                    color: 'var(--Blue-Primary-1, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '12px !important',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '20px',
                  }}
                >
                  All
                </Typography>
              }
            />
            <FormControlLabel
              sx={{
                color: 'var(--Blue-Primary-1, #083A50)',
                fontFamily: 'Nunito',
                fontSize: '12px !important',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
              key={1}
              value='custom'
              control={<Radio size='small' />}
              label={
                <Typography
                  sx={{
                    color: 'var(--Blue-Primary-1, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '12px !important',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '20px',
                  }}
                >
                  Custom
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </div>

      <Input
        type={'date'}
        label={'Date From'}
        value={filterData?.start}
        onChange={(e) => changeFilter('start', e.target.value)}
        formControlProps={{
          sx: {
            width: '12.5%'
          }
        }}
        hidden={filterData?.range === 'custom' ? false : true}
      />

      <Input
        type={'date'}
        label={'Date To'}
        value={filterData?.end}
        onChange={(e) => changeFilter('end', e.target.value)}
        formControlProps={{
          sx: {
            width: '12.5%'
          }
        }}
        hidden={filterData?.range === 'custom' ? false : true}
      />
    </Stack>
  )
}

export default FilterSection