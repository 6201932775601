import React, { useState } from 'react'
import { Box, Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material'
import { Selection } from '../../../components/ui'

const options = [
  {
    value: 'option 1',
    label: 'Option 1'
  },
  {
    value: 'option 2',
    label: 'Option 2'
  }
]
const MasterSelect = () => {
  const [value, setValue] = useState()
  const [multipleValue, setMultipleValue] = useState([])
  const [isFullWidth, setIsFullWidth] = useState(false)
  const [isClearable, setIsClearable] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isError, setIsError] = useState(false)

  return (
    <Stack direction={'column'} gap={2}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={isFullWidth} onChange={() => setIsFullWidth(!isFullWidth)} />
          }
          label="Full Width"
        />
        <FormControlLabel
          control={
            <Checkbox checked={isClearable} onChange={() => setIsClearable(!isClearable)} />
          }
          label="Clearable"
        />
        <FormControlLabel
          control={
            <Checkbox checked={isDisabled} onChange={() => setIsDisabled(!isDisabled)} />
          }
          label="Disabled"
        />
        <FormControlLabel
          control={
            <Checkbox checked={isError} onChange={() => setIsError(!isError)} />
          }
          label="Error"
        />
      </FormGroup>

      <Box>
        <Stack direction={'row'} flexWrap={'wrap'} gap={2}>
          <Selection
            id={'demo'}
            label={'Default'}
            placeholder={'Placeholder'}
            value={value}
            fullWidth={isFullWidth}
            clearable={isClearable}
            disabled={isDisabled}
            error={isError}
            onChange={(e) => setValue(e.target.value)}
            options={options}
          />

          <Selection
            id={'demo-2'}
            label={'Multiple'}
            placeholder={'Placeholder'}
            value={multipleValue}
            fullWidth={isFullWidth}
            clearable={isClearable}
            disabled={isDisabled}
            error={isError}
            multiple
            onChange={(e) => setMultipleValue(e.target.value)}
            options={options}
          />
        </Stack>
      </Box>
    </Stack>
  )
}

export default MasterSelect