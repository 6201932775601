const scrollBarTheme = {
  scrollbarColor: "#d6dee1",
  "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
    backgroundColor: "#F5F5F5",
    width: 13,
    height: 13,
    borderRadius: 8,
  },
  "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
    borderRadius: 8,
    backgroundColor: "#d6dee1",
    minHeight: 8,
    border: "2px solid #FFF",
  },
  "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#959595",
  },
  "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
    backgroundColor: "#transparent",
  },
}

export default scrollBarTheme