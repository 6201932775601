import { Grid, Stack, Typography, InputAdornment, IconButton, MenuItem } from '@mui/material';
import { branchId, companyId, countryId, formatCurrency } from '../../../../services/ApiService';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';
import React from 'react';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { convertToNumeric } from '../../../../utils/masks.jsx';
import { defaultDate } from '../../../../utils/date.jsx';

const ModalHandlingFee = (props) => {
    document.onkeyup = escapeFunction;
    function escapeFunction(e) {
        var keyCode = e.keyCode;
        var valid = (keyCode === 27 || e.key === 'Escape');

        if (valid) {
            if (e.keyCode === 27 || e.key === 'Escape') {
                props.handleModalDetail();
                props.clearForm();
            }
        }
    }

    const signData = [
        { id: 1, code: true, name: '+' },
        { id: 2, code: false, name: '-' }
    ];

    const AdditionalData = [
        { id: 1, code: false, name: 'No' },
        { id: 2, code: true, name: 'Yes' },
    ];

    const saveData = () => {
        let amount_IDR = 0;
        let amount_USD = 0;

        let check = 0;

        if (parseFloat(convertToNumeric(props.Amount)) <= 0) {
            check = 1;
        }
        else if (parseInt(props.QtyUnit) <= 0) {
            check = 1;
        }
        else if (parseFloat(convertToNumeric(props.PerUnitCost)) <= 0) {
            check = 1;
        }
        else if (props.accountId !== 0) {
            check = 1;
        }

        if (check === 1) {
            if (props.Currency === 0) {
                amount_USD = parseFloat(convertToNumeric(props.Amount));
            }
            else {
                amount_IDR = parseFloat(convertToNumeric(props.Amount));
            }

            let CTI = props.contactType
            let II = props.isIncome

            const custId = props.contactId

            let Sequence = 1
            if (props.isAdd) {
                if (props.dataDetail.length > 0) {
                    Sequence = props.dataDetail.length + 1
                }
            }
            else {
                Sequence = props.sequence
            }

            const payload2 = {
                "rowStatus": "ACT",
                "id": 0,
                "eplId": props.eplId,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "sequence": Sequence,
                "isIncome": II,
                "customerId": custId,
                "customerTypeId": CTI,
                "customerName": props.contactName,
                "accountId": props.accountId,
                "accountCode": props.accountCode,
                "accountName": props.accountName,
                "coaCode": props.COACode,
                "coaName": props.COAName,
                "description": props.Desc,
                "codingQuantity": false,
                "type": '0',
                "quantity": parseInt(props.QtyUnit),
                "perQty": convertToNumeric(props.PerUnitCost),
                "amountCrr": parseInt(props.Currency),
                "amountUSD": parseFloat(amount_USD),
                "amountIDR": parseFloat(amount_IDR),
                "sign": props.sign,
                "dataFrom": false,
                "memoNo": 0,
                "statusMemo": false,
                "createdMemoOn": defaultDate,
                "paidPR": false,
                "isSplitIncCost": props.SplitAccount,
                "isContainer": props.isContainer,
                "containerSize": props.containerSize,
                "formatContainer": props.containerFormat,
                "containerTypeId": props.containerTypeId,
                "containerTypeCode": props.containerTypeCode,
                "containerTypeName": props.containerTypeName,
                "idLama": 0,
                "isCostToCost": props.CostToCost,
                "truck_detail_id": 0,
                "isCostTrucking": props.CostTrucking,
                "isAdditional": false,
                "isIgnoreItem": false,
                "kendaraanId": 0,
                "kendaraanNopol": "",
                "employeeId": 0,
                "employeeName": "",
                "mrgId": 0,
                "refId": 0,
                "deliveryDate": defaultDate,
                "originalUsd": convertToNumeric(props.OriginalUSD),
                "originalRate": convertToNumeric(props.OriginalRate),
                "bookingDetailId": 0,
                "user": "",
                "estimateProfitLossDetailStorages": []
            }

            if (props.isAdd) {
                if (II === true && CTI === 2) {
                    props.setIncShipperList([...props.IncShipperList, payload2])
                } else if (II === true && CTI === 1) {
                    props.setIncAgentList([...props.IncAgentList, payload2])
                } else if (II === true && CTI === 3) {
                    props.setIncConsigneeList([...props.IncConsigneeList, payload2])
                } else if (II === false && CTI === 4) {
                    props.setCostSSLineList([...props.CostSSLineList, payload2])
                } else if (II === false && CTI === 5) {
                    props.setCostIATAList([...props.CostIATAList, payload2])
                } else if (II === false && CTI === 6) {
                    props.setCostEMKLList([...props.CostEMKLList, payload2])
                } else if (II === false && CTI === 7) {
                    props.setCostDepoList([...props.CostDepoList, payload2])
                } else if (II === false && CTI === 2) {
                    props.setCostDiscountShipperList([...props.CostDiscountShipperList, payload2])
                    console.log(props.CostDiscountShipperList)
                } else if (II === false && CTI === 3) {
                    props.setCostDiscountConsigneeList([...props.CostDiscountConsigneeList, payload2])
                } else if (II === false && CTI === 1) {
                    props.setCostAgentList([...props.CostAgentList, payload2])
                } else {
                    props.setCostTruckingList([...props.CostTruckingList, payload2])
                }

                props.setDataDetail([...props.dataDetail, payload2])
                props.countSum([...props.dataDetail, payload2])
                props.countTotalData([...props.dataDetail, payload2])
                props.clearForm();
            }
            else {
                const newArr = props.dataDetail.slice()
                newArr.forEach(el => {
                    if (el.sequence === Sequence) {
                        el.eplId = payload2.accountId
                        el.isIncome = payload2.isIncome
                        el.customerId = payload2.customerId
                        el.customerName = payload2.customerName
                        el.customerTypeId = payload2.customerTypeId
                        el.accountId = payload2.accountId
                        el.accountCode = payload2.accountCode
                        el.accountName = payload2.accountName
                        el.coaCode = payload2.coaCode
                        el.coaName = payload2.coaName
                        el.description = payload2.description
                        el.formatContainer = payload2.formatContainer
                        el.containerTypeId = payload2.containerTypeId
                        el.containerTypeCode = payload2.containerTypeCode
                        el.containerTypeName = payload2.containerTypeName
                        el.quantity = payload2.quantity
                        el.perQty = payload2.perQty
                        el.amountUSD = payload2.amountUSD
                        el.amountIDR = payload2.amountIDR
                        el.isSplitIncCost = payload2.isSplitIncCost
                        el.isCostToCost = payload2.isCostToCost
                        el.isCostTrucking = payload2.isCostTrucking
                        el.originalUsd = payload2.originalUsd
                        el.originalRate = payload2.originalRate
                        el.amountCrr = payload2.amountCrr
                        el.sign = payload2.sign
                    }
                })
                props.setDataDetail(newArr)
                props.countSum(newArr)
                props.countTotalData(newArr)
                let data2 = newArr.filter(el => el.customerTypeId === CTI && el.isIncome === II)
                if (II === true && CTI === 2) {
                    props.setIncShipperList(data2)
                } else if (II === true && CTI === 3) {
                    props.setIncConsigneeList(data2)
                } else if (II === true && CTI === 1) {
                    props.setIncAgentList(data2)
                } else if (II === false && CTI === 4) {
                    props.setCostSSLineList(data2)
                } else if (II === false && CTI === 5) {
                    props.setCostIATAList(data2)
                } else if (II === false && CTI === 6) {
                    props.setCostEMKLList(data2)
                } else if (II === false && CTI === 7) {
                    props.setCostDepoList(data2)
                } else if (II === false && (CTI === 2)) {
                    props.setCostDiscountShipperList(data2)
                } else if (II === false && (CTI === 3)) {
                    props.setCostDiscountConsigneeList(data2)
                } else if (II === false && CTI === 1) {
                    props.setCostAgentList(data2)
                } else {
                    props.setCostTruckingList(data2)
                }

                props.clearForm();
            }

            if (props.isDummy === true) {
                showToast({ type: 'warning', message: 'There Still Dummy Container On Shipment Order! Please Update The Container!' });
            }

            props.handleModalDetail();
        }
        else {
            showToast({ type: 'warning', message: 'Please Input Important Data!' });
        }
    }

    const mainContainer = (
        <div>
            <Stack spacing={'32px'} direction="row">
                <Input
                    label={'Agent Id'}
                    value={props.contactId}
                    disabled
                    inputBaseProps={{
                        sx: {
                            marginTop: '3px !important',
                            display: 'flex',
                            height: '41px',
                            padding: '0px 12px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            'borderRadius': '8px',
                            border: '1.5px solid var(--Grey-Outline-2, #989898)',
                            background: '#F2F2F2',
                        },
                    }}
                    formLabelProps={{
                        sx: {
                            fontFamily: 'Nunito',
                            fontSize: '16px',
                            fontWeight: '700',
                            color: '#083A50 !important',
                            textAlign: 'left'
                        }
                    }}
                />

                <Input
                    label="Agent Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={props.contactName}
                    type='text'
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                    inputBaseProps={{
                        sx: {
                            marginTop: '3px !important',
                            display: 'flex',
                            height: '41px',
                            padding: '0px 12px',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            'borderRadius': '8px',
                            border: '1.5px solid var(--Grey-Outline-2, #989898)',
                            background: '#F2F2F2',
                        },
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => props.handleModalContact('delete')}
                                    hidden={props.contactId ? false : true}
                                    disabled={props.isViewOnly}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => { props.handleModalContact('search') }}
                                    disabled={props.isViewOnly}
                                >
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    formLabelProps={{
                        sx: {
                            fontFamily: 'Nunito',
                            fontSize: '16px',
                            fontWeight: '700',
                            color: '#083A50 !important',
                            textAlign: 'left'
                        }
                    }}
                    disabled
                />
            </Stack>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={6}
                >
                    <Selection
                        id='0'
                        label={'Sign'}
                        value={props.sign}
                        fullWidth={true}
                        disabled={props.isViewOnly}
                        placeholder={'Select Sign'}
                        onChange={(e) => props.setSign(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    >
                        {
                            signData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={6}
                >
                    <Selection
                        id='0'
                        label={'Additional'}
                        value={true}
                        fullWidth={true}
                        disabled
                        placeholder={'Select Additional'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    >
                        {
                            AdditionalData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>
            </Grid>

            <div component="div" style={{ marginTop: '24px', 'borderRadius': '12px', }}>
                <Typography
                    sx={{
                        color: 'var(--Primary-One, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '19.6px',
                    }}
                >
                    Handling Fee
                </Typography>

                <div style={{ marginTop: '8px' }}>
                    <Grid
                        container
                    >
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={4}
                        >
                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>(&nbsp;</p>
                                <Input
                                    type='number'
                                    textAlign='right'
                                    value={props.QtyUnit}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                />
                                <p>&nbsp;x&nbsp;20'&nbsp;)</p>
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>(&nbsp;</p>
                                <Input
                                    type='number'
                                    textAlign='right'
                                    value={props.QtyUnit}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                />
                                <p>&nbsp;x&nbsp;40'&nbsp;)</p>
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>(&nbsp;</p>
                                <Input
                                    type='number'
                                    textAlign='right'
                                    value={props.QtyUnit}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                />
                                <p>&nbsp;x&nbsp;45'&nbsp;)</p>
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>(&nbsp;</p>
                                <Input
                                    type='number'
                                    textAlign='right'
                                    value={props.QtyUnit}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                />
                                <p>&nbsp;x&nbsp;M3&nbsp;)</p>
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={4}
                        >
                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;x&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;x&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;x&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;x&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={4}
                        >
                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;=&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;=&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;=&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>

                            <Stack direction={'row'} style={{ alignItems: 'baseline' }}>
                                <p>&nbsp;&nbsp;=&nbsp;&nbsp;USD&nbsp;</p>
                                <Input
                                    type='text'
                                    textAlign='right'
                                    value={props.Amount}
                                    disabled={props.isViewOnly}
                                    formControlProps={{ sx: { width: '100%' } }}
                                    onChange={(e) => props.setAmount(e.target.value)}
                                    inputBaseProps={{ inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput, }}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )

    const handleCancel = () => {
        props.handleModalDetail()
    }

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'warehouse'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.TabType + ' - Handling Fee'}
                isOpen={props.showDetail}
                onClose={handleCancel}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(saveData, false, (props.isViewOnly || props.isReadOnly === true ? true : false))}
                    </div>
                </div>
            </PopUp>
        </>
    );
}

export default React.memo(ModalHandlingFee)