import React from 'react'
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import Grid from '@mui/material/Grid';
const ButtonRegAccess = (props) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.ReloadButton(props.ReloadData)}
                    {
                        props.DataRegUserAccess.map((data) => {
                            return (
                                <React.Fragment key={data.accessId}>
                                    {
                                        data.accessCode === 'REI' ?
                                            <>
                                                {ButtonComponent.EditButton(props.Add, 'Access')}
                                            </>
                                            :
                                            <></>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </Grid>
            </div>
        </div>
    )
}

export default ButtonRegAccess