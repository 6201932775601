import { Box } from '@mui/system';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ModalInvoices from '../Modals/ModalInvoices';
import { tableCellClasses } from '@mui/material/TableCell';
import { convertToNominal } from '../../../../utils/masks.jsx';
import { Input, showToast,
    // Selection
  } from '../../../../components/ui';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent.jsx';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Stack, TableFooter, Typography, Tooltip,
    // MenuItem
 } from '@mui/material';

const InvoiceBeingForPayment = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.footer}`]: {
            background: '#E4E8EC',
            color: '#083A50 !important',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
            left: 0,
            bottom: 0,
            zIndex: 2,
            position: 'sticky',
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);
    const [selectedDetail, setSelectedDetail] = useState({});
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };

    const [AccountId, setAccountId] = useState(0);
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [COACode, setCOACode] = useState('');
    const [COAName, setCOAName] = useState('');
    const [isContainer, setIsContainer] = useState(false);
    const [Description, setDescription] = useState('');
    const [ContainerType, setContainerType] = useState('All');
    const [Quantity, setQuantity] = useState('');
    const [UnitCost, setUnitCost] = useState('');
    const [Sign, setSign] = useState(true);
    const [AmountCrr, setAmountCrr] = useState(1);
    const [Amount, setAmount] = useState('');
    const [VatId, setVatId] = useState(0);
    const [CostToCost, setCostToCost] = useState(false);
    const [OriginalUSD, setOriginalUSD] = useState('');
    const [OriginalRate, setOriginalRate] = useState('');
    const [ContainerFormat, setContainerFormat] = useState('All');
    const [containerTypeId, setContainerTypeId] = useState(0);
    const [containerTypeCode, setContainerTypeCode] = useState('');
    const [containerTypeName, setContainerTypeName] = useState('');
    const [isOthers, setIsOthers] = useState(false);
    const [eplDetailId, setEplDetailId] = useState(0);

    // const currencyData = [
    //     { id: 1, name: 'All' },
    //     { id: 2, name: 'USD' },
    //     { id: 3, name: 'IDR' },
    // ];

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddPaymentFor = () => {
        if (!props.isViewOnly) {
            if (props.CustomerId !== 0) {
                setDetailEdit(false);
                setTitleModal('Invoice To ' + props.Customer);
                if (props.TempDataPayment.length > 0) {
                    let data = props.TempDataPayment.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                    setDetailSequence(data.sequence);
                }
                else {
                    setDetailSequence(0);
                }
                setCostToCost(props.Type);
                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Customer First!' });
            }
        }
        else {
            showToast({ type: 'error', message: 'Data Has Been Printed!' });
        }
    };

    const ShowEditPaymentFor = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setDetailEdit(true);
                setTitleModal('Invoice To ' + props.Customer);

                setAccountId(selectedDetail.accountId);
                setAccountCode(selectedDetail.accountCode);
                setAccountName(selectedDetail.accountName);
                setCOACode(selectedDetail.coaCode);
                setCOAName(selectedDetail.coaName);
                setEplDetailId(selectedDetail.eplDetailId);
                if (selectedDetail.accountName === 'OTHER') {
                    setIsOthers(true);
                }
                else {
                    setIsOthers(false);
                }
                setDescription(selectedDetail.description);
                setContainerType(selectedDetail.containerSize);
                setContainerFormat(selectedDetail.formatContainer);
                setContainerTypeId(selectedDetail.containerTypeId);
                setContainerTypeCode(selectedDetail.containerTypeCode);
                setContainerTypeName(selectedDetail.containerTypeName);
                setIsContainer(selectedDetail.isContainer);
                setQuantity(selectedDetail.quantity);
                setUnitCost(selectedDetail.perQty);
                setSign(selectedDetail.sign);
                setAmountCrr(selectedDetail.amountCrr);
                setAmount(selectedDetail.amount);
                setVatId(selectedDetail.vatId);
                setCostToCost(selectedDetail.isCostToCost);
                setOriginalUSD(selectedDetail.originalUsd);
                setOriginalRate(selectedDetail.originalRate);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    };

    const DeletePaymentFor = () => {
        if (!props.isViewOnly) {
            if (!selectedDetail.sequence) {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
            else {
                let tempSequence = selectedDetail.sequence;

                const deleteFunction = (data) => {
                    return data.sequence !== tempSequence;
                }

                const result = props.DataPayment.filter(deleteFunction);

                if (result.length > 0) {
                    tempSequence = 0;

                    result.forEach((el) => {
                        tempSequence += 1;
                        el.sequence = tempSequence;
                    })

                    setDetailSequence(tempSequence);
                }
                else {
                    setDetailSequence(0);
                }

                props.setDataPayment(result);
                props.setTempDataPayment(result);
                props.calculateTotal(result);

                if (props.Customer !== 'Agent') {
                    if (props.PaymentFrom !== 1) {
                        props.setDataPaymentSC(result);
                    }
                }
                else {
                    if (props.PaymentFrom !== 1) {
                        props.setDataPaymentA(result);
                    }
                }

                setSelectedDetail({});
            }
        }
        else {
            showToast({ type: 'error', message: 'Data Has Been Printed!' });
        }
    }

    const AddHF = () => {

    }

    const AddPS = () => {

    }

    const AddStorage = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
    }

    const AddDemurrage = () => {

    }

    function handleSelectedDetail(val) {
        setSelectedDetail(val);
    }

    function handleVATValue(val) {
        props.setVatValue(val);
        let vatPercent = 0.00;

        for (let i = 0; i < props.VATData.length; i++) {
            if (val === props.VATData[i].id) {
                vatPercent = props.VATData[i].value;
                break;
            }
        }

        let arrayData1 = [];
        let arrayData2 = [];
        let arrayData3 = [];
        arrayData1 = props.DataPaymentSC;
        arrayData2 = props.DataPaymentA;
        arrayData3 = props.DataPayment;

        setVatId(val);
        props.setVatId(val);
        props.setPercentVat(vatPercent);

        arrayData1.forEach(element => {
            element.vatId = val;
            element.percentVat = vatPercent;
            element.amountVat = parseInt(element.amount) * parseFloat(vatPercent) / parseInt(100);
        });

        arrayData2.forEach(element => {
            element.vatId = val;
            element.percentVat = vatPercent;
            element.amountVat = parseInt(element.amount) * parseFloat(vatPercent) / parseInt(100);
        });

        arrayData3.forEach(element => {
            element.vatId = val;
            element.percentVat = vatPercent;
            element.amountVat = parseInt(element.amount) * parseFloat(vatPercent) / parseInt(100);
        });

        if (props.PaymentFrom === 'Invoices') {
            if (props.Customer !== 'Agent') {
                props.setDataPayment(arrayData1);
                props.setTempDataPayment(arrayData1);
                props.calculateTotal(arrayData1);
            }
            else {
                props.setDataPayment(arrayData2);
                props.setTempDataPayment(arrayData2);
                props.calculateTotal(arrayData2);
            }
        }
        else {
            props.setDataPayment(arrayData3);
            props.setTempDataPayment(arrayData3);
            props.calculateTotal(arrayData3);
        }

        props.setDataPaymentSC(arrayData1);
        props.setDataPaymentA(arrayData2);
    }

    // function handleFilterCurrency(val) {
    //     props.setCurrency(val);
    //     if (val === 'All') {
    //         props.setDataPayment(props.TempDataPayment);
    //         props.calculateTotal(props.TempDataPayment);
    //     }
    //     else if (val === 'USD') {
    //         let array = props.TempDataPayment;

    //         const filterFunction = (data) => {
    //             return data.amountCrr === 0;
    //         }

    //         const result = array.filter(filterFunction);
    //         props.setDataPayment(result);
    //         props.calculateTotal(result);
    //     }
    //     else if (val === 'IDR') {
    //         let array = props.TempDataPayment;

    //         const filterFunction = (data) => {
    //             return data.amountCrr === 1;
    //         }

    //         const result = array.filter(filterFunction);
    //         props.setDataPayment(result);
    //         props.calculateTotal(result);
    //     }
    // }

    return (
        <div>
            <form>
                <Box>
                    <Grid container item spacing={'32px'} direction="row">
                        <Grid item xs={10}>
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    marginTop: '16px'
                                }}
                            >
                                Being for Payment
                            </Typography>
                        </Grid>

                        <Grid item xs={2} sx={{ marginTop: '-16px' }}>
                            {props.selectVATData(props.VatValue, handleVATValue, 'All - VAT ', props.isViewOnly ? true : props.Type, 'Set All VAT Percentage')}
                        </Grid>
                    </Grid>

                    <Box sx={{ margin: '24px 0px 16px' }}>
                        <TableContainer sx={{ maxHeight: 350, borderRadius: '12px', background: '#FFF' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Code</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Description</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>VAT</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Sign</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Cost To Cost</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount VAT USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount IDR</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount VAT IDR</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.isCircleProgress ?
                                            <LoadingSpinner />
                                            :
                                            <>
                                                {
                                                    props.DataPayment.length > 0 ?
                                                        props.DataPayment.map((el) => {
                                                            return (
                                                                <TableRow
                                                                    key={el.sequence}
                                                                    onClick={() => handleSelectedDetail(el)}
                                                                    onDoubleClick={() => ShowEditPaymentFor()}
                                                                    sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                                    hover={true}
                                                                >
                                                                    <StyledTableBodyCell sx={{ textAlign: 'center', }}>
                                                                        <Tooltip key={el.sequence} title={el.accountCode + ' - ' + el.accountName}>
                                                                            <span>{el.accountCode}</span>
                                                                        </Tooltip>
                                                                    </StyledTableBodyCell>
                                                                    <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell sx={{ textAlign: 'right', }}>{el.percentVat}%</StyledTableBodyCell>
                                                                    <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.sign === true ? '+' : '-'}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.isCostToCost === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                                    {
                                                                        el.amountCrr === 0 ?
                                                                            <>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amount)}</StyledTableBodyCell>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountVat)}</StyledTableBodyCell>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(0)}</StyledTableBodyCell>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(0)}</StyledTableBodyCell>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(0)}</StyledTableBodyCell>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(0)}</StyledTableBodyCell>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amount)}</StyledTableBodyCell>
                                                                                <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountVat)}</StyledTableBodyCell>
                                                                            </>
                                                                    }
                                                                </TableRow>
                                                            )
                                                        })
                                                        :
                                                        <TableRow>
                                                            <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                                <Typography
                                                                    sx={{
                                                                        color: 'var(--Primary-One, #083A50)',
                                                                        fontFamily: 'Nunito',
                                                                        fontSize: '12px',
                                                                        fontstyle: 'normal',
                                                                        fontWeight: '500',
                                                                        lineHeight: 'normal'
                                                                    }}
                                                                >
                                                                    Data Empty
                                                                </Typography>
                                                            </StyledTableBodyCell>
                                                        </TableRow>
                                                }
                                            </>
                                    }
                                </TableBody>

                                <TableFooter
                                    sx={{
                                        left: 0,
                                        bottom: 0,
                                        zIndex: 2,
                                        position: 'sticky',
                                        background: '#E4E8EC',
                                        '& td:first-of-type': {
                                            borderRadius: '0 0 0 12px'
                                        },
                                        '& td:last-child': {
                                            borderRadius: '0 0 12px 0'
                                        }
                                    }}
                                    hidden={props.DataPayment.length > 0 ? false : true}
                                >
                                    <TableRow>
                                        <StyledTableFooterCell colSpan={5} sx={{ textAlign: 'center', }}>
                                            Total Amount
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.PaymentUSD)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.TotalVatUSD)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.PaymentIDR)}
                                        </StyledTableFooterCell>
                                        <StyledTableFooterCell sx={{ textAlign: 'right', }}>
                                            {convertToNominal(props.TotalVatIDR)}
                                        </StyledTableFooterCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>

                        <div style={{ marginTop: '16px' }}>
                            <Grid container item direction="row">
                                <Grid item xs={7} display={'flex'} sx={{ gap: '10px' }}>
                                    {
                                        props.isEdit === false ?
                                            <>
                                                {ButtonComponent.AddButton(ShowAddPaymentFor, '')}
                                            </>
                                            :
                                            <>
                                                {ButtonComponent.AddButton(ShowAddPaymentFor, '', (props.isViewOnly === true ? true : false))}
                                            </>
                                    }
                                    {ButtonComponent.EditButton(ShowEditPaymentFor, '', props.isViewOnly)}
                                    {ButtonComponent.DeleteButton(DeletePaymentFor, '', props.isViewOnly)}
                                    {
                                        props.isSeaImport === false && (
                                            <>
                                                {ButtonComponent.DemurrageButton(AddDemurrage, true, (props.isViewOnly === true ? true : false))}
                                            </>
                                        )

                                    }
                                </Grid>

                                <Grid item xs={5} className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ gap: '10px' }}>
                                    {ButtonComponent.AddButton(AddHF, 'HF', props.isViewOnly, props.isAgent)}
                                    {ButtonComponent.AddButton(AddPS, 'PS', props.isViewOnly, props.isAgent)}
                                    {ButtonComponent.AddButton(AddStorage, 'Storage', props.isViewOnly, props.isSea)}
                                </Grid>
                            </Grid>
                        </div>
                    </Box>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label={'Payment Type'}
                                value={props.PaymentStatus ? 'Paid' : 'Not Paid'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '8px !important'
                                    }
                                }}
                            />

                            <Stack spacing={'16px'} direction="row">
                                <Input
                                    textAlign='right'
                                    label={'Exchange Rate'}
                                    value={props.Rate}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label={'Exchange Rate Date'}
                                    value={props.ExRateDate}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            {/* <Selection
                                id='0'
                                label={'Currency'}
                                fullWidth={true}
                                placeholder={'Select Currency'}
                                value={props.Currency}
                                onChange={(e) => handleFilterCurrency(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        marginTop: '8px'
                                    }
                                }}
                                disabled
                            >
                                {
                                    currencyData.map((data, index) => (
                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                                    ))
                                }
                            </Selection> */}

                            <Input
                                textAlign='right'
                                label={'Kurs KMK'}
                                value={props.KursKMK}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '8px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {
                isModal && (
                    <ModalInvoices
                        isModal={isModal}
                        setIsModal={setIsModal}
                        handleClick={handleClick}
                        selectVATData={props.selectVATData}
                        VATData={props.VATData}
                        invoiceId={props.invoiceId}
                        Currency={props.Currency}
                        isViewOnly={props.isViewOnly}
                        setSelectedDetail={setSelectedDetail}
                        VatValue={props.VatValue}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        calculateTotal={props.calculateTotal}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        DataPayment={props.DataPayment}
                        setDataPayment={props.setDataPayment}
                        TempDataPayment={props.TempDataPayment}
                        setTempDataPayment={props.setTempDataPayment}
                        setDataPaymentSC={props.setDataPaymentSC}
                        setDataPaymentA={props.setDataPaymentA}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}
                        PaymentFrom={props.PaymentFrom}
                        containerSizeData={props.containerSizeData}
                        serviceType={props.serviceType}
                        portType={props.portType}

                        Customer={props.Customer}
                        PaymentType={props.PaymentType}
                        CustomerId={props.CustomerId}
                        CustomerName={props.CustomerName}
                        AccountId={AccountId}
                        setAccountId={setAccountId}
                        AccountCode={AccountCode}
                        setAccountCode={setAccountCode}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                        COACode={COACode}
                        setCOACode={setCOACode}
                        COAName={COAName}
                        setCOAName={setCOAName}
                        isContainer={isContainer}
                        setIsContainer={setIsContainer}
                        Description={Description}
                        setDescription={setDescription}
                        ContainerType={ContainerType}
                        setContainerType={setContainerType}
                        Quantity={Quantity}
                        setQuantity={setQuantity}
                        UnitCost={UnitCost}
                        setUnitCost={setUnitCost}
                        Sign={Sign}
                        BillToId={props.BillToId}
                        setSign={setSign}
                        Type={props.Type}
                        EPLId={props.EPLId}
                        AmountCrr={AmountCrr}
                        setAmountCrr={setAmountCrr}
                        Amount={Amount}
                        setAmount={setAmount}
                        VatId={VatId}
                        setVatId={setVatId}
                        CostToCost={CostToCost}
                        setCostToCost={setCostToCost}
                        OriginalUSD={OriginalUSD}
                        setOriginalUSD={setOriginalUSD}
                        OriginalRate={OriginalRate}
                        setOriginalRate={setOriginalRate}
                        containerTypeId={containerTypeId}
                        setContainerTypeId={setContainerTypeId}
                        containerTypeCode={containerTypeCode}
                        setContainerTypeCode={setContainerTypeCode}
                        containerTypeName={containerTypeName}
                        setContainerTypeName={setContainerTypeName}
                        ContainerFormat={ContainerFormat}
                        setContainerFormat={setContainerFormat}
                        isOthers={isOthers}
                        setIsOthers={setIsOthers}
                        setEplDetailId={setEplDetailId}
                        eplDetailId={eplDetailId}
                    />
                )
            }
        </div>
    )
}

export default InvoiceBeingForPayment;