import React from "react";
import { Grid } from "@mui/material";

const ButtonInfo = ({ButtonComponents = null}) => {
    return (
        <div
            style={{
                bottom: 0,
                zIndex: 1000,
                width: '100%',
                position: 'sticky',
                backgroundColor: '#E4E8EC'
            }}
        >
            <Grid
                container
                alignContent={'right'}
                justifyContent={"right"}
                sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '0px 72px 16px 32px' }}
            >
                <ButtonComponents />
            </Grid>
        </div>
    )
};

export default ButtonInfo;