import { FormHelperText, Stack, MenuItem } from '@mui/material';
import React, { forwardRef, useImperativeHandle } from 'react';
import { branchId, companyId, countryId, userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditEmployee = forwardRef((props, ref) => {
  const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

  const simpanLocal = () => {
    if (isModal) {
      const data = {
        "employee": {
          ...formik.values,
          rowStatus: formik?.values?.rowStatus || 'ACT',
          titleModal,
          user: userCode,
        }
      }
      localStorage.setItem(username + "-DataEmployee", JSON.stringify(data));
    }
  }

  useImperativeHandle(ref, () => ({

    localSave() {
      simpanLocal()
    }

  }));

  const convertDate = (dt) => {
    if (!dt) return

    const newDate = new Date(dt)
    const formattedDate = newDate.toLocaleDateString("sv-se").split('T')[0]

    return formattedDate
  }

  const validationSchema = Yup.object().shape({
    code: Yup.string().trim().required('This field is required.'),
    name: Yup.string().trim().required('This field is required.'),
    age: Yup.number('Must be a valid number.').min(0, 'Must be greater than 0.').required('This field is required.'),
    position: Yup.string().trim().required('This field is required.'),
    department: Yup.string().trim().required('This field is required.'),
    title: Yup.string().trim().required('This field is required.'),
    baseCamp: Yup.string().trim().required('This field is required.'),
    remark: Yup.string().trim().required('This field is required.'),
    validOn: Yup.date().required('This field is required.'),
    validTo: Yup.date().required('This field is required.'),
  })

  const formik = useFormik({
    initialValues: selectedData?.id ?
      {
        ...selectedData,
        age: selectedData?.age !== 0 ? selectedData.age : '',
        validOn: selectedData?.validOn ? convertDate(selectedData.validOn) : '',
        validTo: selectedData?.validTo ? convertDate(selectedData.validTo) : '',
      } : {
        code: '',
        name: '',
        age: '',
        position: '',
        department: '',
        title: '',
        baseCamp: '',
        remark: '',
        validOn: '',
        validTo: '',
      },
    validationSchema,
    onSubmit: (values) => Save(values)
  })

  const Save = (payload) => {
    if (payload?.name === '') {
      return showToast({ type: 'error', message: 'Name is Required!' });
    }

    if (payload?.code === '') {
      return showToast({ type: 'error', message: 'Code is Required!' });
    }

    const data =
    {
      "employee": {
        "rowStatus": "ACT",
        "countryId": countryId,
        "companyId": companyId,
        "branchId": branchId,
        "id": payload?.id,
        "code": payload?.code,
        "name": payload?.name,
        "age": payload?.age,
        "position": payload?.position,
        "department": payload?.department,
        "title": payload?.title,
        "baseCamp": payload?.baseCamp,
        "remark": payload?.remark,
        "validOn": payload?.validOn,
        "validTo": payload?.validTo,
        "userCode": userCode
      }
    }

    ButtonService.Save(titleModal, title, param, data, () => {
      getData(1, 50, []);
      handleClick();
    });
  }

  const mainContainer = (
    <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
      <Input
        name="code"
        label="Employee Code"
        value={formik.values.code}
        onBlur={formik.handleBlur}
        error={formik.touched.code && Boolean(formik.errors.code)}
        helperText={formik.touched.code && formik.errors.code}
        onChange={(e) => formik.setFieldValue('code', e.target.value)}
        formControlProps={{
          sx: {
            width: '100%',
            // marginTop: '16px'
          }
        }}
      />

      <Input
        name="name"
        label="Name"
        value={formik.values.name}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        onChange={(e) => formik.setFieldValue('name', e.target.value)}
        formControlProps={{
          sx: {
            width: '100%',
            marginTop: '16px'
          }
        }}
      />

      <Stack direction={'row'} spacing={'16px'}>
        <Input
          name="age"
          label="Age"
          type='number'
          value={formik.values.age}
          onBlur={formik.handleBlur}
          error={formik.touched.age && Boolean(formik.errors.age)}
          helperText={formik.touched.age && formik.errors.age}
          onChange={(e) => formik.setFieldValue('age', e.target.value)}
          formControlProps={{
            sx: {
              width: '100%',
              marginTop: '16px !important'
            }
          }}
        />

        <Input
          name="position"
          label="Position"
          value={formik.values.position}
          onBlur={formik.handleBlur}
          error={formik.touched.position && Boolean(formik.errors.position)}
          helperText={formik.touched.position && formik.errors.position}
          onChange={(e) => formik.setFieldValue('position', e.target.value)}
          formControlProps={{
            sx: {
              width: '100%',
              marginTop: '16px !important'
            }
          }}
        />
      </Stack>

      <Selection
        id="department"
        name='department'
        fullWidth={true}
        label="Department"
        labelId="department"
        value={formik.values.department}
        placeholder={'Select Department'}
        formControlProps={{
          sx: {
            width: '100%',
            marginTop: '16px'
          }
        }}
        onChange={(e) => formik.setFieldValue('department', e.target.value)}
        error={formik.touched.department && Boolean(formik.errors.department)}
      >
        <MenuItem sx={{ fontSize: '12px' }} key={0} value={'A'}>Accounting</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} key={1} value={'M'}>Marketing</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} key={2} value={'O'}>Operational</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} key={3} value={'C'}>Cust Service</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} key={4} value={'T'}>Tax</MenuItem>
        <FormHelperText>{formik.touched.department && formik.errors.department}</FormHelperText>
      </Selection>

      <Selection
        id="title"
        name='title'
        fullWidth={true}
        label="Title"
        labelId="title"
        value={formik.values.title}
        placeholder={'Select Title'}
        formControlProps={{
          sx: {
            width: '100%',
            marginTop: '16px'
          }
        }}
        onChange={(e) => formik.setFieldValue('title', e.target.value)}
        error={formik.touched.title && Boolean(formik.errors.title)}
      >
        <MenuItem sx={{ fontSize: '12px' }} key={0} value={'D'}>Director</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} key={1} value={'G'}>General Mgr</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} key={2} value={'M'}>Manager</MenuItem>
        <MenuItem sx={{ fontSize: '12px' }} key={3} value={'S'}>Staff</MenuItem>
        <FormHelperText>{formik.touched.title && formik.errors.title}</FormHelperText>
      </Selection>

      <Input
        name="baseCamp"
        label="Base Camp"
        value={formik.values.baseCamp}
        onBlur={formik.handleBlur}
        error={formik.touched.baseCamp && Boolean(formik.errors.baseCamp)}
        helperText={formik.touched.baseCamp && formik.errors.baseCamp}
        onChange={(e) => formik.setFieldValue('baseCamp', e.target.value)}
        formControlProps={{
          sx: {
            width: '100%',
            marginTop: '16px'
          }
        }}
      />

      <Input
        id='remark'
        name="remark"
        label="Remark"
        value={formik.values.remark}
        multiline
        fullWidth
        formControlProps={{
          sx: {
            width: '100%',
            marginTop: '16px'
          }
        }}
        inputBaseProps={{
          rows: 4,
          sx: {
            padding: '8px',
          },
        }}
        onChange={(e) => formik.setFieldValue('remark', e.target.value)}
      />

      <Stack direction={'row'} spacing={'16px'}>
        <Input
          type={'date'}
          name="validOn"
          label="Valid On"
          value={formik.values.validOn}
          onBlur={formik.handleBlur}
          error={formik.touched.validOn && Boolean(formik.errors.validOn)}
          helperText={formik.touched.validOn && formik.errors.validOn}
          onChange={(e) => formik.setFieldValue('validOn', e.target.value)}
          formControlProps={{
            sx: {
              width: '100%',
              marginTop: '16px !important'
            }
          }}
        />

        <Input
          type={'date'}
          name="validTo"
          label="Valid To"
          value={formik.values.validTo}
          onBlur={formik.handleBlur}
          error={formik.touched.validTo && Boolean(formik.errors.validTo)}
          helperText={formik.touched.validTo && formik.errors.validTo}
          onChange={(e) => formik.setFieldValue('validTo', e.target.value)}
          formControlProps={{
            sx: {
              width: '100%',
              marginTop: '16px !important'
            }
          }}
        />
      </Stack>
    </form>
  )

  return (
    <>
      {
        isModal && (
          <CreateEditModalMaster
            isModal={isModal}
            titleModal={titleModal}
            title={title}
            mainContainer={mainContainer}
            handleClick={handleClick}
            handleSave={() => Save(formik.values)}
          />
        )
      }
    </>
  )
})

export default CreateEditEmployee