import { Grid, IconButton, Stack, InputAdornment, Typography, } from "@mui/material";
import React from "react";
import CustomizeButtonComponent from "../../../../../components/Buttons/CustomizeButtonComponent";
import ModalListPaymentRequest from "./ModalListPaymentRequest";
import { useState } from "react";
import ApiService, {
  branchId,
  companyId,
  countryId,
  userCode,
  userId,
  formatCurrency
} from "../../../../../services/ApiService";
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';
import { Icon, Input, PopUp, showToast } from "../../../../../components/ui";
import { convertToNumeric } from "../../../../../utils/masks";

const ModalBankAdvDetail = (props) => {
  const [isModalListPR, setIsModalListPR] = useState(false);
  const [titleModalListPR, setTitleModalListPR] = useState("");

  const [isLoadingListPR, setIsLoadingListPR] = useState(false);
  const [dataListPR, setDataListPR] = useState([]);
  const [dataMapListPR, setDataMapListPR] = useState([]);
  const [columnDataListPR, setColumnDataListPR] = useState([]);
  const [SelectedDataListPR, setSelectedDataListPR] = useState({});
  const [totalRowsListPR, setTotalRowsListPR] = useState(50);

  const paramListPR = "paymentRequest/paymentRequest";

  const Save = () => {
    let Sequence = 1;
    let bankAdvId = 0;
    if (props.isAdd) {
      if (props.dataDetail.length > 0) {
        Sequence = props.dataDetail.length + 1;
      }
    } else {
      Sequence = props.selectedDetail.sequence;
    }
    if (props.BankAdvId) {
      bankAdvId = props.BankAdvId;
    }
    let bankAdvanceId = parseInt(bankAdvId);
    let cashAdvanceId = parseInt(bankAdvId);

    let payload = {
      rowStatus: "ACT",
      countryId: countryId,
      companyId: companyId,
      branchId: branchId,
      sequence: Sequence,
      description: props.description,
      amountUSD: convertToNumeric(props.amountUSD),
      amountIDR: convertToNumeric(props.amountIDR),
      refId: props.refId,
      refNo: "" + props.refNo,
      refShipmentId: props.refShipmentId,
      refShipmentNo: props.refShipmentNo,
      customerId: props.customerId,
      customerName: props.customerName,
      amountUSDPR: convertToNumeric(props.amountUSDPR),
      amountIDRPR: convertToNumeric(props.amountIDRPR),
      amountDiffUSDPR: convertToNumeric(props.amountDiffUSDPR),
      amountDiffIDRPR: convertToNumeric(props.amountDiffIDRPR),
      userId: userId,
      userCode: userCode
    };

    if (payload.amountUSD === 0 && payload.amountIDR === 0) {
      showToast({ type: 'error', message: 'Amount USD or Amount IDR Must Be Filled Greater Than 0!' });
    }
    else {
      if (props.titleDetail === "Bank") {
        payload = { ...payload, bankAdvanceId };
      } else {
        payload = { ...payload, cashAdvanceId };
      }
      if (props.isAdd) {
        props.setDataDetail([...props.dataDetail, payload]);
        props.sumTotal([...props.dataDetail, payload], props.rate);
      } else {
        const newArr = props.dataDetail.slice();
        newArr.forEach((el) => {
          if (el.sequence === Sequence) {
            el.bankAdvanceId = payload.bankAdvanceId;
            el.description = payload.description;
            el.amountUSD = payload.amountUSD;
            el.amountIDR = payload.amountIDR;
            el.refId = payload.refId;
            el.refNo = payload.refNo;
            el.refShipmentId = payload.refShipmentId;
            el.refShipmentNo = payload.refShipmentNo;
            el.customerId = payload.customerId;
            el.customerName = payload.customerName;
            el.perQty = payload.perQty;
            el.amountUSDPR = payload.amountUSDPR;
            el.amountIDRPR = payload.amountIDRPR;
            el.amountDiffUSDPR = payload.amountDiffUSDPR;
            el.amountDiffIDRPR = payload.amountDiffIDRPR;
          }
        });
        props.setDataDetail(newArr);
        props.sumTotal(newArr, props.rate);
      }
      emptyState();
      props.handleClick();
    }
  };

  const emptyState = () => {
    props.setDescription("");
    props.setAmountUSD(0);
    props.setAmountIDR(0);
    props.setRefId(0);
    props.setRefNo("");
    props.setRefShipmentId(0);
    props.setRefShipmentNo("");
    props.setCustomerId(0);
    props.setCustomerName("");
    props.setAmountUSDPR(0);
    props.setAmountIDRPR(0);
    props.setAmountDiffUSDPR(0);
    props.setAmountDiffIDRPR(0);
  };

  const Close = () => {
    emptyState();
    props.handleClick();
  };

  const SearchReference = (data) => {
    if (data === 'search') {
      if (isModalListPR === false) {
        setIsModalListPR(true);
        setTitleModalListPR("List Payment Request");
        getDataListPR(1, 50);
      } else {
        setIsModalListPR(false);
      }
    }
    else if (data === 'delete') {
      props.setDescription("");
      props.setAmountUSD(0);
      props.setAmountIDR(0);
      props.setRefId(0);
      props.setRefNo("");
      props.setRefShipmentId(0);
      props.setRefShipmentNo("");
      props.setCustomerId(0);
      props.setCustomerName("");
      props.setAmountUSDPR(0);
      props.setAmountIDRPR(0);
      props.setAmountDiffUSDPR(0);
      props.setAmountDiffIDRPR(0);
    }
  };

  const getDataListPR = (pageNumber, pageSize, filters) => {
    setIsLoadingListPR(true);
    ApiService.OperationDynamicPostByPage(
      paramListPR,
      "PAGE",
      pageNumber,
      pageSize,
      [],
      filters
    )
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataListPR({});
          setDataListPR(response.data.data.paymentRequests);

          let temp = response.data.data.paymentRequests;
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            };
            return indexedTemp;
          });

          setDataMapListPR(indexed);
          setColumnDataListPR(response.data.data.columns.headerColumns);
          setTotalRowsListPR(response.data.totalRowCount);

          setIsLoadingListPR(false);
        }
        setIsLoadingListPR(false);
      })
      .catch(function (error) {
        setIsLoadingListPR(false);
        console.error("error saat fetch", error);
      });
  };

  const mainContainer = (
    <>
      <Input
        label={'Description'}
        value={props.description}
        onChange={(e) => props.setDescription(e.target.value)}
        multiline
        disabled={props.printing > 0 ? true : false}
        fullWidth
        formControlProps={{
          sx: {
            width: '100%',
          }
        }}
        inputBaseProps={{
          rows: 4,
          sx: {
            padding: '8px',
          },
        }}
      />

      <div style={{ marginTop: '16px' }}>
        <Stack spacing={'64px'} direction="row">
          <Input
            label="Amount USD"
            textAlign='right'
            fullWidth
            value={props.amountUSD}
            onChange={(e) => props.setAmountUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
            disabled={props.printing > 0 ? true : false}
            formControlProps={{
              sx: {
                width: '100%',
              }
            }}
            inputBaseProps={{
              inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
            }}
          />

          <Input
            label="Amount IDR"
            textAlign='right'
            fullWidth
            value={props.amountIDR}
            onChange={(e) => props.setAmountIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
            disabled={props.printing > 0 ? true : false}
            formControlProps={{
              sx: {
                width: '100%',
              }
            }}
            inputBaseProps={{
              inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
            }}
          />
        </Stack>
      </div>

      <div style={{ marginTop: '24px' }} hidden={props.advanceTo !== 'O'}>
        <Grid container spacing={'64px'}>
          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            <Typography
              sx={{
                color: 'var(--Blue-Primary-1, #083A50)',
                fontFamily: 'Nunito',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
              }}
            >
              Reference Number For {props.titleDetail} Advance
            </Typography>

            <Input
              label="Reference No"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={props.refId}
              type='number'
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '12px !important'
                }
              }}
              inputBaseProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => SearchReference('delete')}
                      hidden={props.printing > 0 ? true : props.refId ? false : true}
                    >
                      <Icon
                        iconName={'close'}
                        svgIconProps={{
                          fontSize: 'small',
                          htmlColor: '#0087C2',
                          sx: {
                            height: '12px',
                            width: '12px'
                          }
                        }}
                      />
                    </IconButton>

                    <IconButton
                      onClick={() => SearchReference('search')}
                      hidden={props.printing > 0 ? true : false}
                    >
                      <Icon
                        iconName={'search'}
                        svgIconProps={{
                          fontSize: 'small',
                          htmlColor: '#0087C2',
                          sx: {
                            height: '12px',
                            width: '12px'
                          }
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              disabled
            />

            <Input
              label="Ref.Shipment No"
              value={props.refShipmentNo}
              disabled
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px !important'
                }
              }}
            />

            <Input
              label="Customer Name"
              value={props.customerName}
              disabled
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px !important'
                }
              }}
            />

            <Input
              label="Amount USD"
              value={props.amountUSDPR}
              textAlign='right'
              disabled
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px !important'
                }
              }}
            />

            <Input
              label="Amount IDR"
              value={props.amountIDRPR}
              textAlign='right'
              disabled
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px !important'
                }
              }}
            />
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            sm={6}
            xs={12}
          >
            <Typography
              sx={{
                color: 'var(--Blue-Primary-1, #083A50)',
                fontFamily: 'Nunito',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: 'normal',
              }}
            >
              Different Cost Payment Request
            </Typography>

            <Input
              label="USD"
              value={props.amountDiffUSDPR}
              onChange={(e) => props.setAmountDiffUSDPR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
              textAlign='right'
              disabled
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '12px !important'
                }
              }}
              inputBaseProps={{
                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
              }}
            />

            <Input
              label="IDR"
              value={props.amountDiffIDRPR}
              onChange={(e) => props.setAmountDiffIDRPR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
              textAlign='right'
              disabled
              fullWidth
              formControlProps={{
                sx: {
                  width: '100%',
                  marginTop: '16px !important'
                }
              }}
              inputBaseProps={{
                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );

  return (
    <>
      <PopUp
        id='modal-id'
        icon={
          <Icon
            iconName={'approve-doc'}
            svgIconProps={{
              htmlColor: '#0087C2',
              sx: {
                height: '24px',
                width: '24px'
              }
            }}
          />
        }
        title={props.titleModal}
        isOpen={props.isModal}
        onClose={Close}
        draggable={true}
        shrinkable={true}
        disableScrollLock={true}
        maxWidth={'lg'}
        fullWidth={true}
        topButton={false}
      >
        <div style={{ padding: '12px 6px 0px' }}>
          {mainContainer}

          <div style={{ marginTop: '32px' }}>
            {CustomizeButtonComponent.SaveCancelModalButton(Save, false, props.printing > 0 ? true : false)}
          </div>
        </div>
      </PopUp>

      {
        isModalListPR && (
          <ModalListPaymentRequest
            setAmountUSD={props.setAmountUSD}
            setAmountIDR={props.setAmountIDR}
            setAmountUSDPR={props.setAmountUSDPR}
            setAmountIDRPR={props.setAmountIDRPR}
            setRefId={props.setRefId}
            setRefNo={props.setRefNo}
            setRefShipmentId={props.setRefShipmentId}
            setRefShipmentNo={props.setRefShipmentNo}
            setCustomerName={props.setCustomerName}
            setCustomerId={props.setCustomerId}
            setAmountDiffUSDPR={props.setAmountDiffUSDPR}
            setAmountDiffIDRPR={props.setAmountDiffIDRPR}
            isModal={isModalListPR}
            handleClick={SearchReference}
            titleModal={titleModalListPR}
            setIsModal={setIsModalListPR}
            isLoading={isLoadingListPR}
            data={dataListPR}
            dataMap={dataMapListPR}
            columnData={columnDataListPR}
            SelectedData={SelectedDataListPR}
            totalRows={totalRowsListPR}
            setSelectedData={setSelectedDataListPR}
            getData={getDataListPR}
          />
        )
      }
    </>
  );
};

export default ModalBankAdvDetail;
