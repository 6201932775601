import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import { Grid, Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ButtonGeneralInvoice from './Buttons/ButtonGeneralInvoice';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import freezeContainer from '../../../../Styles/freezeContainer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import ModalPrintRecap from './Modals/ModalPrintRecap';
import { setDateFormat } from '../../../../utils/date';
import { newWindowConfig } from '../../../../utils/masks.jsx';

const GeneralInvoiceList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramGeneralInvoice = 'generalInvoice/generalInvoice';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramGL = 'generalLedger/generalLedger';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});

    const [isModalRecap, setIsModalRecap] = useState(false);
    const [Period, setPeriod] = useState(new Date());
    const [isDraft, setIsDraft] = useState(true);
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [Printing, setPrinting] = useState(0);

    const [DropDownTitle, setDropDownTitle] = useState('All');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Invoice', link: '/Majura/GeneralInvoice', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const dropDownList = [
        { label: 'All', icon: 'search-doc' },
        { label: 'Active', icon: 'documents' },
        { label: 'Posted', icon: 'posted' },
        { label: 'Deleted', icon: 'status-deleted' },
    ]

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'General Invoice - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let filter = [];
        if (data) {
            if (data === 'All') {
                filter = [...filters]
            }
            else if (data === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (data === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'false' },
                ];
            }
            else if (data === 'Posted') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'true' }
                ];
            }
        }
        else {
            if (DropDownTitle === 'All') {
                filter = [...filters]
            }
            else if (DropDownTitle === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (DropDownTitle === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'false' },
                ];
            }
            else if (DropDownTitle === 'Posted') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'true' }
                ];
            }
        }

        ApiService.ListFinAccPostByPage(paramGeneralInvoice, 'PAGE', pageNumber, pageSize, [], filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.generalInvoice
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false)
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FGIN')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getData(1, 50, []);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false)
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClick = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalConfirmation(false);
            setIsDueDate(false);
            setIsDraft(true);
        }
    }

    function ConfirmationGI() {
        if (titleConfirmation === 'Print') {
            if (isDraft) {
                PrintPDF(dataSelected.id);
            }
            else {
                Print();
            }
        }
        handleClickConfirmation();
    }

    const AddGI = () => {
        history('/Majura/GeneralInvoice/Create');
    }

    const EditGI = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            window.open('/Majura/GeneralInvoice/' + SelectedData.id, newWindowConfig);
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(1, 50, []);
    }

    const DeleteData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.approved) {
                    showToast({ type: 'error', message: 'Can Not Delete Data, Already Approved!' });
                }
                else {
                    setTitleModalDelete('General Invoice');
                    handleClick();
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const DeleteGI = () => {
        ApiService.FinAccDelete(paramGeneralInvoice, SelectedData.id).then((res) => {
            if (res.data.code === 200) {
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Delete Data Success' });
                setIsModalDelete(false);
            }
            else {
                showToast({ type: 'error', message: 'Delete Data Failed' });
                setIsModalDelete(false);
            }
        });
    }

    const OpenLogData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setIsLoadingBackDrop(true);

            let date = new Date(SelectedData.printedOn);
            let formatDate = date.toLocaleDateString("en-US");
            ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, formatDate)
                .then((response) => {
                    let data = response.data.data.closingPeriod;
                    history('/Majura/TransactionLogs/GeneralInvoice/' + SelectedData.id + '/' + data.period + '/' + data.yearPeriod);
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    // console.error('error saat fetch', error);
                })
        }
    }

    const PrintData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === "ACT") {
                setTitleConfirmation('Print')
                setTitleModalConfirmation('General Invoice');
                handleClickConfirmation();
                setPrinting(SelectedData.printing);
                if (SelectedData.printing > 0) {
                    setDateFormat(SelectedData?.printedOn, setDueDate);
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    const Print = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccBackDatePrint(paramGeneralInvoice, dataSelected.id, dueDate).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                if (dataSelected.printing === 0) {
                    CreateGL(dataSelected.id);
                }

                PrintPDF(dataSelected.id);

                getData(numPage, rowsCount, filter);
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    };

    const PrintPDF = (id) => {
        setIsLoadingBackDrop(true);
        let variable = 'PrintGI?Id=' + id + '&isDraft=' + isDraft;
        ApiService.ReportPrint('Transactions/Finance/GeneralInvoiceReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print General Invoice Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print General Invoice' });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print General Invoice' });
            });
    }

    const CreateGL = (id) => {
        var data = {
            "generalLedger": {
                "source": "GI",
                "refId": id,
                "methodPost": "AUTO",
                "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
            }
        }

        ApiService.CreateFinAcc(paramGL, data).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code !== 200) {
                showToast({ type: 'error', message: message });
            }
        });
    }

    const handleClickRecap = () => {
        if (isModalRecap === false) {
            setIsModalRecap(true);
            getDefaultMonth();
        }
        else {
            setIsModalRecap(false);
        }
    }

    const getDefaultMonth = () => {
        const date = new Date();
        const year = date.getFullYear()
        let month = date.getMonth() + 1

        if (month < 10) {
            month = `0${month}`
        }

        setPeriod(`${year}-${month}`);
    }

    const PrintRecap = (type) => {
        handleClickRecap();
        setIsLoadingBackDrop(true);
        let variable = 'PrintGIRecap?typeReport=' + type;
        let params = [
            {
                "attr": "period",
                "value": '' + (new Date(Period).getMonth() + 1),
            },
            {
                "attr": "yearPeriod",
                "value": '' + new Date(Period).getFullYear(),
            }
        ]
        ApiService.ReportPrint('Transactions/Finance/GeneralInvoiceReport/', variable, params)
            .then(response => {
                if (response.status === 200) {
                    let file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    if (type === 'xls') {
                        file = new Blob(
                            [response.data],
                            { type: "application/vnd.ms-excel" }
                        );
                    }

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Recap General Invoice Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Recap General Invoice' });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Recap General Invoice' });
            });
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
    };

    function CellDoubleClick(row) {
        EditGI();
    };

    function HandleStatus(data) {
        setDropDownTitle(data);
        getData(numPage, rowsCount, filter, sortArr, data);
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    const ButtonComponents = () => {
        return (
            <ButtonGeneralInvoice
                AddGI={AddGI}
                EditGI={EditGI}
                ReloadData={ReloadData}
                DeleteData={DeleteData}
                Print={PrintData}
                PrintRecap={handleClickRecap}
                DataRegUserAccess={DataRegUserAccess}
                OpenLogData={OpenLogData}
                DropDownTitle={DropDownTitle}
                HandleStatus={HandleStatus}
                DropDownList={dropDownList}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={'General Invoice'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClick}
                        DeleteData={DeleteGI}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationGI}
                        isDraft={isDraft}
                        setIsDraft={setIsDraft}

                        isDueDate={isDueDate}
                        setIsDueDate={setIsDueDate}
                        PrintCount={Printing}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        getDateNow={getDateNow}
                    />
                )
            }

            {
                isModalRecap && (
                    <ModalPrintRecap
                        isModal={isModalRecap}
                        handleClick={handleClickRecap}
                        PrintRecap={PrintRecap}
                        Period={Period}
                        setPeriod={setPeriod}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default GeneralInvoiceList