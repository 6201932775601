import React from 'react';
import { branchId, companyId, countryId, userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input } from '../../../components/ui';

const CreateEditJobOwner = (props) => {
    const Save = () => {
        var data =
        {
            "jobOwner": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "masterCode": props.masterCode,
                "fullName": props.fullName,
                "idLama": 0,
                "deleted": false,
                "deletedBy": "string",
                "deletedOn": "2023-02-10T02:36:40.659Z",
                "userCode": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                name="code"
                label="Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                value={props.code}
                onChange={(e) => props.setCode(e.target.value)}
            />

            <Input
                name="name"
                label="Job Owner"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
            />

            <Input
                name="code"
                label="Master Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={props.masterCode}
                onChange={(e) => props.setMasterCode(e.target.value)}
            />

            <Input
                name="fullName"
                label="Full Name"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={props.fullName}
                onChange={(e) => props.setFullName(e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }
        </>
    )
}

export default CreateEditJobOwner