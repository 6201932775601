import React, { useState } from 'react'
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from '@mui/material'

import { Paginate } from '../../../components/ui'

const TOTAL_DATA = 666
const sizeList = ['small', 'medium', 'large']

const MasterPaginate = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentLimit, setCurrentLimit] = useState(50)
  const [activeSize, setActiveSize] = useState('medium')

  return (
    <Stack direction={'column'} gap={2}>
      <FormControl>
        <FormLabel id="size-option">Size</FormLabel>
        <RadioGroup
          row
          aria-labelledby="size-option"
          name="size"
          value={activeSize}
          onChange={(e) => setActiveSize(e.target.value)}
        >
          {sizeList.map((size, index) => (
            <FormControlLabel
              key={index}
              value={size}
              control={<Radio />}
              label={size}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <Box>
        <Paginate
          page={currentPage}
          size={activeSize} // Default: large
          limit={currentLimit} // Default: 50
          totalData={TOTAL_DATA}
          onChange={setCurrentPage}
          onChangeLimit={setCurrentLimit}
        />
      </Box>
    </Stack>
  )
}

export default MasterPaginate