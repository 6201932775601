import { MenuItem, FormHelperText } from '@mui/material';
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Input, Selection, showToast } from '../../../components/ui';

const CreateEditSetTable = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props

    const [categoryList, setCategoryList] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (isModal) {
            getCategoryList()
        }
    }, [isModal])

    const getCategoryList = () => {
        setCategoryList([
            { id: 1, name: 'MASTER' },
            { id: 2, name: 'IDENTITY' },
            { id: 3, name: 'OPERATION' },
            { id: 4, name: 'FINANCE' },
            { id: 5, name: 'DASHBOARD' },
            { id: 6, name: 'WAREHOUSE' },
        ])

        setIsLoading(false)
        return true
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "setTable": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DataSetTable", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        code: Yup.string().trim().required('This field is required.'),
        tableName: Yup.string().trim().required('This field is required.'),
        categoryType: Yup.string().trim().required('This field is required.'),
        tableAlias: Yup.string().trim().required('This field is required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            code: '',
            tableName: '',
            categoryType: '',
            tableAlias: '',
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.tableName === '') {
            return showToast({ type: 'error', message: 'Table Name is Required!' });
        }

        if (payload?.code === '') {
            return showToast({ type: 'error', message: 'Code is Required!' });
        }

        if (payload?.categoryType === '') {
            return showToast({ type: 'error', message: 'Category Type is Required!' });
        }

        if (payload?.tableAlias === '') {
            return showToast({ type: 'error', message: 'Table Alias is Required!' });
        }

        const data =
        {
            "setTable": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload?.code,
                "tableName": payload?.tableName,
                "categoryType": payload?.categoryType,
                "tableAlias": payload?.tableAlias,
                "userCode": userCode,
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="code"
                label="Code"
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="tableName"
                label="Table Name"
                value={formik.values.tableName}
                onBlur={formik.handleBlur}
                error={formik.touched.tableName && Boolean(formik.errors.tableName)}
                helperText={formik.touched.tableName && formik.errors.tableName}
                onChange={(e) => formik.setFieldValue('tableName', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="tableAlias"
                label="Table Alias"
                value={formik.values.tableAlias}
                onBlur={formik.handleBlur}
                error={formik.touched.tableAlias && Boolean(formik.errors.tableAlias)}
                helperText={formik.touched.tableAlias && formik.errors.tableAlias}
                onChange={(e) => formik.setFieldValue('tableAlias', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Selection
                id="categoryType"
                name='categoryType'
                label="Category Type"
                labelId="label-category"
                value={formik.values.categoryType}
                placeholder={'Select Category Type'}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                onChange={(e) => formik.setFieldValue('categoryType', e.target.value)}
                error={formik.touched.categoryType && Boolean(formik.errors.categoryType)}
            >
                {
                    categoryList.map((category) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={category.id} value={category.name}>{category.name}</MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.categoryType && formik.errors.categoryType}</FormHelperText>
            </Selection>
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        isLoading={isLoading}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})

export default CreateEditSetTable