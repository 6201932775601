import React, { forwardRef, useImperativeHandle } from 'react';
import { Grid, MenuItem } from '@mui/material';

import ButtonService from '../ButtonService';
import { userCode } from '../../../services/ApiService';
import { Input, Selection } from '../../../components/ui';
import CreateEditModalIdentity from '../CreateEditModalIdentity';

const CreateEditPermission = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        localSave() {
        }
    }));

    const Save = () => {
        var data = {
            "permission": {
                "rowStatus": "ACT",
                "id": props.id,
                "iconName": props.iconName,
                "displayOrder": props.displayOrder,
                "categoryType": props.categoryType,
                "type": props.type.toUpperCase(),
                "parentId": props.parentId,
                "url": props.url,
                "hidden": props.hidden,
                "awsKeyName": props.awsKeyName,
                "awsKeyValue": props.awsKeyValue,
                "endPoint": props.endPoint,
                "code": props.code.toUpperCase(),
                "name": props.name,
                "userCode": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const CategoryTypeData = [
        { id: 1, name: 'IDENTITY' },
        { id: 2, name: 'MASTER' },
        { id: 3, name: 'OPERATION' },
        { id: 4, name: 'FINANCE' },
        { id: 5, name: 'TRUCK' },
    ];

    const HiddenData = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ]

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Grid
                container
                spacing={'16px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label={'Code'}
                        value={props.code}
                        onChange={(e) => props.setCode(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        label={'Icon Name'}
                        value={props.iconName}
                        onChange={(e) => props.setIconName(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Selection
                        label="Category Type"
                        value={props.categoryType}
                        placeholder={'Select Category Type'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        onChange={(e) => props.setCategoryType(e.target.value)}
                    >
                        {
                            CategoryTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>

                    <Input
                        label={'URL'}
                        value={props.url}
                        onChange={(e) => props.setUrl(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        label={'AWS Key Name'}
                        value={props.awsKeyName}
                        onChange={(e) => props.setAwsKeyName(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label={'Name'}
                        value={props.name}
                        onChange={(e) => props.setName(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                // marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        type={'number'}
                        label={'Display Order'}
                        value={props.displayOrder}
                        onChange={(e) => props.setDisplayOrder(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        label={'Type'}
                        value={props.type}
                        onChange={(e) => props.setType(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Selection
                        label="Hidden"
                        value={props.hidden}
                        placeholder={'Select Hidden'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        onChange={(e) => props.setHidden(e.target.value)}
                    >
                        {
                            HiddenData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>

                    <Input
                        label={'AWS Key Value'}
                        value={props.awsKeyValue}
                        onChange={(e) => props.setAwsKeyValue(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                label={'End Point'}
                value={props.endPoint}
                onChange={(e) => props.setEndPoint(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalIdentity
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }
        </>
    )
})

export default CreateEditPermission