import { Grid, IconButton, MenuItem, Stack, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataCOA from '../Selects/SelectDataCOA';
import SelectDataReference from '../Selects/SelectDataReference';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const ModalMemorialDetail = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);
    const [dataCheck, setDataCheck] = useState([]);

    let paramR = "";
    const [isLoadingR, setIsLoadingR] = useState(false);
    const [dataR, setDataR] = useState([]);
    const [dataMapR, setDataMapR] = useState([]);
    const [columnDataR, setColumnDataR] = useState([]);
    const [SelectedDataR, setSelectedDataR] = useState({});
    const [totalRowsR, setTotalRowsR] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataRef(1, 50);
            setDataCheck([]);
        }
        else {
            setIsModal2(false);
        }
    };

    const searchData = (data) => {
        if (data === 'search') {
            if (props.Type === 2) {
                setTitleModal2('Shipment Order');
            }
            else {
                setTitleModal2('Reference');
            }
            handleClick2();
        }
        else if (data === 'delete') {
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        if (props.Type !== 3) {
            props.setRefId(0);
            props.setRefNo('');
            props.setShipmentId(0);
            props.setShipmentNo('');
            props.setRefExRateId(0);
            props.setRefRate(0);
        }

        props.setRefDetailId(0);

        props.setAccountCode('');
        props.setAccountName('');
        props.setDescription('');
        props.setDCNote('D');
        props.setAmountUSD(0);
        props.setAmountIDR(0);
    };

    const TransactionTypeData = [
        { id: 1, code: 'D', name: 'Debit' },
        { id: 2, code: 'C', name: 'Credit' },
    ];

    const handleSave = () => {
        if (!props.AccountCode) {
            showToast({ type: 'error', message: 'Please Select Chart of Account!' });
        }
        else {
            if (props.AmountUSD <= 0 && props.AmountIDR <= 0) {
                showToast({ type: 'error', message: 'Please Input Amount!' });
            }
            else {
                let Sequence = 1;
                let mId = 0;
                let rate = 0;
                let rateId = 0;

                if (props.detailEdit) {
                    Sequence = props.selectedDetail.sequence;
                }
                else {
                    if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                }

                if (props.memorialId) {
                    mId = props.memorialId;
                }

                if (props.Type === 2) // Get Detail for Memorial *Cost Type* Case from Payment Request
                {
                    if (!props.detailEdit) {
                        props.setIsLoadingBackDrop(true);
                        let i = dataCheck.length;
                        if (dataCheck.length > 0) {
                            let customerTypeId = dataCheck[0].customerTypeId;
                            let jobTypeCode = dataCheck[0].jobTypeCode;
                            let newArr = props.MemorialDetailList;
                            ApiService.CustomPostByPage(
                                'regJournalAccount/JournalAccount',
                                1, 50,
                                customerTypeId, 'PR', [], 'D', '',
                                customerTypeId === 4 || customerTypeId === 5 ? jobTypeCode : '',
                                '', 'D'
                            )
                                .then((response) => {
                                    if (response.data.code === 200) {
                                        if (response.data.data.journalAccount[0] !== undefined) {
                                            let accountCode = response.data.data.journalAccount[0].accountCode;
                                            let accountName = response.data.data.journalAccount[0].accountName;

                                            dataCheck.forEach(element => {
                                                let desc = '';
                                                let amountUSD = 0;
                                                let amountIDR = 0;
                                                rate = element.rate;
                                                rateId = element.exRateId;
                                                paramR = 'paymentRequest/paymentRequest';
                                                ApiService.OperationPostById(paramR, element.id)
                                                    .then((response) => {
                                                        if (response.data.code === 200) {
                                                            let dataPR = response.data.data.paymentRequest;

                                                            dataPR.paymentRequestDetails.forEach(el => {
                                                                if (el.amountCrr === 0) {
                                                                    amountUSD = el.amount + amountUSD;
                                                                }
                                                                else {
                                                                    amountIDR = el.amount + amountIDR;
                                                                }

                                                                desc = desc + el.description + ' # ' + convertToNominal(el.amount) + ', ';
                                                            });

                                                            desc = desc + element.shipmentNo;

                                                            let data = {
                                                                "countryId": countryId,
                                                                "companyId": companyId,
                                                                "branchId": branchId,
                                                                "userId": userId,
                                                                "userCode": userCode,

                                                                "rowStatus": "ACT",
                                                                "memorialId": mId,
                                                                "sequence": Sequence,
                                                                "description": desc,
                                                                "dcNote": 'D',
                                                                "amountUSD": formatCurrency === 'USD' ? parseFloat(convertMask(amountUSD)) : parseFloat(convertMaskIDR(amountUSD)),
                                                                "amountIDR": formatCurrency === 'USD' ? parseFloat(convertMask(amountIDR)) : parseFloat(convertMaskIDR(amountIDR)),
                                                                "refId": element.id,
                                                                "refNo": element.prNo2,
                                                                "refShipmentId": element.shipmentId,
                                                                "refShipmentNo": element.shipmentNo,
                                                                "refRate": rate,
                                                                "refDetailId": 0,
                                                                "coaCode": props.AccountCode,
                                                                "coaName": props.AccountName,
                                                                "exRateId": rateId,

                                                                "companySource": 0,
                                                                "postingCode": false,
                                                                "jobOwnerId": props.PrincipleBy,

                                                                "idLama": 0,
                                                            }
                                                            newArr = [...newArr, data];
                                                            Sequence = Sequence + 1;

                                                            data = {
                                                                "countryId": countryId,
                                                                "companyId": companyId,
                                                                "branchId": branchId,
                                                                "userId": userId,
                                                                "userCode": userCode,

                                                                "rowStatus": "ACT",
                                                                "memorialId": mId,
                                                                "sequence": Sequence,
                                                                "description": accountName,
                                                                "dcNote": 'C',
                                                                "amountUSD": formatCurrency === 'USD' ? parseFloat(convertMask(amountUSD)) : parseFloat(convertMaskIDR(amountUSD)),
                                                                "amountIDR": formatCurrency === 'USD' ? parseFloat(convertMask(amountIDR)) : parseFloat(convertMaskIDR(amountIDR)),
                                                                "refId": element.id,
                                                                "refNo": element.prNo2,
                                                                "refShipmentId": element.shipmentId,
                                                                "refShipmentNo": element.shipmentNo,
                                                                "refRate": rate,
                                                                "refDetailId": 0,
                                                                "coaCode": accountCode,
                                                                "coaName": accountName,
                                                                "exRateId": rateId,

                                                                "companySource": 0,
                                                                "postingCode": false,
                                                                "jobOwnerId": props.PrincipleBy,

                                                                "idLama": 0,
                                                            }
                                                            newArr = [...newArr, data];
                                                            Sequence = Sequence + 1;

                                                            props.setMemorialDetailList(newArr);
                                                            props.CalculateTotal(newArr);

                                                            i = i - 1;
                                                            if (i === 0) {
                                                                props.setIsLoadingBackDrop(false);
                                                            }
                                                        }
                                                        else {
                                                            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                                                        }
                                                    })
                                                    .catch(function (error) {
                                                        console.error('error saat fetch', error);
                                                        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                                                    })
                                            });

                                            props.setMemorialDetailList(newArr);
                                            props.CalculateTotal(newArr);
                                        }
                                    }
                                })
                                .catch(function (error) {
                                    console.error('error saat fetch', error)
                                    showToast({ type: 'error', message: error.toString() });
                                })
                        }
                        else {
                            showToast({ type: 'error', message: 'Please Select At Least 1 Data From The List!' });
                        }
                    }
                    else {
                        let newArr = props.MemorialDetailList.slice();
                        newArr.forEach(el => {
                            if (el.sequence === Sequence) {
                                el.coaCode = props.AccountCode;
                                el.coaName = props.AccountName;
                                el.description = props.Description;
                                el.amountUSD = formatCurrency === 'USD' ? parseFloat(convertMask(props.AmountUSD)) : parseFloat(convertMaskIDR(props.AmountUSD));
                                el.amountIDR = formatCurrency === 'USD' ? parseFloat(convertMask(props.AmountIDR)) : parseFloat(convertMaskIDR(props.AmountIDR));
                            }
                        })

                        props.setMemorialDetailList(newArr);
                        props.CalculateTotal(newArr);
                    }

                    emptyState();
                    handleClose();
                    props.setDetailEdit(false);
                    props.setSelectedDetail({});
                }
                else {
                    if (props.Type === 1 && props.SettlementTo === 'P04') // Get Detail for Memorial *General Type* Case from Adjustment AP
                    {
                        if (!props.detailEdit) {
                            props.setIsLoadingBackDrop(true);
                            let i = dataCheck.length;
                            if (dataCheck.length > 0) {
                                let newArr = props.MemorialDetailList;
                                dataCheck.forEach(element => {
                                    rateId = 0;
                                    rate = element.rate;
                                    let params = [
                                        { 'attr': 'EntryFor', 'value': 'JT-' + element.jobId }
                                    ];
                                    ApiService.DynamicPostByPage("regChartOfAccountEntry/regChartOfAccountEntry", 'SEARCH', 1, 50, params, [])
                                        .then((response) => {
                                            if (response.data.code === 200) {
                                                if (response.data.data.chartOfAccountEntry[0] !== undefined) {
                                                    let accountCode = response.data.data.chartOfAccountEntry[0].accountCode;
                                                    let accountName = response.data.data.chartOfAccountEntry[0].accountTitle;

                                                    let data = {
                                                        "countryId": countryId,
                                                        "companyId": companyId,
                                                        "branchId": branchId,
                                                        "userId": userId,
                                                        "userCode": userCode,

                                                        "rowStatus": "ACT",
                                                        "memorialId": mId,
                                                        "sequence": Sequence,
                                                        "description": props.Description,
                                                        "dcNote": 'D',
                                                        "amountUSD": formatCurrency === 'USD' ? parseFloat(convertMask(element.amountUsd)) : parseFloat(convertMaskIDR(element.amountUsd)),
                                                        "amountIDR": formatCurrency === 'USD' ? parseFloat(convertMask(element.amountIdr)) : parseFloat(convertMaskIDR(element.amountIdr)),
                                                        "refId": 0,
                                                        "refNo": '',
                                                        "refShipmentId": element.shipmentOrderId,
                                                        "refShipmentNo": element.shipmentNo,
                                                        "refRate": rate,
                                                        "refDetailId": element.eplDetailId,
                                                        "coaCode": props.AccountCode,
                                                        "coaName": props.AccountName,
                                                        "exRateId": rateId,

                                                        "companySource": 0,
                                                        "postingCode": false,
                                                        "jobOwnerId": props.PrincipleBy,

                                                        "idLama": 0,
                                                    }
                                                    newArr = [...newArr, data];
                                                    Sequence = Sequence + 1;

                                                    data = {
                                                        "countryId": countryId,
                                                        "companyId": companyId,
                                                        "branchId": branchId,
                                                        "userId": userId,
                                                        "userCode": userCode,

                                                        "rowStatus": "ACT",
                                                        "memorialId": mId,
                                                        "sequence": Sequence,
                                                        "description": accountName,
                                                        "dcNote": 'C',
                                                        "amountUSD": formatCurrency === 'USD' ? parseFloat(convertMask(element.amountUsd)) : parseFloat(convertMaskIDR(element.amountUsd)),
                                                        "amountIDR": formatCurrency === 'USD' ? parseFloat(convertMask(element.amountIdr)) : parseFloat(convertMaskIDR(element.amountIdr)),
                                                        "refId": 0,
                                                        "refNo": '',
                                                        "refShipmentId": element.shipmentId,
                                                        "refShipmentNo": element.shipmentNo,
                                                        "refRate": rate,
                                                        "refDetailId": element.eplDetailId,
                                                        "coaCode": accountCode,
                                                        "coaName": accountName,
                                                        "exRateId": rateId,

                                                        "companySource": 0,
                                                        "postingCode": false,
                                                        "jobOwnerId": props.PrincipleBy,

                                                        "idLama": 0,
                                                    }
                                                    newArr = [...newArr, data];
                                                    Sequence = Sequence + 1;

                                                    props.setMemorialDetailList(newArr);
                                                    props.CalculateTotal(newArr);
                                                }

                                                if (i === 0) {
                                                    props.setIsLoadingBackDrop(false);
                                                }
                                            }
                                            else {
                                                props.setIsLoadingBackDrop(false);
                                                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                                            }
                                        })
                                        .catch(function (error) {
                                            props.setIsLoadingBackDrop(false);
                                            console.error('error saat fetch', error);
                                            showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                                        })

                                    i = i - 1;
                                });

                                props.setMemorialDetailList(newArr);
                                props.CalculateTotal(newArr);
                            }
                            else {
                                showToast({ type: 'error', message: 'Please Select At Least 1 Data From The List!' });
                            }
                        }
                        else {
                            let newArr = props.MemorialDetailList.slice();
                            newArr.forEach(el => {
                                if (el.sequence === Sequence) {
                                    el.coaCode = props.AccountCode;
                                    el.coaName = props.AccountName;
                                    el.description = props.Description;
                                    el.amountUSD = formatCurrency === 'USD' ? parseFloat(convertMask(props.AmountUSD)) : parseFloat(convertMaskIDR(props.AmountUSD));
                                    el.amountIDR = formatCurrency === 'USD' ? parseFloat(convertMask(props.AmountIDR)) : parseFloat(convertMaskIDR(props.AmountIDR));
                                }
                            })

                            props.setMemorialDetailList(newArr);
                            props.CalculateTotal(newArr);
                        }

                        emptyState();
                        handleClose();
                        props.setDetailEdit(false);
                        props.setSelectedDetail({});
                    }
                    else {
                        if (props.RefExRateId === 0) {
                            rateId = props.ExRateId;
                            rate = props.Rate;
                        }
                        else {
                            rateId = props.RefExRateId;
                            rate = props.RefRate;
                        }

                        let data = {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "userId": userId,
                            "userCode": userCode,

                            "rowStatus": "ACT",
                            "memorialId": mId,
                            "sequence": Sequence,
                            "description": props.Description,
                            "dcNote": props.DCNote,
                            "amountUSD": formatCurrency === 'USD' ? parseFloat(convertMask(props.AmountUSD)) : parseFloat(convertMaskIDR(props.AmountUSD)),
                            "amountIDR": formatCurrency === 'USD' ? parseFloat(convertMask(props.AmountIDR)) : parseFloat(convertMaskIDR(props.AmountIDR)),
                            "refId": props.RefId,
                            "refNo": props.RefNo,
                            "refShipmentId": props.ShipmentId,
                            "refShipmentNo": props.ShipmentNo,
                            "refRate": rate,
                            "refDetailId": props.RefDetailId,
                            "coaCode": props.AccountCode,
                            "coaName": props.AccountName,
                            "exRateId": rateId,

                            "companySource": 0,
                            "postingCode": false,
                            "jobOwnerId": props.PrincipleBy,

                            "idLama": 0,
                        }

                        if (!props.detailEdit) {
                            let newArr = props.MemorialDetailList;
                            newArr = [...newArr, data];
                            props.setMemorialDetailList(newArr);
                            props.CalculateTotal(newArr);
                        }
                        else {
                            const newArr = props.MemorialDetailList.slice();
                            newArr.forEach(el => {
                                if (el.sequence === data.sequence) {
                                    el.refId = data.refId;
                                    el.refNo = data.refNo;
                                    el.shipmentOrderId = data.shipmentOrderId;
                                    el.shipmentNo = data.shipmentNo;
                                    el.exRateId = data.exRateId;
                                    el.refRate = data.refRate;
                                    el.refDetailId = data.refDetailId;

                                    el.coaCode = data.coaCode;
                                    el.coaName = data.coaName;
                                    el.description = data.description;
                                    el.dcNote = data.dcNote;
                                    el.amountUSD = data.amountUSD;
                                    el.amountIDR = data.amountIDR;
                                }
                            })
                            props.setMemorialDetailList(newArr);
                            props.CalculateTotal(newArr);
                        }

                        emptyState();
                        handleClose();
                        props.setDetailEdit(false);
                        props.setSelectedDetail({});
                    }
                }
            }
        }
    }

    const getDataRef = (pageNumber, pageSize, filters) => {
        setIsLoadingR(true);
        let params = [];
        if (props.Type === 1) {
            if (props.SettlementTo === 'P05') // Get Detail for Memorial *General Type* Case from Invoice Agent
            {
                paramR = 'invoice/invoice';
                params = [
                    { 'attr': 'customerTypeId', 'value': '1' },
                    { 'attr': 'isPosted', 'value': 'true' },
                    { 'attr': 'paid', 'value': 'false' },
                ];
                ApiService.OperationDynamicPostByPage(paramR, 'SEARCH', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataR({})
                            setDataR(response.data.data.invoices);

                            let temp = response.data.data.invoices
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapR(indexed)
                            setColumnDataR(response.data.data.columns.headerColumns)
                            setTotalRowsR(response.data.totalRowCount)

                            setIsLoadingR(false)
                        }
                        setIsLoadingR(false)
                    })
                    .catch(function (error) {
                        setIsLoadingR(false)
                        console.error('error saat fetch', error)
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
            else if (props.SettlementTo === 'P04') {
                paramR = 'memorial/AdjustmentAP';
                params = [
                    { 'attr': 'customerId', 'value': '' + props.CustomerId },
                ];
                ApiService.ListFinAccPostByPage(paramR, 'PAGE', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataR({})
                            setDataR(response.data.data.adjustmentAP);

                            let temp = response.data.data.adjustmentAP
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapR(indexed)
                            setColumnDataR(response.data.data.columns)
                            setTotalRowsR(response.data.totalRowCount)

                            setIsLoadingR(false)
                        }
                        else if (response.data.code === 500) {
                            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });

                            setIsLoadingR(false)
                        }
                        setIsLoadingR(false)
                    })
                    .catch(function (error) {
                        setIsLoadingR(false)
                        console.error('error saat fetch', error)
                        showToast({ type: 'error', message: error.toString() });
                    })
            }
        }
        else if (props.Type === 2) // Get Detail for Memorial *Cost Type* Case from Payment Request
        {
            let paramR = 'paymentRequest/paymentRequest';
            params = [
                { 'attr': 'customerTypeId', 'value': '' + props.CustomerTo },
                { 'attr': 'isPosted', 'value': 'true' },
                { 'attr': 'customerId', 'value': '' + props.CustomerId },
            ];
            ApiService.OperationDynamicPostByPage(paramR, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataR({})
                        setDataR(response.data.data.paymentRequests);

                        let temp = response.data.data.paymentRequests
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapR(indexed)
                        setColumnDataR(response.data.data.columns.headerColumns)
                        setTotalRowsR(response.data.totalRowCount)

                        setIsLoadingR(false)
                    }
                    setIsLoadingR(false)
                })
                .catch(function (error) {
                    setIsLoadingR(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else {

        }
    }

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const mainContainer = (
        <>
            {
                props.Type === 2 || props.Type === 3 || (props.Type === 1 && props.SettlementTo !== 'P01') ?
                    <>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label='Reference No'
                                    value={props.RefNo}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => searchData('search')} hidden={props.verifyAcc ? true : props.Type === 3 ? true : false}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label='Ref Shipment No'
                                    value={props.ShipmentNo}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label='Ref Rate'
                                    value={props.RefRate}
                                    disabled
                                    fullWidth
                                    textAlign='right'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </>
                    :
                    <></>
            }

            <Stack spacing={'24px'} direction="row" sx={{ marginTop: props.Type === 2 || props.Type === 3 || (props.Type === 1 && props.SettlementTo !== 'P01') ? '16px' : '0px' }}>
                <Input
                    label='Account Code'
                    value={props.AccountCode}
                    disabled
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '50%',
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true
                    }}
                />

                <Input
                    value={props.AccountName}
                    disabled
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                    inputBaseProps={{
                        sx: {
                            marginTop: '23px !important',
                        },
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => searchAccount('delete')}
                                    disabled={props.isNone}
                                    hidden={props.verifyAcc ? true : props.AccountName ? false : true}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => searchAccount('search')}
                                    hidden={props.verifyAcc}
                                >
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>

            <Input
                label={'Description'}
                value={props.Description}
                onChange={(e) => props.setDescription(e.target.value)}
                multiline
                fullWidth
                disabled={props.verifyAcc}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />

            <div className='mt-1'>
                <Grid
                    container
                    spacing={'24px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Transaction'}
                            fullWidth={true}
                            placeholder={'Select Transaction'}
                            value={props.DCNote}
                            onChange={(e) => props.setDCNote(e.target.value)}
                            disabled={props.verifyAcc ? true : props.isEdit ? true : props.Type === 2 ? true : props.Type === 1 && props.SettlementTo === 'P04' ? true : false}
                            formControlProps={{
                                sx: {
                                    marginTop: '16px !important'
                                }
                            }}
                        >
                            {
                                TransactionTypeData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="Amount USD"
                            textAlign='right'
                            fullWidth
                            value={props.AmountUSD}
                            disabled={props.verifyAcc ? true : props.isDisabled ? true : props.Type === 2 ? true : props.Type === 1 && props.SettlementTo === 'P04' ? true : false}
                            onChange={(e) => props.setAmountUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="Amount IDR"
                            textAlign='right'
                            fullWidth
                            value={props.AmountIDR}
                            disabled={props.verifyAcc ? true : props.isDisabled ? true : props.Type === 2 ? true : props.Type === 1 && props.SettlementTo === 'P04' ? true : false}
                            onChange={(e) => props.setAmountIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'md'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.verifyAcc)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                        setDescription={props.setDescription}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataReference
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                        getData={getDataRef}
                        isLoading={isLoadingR}
                        totalRows={totalRowsR}
                        setTotalRows={setTotalRowsR}
                        SelectedData={SelectedDataR}
                        setSelectedData={setSelectedDataR}
                        data={dataR}
                        columnData={columnDataR}
                        dataMap={dataMapR}
                        dataCheck={dataCheck}
                        setDataCheck={setDataCheck}
                        setRefId={props.setRefId}
                        setRefNo={props.setRefNo}
                        setShipmentId={props.setShipmentId}
                        setShipmentNo={props.setShipmentNo}
                        setAmountUSD={props.setAmountUSD}
                        setAmountIDR={props.setAmountIDR}
                        setRefExRateId={props.setRefExRateId}
                        setRefRate={props.setRefRate}
                        setRefDetailId={props.setRefDetailId}
                        Type={props.Type}
                        SettlementTo={props.SettlementTo}
                        CustomerTo={props.CustomerTo}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                        setDescription={props.setDescription}
                    />
                )
            }
        </>
    )
}

export default ModalMemorialDetail;