import { showToast } from "../components/ui";
import ApiService from "../services/ApiService";

export const defaultDate = '1785-12-06T10:51:29.426Z';
export const nullDate = '1-01-01';
export const currentDate = new Date();

export function getDefaultDateFormat(date) {
    return new Date(date).toLocaleDateString("sv-se").split('T')[0];
}

export function setDateFormat(date, setDate, isDefault = false, isToday = false) {
    if (date === '') {
        setDate(getDefaultDateFormat(defaultDate));
    }
    else {
        if (getDefaultDateFormat(date) !== getDefaultDateFormat(defaultDate) && getDefaultDateFormat(date) !== nullDate) {
            setDate(getDefaultDateFormat(date));
        }
        else {
            if (!isDefault && !isToday) {
                setDate('');
            }
            else {
                if (isDefault) {
                    setDate(getDefaultDateFormat(defaultDate));
                }

                if (isToday) {
                    setDate(getDefaultDateFormat(new Date()));
                }
            }
        }
    }
}

export function updateDateFormat(date, isDefault = false, isToday = false) {
    if (date === '') {
        return getDefaultDateFormat(defaultDate);
    }
    else {
        if (getDefaultDateFormat(date) !== getDefaultDateFormat(defaultDate) && getDefaultDateFormat(date) !== nullDate) {
            return getDefaultDateFormat(date);
        }
        else {
            if (!isDefault && !isToday) {
                return '';
            }
            else {
                if (isDefault) {
                    return getDefaultDateFormat(defaultDate);
                }

                if (isToday) {
                    return getDefaultDateFormat(new Date());
                }
            }
        }
    }
}

export function getBackDatePeriod() {
    return new Promise((resolve, reject) => {
        const param = 'regClosingPeriod/regClosingPeriod/BackDate';
        ApiService.PostByPageBackDatePeriod(param)
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data.closingPeriod;
                    let yearPeriod = data.yearPeriod;
                    let period = data.period;

                    if (new Date(data.bdActiveDate) < new Date()) {
                        period = parseInt(period) + 1;
                    }

                    const backDate = getDefaultDateFormat(new Date(`${yearPeriod}-${period}-01`));
                    resolve(backDate);
                } else {
                    showToast({ type: 'error', message: response.data });
                    reject(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
                showToast({ type: 'error', message: error });
                reject(error);
            });
    });
}