import { Box, Grid, IconButton, MenuItem, Stack, InputAdornment } from '@mui/material'
import React, { useState } from 'react'
import ModalListCustomer from '../Modal/ModalListCustomer';
import ApiService from '../../../../../services/ApiService';
import { Icon, Input, Selection } from '../../../../../components/ui';

const ReceiptPPh23Header = (props) => {
  const date = new Date();
  const [isModalCustomer, setIsModalCustomer] = useState(false);
  const [titleModalCustomer, setTitleModalCustomer] = useState('');

  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);
  const [dataCustomer, setDataCustomer] = useState([]);
  const [dataMapCustomer, setDataMapCustomer] = useState([])
  const [columnDataCustomer, setColumnDataCustomer] = useState([])
  const [SelectedDataCustomer, setSelectedDataCustomer] = useState({});
  const [totalRowsCustomer, setTotalRowsCustomer] = useState(50)
  const paramCustomer = "regContact/regContact";

  const customerTypeList = [
    { id: 0, code: 'SH', name: 'Shipper' },
    { id: 1, code: 'CO', name: 'Consignee' },
    { id: 2, code: 'AG', name: 'Agent' },
  ]

  const getDataCustomer = (pageNumber, pageSize, filters) => {
    setIsLoadingCustomer(true);
    let type = 2
    if (props.customerType === 'AG') {
      type = 1
    }
    else if (props.customerType === 'CO') {
      type = 3
    }
    else {
      type = 2;
    }

    ApiService.ContactPostByPage(paramCustomer, type, pageNumber, pageSize, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataCustomer({})
          setDataCustomer(response.data.data.contact);

          let temp = response.data.data.contact
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMapCustomer(indexed)
          setColumnDataCustomer(response.data.data.columns)
          setTotalRowsCustomer(response.data.totalRowCount)

          setIsLoadingCustomer(false)
        }
        setIsLoadingCustomer(false)
      })
      .catch(function (error) {
        setIsLoadingCustomer(false)
        console.error('error saat fetch', error)
      })
  }

  const searchCustomer = (data) => {
    if (data === 'search') {
      if (isModalCustomer === false) {
        setIsModalCustomer(true);
        let cst = 'Shipper'
        if (props.customerType === 3) {
          cst = 'Consignee'
        }
        if (props.customerType === 1) {
          cst = 'Agent'
        }
        setTitleModalCustomer(cst)
        getDataCustomer(1, 50)
      } else {
        setIsModalCustomer(false);
      }
    }
    else if (data === 'delete') {
      props.setCustomerId(0);
      props.setCustomerCode('');
      props.setCustomerName('');

      if (props.isAdjustment) {
        props.SumTotal([]);
        props.setDataCheck([]);
        localStorage.setItem('customerId', 0);
        props.getDataOR(1, 50);
      }
    }
  }

  const getMonths = () => {
    const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
    return Array.from({ length: 12 }, (_, i) => ({
      name: formatter.format(new Date(2024, i)),
      value: i + 1
    }));
  };

  const yearList = () => {
    const arrYear = [];
    const currentYear = date.getFullYear();
    const endYear = currentYear;
    let startYear = currentYear - 5;
    while (startYear <= endYear) {
      arrYear.push(startYear++)
    }

    return arrYear
  }

  function handleSelectJobOwner(value) {
    props.setJobOwnerId(value)
    let data = props.dataJobOwner.find((data) => data.id === value)
    console.log(data)
    props.setJobOwnerCode(data.code)
    props.setJobOwnerName(data.name)
  }

  const customerSection = (isHidden) => {
    return (
      <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }} hidden={isHidden}>
        <Input
          label='Customer'
          value={props.customerCode}
          formControlProps={{
            sx: {
              marginTop: '24px'
            }
          }}
          disabled
          readOnly
        />

        <Input
          variant="standard"
          InputLabelProps={{ shrink: true }}
          fullWidth
          disabled
          value={props.customerName}
          type='text'
          label={''}
          formControlProps={{
            sx: {
              width: '100%',
            }
          }}
          inputBaseProps={{
            sx: {
              marginTop: '23px !important',
            },
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => searchCustomer('delete')}
                  hidden={props.customerName && !props.isEdit ? false : true}
                >
                  <Icon
                    iconName={'close'}
                    svgIconProps={{
                      fontSize: 'small',
                      htmlColor: '#0087C2',
                      sx: {
                        height: '12px',
                        width: '12px'
                      }
                    }}
                  />
                </IconButton>

                <IconButton
                  onClick={() => searchCustomer('search')}
                  hidden={props.isEdit}
                >
                  <Icon
                    iconName={'search'}
                    svgIconProps={{
                      fontSize: 'small',
                      htmlColor: '#0087C2',
                      sx: {
                        height: '12px',
                        width: '12px'
                      }
                    }}
                  />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Stack>
    )
  }

  return (
    <div>
      <form>
        <Box>
          <Box>
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Input
                  label="Infoss Number"
                  value={props.infossNo}
                  onChange={(e) => props.setInfossNo(e.target.value)}
                  hidden={props.isAdjustment}
                  fullWidth
                  formControlProps={{
                    sx: {
                      width: '100%',
                      marginBottom: '16px !important'
                    }
                  }}
                  disabled={props.isApprovedAcc}
                  message={props.infossNo !== '' ? '' : 'This Field is Required'}
                />
              </Grid>
            </Grid>
            
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Input
                  label="Reference"
                  value={props.reference}
                  disabled
                  hidden={props.isAdjustment ? true : !props.isEdit}
                  fullWidth
                  formControlProps={{
                    sx: {
                      width: '100%',
                    }
                  }}
                />

                <Selection
                  id='0'
                  label={'Customer Type'}
                  fullWidth={true}
                  placeholder={'Select Customer Type'}
                  value={props.customerType}
                  onChange={(e) => props.setCustomerType(e.target.value)}
                  disabled={props.isEdit}
                  formControlProps={{
                    sx: {
                      marginTop: props.isEdit ? '16px !important' : '0px'
                    }
                  }}
                >
                  {
                    customerTypeList.map((data) => (
                      <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                        {data.name}
                      </MenuItem>
                    ))
                  }
                </Selection>

                <Input
                  label="Bukti Potong"
                  value={props.buktiPotong}
                  onChange={(e) => props.setBuktiPotong(e.target.value)}
                  fullWidth
                  disabled={props.isApprovedAcc}
                  hidden={props.isAdjustment ? true : false}
                  formControlProps={{
                    sx: {
                      width: '100%',
                      marginTop: '16px !important'
                    }
                  }}
                />

                <Stack spacing={'16px'} direction="row" hidden={props.isAdjustment ? true : false}>
                  <Selection
                    id='0'
                    label={'Bulan'}
                    fullWidth={true}
                    placeholder={'Select Bulan'}
                    value={props.masaPajak}
                    onChange={(e) => props.setMasaPajak(e.target.value)}
                    disabled={props.isEdit}
                    hidden={props.isAdjustment ? true : false}
                    formControlProps={{
                      sx: {
                        marginTop: '16px !important'
                      }
                    }}
                  >
                    {
                      getMonths().map((elm) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={elm.value} value={elm.value}>{elm.name}</MenuItem>
                      ))
                    }
                  </Selection>

                  <Selection
                    id='0'
                    label={'Tahun'}
                    fullWidth={true}
                    placeholder={'Select Tahun'}
                    value={props.tahunPajak}
                    onChange={(e) => props.setTahunPajak(e.target.value)}
                    disabled={props.isEdit}
                    hidden={props.isAdjustment ? true : false}
                    formControlProps={{
                      sx: {
                        marginTop: '16px !important'
                      }
                    }}
                  >
                    {
                      yearList().map((elm) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={elm} value={elm}>{elm}</MenuItem>
                      ))
                    }
                  </Selection>
                </Stack>

                {customerSection(!props.isAdjustment)}
              </Grid>

              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Stack spacing={'16px'} direction="row">
                  <Selection
                    id='0'
                    label={'Principle By'}
                    fullWidth={true}
                    placeholder={'Select Principle By'}
                    value={props.jobOwnerId}
                    onChange={(e) => handleSelectJobOwner(e.target.value)}
                    disabled={props.isEdit}
                    formControlProps={{
                      sx: {
                        // marginTop: '16px !important'
                      }
                    }}
                  >
                    {
                      props.dataJobOwner.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.id}>
                          {data.code}-{data.name}
                        </MenuItem>
                      ))
                    }
                  </Selection>

                  <Input
                    label="Receive On"
                    value={props.receivedOn}
                    onChange={(e) => props.setReceivedOn(e.target.value)}
                    type={'date'}
                    disabled={props.isApprovedAcc}
                    hidden={props.isAdjustment ? true : false}
                    formControlProps={{
                      sx: {
                        width: '100%',
                        // marginTop: '16px !important'
                      }
                    }}
                  />
                </Stack>

                {customerSection(props.isAdjustment)}

                <Input
                  label={'Remarks'}
                  value={props.remarks}
                  onChange={(e) => props.setRemarks(e.target.value)}
                  multiline
                  disabled={props.isApprovedAcc}
                  hidden={props.isAdjustment ? true : false}
                  fullWidth
                  formControlProps={{
                    sx: {
                      width: '100%',
                      marginTop: '16px !important'
                    }
                  }}
                  inputBaseProps={{
                    rows: 5,
                    sx: {
                      padding: '8px',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>

      {
        isModalCustomer && (
          <ModalListCustomer
            setCustomerId={props.setCustomerId}
            setCustomerCode={props.setCustomerCode}
            setCustomerName={props.setCustomerName}

            isModal={isModalCustomer}
            handleClick={searchCustomer}
            titleModal={titleModalCustomer}
            setIsModal={setIsModalCustomer}

            isLoading={isLoadingCustomer}
            data={dataCustomer}
            dataMap={dataMapCustomer}
            columnData={columnDataCustomer}
            SelectedData={SelectedDataCustomer}
            totalRows={totalRowsCustomer}
            setSelectedData={setSelectedDataCustomer}
            getData={getDataCustomer}
            getDataOR={props.getDataOR}
            isAdjustment={props.isAdjustment}
          />
        )
      }
    </div>
  )
}

export default ReceiptPPh23Header