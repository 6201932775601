import { IconButton, Stack, InputAdornment, Typography } from '@mui/material'
import React from 'react'
import { Icon, Input, showToast } from '../../../../components/ui'

const TabConsignee = (props) => {
    const cariConsignee = (data) => {
        if (data === 'search') {
            if (props.jobServiceType === 'Export' || props.jobServiceType === 'Domestic') {
                props.setTipe(3);
                props.setTitleModal('Consignee');
                localStorage.setItem("ContactType", 3);
                props.handleClick();
            } else {
                showToast({ type: 'error', message: "Please Select Job Type First!" });
            }
        }
        else {
            props.setIdConsignee(0);
            props.setConsigneeName('');
            props.setConsigneeAddress('');
        }
    }

    return (
        <>
            <Typography
                sx={{
                    color: 'var(--Blue-Primary-1, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal',
                }}
            >
                Consignee
            </Typography>

            <Stack spacing={'16px'} direction="row" className='mt-2'>
                <Input
                    label={'Code'}
                    value={props.idConsignee}
                    formControlProps={{
                        sx: {
                            marginTop: '24px'
                        }
                    }}
                    disabled
                    inputBaseProps={{
                        readOnly: true,
                    }}
                />

                <Input
                    label="Name"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    value={props.ConsigneeName}
                    type='text'
                    disabled
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '24px'
                        }
                    }}
                    inputBaseProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => cariConsignee('delete')}
                                    disabled={props.isApproved}
                                    hidden={props.ConsigneeName && !props.isApproved ? false : true}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => cariConsignee('search')}
                                    disabled={props.isApproved}
                                    hidden={props.isApproved}
                                >
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>

            <Input
                label={'Address'}
                value={props.ConsigneeAddress}
                multiline
                fullWidth
                disabled
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '24px'
                    }
                }}
                inputBaseProps={{
                    readOnly: true,
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />
        </>
    )
}

export default TabConsignee