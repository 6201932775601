import React from 'react';
import { Icon, PopUp } from '../../../../../components/ui';
import { Stack, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const ModalCostMemorialDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const mainContainer = (
        <>
            <TableContainer sx={{ maxHeight: 235, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
                <Table sx={{ minWidth: 650 }} stickyHeader>
                    <TableHead
                        sx={{
                            '& th:first-of-type': {
                                borderRadius: '12px 0 0'
                            },
                            '& th:last-child': {
                                borderRadius: '0 12px 0 0'
                            }
                        }}
                    >
                        <TableRow>
                            <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Account Code</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Description</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>DC Note</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount USD</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount IDR</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Ref. No</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Ref. Shipment</StyledTableCell>
                            <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Ref. Rate</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.MemorialCostDetail.length > 0 ?
                                props.MemorialCostDetail.map((el) => {
                                    return (
                                        <TableRow
                                            key={el.sequence}
                                            hover={true}
                                        >
                                            <StyledTableBodyCell sx={{ textAlign: 'center', }}>
                                                <Tooltip title={el.coaName}>
                                                    <span>{el.coaCode}</span>
                                                </Tooltip>
                                            </StyledTableBodyCell>
                                            <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.dcNote === 'D' ? 'Debit' : 'Credit'}</StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountUSD)}</StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountIDR)}</StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.refNo !== '' ? el.refNo : '-'}</StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.refShipmentNo !== '' ? el.refShipmentNo : '-'}</StyledTableBodyCell>
                                            <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.refRate)}</StyledTableBodyCell>
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                    <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '12px',
                                                fontstyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal'
                                            }}
                                        >
                                            Data Empty
                                        </Typography>
                                    </StyledTableBodyCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'documents'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Cost Memorial'}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'md'}
                fullWidth={true}
                topButton={true}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        <Stack direction={'row'} sx={{ alignItems: 'right', justifyContent: 'right' }}>
                            {ButtonComponent.BackButton(props.handleClick, true)}
                        </Stack>
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalCostMemorialDetail;