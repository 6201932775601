import React, { useState, useEffect, useRef } from 'react';
import ApiService from '../../../services/ApiService';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Backdrop } from '@mui/material';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import TableX from '../../../components/Tables/TableX';
import CreateEditRegAccess from './CreateEditRegAccess';
import ButtonRegAccess from './Buttons/ButtonRegAccess';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';

const RegAccessList = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const history = useNavigate();
  const param = 'permission/permission';
  const paramA = 'access/access';
  const paramR = 'regAccess/regAccess';
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);

  const [isLoadingM, setIsLoadingM] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [DataAccess, setDataAccess] = useState({});
  const [DataRegAccess, setDataRegAccess] = useState([]);
  const [Title, setTitle] = useState('');
  const [SubTitle, setSubTitle] = useState('');

  const paramRegUserAccess = "regUserAccess/regUserAccess";
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Reg Access', link: '/Majura/RegAccess', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  let countRefresh = 0;
  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    if (countRefresh === 0) {
      document.title = 'Reg Access - ' + JSON.parse(localStorage.getItem('branchName'))
      ApiService.refreshToken();
      countRefresh++;

      getDataAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageNumber, pageSize, filters) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    ApiService.IdentityPostByPage(param, 'PAGE', pageNumber, pageSize, params, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.permission
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns)
          setTotalRows(response.data.totalRowCount)

          setIsLoading(false)
          setIsLoadingBackDrop(false)
        }
        else {
          setIsLoadingBackDrop(false)
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
        }
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsLoadingBackDrop(false)
        console.error('error saat fetch', error);
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
      })
  }

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'IRACC')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        {
          attr: "userId",
          value: "" + JSON.parse(localStorage.getItem("userId"))
        },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];
      ApiService.IdentityPostByPage(
        paramRegUserAccess,
        "PAGE",
        1,
        100,
        params,
        []
      )
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getData(1, 50)
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Majura');
        });
    }
    else {
      history('/Majura');
    }
  };

  const getAccess = (pageNumber, pageSize, filters) => {
    let params = [];
    ApiService.IdentityPostByPage(paramA, 'PAGE', pageNumber, pageSize, params, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setDataAccess(response.data.data.access);
        }
      })
      .catch(function (error) {
        console.error('error saat fetch', error);
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
      })
  }

  const getDataRegAccess = (id) => {
    setIsLoadingM(true);
    ApiService.IdentityPostById(paramR, id)
      .then((response) => {
        if (response.data.code === 200) {
          setDataRegAccess(response.data.data.access);
        }
        setIsLoadingM(false);
      })
      .catch(function (error) {
        console.error('error saat fetch', error);
        setIsLoadingM(false);
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
      })
  }

  const handleReset = () => {
    emptyState();
    getData(1, 50, []);
  };

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setGoTo(1);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setStateX({});
    setSortArr([]);
  };

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data' })
    emptyState();
    getData(1, 50, []);
  };

  function Add() {
    if (SelectedData.id === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    }
    else {
      let check = 0;
      for (let i = 0; i < DataRegUserAccess.length; i++) {
        if (DataRegUserAccess[i].accessCode === 'REI') {
          check = 1;
        }
      }

      if (check === 1) {
        setTitleModal('Edit Access - ' + SelectedData.categoryType + ' ' + SelectedData.name);
        setTitle(SelectedData.name);
        setSubTitle(SelectedData.categoryType);
        getDataRegAccess(SelectedData.id);
        handleClick();
      }
      else {
        showToast({ type: 'error', message: 'You Don\'t Have Access To This Menu' })
      }
    }
  }

  function CellDoubleClick() {
    Add();
  };

  const handleClick = () => {
    if (isModal === false) {
      setIsModal(true);
      getAccess(1, 100);
    }
    else {
      setIsModal(false);
    }
  };

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter);
  }

  const ButtonComponents = () => {
    return (
      <ButtonRegAccess
        Add={Add}
        ReloadData={ReloadData}
        DataRegUserAccess={DataRegUserAccess}
      />
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            btnPageRef={btnPageRef}
            title={'Reg Access'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
            <div ref={btnTableRef}>
              {
                ButtonComponent.RefreshButton(
                  sortArr,
                  rowsCount,
                  columnData,
                  setGoTo,
                  handleReset,
                  setFilterBy,
                  filterBy,
                  setFilter,
                  setNumPage,
                  setRowsCount,
                  props.getData,
                  stateX,
                  setStateX
                )
              }
            </div>

            <div
              className="rounded-10 table-responsive"
              style={{
                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                marginTop: '16px',
                padding: '2px 16px',
                borderRadius: '12px',
                background: '#F2F2F2'
              }}
            >
              {
                isLoading ?
                  <LoadingSpinner />
                  :
                  <>
                    <div className='mt-1'>
                      <TableX
                        uniqueKey={'id'}
                        goTo={goTo}
                        rowsCount={rowsCount}
                        setNumPage={setNumPage}
                        setRowsCount={setRowsCount}
                        getData={getData}
                        columnData={columnData}
                        dataMap={dataMap}
                        SelectedData={SelectedData}
                        setSelectedData={setSelectedData}
                        setFilter={setFilter}
                        setFilterBy={setFilterBy}
                        CellDoubleClick={CellDoubleClick}
                        stateX={stateX}
                        setStateX={setStateX}
                        sortArr={sortArr}
                        setSortArr={setSortArr}
                        setGoTo={setGoTo}
                      />
                    </div>
                  </>
              }
            </div>

            <div style={{ marginTop: '16px' }}>
              <Paginate
                page={numPage}
                limit={rowsCount}
                totalData={totalRows}
                onChange={onChangePaging}
                onChangeLimit={onChangeLimit}
              />
            </div>
          </Grid>
        </Grid>

        {
          isModal && (
            <CreateEditRegAccess
              isModal={isModal}
              setIsModal={setIsModal}
              handleClick={handleClick}
              titleModal={titleModal}
              SelectedData={SelectedData}
              isLoading={isLoadingM}

              DataAccess={DataAccess}
              DataRegAccess={DataRegAccess}
              setDataRegAccess={setDataRegAccess}
              Title={Title}
              SubTitle={SubTitle}
            />
          )
        }

        {
          isLoadingBackDrop && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoadingBackDrop}
            >
              <LoadingSpinner />
            </Backdrop>
          )
        }
      </Box>
    </>
  )
})

export default RegAccessList
