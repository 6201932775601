import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
// import { Button, Typography } from '@mui/material';
// import { Icon } from '../../../../components/ui';

const ButtonRestorePostingClosingPage = ({
    AddNew,
    LockUserAccess,
    isLock,
    isDisabledLock
}) => {

    return (
        <div className="row">
            <div className="col-12">
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.AddButton(AddNew, 'New')}

                    {/* Will be Commented Until Further Notice
                        <Button
                        size='small'
                        variant="outlined"
                        className='d-flex align-items-center'
                        sx={{
                            padding: '3px 6px',
                            gap: '4px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '18px !important',
                            minHeight: '28px !important',
                            borderRadius: '8px',
                            background: '',
                            '&:hover': {
                                backgroundColor: '',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            }
                        }}
                        onClick={() => LockUserAccess()}
                        disabled={isDisabledLock}
                    >
                        <Icon
                            svgIconProps={{
                                htmlColor: isDisabledLock? 'inherit' : isLock? '#BE2957' : '#168444',
                                sx: {
                                    height: '12px',
                                    width: '12px',
                                }
                            }}
                            iconName={!isLock? 'lock' : 'unlock'}
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                marginTop: '1px !important',
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                color: isDisabledLock? 'inherit' : !isLock? '#BE2957' : '#168444',
                            }}
                        >
                            {!isLock? 'Lock User Access' : 'Re-Open User Access'}
                        </Typography>
                    </Button> */}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonRestorePostingClosingPage;