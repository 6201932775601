import React, { forwardRef, useEffect, useState } from 'react'
import { Backdrop, Box, Card, CardContent, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService from '../../../../services/ApiService';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Input, showToast } from '../../../../components/ui';

const SummaryGeneralJournalReport = forwardRef(() => {
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const companyName = JSON.parse(localStorage.getItem("companyName"))
  const date = new Date()

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Summary General Journal Report', link: '/Majura/SummaryGeneralJournalReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Summary General Journal Report - ' + JSON.parse(localStorage.getItem('branchName'));
    setTimeout(function () { setIsLoadingBackDrop(false); }, 2000);
  }, []);

  const handlePrint = (fileType) => {
    const payload = formik.values;

    if ((payload.option !== 'monthly' && (!payload?.startPeriod || !payload?.endPeriod)) || (!payload?.option === 'monthly' && !payload?.month)) {
      return showToast({ type: 'error', message: 'Please enter valid period.' });
    }

    setIsLoadingBackDrop(true);
    let dateStart = '';
    let dateEnd = '';

    if (payload.option !== 'monthly') {
      dateStart = payload.startPeriod;
      dateEnd = payload.endPeriod;
    }
    else {
      let date = new Date(payload.month);
      dateStart = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-se").split('T')[0];
      dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString("sv-se").split('T')[0];
    }

    let variable = 'PrintSummaryGeneralJournal?typeReport=' + fileType;
    let params = [
      {
        "attr": "StartDate",
        "value": '' + dateStart
      },
      {
        "attr": "EndDate",
        "value": '' + dateEnd
      }
    ];
    ApiService.ReportPrint("Reports/Finance/Print/ControlReport/SummaryGeneralJournalReport/", variable, params)
      .then((response) => {
        if (response.status === 200) {
          let rptFormat = "application/pdf";
          if (fileType === 'xls') {
            rptFormat = "application/vnd.ms-excel";
          }
          const file = new Blob([response.data], { type: rptFormat });
          const fileURL = URL.createObjectURL(file);

          setIsLoadingBackDrop(false);
          showToast({ type: 'success', message: 'Print Summary General Journal Report Success' });
          window.open(fileURL);
        }
        else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Print Summary General Journal Report, ' + response.data.message });
        }
      })
      .catch((error) => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Summary General Journal Report, ' + error });
      });
  }

  const getDefaultMonth = () => {
    const year = date.getFullYear()
    let month = date.getMonth() + 1

    if (month < 10) {
      month = `0${month}`
    }

    return `${year}-${month}`
  }

  const resetField = () => {
    formik.setFieldValue('month', getDefaultMonth())
    formik.setFieldValue('startPeriod', date.toLocaleDateString("sv-se").split('T')[0])
    formik.setFieldValue('endPeriod', date.toLocaleDateString("sv-se").split('T')[0])
  }

  const formik = useFormik({
    initialValues: {
      option: 'monthly',
      month: getDefaultMonth(),
      startPeriod: date.toLocaleDateString("sv-se").split('T')[0],
      endPeriod: date.toLocaleDateString("sv-se").split('T')[0],
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {ButtonComponent.PrintButton(() => handlePrint('pdf'), 'PDF')}
            {ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Print Excel')}
          </Grid>
        </div>
      </div>
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: 3 }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          title={'Summary General Journal Report'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div style={{ marginTop: '24px' }}>
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                  <CardContent>
                    <Grid container spacing={'16px'}>
                      <Grid item xs={12}>
                        <Input
                          disabled
                          fullWidth
                          label="Company"
                          variant="standard"
                          value={companyName || ''}
                          InputLabelProps={{ shrink: true }}
                          formControlProps={{ sx: { width: '100%' } }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <RadioGroup
                          row
                          sx={{
                            marginTop: '4px',
                            gap: '20px'
                          }}
                          aria-labelledby="variant-option"
                          name="variant"
                          value={formik.values.option}
                          onChange={(e) => {
                            formik.setFieldValue('option', e.target.value)
                            resetField()
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'monthly'}
                            key={1}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Monthly
                              </Typography>
                            }
                          />

                          <FormControlLabel
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '12px !important',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              lineHeight: '20px',
                            }}
                            value={'custom'}
                            key={2}
                            control={<Radio size='small' />}
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                }}
                              >
                                Custom Period
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </Grid>

                      {
                        formik.values.option === 'monthly' && (
                          <Grid item xs={6}>
                            <Input
                              fullWidth
                              type="month"
                              variant="standard"
                              value={formik.values.month}
                              InputLabelProps={{ shrink: true }}
                              formControlProps={{ sx: { width: '100%' } }}
                              onChange={(e) => { formik.setFieldValue('month', e.target.value) }}
                            />
                          </Grid>
                        )
                      }

                      {formik.values.option === 'custom' && (
                        <Grid item xs={12}>
                          <Stack spacing={'16px'} direction={'row'} alignItems={'center'}>
                            <Input
                              fullWidth
                              type='date'
                              variant="standard"
                              value={formik.values.startPeriod}
                              InputLabelProps={{ shrink: true }}
                              formControlProps={{ sx: { width: '100%' } }}
                              onChange={(e) => { formik.setFieldValue('startPeriod', e.target.value) }}
                            />

                            <Typography
                              sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '19.6px',
                              }}
                            >
                              To
                            </Typography>

                            <Input
                              fullWidth
                              type='date'
                              variant="standard"
                              value={formik.values.endPeriod}
                              InputLabelProps={{ shrink: true }}
                              formControlProps={{ sx: { width: '100%' } }}
                              onChange={(e) => { formik.setFieldValue('endPeriod', e.target.value) }}
                            />
                          </Stack>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </Box>
  )
})

export default SummaryGeneralJournalReport