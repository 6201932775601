import { Grid } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const ModalData = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: "Please Select Data!" });
        } else {
            switch (props.titleModal2) {
                case 'Place of Receipt':
                    props.setPlaceOfReceipt(props.SelectedData.name)
                    props.setPlaceOfReceiptId(props.SelectedData.id)
                    props.setPlaceOfReceiptCode(props.SelectedData.code)
                    break;
                case 'Place of Delivery':
                    props.setPlaceOfDelivery(props.SelectedData.name)
                    props.setPlaceOfDeliveryId(props.SelectedData.id)
                    props.setPlaceOfDeliveryCode(props.SelectedData.code)
                    break;
                case 'Port of Loading':
                    props.setPortOfLoading(props.SelectedData.name)
                    props.setPortOfLoadingId(props.SelectedData.id)
                    props.setPortOfLoadingCode(props.SelectedData.abbreviation)
                    break;
                case 'Port of Discharge':
                    props.setPortOfDischarge(props.SelectedData.name)
                    props.setPortOfDischargeId(props.SelectedData.id)
                    props.setPortOfDischargeCode(props.SelectedData.abbreviation)
                    break;
                case 'Airport Departure':
                    props.setAirportDeparture(props.SelectedData.airPortName)
                    props.setAirportDepartureId(props.SelectedData.id)
                    props.setAirportDepartureCode(props.SelectedData.abbreviation)
                    break;
                case 'Airport Destination':
                    props.setAirportDestination(props.SelectedData.airPortName)
                    props.setAirportDestinationId(props.SelectedData.id)
                    props.setAirportDestinationCode(props.SelectedData.abbreviation)
                    break;
                case 'Vessel':
                    props.setVessel(props.SelectedData.name)
                    props.setVesselId(props.SelectedData.id)
                    props.setVesselCode(props.SelectedData.code)
                    break;
                case 'Airline':
                    props.setAirline(props.SelectedData.name)
                    props.setFlightAb(props.SelectedData.abbreviation)
                    props.setAirlineId(props.SelectedData.id)
                    props.setAirlineCode(props.SelectedData.code)
                    break;

                default:
            }
        }
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({})
        setFilter([])
        setFilterBy([])
        props.setIsModal2(false);
    }

    function Close() {
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({})
        setFilter([])
        setFilterBy([])
        props.handleClick2();
    }

    const handleReset = () => {
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({})
        setFilter([])
        setFilterBy([])
        props.getData(1, 50)
        setStateX({})
    }

    function CellDoubleClick(row) {
        Select()
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'warehouse'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal2}
            isOpen={props.isModal2}
            onClose={Close}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default ModalData