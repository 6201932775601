import React from 'react'
import { NestedMenuItem } from 'mui-nested-menu'
import { Menu, MenuItem, useTheme } from '@mui/material'

const ShrinkMenu = ({
  shrinkNodeEl,
  isShrinkMenuOpen,
  toggleShrinkMenu,
  shrinkActiveMenu,
  handleClick,
  setNodeSelected
}) => {
  const theme = useTheme()

  const renderMenu = (childs) => {
    const anotherChilds = childs?.children?.length
    if (anotherChilds) {
      return (
        <NestedMenuItem
          key={childs.id}
          hidden={childs?.hidden === 'false' ? false : true}
          label={childs.name || '-'}
          parentMenuOpen={isShrinkMenuOpen}
          MenuProps={{
            MenuListProps: {
              sx: {
                color: '#fff',
                background: isShrinkMenuOpen ? theme.palette.primary[300] : 'transparent',
              }
            }
          }}
        >
          {
            childs?.children.map((x) => renderMenu(x))
          }
        </NestedMenuItem>
      )
    } else {
      return (
        <MenuItem
          key={childs.id}
          hidden={childs?.hidden === 'false' ? false : true}
          onClick={(e) => {
            handleClick(childs?.url || '#', shrinkActiveMenu?.id || '0')
            setNodeSelected(e, childs.id)
            toggleShrinkMenu(null)
          }}
          sx={{
            '&:hover': {
              background: theme.palette.primary[400],
            }
          }}
        >
          {childs?.name || '-'}
        </MenuItem>
      )
    }
  }

  return (
    <Menu
      anchorEl={shrinkNodeEl}
      open={isShrinkMenuOpen}
      onClose={() => toggleShrinkMenu(null)}
      sx={{
        '.MuiPaper-root': {
          left: '50px!important'
        }
      }}
      MenuListProps={{
        sx: {
          background: isShrinkMenuOpen ? theme.palette.primary[300] : 'transparent',
          color: '#fff',
          '.MuiMenuItem-root:hover': {
            background: theme.palette.primary[400],
          }
        }
      }}
    >
      {shrinkActiveMenu?.children?.length && (
        shrinkActiveMenu?.children?.map((childs) => renderMenu(childs))
      )}
    </Menu>
  )
}

export default ShrinkMenu