import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../components/Buttons/CustomizeButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import TableX from '../../../components/Tables/TableX';
import ApiService from '../../../services/ApiService';
import { Icon, Paginate, PopUp, showToast } from '../../../components/ui';

const ModalDriver = (props) => {
    const { titleModal, isModal, handleClick, selectedData, setSelectedData, errorToast } = props

    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);

    const [isLoading, setIsLoading] = useState(true);
    const [dataDriver, setDataDriver] = useState([])
    const [columnData, setColumnData] = useState([])
    const [totalRows, setTotalRows] = useState(0)

    useEffect(() => {
        if (isModal) {
            getData(1, 50)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModal])

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoading(true);
        ApiService.DynamicPostByPage('employee/employee', 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    let temp = response.data.data.employees
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataDriver(indexed)
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)
                } else {
                    errorToast('driver')
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                errorToast('driver')
                console.log('error saat fetch', error)
            })
    }

    function Close() {
        handleClick();
    }

    function Select() {
        if (selectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            setSelectedData(selectedData)
            handleClick()
        }
    }

    const handleReset = () => {
        setGoTo(1);
        setSortArr([]);
        setFilter([])
        setFilterBy([])
        getData(1, 50)
        setStateX({})
    }

    function CellDoubleClick(row) {
        Select()
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(sortArr, rowsCount, columnData,
                            setGoTo, handleReset, setFilterBy,
                            filterBy, setFilter, setNumPage,
                            setRowsCount, getData, stateX, setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX uniqueKey={'id'}
                                            goTo={goTo} rowsCount={rowsCount} setNumPage={setNumPage}
                                            setRowsCount={setRowsCount} getData={getData} columnData={columnData}
                                            dataMap={dataDriver} SelectedData={props.SelectedData} setSelectedData={props.setSelectedData}
                                            setFilter={setFilter} setFilterBy={setFilterBy} CellDoubleClick={CellDoubleClick}
                                            stateX={stateX} setStateX={setStateX} sortArr={sortArr}
                                            setSortArr={setSortArr} setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + titleModal}
            isOpen={isModal}
            onClose={Close}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default ModalDriver