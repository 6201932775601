import { Box, Typography, Grid, Button, Stack } from '@mui/material';
import React, { useMemo, useCallback } from 'react';
import { Icon, PopUp } from '../ui';

const ModalRePostGL = React.memo(({
    transactionData = [],
    handleRePost,
    handleClick,
    isModal,
    dataCheck = [],
    isBulk = false
}) => {
    const memoizedTitle = useMemo(() => (
        <>
            {
                !isBulk ?
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            fontFamily: 'Nunito',
                            color: 'var(--Primary-One, #083A50)',
                        }}
                    >
                        This transaction is already approved but the system is failed to post for General Ledger. Re-Post the transaction for record this transaction as General Ledger:
                    </Typography>
                    :
                    <Stack direction={'column'} spacing={'12px'}>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                fontFamily: 'Nunito',
                                color: 'var(--Primary-One, #083A50)',
                            }}
                        >
                            {dataCheck.length} selected transactions is already approved but the system is failed to post for General Ledger.
                        </Typography>

                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                fontFamily: 'Nunito',
                                color: 'var(--Primary-One, #083A50)',
                            }}
                        >
                            Re-post the transaction for record this transaction as General Ledger.
                        </Typography>
                    </Stack>
            }
        </>
    ), [isBulk, dataCheck]);

    const detailContainer = (title, data) => (
        <>
            <Grid item xs={2}>
                <Typography
                    sx={{
                        color: 'var(--Primary-One, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '19.6px',
                    }}
                >
                    {title}
                </Typography>
            </Grid>

            <Grid item xs={1}>
                <Typography
                    sx={{
                        color: 'var(--Primary-One, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '19.6px',
                        textAlign: 'right'
                    }}
                >
                    :
                </Typography>
            </Grid>

            <Grid item xs={8}>
                <Typography
                    sx={{
                        color: 'var(--Primary-One, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '19.6px',
                    }}
                >
                    {data}
                </Typography>
            </Grid>
        </>
    );

    const mainContainer = useMemo(() => (
        <Grid container spacing={'4px'} direction="row">
            {detailContainer('Ref Number', transactionData.refNumber)}
            {detailContainer('Source', transactionData.source)}
            {detailContainer('Customer', transactionData.customer)}
            {detailContainer('Transaction Date', transactionData.transactionDate)}
            {detailContainer('Error Message', transactionData.errorMessage)}
        </Grid>
    ), [transactionData]);

    const handleRePostGL = useCallback(() => {
        handleRePost();
    }, [handleRePost]);

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#083A50',
                        sx: {
                            height: '20px',
                            width: '20px'
                        }
                    }}
                />
            }
            title={'Re-Posting for General Ledger'}
            isOpen={isModal}
            onClose={handleClick}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'md'}
            fullWidth={true}
            topButton={false}
        >
            <div style={{ padding: '16px 6px' }}>
                <Box display="flex" sx={{ marginBottom: '12px' }}>
                    {memoizedTitle}
                </Box>

                {!isBulk && mainContainer}
            </div>

            <div style={{ padding: '24px 6px 0px' }}>
                <Box display="flex" alignItems="center">
                    <Grid container item direction="row">
                        <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '4px 12px !important',
                                    gap: '4px',
                                    marginInlineStart: '12px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px !important',
                                    borderRadius: '8px',
                                    background: '#0087C2',
                                    '&:hover': {
                                        background: '#0087C2',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={handleRePostGL}
                            >
                                <Icon
                                    svgIconProps={{
                                        htmlColor: '#E4E8EC',
                                        sx: {
                                            height: '14px',
                                            width: '14px',
                                            marginTop: '1px'
                                        }
                                    }}
                                    iconName={'save'}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '3px !important',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        color: '#E4E8EC',
                                    }}
                                >
                                    Re-Posting GL
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </PopUp>
    );
});

export default React.memo(ModalRePostGL);