/* eslint-disable react/no-typos */
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import React from 'react';

export const convertMask = (value, dataType = 0) => {
    let tempData = '' + value;

    if (tempData === '') {
        tempData = '0';
    }

    // let val = tempData.replace(/,/g, '');
    let val = tempData.split(',').join('');

    if (dataType === 0) {
        val = parseFloat(val);
    }

    return val;
}

const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 5, // how many digits allowed after the decimal
    // integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
}

const DollarInput = React.forwardRef(({ maskOptions, ...inputProps }, ref) => {
    const DollarMask = createNumberMask({
      ...defaultMaskOptions,
      ...maskOptions,
    });
  
    return <MaskedInput mask={DollarMask} {...inputProps} />;
});

DollarInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
};

DollarInput.propTypes = {
    inputmode: PropTypes.string,
    maskOptions: PropTypes.shape({
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        includeThousandsSeparator: PropTypes.bool,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.bool,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.bool,
        allowNegative: PropTypes.bool,
        allowLeadingZeroes: PropTypes.bool,
        // integerLimit: PropTypes.number,
    }),
};

export default DollarInput;