import React from 'react'
import MainTable from './MainTable';
import IgnoreTable from './IgnoreTable';

const TableEPL = (props) => {
  const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };

  const handleCheck = (e, el) => {
    let dataChecked = [];
    let Sequence = 0;

    props.dataList.map((data) => {
      if (data.sequence === el.sequence) {
        Sequence = data.sequence;
        data.isIgnoreItem = e.target.checked;
      }

      return data;
    });

    dataChecked = [...props.dataList];

    props.setDataList(dataChecked);

    const newArr = props.dataDetail.slice()
    newArr.forEach(el => {
      if (el.sequence === Sequence) {
        el.isIgnoreItem = e.target.checked;
      }
    })

    props.setDataDetail(newArr);
    props.countSum(newArr);
    props.countTotalData(newArr);
  }

  function handleSelectData(value) {
    props.setSelectedDetail(value);
  }

  return (
    <div>
      <div className='mt-2'>
        <MainTable
          dataList={props.dataList}
          totalSData={props.totalSData}
          openClose2={props.openClose2}
          setOpenClose2={props.setOpenClose2}
          contactTypeName={props.contactTypeName}
          selectedDetail={props.selectedDetail}
          handleSelectData={handleSelectData}
          handleDoubleClick={props.handleDoubleClick}
          key1={props.key1}
          ignoreAccess={props.ignoreAccess}
          isReadOnly={props.isReadOnly}
          selectedStyle={selectedStyle}
          handleCheck={handleCheck}
          type={props.type}
        />
      </div>

      <hr
        className='mt-3 mb-3'
        style={{
          borderWidth: '1.75px',
          borderStyle: 'solid',
          color: '#CACED8 !important',
          marginTop: '0px !important',
          marginLeft: '0px !important',
          marginRight: '0px !important',
          marginBottom: '0px !important',
          borderColor: '#CACED8 !important',
          backgroundColor: '#CACED8 !important',
        }}
      />

      <div className='mb-1'>
        <IgnoreTable
          dataList={props.dataList}
          totalNData={props.totalNData}
          openClose={props.openClose}
          setOpenClose={props.setOpenClose}
          contactTypeName={props.contactTypeName}
          selectedDetail={props.selectedDetail}
          handleSelectData={handleSelectData}
          handleDoubleClick={props.handleDoubleClick}
          key2={props.key2}
          selectedStyle={selectedStyle}
          type={props.type}
        />
      </div>
    </div>
  )
}

export default React.memo(TableEPL)