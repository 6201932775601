import { Grid } from '@mui/material';
import React, { useState } from 'react'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';
import { convertToNominal } from '../../../../utils/masks.jsx';

const SelectContact = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: "Please Select Data!" });
        } else {
            if (props.titleModal === "Customer/Vendor") {
                props.setTempCustId(props.SelectedData.contactId);
                props.setTempCustName(props.SelectedData.contactName);
            } else {
                if (props.tipe === 23) {
                    props.setIdCustomer(props.SelectedData.contactId);
                    props.setCustomerName(props.SelectedData.contactName);
                    props.setCustomerAddress(props.SelectedData.contactAddress);
                    props.setPic(props.SelectedData.contactPerson);
                    props.setEmail(props.SelectedData.email);
                    props.setPhone(props.SelectedData.phone);
                    let formatUSD = convertToNominal(props.SelectedData.creditTermInUSD);
                    let formatIDR = convertToNominal(props.SelectedData.creditTermInIDR);
                    props.setCreditTermUsd(formatUSD);
                    props.setCreditTermIdr(formatIDR);

                    props.setCreditTermUsdReal(props.SelectedData.creditTermInUSD);
                    props.setCreditTermIdrReal(props.SelectedData.creditTermInIDR);
                    props.setCreditTermDays(props.SelectedData.creditTermInDays);
                    if (props.jobServiceType !== 'Import') {
                        props.setIdShipper(props.SelectedData.contactId);
                        props.setShipperName(props.SelectedData.contactName);
                        props.setShipperAddress(props.SelectedData.contactAddress);
                    } else {
                        props.setIdConsignee(props.SelectedData.contactId);
                        props.setConsigneeName(props.SelectedData.contactName);
                        props.setConsigneeAddress(props.SelectedData.contactAddress);
                    }
                } else if (props.tipe === 2) {
                    props.setIdShipper(props.SelectedData.contactId);
                    props.setShipperName(props.SelectedData.contactName);
                    props.setShipperAddress(props.SelectedData.contactAddress);
                } else if (props.tipe === 3) {
                    props.setIdConsignee(props.SelectedData.contactId);
                    props.setConsigneeName(props.SelectedData.contactName);
                    props.setConsigneeAddress(props.SelectedData.contactAddress);
                } else if (props.tipe === 4) {
                    props.setIdCarrier(props.SelectedData.contactId);
                    props.setCarrierName(props.SelectedData.contactName);
                } else if (props.tipe === 5) {
                    props.setIdCarrier(props.SelectedData.contactId);
                    props.setCarrierName(props.SelectedData.contactName);
                } else if (props.tipe === 1) {
                    props.setIdAgent(props.SelectedData.contactId);
                    props.setAgentName(props.SelectedData.contactName);
                    props.setAgentAddress(props.SelectedData.contactAddress)
                } else if (props.tipe === 6) {
                    props.setIdEMKL(props.SelectedData.contactId);
                    props.setEMKLName(props.SelectedData.contactName);
                } else if (props.tipe === 7) {
                    props.setIdDepo(props.SelectedData.contactId);
                    props.setDepoName(props.SelectedData.contactName);
                } else if (props.tipe === 11) {
                    props.setIdBookingParty(props.SelectedData.contactId);
                    props.setBookingPartyName(props.SelectedData.contactName);
                    props.setBookingPartyAddress(props.SelectedData.contactAddress);
                }
            }
        }

        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setFilterBy([])
        setFilter([])
        setStateX({})
        props.setIsModal(false);
    }

    function keluar() {
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setFilterBy([])
        setFilter([])
        setStateX({})
        props.handleClick();
    }

    const handleReset = () => {
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([])
        setFilterBy([])
        setStateX({})
        props.getData(1, 50)
        setStateX({})
    }

    function CellDoubleClick(row) {
        Select()
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={keluar}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectContact