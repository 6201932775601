import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService, { branchId, companyId, countryId, userCode, userId, username } from '../../../../services/ApiService'
import BankAdvCreateEditButton from '../BankAdvance/ComponentBankAdv/BankAdvCreateEditButton'
import BankAdvDetail from '../BankAdvance/ContentBankAdv/BankAdvDetail'
import BankAdvTotal from '../BankAdvance/ContentBankAdv/BankAdvTotal'
import CashAdvHeader from './ContentCashAdv/CashAdvHeader'
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import { forwardRef } from 'react';
import { Box, CardContent, Grid, Card } from '@mui/material'
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo'
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { defaultDate, getDefaultDateFormat, setDateFormat, updateDateFormat } from '../../../../utils/date'
import { convertToNominal } from '../../../../utils/masks.jsx'
import CustomToastContainer, { showToast } from '../../../../components/ui'

const CreateEditCashAdv = forwardRef((props, ref) => {
  const dataLocal = JSON.parse(localStorage.getItem(username + "-DataCA"));

  const { cashAdvId } = useParams()
  const [dataDetail, setDataDetail] = useState([])
  const [selectedDetail, setSelectedDetail] = useState({})
  const DefDate = '2023-01-17T10:25:59.631Z'
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

  const [cashAdvanceNo, setCashAdvanceNo] = useState(0)
  const [reference, setReference] = useState('')
  const [employeeId, setEmployeeId] = useState(0)
  const [employeeCode, setEmployeeCode] = useState('')
  const [employeeName, setEmployeeName] = useState('')
  const [cashAdvanceTo, setCashAdvanceTo] = useState('O')
  const [rate, setRate] = useState(0)
  const [printing, setPrinting] = useState(0)
  const [rateReal, setRateReal] = useState(0);
  const [exRateDate, setExRateDate] = useState(DefDate)
  const [exRateId, setExRateId] = useState(0)

  const [totalUSD, setTotalUSD] = useState(0)
  const [totalIDR, setTotalIDR] = useState(0)
  const [totalInIDR, setTotalInIDR] = useState(0)

  const [totalUSDReal, setTotalUSDReal] = useState(0)
  const [totalIDRReal, setTotalIDRReal] = useState(0)
  const [totalInIDRReal, setTotalInIDRReal] = useState(0)

  const [printedOn, setPrintedOn] = useState('')
  const [paidOn, setPaidOn] = useState('')

  const paramCashAdv = 'cashAdvance/cashAdvance'
  const paramRegUserAccess = 'regUserAccess/regUserAccess';

  const history = useNavigate()
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [check, setCheck] = useState(0);

  const titleDetail = 'Cash'

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
  const [titleConfirmation, setTitleConfirmation] = useState('');
  const [isRemarks, setIsRemarks] = useState(false);
  const [approvedCreditRemarks, setApprovedCreditRemarks] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [isApprove, setIsApprove] = useState(false);
  const [documentStatus, setDocumentStatus] = useState('');

  const [infossNo, setInfossNo] = useState('');

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Cash Advance', link: '/Majura/CashAdvance', style: '' },
    { label: 'Create Cash Advance', link: '/Majura/CashAdvance/Create', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  const BreadcrumbsEditItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Cash Advance', link: '/Majura/CashAdvance', style: '' },
    { label: 'Detail Cash Advance', link: '/Majura/CashAdvance/' + cashAdvId, style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Cash Advance - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashAdvId])

  useImperativeHandle(ref, () => ({
    localSave() {
      saveLocalData();
    }
  }));

  function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
    if (rowStatus !== 'ACT') {
      setDocumentStatus('DELETED');
    }
    else if (isApproved) {
      setDocumentStatus('APPROVED');
    }
    else {
      setDocumentStatus('ONGOING');
    }
  }

  const saveLocalData = () => {
    let id = cashAdvId ? cashAdvId : 0;
    let datePaid = updateDateFormat(paidOn, true);
    let datePrint = updateDateFormat(printedOn, true);

    const payload = {
      "cashAdvance": {
        "rowStatus": "ACT",
        'noInfoss': infossNo,
        "countryId": countryId,
        "companyId": companyId,
        "branchId": branchId,
        "id": id,
        "cashAdvanceNo": cashAdvanceNo,
        "reference": reference,
        "approved": isApprove,
        "approvedOn": defaultDate,
        "employeeId": employeeId,
        "employeeCode": employeeCode,
        "employeeName": employeeName,
        "cashAdvanceTo": cashAdvanceTo,
        "cashAdvanceUSD": totalUSDReal,
        "cashAdvanceIDR": totalIDRReal,
        "paid": false,
        "paidOn": datePaid,
        "rate": rateReal,
        "exRateDate": exRateDate,
        "printing": printing,
        "printedOn": datePrint,
        "deleted": false,
        "deletedOn": defaultDate,
        "exRateId": exRateId,
        "approvedCredit": false,
        "approvedCreditById": 0,
        "approvedCreditOn": defaultDate,
        "approvedCreditRemarks": approvedCreditRemarks,
        "userId": userId,
        "userCode": userCode,
      },
      "cashAdvanceDetails": dataDetail
    }

    localStorage.setItem(username + "-DataCA", JSON.stringify(payload));
  }

  const getLocalData = () => {
    let cashAdvanceData = dataLocal.cashAdvance;
    let cashAdvanceDetailData = dataLocal.cashAdvanceDetails;

    if (cashAdvId) {
      setIsEdit(true);
    }
    setInfossNo(cashAdvanceData.noInfoss)
    setCashAdvanceNo(cashAdvanceData.cashAdvanceNo)
    setReference(cashAdvanceData.reference)
    setEmployeeId(cashAdvanceData.employeeId)
    setCashAdvanceTo(cashAdvanceData.cashAdvanceTo)
    setTotalUSD(cashAdvanceData.totalUSD)
    setTotalIDR(cashAdvanceData.totalIDR)
    setRateReal(cashAdvanceData.rate)
    handleDocumentStatus(cashAdvanceData.rowStatus, cashAdvanceData.approved)
    setRate(convertToNominal(cashAdvanceData.rate));
    setDateFormat(cashAdvanceData.exRateDate, setExRateDate)
    setPrinting(cashAdvanceData.printing)
    setExRateId(cashAdvanceData.exRateId)
    setApprovedCreditRemarks(cashAdvanceData.approvedCreditRemarks)
    setEmployeeCode(cashAdvanceData.employeeCode)
    setEmployeeName(cashAdvanceData.employeeName)
    setIsApprove(cashAdvanceData.approved);
    setDateFormat(cashAdvanceData.printedOn, setPrintedOn);
    setDateFormat(cashAdvanceData.paidOn, setPaidOn);

    setDataDetail(cashAdvanceDetailData)
    sumTotal(cashAdvanceDetailData)

    localStorage.removeItem(username + '-DataCA');
    setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
  }

  const getData = () => {
    ApiService.FinAccPostById(paramCashAdv, cashAdvId)
      .then((response) => {
        if (response.data.code === 200) {
          var result = response.data.data.cashAdvance
          setIsEdit(true);
          setInfossNo(result.noInfoss)
          setCashAdvanceNo(result.cashAdvanceNo)
          setReference(result.reference)
          setEmployeeId(result.employeeId)
          setCashAdvanceTo(result.cashAdvanceTo)
          setTotalUSD(result.totalUSD)
          setTotalIDR(result.totalIDR)
          setRateReal(result.rate)
          setRate(convertToNominal(result.rate))
          setExRateDate(getDefaultDateFormat(result.exRateDate))
          setExRateId(result.exRateId)
          setApprovedCreditRemarks(result.approvedCreditRemarks)
          setIsApprove(result.approved);
          setDataDetail(result.cashAdvanceDetails)
          setEmployeeCode(result.employeeCode)
          setEmployeeName(result.employeeName)
          handleDocumentStatus(result.rowStatus, result.approved)
          setPrinting(result.printing)
          setDateFormat(result.printedOn, setPrintedOn);
          setDateFormat(result.paidOn, setPaidOn);

          sumTotal(result.cashAdvanceDetails)

          if (!dataLocal) {
            setIsLoadingBackDrop(false);
          }
        }
        else {
          handleBack();
        }
      })
      .catch(function (error) {
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + error })
      })
  }

  const getDataAccess = () => {
    if (!dataLocal) {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false
    let tempArray = [];
    let checked = 0;

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'FCAD')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);
            tempArray = response.data.data.userAccess;

            tempArray.forEach(element => {
              if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                checked = 1;
              }
            });

            setCheck(checked);

            if (dataLocal) {
              setIsLoadingBackDrop(true);
              setTimeout(function () { getLocalData(); }, 2000);
            } else {
              if (!cashAdvId) {
                getDateExchRate()
                handleDocumentStatus()
              } else {
                getData()
              }
            }
          }
          else {
            handleBack();
          }
        })
        .catch(function (error) {
          setIsLoadingBackDrop(false);
          console.error('error saat fetch', error)
          history('/Majura');
        })
    }
    else {
      if (!dataLocal) {
        setIsLoadingBackDrop(false);
      }

      history('/Majura');
    }
  }

  const handleSave = (isPrint = false) => {
    setIsLoadingBackDrop(true);

    let id = cashAdvId ? cashAdvId : 0;
    let datePaid = updateDateFormat(paidOn, true);
    let datePrint = updateDateFormat(printedOn, true);

    const payload = {
      "cashAdvance": {
        "rowStatus": "ACT",
        "countryId": countryId,
        "companyId": companyId,
        "branchId": branchId,
        "id": id,
        'noInfoss': infossNo,
        "reference": reference,
        "approved": true,
        "approvedOn": defaultDate,
        "employeeId": employeeId,
        "employeeCode": employeeCode,
        "employeeName": employeeName,
        "cashAdvanceTo": cashAdvanceTo,
        "cashAdvanceUSD": totalUSDReal,
        "cashAdvanceIDR": totalIDRReal,
        "paid": false,
        "paidOn": datePaid,
        "rate": rateReal,
        "exRateDate": exRateDate,
        "printing": printing,
        "printedOn": datePrint,
        "deleted": false,
        "deletedOn": defaultDate,
        "exRateId": exRateId,
        "approvedCredit": false,
        "approvedCreditById": 0,
        "approvedCreditOn": defaultDate,
        "approvedCreditRemarks": approvedCreditRemarks,
        "userId": userId,
        "userCode": userCode,
        "SettlementIDR": 0,
        "UnSettledIDR": totalIDRReal,
        "SettlementUSD": 0,
        "UnSettledUSD": totalUSDReal,
      },
      "cashAdvanceDetails": dataDetail
    }

    let errorMessage = undefined;

    if (payload.cashAdvance.employeeId === 0) {
      errorMessage = 'Please Select Employee Data!';
    }
    else if (payload.cashAdvanceDetails.length === 0) {
      errorMessage = 'Please Fill Detail Data!';
    }
    else if (payload.cashAdvance.noInfoss === '') {
      errorMessage = 'Please Fill Infoss Number!';
    }

    if (errorMessage === undefined) {
      ApiService.HelpCreateData(payload, paramCashAdv, id)
        .then((res) => {
          if (res.data.code === 200) {
            let message = ''
            if (!cashAdvId) {
              ApiService.CreateFinAcc(paramCashAdv, payload)
                .then((res) => {
                  if (res.data.code === 200) {
                    message = 'Success create data';
                    showToast({ type: 'success', message: message });

                    setTimeout(
                      function () {
                        localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                        history('/Majura/CashAdvance/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                        window.location.reload();
                        localStorage.removeItem('tempId');
                      },
                      1250);
                  }
                  else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: res.data.error });
                  }
                })
            }
            else {
              ApiService.EditFinAcc(paramCashAdv, payload)
                .then((res) => {
                  if (res.data.code === 200) {
                    if (isPrint === false) {
                      message = 'Success update data';
                      showToast({ type: 'success', message: message });

                      setTimeout(
                        function () {
                          history('/Majura/CashAdvance/' + cashAdvId, { replace: true });
                          window.location.reload();
                        },
                        1250);
                    }
                    else {
                      Print();
                    }
                  }
                  else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: res.data.error });
                  }
                })
            }
          }
          else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Failed to Create Help Data, ' + res.data.error });
          }
        })
        .catch(function (error) {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + error });
        })
    }
    else {
      setIsLoadingBackDrop(false);
      showToast({ type: 'error', message: errorMessage });
    }
  }

  const handleBack = () => {
    history('/Majura/CashAdvance')
  }

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setIsModalConfirmation(true);
      setApprovedCreditRemarks('');
    }
    else {
      setIsModalConfirmation(false);
    }
  }

  function ConfirmationCA() {
    if (titleConfirmation === 'Print') {
      if (documentStatus !== 'APPROVED') {
        handleSave(true);
      }
      else {
        Print();
      }
    }
    else if (titleConfirmation === 'Approve') {
      Approval();
    }
    else if (titleConfirmation === 'Approval Credit') {
      ApprovalCr();
    }
    else if (titleConfirmation === "Add") {
      Add();
    }
    setIsRemarks(false);
    setIsModalConfirmation(false);
  }

  const handlePrint = () => {
    if (cashAdvId) {
      if (isApprove) {
        setTitleConfirmation('Print')
        setTitleModalConfirmation('Cash Advance');
        handleClickConfirmation();
      }
      else {
        showToast({ type: 'error', message: 'Cash Advance Need To Be Approved!' });
      }
    }
    else {
      showToast({ type: 'error', message: 'Create The Data First!' });
    }
  }

  const PrintPDF = () => {
    setIsLoadingBackDrop(true);
    let variable = 'PrintCA?Id=' + cashAdvId;
    ApiService.ReportPrint('Transactions/Finance/CashAdvanceReport/', variable)
      .then(response => {
        if (response.status === 200) {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' }
          );

          const fileURL = URL.createObjectURL(file);

          setIsLoadingBackDrop(false);
          showToast({ type: 'success', message: 'Print Cash Advance Success!' });
          window.open(fileURL);

          setTimeout(
            function () {
              history('/Majura/CashAdvance/' + cashAdvId, { replace: true });
              window.location.reload();
            },
            1250);

        } else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Print Cash Advance!' });
        }
      })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Print Cash Advance!, ' + error });
      });
  }

  const Print = () => {
    ApiService.FinAccUpdateStatusPrint(paramCashAdv, cashAdvId).then((res) => {
      if (res.data.code !== 200) {
        showToast({ type: 'error', message: res.data.error });
      }
      else {
        PrintPDF();
      }
    });
  }

  const handleApprovalCr = () => {
    if (cashAdvId) {
      setTitleConfirmation('Approval Credit')
      setTitleModalConfirmation('Cash Advance');
      handleClickConfirmation();
      setIsRemarks(true);
    }
    else {
      showToast({ type: 'error', message: 'Create The Data First!' });
    }
  }

  const ApprovalCr = () => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccApproveCredit('bankAdvance', cashAdvId, approvedCreditRemarks)
      .then((res) => {
        if (res.data.code === 200) {
          showToast({ type: 'success', message: 'Approval Credit Success!' });
          setIsLoadingBackDrop(false);
          handleClickConfirmation();

          setTimeout(
            function () {
              history('/Majura/BankAdvance/' + cashAdvId, { replace: true });
              window.location.reload();
            },
            1250);
        }
        else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Approve Credit!, ' + res.data.error });
        }
      })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Approve Credit!, ' + error });
      });
  }

  const handleApproval = () => {
    if (cashAdvId) {
      if (isApprove === false) {
        setTitleConfirmation('Approve')
        setTitleModalConfirmation('Cash Advance');
        handleClickConfirmation();
      }
      else {
        showToast({ type: 'error', message: 'Cash Advance Already Been Approved!' });
      }
    }
    else {
      showToast({ type: 'error', message: 'Create The Data First!' });
    }
  }

  const Approval = () => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccApprove(paramCashAdv, cashAdvId)
      .then((res) => {
        if (res.data.code === 200) {
          setIsLoadingBackDrop(false);
          setIsApprove(true);
          showToast({ type: 'success', message: 'Approve Cash Advance Success!' });

          setTimeout(
            function () {
              history('/Majura/CashAdvance/' + cashAdvId, { replace: true });
              window.location.reload();
            },
            1250);
        }
        else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Approve Cash Advance!, ' + res.data.error });
        }
      })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Approve Cash Advance!, ' + error });
      });
  }

  const handleAdd = () => {
    setTitleConfirmation("Add");
    setTitleModalConfirmation("Cash Advance");
    handleClickConfirmation();
  }

  const Add = () => {
    history('/Majura/CashAdvance/Create', { replace: true });
    window.location.reload();
  }

  const getDateExchRate = () => {
    ApiService.PostByExRateDate()
      .then((response) => {
        if (response.data.code === 200) {
          setExRateId(response.data.data.exchangeRate.id);
          let count = convertToNominal(response.data.data.exchangeRate.exRate1);
          setRate(count);
          setRateReal(response.data.data.exchangeRate.exRate1);
          setExRateDate(getDefaultDateFormat(response.data.data.exchangeRate.exRateDate));

          GetFormatCurrency()
        }
        else {
          handleBack();
        }
      })
      .catch(function (error) {
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + error });
      })
  }

  const GetFormatCurrency = () => {
    let totalUSD = 0;
    let totalIDR = 0;
    let formatUSD = convertToNominal(`${totalUSD}`);
    let formatIDR = convertToNominal(`${totalIDR}`);

    setTotalUSD(formatUSD);
    setTotalIDR(formatIDR);
    setTotalInIDR(formatIDR);

    if (!dataLocal) {
      setIsLoadingBackDrop(false);
    }
  }

  const sumTotal = (data) => {
    let sumUSD = 0
    let sumIDR = 0
    let sumInIDR = 0
    data.forEach(el => {
      sumUSD += parseInt(el.amountUSD)
      sumIDR += parseInt(el.amountIDR)
    });

    setTotalUSDReal(sumUSD)
    setTotalIDRReal(sumIDR)
    setTotalUSD(convertToNominal(`${sumUSD}`));
    setTotalIDR(convertToNominal(`${sumIDR}`));

    sumInIDR = (sumUSD * rateReal) + sumIDR;
    setTotalInIDRReal(sumInIDR)
    setTotalInIDR(convertToNominal(`${sumInIDR}`));
  }

  const ButtonComponents = () => {
    return (
      <BankAdvCreateEditButton
        handleSave={handleSave}
        handleBack={handleBack}
        handlePrint={handlePrint}
        handleApproval={handleApproval}
        handleApprovalCr={handleApprovalCr}
        DataRegUserAccess={DataRegUserAccess}
        check={check}
        isEdit={isEdit}
        handleAdd={handleAdd}
        printing={printing}
      />
    )
  }

  return (
    <>
      <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            title={
              cashAdvId ?
                'Cash Advance (' + reference + ')'
                :
                'Cash Advance ( New Document )'
            }
            BreadcrumbsItems={cashAdvId ? BreadcrumbsEditItems : BreadcrumbsItems}
            type={'Form'}
            ticketId={0}
            BackFunction={handleBack}
            documentStatus={documentStatus}
          />

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <CashAdvHeader
                infossNo={infossNo}
                setInfossNo={setInfossNo}
                cashAdvanceNo={cashAdvanceNo}
                setCashAdvanceNo={setCashAdvanceNo}
                reference={reference}
                setReference={setReference}
                cashAdvanceTo={cashAdvanceTo}
                setDataDetail={setDataDetail}
                setCashAdvanceTo={setCashAdvanceTo}
                isEdit={isEdit}

                printedOn={printedOn}
                paidOn={paidOn}
                rate={rate}
                exRateDate={exRateDate}
                setEmployeeId={setEmployeeId}
                setEmployeeCode={setEmployeeCode}
                employeeCode={employeeCode}
                setEmployeeName={setEmployeeName}
                employeeName={employeeName}
                printing={printing}
              />
            </CardContent>
          </Card>

          <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
            <CardContent>
              <BankAdvDetail
                dataDetail={dataDetail}
                setDataDetail={setDataDetail}
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                employeeId={employeeId}
                BankAdvId={cashAdvId}
                sumTotal={sumTotal}
                totalUSD={totalUSD}
                totalIDR={totalIDR}
                titleDetail={titleDetail}
                isEdit={isEdit}
                printing={printing}
                advanceTo={cashAdvanceTo}
              />

              <BankAdvTotal
                titleDetail={titleDetail}
                totalUSD={totalUSD}
                totalIDR={totalIDR}
                totalInIDR={totalInIDR}
                isEdit={isEdit}
                totalInIDRReal={totalInIDRReal}
                printing={printing}
              />
            </CardContent>
          </Card>
        </Grid>

        {
          isModalConfirmation && (
            <ModalConfirmation
              isModal={isModalConfirmation}
              titleModal={titleModalConfirmation}
              title={titleConfirmation}
              handleClick={handleClickConfirmation}
              ConfirmationData={ConfirmationCA}

              isRemarks={isRemarks}
              Remarks={approvedCreditRemarks}
              setRemarks={setApprovedCreditRemarks}
            />
          )
        }
      </Box>

      <ButtonInfo
        ButtonComponents={ButtonComponents}
      />

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  )
})
export default CreateEditCashAdv