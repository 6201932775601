import { IconButton, Stack, Grid } from '@mui/material';
import React from 'react';
import { Icon, Input, showToast } from '../../../../components/ui';

const TabDelivery = (props) => {
    const searchDelivery = (data) => {
        if (data === 'search') {
            if (props.principleBy !== 0) {
                props.setTipe(1);
                props.setContactTypeId(12);
                props.setTitleModal('Delivery');
                props.handleClick(1);
            }
            else {
                showToast({ type: 'error', message: 'Please Select Principle By!' });
            }
        }
        else if (data === 'delete') {
            props.setDeliveryId(0);
            props.setDeliveryName('');
            props.setDeliveryAddress('');
        }
    };

    return (
        <>
            <Grid
                container
                spacing={'16px'}
            >
                <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    sm={1.5}
                    xs={12}
                >
                    <Input
                        label="Code"
                        value={props.DeliveryId}
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={5}
                    lg={5}
                    sm={5}
                    xs={12}
                >
                    <Input
                        label="Name"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.DeliveryName}
                        disabled
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={5.5}
                    lg={5.5}
                    sm={5.5}
                    xs={12}
                >
                    <Stack direction={'row'} spacing={'16px'} sx={{ alignItems: 'center' }}>
                        <Input
                            label="Address"
                            value={props.DeliveryAddress}
                            disabled
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                                rows: 4,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                        />

                        <Stack direction={'column'} spacing={'8px'} sx={{ marginTop: '36px !important' }}>
                            <IconButton
                                onClick={() => searchDelivery('search')}
                                disabled={props.isJobClose}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '16px',
                                            width: '16px'
                                        }
                                    }}
                                />
                            </IconButton>

                            <IconButton
                                onClick={() => searchDelivery('delete')}
                                hidden={props.DeliveryAddress ? false : true}
                                disabled={props.isJobClose}
                            >
                                <Icon
                                    iconName={'close'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '16px',
                                            width: '16px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default TabDelivery;