import React from 'react'
import { Backdrop, Box, Grid } from '@mui/material'

import OperationalMarketingSection from './components/OperationalMarketingSection';
import AccountingFinanceSection from './components/AccountingFinanceSection';

import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { Icon, PopUp } from '../../../components/ui';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';

const CustomerServiceReport = ({
  isOpen,
  closeModal,
  exportData,
  jobTypeList,
  isLoading,

  shipmentData,
  bookingConfirmationData,
  eplData,
  paymentRequestData,
  temporaryReceiptData,
  invoiceData,
  paymentVoucherData,
  officialReceiptData,

  tableINV,
  tableOR,
  tablePR,
  tablePV,
  tableTR,
}) => {

  const mainContainer = (
    <div minHeight={'500px'}>
      <div style={{ marginTop: '12px' }}>
        <OperationalMarketingSection
          eplData={eplData}
          invoiceData={invoiceData}
          shipmentData={shipmentData}
          paymentRequestData={paymentRequestData}
          paymentVoucherData={paymentVoucherData}
          officialReceiptData={officialReceiptData}
          temporaryReceiptData={temporaryReceiptData}
          bookingConfirmationData={bookingConfirmationData}
          jobTypeList={jobTypeList}
        />
      </div>

      <div style={{ marginTop: '24px' }}>
        <AccountingFinanceSection
          shipmentData={shipmentData}
          tableINV={tableINV}
          tableOR={tableOR}
          tablePR={tablePR}
          tablePV={tablePV}
          tableTR={tableTR}
        />
      </div>
    </div>
  )

  return (
    <>
      <PopUp
        id='modal-id'
        icon={
          <Icon
            iconName={'approve-doc'}
            svgIconProps={{
              htmlColor: '#0087C2',
              sx: {
                height: '24px',
                width: '24px'
              }
            }}
          />
        }
        title={'Customer Service Report'}
        isOpen={isOpen}
        onClose={closeModal}
        draggable={true}
        shrinkable={true}
        disableScrollLock={true}
        maxWidth={'xl'}
        fullWidth={true}
        topButton={false}
      >
        <div style={{ padding: '12px 6px 0px' }}>
          {mainContainer}

          <div style={{ marginTop: '48px' }}>
            <Box display="flex" alignItems="center">
              <Grid container item direction="row">
                <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ columnGap: '12px' }}>
                  {ButtonComponent.PrintButton(() => exportData('pdf'), 'PDF', true)}
                  {ButtonComponent.ExcelButton(() => exportData('xls'), 'Print Excel', false, true)}
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </PopUp>

      {
        isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: 100000 }}
            open={isLoading}
          >
            <LoadingSpinner color="inherit" />
          </Backdrop>
        )
      }
    </>
  )
}

export default CustomerServiceReport