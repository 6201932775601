import React from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

const BookingButton = ({ 
    AddBc,
    ReloadData,
    EditBc,
    DeleteData,
    PrintData,
    ApprovalData,
    UnApprovalData,
    CopyData,
    DataRegUserAccess,
    DropDownTitle,
    HandleStatus,
    DropDownList
}) => {

    const renderButton = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddBc, 'New');

            case 'REI':
                return ButtonComponent.EditButton(EditBc, 'Data');

            case 'DEL':
                return DropDownTitle !== 'Deleted'
                    ? ButtonComponent.DeleteButton(DeleteData, 'Data')
                    : null;

            case 'PRI':
                return DropDownTitle !== 'Deleted'
                    ? ButtonComponent.PrintButton(PrintData)
                    : null;

            case 'APP':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All')
                    ? ButtonComponent.ApproveButton(ApprovalData, 'Approval')
                    : null;

            case 'UPP':
                return (DropDownTitle === 'Approved' || DropDownTitle === 'All')
                    ? ButtonComponent.UnApprovalButton(UnApprovalData, '')
                    : null;

            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <Grid
                    container
                    item
                    spacing={1}
                    direction="row"
                    style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
                >
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data.accessCode)}
                        </React.Fragment>
                    ))}

                    {ButtonComponent.CopyButton(CopyData, 'BC')}

                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                </Grid>  
            </div>
        </div>
    );
};

export default BookingButton;