import React, { useState } from 'react'
import { Box, FormGroup, Stack, Typography } from '@mui/material'

import { Icon, TickBox } from '../../../components/ui'

const MasterTickbox = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isSmall, setIsSmall] = useState(false)

  const handleChange = (e) => {
    setIsChecked(e.target.checked)
  }
  return (
    <Stack direction={'column'} gap={2}>
      <FormGroup>
        <TickBox
          label={'Disabled'}
          checked={isDisabled}
          onChange={() => setIsDisabled(!isDisabled)}
        />
        <TickBox
          label={'Small'}
          checked={isSmall}
          onChange={() => setIsSmall(!isSmall)}
        />
      </FormGroup>

      <Box>
        <Typography fontWeight={700}>DEFAULT</Typography>
        <TickBox
          checked={isChecked}
          onChange={handleChange}
          disabled={isDisabled}
          label='TickBox Label'
          size={isSmall ? 'small' : undefined}
        />
      </Box>

      <Box>
        <Typography fontWeight={700}>CUSTOM ICON</Typography>
        <TickBox
          checked={isChecked}
          onChange={handleChange}
          disabled={isDisabled}
          label='Custom Icon'
          icon={<Icon iconName='bell' />}
          checkedIcon={<Icon iconName='bell' />}
          size={isSmall ? 'small' : undefined}
        />
      </Box>
    </Stack>
  )
}

export default MasterTickbox