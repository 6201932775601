import { Grid, IconButton, Stack, InputAdornment } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';

import SelectDataNonContact from '../Selects/SelectDataNonContact';
import { Icon, Input, PopUp, showToast } from '../../../../components/ui';

const ModalFlight = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [tipe, setTipe] = useState(0);

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);
    let countErrN = 1;

    const handleClick = (title) => {
        if (isModal === false) {
            setIsModal(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal(false);
            localStorage.removeItem("title");
        }
    };

    const searchFlight = (data) => {
        if (data === 'search') {
            setTitleModal('Flight');
            handleClick('Flight');
        }
        else if (data === 'delete') {
            props.setFlightId(0);
            props.setFlightCode('');
            props.setFlightName('');
        }
    };

    const searchAirPortFrom = (data) => {
        if (data === 'search') {
            setTipe(5);
            setTitleModal('AirPort');
            handleClick('AirPort');
        }
        else if (data === 'delete') {
            props.setAirportFromId(0);
            props.setAirportFromCode('');
            props.setAirportFromName('');

            props.setCityFromId(0);
            props.setCityFromCode('');
            props.setCityFromName('');
        }
    };

    const searchAirPortTo = (data) => {
        if (data === 'search') {
            setTipe(6);
            setTitleModal('AirPort');
            handleClick('AirPort');
        }
        else if (data === 'delete') {
            props.setAirportToId(0);
            props.setAirportToCode('');
            props.setAirportToName('');

            props.setCityToId(0);
            props.setCityToCode('');
            props.setCityToName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick2();
    };

    const emptyState = () => {
        props.setFlightId(0);
        props.setFlightCode('');
        props.setFlightName('');
        props.setFlightNo('');
        props.setAirportFromId(0);
        props.setAirportFromCode('');
        props.setAirportFromName('');
        props.setAirportToId(0);
        props.setAirportToCode('');
        props.setAirportToName('');
        props.setCityFromId(0);
        props.setCityFromCode('');
        props.setCityFromName('');
        props.setCityToId(0);
        props.setCityToCode('');
        props.setCityToName('');
        props.getDateNow();
    };

    const handleSave = () => {
        if (!props.flightId) {
            showToast({ type: 'error', message: 'Please Select Flight!' });
        }
        else {
            if (props.flightNo === '') {
                showToast({ type: 'error', message: 'Please Input Flight Number!' });
            }
            else {
                if (!props.etd) {
                    showToast({ type: 'error', message: 'Please Input ETD!' });
                }
                else {
                    if (!props.airportFromId) {
                        showToast({ type: 'error', message: 'Please Select Airport Departure!' });
                    }
                    else {
                        if (!props.airportToId) {
                            showToast({ type: 'error', message: 'Please Select Airport Destination!' });
                        }
                        else {
                            let Sequence = 1;

                            if (props.detailEdit) {
                                Sequence = props.selectedDetail.sequence;
                            }
                            else {
                                if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                            }

                            if (Sequence === 1) {
                                props.setAirLineName(props.flightName);
                            }

                            let data = {
                                "countryId": countryId,
                                "companyId": companyId,
                                "branchId": branchId,
                                "userId": userId,
                                "userCode": userCode,

                                "rowStatus": "ACT",
                                "shipmentId": props.ShipmentId,
                                "sequence": Sequence,
                                "vesselId": 0,
                                "vesselName": "",
                                "vesselType": "",
                                "voyage": "",
                                "cityId": 0,
                                "cityCode": "",
                                "cityName": "",
                                "portId": 0,
                                "portCode": "",
                                "portName": "",
                                "flightId": props.flightId,
                                "flightCode": props.flightCode,
                                "flightName": props.flightName,
                                "flightNo": props.flightNo,
                                "airportFromId": props.airportFromId,
                                "airPortFromCode": props.airportFromCode,
                                "airPortFromName": props.airportFromName,
                                "airportToId": props.airportToId,
                                "airPortToCode": props.airportToCode,
                                "airPortToName": props.airportToName,
                                "cityFromId": props.cityFromId,
                                "cityFromCode": props.cityFromCode,
                                "cityFromName": props.cityFromName,
                                "cityToId": props.cityToId,
                                "cityToCode": props.cityToCode,
                                "cityToName": props.cityToName,
                                "etd": props.etd,
                                "idLama": 0,
                            }

                            if (!props.detailEdit) {
                                props.setFlightData([...props.flightData, data]);
                            }
                            else {
                                const newArr = props.flightData.slice();
                                newArr.forEach(el => {
                                    if (el.sequence === data.sequence) {
                                        el.flightId = data.flightId;
                                        el.flightCode = data.flightCode;
                                        el.flightName = data.flightName;
                                        el.flightNo = data.flightNo;
                                        el.airportFromId = data.airportFromId;
                                        el.airPortFromCode = data.airPortFromCode;
                                        el.airPortFromName = data.airPortFromName;
                                        el.airportToId = data.airportToId;
                                        el.airPortToCode = data.airPortToCode;
                                        el.airPortToName = data.airPortToName;
                                        el.cityFromId = data.cityFromId;
                                        el.cityFromCode = data.cityFromCode;
                                        el.cityFromName = data.cityFromName;
                                        el.cityToId = data.cityToId;
                                        el.cityToCode = data.cityToCode;
                                        el.cityToName = data.cityToName;
                                        el.etd = data.etd;
                                    }
                                })
                                props.setFlightData(newArr);
                            }

                            emptyState();
                            handleClose();
                            props.setDetailEdit(false);
                            props.setSelectedDetail({});
                        }
                    }
                }
            }
        }
    };

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        let title = JSON.parse(localStorage.getItem("title"));

        if (title === 'Flight') {
            param = 'airLine/airLine';
        }

        else if (title === 'AirPort') {
            param = 'airPort/airPort';
        }

        if (param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        let temp = [];
                        switch (title) {
                            case 'Flight':
                                setDataN(response.data.data.airLine);
                                temp = response.data.data.airLine;
                                break;
                            case 'AirPort':
                                setDataN(response.data.data.airPort);
                                temp = response.data.data.airPort;
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false);
                    countErrN++
                    if (countErrN < 3) {
                        getDataNonContact(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                        setIsModal(false);
                    }
                })
        }
    };

    const mainContainer = (
        <Grid
            container
            spacing={'32px'}
        >
            <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
            >
                <Stack spacing={'16px'} direction="row">
                    <Input
                        label="Flight Code"
                        value={props.flightCode}
                        disabled
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '50%',
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => searchFlight('delete')}
                                        hidden={props.flightCode ? false : true}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton onClick={() => searchFlight('search')}>
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <Input
                        label="Flight Number"
                        value={props.flightNo}
                        onChange={(e) => props.setFlightNo(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Stack>

                <Input
                    label="ETD"
                    type={'date'}
                    value={props.etd}
                    onChange={(e) => props.setETD(e.target.value)}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px'
                        }
                    }}
                />
            </Grid>

            <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
            >
                <Stack spacing={'16px'} direction="row">
                    <Input
                        label="AirPort From Code"
                        value={props.airportFromCode}
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '24px',
                                width: '50%'
                            }
                        }}
                    />

                    <Input
                        label="AirPort From Name"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.airportFromName}
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => searchAirPortFrom('delete')}
                                        hidden={props.airportFromName ? false : true}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton onClick={() => searchAirPortFrom('search')}>
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        disabled
                    />
                </Stack>

                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                    <Input
                        label="City Code"
                        value={props.airportToCode}
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '24px',
                                width: '50%'
                            }
                        }}
                    />

                    <Input
                        label="AirPort To Name"
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.airportToName}
                        type='text'
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => searchAirPortTo('delete')}
                                        hidden={props.airportToName ? false : true}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton onClick={() => searchAirPortTo('search')}>
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        disabled
                    />
                </Stack>
            </Grid>
        </Grid>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal2}
                isOpen={props.isModal2}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataNonContact
                        jobType={props.jobType}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        tipe={tipe}
                        setTitleModal={setTitleModal}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setFlightId={props.setFlightId}
                        setFlightCode={props.setFlightCode}
                        setFlightName={props.setFlightName}

                        setAirportFromId={props.setAirportFromId}
                        setAirportFromCode={props.setAirportFromCode}
                        setAirportFromName={props.setAirportFromName}
                        setCityFromId={props.setCityFromId}
                        setCityFromCode={props.setCityFromCode}
                        setCityFromName={props.setCityFromName}

                        setAirportToId={props.setAirportToId}
                        setAirportToCode={props.setAirportToCode}
                        setAirportToName={props.setAirportToName}
                        setCityToId={props.setCityToId}
                        setCityToCode={props.setCityToCode}
                        setCityToName={props.setCityToName}
                    />
                )
            }
        </>
    )
}

export default ModalFlight;