import { TableContainer, Table, TableBody, TableRow, TableHead, TableCell, Typography, } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React from 'react';
import ApiService, { userCode } from '../../../services/ApiService';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import CreateEditModalIdentity from '../CreateEditModalIdentity';
import { showToast, TickBox } from '../../../components/ui';

const CreateEditRegAccess = forwardRef((props, ref) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const param = 'regAccess/regAccess';

    const handleClose = () => {
        props.setDataRegAccess([]);
        props.handleClick();
    };

    useImperativeHandle(ref, () => ({
        localSave() {
        }
    }));

    const handleSave = () => {
        let data = {
            "access": props.DataRegAccess
        }
        ApiService.IdentityCreateData(param, data)
            .then((res) => {
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: 'Edit Access ' + props.Title + ' Success' });
                    props.handleClick();
                }
            })
            .catch(function (error) {
                showToast({ type: 'error', message: 'Edit Access ' + props.Title + ' Failed, ' + error.toString() });
            });

        handleClose();
    }

    const handleCheck = (e, el) => {
        let dataChecked = [];
        if (e.target.checked) {
            let data = {
                "userCode": userCode,
                "rowStatus": "ACT",
                "id": 0,
                "permissionId": props.SelectedData.id,
                "accessId": el.id,
            }
            dataChecked = [...props.DataRegAccess, data];
        }
        else {
            dataChecked = props.DataRegAccess.filter(dt => dt.accessId !== el.id);
            // alert('uncheck')
        }
        props.setDataRegAccess(dataChecked);
    }

    const mainContainer = (
        <TableContainer sx={{ maxHeight: 500, marginTop: '12px', background: '#FFF', borderRadius: '12px' }}>
            <Table stickyHeader>
                <TableHead
                    sx={{
                        '& th:first-of-type': {
                            borderRadius: '12px 0 0'
                        },
                        '& th:last-child': {
                            borderRadius: '0 12px 0 0'
                        }
                    }}
                >
                    <TableRow>
                        <StyledTableCell sx={{ fontWeight: 'bold', }}>Access</StyledTableCell>
                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Available</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.isLoading ?
                            <TableRow>
                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}><LoadingSpinner /></StyledTableBodyCell>
                            </TableRow>
                            :
                            <>
                                {
                                    props.DataAccess.length > 0 ?
                                        props.DataAccess.map((el) => {
                                            return (
                                                <TableRow key={el.id}>
                                                    <StyledTableBodyCell>{el.name}</StyledTableBodyCell>
                                                    <StyledTableBodyCell sx={{ textAlign: 'center', }}>
                                                        <TickBox
                                                            size={'small'}
                                                            checked={
                                                                props.DataRegAccess.filter(dt => dt.accessId === el.id).length > 0 ?
                                                                    true
                                                                    :
                                                                    false
                                                            }
                                                            baseCheckboxProps={{
                                                                sx: {
                                                                    marginLeft: '20px'
                                                                }
                                                            }}
                                                            onChange={(e) => { handleCheck(e, el) }}
                                                        />
                                                    </StyledTableBodyCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                            <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px',
                                                        fontstyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal'
                                                    }}
                                                >
                                                    Data Empty
                                                </Typography>
                                            </StyledTableBodyCell>
                                        </TableRow>
                                }
                            </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalIdentity
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={handleSave}
                    />
                )
            }
        </>
    )
})

export default CreateEditRegAccess;