import React from 'react';
import Grid from '@mui/material/Grid';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonInBoundList = ({ ReloadData, DataRegUserAccess, AddData, EditData, DeleteData, HandleStatus, DropDownTitle }) => {
    return (
        <div className="row">
            <div className='col-12'>
                <Grid
                    container
                    item
                    spacing={1}
                    direction="row"
                    style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
                >
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map(({ accessId, accessCode }) => (
                        <React.Fragment key={accessId}>
                            {accessCode === 'CRE' && ButtonComponent.AddButton(AddData, 'New')}
                            {accessCode === 'REI' && ButtonComponent.EditButton(EditData, 'Data')}
                            {accessCode === 'DEL' && ButtonComponent.DeleteButton(DeleteData, 'Data')}
                        </React.Fragment>
                    ))}

                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
                </Grid>
            </div>
        </div>
    );
}

export default ButtonInBoundList;